import { Navigation } from "interfaces";
import { MenuOption } from "./MenuOption";
import { SwiperSlide, Swiper } from "swiper/react";
import { useEffect, useRef } from "react";
import SwiperCore, { Navigation as SwiperNavigation } from "swiper";
import { useLocation } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
SwiperCore.use([SwiperNavigation]);

const MainMenu = ({
	pages
}: {
	pages: Navigation[];
	className?: string;
	isLandingPage?: boolean;
}): JSX.Element => {
	const isLowWidth = useMediaQuery("(max-width:720px)");
	const swiperInstance = useRef<SwiperCore>();
	const location = useLocation();

	const goToSlide = (index: number) => {
		//swiperInstance?.slideTo(index);
		swiperInstance?.current?.slideTo(index, 0, false);
	};

	useEffect(() => {
		pages.find((page: Navigation, index: number) => {
			if (page.path === location.pathname) {
				goToSlide(index);
			}
		});
	}, [swiperInstance]);

	return (
		<Box sx={{ paddingTop: { xs: "6px", md: "14px" } }}>
			<Swiper
				//onSwiper={setSwiperInstance}
				style={{ height: isLowWidth ? "55px" : "68px" }}
				onInit={(swiper: SwiperCore) => {
					swiperInstance.current = swiper; // Aquí almacenamos la instancia de swiper en ref
				}}
				slidesPerView={"auto"}
				spaceBetween={16}
				freeMode={false}
				loop={false}
				pagination={{
					clickable: true
					//el: ".swiper-custom-pagination"
				}}
				className="mySwiper"
				//modules={[Pagination, FreeMode]}
			>
				{pages.map((page) => {
					return (
						<SwiperSlide key={page.path} style={{ width: "auto" }}>
							<MenuOption page={page}></MenuOption>
						</SwiperSlide>
					);
				})}
			</Swiper>
			{/* <button onClick={() => goToSlide(5)}>Test</button> */}
		</Box>
	);
};

export default MainMenu;
