import { useContext, useEffect, useState } from "react";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { IShopPurchaseOfferMultiItem } from "contexts/WebStoreProvider/types";
import { FeaturedDealsSkeleton } from "../../skeleton";
import { offersApi, useGetOffersQuery } from "redux/apis";
import { ShopContext } from "../../index";
import { FeaturedOfferSection } from "..";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks";
import { selectShopSectionsState } from "redux/slices/shop-sections";

const FeaturedDeals = () => {
	const { authToken } = useWebStoreContext();
	const [content, setContent] = useState<JSX.Element[]>();
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const dispatch = useDispatch();
	const { data: shop } = useGetOffersQuery(
		{
			offerType: "featured",
			isAuthenticated: authToken?.value ? true : false
		},
		{ pollingInterval: 600000 }
	);
	const { sectionMenuData } = useContext(ShopContext);

	useEffect(() => {
		if (!shopSections?.mainShopSections || !shop || !sectionMenuData.length) {
			return;
		}

		//TODO: FIX any
		/* eslint-disable */
		setContent(
			shopSections?.mainShopSections
				.filter((data) =>
					shop.offers?.find((of: any) => of.sectionName === data.name)
				)
				.map((data) => {
					const sectionOffers = shop?.offers.filter(
						(of: any) => of.sectionName === data.name
					);
					const sectionProps = {
						data,
						sectionRef: sectionMenuData.find(
							(item: any) => item.name === data.name
						)?.sectionRef,
						uniqueKey: data.name
					};

					return (
						<FeaturedOfferSection
							{...sectionProps}
							key={data.name}
							sectionOffers={sectionOffers as IShopPurchaseOfferMultiItem[]}
						/>
					);
				})
		);
		/* eslint-enable */
	}, [shop, sectionMenuData]);

	useEffect(() => {
		dispatch(
			offersApi.util.invalidateTags([
				{
					type: "offersTag",
					id: "featured"
				}
			])
		);
	}, [sectionMenuData]);

	if (!content) {
		return <FeaturedDealsSkeleton />;
	}

	return <>{content}</>;
};

export default FeaturedDeals;
