import classNames from "classnames";
import useBreakpoints from "hooks/useBreakpoints";
import { useImagePath } from "hooks";
import { LearnMoreItem } from "../../items";
import { CardStyled, Img, Body, Title, Description } from "./styles";

interface CardProps {
	item: LearnMoreItem;
}

export const Card: React.FC<CardProps> = ({ item }) => {
	const { isSmDown } = useBreakpoints();
	const { image, description, title } = item;
	const { getImage } = useImagePath("local");

	return (
		<CardStyled
			className={classNames([isSmDown && "mobile", !isSmDown && "landscape"])}
		>
			<Img className="image" src={getImage(image)} />
			<Body className="body">
				<Title className="title">{title}</Title>
				<Description className="description">{description}</Description>
			</Body>
		</CardStyled>
	);
};
