import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headersConfig } from "./apiHelpers";

export const baseApi = createApi({
	reducerPath: "baseApi",
	tagTypes: [
		"offersTag",
		"featureTrackerTag",
		"segmentationTag",
		"experimentsTag",
		"bouncyChestTag"
	],
	keepUnusedDataFor: 1800,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	//refetchOnFocus: true,
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URI,
		prepareHeaders: (headers) => {
			return headersConfig(headers);
		}
	}),
	endpoints: () => ({})
});
