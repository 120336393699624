import ThemeButton from "components/Buttons/ThemeButton";
import { styled } from "@mui/material";
import { PBTextShadow } from "components/PoisBountyWidget/lib/common";
/* eslint-disable */
export const Container = styled("div")`
	width: 80vw;
	border-radius: 1rem;
	background-image: url(${(props: any) => props["background-image"]});
	background-repeat: no-repeat;
	background-size: 42%;
	background-position: left bottom;
	padding: 2rem 1rem;
	background-color: #0184a4;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	gap: 1rem;

	@media (min-width: 1200px) {
		width: 956px;
	}
	@media (max-width: 1094px) {
		background-size: 50%;
	}
	@media (max-width: 800px) {
		background-size: 70%;
	}
	@media (max-width: 600px) {
		background-size: 100%;
		padding: 4rem 1rem;
		background-position: -100px bottom;
	}
	@media (max-width: 900px) and (max-height: 570px) {
		height: 190px;
		background-size: 42%;
	}
	@media (max-height: 360px) {
		height: 100%;
		background-size: 30%;
		gap: 0.3rem;
		padding: 1rem .5rem;
	}
`;
/* eslint-enable */
export const Title = styled("img")`
	width: 280px;
	@media (max-width: 900px) and (max-height: 570px) {
		width: 140px;
	}
	@media (max-height: 360px) {
		width: 115px;
	}
`;

export const Description = styled(PBTextShadow)`
	line-height: 40px;
	font-size: 35px;
	align-items: center;
	width: 70%;
	@media (max-width: 1094px) {
		width: 90%;
	}
	@media (max-width: 850px) {
		text-shadow: 1.0996px 3.0996px 0px #000000, 0px -2px 0px #000000;
		font-size: 28px;
	}
	@media (max-width: 600px) {
		width: 95%;
		font-size: 22px;
	}
	@media (max-width: 900px) and (max-height: 570px) {
		font-size: 18px;
		line-height: 30px;
	}
	@media (max-height: 360px) {
		font-size: 16px;
		line-height: 22px;
	}
`;

export const Button = styled(ThemeButton)`
	width: 40%;
	@media (max-width: 650px) {
		width: 80%;
	}
`;
