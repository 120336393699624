import { createAsyncThunk } from "@reduxjs/toolkit";
import { PoisBountyV2Response } from "interfaces";
import { onDIPtrigger } from "redux/slices/dip";
import { onOpenPopUp } from "redux/slices/pop-up-manager";
import { RootState } from "redux/store";
import service from "services/api";
import { PREFIX } from "../helper";

export const getPoisBountyV2EventTrackers = createAsyncThunk(
	`${PREFIX}/get-trackers`,
	async (): Promise<PoisBountyV2Response | undefined> => {
		try {
			return await service.Get<PoisBountyV2Response>(
				"/rest/poisBounty/trackers"
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const onClaimV2Reward = createAsyncThunk(
	`${PREFIX}/claim-reward`,
	async (
		rewardIndex: number,
		thunkAPI
	): Promise<PoisBountyV2Response | undefined> => {
		const { trackers } = (thunkAPI.getState() as RootState).poisBountyV2;
		const { eventId } = trackers;
		try {
			const response = await service.Post<PoisBountyV2Response>(
				`/rest/poisBounty/v2/claim/?eventId=${eventId}&rewardIndex=${rewardIndex}`
			);
			thunkAPI.dispatch(onDIPtrigger("poisBountyAutoClaim"));
			thunkAPI.dispatch(onOpenPopUp("pb-reward-collected-v2"));
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);

export const onClaimAll = createAsyncThunk(
	`${PREFIX}/claim-all`,
	async (
		includeCurrentCycle: boolean,
		thunkAPI
	): Promise<PoisBountyV2Response | undefined> => {
		const { trackers, dataV2, remaining24hour } = (
			thunkAPI.getState() as RootState
		).poisBountyV2;
		const { eventId, rewardClaimStatuses } = trackers;
		try {
			const response = await service.Post<PoisBountyV2Response>(
				`/rest/poisBounty/claimAll?eventId=${eventId}&includeCurrentCycle=${includeCurrentCycle}`
			);
			thunkAPI.dispatch(onOpenPopUp("pb-claim-all-rewards"));
			if (
				dataV2.trackers[0].loyaltyPoints ===
					rewardClaimStatuses.slice(-1)[0].reward.loyaltyPoints &&
				!remaining24hour
			) {
				thunkAPI.dispatch(onCreateCycleTracker());
			}
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);

export const onCreateCycleTracker = createAsyncThunk(
	`${PREFIX}/create-cycle-tracker`,
	async (_, thunkAPI): Promise<PoisBountyV2Response | undefined> => {
		const { trackers } = (thunkAPI.getState() as RootState).poisBountyV2;
		const { eventId } = trackers;
		try {
			const response = await service.Post<PoisBountyV2Response>(
				`/rest/poisBounty/createCycleTracker?eventId=${eventId}`
			);
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);

/* eslint-disable */
export const onPopUpV2Displayed = createAsyncThunk(
	`${PREFIX}/pop-up-displayed`,
	async (eventId: string): Promise<any | undefined> => {
		try {
			await service.Post(
				`/rest/poisBounty/v2/completeDisplayed?eventId=${eventId}`
			);
		} catch (error) {
			console.log(error);
		}
	}
);
/* eslint-enable */

/***************************************************
 * DEBUG thunks
 ***************************************************/

interface loyaltyPointsProps {
	userId: string;
	loyaltyPoints: number;
}

export const setLoyaltyPoints = createAsyncThunk(
	`${PREFIX}/DEBUG-add-loyalty-points`,
	async (data: loyaltyPointsProps, thunkAPI): Promise<void> => {
		const { trackers } = (thunkAPI.getState() as RootState).poisBountyV2;
		const { eventId } = trackers;
		const { loyaltyPoints, userId } = data;

		try {
			await service.Post(
				`/rest/debug/poisBounty/addLoyaltyPoints?loyaltyPoints=${loyaltyPoints}&userId=${userId}&eventId=${eventId}`
			);
			thunkAPI.dispatch(getPoisBountyV2EventTrackers());
		} catch (error) {
			console.log(error);
		}
	}
);
