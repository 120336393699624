import { Typography } from "@mui/material";
import BootstrapDialog from "components/BootstrapDialogV2";
import FacebookLoginButton from "components/Buttons/FacebookLoginButton";
import ScopelyLoginButton from "components/Buttons/ScopelyLoginButton";
import ThemeButton from "components/Buttons/ThemeButton";
import { useAppSelector } from "hooks";
import { selectDialogState } from "redux/slices";

export const DialogGeneric = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onCloseClick: () => void;
}) => {
	const { props } = useAppSelector(selectDialogState);
	if (!props) {
		return null;
	}
	const {
		subtitle,
		showClose,
		title,
		message,
		isReLoginEnabled,
		isContinueButtonEnabled,
		isReloadButtonEnabled
	} = props;
	/**
	 * DEFAULT MODAL DIALOG (NEW)
	 * - custom modals above
	 */
	return (
		<BootstrapDialog
			title="OOPS.."
			subtitle={subtitle}
			open={show}
			handleClose={onCloseClick}
			showClose={showClose}
		>
			<Typography
				component="span"
				variant="h6"
				sx={{
					display: "flex",
					fontWeight: "bold",
					justifyContent: "center"
				}}
			>
				{title}
			</Typography>
			<Typography
				component="span"
				sx={{
					display: "flex",
					marginBottom: "1rem"
				}}
			>
				{message}
			</Typography>
			{isReLoginEnabled && (
				<>
					<ScopelyLoginButton
						cbClick={onCloseClick}
						source={"try_relogin_dialog"}
					/>
					<FacebookLoginButton
						cbClick={onCloseClick}
						source={"try_relogin_dialog"}
					/>
				</>
			)}
			{isContinueButtonEnabled && (
				<ThemeButton
					onClick={() => {
						onCloseClick();
					}}
					sx={{
						paddingTop: ".75rem",
						paddingBottom: ".75rem",
						marginBottom: 1,
						width: "100%"
					}}
				>
					Continue Shopping
				</ThemeButton>
			)}
			{isReloadButtonEnabled && (
				<ThemeButton
					onClick={() => {
						onCloseClick();
						location.reload();
					}}
					sx={{
						paddingTop: ".75rem",
						paddingBottom: ".75rem",
						marginBottom: 1,
						width: "100%"
					}}
				>
					Reload Page
				</ThemeButton>
			)}
		</BootstrapDialog>
	);
};
