import styled from "styled-components";
import { Box } from "@mui/material";

export const StatusIcon = styled(Box)`
	position: absolute;
	top: -18%;
	right: -18%;
	z-index: 1;
	height: 48px;
`;
export const LockIcon = styled(StatusIcon)`
	img {
		width: 40px;
		content: url("https://storage.googleapis.com/tripeaks/WebStore/static_assets/frontend/public/spec_pois_bounty/pois_bounty_lock.png");

		&.mega-menu-variant {
			@media (max-width: 990px) {
				width: 30px;
			}
		}
	}
`;
export const SuccessIcon = styled(StatusIcon)`
	top: -20%;
	right: -5%;
	img {
		width: 40px;
	}
	&.mega-menu-variant {
		img {
			@media (max-width: 990px) {
				width: 30px;
			}
		}
		@media (max-width: 990px) {
				right: -15%;
			}
	}
	@media (max-width: 600px) {
		top: -25%;
		right: -20%;
		img {
			width: 48px;
		}
	}
`;
