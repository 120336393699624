import { parseContent } from "..";
import { Link as RouterLink } from "react-router-dom";
import { Box, styled, Typography } from "@mui/material";
import moment from "moment";
import Button from "components/Buttons/ThemeButton";
import { useRef } from "react";
import { useWebNewsContext } from "contexts/WebNewsProvider";
import "animate.css";
import { PostListItemSkeleton } from "../skeleton";
import { useImagePreloader } from "hooks/useImagePreloader";
import { IPostListItem } from "./PostList";

const NewsItem = styled(Box)`
	border-radius: 18px;
	overflow: hidden;
	background: #fffaf1;
	box-shadow: 0px 11.0701px 11.0701px rgba(0, 0, 0, 0.1);
	border-radius: 22px;
	color: #014189;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	a,
	.date {
		color: #01a1bd;
		text-decoration: none;
	}
`;

const FeaturedImage = styled("img")`
	width: 100%;
`;
const Summary = styled("div")`
	padding: 1em;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

const ReadMoreButton = styled(Button)`
	background-color: #85bafb;
	border-radius: 14.9071px;
	color: white;
	width: 100%;
`;

const PostListItem = ({ post }: { post: IPostListItem }) => {
	const { setCurrentNewsListScrollPosition } = useWebNewsContext();
	const postDate = post?.date && moment(post.date).format("MMMM DD, YYYY");
	const newsItemRef = useRef<HTMLElement | null>(null);
	const { isImageLoaded: isFeatureImageLoaded } = useImagePreloader(
		post?.jetpack_featured_media_url
	);

	const handleClick = () => {
		setCurrentNewsListScrollPosition(
			window.scrollY + (newsItemRef?.current?.offsetHeight || 0)
		);
	};
	const postUri = `/news/${post.slug}`;

	return !isFeatureImageLoaded ? (
		<PostListItemSkeleton />
	) : (
		<NewsItem ref={newsItemRef} className="animate__animated animate__fadeIn">
			<Box>
				<RouterLink to={postUri} onClick={handleClick}>
					<FeaturedImage src={post?.jetpack_featured_media_url} />
				</RouterLink>
				<Summary>
					<Typography component="span" sx={{ marginBottom: "1em" }}>
						{postDate}
					</Typography>
					<Typography component="h3" variant="h5">
						{parseContent(post?.title?.rendered)}
					</Typography>
					<Typography component="p">
						{parseContent(post?.excerpt?.rendered)}
					</Typography>
				</Summary>
			</Box>
			<Box sx={{ padding: "0 1em 1em 1em" }}>
				<RouterLink to={postUri} onClick={handleClick}>
					<ReadMoreButton>Read More</ReadMoreButton>
				</RouterLink>
			</Box>
		</NewsItem>
	);
};

export default PostListItem;
