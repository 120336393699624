import { useImagePath } from "hooks";
import { PoisBountyStatus } from "interfaces";
import { LockIcon, SuccessIcon } from "./styles";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";

interface StatusIconProps {
	status: PoisBountyStatus;
}

export const StatusIcon = ({ status }: StatusIconProps) => {
	const { getImage } = useImagePath("local");
	const { isPageVariant } = usePoisBountyContext();

	switch (status) {
		case "locked":
			return (
				<LockIcon>
					<img className={`${!isPageVariant && "mega-menu-variant"}`} />
				</LockIcon>
			);
		case "unlocked":
			return (
				<SuccessIcon className={`${!isPageVariant && "mega-menu-variant"}`}>
					<img src={getImage("/spec_pois_bounty/pois_bounty_icon_check.png")} />
				</SuccessIcon>
			);
		default:
			return null;
	}
};
