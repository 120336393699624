import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpVariant } from "interfaces";
import { RootState } from "redux/store";
import { PREFIX } from "./helper";
import { initialState } from "./initial-state";

export const popUpManagerSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onOpenPopUp: (state, action: PayloadAction<PopUpVariant>) => {
			state.variant = action.payload;
		},
		onClosePopUp: (state) => {
			state.variant = undefined;
		}
	}
});

export const { onOpenPopUp, onClosePopUp } = popUpManagerSlice.actions;

export const selectPopUpManagerState = (state: RootState) => state.popUpManager;
export default popUpManagerSlice.reducer;
export * from "./types";
