import { getDebugLogger } from "../../../utils/debugLogger";
import { IPopupsProp, IUserTracker } from "../types";
import { simulateErrorResponse } from "./handleErrorResponse";

const debug = getDebugLogger({
	isEnabled: true,
	color: "Orange",
	iconText: "OOPS:"
});

export interface IGetCheckUserEligibilityProps {
	popups: IPopupsProp;
	userTracker: IUserTracker | null;
}

export interface ICheckUserEligibilityOffer {
	offerId?: string;
	purchaseLimit?: number;
	onlyNonPayers?: boolean;
	loyaltyPoints?: number;
}

/* eslint-disable */
export type IGetCheckUserEligibilityReturnType = (
	type: string,
	offer: ICheckUserEligibilityOffer
) => boolean;
/* eslint-enable */

const getCheckUserEligibility = ({
	popups,
	userTracker
}: IGetCheckUserEligibilityProps): IGetCheckUserEligibilityReturnType => {
	const checkUserEligibility = (
		type: string,
		offer: ICheckUserEligibilityOffer
	) => {
		if (!userTracker) {
			return false;
		}
		let isEligible = true;
		switch (type) {
			case "loyalty_buy":
				// insufficient_loyalty_point_balance
				if (
					offer?.loyaltyPoints &&
					userTracker?.loyaltyPoints &&
					offer.loyaltyPoints < userTracker.loyaltyPoints
				) {
					isEligible = false;
					debug.logFunction({
						iconText: "checkUserEligibility:",
						message: ["insufficient_loyalty_point_balance"]
					});
					popups.handleErrorResponse(
						simulateErrorResponse(
							"insufficient_loyalty_point_balance",
							"Insufficient Loyalty Point balance"
						)
					);
				}
				break;
		}
		debug.logFunction({
			iconText: "checkUserEligibility:",
			message: ["isEligible:", isEligible]
		});
		return isEligible;
	};

	return checkUserEligibility;
};

export default getCheckUserEligibility;
