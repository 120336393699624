import "animate.css";
import classNames from "classnames";
import { InfoButton, Timer } from "../../lib/common";
import { usePoisBountyContext } from "components/PoisBountyWidget/context";
import { useImagePath } from "hooks";
import { Container, Body, Head } from "./styles";
import { ProgressSteps } from "components/PoisBountyWidget/lib";

export const InProgress = () => {
	const { getImage } = useImagePath("local");
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const headImageUrl = getImage("/spec_pois_bounty/bg-pb-megamenu.jpg");

	return (
		<Container
			sx={{
				minHeight: isPageVariant ? "100px" : isPortrait ? "490px" : "420px"
			}}
			className={classNames([
				isPortrait && "portrait",
				isPageVariant && "page-variant"
			])}
		>
			{!isPageVariant && <Head data-image={headImageUrl} />}
			<Body>
				<ProgressSteps />
			</Body>
			{/* 🔦 Overlap components */}
			<Timer />
			<InfoButton />
		</Container>
	);
};
