import classNames from "classnames";
import Button from "components/Buttons/ThemeButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment } from "react";
import { getFlexBasis } from "../helper";
import { CrownIcon } from "utils/svgIcons";
import { selectDipState, selectPoisBountyV2State } from "redux/slices";
import { useAppSelector, useImagePath } from "hooks";
import { getClassNameVariant } from "components/DIP/helper";
import { useDIPAction, useDIPRewards } from "components/DIP/hooks";
import {
	BogoBox,
	BogoItem,
	OffersStyled,
	ItemImage,
	Items,
	RewardItem,
	RewardAmount,
	Footer,
	LoyaltyPoints,
	OfferItem,
	Plus,
	FreeTag
} from "./styles";
import { sortRewardsWithSpecificIdAtEnd } from "helpers";
import { CardFinalMessage } from "styles/general_styles";
import { useWebStoreContext } from "contexts";

interface BogoProps {}

export const Bogo: React.FC<BogoProps> = () => {
	const { currentDip, claimRewardProcess } = useAppSelector(selectDipState);
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const { userTracker } = useWebStoreContext();
	const { getDIPReward } = useDIPRewards();
	const { onAction } = useDIPAction();
	const { loading } = claimRewardProcess;
	const { getImage } = useImagePath("local");
	const plus = getImage("/spec_dips/bogo_plus.png");
	const freeTag = getImage("/spec_dips/free_tag.png");
	const freeTagTwo = getImage("/spec_dips/free_tag_two.png");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const isMobile = isPortrait;
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;

	if (!currentDip || !currentDip.buttons) {
		return <Fragment />;
	}
	const { buttons, dip } = currentDip;
	const data = [buttons[0], buttons[0]];
	const { rewards, loyaltyPointReward, rewardMultiplier, price } = getDIPReward(
		data[0]
	);

	return (
		<BogoBox>
			<OffersStyled className={getClassNameVariant(data?.length as number)}>
				{data?.map((item, index) => {
					const bogoOffer =
						index + 1 === data.length &&
						typeof rewardMultiplier !== "undefined";
					return (
						<BogoItem key={`${item.id} ${index}`}>
							<OfferItem
								className={classNames([
									getClassNameVariant(data.length as number),
									"item-reward"
								])}
							>
								<Items
									className={classNames([
										getClassNameVariant(data.length as number),
										"bundle-rewards-item"
									])}
								>
									{sortRewardsWithSpecificIdAtEnd(rewards, "random").map(
										({ imageUrl, description }, key) => {
											const flexBasis = getFlexBasis(data, rewards, isMobile);
											/* eslint-disable */
											// prettier-ignore
											const rewardAmount = bogoOffer
												? (Number(description?.replace(",", "")) * rewardMultiplier!).toLocaleString("en-US")
												: description;
											/* eslint-enable */

											return (
												<RewardItem key={key} width={flexBasis + "%"}>
													<ItemImage
														className="item-icon"
														src={imageUrl}
														alt=""
													/>
													<RewardAmount className="description">
														{rewardAmount}
													</RewardAmount>
												</RewardItem>
											);
										}
									)}
								</Items>

								<Footer
									className={classNames([
										loyaltyPointReward && "loyalty-reward",
										getClassNameVariant(data.length as number)
									])}
								>
									<LoyaltyPoints>
										<div>
											Earn <CrownIcon width={16} />
											{
												/* eslint-disable */
												bogoOffer
													? (
															loyaltyPointReward! * rewardMultiplier
													  ).toLocaleString("en-US")
													: loyaltyPointReward?.toLocaleString("en-US")
												/* eslint-enable */
											}
										</div>
										{isPoisActive && (
											<CardFinalMessage color="#FBBD05">
												Contribute to Poi’s Bounty
											</CardFinalMessage>
										)}
									</LoyaltyPoints>
								</Footer>
								{bogoOffer && (
									<FreeTag
										src={dip?.bogoType === "double" ? freeTagTwo : freeTag}
									/>
								)}
							</OfferItem>
							{!bogoOffer && <Plus src={plus} />}
						</BogoItem>
					);
				})}
			</OffersStyled>
			<Button
				className="bogo-button"
				disabled={loading}
				onClick={() => onAction(data[0])}
			>
				{price} USD
			</Button>
		</BogoBox>
	);
};
