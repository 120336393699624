import { LoginType } from "../../types";
import AbstractLoginRequest from "../types";

export default class FacebookLoginRequest extends AbstractLoginRequest {
	constructor(fbAccessToken: string) {
		super(LoginType.Facebook, {
			facebookAccessToken: fbAccessToken
		});
	}
}
