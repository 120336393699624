import "animate.css";
import classNames from "classnames";
import { Body, Container, Head } from "./styles";
import { InfoButton, Timer } from "../../lib/common";
import { useAppSelector, useImagePath } from "hooks";
import { selectPoisBountyV2State } from "redux/slices";
import { ProgressSteps } from "components/PoisBountyWidgetV2/lib";
import defaultHeadImg from "assets/pois-bounty/v2/bg-pois-megamenu.png";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";

export const InProgress = () => {
	const { getImage } = useImagePath("local");
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const { trackers } = useAppSelector(selectPoisBountyV2State);
	const headImageUrl = trackers.megaMenuBackgroundImageUrl || defaultHeadImg || getImage("/spec_pois_bounty/bg-pb-megamenu.jpg");

	return (
		<Container
			color={trackers.backgroundColorHex}
			sx={{
				minHeight: isPageVariant ? "100px" : isPortrait ? "490px" : "420px"
			}}
			className={classNames([
				isPortrait && "portrait",
				isPageVariant && "page-variant"
			])}
		>
			{!isPageVariant && <Head data-image={headImageUrl} />}
			<Body>
				<ProgressSteps />
			</Body>
			{/* 🔦 Overlap components */}
			<Timer />
			<InfoButton />
		</Container>
	);
};
