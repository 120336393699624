import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PREFIX } from "./helper";
import { initialState } from "./initial-state";
import { PBChangePayload, PoisBountySlice } from "./types";
import {
	TRACKER_EXTRA_REDUCER,
	CLAIM_REWARD_EXTRA_REDUCER
} from "./extra-reducers";

export const poisBountySlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onPBchange: (
			state,
			action: PayloadAction<PBChangePayload<PoisBountySlice>>
		) => {
			const { payload } = action;
			for (const [key, value] of Object.entries(payload)) {
				// @ts-expect-error
				state[key] = value;
			}
		}
	},
	extraReducers: (builder) => {
		TRACKER_EXTRA_REDUCER(builder);
		CLAIM_REWARD_EXTRA_REDUCER(builder);
	}
});

export const { onPBchange } = poisBountySlice.actions;

export const selectPoisBountyState = (state: RootState) => state.poisBounty;
export default poisBountySlice.reducer;
export * from "./types";
export * from "./thunks";
