import classNames from "classnames";
import { CrownIcon } from "utils/svgIcons";
import { useMediaQuery } from "@mui/material";
import { DipButton, WsDialogs } from "interfaces";
import Button from "components/Buttons/ThemeButton";
import { useAppDispatch, useAppSelector } from "hooks";
import { sortRewardsWithSpecificIdAtEnd } from "helpers";
import { getFlexBasis } from "components/DIP/lib/helper";
import { CardFinalMessage } from "styles/general_styles";
import { getClassNameVariant } from "components/DIP/helper";
import { getDisplayRewards } from "utils/getDisplayRewards";
import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import {
	getLimitedDeals,
	selectPoisBountyV2State,
	selectWebStoreState
} from "redux/slices";
import {
	Items,
	Footer,
	ItemImage,
	OfferItem,
	RewardItem,
	RewardAmount,
	OffersStyled,
	LoyaltyPoints
} from "./styles";

interface OffersProps {
	data: Omit<IShopPurchaseOffer, "onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText">[];
}

export const Offers: React.FC<OffersProps> = ({ data }: OffersProps) => {
	const dispatch = useAppDispatch();
	const isLowWidth = useMediaQuery("(max-width:733px)");
	const isMobile = isLowWidth;
	const { rewards, rewardTypes } = useAppSelector(selectWebStoreState).store;
	const { reportEvent } = useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const { wsStoreBuy, suppressOrderConfirmation, postUserMetadata } = useWebStoreContext();
	const { isEventActiveV2, remaining24hour } = useAppSelector(selectPoisBountyV2State);
	const { userTracker } = useWebStoreContext();
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;

	return (
		<OffersStyled className={getClassNameVariant(data?.length as number)}>
			{data &&
				data.map(({ rewardId, price, loyaltyPointReward, id }, key) => {
					const items = getDisplayRewards(rewardId, {
						rewards,
						rewardTypes
					});
					return (
						<OfferItem
							key={key}
							className={classNames([
								getClassNameVariant(data.length as number),
								loyaltyPointReward && "loyalty-reward",
								"item-reward"
							])}
						>
							<Items
								className={classNames([
									getClassNameVariant(data.length as number),
									"bundle-rewards-item"
								])}
							>
								{sortRewardsWithSpecificIdAtEnd(items, "random").map(
									({ imageUrl, description }, key) => {
										const flexBasis = getFlexBasis(
											data as unknown as DipButton[],
											items,
											isMobile
										);

										return (
											<RewardItem key={key} width={flexBasis + "%"}>
												<ItemImage src={imageUrl} alt="" />
												<RewardAmount className="description">
													{description}
												</RewardAmount>
											</RewardItem>
										);
									}
								)}
							</Items>

							<Footer
								className={classNames([
									loyaltyPointReward && "loyalty-reward",
									getClassNameVariant(data.length as number)
								])}
							>
								{loyaltyPointReward && (
									<LoyaltyPoints>
										<div>
											Earn <CrownIcon width={16} />
											{loyaltyPointReward?.toLocaleString("en-US")}
										</div>
										{isPoisActive && (
											<CardFinalMessage color="#FBBD05">
												Contribute to Poi’s Bounty
											</CardFinalMessage>
										)}
									</LoyaltyPoints>
								)}
								<Button
									onClick={() => {
										wsStoreBuy({ offerId: id }).then((invoice) => {
											if (invoice) {
												openDialog(WsDialogs.XSOLLA_PURCHASE, {
													xsollaToken: invoice.xsollaToken,
													xsollaHost: invoice.xsollaHost,
													suppressOrderConfirmation,
													source: "dips",
													postUserMetadata,
													onPurchaseStarted: (data) => {
														reportEvent("purchase_started", {
															section_name: "limited_deals",
															offer_id: id,
															price_in_cents: price * 100,
															reward_id: rewardId,
															invoice_id: invoice?.id,
															paystation_id: data?.payStation
														});
													},
													onPurchaseSuccess: () => {
														reportEvent("purchase_success", {
															invoice_id: invoice?.id
														});
														dispatch(getLimitedDeals());
													},
													cbClose: () => {
														reportEvent("dialog_x_closed", {
															source: "xsolla_dialog",
															page_location: location.pathname
														});
													}
												});
											}
										});
									}}
								>
									{price} USD
								</Button>
							</Footer>
						</OfferItem>
					);
				})}
		</OffersStyled>
	);
};
