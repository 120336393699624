/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Divider, useMediaQuery } from "@mui/material";
import { Footer, FooterContainer, FooterContent } from "./styled";
import {
	FooterAppStoreButtons,
	FooterCopyright,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	FooterLogo,
	FooterMainMenu,
	FooterPrivacyMenu,
	FooterSocialLinks
} from "./lib";

const PageFooter = () => {
	const isLowWidth = useMediaQuery("(max-width:599px)");
	//const isPortrait = useMediaQuery("(orientation: portrait)");
	const isMobile = isLowWidth;

	return (
		<FooterContainer>
			<Footer>
				<FooterContent>
					<Box
						sx={{
							alignItems: "flex-start",
							justifyContent: "space-between",
							display: "flex",
							minHeight: { sm: "330px" },
							flexWrap: { xs: "wrap", sm: "nowrap" },
							marginBottom: "20px"
						}}
					>
						<FooterMainMenu />
						{!isMobile && <FooterLogo />}
					</Box>
					{/* <Divider /> */}
					<Box
						sx={{
							display: "flex",
							justifyContent: { xs: "center", sm: "space-between" },
							flexWrap: { xs: "nowrap", sm: "wrap" },
							flexDirection: { xs: "column", sm: "row" }
						}}
					>
						<FooterSocialLinks />
						<FooterAppStoreButtons />
					</Box>
					{!isMobile && <Divider sx={{ margin: "15px" }} />}
					<Box
						sx={{
							justifyContent: { xs: "center", sm: "space-between" },
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "row-reverse"
						}}
					>
						{isMobile && <FooterLogo />}
						<FooterPrivacyMenu />
						<FooterCopyright />
					</Box>
				</FooterContent>
			</Footer>
		</FooterContainer>
	);
};

export default PageFooter;
