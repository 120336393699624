import styled from "styled-components";

export const EventCompleted = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 27px;
	width: -webkit-fill-available;

	img {
		width: 173px;
		z-index: 2;
	}

	p {
		font-family: "Inter";
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
		color: white;
		margin: 0 20px 0 -60px;
		max-width: 480px;
		width: -webkit-fill-available;

		span {
			color: #f8cb53;
			margin-right: 4px;
		}
	}

	button {
		width: 247px;
		font-size: 20px;
		border-radius: 16px;
		transform: translateY(0);
	}

	:before {
		content: "";
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		border: 1px solid rgba(0, 0, 0, 0.2);
		width: 95%;
	}

	&.mega-menu-variant {
		img {
			width: 100px;
		}

		p {
			font-size: 14px;
			line-height: 16px;
			margin: 0 0 0 -35px;
		}

		button {
			height: 45px;
			font-size: 13px;
		}

		:before {
			top: 0;
		}
	}
`;

export const EventCompletedMobile = styled.div`
	position: relative;
	padding: 0 15px;
	margin-top: 55px;

	img {
		width: 173px;
		z-index: 2;
		transform: rotateY(180deg);
		margin-left: -80px;
	}

	p {
		font-family: "Inter";
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
		color: white;
		margin: 0;

		span {
			color: #f8cb53;
		}

		@media (max-width: 360px) {
			font-size: 18px;
		}
	}

	button {
		width: 231px;
		font-size: 20px;
		border-radius: 16px;
		z-index: 3;
	}

	div {
		display: flex;
		align-items: center;
		
		:after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			border: 1px solid rgba(0, 0, 0, 0.2);
			width: 335px;

			@media (max-width: 360px) {
				width: 325px;
			}
		}
	}
`;