import { Box } from "@mui/material";
import styled from "styled-components";
import { CrownIcon } from "utils/svgIcons";

export const dialogSxProps = {
	".MuiPaper-root": {
		borderRadius: 6,
		paddingBottom: ".15rem",
		width: "462px"
	}
};

export const StyledBox = styled(Box)({
	background: "white",
	borderRadius: "13px",
	width: "100px",
	height: "100px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",

	boxShadow:
		"0px 11px 8px -7px rgb(0 0 0 / 10%), 0px 6px 6px 3px rgb(0 0 0 / 4%), 0px 9px 16px 8px rgb(0 0 0 / 5%)",

	img: {
		width: "auto",
		maxWidth: "90%",
		height: "auto",
		maxHeight: "80%",
		margin: 0,
	},
	strong: {
		textShadow:
			"0px 2.961822271347046px #00000026, 2.961822271347046px 0px #00000026"
	},

	"@media (max-width: 932px) and (max-height: 440px)": {
		width: 70,
		height: 70,
	},
	"@media (max-height: 360px)": {
		width: 50,
		height: 50,
	}
});

export const BundleStyledBox = styled(Box)({
	borderRadius: "13px",
	height: "100px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	img: {
		width: "auto",
		maxWidth: "90%",
		height: "auto",
		maxHeight: "80%",
		margin: 0,
		marginBottom: "8px",
		filter: "drop-shadow(0px 4.69351px 9.38703px rgba(0, 0, 0, 0.3))"
	},
	marginBottom: "1rem"
});

export const DialogTitleText = styled(Box)({
	paddingTop: "1rem",
	textAlign: "center",
	fontSize: "34px",
	lineHeight: "28px",
	fontWeight: 400,
	marginBottom: "2rem",
	fontFamily: "Baloo"
});

export const bundleItem_dialogTitleSxProps = {
	padding: "32px 15px 16px",
	backgroundSize: "contain",
	backgroundColor: "#FFFAF1",
	backgroundPosition: "center",
	overflow: "hidden"
};

export const bundleItem_dialogContentText_typographySxProps = {
	fontWeight: 400,
	margin: ".15rem 0 .5rem",
	display: "block",
	textAlign: "center",
	lineHeight: 1,
	color: "#333333",
	fontSize: " 26px",
	fontFamily: "Baloo"
};

export const dialogActions_buttonSxProps = {
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	marginBottom: 1,
	width: "calc(100% - 3rem)",
	marginLeft: 2,
	marginRight: 2
};

export const StyledCrownIcon = styled(CrownIcon)({
	marginRight: "4px",
	marginBottom: "-3px",
	width: "24px",
	height: "24px"
});
