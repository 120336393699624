import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)`
	position: absolute;
	bottom: -1rem;
	right: 5rem;
	width: 452px;

	@media (max-width: 599px) {
		top: 8rem;
		left: 0;
		width: 85%;
		margin-left: 50%;
		transform: translateX(-50%);
	}
`;
