import styled from "styled-components";
import ThemeButton from "components/Buttons/ThemeButton";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;

	@media (min-width: 650px) and (max-height: 430px) {
		gap: 8px;
		width: 90%;
	}
`;

export const Progress = styled.div`
	display: grid;
	grid-gap: 10px;
	width: 100%;
	grid-template-columns: repeat(3, 1fr);
	.last-item {
		grid-column: span 3;
	}
	@media (min-width: 650px) {
		width: unset;
		grid-gap: 10px;
		margin-right: 0.7rem;
		grid-template-columns: repeat(4, minmax(max-content, 127px));
		.last-item {
			grid-column: span 2;
		}
	}
	@media (min-width: 650px) and (max-height: 430px) {
		gap: 4px 8px;
		width: 90%;
	}
	@media (min-width: 1150px) {
		grid-template-columns: repeat(4, minmax(max-content, 147px));
	}
`;

export const ClaimButton = styled(ThemeButton)`
	width: 100%;
	font-size: 18px;

	@media (min-width: 650px) {
		width: 178px;
	}
	@media (min-width: 650px) and (max-height: 430px) {
		height: auto;
	}
`;
