import { styled } from "@mui/material";
import { useRef } from "react";
import theme from "../../../theme";

export const Content = styled("div")`
	justify-content: center;
	--grid-columns: 1;

	transition: all 0.3s linear;
	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(
		var(--grid-columns),
		calc(96% / var(--grid-columns)) [col]
	);

	${theme.breakpoints.up("sm")} {
		--grid-columns: 1;
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
	${theme.breakpoints.up("md")} {
		justify-content: space-around;
		--grid-columns: 2;

		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
	${theme.breakpoints.up("lg")} {
		justify-content: center;
		--grid-columns: 2;
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
	${theme.breakpoints.up("xl")} {
		justify-content: space-around;
		--grid-columns: 3;

		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
`;

const SectionContent = ({ items }: { items: JSX.Element[] }) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	return <Content ref={contentRef}>{items}</Content>;
};

export default SectionContent;
