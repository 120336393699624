import ThemeButton from "components/Buttons/ThemeButton";
import { Box, styled } from "@mui/material";

export const Container = styled("div")`
	width: 444px;
	background-color: #fff;
	border-radius: 1.5rem 1.5rem 1rem 1rem;
	position: relative;
	overflow: hidden;

	@media (max-width: 444px) {
		width: 95%;
		margin: 0 auto;
	}
`;

export const Header = styled("div")`
	height: 140px;
	position: relative;

	@media (max-height: 390px) {
		height: 100px;
	}
`;

export const Background = styled("img")`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;
/* eslint-disable */
export const Reward = styled("div")`
	width: 152px;
	height: 152px;
	background-color: #7ae0db;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: calc(152px / 2.5);
	z-index: 1199;
	border: 4px solid #fff;
	border-radius: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(${(props: any) => props["data-image"]});
	background-size: 200%;
	background-repeat: no-repeat;
	background-position: 50% 110%;

	@media (max-height: 390px) {
		width: 100px;
		height: 100px;
		top: calc(100px / 2.5);
	}
`;
/* eslint-enable */
export const Icon = styled("div")`
	position: absolute;
	top: -1.5rem;
	right: -1rem;

	@media (max-width: 600px) {
		top: -1.5rem;
		right: -0.5rem;
	}
`;

export const RewardImg = styled("img")`
	width: 100px;
	height: 100px;
	object-fit: contain;

	@media (max-height: 390px) {
		width: 70px;
		height: 70px;
	}
`;

export const RewardText = styled(Box)`
	font-family: "Baloo";
	font-size: 35px;
	position: absolute;
	bottom: -3px;
	right: 10px;
	color: white;
	text-shadow: 2.0996px 1.0996px 0px #000000;
	display: flex;
	align-items: center;

	@media (max-height: 390px) {
		font-size: 25px;
	}
`;

export const Body = styled("div")`
	padding: 6rem 3rem 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
	margin: 0 auto;

	@media (max-height: 390px) {
		padding: 3rem 1.5rem 1rem;
		gap: 0.3rem;
	}
`;

export const Title = styled("span")`
	font-size: 14px;
	line-height: 28px;
	text-align: center;

	@media (max-height: 390px) {
		font-size: 12px;
	}
`;

export const Button = styled(ThemeButton)`
	padding: 0.5rem;
	background-color: #4088b7;
`;
