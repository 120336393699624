import { baseApi } from "../api";

export const experimentsAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getDebugExperiments: builder.query({
			query: () => `/rest/debug/experiments/user`,
			providesTags: ["experimentsTag"],
		}),
		setDebugExperiments: builder.mutation({
			query: (body) => ({
				url: `/rest/debug/experiments/debug-experiments`,
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: "experimentsTag", id: arg.FeatureType }
			]
		}),
		clearDebugExperiments: builder.mutation({
			query: () => ({
				url: `/rest/debug/experiments/debug-experiments/clear`,
				method: "POST"
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: "experimentsTag", id: arg.FeatureType }
			]
		})
	})
});

export const { useGetDebugExperimentsQuery, useSetDebugExperimentsMutation, useClearDebugExperimentsMutation } =
	experimentsAPI;
