import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import backgroundPayment1 from "assets/tutorial/purchase-step01.png";
import backgroundPayment2 from "assets/tutorial/purchase-step02.png";
import tiki from "assets/tutorial/tiki-2.svg";
import Button from "components/Buttons/ThemeButton";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { onClosePopUp } from "redux/slices";
import { Arrow, Background2, BackgroundPaimentLarge, Text, Tiki2 } from "../../styles";

interface Slide4Props {
	onNext: () => void;
}

export const Slide4 = ({ onNext }: Slide4Props) => {
	const dispatch = useAppDispatch();
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isLowHeight = useMediaQuery("(max-height: 600px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");

	const [step, setStep] = useState<number>(1);
	const [bottomPosition, setBottomPosition] = useState<number | string>(60);

	const scrollAndHighlight = async () => {
		if (step === 1) {
			const image1 = document.getElementById("slide-4-step-1-image");
			const imagePosition1 = image1?.getBoundingClientRect();
			if (imagePosition1) {
				const centerY2 = imagePosition1.bottom - (imagePosition1.height);
				setBottomPosition(centerY2);
			}
		}
		if (step === 2) {
			const image2 = document.getElementById("slide-4-step-2-image");
			const imagePosition2 = image2?.getBoundingClientRect();
			if (imagePosition2) {
				const centerY2 = imagePosition2.bottom - (imagePosition2.height);
				setBottomPosition(centerY2);
			}
		}
	};

	useEffect(() => {
		scrollAndHighlight();
	}, [window.innerWidth, window.innerHeight, isExtraSmall, isPortrait]);

	return (
		<>
			{step === 1
				? <BackgroundPaimentLarge
					id="slide-4-step-1-image"
					src={backgroundPayment1}
					alt="Payment tutorial image 1"
				/>
				: <BackgroundPaimentLarge
					id="slide-4-step-2-image"
					src={backgroundPayment2}
					alt="Payment tutorial image 2"
				/>
			}
			<Grid
				px={3}
				container
				maxWidth={380}
				position="fixed"
				left={isExtraSmall ? "-50%" : "-190px"}
				sx={{ transform: "translateX(50vw)" }}
				bottom={isLowHeight ? 60 : bottomPosition}
			>
				<Grid item xs={12} display="flex" justifyContent="flex-end" mr={step === 1 ? 7 : 9}>
					<Arrow src={arrowUp} alt="Arrow up" />
				</Grid>

				<Grid item xs={12} display="flex" justifyContent="right">
					<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
						{step === 1 ? "5/7" : "6/7"}
					</Text>
				</Grid>

				<Grid item xs={12}>
					<Background2>
						<Grid container mb={2}>
							<Grid item xs={2.5}>
								<Tiki2 src={tiki} alt="Tiki" bottom={step === 1 ? "2px" : "11px"} />
							</Grid>
							<Grid item xs={9.5} mb={step === 1 ? 2 : 0}>
								<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
									{step === 1
										? "Enter your payment details and save them for quicker future purchases."
										: "Confirm your purchase, and your items will appear instantly in your in-game inbox."
									}
								</Text>
							</Grid>
						</Grid>
						<Button className="next" onClick={() => step === 1 ? setStep(2) : onNext()}>Next</Button>
						<Button className="skip" variant="text" onClick={() => dispatch(onClosePopUp())}>Skip</Button>
					</Background2>
				</Grid>
			</Grid>
		</>
	)
};
