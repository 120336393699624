import classNames from "classnames";
import { CrownMarker, CrownMarkerHighlighted } from "assets/pois-bounty/svg";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";
import { Indicator } from "components/PoisBountyWidgetV2/lib/common";
import { useAppSelector } from "hooks";
import { StepMarker as StepMarkerStyled } from "./styles";
import { useStepContext } from "../Step/context";
import { selectPoisBountyV2State } from "redux/slices";

export const Marker = () => {
	const { target, last } = useStepContext();
	const { isPageVariant } = usePoisBountyContext();
	const { userLoyaltyPoints } = useAppSelector(selectPoisBountyV2State);
	const className = "crown-svg";
	const onTarget = target === userLoyaltyPoints;

	return (
		<StepMarkerStyled
			className={classNames(["step-marker", isPageVariant ? "page-variant" : "mega-menu-variant"])}
		>
			{onTarget && !last && (
				<Indicator mode="marker" userLoyaltyPoints={userLoyaltyPoints} />
			)}
			{target <= userLoyaltyPoints ? (
				<CrownMarkerHighlighted className={className} />
			) : (
				<CrownMarker className={className} />
			)}
		</StepMarkerStyled>
	);
};
