import { Grid, useMediaQuery } from "@mui/material";
import { onClosePopUp } from "redux/slices";
import { useAppDispatch, useImagePath } from "hooks";
import title from "assets/endless-offer/popup/title.png";
import palmTreesImg from "assets/endless-offer/popup/bg-palm-trees.png";
import headerImg from "assets/endless-offer/popup/bg.png";
import {
	HeaderTitle,
	Body,
	Button,
	Container,
	Header,
	Title,
	PalmTrees
} from "./styles";
import { featureTrackerAPI } from "redux/apis";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";

interface EndlessOfferRewardProps {}

export const EndlessOfferReward: React.FC<EndlessOfferRewardProps> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const headImageUrl = getImage(headerImg);
	const palmTreesImgUrl = getImage(palmTreesImg);
	const titleUrl = getImage(title);
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const scale = useCalculateScale(378, 380);

	const onClose = () => {
		dispatch(
			featureTrackerAPI.util.invalidateTags([
				{
					type: "featureTrackerTag",
					id: "endless-offer"
				}
			])
		);
		dispatch(onClosePopUp());
	};

	return (
		<Container scale={scale}>
			<CloseIconButton onClose={onClose} />
			<Header data-image={headImageUrl}>
				<PalmTrees data-image={palmTreesImgUrl} />
				<HeaderTitle src={titleUrl} />
			</Header>
			<Body>
				<Title>
					Your items are waiting in your in-game inbox to be collected!
				</Title>

				<Grid container spacing={isPortrait ? 2 : 1}>
					<Grid
						item
						xs={12}
						//xs={isMobile ? 6 : 12}
						//sm={isMobile ? 6 : 12}
						display="flex"
						justifyContent="center"
					>
						<Button
							variant="contained"
							themeColor="primary"
							sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							onClick={() => onClose()}
						>
							Continue Shopping
						</Button>
					</Grid>
					{/* {isMobile && (
						<Grid item xs={6} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="endless_offer_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							/>
						</Grid>
					)} */}
				</Grid>
			</Body>
		</Container>
	);
};
