// eslint-disable-next-line
export type ReportCallback = (eventName: string, eventValues: any) => void;

export enum LoginType {
	Facebook = "facebook",
	Scopely = "scopely",
	Auto = "auto",
}

export class LoginGoogleAnalyticsHelper {
	private reportCallback: ReportCallback;
	constructor(reportCallback: ReportCallback) {
		this.reportCallback = reportCallback;
	}

	public reportLoginOptionClick(loginType: LoginType, source: string) {
		this.reportCallback("login_option_click", {
			source: source,
			loginType: loginType
		});
	}

	public reportLoginOptionLoginStart(loginType: LoginType) {
		this.reportCallback("login_option_login_start", {
			loginType: loginType
		});
	}

	public reportLoginOptionLoginSuccess(loginType: LoginType) {
		this.reportCallback("login_option_login_success", {
			loginType: loginType
		});
	}

	public reportLoginOptionLoginFailure(loginType: LoginType, reason: string) {
		this.reportCallback("login_option_login_failure", {
			loginType: loginType,
			reason: reason
		});
	}

	public reportLoginStart(loginType: LoginType) {
		this.reportCallback("login_start", {
			loginType: loginType
		});
	}

	// eslint-disable-next-line
	public reportLoginSuccess(loginType: LoginType) {
		// this.reportCallback("login_success", {
		// 	loginType: loginType
		// });
	}

	public reportLoginFailure(loginType: LoginType) {
		this.reportCallback("login_failure", {
			loginType: loginType
		});
	}

	public reportLogoutClick(source: string) {
		this.reportCallback("logout_click", {
			source: source
		});
	}

	public reportLogout() {
		this.reportCallback("logout", {});
	}
}
