export type HttpMethod =
	| "get"
	| "GET"
	| "delete"
	| "DELETE"
	| "head"
	| "HEAD"
	| "options"
	| "OPTIONS"
	| "post"
	| "POST"
	| "put"
	| "PUT"
	| "patch"
	| "PATCH"
	| "purge"
	| "PURGE"
	| "link"
	| "LINK"
	| "unlink"
	| "UNLINK";

/* eslint-disable */
export default class HttpRequest {
	params: Record<string, any>;
	headers: Record<string, string>;
	url: string;
	method: HttpMethod;

	constructor(
		url: string,
		method: HttpMethod,
		params: Record<string, any> = {},
		headers: Record<string, string> = {}
	) {
		this.url = url;
		this.method = method;
		this.params = params;
		this.headers = headers;
	}
}

/* eslint-enable */
