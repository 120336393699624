import { ggItems } from "./items";
import classNames from "classnames";
import useBreakpoints from "hooks/useBreakpoints";
import { Carousel, Grid } from "../PB-common/learn-more-ftue";
import background from "assets/club-gifts-galore/bg-pop-up.png";
import {
	Background,
	Alignment,
	Body,
	Container,
	Head,
	Footer,
	Copy,
	Note
} from "./styles";

interface CGGMoreInfoProps {}

export const CGGMoreInfo: React.FC<CGGMoreInfoProps> = () => {
	const { isSmDown } = useBreakpoints();

	return (
		<Container className={classNames(isSmDown && "mobile")}>
			<Head className="head">
				<Background src={background} />
			</Head>
			<Alignment className="alignment">
				<Body>
					{isSmDown ? <Carousel items={ggItems} /> : <Grid items={ggItems} />}
				</Body>
				<Footer>
					<Copy className="copy">
						<Note className="note">Please Note:</Note>
						{
							"Only one Large Gift Box purchase on Web per Club member counts for each event."
						}
					</Copy>
				</Footer>
			</Alignment>
		</Container>
	);
};
