import { IShopReward, IShopRewardItem, IShopStore } from "contexts/WebStoreProvider/types";
import { Node } from "interfaces/drip-offer";

export const getAvailablePrizes = (nodes: Node[]) => {
	return nodes.filter(
		(n) => n.state === "unavailable" || n.state === "available"
	).length;
};

export const handleImageVariant = (url: string, isMobile: boolean) => {
	return url?.replace("{drip_offer_variant}", isMobile ? "small" : "large");
};

export const handleTicketVariant = (url: string, isClaimable: boolean) => {
	return url.replace(
		"{drip_offer_availability}",
		isClaimable ? "available" : "unavailable"
	);
};

export const handleTitleImageVariant = (url: string, isBought: boolean) => {
	return url?.replace("{drip_offer_type}", isBought ? "reward" : "offer");
};

export const getMissedDays = (nodes: Node[], localTime: string) => {
	let unclaimedDays = 0;
	const currentDate = new Date(localTime);

	nodes.forEach((node) => {
		const { state, claimedDate, claimAvailableDate } = node;

		if (state === "claimed") {
			const claimedDateObj = new Date(claimedDate);
			const claimAvailableDateObj = new Date(claimAvailableDate);
			const daysDiff = Math.ceil(
				(claimedDateObj.valueOf() - claimAvailableDateObj.valueOf()) /
					(1000 * 60 * 60 * 24)
			);
			if (daysDiff > 1) {
				unclaimedDays += daysDiff - 1;
			}
		} else if (state === "available") {
			const availableDate = new Date(claimAvailableDate);
			const daysDiff = Math.ceil(
				(currentDate.valueOf() - availableDate.valueOf()) /
					(1000 * 60 * 60 * 24)
			);
			if (daysDiff > 1) {
				unclaimedDays += daysDiff - 1;
			}
		}
	});

	return unclaimedDays;
};

export const getNodeItemData = (store: IShopStore, node: Node) => {
	const reward = store.rewards[node.rewardId];
	if (!reward) {
		console.error("reward", reward);
		return null;
	}
	const items: IShopRewardItem[] = Object.keys(reward.items).map((key) => {
		const { imageUrl = "" } = {
			...store.rewardTypes.filter((t: IShopReward) => t.id === key)[0]
		};
		const rewardItem = reward.items;
		return {
			id: key,
			value: rewardItem[key as keyof typeof rewardItem],
			imageUrl
		};
	});

	return items;
};
