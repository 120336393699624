import { Typography } from "@mui/material";
import {
	BonusFlagCenter,
	BonusFlagCenterRight,
	BonusFlagLeft,
	BonusFlagRight,
	WrapperCenter,
	WrapperLeft,
	WrapperRight,
	BonusFlagSpecial,
	BonusFlagRightSmall
} from "./styled";

const BonusFlagComponent = ({
	text,
	position = "center"
}: {
	text: string;
	position?: string;
}): JSX.Element | null => {
	if (!text) {
		return null;
	}

	const getText = () => (
		<Typography variant="body1" textAlign="center">
			{text}
		</Typography>
	);

	switch (position) {
		case "center":
			return (
				<WrapperCenter>
					<BonusFlagCenter>{getText()}</BonusFlagCenter>
				</WrapperCenter>
			);
		case "special":
			return (
				<WrapperCenter>
					<BonusFlagSpecial>{getText()}</BonusFlagSpecial>
				</WrapperCenter>
			);
		case "center right":
			return (
				<WrapperCenter>
					<BonusFlagCenterRight>{getText()}</BonusFlagCenterRight>
				</WrapperCenter>
			);
		case "left":
			return (
				<WrapperLeft>
					<BonusFlagLeft>{getText()}</BonusFlagLeft>
				</WrapperLeft>
			);
		case "right":
			return (
				<WrapperRight>
					<BonusFlagRight>{getText()}</BonusFlagRight>
				</WrapperRight>
			);
		case "right small":
			return (
				<WrapperRight>
					<BonusFlagRightSmall>{getText()}</BonusFlagRightSmall>
				</WrapperRight>
			);
		default:
			return null;
	}
};

export default BonusFlagComponent;
