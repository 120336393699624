import { PREFIX } from "./helper";
import service from "services/api";
import { SaleTag, WebStore } from "interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSaleTags = createAsyncThunk(
	`${PREFIX}/get-sale-tags`,
	async (): Promise<SaleTag[] | undefined> => {
		try {
			const { saleTags } = await service.Get<WebStore>("/rest/store");
			return saleTags;
		} catch (error) {
			console.log(error);
		}
	}
);
