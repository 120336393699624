import { SxProps, Theme } from "@mui/material";

export const appBarStyles: SxProps<Theme> = {
	zIndex: 1201, // at 1200 hidden below sidebar
	marginRight: { lg: "auto" },
	position: { lg: "fixed" },
	justifyContent: { xs: "flex-end", sm: "center" },
	height: { xs: 82, sm: "initial" },
	top: 0,
	left: 0,
	right: 0,
	background: {
		xs: "#00789E",
		sm: "linear-gradient(90deg, #00789E 22.73%, #00D7CA 78.32%)"
	},
	"@media(max-width: 600px)": {
		justifyContent: { xs: "center" },
	}
};
