import { PoisBountyUIVariant } from "interfaces";
import { SentUncollectedRewards, InProgress, Intro } from "./variants";

export const loadVariant = (variant: PoisBountyUIVariant): JSX.Element => {
	switch (variant) {
		case "intro":
			return <Intro />;
		case "in-progress":
			return <InProgress />;
		default:
			return <SentUncollectedRewards />;
	}
};
