import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDialogProps } from "interfaces";
import { RootState } from "redux/store";
import { PREFIX } from "./helper";
import { initialState } from "./initial-state";

export const dialogSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onOpenDialog: (state, action: PayloadAction<IDialogProps>) => {
			const { variant } = action.payload;
			state.variant = variant;
			state.props = action.payload;
		},
		onCloseDialog: (state) => {
			state.variant = undefined;
			state.props = undefined;
		}
	}
});

export const { onOpenDialog, onCloseDialog } = dialogSlice.actions;

export const selectDialogState = (state: RootState) => state.dialog;
export default dialogSlice.reducer;
export * from "./types";
