import { baseApi } from "../api";

export const debugActionApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		executeAction: builder.mutation({
			query: (body) => {
				const queryParams = new URLSearchParams();
				for (const key in body) {
					queryParams.append(key, body[key]);
				}
				return {
					url: `/rest/debug/dbg-actions/execute?${queryParams.toString()}`,
					method: "POST"
					//body
				};
			}
		})
	})
});

export const { useExecuteActionMutation } = debugActionApi;
