import { Chip, Box } from "@mui/material";
import styled from "styled-components";

export const StyledChip = styled(Chip)({
	width: "auto",
	display: "flex",
	alignItems: "center",
	maxWidth: "none",
	fontFamily: "Roboto, Helvetica, Arial, sans-serif",
	fontWeight: "405",
	fontSize: "0.85rem",
	backgroundColor: "rgb(0 0 0 / 50%)",
	borderRadius: "25px",
	padding: "18px 5px 18px 5px",

	"&, .MuiChip-icon": {
		color: "white"
	},
	"> svg, > img": {
		width: 18
	},
	"@media(max-width: 600px)": {
		padding: "0 0 0 5px",
		fontSize: 12,
		"> svg, > img": {
			width: 12
		}
	}
});

export const UserProfileImage = styled(Box)({
	display: "flex",
	justifyContent: "center",
	"svg, img": { width: "37px", height: "37px" }
});

export const AppBarUserBox = styled(Box)({
	width: 62,
	right: 0,
	position: "relative",
	display: "flex",
	alignItems: "center",
	padding: "0",
	margin: "0rem",
	justifyContent: "center",
	"& img": {
		borderRadius: "30px"
	}
});
