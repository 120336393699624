import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)`
	background-size: cover;
	background-position: center;
	position: relative;
	height: 100%;
	background-image: unset;
	display: grid;
	grid-template-rows: 190px auto;
	background-color: #0184a4;

	&.portrait {
		background-position: top;
		background-size: cover;
	}

	.info-icon:hover {
		circle {
			fill: #0184a4;
		}
	}
`;
/* eslint-disable */
export const Head = styled(Box)`
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-image: url(${(props: any) => props["data-image"]});
`;
/* eslint-enable */
export const Body = styled(Box)`
	padding: 0 1rem;
`;
