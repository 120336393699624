import { getVariantImage } from "utils/getVariantImage";
type Values = {
	slidesPerView: number;
	spaceBetween?: number;
};

type breakpoint = {
	[key: number]: Values;
};

export const boxesBreakpoints: breakpoint = {
	733: {
		slidesPerView: 2.2
	},
	900: {
		slidesPerView: 2.5
	},
	1000: {
		slidesPerView: 3
	}
};

export const getBackgroundUrl = (
	url: string,
	key: string,
	isPortrait: boolean
) => {
	return getVariantImage(url, `{${key}}`, isPortrait ? "small" : "large");
};
