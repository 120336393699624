import styled from "styled-components";
import { Box } from "@mui/material";
import { isIPad13 } from "react-device-detect";

export const StyledStepTarget = styled(Box)`
	background: #ffffff;
	border: 1.61176px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0px 3.22353px 3.22353px rgba(0, 0, 0, 0.25);
	border-radius: 8.05882px;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	padding: 0 8px 0 4px;
	font-weight: bold;
	transform: translateY(${isIPad13 ? "5px" : 0});
	svg {
		height: 10px;
	}
`;
