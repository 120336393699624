import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import { Trigger } from "interfaces";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { onDIPtrigger, selectDipState, sendDipAction } from "redux/slices";
import { dispatchLogOut } from "../helper";
import { PATHS_DIPS_TRIGGERS } from "./helper";

export const useListeners = (): void => {
	const dispatch = useAppDispatch();
	const { handleErrorResponse, logout } = useWebStoreContext();
	const { currentDip, claimRewardProcess } = useAppSelector(selectDipState);
	const { reportEvent } = useGoogleAnalyticsContext();
	const { pathname } = useLocation();

	/**🔦 Handling errors */
	useEffect(() => {
		const { error } = claimRewardProcess;
		if (error) {
			handleErrorResponse("Request Failed");
		}
	}, [claimRewardProcess]);

	/**🔦 sendDipAction when the DIP component init and/or change */
	useEffect(() => {
		if (!currentDip) {
			return;
		}
		document.querySelector("body")?.classList.add("stop-scroll");

		const { buttons: data } = currentDip;

		dispatch(sendDipAction({ actionType: "view" }));
		reportEvent("dialog_shown", {
			source: "dips_dialog",
			dip_id: currentDip.dip.id,
			page_location: pathname,
			offers: JSON.stringify(
				data?.map((offer) => {
					return {
						loyalty_offer_id: offer.loyaltyOfferId,
						reward_id: offer.rewardId,
						offer_id: offer.offerId
					};
				})
			)
		});

		return () => {
			if (dispatchLogOut(currentDip)) {
				logout();
			}
			document.querySelector("body")?.classList.remove("stop-scroll");
		};
	}, [currentDip?.dip?.id]);

	useEffect(() => {
		const trigger: Trigger | undefined = PATHS_DIPS_TRIGGERS[pathname];
		if (trigger) {
			dispatch(onDIPtrigger(trigger));
		}
	}, [pathname]);
};
