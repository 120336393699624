/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import { getDebugLogger } from "../utils/debugLogger";
import service from "services/api";
import { FeatureFlagsResponse } from "interfaces";
import { FeaturesFlags } from "helpers";

const debugColor = "Gold";
const debug = getDebugLogger({
	isEnabled: !false,
	color: debugColor
});
export interface IProps {
	backendUri?: string;
	cdnPrefix?: string;
	children: React.ReactNode;
}
export interface IEnvContext {
	backendUri?: string;
	cdnPrefix?: string;
	isFeatureFlagEnabled(ffName: string): boolean;
	refetchFeatureFlags: () => void;
	isFeatureFlagsRefetching: boolean;
}
const defaultBackendUri = "https://tripeaks-webstore-service.tripeaksapp.com";

export const EnvContext = createContext<IEnvContext>({
	backendUri: defaultBackendUri,
	// eslint-disable-next-line
	isFeatureFlagEnabled(ffName: string): boolean {
		return false;
	},
	refetchFeatureFlags: () => {},
	isFeatureFlagsRefetching: false
});

////////////////////////////////////////////////////////////////////////////////////////
/// LIVEOPS FEATURE FLAGS GO HERE
////////////////////////////////////////////////////////////////////////////////////////

const setFFMap = (
	ffMap: Map<string, boolean>,
	ffHandle: string,
	ffLiveOpsEnabled: boolean | undefined
) => {
	// check for local dev env vars if enabled, otherwise use liveops
	const getFFValue = () => {
		const ffConstent = `REACT_APP_FF_${ffHandle}`;
		if (
			process.env?.NODE_ENV === "development" &&
			process.env?.REACT_APP_FF_USE_LOCAL_DEV === "true"
		) {
			if (typeof process.env[ffConstent] !== "undefined") {
				return process.env[ffConstent] === "true" ? true : false;
			}
		}
		return ffLiveOpsEnabled === undefined ? true : ffLiveOpsEnabled;
	};
	const ffName = FeaturesFlags[ffHandle as keyof typeof FeaturesFlags];
	const ffValue = getFFValue();
	ffMap.set(ffName, ffValue);
};

const loadFeatureFlagsAsync = async () => {
	const ffMap = new Map<string, boolean>();

	const response = await service.Get<FeatureFlagsResponse>(
		"/rest/featureFlag/v2"
	);

	debug.logFunction({
		iconText: "response",
		message: ["\n   Feature Flags response:", JSON.stringify(response)]
	});

	const {
		scopely_login_enabled,
		club_shop_enabled,
		club_shop_free_gift_enabled,
		merch_shop_enabled,
		news_enabled,
		daily_bonus_enabled,
		winners_tab_enabled,
		waterfall_sales_enabled,
		club_gift_bonus_enabled,
		dips_enabled,
		pois_bounty_enabled,
		pois_bounty_v2_enabled,
		bogo_enabled,
		sticky_dips_enabled,
		bogo_validation_enabled,
		sale_tags_enabled,
		endless_offer_enabled,
		first_purchase_bonus_enabled,
		drip_offer_enabled,
		bouncy_chest_enabled,
		ui_v2_enabled
	} = response.featureFlags;

	setFFMap(ffMap, "SCOPELY_LOGIN", scopely_login_enabled);
	setFFMap(ffMap, "CLUB_SHOP", club_shop_enabled);
	setFFMap(ffMap, "MERCH_SHOP", merch_shop_enabled);
	setFFMap(ffMap, "CLUB_SHOP_FREE_GIFT", club_shop_free_gift_enabled);
	setFFMap(ffMap, "CLUB_GIFT_BONUS", club_gift_bonus_enabled);
	setFFMap(ffMap, "NEWS", news_enabled);
	setFFMap(ffMap, "WINNERS", winners_tab_enabled);
	setFFMap(ffMap, "WATERFALL_SALES", waterfall_sales_enabled);
	setFFMap(ffMap, "STICKY_DIPS", sticky_dips_enabled);
	setFFMap(ffMap, "DIPS", dips_enabled);
	setFFMap(ffMap, "DAILY_BONUS", daily_bonus_enabled);
	setFFMap(ffMap, "POIS_BOUNTY", pois_bounty_enabled);
	setFFMap(ffMap, "POIS_BOUNTY_V2", pois_bounty_v2_enabled);
	setFFMap(ffMap, "STICKY_TAG", sale_tags_enabled);
	setFFMap(ffMap, "BOGO", bogo_enabled);
	setFFMap(ffMap, "BOGO_VALIDATION", bogo_validation_enabled);
	setFFMap(ffMap, "ENDLESS_OFFER", endless_offer_enabled);
	setFFMap(ffMap, "FIRST_PURCHASE_BONUS", first_purchase_bonus_enabled);
	setFFMap(ffMap, "DRIP_OFFER", drip_offer_enabled);
	setFFMap(ffMap, "BOUNCY_CHEST", bouncy_chest_enabled);
	setFFMap(ffMap, "UI_V2", ui_v2_enabled);

	debug.logFunction({
		iconText: "EnvProvider",
		message: ["\n   Feature Flags:", JSON.stringify(Array.from(ffMap))]
	});

	return ffMap;
};

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

function EnvProvider({ children, backendUri, cdnPrefix }: IProps) {
	debug.logFunction({
		iconText: "EnvProvider",
		message: ["\n   backendUri:", backendUri]
	});

	const [flags, setFlags] = useState<Map<string, boolean>>();
	const [isFeatureFlagsRefetching, setIsFeatureFlagRefetching] =
		useState<boolean>(false);

	const getFlags = () => {
		loadFeatureFlagsAsync().then((result) => {
			setFlags(result);
		});
	};

	useEffect(() => {
		getFlags();
		setIsFeatureFlagRefetching(false);
	}, [isFeatureFlagsRefetching]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			getFlags();
		}, 30000);
		return () => clearInterval(intervalId);
	}, []);

	if (!flags) {
		return <></>;
	}

	const value: IEnvContext = {
		isFeatureFlagEnabled(ffName: string): boolean {
			return flags.get(ffName) === true;
		},
		refetchFeatureFlags() {
			setIsFeatureFlagRefetching(true);
		},
		isFeatureFlagsRefetching
	};
	if (backendUri) {
		value.backendUri = backendUri;
	}
	value.cdnPrefix = cdnPrefix;

	return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
}

export const useEnvContext = () => {
	const context = useContext(EnvContext);
	if (context === undefined) {
		throw new Error("EnvContext was used outside of its Provider");
	}
	return context;
};

export default EnvProvider;
