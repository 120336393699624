import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch } from "hooks";
import { useEffect } from "react";
import { getPoisBountyV2EventTrackers } from "redux/slices";

export const useInitialActions = (): void => {
	const dispatch = useAppDispatch();
	const { userTracker } = useWebStoreContext();

	useEffect(() => {
		if (userTracker) {
			dispatch(getPoisBountyV2EventTrackers());
		}
	}, [userTracker]);
};
