import { Dispatch, SetStateAction } from "react";
import { IDebugLogger } from "../../../utils/debugLogger";

const getCheckLoginState = ({
	setFbAccessToken,
	debug
}: {
	setFbAccessToken: Dispatch<SetStateAction<string | null>>;
	debug: IDebugLogger;
}) => {
	const checkLoginState = async () => {
		try {
			!!window?.FB &&
				window.FB.getLoginStatus((response: fb.StatusResponse) => {
					if (response.status === "connected") {
						setFbAccessToken(response.authResponse.accessToken);
					} else {
						setFbAccessToken(null);
					}
					debug.log({
						iconText: "checkLoginState:",
						message: ["accessToken:\n", response?.authResponse?.accessToken]
					});
				}, true);
		} catch (err) {
			console.error(err);
		}
	};

	return checkLoginState;
};

export default getCheckLoginState;
