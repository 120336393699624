import { useAppSelector } from "hooks";
import { PopUpVariant } from "interfaces";
import { selectPoisBountyState } from "redux/slices";

type EventReport = {
	name: string;
	opts: { [key: string]: string };
};

export const useReportEventOnClose = (
	variant: PopUpVariant | undefined
): EventReport | undefined => {
	const { tracker } = useAppSelector(selectPoisBountyState).data;
	const eventReport = {} as EventReport;

	if (typeof variant === "undefined") {
		return;
	}

	switch (variant) {
		case "pb-reward-collected":
			eventReport.name = "reward_claimed_popup_closed";
			eventReport.opts = {
				pois_bounty_id: tracker.eventId,
				source: "pois_bounty_menu"
			};
			break;

		default:
			console.warn(`${variant} not handled`);
			return;
	}

	return eventReport;
};
