import { useAppSelector } from "hooks";
import { RootState } from "redux/store";
import { Dot, IconContainer } from "./styles";
import { setOpen } from "redux/slices/mega-menu";
import { useShowHamburgerDotCooldown } from "./hooks";
import { useSelector, useDispatch } from "react-redux";
import { selectStickySaleTagState } from "redux/slices";
import { ReactComponent as MenuIcon } from "assets/MenuIconHamb.svg";
import { ReactComponent as CloseIcon } from "assets/MenuIconClose.svg";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useCallback } from "react";
import { useWebStoreContext } from "contexts/WebStoreProvider";

export const MenuButton = () => {
	const { nowTime } = useWebStoreContext();
	const show = useShowHamburgerDotCooldown();
	const { showMegaMenuIndicator, saleTags } = useAppSelector(
		selectStickySaleTagState
	);
	const isOpen = useSelector((state: RootState) => state.megaMenu.isOpen);
	const dispatch = useDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();

	const toggleDrawer = (state: boolean) => () => {
		dispatch(setOpen(state));
		reportToGoogle(state);
	};

	const activeTags: string[] = [];
	saleTags.forEach((tag) => {
		if (tag.showMegaMenuTag) {
			if (nowTime?.isAfter(tag.startTime) && nowTime.isBefore(tag.endTime)) {
				activeTags.push(tag.megaMenuTagSection);
			}
		}
	});

	const reportToGoogle = useCallback(
		(state: boolean) => {
			if (state) {
				reportEvent("mega_menu_open_click", {
					source: "mega_menu",
					hamburger_tag: showMegaMenuIndicator,
					tags: activeTags
				});
			} else {
				reportEvent("mega_menu_close_click", { source: "mega_menu" });
			}

			if (showMegaMenuIndicator) {
				reportEvent("spawn_hamburger_tag", {
					source: "hamburger_tag"
				});
			}
		},
		[showMegaMenuIndicator]
	);

	return (
		<IconContainer onClick={toggleDrawer(!isOpen)}>
			{!isOpen ? <MenuIcon /> : <CloseIcon />}
			{showMegaMenuIndicator && show && <Dot />}
		</IconContainer>
	);
};
