import { DisplayItem, RewardType } from "components/DIP/types";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { selectWebStoreState } from "redux/slices";

const useRewardsById = (rewardId: string): DisplayItem["rewards"] => {
	const { rewards, rewardTypes } = useAppSelector(selectWebStoreState).store;
	const [displayRewards, setDisplayRewards] = useState<DisplayItem["rewards"]>(
		[]
	);

	useEffect(() => {
		if (!rewards) {
			return;
		}

		const items = rewards[rewardId]?.items || {};

		const newDisplayRewards: DisplayItem["rewards"] = [];
		Object.entries(items).forEach(([rewardItemId, units]) => {
			const rewardType: RewardType | undefined = rewardTypes.find(
				(type: RewardType) => type.id === rewardItemId
			);
			if (rewardType) {
				const { imageUrl, overrideImageUrl, pluralLabel, singularLabel } =
					rewardType;
				newDisplayRewards.push({
					id: rewardItemId,
					imageUrl,
					overrideImageUrl,
					description: units.toLocaleString("en-US"),
					singularLabel,
					pluralLabel
				});
			}
		});

		setDisplayRewards(newDisplayRewards);
	}, [rewardId, rewards, rewardTypes]);

	return displayRewards;
};

export default useRewardsById;
