import { Typography } from "@mui/material";
import Logo from "components/Logo";
import { Link } from "react-router-dom";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useMegaMenuRedux } from "redux/slices/mega-menu/hooks/useMegaMenuRedux";
import { links } from "helpers";

export const AppBarLogo = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { toggleDrawer } = useMegaMenuRedux();

	const handleLogoClick = () => {
		window.scrollTo(0, 0);
		toggleDrawer(false);
		reportEvent("logo_click", { source: "mega_menu" });
	};

	return (
		<Typography
			variant="h6"
			noWrap
			component="h1"
			sx={{
				flexGrow: 1,
				display: { lg: "flex" },
				justifyContent: { lg: "left" },
				background: { lg: "none" }
			}}
		>
			<Link to={links.home.path} onClick={handleLogoClick}>
				<Logo />
			</Link>
		</Typography>
	);
};
