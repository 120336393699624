/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { getFeaturedCard } from "./styled";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { IShopRewardItem, IShopReward } from "contexts/WebStoreProvider/types";
import BonusFlag from "components/BonusFlag";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useAppDispatch } from "hooks";
import {
	CardLayout,
	TitleContent,
	RewardItems,
	TitleContentProps
} from "./lib";
import { IFeaturedOfferCardProps } from "./types";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";
import { offersApi } from "redux/apis";
import { sortRewardsWithSpecificIdAtEnd } from "helpers/sort";
import CountdownV2 from "components/CountdownV2";

const FeaturedOfferCard = ({
	data: {
		id: offerId,
		rewardId,
		price,
		bannerText,
		loyaltyPointReward,
		timer,
		endTime,
		// using `nowTime` for Debug Time Travelling
		backgroundImageUrl,
		titleImageUrl,
		priority,
		campaignGroup,
		onlyNonPayers,
		purchaseLimit
	},
	sectionName
}: IFeaturedOfferCardProps) => {
	const {
		store,
		wsStoreBuy,
		authToken,
		currentOffer,
		userTracker,
		openLoginToContinueDialog,
		setCurrentOffer,
		handleRefresh,
		nowTime,
		setActiveItemToBeClicked,
		suppressOrderConfirmation,
		postUserMetadata
	} = useWebStoreContext();
	const [rewardItems, setRewardItems] = useState<IShopRewardItem[]>([]);
	const dispatch = useAppDispatch();
	const { reportPurchaseEvent, reportEvent } = useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const [isCurrentOffer, setIsCurrentOffer] = useState<boolean>(false);
	const buyButtonRef = useRef(null);
	/* eslint-disable-next-line */
	const [FeaturedCard] = useState<any>(getFeaturedCard(backgroundImageUrl));

	const uniqueKey = offerId;

	const isTimerEnabled = timer ? timer !== "false" : false;

	const resetCurrentOffer = () => {
		setIsCurrentOffer(false);
		setCurrentOffer(null);
	};

	const onClickBuy = async () => {
		setCurrentOffer(buyButtonRef.current);

		if (authToken?.value) {
			const invoice = await wsStoreBuy({ offerId });
			if (invoice && typeof invoice !== "number") {
				openDialog(WsDialogs.XSOLLA_PURCHASE, {
					xsollaToken: invoice.xsollaToken,
					xsollaHost: invoice.xsollaHost,
					section: "shop",
					source: "featured_offer",
					onPurchaseStarted: (data) => {
						reportEvent("purchase_started", {
							tuid: userTracker?.id as string,
							offer_id: offerId,
							reward_id: rewardId,
							priority: priority,
							price_in_cents: Math.round(price * 100),
							campaign_group: campaignGroup,
							section_name: sectionName,
							only_non_payers: onlyNonPayers,
							//event_time_client: eventTimeClient.toISO(),
							//minutes_left: minutesLeft,
							invoice_id: invoice?.id,
							paystation_id: data?.payStation
						});
					},
					onPurchaseSuccess: () => {
						reportEvent("purchase_success", {
							invoice_id: invoice?.id
						});
					},
					cbClose: () => {
						invalidateFeaturedOffers();
						resetCurrentOffer();
						handleRefresh();
						reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
					},
					suppressOrderConfirmation,
					postUserMetadata,
					showClose: true
				});
			} else {
				resetCurrentOffer();
			}

			//NOTE:bfloyd error cases already handled for undefined/null invoice
		} else {
			setActiveItemToBeClicked({
				type: "store_offer",
				offerId,
				onlyNonPayers,
				purchaseLimit
			});
			// claimMyReward();
			setActiveItemToBeClicked({
				type: "store_offer",
				offerId,
				onlyNonPayers,
				purchaseLimit
			});
			openLoginToContinueDialog();
		}
	};

	const invalidateFeaturedOffers = () => {
		dispatch(
			offersApi.util.invalidateTags([
				{
					type: "offersTag",
					id: "featured"
				}
			])
		);
	};

	const handleOnEnd = () => {
		invalidateFeaturedOffers();
	};

	useEffect(() => {
		if (currentOffer === buyButtonRef.current) {
			setIsCurrentOffer(true);
		} else {
			setIsCurrentOffer(false);
		}
	}, [currentOffer]);

	useEffect(() => {
		if (!store) {
			return;
		}
		// get reward
		const reward = rewardId && store.rewards[rewardId];
		if (!reward) {
			console.error("reward", reward);
			return;
		}
		// flatten reward items
		const items: IShopRewardItem[] = Object.keys(reward.items).map((key) => {
			const { imageUrl = "" } = {
				...store.rewardTypes.filter((t: IShopReward) => t.id === key)[0]
			};
			const rewardItem = reward.items;
			return {
				id: key,
				text: key,
				value: rewardItem[key as keyof typeof rewardItem],
				imageUrl
			};
		});
		setRewardItems(items);
		//setFeaturedOfferCard(getFeaturedCard(backgroundImageUrl));
	}, [store]);

	const titleContentProps: TitleContentProps = {
		titleImageUrl,
		currentOffer,
		isCurrentOffer,
		offerId,
		buyButtonRef,
		onClickBuy,
		ctaText: `${price.toString()} USD`,
		loyaltyPointReward
	};

	return (
		<FeaturedCard data-testid="featuredCard" key={uniqueKey}>
			{isTimerEnabled && endTime && (
				<CountdownV2
					text="Offer ends in:"
					startTime={nowTime}
					endTime={endTime}
					onEnd={handleOnEnd}
				/>
			)}
			<BonusFlag text={bannerText} position="right" />
			<CardLayout
				itemsLength={rewardItems.length}
				titleContent={<TitleContent {...titleContentProps} />}
				itemsContent={
					<RewardItems
						items={sortRewardsWithSpecificIdAtEnd(rewardItems, "random")}
					/>
				}
			/>
		</FeaturedCard>
	);
};

FeaturedOfferCard.displayName = "FeaturedOfferCard";

export { FeaturedOfferCard };
