import ThemeButton from "components/Buttons/ThemeButton";
import { styled } from "@mui/material";

export const Container = styled("div")`
	width: 340px;
	background-color: #fff;
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
	@media (max-width: 345px) {
		width: 95%;
	}
`;
/* eslint-disable */
export const Header = styled("div")`
	height: 130px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: cover;
	background-size: cover;
	background-image: url(${(props: any) => props["data-image"]});
	position: relative;
`;
/* eslint-enable */
export const Avatar = styled("img")`
	width: 85px;
	object-fit: contain;
	position: absolute;
	bottom: 0.5rem;
	right: 15%;
`;

export const Body = styled("div")`
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

export const Title = styled("span")`
	font-family: "Baloo";
	font-size: 20px;
	text-align: center;
`;

export const Button = styled(ThemeButton)`
	padding: 0.5rem;
	width: 80%;
`;
