import { DipButton, ShopSection } from "interfaces";
const shopSections = {
	purchase: "OPEN SHOP",
	loyalty: "OPEN LOYALTY POINTS",
	clubShop: "OPEN CLUB SHOP",
	clubFreeGift: "TO CLUB FREE GIFT",
	dailyGift: "TO DAILY GIFT",
	freeDailyGifts: "TO FREE DAILY GIFTS",
	poisBounty: "TO POI'S BOUNTY",
	news: "OPEN NEWS",
	merchShop: "TO MERCH SHOP"
};

export const getButtonLabel = (action: DipButton): string => {
	const { actionType, shopSection } = action;

	switch (actionType) {
		case "deeplink":
			return "OPEN";
		case "grantReward":
			return "CLAIM";
		case "externalUrl":
			return "OPEN";
		case "shopSection":
			return shopSections[shopSection as ShopSection];
		case "closeDip":
			return "CLOSE";
		default:
			return "";
	}
};
