/* eslint-disable @typescript-eslint/no-unused-vars */
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BoxCardMobile } from "./BoxCardMobile";
//import { IconContainer, SectionTitle } from "../styles";
import { boxesBreakpoints } from "./helpers";
import { ReactComponent as BoxesIcon } from "assets/GiftBoxesIcon.svg";
import { loop } from "helpers";
import { BoxCardSkeleton } from "./BoxCardMobile/skeleton";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useGetOffersQuery } from "redux/apis";
import { IShopPurchaseOfferClub } from "contexts/WebStoreProvider/types";
import { orderBy } from "lodash";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import { selectGiftsGaloreState } from "redux/slices";
import { useMediaQuery } from "@mui/material";
import { BannerCard } from "./BannerCard";
import { BoxCard } from "./BoxCard";
import { SectionIconContainer, SectionTitle } from "styles/general_styles";

export const SwiperStyled = styled(Swiper)`
	padding-left: 5px;
	/* padding-top: 13px;
	padding-left: 5px;
	@media (max-width: 1200px) {
		width: 89vw;
	} */
`;

export const GiftBoxesSection = () => {
	const { store, authToken } = useWebStoreContext();
	const isMobile = useMediaQuery("(max-width: 732px)");
	const { tracker, trackerLoading } = useAppSelector(selectGiftsGaloreState);
	const { data, isLoading, isFetching, refetch } = useGetOffersQuery(
		{
			offerType: "clubGift",
			isAuthenticated: !!authToken?.value
		},
		{ pollingInterval: 600000 }
	);
	const orderedOfferes = orderBy(data?.offers, ["priority"], ["asc"]);

	if (isLoading /*|| isFetching*/ || !store || trackerLoading) {
		if (!isMobile) {
			return (
				<SwiperStyled breakpoints={boxesBreakpoints}>
					{loop(3, (i: number) => (
						<SwiperSlide key={`box-${i}`}>
							<BoxCardSkeleton />
						</SwiperSlide>
					))}
				</SwiperStyled>
			);
		}

		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					//alignItems: "center",
					width: "auto"
				}}
			>
				{loop(3, (i: number) => (
					<BoxCardSkeleton key={`box-${i}`} />
				))}
			</div>
		);
	}

	return (
		<Fragment>
			{!isLoading &&
				//!isFetching &&
				!trackerLoading &&
				!!store &&
				!!data.offers &&
				data.offers.length > 0 &&
				!tracker && <BannerCard offer={data?.offers[0]} refetch={refetch} />}
			<SectionTitle id="gift-boxes">
				<SectionIconContainer>
					<BoxesIcon width="21px" height="40px" fill="#073670" />
				</SectionIconContainer>
				<div>GIFT BOXES</div>
			</SectionTitle>

			{isMobile ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%"
					}}
				>
					{orderedOfferes.map((offer: IShopPurchaseOfferClub) => (
						<BoxCardMobile
							key={offer.id}
							offer={offer}
							gift={store?.clubGifts?.find((gift) => gift.id === offer.id)}
						/>
					))}
				</div>
			) : (
				<SwiperStyled breakpoints={boxesBreakpoints} spaceBetween={16}>
					{orderedOfferes.map((offer: IShopPurchaseOfferClub) => (
						<SwiperSlide key={offer.id}>
							<BoxCardMobile
								key={offer.id}
								offer={offer}
								gift={store?.clubGifts?.find((gift) => gift.id === offer.id)}
							/>
						</SwiperSlide>
					))}
				</SwiperStyled>
			)}
		</Fragment>
	);
};
