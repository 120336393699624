import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const ResultConfirmationDialog = ({
	title,
	show,
	setConfirmationResultDialog
}: {
	title: string;
	show: boolean;
	setConfirmationResultDialog: (close: boolean) => void;
}) => {
	const handleClose = () => {
		setConfirmationResultDialog(false);
	};
	return (
		<div>
			<Dialog fullWidth open={show} onClose={handleClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>Completed!</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Ok</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ResultConfirmationDialog;
