/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState, useEffect } from "react";
import Button from "components/Buttons/ThemeButton";
import BonusFlag from "components/BonusFlag";
import {
	FinalMessage,
	Reward,
	RewardsContainer,
	SubTitle,
	ThanksMessage,
	Title,
	CardContainer,
	DataContainer,
	Footer,
	ImageContainer,
	LoyaltyPoints,
	Number,
	CrossOutValue,
	CrossOutLine,
	StarBurst,
	RewardIcon
} from "./styles";
import { ReactComponent as CrownIcon } from "assets/Crown.svg";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	getGiftsGalore,
	selectGiftsGaloreState,
	selectPoisBountyV2State
} from "redux/slices";
import {
	IShopPurchaseOfferClub,
	IClubGift,
	IShopReward
} from "contexts/WebStoreProvider/types";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { GiftsGaloreTracker, WsDialogs } from "interfaces";
import { Typography, useMediaQuery } from "@mui/material";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { setProviderValues } from "pages/ClubShop/ClubEventSection/helpers";
import starBurst from "assets/club-gifts-galore/start-burst-reward.png";
import { getBackgroundUrl } from "../helpers";

interface Props {
	offer: IShopPurchaseOfferClub;
	gift?: IClubGift;
}

export const BoxCardMobile: React.FC<Props> = ({ offer, gift }) => {
	const [eventIsCompleted, setEventIsCompleted] = useState<boolean>(false);
	const { reportEvent } = useGoogleAnalyticsContext();
	const { store } = useWebStoreContext();
	const { tracker } = useAppSelector(selectGiftsGaloreState);
	const dispatch = useAppDispatch();
	const { openDialog } = useDialogModalRedux();
	const {
		wsStoreBuy,
		authToken,
		handleRefresh,
		openLoginToContinueDialog,
		suppressOrderConfirmation,
		postUserMetadata
	} = useWebStoreContext();
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const { userTracker } = useWebStoreContext();
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;
	const isMobile = useMediaQuery("(max-width: 732px)");
	const orderedRewards = [...(gift?.rewards ?? [])].reverse();

	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);

	useEffect(() => {
		if (tracker) {
			const { eventIsCompleted } = setProviderValues(
				tracker as GiftsGaloreTracker
			);
			setEventIsCompleted(eventIsCompleted);
		}
	}, [tracker]);

	const getRewardInfo = useCallback(
		(rewardId: string) => {
			if (store) {
				const items = store?.rewards[rewardId].items;
				return {
					value: Object.values(items)[0],
					imageUrl: store.rewardTypes.find(
						(t: IShopReward) => t.id === Object.keys(items)[0]
					).imageUrl
				};
			}
		},
		[gift]
	);

	const validateToken = () => {
		if (!authToken?.value) {
			openLoginToContinueDialog();
			return false;
		}
		return true;
	};

	const onClickBuy = async () => {
		if (!validateToken()) {
			return;
		}

		const invoice = await wsStoreBuy({ offerId: offer.id });
		if (invoice) {
			openDialog(WsDialogs.XSOLLA_PURCHASE, {
				xsollaToken: invoice.xsollaToken,
				xsollaHost: invoice.xsollaHost,
				section: "club_shop",
				source: "gift_boxes",
				cbClose: () => {
					dispatch(getGiftsGalore());
					reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
					handleRefresh();
				},
				suppressOrderConfirmation,
				postUserMetadata
			});
		}
	};

	const isEventBannerFlagEnabled = () => {
		return (
			tracker?.isUserQualifiedToContribute &&
			!eventIsCompleted &&
			offer.priority === 1
		);
	};

	return (
		<CardContainer
			eventIsCompleted={eventIsCompleted}
			priority={offer.priority}
			isUserQualifiedToContribute={tracker?.isUserQualifiedToContribute}
			color="#FFF"
		>
			<ImageContainer
				image={
					isEventBannerFlagEnabled()
						? getBackgroundUrl(
								tracker?.qualifiedForPurchaseChestImageUrl || "",
								"club_gifts_galore_variant",
								isMobile
						  )
						: getBackgroundUrl(
								gift?.chestImageUrl || "",
								"club_box_variant",
								isMobile
						  )
				}
			>
				<Title>
					{offer.description.split("+").map((text, index) => (
						<span key={index} style={{ whiteSpace: "nowrap" }}>
							{text}
							{offer.description.split("+").length - 1 > index && (
								<>
									<span>&nbsp;+</span>
									<br />
								</>
							)}
						</span>
					))}
				</Title>
			</ImageContainer>

			<BonusFlag
				position={"right small"}
				text={isEventBannerFlagEnabled() ? "CLUB EVENT DEAL" : offer.bannerText}
			/>

			<DataContainer>
				<SubTitle>All members get:</SubTitle>
				<SubTitle>You also get:</SubTitle>
				<RewardsContainer>
					<Reward>
						<RewardIcon
							src={gift?.randomRewardImageUrl}
							className={
								Array.isArray(orderedRewards) && orderedRewards?.length > 1
									? "several-items"
									: ""
							}
						/>

						<Number>x{gift?.randomRewardCount}</Number>
						{offer?.rewardCrossout && (
							<CrossOutValue className="reward">
								<CrossOutLine className="reward" />
								{gift?.randomRewardCrossout && (
									<>x{gift?.randomRewardCrossout}</>
								)}
							</CrossOutValue>
						)}
						{gift?.highlightRandomReward && (
							<StarBurst src={starBurst}></StarBurst>
						)}
					</Reward>
					{orderedRewards?.map((reward, index) => (
						// <CardItemContainer key={reward.id}>
						<Reward key={reward.id}>
							<RewardIcon
								src={getRewardInfo(reward.id)?.imageUrl}
								className={
									Array.isArray(orderedRewards) && orderedRewards?.length > 1
										? "several-items"
										: ""
								}
							/>
							<Number>x{getRewardInfo(reward.id)?.value}</Number>
							{offer?.rewardCrossout && (
								<CrossOutValue className="reward">
									<CrossOutLine className="reward" />
									{offer?.rewardCrossout && <>x{offer?.rewardCrossout}</>}
								</CrossOutValue>
							)}
							{((gift?.highlightReward && index === 1) ||
								(gift?.highlightReward2 && index === 0)) && (
								<StarBurst src={starBurst}></StarBurst>
							)}
						</Reward>
					))}
				</RewardsContainer>

				<RewardsContainer>
					<Reward>
						<RewardIcon
							src={gift?.thankYouCoinsImageUrl}
							style={{ maxHeight: "60px" }}
						/>
						<ThanksMessage>
							{gift?.thankYouCoinsCrossout && (
								<CrossOutValue className="thankyoucoins">
									{gift?.thankYouCoinsCrossout?.toLocaleString("en-US")}
								</CrossOutValue>
							)}
							{gift?.thankYouCoins?.toLocaleString("en-US")} free Coins for
							every THANKS received!
						</ThanksMessage>
					</Reward>
				</RewardsContainer>
			</DataContainer>
			<Footer>
				<LoyaltyPoints
					className={offer?.loyaltyPointCrossout ? "crossout" : ""}
				>
					{offer?.loyaltyPointCrossout && (
						<CrossOutValue>
							<CrossOutLine />
							Earn <CrownIcon width={30} height={16} />
							{offer?.loyaltyPointCrossout.toLocaleString("en-US")}
						</CrossOutValue>
					)}

					<div style={{ display: "flex" }}>
						<span>Earn </span>
						<CrownIcon width={30} height={16} />
						<span>{gift?.loyaltyPointReward.toLocaleString("en-US")}</span>
					</div>
				</LoyaltyPoints>
				<Button
					variant="contained"
					color="success"
					size="large"
					sx={{
						width: "48%",
						paddingTop: "0.5rem",
						paddingBottom: "0.5rem",
						fontSize: "16px",
						Weight: "700",
						height: "46px"
					}}
					onClick={onClickBuy}
				>
					{offer.price} USD
				</Button>
			</Footer>
			<FinalMessage>
				{offer.priority === 1 && tracker?.isUserQualifiedToContribute && (
					<div style={{ color: "#834800", lineHeight: "18px" }}>
						...and contribute to the Club Event!
					</div>
				)}
				{isPoisActive && (
					<div style={{ color: "#FBBD05", lineHeight: "18px" }}>
						Contribute to Poi’s Bounty
					</div>
				)}
			</FinalMessage>

			{showOfferId && (
				<Typography
					sx={{
						fontWeight: 700,
						color: "red",
						fontSize: "10px",
						height: "auto",
						textAlign: "center",
						marginTop: "10px"
					}}
				>
					{offer.id}
				</Typography>
			)}
		</CardContainer>
	);
};
