import {
	useDeleteSegmentationOverrideMutation,
	useGetSegmentationOverrideQuery,
	useSetSegmentationOverrideMutation
} from "redux/apis";
import { Container, ErrorMessage, LoadingMessage } from "./styles";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { offersApi } from "redux/apis";
import { useDispatch } from "react-redux";

interface SegmentationOverrideProps {
	onSave: () => void;
}

const SegmentationOverride = ({ onSave }: SegmentationOverrideProps) => {
	const [values, setValues] = useState("");
	const dispatch = useDispatch();
	/* eslint-disable-next-line */
	const [errorMessage, setErrorMessage] = useState<any>();
	const {
		isLoading: queryIsLoading,
		error: queryError,
		data
	} = useGetSegmentationOverrideQuery("", { refetchOnFocus: true });
	const [
		createSegmentation,
		{ isLoading: createIsLoading, error: createError }
	] = useSetSegmentationOverrideMutation();

	const [
		deleteSegmentation,
		{ isLoading: deleteIsLoading, error: deleteError }
	] = useDeleteSegmentationOverrideMutation();

	const invalidateOffersTags = () => {
		dispatch(offersApi.util.invalidateTags(["offersTag"]));
	};

	const handleSave = async () => {
		setErrorMessage("");
		// prevent CRLF injection override
		const formatedValues = values.replace(/\r?\n|\r/g, "");
		const data = await createSegmentation(formatedValues).unwrap();
		syncValues(data.userSegmentationData);
		invalidateOffersTags();
		onSave();
	};

	const handleDelete = async () => {
		setErrorMessage("");
		await deleteSegmentation("").unwrap();
		invalidateOffersTags();
	};
	/* eslint-disable */
	const syncValues = (data: any) => {
		const sortedData = {} as any;
		if (data) {
			Object.keys(data)
				.sort()
				.forEach(function (key) {
					sortedData[key] = data[key];
				});
		}

		setValues(data ? JSON.stringify(sortedData, null, 4) : "");
	};
	/* eslint-enable */

	useEffect(() => {
		if (data) {
			syncValues(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (createError) {
			setErrorMessage(
				"status" in createError
					? "error" in createError
						? createError.error
						: createError.status
					: createError.message
			);
		}
	}, [createError]);

	useEffect(() => {
		if (deleteError) {
			setErrorMessage(
				"status" in deleteError
					? "error" in deleteError
						? deleteError.error
						: deleteError.status
					: deleteError.message
			);
		}
	}, [deleteError]);

	if (queryIsLoading) {
		return <LoadingMessage>Loading Segmentation Override...</LoadingMessage>;
	}

	if (queryError) {
		return (
			<ErrorMessage>
				Error:
				{"status" in queryError
					? "error" in queryError
						? queryError.error
						: queryError.status
					: queryError.message}
			</ErrorMessage>
		);
	}

	return (
		<Container>
			<h2>Segmentation Override</h2>
			<textarea
				id="txtArea"
				name="txtArea"
				rows={43}
				style={{ width: "700px" }}
				//defaultValue={JSON.stringify(data, null, 4)}
				value={values}
				onChange={(e) => setValues(e.target.value)}
			></textarea>
			{errorMessage && <ErrorMessage>Error: {errorMessage}</ErrorMessage>}
			<div>
				<Button
					variant="outlined"
					sx={{ margin: "10px" }}
					onClick={handleDelete}
					disabled={deleteIsLoading}
				>
					Delete
				</Button>
				<Button
					variant="outlined"
					sx={{ margin: "10px" }}
					onClick={handleSave}
					disabled={createIsLoading}
				>
					Set Override
				</Button>
			</div>
		</Container>
	);
};

export default SegmentationOverride;
