import Countdown from "components/CountdownV2";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment } from "react";
import { onPBV2change, selectPoisBountyV2State } from "redux/slices";
import { TimerIcon } from "utils/svgIcons";
import { EventEnded, EventEndedLabel, iconStyles } from "./styles";

interface TimerProps { }

export const Timer: React.FC<TimerProps> = () => {
	const dispatch = useAppDispatch();
	const { nowTime } = useWebStoreContext();
	const { remaining24hour, eventEnded, trackers } = useAppSelector(selectPoisBountyV2State);
	const endEventTime = trackers?.endTime;
	// @ts-expect-error
	const showCountDown = typeof nowTime._tzm !== "undefined";

	return (
		<Fragment>
			{remaining24hour || eventEnded ? (
				<EventEnded>
					<TimerIcon style={iconStyles} />
					<EventEndedLabel>Event Ended</EventEndedLabel>
				</EventEnded>
			) : showCountDown ? (
				<Countdown
					startTime={nowTime}
					endTime={endEventTime}
					onEnd={() => {
						if (typeof trackers !== "undefined") {
							dispatch(onPBV2change({ remaining24hour: true }));
						}
					}}
				/>
			) : (
				<Fragment />
			)}
		</Fragment>
	);
};
