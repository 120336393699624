import { Box, styled } from "@mui/material";
import { isIPad13 } from "react-device-detect";

export const StepMarker = styled(Box)`
	width: 2.5px;
	height: 40px;
	right: 0.9px;
	bottom: -8px;
	background: #ffffff;
	border: 1.82418px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0px 3.64835px 3.64835px rgba(0, 0, 0, 0.25);
	border-radius: 1rem;
	transform: rotate(0deg);
	position: relative;
	transform: translateY(${isIPad13 ? "-8px" : 0});

	&.page-variant {
		bottom: 0;
	}

	&.mega-menu-variant {
		transform: translateY(${isIPad13 ? "-8px" : "5px"});
	}

	.crown-svg {
		position: absolute;
		left: -14px;
		top: 50%;
		transform: translateY(-50%);
	}

	&.is-event-completed.page-variant {
		.loyalty-points-indicator {
			font-size: 1rem;
		}
	}
`;
