import Button from "components/Buttons/ThemeButton";
import CloseIconButton from "../../lib/CloseIconButton";
import {
	Box,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Typography,
	useMediaQuery
} from "@mui/material";
import { StyledBox } from "../styled";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useState } from "react";
import { CrownIcon } from "utils/svgIcons";
import { Legalese } from "../lib/Legalese_SingleItem";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

export const SingleItem = ({
	onCloseClick,
	onClickBuy
}: {
	onCloseClick: () => void;
	onClickBuy: (offerId: string) => void;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const [isLoading, setIsLoading] = useState(false);
	const { dialogState } = useDialogModalRedux();
	const { offerId, itemDescription, itemPrice, itemImageUrl } = dialogState;
	const isLandscapeMobile = useMediaQuery("(max-width: 932px) and (max-height: 440px)");

	return (
		<>
			<DialogTitle
				sx={{
					p: !isLandscapeMobile ? "48px 32px" : "16px 24px",
					backgroundSize: "contain",
					backgroundColor: "#FFFAF1",
					backgroundPosition: "center",
					overflow: "hidden"
				}}
			>
				<CloseIconButton
					onClose={() => {
						onCloseClick();
						reportEvent("dialog_x_close", {
							source: "loyalty_purchase_confirm_dialog"
						});
					}}
				/>
				<Grid container sx={{ flexWrap: "nowrap", lineHeight: 1 }} alignItems="center">
					<Grid item>
						<StyledBox sx={{ mr: 2 }}>
							<img src={itemImageUrl} alt={itemDescription} />
						</StyledBox>
					</Grid>
					<Grid item>
						<Box>
							<strong style={{ fontSize: !isLandscapeMobile ? 20 : 16 }}>
								{itemDescription}
							</strong>
							<Typography fontSize={!isLandscapeMobile ? 16 : 14}>
								<CrownIcon
									style={{
										marginRight: "6px",
										marginBottom: "-2px",
										width: "14px"
									}}
								/>
								{itemPrice?.toLocaleString("en-US")}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent sx={{ pb: 0 }}>
				<DialogContentText sx={{ p: ".75rem 1rem" }}>
					<Typography
						variant={!isLandscapeMobile ? "h4" : "h5"}
						component="strong"
						sx={{
							fontWeight: "bold",
							display: "block",
							textAlign: "center",
							lineHeight: 1,
							color: "#333333"
						}}
					>
						Confirm your purchase
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column", pb: 0 }}>
				<Legalese />
				{isLoading ? (
					<Button
						disabled
						sx={{
							pt: ".75rem",
							pb: ".75rem",
							mb: 1,
							width: "calc(100% - 3rem)",
						}}
					>
						processing...
					</Button>
				) : (
					<Button
						onClick={() => {
							setIsLoading(true);
							onClickBuy(offerId || "");
							reportEvent("loyalty_buy_click", {
								source: "loyalty_purchase_confirm_dialog"
							});
							// disable loading after timeout ie. if user loses internet mid-purchase
							setTimeout(() => {
								setIsLoading(false);
							}, 5000);
						}}
						sx={{
							pt: ".75rem",
							pb: ".75rem",
							mb: 1,
							width: "calc(100% - 3rem)",
						}}
					>
						Buy
					</Button>
				)}
			</DialogActions>
		</>
	);
};
