import { updatePoisBountyV2Slice } from "./update-slice";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { PoisBountyV2Slice, onClaimAll, onClaimV2Reward } from "../../v2";


export const CLAIM_REWARD_EXTRA_REDUCER_V2 = (
	builder: ActionReducerMapBuilder<PoisBountyV2Slice>
): void => {
	builder.addCase(onClaimV2Reward.pending, (state) => {
		state.claimingReward = true;
	});

	builder.addCase(onClaimV2Reward.fulfilled, (state, action) => {
		updatePoisBountyV2Slice(state, action);
		state.claimingReward = false;
	});
};

export const CLAIM_ALL_REWARDS_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<PoisBountyV2Slice>
): void => {
	builder.addCase(onClaimAll.pending, (state) => {
		state.claimingAllRewards = true;
	});

	builder.addCase(onClaimAll.fulfilled, (state, action) => {
		updatePoisBountyV2Slice(state, action);
		state.claimingAllRewards = false;
		state.blockAllButtons = false;
	});
};
