import { updatePoisBountyV2Slice } from "./update-slice";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { PoisBountyV2Slice, getPoisBountyV2EventTrackers, onCreateCycleTracker } from "../../v2";

export const TRACKERS_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<PoisBountyV2Slice>
): void => {
	builder.addCase(getPoisBountyV2EventTrackers.fulfilled, (state, action) => {
		updatePoisBountyV2Slice(state, action);
	});
};

export const CREATE_CYCLE_TRACKER_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<PoisBountyV2Slice>
): void => {
	builder.addCase(onCreateCycleTracker.pending, (state) => {
		state.creatingNewCycle = true;
		state.claimingAllRewards = true;
	});

	builder.addCase(onCreateCycleTracker.fulfilled, (state, action) => {
		updatePoisBountyV2Slice(state, action);
		state.creatingNewCycle = false;
		state.claimingAllRewards = false;
	});
};