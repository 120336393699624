import service from "services/api";
import { RootState } from "redux/store";
import { getLimitedDeals } from "../sticky-dip";
import { onOpenPopUp } from "../pop-up-manager";
import { PREFIX, grantRewardPopUp } from "./helper";
import { getShopSections } from "../shop-sections";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	Segment,
	DipResponse,
	SegmentResponse,
	UserSegmentResponse
} from "interfaces";
import {
	onCloseDIP,
	onDIPtrigger,
	ClaimDipPayload,
	SendActionPayload,
	UserSegmentPayload
} from ".";

export const getDIPs = createAsyncThunk(
	`${PREFIX}/get`,
	async (): Promise<DipResponse | undefined> => {
		try {
			const response = await service.Get<DipResponse>("/rest/dips");
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);

export const claimDipReward = createAsyncThunk(
	`${PREFIX}/claim-dip-reward`,
	async (
		{ dipId, buttonId, grantRewardType }: ClaimDipPayload,
		thunkAPI
	): Promise<void> => {
		try {
			const { success } = await service.Post<{ success: boolean }>(
				`/rest/dips/reward?dipId=${dipId}&buttonId=${buttonId}`
			);
			if (success) {
				thunkAPI.dispatch(onCloseDIP());
				thunkAPI.dispatch(onOpenPopUp(grantRewardPopUp[grantRewardType]));
				thunkAPI.dispatch(getDIPs());
			}
		} catch (error) {
			/* eslint-disable-next-line */
			throw Error((error as any).message);
		}
	}
);

export const sendDipAction = createAsyncThunk(
	`${PREFIX}/send-action`,
	async (payload: SendActionPayload, thunkAPI): Promise<void> => {
		const { currentDip } = (thunkAPI.getState() as RootState).dip;
		const { dipId, actionType, buttonId } = payload;

		try {
			await service.Post<{ success: boolean }>("/rest/dips/action", {
				dipId: dipId ?? currentDip?.dip.id,
				buttonId: buttonId ?? "",
				actionType,
				metadata: {}
			});

			switch (actionType) {
				case "view":
					thunkAPI.dispatch(getDIPs());
					break;
				case "close":
					thunkAPI.dispatch(getShopSections());
					thunkAPI.dispatch(getLimitedDeals());
					break;
			}
		} catch (error) {
			console.log(error);
		}
	}
);

/***************************************************
 * DEBUG thunks
 ***************************************************/
export const getAllDIPs = createAsyncThunk(
	`${PREFIX}/DEBUG-get-all`,
	async (_, thunkAPI): Promise<DipResponse | undefined> => {
		try {
			const response = await service.Get<DipResponse>(
				"/rest/debug/dips?includeNonMatching=true"
			);
			return response;
		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				thunkAPI.dispatch(onDIPtrigger("sessionStart"));
			}, 1000);
		}
	}
);

export const clearUserDipActions = createAsyncThunk(
	`${PREFIX}/DEBUG-clear-user-dip-actions`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post<DipResponse>("/rest/debug/dips/action/clear");
		} catch (error) {
			console.log(error);
		} finally {
			thunkAPI.dispatch(getDIPs());
		}
	}
);

export const clearUserDipRequisitions = createAsyncThunk(
	`${PREFIX}/DEBUG-clear-user-dip-requisitions`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post<DipResponse>("/rest/debug/dips/requisition/clear");
		} catch (error) {
			console.log(error);
		} finally {
			thunkAPI.dispatch(getDIPs());
		}
	}
);

export const getCurrentSegments = createAsyncThunk(
	`${PREFIX}/DEBUG-get-current-segments`,
	async (): Promise<Segment[] | undefined> => {
		try {
			const { segments } = await service.Get<SegmentResponse>(
				"/rest/debug/segments"
			);
			return segments;
		} catch (error) {
			console.log(error);
		}
	}
);

export const getCurrentUserSegments = createAsyncThunk(
	`${PREFIX}/DEBUG-get-current-user-segments`,
	async (): Promise<UserSegmentPayload> => {
		try {
			const { data } = await service.Get<UserSegmentResponse>(
				"/rest/debug/segments/data"
			);
			const { ...keys } = data;
			return keys;
		} catch (error) {
			console.log(error);
		}
	}
);
