import { useDispatch } from "react-redux";
import { setOpen } from "..";
export const useMegaMenuRedux = () => {
	const dispatch = useDispatch();

	const toggleDrawer = (value = false) => {
		dispatch(setOpen(value));
	};

	return {
		toggleDrawer
	};
};
