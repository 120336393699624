import { SaleTag } from "interfaces";
import { getSaleTags } from "./thunks";
import { RootState } from "redux/store";
import { initialState } from "./initial-state";
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import {
	PREFIX,
	setStickyLocalStorage,
	LS_DOT_MEGAMENU_HIDDEN_AT
} from "./helper";

export const stickySaleTagSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		setActiveTags: (state, action: PayloadAction<SaleTag[]>) => {
			state.activeTags = action.payload;
		},
		closeStickyTag: (state, action: PayloadAction<string>) => {
			const stickyId = action.payload;
			setStickyLocalStorage(stickyId);
			state.closedStickyTags = [...state.closedStickyTags, action.payload];
		},
		showHamburgerDot: (state) => {
			state.showMegaMenuIndicator = true;
		},
		hideHamburgerDot: (
			state,
			action: PayloadAction<SaleTag["id"] | undefined>
		) => {
			if (typeof action.payload === "undefined") {
				state.showMegaMenuIndicator = false;
				return;
			}
			const { closedMenuSaleTags, activeTags } = current(state);
			if (closedMenuSaleTags.includes(action.payload)) {
				return;
			}
			const tagsOnMegaMenu = activeTags.filter((tag) => tag.showMegaMenuTag);
			const closedMenuTagsUpdated = [...closedMenuSaleTags, action.payload];

			state.closedMenuSaleTags = closedMenuTagsUpdated;

			if (closedMenuTagsUpdated.length === tagsOnMegaMenu.length) {
				state.showMegaMenuIndicator = false;
				localStorage.setItem(
					LS_DOT_MEGAMENU_HIDDEN_AT,
					JSON.stringify(new Date())
				);
				state.closedMenuSaleTags = [];
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getSaleTags.fulfilled, (state, action) => {
			const saleTags = action.payload;
			if (saleTags) {
				state.saleTags = saleTags;
			}
		});
	}
});

export const {
	closeStickyTag,
	hideHamburgerDot,
	showHamburgerDot,
	setActiveTags
} = stickySaleTagSlice.actions;
// prettier-ignore
export const selectStickySaleTagState = (state: RootState) => state.stickySaleTag;
export default stickySaleTagSlice.reducer;
export * from "./types";
export * from "./thunks";
