import { PREFIX } from "./helper";
import service from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DebugEnabled } from "interfaces";

export const isDebugEnabled = createAsyncThunk(
	`${PREFIX}/get`,
	async (): Promise<DebugEnabled | undefined> => {
		try {
			return await service.Get<DebugEnabled>("/rest/debug/user/debugEnabled");
		} catch (error) {
			console.log(error);
		}
	}
);
