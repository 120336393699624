import { MerchItem } from "./lib";
import explore from "assets/merch-shop/explore.jpg";
import apparel from "assets/merch-shop/apparel.jpg";
import accessories from "assets/merch-shop/accessories.jpg";
import exploreMobile from "assets/merch-shop/explore-mobile.jpg";
import apparelMobile from "assets/merch-shop/apparel-mobile.jpg";
import accessoriesMobile from "assets/merch-shop/accessories-mobile.jpg";

export const items: MerchItem[] = [
	{
		desktopImage: explore,
		mobileImage: exploreMobile,
		buttonLabel: "Explore",
		link: "https://merch.solitairetripeaks.com/"
	},
	{
		desktopImage: apparel,
		mobileImage: apparelMobile,
		buttonLabel: "Apparel",
		link: "https://merch.solitairetripeaks.com/apparel"
	},
	{
		desktopImage: accessories,
		mobileImage: accessoriesMobile,
		buttonLabel: "Accessories",
		link: "https://merch.solitairetripeaks.com/accessories"
	}
];
