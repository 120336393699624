import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";

const useDIPCalculateScale = (dipType?: string) => {
	const [scale, setScale] = useState(1);
	const isPortrait = useMediaQuery("(orientation: portrait)");

	useEffect(() => {
		const calculateScale = () => {
			// offers and bogo dips
			let referenceWidth = isPortrait ? 510 : 1100;
			let referenceHeight = isPortrait ? 914 : 471;

			// informative dips
			if (dipType === "informative") {
				referenceWidth = isPortrait ? 450 : 1100;
				referenceHeight = isPortrait ? 634 : 417;
			}

			const windowWidth = document.documentElement.clientWidth;
			const windowHeight = document.documentElement.clientHeight;
			const scaleX = windowWidth / referenceWidth;
			const scaleY = windowHeight / referenceHeight;
			const scaleFactor = Math.min(scaleX, scaleY);
			setScale(scaleFactor);
		};

		calculateScale();
		window.addEventListener("resize", calculateScale);

		return () => {
			window.removeEventListener("resize", calculateScale);
		};
	}, [isPortrait, dipType]);

	return scale > 1 ? 1 : scale;
};

export default useDIPCalculateScale;
