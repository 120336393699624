import { LoginType } from "../types";

export default abstract class AbstractLoginRequest {
	readonly loginType: LoginType;
	readonly credentials: Record<string, string>;

	protected constructor(
		loginType: LoginType,
		credentials: Record<string, string>
	) {
		this.loginType = loginType;
		this.credentials = credentials;
	}

	getCredentialsAsString(): string {
		return JSON.stringify(this.credentials);
	}
}
