import React, { ReactNode } from "react";
import { Container } from "./styles";
import { useMediaQuery } from "@mui/material";

const Footer = ({
	children,
	className
}: {
	children: ReactNode | ReactNode[];
	className?: string;
	isPortrait?: boolean;
}) => {
	const isLowWidth = useMediaQuery("(max-width:732px)");
	const isMobile = isLowWidth;

	if (isMobile && className === "") {
		return <Container>{children}</Container>;
	}
	return <>{children}</>;
};

export default Footer;
