import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";

export const megaMenuSlice = createSlice({
	name: "mega-menu",
	initialState,
	reducers: {
		setOpen: (state, action: PayloadAction<boolean>) => {
			state.isOpen = action.payload;
		}
	}
});

export const { setOpen } = megaMenuSlice.actions;

export default megaMenuSlice.reducer;
