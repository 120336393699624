export const PRODUCTION_BUILD_TYPE_VALUE = "prod";
export const DEBUG_COLOR = "rgb(0, 173, 61)"; // highlight color

export const SCOPELY_ANALYTICS_API_KEY = process.env.REACT_APP_SCOPELY_API_KEY ?? "459d50c2-2194-4038-b6b1-287e4193a461";

export const SCOPELY_IDENTITY_PROVIDER_URL =
	"https://id.scopely.com/oauth2/default/v1/authorize";
export const SCOPELY_IDENTITY_CLIENT_ID = process.env.REACT_APP_SCOPELY_CLIENT_ID ?? "0oa6f1niqht8kT1Lc5d7";
export const SCOPELY_IDENTITY_API_ENDPOINT =
	"https://heimdall.aprod.scopely.io";

const redirectUrl = `${window.location.protocol}//${window.location.host}/`; //note host includes port numbers for local dev

export const LOGIN_REDIRECT_URL = redirectUrl;
export const LOGOUT_REDIRECT_URL = redirectUrl;

export const SCOPELY_STATE_KEY = "scopelyId.stateObj";
export const SCOPELY_STATE_KEY_SESSION_DURATION = 30;
