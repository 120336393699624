import { AnimatePresence, motion } from "framer-motion";

export const Animated = ({
	children
}: {
	children: JSX.Element;
}): JSX.Element => {
	return (
		<AnimatePresence>
			<motion.div
				initial={{ translateY: 100 }}
				animate={{ translateY: 0 }}
				exit={{ opacity: 0 }}
				style={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					display: "flex"
				}}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};
