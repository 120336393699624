import {
	SCOPELY_STATE_KEY,
	SCOPELY_STATE_KEY_SESSION_DURATION
} from "./constants";

export function getScopelyStateFromSession() {
	const sessionVal = sessionStorage.getItem(SCOPELY_STATE_KEY);
	if (sessionVal !== null) {
		const stateObj = JSON.parse(sessionVal);
		const expiredAt = new Date(stateObj.expiredAt);
		if (expiredAt > new Date()) {
			return stateObj.value;
		} else {
			sessionStorage.removeItem(SCOPELY_STATE_KEY);
		}
	}
	return null;
}

export function setScopelyStateToSession(
	value: string,
	inMin = SCOPELY_STATE_KEY_SESSION_DURATION
) {
	const expiredAt = new Date(new Date().getTime() + 60000 * inMin);
	const obj = {
		value: value,
		expiredAt: expiredAt.toISOString()
	};
	sessionStorage.setItem(SCOPELY_STATE_KEY, JSON.stringify(obj));
}

export function removeScopelyStateSession() {
	sessionStorage.removeItem(SCOPELY_STATE_KEY);
}
