import { ClubName } from "..";
import { useCallback } from "react";
import { useAppDispatch } from "hooks";
import { Button, Container } from "./styles";
import { Info } from "assets/club-gifts-galore/svg";
import { useGiftGaloreTracker } from "pages/ClubShop/ClubEventSection";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { onOpenPopUp } from "redux/slices/pop-up-manager";

interface InfoButtonProps {}

export const InfoButton: React.FC<InfoButtonProps> = () => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { tracker } = useGiftGaloreTracker();

	const onLearnMore = useCallback(() => {
		dispatch(onOpenPopUp("cgg-more-info"));
		reportEvent("learn_more_clicked", {
			source: "club_gifts_galore",
			club_gifts_galore_id: tracker.id
		});
	}, []);

	return (
		<Container>
			<ClubName className="clubName" name={tracker.clubName} />
			<Button onClick={onLearnMore}>
				<Info />
			</Button>
		</Container>
	);
};
