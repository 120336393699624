import { styled } from "@mui/material";
import { useRef } from "react";
import theme from "../../../theme";
import classNames from "classnames";

export const Content = styled("div")`
	&.flex {
		display: flex;
		justify-content: flex-start;
		padding: 0 1.5rem;
		.winner-item {
			max-width: 610px;
		}
	}
	&.grid {
		justify-content: center;
		--grid-columns: 1;
		transition: all 0.3s linear;
		display: grid;
		gap: 2rem;
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(96% / var(--grid-columns)) [col]
		);

		${theme.breakpoints.up("sm")} {
			--grid-columns: 1;
			grid-template-columns: repeat(
				var(--grid-columns),
				calc(93% / var(--grid-columns)) [col]
			);
			max-width: 655px;
			margin: 0 auto;
		}
		${theme.breakpoints.up("md")} {
			max-width: fit-content;
			margin: auto;
			justify-content: space-around;
			--grid-columns: 2;

			grid-template-columns: repeat(
				var(--grid-columns),
				calc(93% / var(--grid-columns)) [col]
			);
		}
		${theme.breakpoints.up("lg")} {
			justify-content: center;
			--grid-columns: 2;
			grid-template-columns: repeat(
				var(--grid-columns),
				calc(93% / var(--grid-columns)) [col]
			);
		}
		${theme.breakpoints.up("xl")} {
			justify-content: space-around;
			--grid-columns: 2;

			grid-template-columns: repeat(
				var(--grid-columns),
				calc(93% / var(--grid-columns)) [col]
			);
		}
	}
`;

const SectionContent = ({ items }: { items: JSX.Element[] }) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	return (
		<Content
			className={classNames(items.length === 1 ? "flex" : "grid")}
			ref={contentRef}
		>
			{items}
		</Content>
	);
};

export default SectionContent;
