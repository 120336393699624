import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEnvContext, useWebStoreContext, WebStoreContext } from "contexts";
import { FeaturesFlags } from "helpers";
import { useContext, useState } from "react";
import { useGetTimezonesQuery } from "redux/apis";

export const LOCAL_STORAGE_TIME_ZONE = "store.solitairetripeaks.com:timeZone";

const GetLocalTimeZones = () => {

	const { userTracker } = useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();

	const { data: timezoneData } = useGetTimezonesQuery(
		{
			isAuthenticated: !!userTracker
		},
		{ skip: !isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER) || !userTracker }
	);

	const defaultTimeZone = "America/Los_Angeles";

	const { nowTime } = useContext(WebStoreContext);

	const [localTime, setLocalTime] = useState(localStorage.getItem(LOCAL_STORAGE_TIME_ZONE) ?? "");

	const handleChange = (event: SelectChangeEvent) => {
		handleChangeTimeZone(event.target.value);
	};

	const clearTimeZone = () => {
		handleChangeTimeZone(defaultTimeZone);
		localStorage.removeItem(LOCAL_STORAGE_TIME_ZONE);
	}

	const handleChangeTimeZone = (zone: string) => {
		if (nowTime) {
			setLocalTime(zone);
			localStorage.setItem(LOCAL_STORAGE_TIME_ZONE, zone);
		}
	};

	return (
		<>
			<FormControl sx={{ m: "1rem 1rem 1rem 0", minWidth: 200 }}>
				<InputLabel>Time zone</InputLabel>
				<Select
					value={localTime}
					label="Local time"
					onChange={handleChange}
				>
					<MenuItem value={defaultTimeZone}>Server Time</MenuItem>
					{timezoneData && Object.keys(timezoneData.timeZoneOffsets).map((tz: string) => (
						<MenuItem key={tz} value={tz}>{tz}</MenuItem>
					))}
				</Select>
			</FormControl>
			<div>
				<ButtonGroup>
					<Button onClick={clearTimeZone}>
						Clear Time Zone
					</Button>
				</ButtonGroup>
			</div>
		</>
	);
};

export default GetLocalTimeZones;
