export interface LearnMoreItem {
	image: string;
	title: string;
	description?: string;
}

export const ggItems: LearnMoreItem[] = [
	{
		image: "/spec_gift_galore/pop-up-image-1.png",
		title: "Purchase Web Large Gift Boxes with your fellow Club Members!"
	},
	{
		image: "/spec_gift_galore/pop-up-image-2.png",
		title: "As more Club members participate, the meter fills up!"
	},
	{
		image: "/spec_gift_galore/pop-up-image-3.png",
		title:
			"When the meter is filled, the WHOLE CLUB earns a BONUS REWARD in their inbox!"
	}
];
