import styled, { CSSProperties } from "styled-components";

export const Container = styled.div`
	width: 100%;
	padding: 0px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`;

export const NodesContainer = styled.div`
	width: 100%;
	height: 51.1%;
	position: absolute;
	top: 38%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 18%;

	&.several-nodes {
		height: 41.6%;
		margin-left: 14%;
	}
`;

export const NodesContainerThreePortrait = styled.div`
	width: 90%;
	height: 92%;
	position: absolute;
	top: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const NodesContainerSixPortrait = styled.div`
	width: 92%;
	height: 77%;
	position: absolute;
	top: 20%;
	display: grid;
	//grid-template-columns: repeat(2, 1fr);
	//grid-template-rows: repeat(3, 1fr);
	grid-template-columns: minmax(0, 50%) minmax(0, 50%);
	grid-template-rows: minmax(0, 33.33%) minmax(0, 33.33%) minmax(0, 33.33%);
	//flex-wrap: wrap;
`;

export const Background = styled.div<{ imageUrl?: string }>`
	content: url(${(props) => props.imageUrl});
	width: 100%;
	height: 100%;
`;

export const PalmTrees = styled.div<{ imageUrl?: string }>`
	content: url(${(props) => props.imageUrl});
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
`;

export const BgBrush = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
`;

export const UnderConstruction = styled.img`
	position: absolute;
	width: 80%;
	top: 30%;
`;

export const Rope = styled.img`
	position: absolute;
	top: 5.6%;
	width: 100%;
	margin-right: 18%;
	height: 6%;

	&.several-nodes {
		top: 5%;
		height: 6.5%;
	}
`;

export const RopeThreePortrait = styled.img`
	position: absolute;
	justify-content: center;
	rotate: 90deg;
	top: 101%;
	height: 3%;
	align-items: center;
`;

export const RopeSixPortrait = styled.div<{ imageUrl?: string }>`
	position: relative;
	content: url(${(props) => props.imageUrl});
	bottom: 52%;
	right: 38%;
	height: 10%;
	z-index: 1;

	&.first {
		rotate: 180deg;
		left: 62%;
	}

	&.vertical {
		rotate: 90deg;
		bottom: 10%;
		left: 15%;
	}
`;

export const Title = styled.div<{ titleImage?: string }>`
	content: url(${(props) => props.titleImage});
	width: 330px;
	position: absolute;
	left: 50%;
	top: 14%;
	transform: translate(-50%, -50%);

	@media (min-width: 733px) {
		top: 25%;
	}

	@media (min-width: 1050px) {
		width: 450px;
	}
`;

export const timerStyle: CSSProperties = {
	top: "8%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#fff",
	position: "absolute"
};

export const timerStyleMobile: CSSProperties = {
	...timerStyle,
	top: "5%",
	whiteSpace: "nowrap"
};
