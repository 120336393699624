import useBreakpoints from "hooks/useBreakpoints";
import Box from "@mui/material/Box";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { Link } from "@mui/material";
import { Fragment } from "react";
import { links } from "helpers";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOpen } from "redux/slices";
import { SiteLink } from "interfaces";
import { Title, CookieButton } from "./styles";

const CookiesButton = () => {
	const siteData = window.OneTrust?.GetDomainData();
	const text = siteData ? siteData.CookieSettingButtonText : "Cookies";

	return (
		<CookieButton id="ot-sdk-btn" className="ot-sdk-show-settings">
			{text}
		</CookieButton>
	);
};

export const MenuLinks = () => {
	const { isSmDown } = useBreakpoints();
	const { reportEvent } = useGoogleAnalyticsContext();
	const dispatch = useDispatch();

	const reportToGoogle = (analytics_key: string) => {
		reportEvent(analytics_key, { source: "mega_menu" });
	};

	const handleClick = (link: SiteLink) => {
		dispatch(setOpen(false));
		reportToGoogle(link.analytics_key);
	};

	const items = [links.tos, links.privacy];

	return (
		<Box
			sx={{
				display: "block",
				textAlign: "center",
				marginTop: "10px",
				marginBottom: "10px"
			}}
		>
			{items.map((item, index) => {
				if (item.isExternal) {
					return (
						<Fragment key={item.title}>
							<Link
								sx={{
									textDecoration: "none"
								}}
								href={item.path}
								title={item.title}
								target="_blank"
								onClick={() => {
									() => reportToGoogle(item.analytics_key);
								}}
							>
								<Title>{item.title}</Title>
							</Link>
							{isSmDown ? (
								!index ? (
									<span style={{ marginTop: "20px" }}>|</span>
								) : null
							) : (
								<span style={{ marginTop: "20px" }}>|</span>
							)}
						</Fragment>
					);
				}

				return (
					<Fragment key={item.title}>
						<RouterLink
							to={item.path}
							style={{
								textDecoration: "none"
							}}
							key={item.path}
							onClick={() => handleClick(item)}
						>
							<Title>{item.title}</Title>
						</RouterLink>
						{items[index + 1]?.title && (
							<span style={{ marginTop: "20px" }}>|</span>
						)}
					</Fragment>
				);
			})}

			<Fragment key="cookie">
				<CookiesButton />
			</Fragment>
		</Box>
	);
};
