import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const CloseIconButton = ({
	onClose
}: {
	// eslint-disable-next-line
	onClose: (event?: any, reason?: any) => void;
}) => {
	return (
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={{
				position: "absolute",
				right: 4,
				top: 4,
				zIndex: 1,
				color: (theme) => theme.palette.grey[500],
				"&, & *, *": {
					transition: "all 0.3s linear"
				}
			}}
		>
			<CancelIcon
				sx={{
					color: "black",
					opacity: "0.5",
					backgroundColor: "white",
					borderRadius: "13px",
					"&:hover": {
						opacity: 0.9
					}
				}}
			/>
		</IconButton>
	);
};

export default CloseIconButton;
