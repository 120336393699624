import { Crown } from "assets/pois-bounty/svg";
import classNames from "classnames";
import { usePoisBountyContext } from "components/PoisBountyWidget/context";
import { IndicatorStyled, LoyaltyContainer, LoyaltyPoints } from "./styles";

interface IndicatorProps {
	userLoyaltyPoints: number;
	lastStep?: boolean;
	mode?: "progress-bar" | "marker";
}

export const Indicator: React.FC<IndicatorProps> = ({
	lastStep = false,
	userLoyaltyPoints,
	mode = "progress-bar"
}) => {
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	return (
		<IndicatorStyled
			className={classNames([
				"progress-indicator",
				isPortrait && "portrait",
				mode
			])}
		>
			<LoyaltyContainer
				className={classNames([
					"container-indicator",
					isPortrait && "portrait",
					mode
				])}
			>
				<LoyaltyPoints
					className={classNames([
						"loyalty-points-indicator",
						isPortrait && "portrait",
						!isPageVariant && lastStep && "reverse",
						mode
					])}
				>
					<Crown />
					{userLoyaltyPoints?.toLocaleString("en-US")}
				</LoyaltyPoints>
			</LoyaltyContainer>
		</IndicatorStyled>
	);
};
