/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from "react";
import { BannerImage, Brush, Container, InfoButton } from "./styles";
import bgBrush from "assets/header-banner/bg-brush-mobile.png";
import infoIcon from "assets/header-banner/info-button.svg";
import { ShopContext } from "pages/StoreFront";
import { ISectionMenuItem } from "../../../../components/PageTopNav/types";
import { getVariantImage } from "utils/getVariantImage";
import { useMediaQuery } from "@mui/material";
import { useAppDispatch } from "hooks";
import { onOpenPopUp } from "redux/slices";
import { Shine } from "components/PopUpManager/lib/Tutorial/styles";
import shine from "assets/tutorial/shine.png";

const HeaderBanner = () => {
	const dispatch = useAppDispatch();
	const sectionMenuData: ISectionMenuItem[] =
		useContext(ShopContext)?.sectionMenuData;
	const isPortrait = useMediaQuery("(orientation: portrait)");

	const section = sectionMenuData.find(
		(section) => section.name === "shop_header"
	);

	if (!section) {
		return null;
	}

	return (
		<Container>
			<BannerImage
				src={getVariantImage(
					section?.cardBackgroundImageUrl || "",
					"{shop_header_variant}",
					isPortrait ? "small" : "large"
				)}
			/>
			<InfoButton
				id="tutorial-info-button"
				src={infoIcon}
				onClick={() => dispatch(onOpenPopUp("tutorial"))}
			/>
			<Shine id="tutorial-info-button-shine" src={shine} width={71} />
			<Brush image={bgBrush}></Brush>
		</Container>
	);
};

export default HeaderBanner;
