import styled, { CSSProperties } from "styled-components";

export const Container = styled.div`
	position: relative;
	margin: 20px 0px 20px 0px;
`;

export const Image = styled.img`
	width: 100%;
	border-radius: 20px;
`;

export const CountDownStyles: CSSProperties = {
	fontSize: "11px",
	top: "3px",
	left: "3px",
	padding: "6px"
};
