import { styled } from "@mui/material";

export const PopUp = styled("div")`
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9998;
	background-color: rgba(0, 0, 0, 0.7);
	display: grid;
	overflow-y: auto;
	justify-content: center;
	align-items: center;

	&.scale {
		//width: 100dvw;
		height: 100dvh;
		//position: fixed;
		//top: 0;
		//left: 0;
		//z-index: 9998;
		//background-color: rgba(0, 0, 0, 0.7);
		display: flex;
		grid-auto-columns: 1fr;
		//justify-content: center;
		//align-items: center;
	}
`;

export const Wrapper = styled("div")`
	position: relative;
	/* &.mobile {
		transform: translateY(-2vh);
	}
	@media (max-height: 360px) {
		transform: translateY(-8vh);
	} */
`;
