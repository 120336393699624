import { links } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment, useCallback, useEffect, useState } from "react";
import { selectPoisBountyState, selectPoisBountyV2State, setOpen } from "redux/slices";
import { useLocation, useNavigate } from "react-router-dom";
import { useWebStoreContext } from "contexts/WebStoreProvider";

type Links = "/pois-bounty" | "/club-shop" | "/club-free-gift" | "/special-offers";

const LS_SURVIVABLE_LINK = "store.solitairetripeaks.com:survivableLink";
const LS_PREVENT_SURVIVABLE =
	"store.solitairetripeaks.com:preventSurvivableLink";
const SURVIVABLE_LINKS = [
	links.pois_bounty.path,
	links.club_shop.path,
	links.club_free_gift.path,
	links.drip_offer.path
];

interface UnLoggedRedirectionProps {}

export const UnLoggedRedirection: React.FC<UnLoggedRedirectionProps> = ({
	children
}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const [appStarted, setAppStarted] = useState<boolean>(false);
	const { isEventActive } = useAppSelector(selectPoisBountyState);
	const { isEventActiveV2 } = useAppSelector(selectPoisBountyV2State);
	const { userTracker, openLoginToContinueDialog } = useWebStoreContext();
	const path = localStorage.getItem(LS_SURVIVABLE_LINK) as Links;

	const cleanAfterNavigate = useCallback(() => {
		dispatch(setOpen(false));
		localStorage.removeItem(LS_SURVIVABLE_LINK);
		localStorage.setItem(LS_PREVENT_SURVIVABLE, "prevent");
	}, []);

	useEffect(() => {
		if (!SURVIVABLE_LINKS.includes(pathname)) {
			localStorage.removeItem(LS_PREVENT_SURVIVABLE);
		}
	}, [pathname]);

	useEffect(() => {
		if (!userTracker && SURVIVABLE_LINKS.includes(pathname) && !appStarted) {
			const prevent = localStorage.getItem(LS_PREVENT_SURVIVABLE);
			if (prevent) {
				return;
			}
			openLoginToContinueDialog();
			localStorage.setItem(LS_SURVIVABLE_LINK, pathname);
			setAppStarted(true);
		}
	}, [userTracker, appStarted]);

	useEffect(() => {
		if (!userTracker) {
			return;
		}
		const { clubId } = userTracker;

		if (path === "/pois-bounty" && (isEventActive || isEventActiveV2)) {
			navigate("/pois-bounty");
			cleanAfterNavigate();
		}

		if (path === "/club-shop" && clubId) {
			navigate("/club-shop");
			cleanAfterNavigate();
		}

		if (path === "/club-free-gift" && clubId) {
			navigate("/club-free-gift");
			cleanAfterNavigate();
		}

		if (path === "/special-offers") {
			navigate("/special-offers");
			cleanAfterNavigate();
		}
	}, [userTracker, isEventActive, isEventActiveV2, path]);

	return <Fragment>{children}</Fragment>;
};
