import Countdown from "components/CountdownV2";
import { Fragment } from "react";
import { TimerIcon } from "utils/svgIcons";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import { onPBchange, selectPoisBountyState } from "redux/slices";
import { EventEnded, EventEndedLabel, iconStyles } from "./styles";

interface TimerProps {}

export const Timer: React.FC<TimerProps> = () => {
	const dispatch = useAppDispatch();
	const { nowTime } = useWebStoreContext();
	const { data, remaining24hour, eventEnded } = useAppSelector(
		selectPoisBountyState
	);
	const { tracker } = data;
	const endEventTime = data?.tracker?.endTime;
	// @ts-expect-error
	const showCountDown = typeof nowTime._tzm !== "undefined";

	return (
		<Fragment>
			{remaining24hour || eventEnded ? (
				<EventEnded>
					<TimerIcon style={iconStyles} />
					<EventEndedLabel>Sale Ended</EventEndedLabel>
				</EventEnded>
			) : showCountDown ? (
				<Countdown
					startTime={nowTime}
					endTime={endEventTime}
					onEnd={() => {
						if (typeof tracker !== "undefined") {
							dispatch(onPBchange({ remaining24hour: true }));
						}
					}}
				/>
			) : (
				<Fragment />
			)}
		</Fragment>
	);
};
