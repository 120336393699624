import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section`
	position: relative;
	height: 300px;
	margin-bottom: 3rem;
	@media (max-width: 599px) {
		height: 300px;
		margin-bottom: -4rem;
	}
`;

export const Title = styled(Typography)`
	font-weight: bold;
	position: absolute;
	top: 6rem;
	left: 6%;
	color: #fff;
	text-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
	font-size: 86px;
	font-family: Baloo;
	line-height: 41.636px;

	@media (max-width: 599px) {
		font-size: 43px;
	}
`;

export const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 300px;
	display: block;
	object-fit: cover;
	z-index: -1;
	@media (max-width: 599px) {
		height: 250px;
	}
`;

export const Characters = styled.img`
	position: absolute;
	bottom: 2.5rem;
	right: 14%;
	object-fit: contain;
	z-index: -1;
	width: 20%;
	@media (max-width: 900px) {
		width: 30%;
		bottom: 4rem;
		right: 8%;
	}
	@media (max-width: 599px) {
		display: none;
	}
`;
