import { List, ListItem, ListItemButton } from "@mui/material";
import { links } from "helpers";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useImagePath } from "hooks/useImagePath";

export const FooterSocialLinks = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { getImage } = useImagePath("cdn");

	return (
		<List
			sx={{
				flexDirection: "row",
				justifyContent: { xs: "center", sm: "center" },
				display: "flex",
				width: { xs: "auto" },
				".MuiListItem-root": { paddingLeft: 0, paddingRight: 0 }
			}}
		>
			<ListItem>
				<ListItemButton
					onClick={() => {
						reportEvent("facebook_tripeaks_click", { source: "footer" });
					}}
					component="a"
					title="Facebook"
					href={links.social_facebook.path}
					target="_blank"
					rel="noreferrer"
					sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
				>
					<img src={getImage("/social-facebook.png")} alt="facebook page" />
				</ListItemButton>
			</ListItem>
			<ListItem>
				<ListItemButton
					onClick={() => {
						reportEvent("twitter_tripeaks_click", { source: "footer" });
					}}
					component="a"
					title="Twitter"
					href={links.social_twitter.path}
					target="_blank"
					rel="noreferrer"
					sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
				>
					<img src={getImage("/social-twitterX.png")} alt="twitter page" />
				</ListItemButton>
			</ListItem>
			<ListItem>
				<ListItemButton
					onClick={() => {
						reportEvent("instagram_tripeaks_click", { source: "footer" });
					}}
					component="a"
					title="Instagram"
					href={links.social_instagram.path}
					target="_blank"
					rel="noreferrer"
					sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
				>
					<img src={getImage("/social-instagram.png")} alt="instagram page" />
				</ListItemButton>
			</ListItem>
		</List>
	);
};
