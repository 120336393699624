import "animate.css";
import { useRef } from "react";
import { parseContent } from "../../helper";
import { Box, Typography } from "@mui/material";
import { PostListItemSkeleton } from "../../skeleton";
import { useImagePreloader } from "hooks/useImagePreloader";
import {
	Content,
	WinnersItem,
	ShareContainer,
	ShareButton,
	Helper
} from "./styles";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import classNames from "classnames";

interface IPostListItem {
	post: {
		jetpack_featured_media_url: string;
		id: string;
		link: string;
		content: {
			rendered: string;
		};
	};
}

const PostListItem = ({ post }: IPostListItem) => {
	const winnersItemRef = useRef<HTMLElement | null>(null);
	const { isImageLoaded: isFeatureImageLoaded } = useImagePreloader(
		post?.jetpack_featured_media_url
	);

	return !isFeatureImageLoaded ? (
		<PostListItemSkeleton />
	) : (
		<WinnersItem
			id={post.id}
			ref={winnersItemRef}
			className={classNames([
				"animate__animated animate__fadeIn",
				"winner-item"
			])}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					animation: "fadeIn 1s"
				}}
			>
				<Content>
					<Typography
						component="div"
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "flex-start"
						}}
					>
						{parseContent(post?.content?.rendered)}
					</Typography>
					<ShareContainer>
						<Typography component="span">Share this:</Typography>
						<FacebookShareButton url={post?.link}>
							<ShareButton>
								<svg width="10" height="19" viewBox="0 0 10 19" fill="none">
									<path
										d="M9.34469 10.6875L9.86325 7.24895H6.62096V5.01756C6.62096 4.07684 7.07388 3.15986 8.526 3.15986H10V0.232305C10 0.232305 8.66239 0 7.38349 0C4.71337 0 2.96806 1.64691 2.96806 4.62828V7.24895H0V10.6875H2.96806V19H6.62096V10.6875H9.34469Z"
										fill="white"
									/>
								</svg>
							</ShareButton>
						</FacebookShareButton>
						<TwitterShareButton url={post?.link}>
							<ShareButton style={{ backgroundColor: "#0a0a0a" }}>
								<svg
									width="20"
									height="16"
									viewBox="0 0 1200 1227"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
										fill="white"
									/>
								</svg>
							</ShareButton>
						</TwitterShareButton>
					</ShareContainer>
				</Content>
			</Box>
			<Helper />
		</WinnersItem>
	);
};

export default PostListItem;
