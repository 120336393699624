import { styled } from "@mui/material";

export const Container = styled("div")<{ scale: number }>`
	background-color: #efefef;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
	transform: scale(${(props) => props?.scale});
	width: 320px;
	padding: 12px;
`;

export const ContentBackground = styled("div")`
	background-color: #fff;
	border-radius: 16px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	margin-top: 4px;
`;

export const Text = styled("p")<{ color: string; align?: string }>`
	color: ${(props) => props.color};
	font-family: "Inter";
	font-size: 12px;
	line-height: 15px;
	font-weight: 700;
	margin: 0;
	text-align: ${(props) => props.align ?? "initial"};
`;

export const Plus = styled("img")`
	position: absolute;
	width: 32px;
	height: 35px;
	top: calc(50% - 4px);
	left: calc(50% - 12px);
`;

export const RewardImage = styled("img")`
	width: auto;
	height: 51px;
	margin-bottom: 4px;
`;
