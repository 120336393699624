import styled from "styled-components";
import { Typography } from "@mui/material";

export const BogoBox = styled.div`
	//margin-bottom: 5rem;
	position: absolute;
	top: 70%;
	transform: translateY(-50%);
	right: 0px;
	left: 0px;

	@media (max-width: 733px) {
		top: 58%;
		transform: translateY(-50%);
	}

	.bogo-button {
		width: 300px;
		margin: 3px auto;
		padding: 0.5rem;
		z-index: 1;
		height: 40px;
		@media (max-width: 733px) {
			top: 20px;
			width: 88%;
			height: unset;
		}

		@media (min-width: 1050px) {
			height: unset;
		}
	}
`;

export const OffersStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: stretch;
	@media (max-width: 733px) {
		//align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}
`;

export const OfferItem = styled.div`
	position: relative;
	//display: grid;
	grid-template-rows: auto;
	background-color: rgba(51, 51, 51, 0.3);
	border: 1px solid #9aeffc;
	border-radius: 12px;
	padding: 0.7rem;
	gap: 0.5rem;
	margin: 3px;
	width: 200px;
	height: auto;
	z-index: 999;
	align-content: stretch;
	&.loyalty-reward {
		align-content: stretch;
	}
	&.one-item {
		min-width: 400px;
		width: auto;
		.item-icon {
			width: 55px;
			height: 55px;
		}
	}
	&.two-items {
		min-width: 250px;
	}
	&.several-items {
		min-width: 200px;
	}

	@media (max-width: 733px) {
		display: grid;
		width: 85%;
		&.one-item {
			min-width: unset;
			width: 85%;
		}
	}
	@media (min-width: 1050px) {
		.item-icon {
			width: 65px;
			height: 65px;
		}
		gap: 1.2rem;
		&.one-item {
			.item-icon {
				width: 65px;
				height: 65px;
			}
		}
		&.two-items {
			min-width: 350px;
		}
	}
`;

export const BogoItem = styled.div`
	align-items: center;
	display: flex;
	@media (max-width: 733px) {
		flex-direction: column;
	}
`;

export const Items = styled.div`
	justify-content: center;
	align-items: end;
	display: flex;
	//flex-wrap: wrap;
`;

export const RewardItem = styled.div<{ width: string }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${(props) => props.width};
	box-sizing: border-box;
	padding: 1px;
`;

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 11px;
	@media (min-width: 1050px) {
		font-size: 15px;
	}
`;

export const ItemImage = styled.img`
	width: 46px;
	height: 46px;
	object-fit: contain;
	justify-self: center;
	align-self: center;
`;

export const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	padding: 0.5rem 0.8rem;
	border-radius: 100px;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.2rem;
	}
`;

export const Plus = styled.img`
	width: 40px;
	height: 42px;
	margin: 0 1rem;
	@media (max-width: 733px) {
		margin: 0.4rem 0;
	}
`;

export const FreeTag = styled.img`
	width: 105px;
	height: 93px;
	object-fit: contain;
	position: absolute;
	top: -3.8rem;
	right: 8%;
`;
