import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { claimDipReward } from "../thunks";
import { DipSlice } from "../types";

export const CLAIM_REWARD_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<DipSlice>
): void => {
	builder.addCase(claimDipReward.rejected, (state) => {
		state.claimRewardProcess.error = true;
		state.claimRewardProcess.loading = false;
	});

	builder.addCase(claimDipReward.pending, (state) => {
		state.claimRewardProcess.loading = true;
		state.claimRewardProcess.error = false;
	});

	builder.addCase(claimDipReward.fulfilled, (state) => {
		state.claimRewardProcess.loading = false;
		state.claimRewardProcess.error = false;
	});
};
