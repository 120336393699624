import { RefObject } from "react";
import {
	IShopPurchaseOfferMultiItem,
	IShopSection
} from "contexts/WebStoreProvider/types";
import getSectionIcon from "utils/svgIcons";
import { FeaturedOfferCard } from "../FeaturedOfferCard";
import Section from "components/Section";
import { sortOfferByPriority } from "helpers/sort";

const FeaturedOfferSection = ({
	data,
	sectionRef,
	sectionOffers
}: {
	className?: string;
	data: IShopSection;
	sectionRef: RefObject<HTMLElement>;
	sectionOffers: IShopPurchaseOfferMultiItem[];
}): JSX.Element => {
	const offers: JSX.Element[] = sortOfferByPriority(sectionOffers).map(
		(offer) => {
			return (
				<FeaturedOfferCard
					data={offer as IShopPurchaseOfferMultiItem}
					key={offer.rewardId}
					sectionName={data.name}
				/>
			);
		}
	);

	const sectionProps = {
		offers,
		sectionRef,
		headerText: data.title,
		iconUrl: getSectionIcon(data.name)?.url,
		icon: getSectionIcon(data.name)?.svg,
		display: "carousel",
		sectionPage: "featured"
	};

	return <Section {...sectionProps} />;
};

FeaturedOfferCard.displayName = "FeaturedOfferSection";

export { FeaturedOfferSection };
