import React, { RefObject, useRef } from "react";
import {
	IShopPurchaseOfferSingleItem,
	IShopSection
} from "contexts/WebStoreProvider/types";
import getSectionIcon from "utils/svgIcons";
import { OfferCard } from "../OfferCard";
import Section from "components/Section";
import { OfferCardV2 } from "../OfferCardV2";
import { useEnvContext } from "contexts";
import { FeaturesFlags } from "helpers";

function OfferSection({
	data,
	sectionRef,
	sectionOffers
}: {
	className?: string;
	data: IShopSection;
	sectionRef: RefObject<HTMLElement>;
	uniqueKey?: string;
	sectionOffers: IShopPurchaseOfferSingleItem[];
}) {
	const { cardBackgroundImageUrl: backgroundImageUrl = "" } = data;
	const { isFeatureFlagEnabled } = useEnvContext();
	const itemRefs = useRef(
		sectionOffers.map(() => React.createRef<HTMLDivElement>())
	);

	const offers: JSX.Element[] = sectionOffers.map((o, i) =>
		isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
			<OfferCardV2
				itemRef={itemRefs?.current[i]}
				data={o as IShopPurchaseOfferSingleItem}
				key={o.id + i}
			/>
		) : (
			<OfferCard
				itemRef={itemRefs?.current[i]}
				data={o as IShopPurchaseOfferSingleItem}
				backgroundImageUrl={backgroundImageUrl}
				key={o.id + i}
			/>
		)
	);

	const sectionProps = {
		offers,
		sectionRef,
		headerText: data.title,
		iconUrl: getSectionIcon(data.name)?.url,
		icon: getSectionIcon(data.name)?.svg,
		display: "grid",
		isHideShowLess: true,
		sectionPage: "store"
	};

	return <Section {...sectionProps} />;
}

OfferSection.displayName = "OfferSection";
export { OfferSection };
