import styled from "styled-components";

export const landingPageContainerSxProps = {
	position: "relative",
	paddingBottom: "9rem",
	paddingTop: "55px",
	marginTop: "-55px",
	backgroundSize: {
		xs: "100% auto, contain",
		sm: "174% auto, contain",
		md: "140% auto, contain",
		xl: "100% auto, contain"
	},
	backgroundPosition: {
		xs: "87% top,center 75px",
		sm: "61% top, center",
		md: "center top, center"
	},
	backgroundRepeat: {
		xs: "no-repeat, repeat"
	}
};

export const SectionContent = styled.div`
	@media (min-width: 450px) and (max-width: 600px) {
		margin-top: 2rem;
	}
`;
