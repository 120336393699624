import { useAppSelector } from "hooks";
import { useEffect } from "react";
import { selectPopUpManagerState } from "redux/slices";

export const useInitialActions = (): void => {
	const { variant } = useAppSelector(selectPopUpManagerState);
	useEffect(() => {
		if (typeof variant === "undefined") {
			return;
		}
		document.querySelector("body")?.classList.add("stop-scroll");
		return () => {
			document.querySelector("body")?.classList.remove("stop-scroll");
		};
	}, [variant]);
};
