import classNames from "classnames";
import starBurst from "assets/pois-bounty/star-burst.png";
import { useAppDispatch, useAppSelector, useImagePath } from "hooks";
import { Marker, ClaimButton, StatusIcon, Target } from "..";
import { usePoisBountyContext } from "components/PoisBountyWidget/context";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { StepProps } from "./types";
import { nFormatter } from "utils/number-formatter";
import { Indicator } from "components/PoisBountyWidget/lib/common";
import { onClaimReward, selectPoisBountyState, onPBchange } from "redux/slices";
import { StepContext } from "./context";
import { useCallback, useMemo } from "react";
import {
	DEFAULT_CONFIG,
	getPrizeClassNames,
	getProgressBarWidth,
	getProgressClassNames,
	getRewardItemClassNames,
	getStepClassNames,
	getTextClassNames
} from "./helper";
import {
	Bar,
	Percentage,
	ProgressRewardStep,
	Reward,
	StarBurst,
	RewardImage,
	StepStyled,
	RewardText,
	RewardItem,
	RewardPrizeImage
} from "./styles";

export const Step = ({
	reward,
	stepIndex,
	config = DEFAULT_CONFIG
}: StepProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const { userLoyaltyPoints, data, rewards } = useAppSelector(
		selectPoisBountyState
	);
	const { hideBar, first, last } = config;
	const { target, status, rewardAmount, imageUrl } = reward;
	const { getImage } = useImagePath("cdn");
	const isInProgress = status === "in-progress";
	const isLocked = status === "locked";

	const onClaim = useCallback(() => {
		const { tracker } = data;

		dispatch(onPBchange({ rewardCollected: reward }));
		dispatch(onClaimReward(stepIndex));
		reportEvent(`tier_${stepIndex + 1}_clicked`, {
			source: "pois_bounty_menu",
			pois_bounty_id: tracker.eventId,
			reward_id: rewards[stepIndex].reward.rewardId
		});
	}, []);

	const values = useMemo(() => ({ target, last, status }), [status]);

	return (
		<StepContext.Provider value={values}>
			<StepStyled className={classNames(getStepClassNames(isPortrait, first))}>
				{/* 🎁 Reward:  item, marker, target and claim button */}
				<Reward className="reward">
					<RewardItem
						className={classNames(
							getRewardItemClassNames(status, isPageVariant)
						)}
					>
						{isInProgress && <StarBurst src={starBurst} />}
						<StatusIcon status={status} />
						<RewardImage
							data-image={getImage(
								"/spec_pois_bounty/pois_bounty_reward_background.png"
							)}
						>
							<RewardPrizeImage
								src={imageUrl}
								className={getPrizeClassNames(status)}
							/>
							<RewardText
								className={classNames(getTextClassNames(status, isPageVariant))}
							>
								x{nFormatter(rewardAmount)}
							</RewardText>
						</RewardImage>
					</RewardItem>
					<Marker />
					<Target />
					<ClaimButton onClick={onClaim} />
				</Reward>

				{/* 📊 Reward Progress Bar */}
				<ProgressRewardStep
					className={classNames(getProgressClassNames(first, hideBar))}
				>
					<Bar className="reward-bar">
						{!hideBar && (
							<Percentage
								className={classNames(first && "first")}
								style={{
									width: getProgressBarWidth(
										status,
										userLoyaltyPoints,
										target,
										isPageVariant
									)
								}}
							>
								{isLocked && (
									<Indicator
										lastStep={last}
										userLoyaltyPoints={userLoyaltyPoints}
									/>
								)}
							</Percentage>
						)}
					</Bar>
				</ProgressRewardStep>
			</StepStyled>
		</StepContext.Provider>
	);
};
