import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { getNavigationMenu } from "utils/getNavigationMenu";
import { SubMenuOption } from "../SubMenuOption";
import { useDispatch } from "react-redux";
import { Navigation } from "interfaces";
import { useAppSelector } from "hooks";
import { isSaleActive } from "./helper";
import {
	selectPoisBountyState,
	selectPoisBountyV2State,
	selectStickySaleTagState,
	setOpen
} from "redux/slices";

interface Props {
	categoryId: number;
}

export const SubMenu: React.FC<Props> = ({ categoryId }) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { userTracker } = useWebStoreContext();
	const { saleTags } = useAppSelector(selectStickySaleTagState);
	const { isEventActive } = useAppSelector(selectPoisBountyState);
	const { isEventActiveV2 } = useAppSelector(selectPoisBountyV2State);
	const dispatch = useDispatch();
	const isPoisActive: boolean = isEventActive || isEventActiveV2;
	const navigation = getNavigationMenu(
		userTracker?.clubId,
		isPoisActive,
		userTracker
	);

	const reportToGoogle = (analytics_key: string, sale_tag = false) => {
		reportEvent(analytics_key, {
			source: "mega_menu",
			sale_tag: sale_tag
		});
	};

	const handleClick = (page: Navigation) => {
		window.scrollTo(0, 0);
		dispatch(setOpen(false));
		const sale_actived = isSaleActive(saleTags, page.title);
		reportToGoogle(page.analytics_key, sale_actived);
	};

	return (
		<>
			{navigation
				.filter((n) => n.categoryId === categoryId)
				.map((page) => {
					if (!page.isEnabled) {
						return null;
					}
					if (page.isExternal) {
						return (
							<Link
								href={page.path}
								style={{ textDecoration: "none" }}
								key={page.path}
								target="_blank"
								onClick={() => reportToGoogle(page.analytics_key)}
							>
								<SubMenuOption page={page} />
							</Link>
						);
					}

					return (
						<RouterLink
							to={page.path}
							style={{ textDecoration: "none" }}
							key={page.path}
							onClick={() => handleClick(page)}
						>
							<SubMenuOption page={page} />
						</RouterLink>
					);
				})}
		</>
	);
};
