export const Test = () => {
	return (
		<div
			style={{
				minHeight: "50vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
		></div>
	);
};
