import styled from "styled-components";
import Button from "components/Buttons/ThemeButton";
import { isIPad13 } from "react-device-detect";

export const ButtonStyled = styled(Button)`
	box-shadow: 0px 3.64835px 3.64835px rgba(0, 0, 0, 0.25);
	border-radius: 7.2967px;
	width: 90%;
	padding: 1rem 0;
	font-size: 12.7692px;
	line-height: 1;
	transition: all ease 0.3s;
	transform: translateY(${isIPad13 ? "15px" : 0});
	&.page-variant {
		width: 110px;
		padding: 1rem 2.5rem;
	}
	&.mega-menu-variant {
		width: 85px;
		font-size: 0.8rem;

		@media (max-width: 1120px) {
			width: 75px;
		}
		@media (max-width: 990px) {
			width: 65px;
		}
	}
	&.portrait {
		padding: 1rem 2rem;
	}
	&.unlocked {
		color: #7b7b7b;
		background-color: #b9b9b9;
		text-shadow: unset;
	}
	&.locked {
		background: transparent !important;
		opacity: 0.5;
		color: #b9b9b9;
		border: 2px solid #b9b9b9;
		text-shadow: unset;
	}

	@media (min-width: 755px) and (max-width: 950px) {
		padding: 0.8rem 0.5rem;
		min-height: 2rem;
		min-width: 2rem;
	}
`;
