/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
	BgBrush,
	CustomAccordion,
	DotIcon,
	Container,
	ItemsContainer,
	Title,
	Character
} from "./styles";
import { ReactComponent as Arrow } from "assets/faq/arrow.svg";
import { faqData } from "./helpers";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import mini1 from "assets/faq/mini1.png";
import mini2 from "assets/faq/mini2.png";

export const FAQ = () => {
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	return (
		<Container>
			<BgBrush />
			<Title>{"GOT QUESTIONS?"}</Title>
			<Character
				src={mini1}
				style={{
					right: "50%",
					transform: "translateX(-50%)",
					top: "250px",
					marginRight: "20%"
				}}
			/>
			<Character
				src={mini2}
				style={{
					left: "50%",
					transform: "translateX(50%)",
					top: "215px",
					marginLeft: "20%"
				}}
			/>

			<ItemsContainer>
				{faqData.map((item, index) => (
					<CustomAccordion
						key={index}
						expanded={expanded === `panel${index + 1}`}
						onChange={handleChange(`panel${index + 1}`)}
						sx={{ marginBottom: "8px", borderRadius: "12px" }}
					>
						<AccordionSummary
							expandIcon={<Arrow />}
							aria-controls={`panel${index + 1}bh-content`}
							id={`panel${index + 1}bh-header`}
							sx={{ backgroundColor: "#FBBD05", borderRadius: "12px" }}
						>
							<Typography
								sx={{
									width: "100%",
									flexShrink: 0,
									fontWeight: "700",
									lineHeight: "18px"
								}}
							>
								{item.question}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{item.answer.type === "text" ? (
								<Typography>{item.answer.content}</Typography>
							) : (
								item.answer.type === "steps" &&
								Array.isArray(item.answer.content) && (
									<List disablePadding>
										{item.answer.content.map((step, index) => (
											<ListItem key={index} disablePadding>
												<ListItemIcon
													sx={{ minWidth: "20px", alignSelf: "flex-start" }}
												>
													<DotIcon />
												</ListItemIcon>
												<ListItemText
													primary={step}
													sx={{ marginTop: "2px", marginBottom: "2px" }}
												/>
											</ListItem>
										))}
									</List>
								)
							)}
						</AccordionDetails>
					</CustomAccordion>
				))}
			</ItemsContainer>
		</Container>
	);
};
