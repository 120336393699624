import { CSSProperties } from "styled-components";

export const timerStyle: CSSProperties = {
	fontSize: 14
};

export const timerMobileStyle: CSSProperties = {
	fontSize: 11,
	left: "1%",
	padding: "4px 8px"
};
