import { Typography } from "@mui/material";
import ScopelyLoginButton from "components/Buttons/ScopelyLoginButton";
import { useEnvContext } from "contexts/EnvProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { FeaturesFlags } from "helpers";
import FacebookButton from "../../Buttons/FacebookLoginButton";

export const LoginButtons = () => {
	const { userTracker, authToken, setActiveItemToBeClicked } =
		useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	return (
		<>
			{isFeatureFlagEnabled(FeaturesFlags.SCOPELY_LOGIN) &&
				(!userTracker || !authToken) && (
					<ScopelyLoginButton
						prevCb={() => setActiveItemToBeClicked(null)}
						hideLogoutState
						source={"right_menu"}
					/>
				)}
			{(!userTracker || !authToken) && (
				<FacebookButton
					prevCb={() => setActiveItemToBeClicked(null)}
					hideLogoutState
					source={"right_menu"}
				/>
			)}

			{(!userTracker || !authToken) && (
				<Typography
					component="div"
					variant="caption"
					sx={{
						marginBottom: 5,
						marginTop: 2,
						display: "flex",
						flexDirection: "column"
					}}
				>
					Make sure your account is connected in the mobile game app first.
				</Typography>
			)}
		</>
	);
};
