import { PREFIX } from "./helper";
import { initialState } from "./initial-state";
import { createSlice } from "@reduxjs/toolkit";
import { postUserTrackerValue } from "./thunks";

export const commonSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(postUserTrackerValue.fulfilled, () => {});
	}
});

export default commonSlice.reducer;
export * from "./types";
export * from "./thunks";
