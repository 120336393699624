import classNames from "classnames";
import { useAppDispatch } from "hooks";
import { onClosePopUp } from "redux/slices";
import useBreakpoints from "hooks/useBreakpoints";
import avatar from "assets/pois-bounty/v2/pois-logo.png";
import { Carousel, Grid, itemsV2 } from "../../PB-common/learn-more-ftue";
import background from "assets/pois-bounty/v2/bg-page-pois-bounty-ftue-v2.png";
import {
	Alignment,
	Avatar,
	Background,
	Body,
	Bold,
	Button,
	Container,
	Copy,
	Footer,
	Head,
	Note,
	Title
} from "./styles";

interface PBFTUEV2Props { }

export const PBFTUEV2: React.FC<PBFTUEV2Props> = () => {
	const dispatch = useAppDispatch();
	const { isSmDown } = useBreakpoints();

	return (
		<Container className={classNames(isSmDown && "mobile")}>
			<Head className="head">
				<Background src={background} />
				{isSmDown ? (
					<Title className="avatar-title" src={avatar} />
				) : (
					<Avatar className="avatar" src={avatar} />
				)}
			</Head>
			<Alignment className="alignment">
				<Body>{isSmDown ? <Carousel items={itemsV2} /> : <Grid items={itemsV2} />}</Body>
				<Footer>
					<Copy className="copy">
						<Note className="note">Please Note:</Note>
						<Bold>
							Loyalty points are not being deducted for Poi’s Bounty progression.
						</Bold>{" "}
						Only Loyalty Points accumulated during the sale are counted towards your progression.
					</Copy>
				</Footer>
			</Alignment>
			<Button className="button" themeColor="secondary" onClick={() => dispatch(onClosePopUp())}>
				Continue Shopping
			</Button>
		</Container>
	);
};
