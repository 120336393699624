import { Skeleton } from "@mui/material";
import { ReactElement } from "react";
import { Content as ContentContainer } from "./components/SectionContentGrid";

/* eslint-disable */
const loop = (times: number, callback: (i: number) => ReactElement) => {
	return [...Array(times)].map((item: any, i: number) => callback(i));
};
/* eslint-enable */

export const PostListItemSkeleton = ({
	className = ""
}: {
	className?: string;
}) => (
	<Skeleton
		animation="wave"
		variant="rectangular"
		height={369}
		sx={{ marginBottom: 5, paddingBottom: 0, borderRadius: "12px" }}
		className={className}
	/>
);

const NewsPageContentSkeleton = () => {
	return (
		<>
			<ContentContainer>
				{loop(6, (i: number) => (
					<PostListItemSkeleton key={"post-item-" + i} />
				))}
			</ContentContainer>
		</>
	);
};

export const NewsArticleContentHeaderSkeleton = () => (
	<Skeleton
		animation="wave"
		variant="rectangular"
		height="22vw"
		sx={{ marginBottom: 5, paddingBottom: 0 }}
	/>
);
export const NewsListItemContentHeaderSkeleton = () => (
	<Skeleton
		animation="wave"
		variant="rectangular"
		height="33%"
		sx={{ marginBottom: 5, paddingBottom: 0 }}
	/>
);

export { NewsPageContentSkeleton };
