import { links } from "helpers";
import { useWebStoreContext } from "contexts";
import { Fragment, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

interface PrivateRouteProps {
	children: JSX.Element | JSX.Element[];
	isEnabled: boolean;
}

export function PrivateRoute({ isEnabled, children }: PrivateRouteProps) {
	const [searchParams] = useSearchParams();
	const [isImmediateLoginActive, setIsImmediateLoginActive] = useState<boolean>(false);
	const { isAutoLoginIn } = useWebStoreContext();
	const navigate = useNavigate();

	useEffect(() => {
		const params: { [key: string]: string } = {};
		for (const entry of searchParams.entries()) {
			params[entry[0]] = entry[1];
		}
		setIsImmediateLoginActive(params?.token && params?.loginType === "auto" ? true : false);
	}, [searchParams]);

	if (!isEnabled && !isAutoLoginIn && !isImmediateLoginActive) {
		navigate(links.home.path);
		return <Navigate to={links.home.path} />;
	}

	return <Fragment>{children}</Fragment>;
}
