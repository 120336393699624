import { Box, styled } from "@mui/material";

export const pageHeroWrapperBoxSxProps = {
	height: { xs: "132vw", sm: "63vw", md: "52vw", lg: "48vw", xl: "33vw" },
	"*": {
		transition: "all .3s ease-out"
	}
};

export const pageHeroBoxSxProps = {
	position: "relative",
	display: "flex",
	justifyContent: "center",
	bottom: "auto",
	top: { xs: "10%", sm: "12%" },
	margin: { xs: 0, lg: "0 -6vw" },
	"img.left, img.right": {
		position: "absolute",
		bottom: 0
	},
	"img.left": {
		left: { xs: 0, sm: "3%", md: "6%" },
		bottom: { xs: "-107%", sm: "-54%" },
		height: { xs: "113%", sm: "102%" }
	},
	"img.right": {
		right: { xs: 0, md: "6%" },
		bottom: { xs: "-77%", sm: "-54%" },
		height: { xs: "73%" }
	}
};

export const heroGetGameSxProps = {
	position: "absolute",
	bottom: { xs: "-147%", sm: "-60%", md: "-40%", lg: "-42%" },
	width: { xs: "100%", sm: "auto" },
	alignItems: { xs: "center" },
	display: { xs: "flex" },
	zIndex: "111"
};

export const HoverHangBox = styled(Box)`
	@-webkit-keyframes hvr-hang {
		0% {
			transform: translateY(8px);
		}
		50% {
			transform: translateY(4px);
		}
		100% {
			transform: translateY(8px);
		}
	}
	@keyframes hvr-hang {
		0% {
			transform: translateY(8px);
		}
		50% {
			transform: translateY(4px);
		}
		100% {
			transform: translateY(8px);
		}
	}
	@-webkit-keyframes hvr-hang-sink {
		100% {
			transform: translateY(8px);
		}
	}
	@keyframes hvr-hang-sink {
		100% {
			transform: translateY(8px);
		}
	}

	/*
		Hang - reversed non-hover hanging
	*/
	&.hvr-hang {
		animation-name: hvr-hang-sink, hvr-hang;
		animation-duration: 0.3s, 1.5s;
		animation-delay: 0s, 0.3s;
		animation-timing-function: ease-out, ease-in-out;
		animation-iteration-count: 1, infinite;
		animation-fill-mode: forwards;
		animation-direction: normal, alternate;
	}
	&.hvr-hang:hover,
	&.hvr-hang:focus,
	&.hvr-hang:active {
		display: inline-block;
		vertical-align: middle;
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	}

	&.hvr-hang:active {
		opacity: 0.5;
	}
`;
