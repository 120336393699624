import { Dip } from "interfaces";

export const getClassNameVariant = (buttonsLength: number): string => {
	switch (buttonsLength) {
		case 1:
			return "one-item";
		case 2:
			return "two-items";
		default:
			return "several-items";
	}
};

export const dispatchLogOut = (activeDip: Dip): boolean => {
	const { triggerType } = activeDip.dip;
	return triggerType === "scopelyLogout" || triggerType === "facebookLogout";
};

/* TP-26267 Close Click Tracking */
/* eslint-disable */
export const getCloseClickTrackingParams = (
	dipId?: string,
	buttons: any[] = []
) => {
	const purchase: any = [];
	buttons.map((button: any) => {
		const { loyaltyOfferId, offerId, rewardId } = button;
		const buttonTrackingItem: any = {};
		if (loyaltyOfferId) {
			buttonTrackingItem.loyaltyOfferId = loyaltyOfferId;
		}
		if (offerId) {
			buttonTrackingItem.offerId = offerId;
		}
		if (rewardId) {
			buttonTrackingItem.rewardId = rewardId;
		}
		purchase.push(buttonTrackingItem);
	});

	return {
		dip_id: dipId,
		purchase,
		page_location: location.pathname
	};
};
/* eslint-enable */
