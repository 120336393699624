import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitleProps } from "components/BootstrapDialog";

export const XsollaModalTitle = (props: DialogTitleProps) => {
	const { children, onClose, isShowClose, ...other } = props;
	return (
		<DialogTitle
			sx={{
				m: 0,
				p: 4,
				textAlign: "center",
				backgroundColor: "#34AFBD",
				color: "white",
				fontWeight: "bold",
				fontSize: {
					md: "clamp(1.5rem, 7vw, 3rem)",
					xs: "clamp(1.5rem, 4.2vw, 3rem)"
				}
			}}
			{...other}
		>
			{children}
			{isShowClose && onClose && (
				<IconButton
					aria-label="close"
					onClick={() => onClose()}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.common.white
					}}
				>
					<CloseIcon />
				</IconButton>
			)}
		</DialogTitle>
	);
};
