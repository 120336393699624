import styled from "styled-components";

export const Container = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #073670;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
`;

export const ErrorMessage = styled.div`
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	color: #e00707;
`;

export const LoadingMessage = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #073670;
`;
