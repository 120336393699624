import { Box } from "@mui/material";
import AppStoreButton from "components/Buttons/AppStoreButton";

export const StoreButtons = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				marginTop: "35px",
				img: {
					margin: "0 .5rem"
				}
			}}
		>
			<AppStoreButton type="apple" source="mega_menu" />
			<AppStoreButton type="google" source="mega_menu" />
		</Box>
	);
};
