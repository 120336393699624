import React, { useEffect } from "react";
import classNames from "classnames";
import { Navigation } from "interfaces";
import { useSaleTag } from "./hooks/useSaleTag";
import { hideHamburgerDot } from "redux/slices";
import { useAppDispatch, useWindowSize } from "hooks";
import { getDesktopGradient, getMobileGradient } from "./helper";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import {
	ItemDescription,
	ItemTitle,
	OptionContainer,
	TitleContainer,
	Tag,
	TagText
} from "./styles";
import {
	ClubGiftIcon,
	ClubShopIcon,
	CrownIcon,
	DailyGiftIcon,
	FaqIcon,
	ForumIcon,
	HomeIcon,
	NewsIcon,
	PoisBountyIcon,
	ShopIcon,
	ExternalIcon,
	MerchShopIcon,
	WinnersIcon,
	DripOfferIcon,
} from "utils/svgIcons";

interface Props {
	page: Navigation;
}

export const SubMenuOption: React.FC<Props> = ({ page }) => {
	const dispatch = useAppDispatch();
	const activeSaleTag = useSaleTag(page.title);
	const { reportEvent } = useGoogleAnalyticsContext();
	const mobile = useWindowSize().width <= 819;

	useEffect(() => {
		if (typeof activeSaleTag === "undefined") {
			return;
		}
		const { startTime, endTime, megaMenuTagText, megaMenuHeaderColorHex } =
			activeSaleTag;

		const eventName =
			page.mega_menu_sale_tag_event_name != null
				? page.mega_menu_sale_tag_event_name
				: "";

		reportEvent(eventName, {
			source: "mega_menu_sale_tag",
			sale_start_time: startTime,
			sale_end_time: endTime,
			megaMenuTagText,
			megaMenuHeaderColorHex
		});
	}, [activeSaleTag]);

	return (
		<OptionContainer
			background-color={activeSaleTag && activeSaleTag.megaMenuHeaderColorHex}
			className={classNames(activeSaleTag && "sale-tag")}
			onClick={() => {
				if (activeSaleTag) {
					dispatch(hideHamburgerDot(activeSaleTag.id));
				}
			}}
			style={{
				background:
					activeSaleTag && !mobile
						? getDesktopGradient(activeSaleTag.megaMenuHeaderColorHex)
						: activeSaleTag && mobile
						? getMobileGradient(activeSaleTag.megaMenuHeaderColorHex, "body")
						: "transparent"
			}}
		>
			<TitleContainer>
				{page.icon === "home" && <HomeIcon />}
				{page.icon === "crown" && <CrownIcon />}
				{page.icon === "club" && <ClubShopIcon />}
				{page.icon === "store" && <ShopIcon />}
				{page.icon === "news" && <NewsIcon />}
				{page.icon === "daily-gift" && <DailyGiftIcon />}
				{page.icon === "club-gift" && <ClubGiftIcon />}
				{page.icon === "faq" && <FaqIcon />}
				{page.icon === "forum" && <ForumIcon />}
				{page.icon === "winners" && <WinnersIcon />}
				{page.icon === "pois-bounty" && <PoisBountyIcon />}
				{page.icon === "merch-shop" && <MerchShopIcon />}
				{page.icon === "drip-offer" && <DripOfferIcon />}

				<ItemTitle>{page.title}</ItemTitle>
				{page.isExternal && (
					<ExternalIcon
						color="#A6A6A6"
						style={{ transform: "translateY(-1px)", marginLeft: 5 }}
					/>
				)}
			</TitleContainer>
			<ItemDescription>{page.description}</ItemDescription>
			{typeof activeSaleTag !== "undefined" && (
				<Tag
					className={classNames(mobile && "mobile")}
					style={{
						background: mobile
							? getMobileGradient(activeSaleTag.megaMenuHeaderColorHex, "head")
							: activeSaleTag.megaMenuHeaderColorHex
					}}
				>
					<TagText className={classNames(mobile && "mobile")}>
						{activeSaleTag.megaMenuTagText}
					</TagText>
				</Tag>
			)}
		</OptionContainer>
	);
};
