import { createContext, useContext } from "react";

interface IPoisContext {
	isPortrait: boolean;
	isPageVariant: boolean;
}

export const PoisBountyContext = createContext<IPoisContext>({
	isPortrait: false,
	isPageVariant: false
});

export const usePoisBountyContext = (): IPoisContext => {
	const context = useContext(PoisBountyContext);
	if (!context) {
		throw new Error("You need a PoisBountyContext Provider");
	}
	return context;
};
