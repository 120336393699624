import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";

type DataType = Record<
	string,
	Omit<
		IShopPurchaseOffer,
		"onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText"
	>[]
>;
type OfferType = Omit<
	IShopPurchaseOffer,
	"onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText"
>;

export const SortStyckyDips = (stickyData: DataType, contentLength: number) => {
	const availableSlots = 5 - contentLength;
	const sortedStickyDataByDates = Object.entries(stickyData)
		.sort(
			(
				[keyA, arrayA]: [string, OfferType[]],
				[keyB, arrayB]: [string, OfferType[]]
			) => {
				console.log(keyA, keyB);
				//* Verify both arrays have elements.
				if (!arrayA[0] || !arrayB[0]) {
					//* If array is empty, no changes apply.
					return 0;
				}
				const dateA = arrayA[0].offerMetadata?.dipViewedDate
					? new Date(arrayA[0].offerMetadata.dipViewedDate).getTime()
					: null;
				const dateB = arrayB[0].offerMetadata?.dipViewedDate
					? new Date(arrayB[0].offerMetadata.dipViewedDate).getTime()
					: null;
				//* If dateA or dateB are null sort accordingly
				if (dateA === null && dateB === null) return 0; // if both are null they are equal
				if (dateA === null) return 1; // If dateA is null it's placed after dateB
				if (dateB === null) return -1; // If dateB is null it's placed after dateA

				//* If both have valid dates we compare
				return dateA - dateB;
			}
		)
		.slice(0, availableSlots)
		.sort(
			(
				[keyA, arrayA]: [string, OfferType[]],
				[keyB, arrayB]: [string, OfferType[]]
			) => {
				console.log(keyA, keyB);
				if (!arrayA[0] || !arrayB[0]) {
					return 0;
				}
				const dateA = arrayA[0].timerExpirationDate
					? new Date(arrayA[0].timerExpirationDate).getTime()
					: null;
				const dateB = arrayB[0].timerExpirationDate
					? new Date(arrayB[0].timerExpirationDate).getTime()
					: null;

				if (dateA === null && dateB === null) return 0;
				if (dateA === null) return 1;
				if (dateB === null) return -1;

				return dateA - dateB;
			}
		);
	return sortedStickyDataByDates;
};
