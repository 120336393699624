import { IDialogProps } from "interfaces";
import { DialogSlice } from "./types";

export const initialDialogState: IDialogProps = {
	title: "",
	subtitle: "",
	message: "",
	type: undefined,
	isContinueButtonEnabled: true,
	isReLoginEnabled: false,
	isReloadButtonEnabled: false,
	section: undefined
};

export const initialState: DialogSlice = {
	variant: undefined,
	props: initialDialogState
};
