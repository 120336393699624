import { styled } from "@mui/material";

export const PoisBountyContainer = styled("div")`
	position: relative;
	border-radius: 18px;
	background-color: #0183a2;
	max-width: 503px;
	height: 460px;
	overflow: hidden;

	&.page-variant {
		height: 323px;
	}

	&.mega-menu-variant {
		transform: translateX(10px);
		@media (min-width: 755px) and (max-width: 1120px) {
			max-width: 450px;
		}
		@media (min-width: 755px) and (max-width: 990px) {
			max-width: 400px;
		}
		@media (min-width: 755px) and (max-width: 780px) {
			max-width: 372px;
		}
	}
	background: linear-gradient(
		153.95deg,
		rgba(1, 125, 156, 0) 41.84%,
		#0183a2 86.81%
	);
	&.portrait {
		height: 740px;
		max-width: 370px;
		background-color: #0183a2;
	}
`;
