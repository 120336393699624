import { useEffect } from "react";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { useWebNewsContext } from "contexts/WebNewsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import {
	onFTUE,
	onOpenPopUp,
	selectPoisBountyState,
	selectPoisBountyV2State
} from "redux/slices";

const LS_PB_FTUE = "store.solitairetripeaks.com:pbFtue";

export const useInitialActions = (): void => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { userTracker } = useWebStoreContext();
	const { resetNewsState } = useWebNewsContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { data, responseSucceedWithNoTracker } = useAppSelector(
		selectPoisBountyState
	);
	const { dataV2, responseSucceedWithNoTrackerV2 } = useAppSelector(
		selectPoisBountyV2State
	);

	useEffect(() => {
		window && window.scrollTo(0, 0);
		resetNewsState();
	}, []);

	useEffect(() => {
		if (
			(isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2) &&
				responseSucceedWithNoTrackerV2) ||
			(!isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2) &&
				isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY) &&
				responseSucceedWithNoTracker)
		) {
			navigate("/");
		}
	}, [responseSucceedWithNoTracker, responseSucceedWithNoTrackerV2]);

	// display the popup if the user hasn't seen it
	useEffect(() => {
		const ls_ftue = localStorage.getItem(LS_PB_FTUE);
		if (
			(!Object.keys(data).length &&
				!isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2) &&
				isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY)) ||
			(!Object.keys(dataV2).length &&
				isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)) ||
			!userTracker ||
			ls_ftue
		) {
			return;
		}
		if (typeof userTracker.metadata?.poisBountyFtue !== "undefined") {
			return;
		}
		dispatch(onFTUE());
		if (isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)) {
			dispatch(onOpenPopUp("pb-pbftue-v2"));
		} else if (isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY)) {
			dispatch(onOpenPopUp("pb-pbftue"));
		}
		localStorage.setItem(LS_PB_FTUE, JSON.stringify(new Date()));
	}, [data, dataV2, userTracker]);
};
