import { PREFIX } from "./helper";
import { RootState } from "redux/store";
import { initialState } from "./initial-state";
import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { collectDailyBonus, getDailyLoginStreak } from "./thunks";

export const dailyLoginStreakSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onButtonDisable: (state, action: PayloadAction<boolean>) => {
			state.isClaiming = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(getDailyLoginStreak.fulfilled, collectDailyBonus.fulfilled),
			(state, action) => {
				const response = action.payload;
				if (typeof response === "undefined" || !("tracker" in response)) {
					return;
				}
				state.data = response;
				state.tracker = response.tracker;
			}
		);
	}
});

export const { onButtonDisable } = dailyLoginStreakSlice.actions;

export const selectDailyLoginStreakState = (state: RootState) =>
	state.dailyLoginStreak;
export default dailyLoginStreakSlice.reducer;
export * from "./types";
export * from "./thunks";
