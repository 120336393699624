import { getDebugLogger } from "../../utils/debugLogger";
import { ReactNode, useContext, useState } from "react";

// API Methods:
import { initAPI, IScopelyIdentityApi } from "./api";
// Types:
import { ConnectionStatus, ScopelyIdentityContext } from "./types";
// Constants:
import { DEBUG_COLOR } from "./constants";
// Other:
import createPersistedState from "use-persisted-state";
import { useEffectOnce } from "react-use";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LOGIN_ACTION } from "./api/login";
import { LOGOUT_ACTION, LOGOUT_NOT_CONNECTED_ACTION } from "./api/logout";
import { getScopelyStateFromSession } from "./helpers";
import { links } from "helpers";

const useSIDAccessTokenState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:sidAccessToken"
);

const useSIDIdentityTokenState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:sidIdentityToken"
);

const debug = getDebugLogger({
	isEnabled: !false,
	color: DEBUG_COLOR
});

const ScopelyIdentityProvider = ({ children }: { children: ReactNode }) => {
	const [redirectTo, setRedirectTo] = useState<string | undefined>();
	const [connectionStatus] = useState<ConnectionStatus>(
		ConnectionStatus.UNKNOWN
	);

	const [sidIdentityToken, setSIDIdentityToken] =
		useSIDIdentityTokenState(null);
	const [sidAccessToken, setSIDAccessToken] = useSIDAccessTokenState(null);

	const navigate = useNavigate();

	const api: IScopelyIdentityApi = initAPI({
		setSIDAccessToken,
		setSIDIdentityToken,
		debug
	});

	const onSuccess =
		(path = links.home.path) =>
		() =>
			navigate(redirectTo ?? path);

	const [searchParams] = useSearchParams();

	// Handle Redirect from Scopely
	useEffectOnce(() => {
		const searchParamState = searchParams.get("state");

		const tryConcludeScopelyLoginAction = async () => {
			if (searchParamState === null) {
				return;
			}

			let stateObj = getScopelyStateFromSession();
			stateObj = stateObj && JSON.parse(stateObj);
			stateObj = stateObj || { action: searchParamState };

			// console.log(
			// 	"tryConcludeScopelyLoginAction" +
			// 		"searchParamState:" +
			// 		searchParamState +
			// 		"\n stateObj?.action:\n " +
			// 		stateObj?.action
			// );

			if (stateObj?.action === LOGIN_ACTION) {
				await api.tryConcludeLogin(onSuccess(stateObj?.path));
			} else if (
				stateObj?.action === LOGOUT_ACTION ||
				stateObj?.action === LOGOUT_NOT_CONNECTED_ACTION
			) {
				await api.tryConcludeLogout(onSuccess(stateObj?.path));
			}
		};

		if (searchParamState !== null) {
			tryConcludeScopelyLoginAction().catch(console.log);
		}
	});

	return (
		<ScopelyIdentityContext.Provider
			value={{
				connectionStatus: connectionStatus,
				isConnected: connectionStatus === ConnectionStatus.CONNECTED,
				startLogin: api.startLogin,
				tryConcludeLogin: api.tryConcludeLogin,
				startLogout: api.startLogout,
				tryConcludeLogout: api.tryConcludeLogout,
				setScopelyAccessToken: setSIDAccessToken,
				setScopelyIdentityToken: setSIDIdentityToken,
				sidAccessToken: sidAccessToken,
				sidIdentityToken: sidIdentityToken,
				setRedirectTo
			}}
		>
			{children}
		</ScopelyIdentityContext.Provider>
	);
};

const useScopelyIdentityContext = () => {
	const context = useContext(ScopelyIdentityContext);
	if (context === undefined) {
		throw new Error(
			"useScopelyIdentityContext was used outside of its Provider"
		);
	}
	return context;
};

export default ScopelyIdentityProvider;
export { useScopelyIdentityContext };
