import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useWebStoreContext } from "contexts";
import { useAppDispatch } from "hooks";
import { useState } from "react";
import { setLoyaltyPoints } from "redux/slices";

const SetAddLoyaltyPointsDialog = () => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [fieldValue, setFieldValue] = useState(0);
	const { userTracker } = useWebStoreContext();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(e.target.valueAsNumber);
	};

	const handleSetLoyaltyPoints = () => {
		if (fieldValue < 0) {
			return;
		}
		dispatch(setLoyaltyPoints({ userId: userTracker?.id ?? "", loyaltyPoints: fieldValue }));
		setOpen(false);
		setFieldValue(0);
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Add
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Add Loyalty Points</DialogTitle>
				<DialogContent>
					<TextField
						onChange={onChange}
						autoFocus
						margin="dense"
						id="name"
						value={fieldValue}
						label="Loyalty Points"
						type="number"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSetLoyaltyPoints}>Set</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SetAddLoyaltyPointsDialog;
