import { useAppSelector } from "hooks";
import { selectWebStoreState } from "redux/slices";
import { ILoyaltyRewardBundleItem } from "..";

/**
 *
 */
/* eslint-disable */
export const useRewardItems = (items: any) => {
	const { rewardTypes } = useAppSelector(selectWebStoreState).store;

	const hasRewardItems = items ? Object.keys(items).length > 0 : false;
	const rewardItems: ILoyaltyRewardBundleItem[] = [];
	if (hasRewardItems) {
		for (const key in items) {
			const amount = items[key as any];
			for (const rewardType of rewardTypes) {
				if (rewardType.id === key) {
					const { imageUrl, pluralLabel, singularLabel } = rewardType;
					rewardItems.push({
						id: key,
						amount: amount as any,
						imageUrl,
						pluralLabel,
						singularLabel
					});
				}
			}
		}
	}

	return { hasRewardItems, rewardItems };
};
/* eslint-enable */
