import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as DeviceTablet } from "assets/DeviceTablet.svg";
import { ReactComponent as Gift } from "assets/Gift.svg";
import { ReactComponent as Key } from "assets/Key.svg";
import CloseIconButton from "../lib/CloseIconButton";
import { Fragment, useEffect } from "react";
import {
	StyledDialog,
	StyledDialogTitle,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogActions,
	StyledActionsBox,
	StyledList,
	StyledListItem,
	StyledListItemIcon,
	StyledListHeading
} from "./styled";
import { useWebStoreContext, useEnvContext } from "contexts";
import BackToGameButton from "components/Buttons/BackToGameButton";

const DialogYourAccountIsNotConnected = ({
	show,
	onCloseClick,
	onConnect
}: {
	show: boolean;
	onCloseClick: () => void;
	onConnect: () => void;
}) => {
	const { setCurrentOffer } = useWebStoreContext();
	const { cdnPrefix } = useEnvContext();
	const backgroundImage = `url(${cdnPrefix}/spec_dialogs/facebook-connect-background.png)`;

	useEffect(() => {
		setCurrentOffer(null);
	}, []);

	return (
		<StyledDialog open={show} onClose={onCloseClick}>
			<StyledDialogTitle sx={{ backgroundImage }}>
				<img
					src={`${cdnPrefix}/spec_dialogs/facebook-connect-foreground.png`}
				/>
				<CloseIconButton onClose={onCloseClick} />
			</StyledDialogTitle>
			<StyledDialogContent>
				<StyledDialogContentText>
					<StyledListHeading>Your Account Is Not Connected</StyledListHeading>
					<ListContent />
				</StyledDialogContentText>
			</StyledDialogContent>
			<StyledDialogActions>
				<StyledActionsBox>
					<BackToGameButton
						onClick={() => onConnect()}
						source={"account_not_connected_dialog"}
					>
						Connect
					</BackToGameButton>
				</StyledActionsBox>
			</StyledDialogActions>
		</StyledDialog>
	);
};

const ListContent = () => {
	return (
		<StyledList>
			<StyledListItem>
				<StyledListItemIcon>
					<DeviceTablet />
				</StyledListItemIcon>
				<ListItemText
					primary="Return to Tiki Solitaire TriPeaks"
					secondary={
						<Fragment>
							Go back to Tiki Solitaire TriPeaks on your device
						</Fragment>
					}
				/>
			</StyledListItem>
			<StyledListItem>
				<StyledListItemIcon>
					<Key />
				</StyledListItemIcon>
				<ListItemText
					primary="Connect Your Account"
					secondary={
						<Fragment>
							To purchase in the Web Shop, you must have your Tiki Solitaire
							Tripeaks account connected to either Facebook or Scopely. If you
							haven&lsquo;t yet done this, open the game, go to MENU, and tap
							ACCOUNTS. From there, connect to either your existing Facebook or
							Scopely account. If you don&lsquo;t have either, tap the HELP
							button for assistance
						</Fragment>
					}
				/>
			</StyledListItem>
			<StyledListItem>
				<StyledListItemIcon>
					<Gift />
				</StyledListItemIcon>
				<ListItemText
					primary="Return to Web Shop"
					secondary={
						<Fragment>
							Enjoy browsing the shop for now, and when you&lsquo;re ready to
							make a purchase, connect your account to either Facebook or
							Scopely
						</Fragment>
					}
				/>
			</StyledListItem>
		</StyledList>
	);
};

export { DialogYourAccountIsNotConnected };
