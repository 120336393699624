import { IDebugLogger } from "../../../utils/debugLogger";
import { ScopelyId } from "@scopely/web-sdk-scopelyid";
import { Dispatch, SetStateAction } from "react";
import {
	setScopelyStateToSession,
	removeScopelyStateSession
} from "../helpers";

export const LOGOUT_ACTION = "logout";
export const LOGOUT_NOT_CONNECTED_ACTION = "logout_not_connected";

export const getStartLogout = ({
	scopelyId,
	debug
}: {
	scopelyId: ScopelyId;
	debug: IDebugLogger;
}) => {
	return async (logoutActionType = LOGOUT_ACTION, origPath: string) => {
		debug.logFunction({
			iconText: "/ScopelyIdentityProvider/api/logout",
			message: ["startLogout", logoutActionType]
		});
		setScopelyStateToSession(
			JSON.stringify({ action: logoutActionType, path: origPath })
		);
		await scopelyId.logout(logoutActionType);
	};
};

export const getTryConcludeLogout = ({
	scopelyId,
	setSIDAccessToken,
	setSIDIdentityToken,
	debug
}: {
	scopelyId: ScopelyId;
	setSIDAccessToken: Dispatch<SetStateAction<string | null>>;
	setSIDIdentityToken: Dispatch<SetStateAction<string | null>>;
	debug: IDebugLogger;
}) => {
	return async (onSuccess?: () => void) => {
		debug.logFunction({
			iconText: "/ScopelyIdentityProvider/api/logout",
			message: ["concludeLogout"]
		});

		const logoutResult = await scopelyId.logoutResult(window.location.href);

		if (logoutResult.isSuccessful()) {
			removeScopelyStateSession();
			setSIDAccessToken(null);
			setSIDIdentityToken(null);
			onSuccess && onSuccess();
		} else {
			debug.log({
				iconText: "Scopely Logout Fail",
				message: [logoutResult.getError()]
			});
		}
		return logoutResult;
	};
};
