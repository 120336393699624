import { Button, ButtonGroup } from "@mui/material";
import styled from "styled-components";

export const ExpandedText = styled.span`
	width: 0;
	overflow: hidden;
	transition: width 0.3s ease-in-out;
	//transition: width 0.1s linear;
	white-space: nowrap;
	margin-left: 2px;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;

	&.expanded {
		width: 100px;
		padding: 5px;
	}

	@media (orientation: landscape) {
		width: fit-content;
		padding: 5px;

		&.expanded {
			width: fit-content;
		}
	}
`;

export const StyledButtonGroup = styled(ButtonGroup)({
	borderRadius: "20px",
	padding: "5px",
	backgroundColor: "#479fba",
	"& .MuiButtonGroup-grouped": {
		backgroundColor: "#479FBA",
		borderRadius: "20px"
	},
	"& .MuiButtonGroup-grouped:not(:last-of-type)": {
		borderRight: "0px"
	}
});

export const CustomButton = styled(Button)({
	height: 30,
	textTransform: "capitalize",
	padding: "8px",
	textAlign: "left",

	"&.expanded": {
		backgroundColor: "white",
		color: "#479fba",
		borderRadius: "25px !important"
	},

	"@media (orientation: landscape)": {
		borderRadius: "25px !important",
		margin: "0px 2px",
		border: "1px solid rgba(255, 255, 255, 0.30)!important"
	}
});

export const Container = styled.div`
	margin-top: 5px;
`;

export const Dot = styled.div`
	position: absolute;
	top: -1px;
	right: 3px;
	width: 13px;
	height: 13px;
	border-radius: 100%;
	background-color: #e53939;
`;
