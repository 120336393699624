import { Chip } from "@mui/material";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { Level } from "../styled";

export const UserLevelComponent = () => {
	const { userTracker } = useWebStoreContext();
	const isLevelEnabled = false;

	return (
		<>
			{userTracker && isLevelEnabled && (
				<Level>
					<div>
						<p className="label">Level</p>
						<div className="value">
							<Chip label={userTracker?.totalLoyaltyPointSpend} />
						</div>
					</div>
				</Level>
			)}
		</>
	);
};
