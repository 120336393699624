import { IContext } from ".";
import { GiftsGaloreTracker } from "interfaces";

export const setProviderValues = (tracker: GiftsGaloreTracker): IContext => {
	const { purchaseCount, purchaseCountRequired, qualifiedMemberCount } =
		tracker;

	return {
		tracker,
		eventIsCompleted: purchaseCount === purchaseCountRequired,
		moreClubMembersNeeded:
			purchaseCount + qualifiedMemberCount < purchaseCountRequired
	};
};
