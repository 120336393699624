import styled from "styled-components";
import { desktopGiftsGaloreView, tabletGiftsGaloreView } from "../../styles";

export const Container = styled.div`
	position: absolute;
	width: 350px;
	align-items: center;
	display: grid;
	grid-template-columns: auto 80px;
	transform: rotate(-90deg);
	left: unset;
	bottom: 35%;
	right: -128px;

	@media (min-width: ${tabletGiftsGaloreView}) {
		position: unset;
		width: 80%;
		grid-template-columns: auto 100px;
		transform: translateX(0%);
		left: unset;
		bottom: unset;
	}

	@media (min-width: ${desktopGiftsGaloreView}) {
		max-width: 630px;
		grid-template-columns: auto 120px;
	}
`;

export const Progress = styled.div`
	position: relative;
	border-radius: 3rem;
	height: 25px;
	border: 3.5px solid #fff;
	padding: 0.25rem;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.5);

	@media (min-width: ${tabletGiftsGaloreView}) {
		height: 35px;
	}
`;

export const Box = styled.img`
	width: 70px;
	height: 60px;
	object-fit: cover;
	position: absolute;
	top: 5px;
	transform: rotate(90deg) translateX(35%);
	left: -5%;
	z-index: 9;

	@media (min-width: ${tabletGiftsGaloreView}) {
		width: 100px;
		height: 80px;
		left: -10px;
		top: 50%;
		transform: translateY(-50%);
	}
`;

export const Bar = styled.div`
	border-radius: 3rem;
	height: 100%;
	background: linear-gradient(
		180deg,
		#ffe37e 0%,
		#ffe178 19.09%,
		#ffda58 20.5%,
		#ffc700 100%
	);
`;

export const CurrentMembers = styled.span`
	position: absolute;
	top: 50%;
	transform: translateY(-52%);
	right: 15px;
	font-family: Berlin Sans FB Demi;
	font-size: 33px;
	text-align: center;
	color: #fff;
	text-shadow: -2.5px 2.5px 0 #333333, -2.5px -2.5px 0 #333333;
	display: none;

	@media (min-width: ${tabletGiftsGaloreView}) {
		display: block;
	}
`;

export const CurrentMembersMobile = styled(CurrentMembers)`
	position: absolute;
	top: 52%;
	z-index: 10;
	transform: rotate(90deg) translateX(-100%);
	left: -15px;
	width: 25px;
	font-family: Berlin Sans FB Demi;
	font-size: 30px;
	text-align: center;
	color: #fff;
	text-shadow: -2.5px 2.5px 0 #333333, -2.5px -2.5px 0 #333333;
	display: block;

	@media (min-width: ${tabletGiftsGaloreView}) {
		display: none;
	}
`;

export const Reward = styled.div`
	position: relative;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(${(props) => {
		const url = "data-image";
		return props[url as keyof typeof props];
	}});
	background-size: 150% 150%;
	background-position: center center;
	transform: translateY(-3px);
	@media (min-width: ${tabletGiftsGaloreView}) {
		transform: unset;
	}
`;

export const RewardBackground = styled.img`
	z-index: -2;
	position: absolute;
	object-fit: contain;
	top: 5%;
	width: 100%;
	height: 100%;
`;

export const StarBurst = styled.img`
	z-index: -1;
	position: absolute;
	top: 1;
	left: 1;
	width: 180%;
	height: 180%;
`;

export const RewardImage = styled.img`
	width: 70px;
	height: 70px;
	object-fit: contain;
	transform: rotate(90deg) translateX(2px) translateY(-6px);

	@media (min-width: ${tabletGiftsGaloreView}) {
		width: 90px;
		height: 90px;
		transform: rotate(0) translateY(-5px);
	}
`;

export const RewardLabel = styled.span`
	position: absolute;
	bottom: -8px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 28px;
	font-family: "Baloo";
	color: white;
	text-shadow: 2px 2px 0px #000000;
	-webkit-text-stroke: 0.7px black;

	@media (max-width: ${desktopGiftsGaloreView}) {
		bottom: -4px;
	}

	@media (max-width: ${tabletGiftsGaloreView}) {
		transform: rotate(90deg);
		left: unset;
		right: 15px;
		width: 105px;
		font-size: 24px;
		text-align: center;
		bottom: unset;
	}
`;
