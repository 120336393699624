import { Box, styled } from "@mui/material";
import { images } from "helpers";

const imageSrc = images.spec_landing_page__page_content_background.src;

export const PageContentContainer = styled("div")`
	display: flex;
	justify-content: center;
	background-repeat: no-repeat, no-repeat;
	background-size: 100% auto;
	background-position: center 69vw;
	//min-height: 28vh;
	background-color: #fffaf1;
	//background-image: url(${imageSrc});
	//background-repeat: repeat-y;
	position: relative;
	min-height: 50vh;
	padding-bottom: 2rem;

	@media (min-width: 800px) {
		padding-bottom: 6rem;
	}

	&.back-to-top {
		padding-bottom: 1rem;

		@media (min-width: 800px) {
			padding-bottom: 1.5rem;
		}
	}
`;
export const PageContentInner = styled(Box)`
	flex-direction: column;
	width: calc(100% - 2rem);
	padding: 0 1rem;
`;
