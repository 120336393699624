import styled from "@emotion/styled";
import { CSSProperties } from "react";

export const EventEnded = styled.div`
	position: absolute;
	top: 0.6rem;
	left: 0.38rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 2rem;
	width: 148px;
	height: 24px;
	color: #fff;
	font-weight: bold;
`;

export const EventEndedLabel = styled.span`
	margin-left: 10px;
`;

export const iconStyles: CSSProperties = {
	width: "22px",
	height: "22px",
	fill: "white"
};
