import { PoisBountyUIVariant } from "interfaces";

export const getClassNameVariants = (
	isPage: boolean,
	isPortrait: boolean,
	variant: PoisBountyUIVariant
): (string | boolean)[] => {
	return [
		"pois-bounty-widget",
		isPage && "page-variant",
		!isPage && "mega-menu-variant",
		isPortrait && "portrait",
		variant
	];
};
