import { keyframes } from "@emotion/react";


export const blink = keyframes`
  0%, 100% { opacity: 1; }
	50% { opacity: 0; }
`;

export const shine = keyframes`
  0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	50% {
		transform: translate(-50%, -50%) rotateZ(180deg);
	}
	100% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
`;

export const backAndForth = keyframes`
  0%, 100% {
		transform: translateY(10%);
	}
	50% {
		transform: translateY(-10%);
	}
`;

export const sideToSideLeft = keyframes`
  0%, 100% {
		transform: rotate(-90deg) translateY(10%);
	}
	50% {
		transform: rotate(-90deg) translateY(-10%);
	}
`;

export const sideToSideRight = keyframes`
  0%, 100% {
		transform: rotate(90deg) translateY(10%);
	}
	50% {
		transform: rotate(90deg) translateY(-10%);
	}
`;
