import axios from "axios";
import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState
} from "react";
import useSWR from "swr";
import { WEB_BLOG_ROOT_URL } from "./contstants";

export interface IWebNewsContext {
	posts: [];
	setPosts: Dispatch<SetStateAction<[]>>;
	currentNewsListScrollPosition: number;
	setCurrentNewsListScrollPosition: Dispatch<SetStateAction<number>>;
	setSearchTerm: Dispatch<SetStateAction<string | null>>;
	searchTerm: string | null;
	resetNewsState: () => void;
	currentPageNum: number;
	setCurrentPageNum: Dispatch<SetStateAction<number>>;
	perPage: number;
	setPerPage: Dispatch<SetStateAction<number>>;
	totalPages: number;
	setTotalPages: Dispatch<SetStateAction<number>>;
	totalPosts: number;
	setTotalPosts: Dispatch<SetStateAction<number>>;
	error: Error | null;
	isLoading: boolean;
}

const WebNewsContext = createContext<IWebNewsContext>({
	posts: [],
	setPosts: () => undefined,
	currentNewsListScrollPosition: 0,
	setCurrentNewsListScrollPosition: () => null,
	searchTerm: null,
	setSearchTerm: () => null,
	resetNewsState: () => null,
	currentPageNum: 1,
	setCurrentPageNum: () => null,
	perPage: 6,
	setPerPage: () => null,
	totalPages: 0,
	setTotalPages: () => null,
	totalPosts: 0,
	setTotalPosts: () => null,
	error: null,
	isLoading: false
});

const WebNewsProvider = ({ children }: { children?: React.ReactNode }) => {
	const [perPage, setPerPage] = useState(6);

	const [posts, setPosts] = useState<[]>([]);

	const [currentNewsListScrollPosition, setCurrentNewsListScrollPosition] =
		useState<number>(0);
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [currentPageNum, setCurrentPageNum] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [totalPosts, setTotalPosts] = useState(0);

	// For list of categories:
	// https://solitairetripeaks.com/wp-json/wp/v2/categories
	const { data, error, isLoading } = useSWR(
		`${WEB_BLOG_ROOT_URL}wp/v2/posts?${
			searchTerm ? `search=${searchTerm}&` : ""
		}per_page=${perPage}&page=${currentPageNum}&categories=${
			// show featured & winners categories when searching
			searchTerm ? "1360,1359" : "1360"
		}`,
		axios
	);

	const resetNewsState = () => {
		setCurrentPageNum(1);
		setSearchTerm(null);
		setCurrentNewsListScrollPosition(0);
	};

	// set posts data returned from api
	useEffect(() => {
		// console.log(data);
		data?.data && setPosts(data.data);
		if (data?.headers) {
			setTotalPages(parseInt(data.headers["x-wp-totalpages"]) || 0);
			setTotalPosts(parseInt(data.headers["x-wp-total"]) || 0);
		}
	}, [data]);

	useEffect(() => {
		totalPages && setTotalPages(totalPages);
	}, [totalPages]);
	useEffect(() => {
		totalPosts && setTotalPosts(totalPosts);
	}, [totalPosts]);

	const value = {
		perPage,
		setPerPage,
		posts,
		setPosts,
		currentNewsListScrollPosition,
		setCurrentNewsListScrollPosition,
		totalPages,
		setTotalPages,
		totalPosts,
		setTotalPosts,
		searchTerm,
		setSearchTerm,
		currentPageNum,
		setCurrentPageNum,
		resetNewsState,
		error,
		isLoading
	};

	return (
		<WebNewsContext.Provider value={value}>{children}</WebNewsContext.Provider>
	);
};

const useWebNewsContext = () => {
	const context = useContext(WebNewsContext);
	if (context === undefined) {
		throw new Error("useWebNewsContext was used outside of its Provider");
	}
	return context;
};

export default WebNewsProvider;
export { WebNewsContext, useWebNewsContext };
