/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, useMediaQuery } from "@mui/material";
import { onClosePopUp, onDIPtrigger } from "redux/slices";
import { useAppDispatch, useImagePath } from "hooks";
import headerImg from "assets/daily-gift/bg-loyalty-points_v2.png";
import { Body, Button, Container, Header, Title, SubTitle } from "./styles";
import BackToGameButton from "components/Buttons/BackToGameButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import { useWebStoreContext } from "contexts";

interface DailyRewardLoyaltyPointsProps {}

export const DailyRewardLoyaltyPoints: React.FC<
	DailyRewardLoyaltyPointsProps
> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const headImageUrl = getImage(headerImg);
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const scale = useCalculateScale(378, 380);
	const { syncUserTracker } = useWebStoreContext();

	const onClose = () => {
		dispatch(onDIPtrigger("collectDailyGift"));
		syncUserTracker();
		dispatch(onClosePopUp());
	};

	return (
		<Container scale={scale}>
			<CloseIconButton onClose={onClose} />
			<Header data-image={headImageUrl}></Header>
			<Body>
				<Title>Congratulations!</Title>
				<SubTitle>Your Loyalty Points balance has been updated!</SubTitle>
				<Grid container spacing={isPortrait ? 2 : 1}>
					<Grid
						item
						xs={12}
						//xs={isMobile ? 6 : 12}
						//sm={isMobile ? 6 : 12}
						display="flex"
						justifyContent="center"
					>
						<Button
							variant="contained"
							themeColor="primary"
							sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							onClick={() => onClose()}
						>
							Continue
						</Button>
					</Grid>
					{/* {isMobile && (
						<Grid item xs={6} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="endless_offer_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							/>
						</Grid>
					)} */}
				</Grid>
			</Body>
		</Container>
	);
};
