import { Box, styled } from "@mui/material";

export const IndicatorStyled = styled(Box)`
	width: 5px;
	border-radius: 1rem;
	height: 38px;
	margin-top: -10px;
	position: relative;
	z-index: 9999;
	background-color: #fff;
	&.marker {
		background-color: transparent;
	}
	&.marker.portrait {
		background-color: #fff;
		margin-left: -2.5px;
	}
`;

export const LoyaltyContainer = styled("div")`
	display: flex;
	position: absolute;
	top: -27px;
	height: 27px;
	z-index: -1;

	&::before {
		content: "";
		bottom: -6px;
		z-index: -1;
		position: absolute;
		background-color: rgba(255, 255, 255, 0.6);
		width: 5px;
		height: 30px;
		border-radius: 1rem;
		display: block;
	}
	&.marker::before {
		left: -1px;
		bottom: -20.5px;
	}

	&.marker.portrait::before {
		left: -1px;
		bottom: -5.5px;
	}
`;

export const LoyaltyPoints = styled("span")`
	display: flex;
	align-items: center;
	gap: 0.2rem;
	margin: 0.2rem 0 0 0.8rem;
	font-weight: bold;
	color: #fff;
	&.reverse {
		transform: translateX(-135%);
	}
	&.marker {
		margin: 1.5rem 0 0 0.8rem;
	}
	&.marker.portrait {
		margin: 0rem 0 0 0.7rem;
	}
`;
