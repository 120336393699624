import { keyframes } from "styled-components";

export const blink = keyframes`
  0%, 100% { opacity: 1; }
	50% { opacity: 0; }
`;

export const shine = keyframes`
  from {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
`;
