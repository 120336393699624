import { CrownIcon } from "utils/svgIcons";
import { StyledStepTarget } from "./styles";
import { useStepContext } from "../Step/context";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";

export const Target = () => {
	const { target, status } = useStepContext();
	const { isPortrait, isPageVariant } = usePoisBountyContext();

	return (
		<StyledStepTarget
			className={`target ${status === "unlocked" && "claimed"} ${!isPageVariant && "mega-menu-variant"}`}
			portrait={isPortrait ? 1 : 0}
		>
			{!isPortrait && <CrownIcon />}
			<span>{target?.toLocaleString("en-US")}</span>
		</StyledStepTarget>
	);
};
