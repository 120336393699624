import { Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Buttons/ThemeButton";
import { links } from "helpers";
import { useEffect } from "react";
import { Container, Image, Text } from "./styles";
import { PageContent } from "components/PageContent";

const PageNotFoundComponent = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		switch (pathname) {
			case "/loyalty":
				navigate("/loyalty-points");
				break;
		}
	}, [pathname]);

	return (
		<PageContent>
			<Container>
				<Text>
					<Typography
						variant="h3"
						component="h3"
						sx={{
							fontFamily: "Berlin Sans FB Demi",
							marginBottom: "0.5rem",
							lineHeight: 1
						}}
					>
						Page Not Found
					</Typography>
					<Typography sx={{ textAlign: "left", marginBottom: "1rem" }}>
						Call it a “cosmic hiccup” or a silly “whoops”, but not to worry -
						just choose what you want to do now:
					</Typography>
					<Link to={links.home.path} style={{ textDecoration: "none" }}>
						<Button
							variant="contained"
							themeColor="secondary"
							sx={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
						>
							Back to Shop
						</Button>
					</Link>
				</Text>
				<Image
					src="https://storage.googleapis.com/tripeaks/WebStore/static_assets/frontend/public/minitiki_space_center.png"
					alt="MiniTiki Space Center"
				/>
			</Container>
		</PageContent>
	);
};

export default PageNotFoundComponent;
