import { DailyLoginStreakTracker } from "interfaces";
import { createContext, useContext } from "react";

type Context = {
	tracker: DailyLoginStreakTracker;
};

export const DailyLoginStreakContext = createContext<Context>({
	tracker: {} as DailyLoginStreakTracker
});

export const useDailyLoginStreak = () => useContext(DailyLoginStreakContext);
