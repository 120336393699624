import { PREFIX } from "./helper";
import { RootState } from "redux/store";
import { initialState } from "./initial-state";
import { createSlice } from "@reduxjs/toolkit";
import { getShopSections } from "./thunks";
import { IShopSection } from "./types";

export const shopSectionsSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		setShopSectionsResponse: (state, action) => {
			state.shopSections = action.payload;

			if (action.payload.mainShopSections) {
				state.shopSections.mainShopSections =
					action.payload.mainShopSections.sort(
						(a: IShopSection, b: IShopSection) => a.priority - b.priority
					);
			}
			if (action.payload.loyaltyShopSections) {
				state.shopSections.loyaltyShopSections =
					action.payload.loyaltyShopSections.sort(
						(a: IShopSection, b: IShopSection) => a.priority - b.priority
					);
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getShopSections.fulfilled, (state, action) => {
			state.shopSections = action.payload;

			if (action.payload.mainShopSections) {
				state.shopSections.mainShopSections =
					action.payload.mainShopSections.sort(
						(a: IShopSection, b: IShopSection) => a.priority - b.priority
					);
			}
			if (action.payload.loyaltyShopSections) {
				state.shopSections.loyaltyShopSections =
					action.payload.loyaltyShopSections.sort(
						(a: IShopSection, b: IShopSection) => a.priority - b.priority
					);
			}
		});
	}
});

export const { setShopSectionsResponse } = shopSectionsSlice.actions;

export const selectShopSectionsState = (state: RootState) => state.shopSections;
export default shopSectionsSlice.reducer;
export * from "./types";
export * from "./thunks";
