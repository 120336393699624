import styled, { CSSProperties } from "styled-components";

export const tabletGiftsGaloreView = "550px";
export const desktopGiftsGaloreView = "850px";
export const giftGaloreTextShadow =
	"-3px -3px 0 #333333, 0 -3px 0 #333333, 3px -3px 0 #333333, 3px 0 0 #333333, 3px 3px 0 #333333, 0 3px 0 #333333, -3px 3px 0 #333333, -3px 0 0 #333333";
export const giftGaloreTextShadowMobile =
	"-1.8px -1.8px 0 #333333, 0 -1.8px 0 #333333, 1.8px -1.8px 0 #333333, 1.8px 0 0 #333333, 1.8px 1.8px 0 #333333, 0 1.8px 0 #333333, -1.8px 1.8px 0 #333333, -1.8px 0 0 #333333";

export const Container = styled.div<{ backgroundImage: string }>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	background-image: url(${(props) => props.backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	margin: 0 auto;
	//height: 490px;
	//TODO: check this height for iphone 14
	height: 430px;
	width: 100%;
	border-radius: 1rem;

	.timer {
		font-size: 2rem;
	}

	@media (min-width: ${tabletGiftsGaloreView}) {
		overflow: unset;
		justify-content: center;
		align-items: center;
		width: 99%;
		margin: 0 auto;
		align-items: center;
		background-size: cover;
		height: 520px;
	}
`;

export const timeStyle: CSSProperties = {
	position: "absolute",
	padding: 10,
	top: 10,
	left: 10
};
