import classNames from "classnames";
import { useRewards } from "components/PoisBountyWidget/hooks";
import { useAppSelector } from "hooks";
import { selectPoisBountyState } from "redux/slices";
import { hideBar } from "./helper";
import { ProgressStyled } from "./styles";
import { Step } from "./lib";
import { usePoisBountyContext } from "components/PoisBountyWidget/context";
import { StepConfig } from "./lib/Step/types";

export interface ProgressStepsProps {}

export const ProgressSteps: React.FC<ProgressStepsProps> = () => {
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const { userLoyaltyPoints, data } = useAppSelector(selectPoisBountyState);
	const { tracker } = data;
	const { steps } = useRewards(tracker.rewardClaimStatuses);

	return (
		<ProgressStyled
			className={classNames([
				isPortrait && "portrait",
				isPageVariant && "page-variant"
			])}
		>
			{steps.map((reward, index, arr) => {
				const config: StepConfig = {
					hideBar: hideBar(arr, index, userLoyaltyPoints),
					first: index === 0,
					last: index + 1 === arr.length
				};

				return (
					<Step key={index} reward={reward} stepIndex={index} config={config} />
				);
			})}
		</ProgressStyled>
	);
};
