import service from "services/api";
import { PREFIX } from "./helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IUserTracker } from "contexts/WebStoreProvider/types";
import { offersApi } from "redux/apis";

export const onSuppress = createAsyncThunk(
	`${PREFIX}/on/suppress`,
	async ({
		key,
		value
	}: {
		key: keyof IUserTracker["metadata"];
		value: string;
	}): Promise<void> => {
		try {
			await service.Post(
				`/rest/user/tracker/metadataValue?key=${key}&value=${value}`
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const clearAllTrackers = createAsyncThunk(
	`${PREFIX}/clear/all/trackers`,
	async (): Promise<void> => {
		try {
			await service.Post("/rest/debug/user/clear-trackers");
		} catch (error) {
			console.log(error);
		}
	}
);

export const clearPurchaseTrackers = createAsyncThunk(
	`${PREFIX}/clear/all/trackers`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post("/rest/debug/purchasetracker/clear");
		} catch (error) {
			console.log(error);
		} finally {
			thunkAPI.dispatch(
				offersApi.util.invalidateTags([
					{
						type: "offersTag",
						id: "singleItem"
					}
				])
			);
		}
	}
);
