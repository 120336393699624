/**
 * debugLogger:
 * - utility function to help with console logging
 *
 * useage:
 *
 *  // create debug logger object, enabled with custom color option
 *  const debug = getDebugLogger({
 * 		isEnabled: true,
 * 		color: "DarkTurquoise"
 * 	});
 *
 *  // log message
 *  debug.log({ message: ["some message", someVar]});
 *
 *  // log message with custom icon text
 *  debug.log({ iconText: "Colored Text", message: ["some message:", someVar]});
 *
 *  // Adding custom loggers
 *  addDebugLogger("logUseEffect", getLogger({ iconString: "\n%c⬤" }));
 */
/* eslint-disable */
export interface ILoggerProps {
	icon?: string;
	iconText?: string;
	iconColor?: string;
	message?: any[];
	isEnabled?: boolean;
}
type Logger = (props: ILoggerProps) => void;
// TODO: Clean this up later, right now using an alias to limit change surface area
export type LoggingMethod = Logger;

/**
 * getLogger
 * @param params
 * @returns Logger
 */
const getLogger = ({
	icon: iconDefault = " ⇢", //"▶",
	iconString: iconStringDefault,
	iconStyle: iconStyleDefault,
	iconColor: iconColorDefault = "grey"
}: {
	icon?: string;
	iconString?: string;
	iconStyle?: string;
	iconColor?: string;
} = {}): Logger => {
	const logger = ({
		icon = iconDefault,
		iconText = "",
		iconColor = iconColorDefault,
		message = []
	}: ILoggerProps): void => {
		window?.console &&
			console.log(
				iconStringDefault
					? `${iconStringDefault}${iconText && ` ${iconText}`}`
					: `%c${icon}${iconText && ` ${iconText}`}`,
				iconStyleDefault ? iconStyleDefault : `color: ${iconColor}`,
				...message
			);
	};
	return logger;
};

export interface IDebugLogger {
	isEnabled: boolean;
	color?: string;
	log: Logger;
	[key: string]: any;
}
const defaultDebugLogProps = {
	isEnabled: true
};

const defaultDebugLoggerProps = {
	isEnabled: process.env.REACT_APP_LOGGER_ENABLED === "true" ? true : false,
	color: "",
	log: function (props: ILoggerProps = defaultDebugLogProps) {
		props = { ...defaultDebugLogProps, ...props };
		if (!this.isEnabled || !props.isEnabled) {
			return;
		}
		if (!props?.iconColor) {
			props.iconColor = this.color;
		}
		const logger = getLogger();
		logger(props);
		return this;
	}
};

const addedLoggers = {};

/**
 * getDebugLogger
 * @param params
 * @returns Logger
 * useage:
 *  const debug = getDebugLogger({
 * 		isEnabled: true,
 * 		color: "DarkTurquoise"
 * 	});
 */
const getDebugLogger = (props: any): IDebugLogger => {
	const debug = defaultDebugLoggerProps;

	if (Object.keys(addedLoggers).length > 0) {
		Object.keys(addedLoggers).forEach((key) => {
			(debug as any)[key] = function (
				props: ILoggerProps = defaultDebugLogProps
			) {
				props = { ...props, ...defaultDebugLogProps };
				if (!this.isEnabled || !props.isEnabled) {
					return this;
				}
				if (!props?.iconColor) {
					props.iconColor = this.color;
				}
				(addedLoggers as any)[key](props);
				return this;
			};
		});
	}

	return { ...debug, ...props };
};

const addDebugLogger = (name: string, newLogger: Logger) => {
	(addedLoggers as any)[name] = newLogger;
};

addDebugLogger("logUseEffect", getLogger({ iconString: "\n%c⬤" }));
addDebugLogger("logFunction", getLogger({ iconString: "\n%c〇" }));
addDebugLogger("logError", getLogger({ iconString: "\n%c⛔️" }));
/* eslint-enable */
export { getLogger, getDebugLogger, addDebugLogger, defaultDebugLoggerProps };
