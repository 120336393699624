import { PREFIX } from "./helper";
import service from "services/api";
import { GiftsGaloreResponse } from "interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getGiftsGalore = createAsyncThunk(
	`${PREFIX}/get-tracker`,
	async (): Promise<GiftsGaloreResponse | undefined> => {
		try {
			return await service.Get<GiftsGaloreResponse>(
				"/rest/club-gift-bonus/event-tracker"
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const onGiftGaloreEventEnded = createAsyncThunk(
	`${PREFIX}/event-ended`,
	async (): Promise<GiftsGaloreResponse | undefined> => {
		try {
			return await service.Get<GiftsGaloreResponse>(
				"/rest/club-gift-bonus/event-tracker"
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const clearGiftGaloreTracker = createAsyncThunk(
	`${PREFIX}/delete-tracker`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post("/rest/debug/club-gift-bonus/tracker/delete");
			thunkAPI.dispatch(getGiftsGalore());
		} catch (error) {
			console.log(error);
		}
	}
);

export const simulateGiftGalorePurchase = createAsyncThunk(
	`${PREFIX}/simulate-purchase`,
	async (count: number, thunkAPI): Promise<void> => {
		try {
			await service.Post(
				`/rest/debug/club-gift-bonus/tracker/simulate-purchases?count=${count}`
			);
			thunkAPI.dispatch(getGiftsGalore());
		} catch (error) {
			console.log(error);
		}
	}
);
