import styled from "@emotion/styled";
import { PBBackground } from "components/PoisBountyWidget/lib/common";

export const Container = styled(PBBackground)`
	background-size: cover;
	background-position: center;
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;
