import { useEffect, useState } from "react";
import PageTopNav from "../../components/PageTopNav";
import { NewsArticleContentSkeleton } from "./skeleton";
import useSWR from "swr";
import axios from "axios";
import { useParams } from "react-router-dom";
import BreadCrumbsBlog from "./components/Breadcrumbs";
import { parseContent, stripHtml } from ".";
import { WEB_BLOG_ROOT_URL } from "contexts/WebNewsProvider/contstants";
import ArticleItem from "./components/ArticleItem";
import MetaTags from "./components/MetaTags";
import titleCase from "utils/titleCase";
import { PageContent } from "components/PageContent";
import { IPostListItem } from "./components/PostList";

const News = () => {
	const [post, setPost] = useState<IPostListItem>({} as IPostListItem);
	const params = useParams();
	// For list of categories:
	// https://solitairetripeaks.com/wp-json/wp/v2/categories
	const { data, isLoading } = useSWR(
		// Allow featured, winners, and uncategorized categories
		`${WEB_BLOG_ROOT_URL}wp/v2/posts?slug=${params?.slug}&categories=1360,1359,1`,
		axios
	);

	useEffect(() => {
		data?.data[0] && setPost(data.data[0]);
	}, [data]);
	useEffect(() => {
		window && window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<MetaTags
				title={parseContent(post?.title?.rendered || "")}
				url={location.href}
				description={
					post?.excerpt?.rendered &&
					parseContent(stripHtml(post?.excerpt?.rendered))
				}
				image={post?.jetpack_featured_media_url}
			/>
			<PageTopNav sectionMenuData={[]} />
			<PageContent source="news_article_page" enableBackToTop>
				<BreadCrumbsBlog
					title={(params?.slug && titleCase(params.slug)) || ""}
				/>
				{isLoading ? (
					<NewsArticleContentSkeleton />
				) : (
					<ArticleItem post={post} />
				)}
			</PageContent>
		</>
	);
};

export default News;
