import { Box, Typography, styled } from "@mui/material";

export const Sticky = styled("div")<{ imageUrl: string }>(({ imageUrl }) => `
	position: fixed;
	bottom: 1rem;
	left: 10%;
	transform: translateX(-10%);
	z-index: 999;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% 100%;
	height: 89px;
	width: 296px;
	background-image: url(${imageUrl});
	cursor: pointer;

	> div {
		width: inherit;
		height: inherit;
	}

	@media (min-width: 600px) {
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		width: 485px;
	}
`
);

export const Text = styled(Typography)`
	position: absolute;
	left: 30%;
	top: 30%;
	font-family: "Baloo";
	font-size: 16px;
	color: white;
	text-shadow: 0px 1.05px 0px #000;
	-webkit-text-stroke-width: 0.7;
	-webkit-text-stroke-color: #000;

	@media (min-width: 600px) {
		left: 20%;
	}
`;

export const Button = styled("button")`
	position: absolute;
	top: 20%;
	transform: translateY(-50%);
	right: 5%;
	background-color: unset;
	border: unset;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	border-radius: 100%;

	svg {
		stroke: #fff;
	}

	@media (max-width: 600px) {
		top: 30%;
	}
`;

export const EmptyProgressBar = styled(Box)`
	position: absolute;
	left: 30%;
	top: 70%;
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
	transform: translateY(-50%);
	width: 55%;
	height: 18px;
	padding: 0.1rem;
	border: 2px solid #fff;
	border-radius: 1rem;
	fill: rgba(255, 255, 255, 0.20);

	@media (min-width: 600px) {
		left: 20%;
		width: 65%;
	}
`;

export const Bar = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	background-color: transparent;
`;

export const FillPercentage = styled(Box)`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	border-radius: 1rem;
	transition: all 0.3s ease;
	min-width: 10%;
	background: linear-gradient(
		180deg,
		#ffe37e 0%,
		#ffe178 19.09%,
		#ffda58 20.5%,
		#ffc700 100%
	);
`;

export const FillText = styled("div")`
	display: flex;
	position: absolute;
	align-items: center;
	color: #333333;
	left: 4%;
	font-family: "Inter";
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;

	p {
		margin: 0;
	}

	a {
		text-decoration: none;
		color: #333333;
		width: max-content;
		line-height: 18px;
	}

	svg {
		margin-right: 4px;
	}
`;

export const RewardsCountDot = styled("div")`
	position: absolute;
	top: -50%;
	right: 5px;
	font-family: "Inter";
	font-size: 12px;
	font-weight: 700;
	background-color: #e53939;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	z-index: 1;
`;
