import { itemsV1 } from "../../items";
import { Card } from "../Card";
import { GridStyled } from "./styles";

interface GridProps {
	items?: {
		image: string;
		title: string;
		description?: string;
	}[];
}

export const Grid: React.FC<GridProps> = ({ items }) => {

	const renderItems = items || itemsV1;

	return (
		<GridStyled>
			{renderItems.map((item, key) => (
				<Card item={item} key={key} />
			))}
		</GridStyled>
	);
};
