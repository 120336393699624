import { Box, styled } from "@mui/material";
import { isIPad13 } from "react-device-detect";

export const StepStyled = styled(Box)`
	position: relative;
	width: 20.1%;
	height: 250px;
	&.first-step {
		width: 8.6%;
	}
	&.portrait {
		width: 120px;

		.claim-button {
			transform: rotate(90deg) translateX(2px);
		}
		.reward-item {
			transform: rotate(90deg) translateX(58px);
		}
		.target {
			transform: rotate(90deg) translate(-60px, 54px);
		}
		.step-marker {
			transform: rotate(90deg) translateX(-96px);
			background-color: unset;
			border: unset;
			box-shadow: unset;
		}
		.progress-reward-step {
			transform: translateY(-110px);
		}
		.loyalty-points-indicator {
			transform: rotate(90deg) translate(-20px, 15px);
		}
		.progress-indicator.marker {
			transform: rotate(-90deg) translate(-12px, 0px);
			z-index: -1;
		}
		.container-indicator {
			&::before {
				height: 62px;
			}
		}
	}
`;

export const Reward = styled(Box)`
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	gap: 1rem;
	transform: translateX(24px);
	width: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* @media (min-width: 755px) and (max-width: 950px) {
		transform: translateX(21.1px);
		top: 0.5rem;
		.reward-item {
			width: 42px;
			height: 42px;
		}
	} */
`;

export const ProgressRewardStep = styled(Box)`
	background-color: rgba(0, 0, 0, 0.51);
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;
	height: 18px;
	padding: 0.1rem;

	&.first {
		border-left: 2px solid #fff;
		border-radius: 1rem 0 0 1rem;
	}
	&.last-step {
		width: 50%;
	}
`;

export const Bar = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	background-color: transparent;
`;

export const Percentage = styled(Box)`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	background: linear-gradient(
		180deg,
		#ffe37e 0%,
		#ffe178 19.09%,
		#ffda58 20.5%,
		#ffc700 100%
	);

	&.first {
		border-radius: 1rem 0 0 1rem;
	}
`;

export const RewardItem = styled(Box)`
	width: 52px;
	height: 52px;
	&.page-variant {
		width: 70px;
		height: 70px;
		transform: translateY(${isIPad13 ? "-35px" : "-0.7rem"});
	}
	&.mega-menu-variant {
		transform: translateY(${isIPad13 ? "-28px" : 0});
	}
	padding: 0.25rem;
	border-radius: 11px;
	background-color: #fff;
	&.in-progress {
		background-color: #ffca0d;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;
`;

export const StarBurst = styled("img")`
	position: absolute;
	width: 150px;
	height: 150px;
`;

export const RewardText = styled(Box)`
	font-family: "Baloo";
	position: absolute;
	bottom: -3px;
	right: 4px;
	color: white;
	text-shadow: 2.0996px 1.0996px 0px #000000;
	display: flex;
	font-size: 18px;
	align-items: center;

	&.page-variant {
		font-size: 20px;
	}
`;
/* eslint-disable */
export const RewardImage = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 11px;
	background-image: url(${(props: any) => props["data-image"]});
	background-size: cover;
	background-position: center bottom;
	display: flex;
	justify-content: center;
	align-items: center;
`;
/* eslint-enable */
export const RewardPrizeImage = styled("img")`
	width: auto;
	height: auto;
	width: 80%;
	height: 75%;
	object-fit: contain;
`;
