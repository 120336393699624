import { links } from "helpers";
import ThemeButton from "../ThemeButton";
import { SxProps, Theme } from "@mui/material";
import { useGoogleAnalyticsContext } from "contexts";

interface BackToGameButtonProps {
	children?: React.ReactNode;
	styles?: React.CSSProperties;
	sx?: SxProps<Theme> | undefined;
	source: string;
	onClick?: () => void;
	themeColor?: "primary" | "secondary";
}

const BackToGameButton = ({
	children = "Back to Game",
	styles,
	sx,
	source,
	onClick,
	themeColor = "primary",
}: BackToGameButtonProps) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { analytics_key, path } = links.back_to_game;

	const handleClick = () => {
		reportEvent(analytics_key, { source: source });
		window?.open(path, "_blank");
		onClick?.();
	};

	return (
		<ThemeButton
			themeColor={themeColor}
			onClick={handleClick}
			style={{ textTransform: "none", ...styles }}
			sx={sx}
		>
			{children}
		</ThemeButton>
	);
};

export default BackToGameButton;
