import { Dialog } from "@mui/material";
import styled from "styled-components";
import { DialogContent as _DialogContent } from "@mui/material";

export const StyledDialog = styled(Dialog)`
	& .MuiPaper-root {
		max-width: none;
	}
	& div[role="dialog"] {
		border-radius: 12px;
	}
	@media (max-width: 900px) {
		& div[role="dialog"] {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: 0;
			max-height: none;
			border-radius: 0;
		}
	}
`;

export const DialogContent = styled(_DialogContent)`
	width: 650px;
	height: 600px;
	max-height: 600px;
	@media (max-width: 900px) {
		width: 100%;
		height: auto;
		max-height: none;
	}
`;

export const XsollaCont = styled("div")`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	&,
	iframe {
		border: none;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		height: 100%;
	}
	iframe {
		width: 100%;
		display: block;
		padding-top: 0.1rem;
		overflow: auto;
	}
`;
