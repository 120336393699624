import { baseApi } from "../api";

export const segmentationOverrideApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getSegmentationOverride: builder.query({
			query: () => "/rest/debug/segments/data",
			providesTags: ["segmentationTag"]
		}),
		setSegmentationOverride: builder.mutation({
			query: (body) => ({
				url: "/rest/debug/user/segmentationDataOverride",
				method: "POST",
				body
			}),
			invalidatesTags: ["segmentationTag"]
		}),
		deleteSegmentationOverride: builder.mutation({
			query: () => ({
				url: "/rest/debug/user/deleteSegmentationDataOverride",
				method: "POST"
			}),
			invalidatesTags: ["segmentationTag"]
		})
	})
});

export const {
	useGetSegmentationOverrideQuery,
	useSetSegmentationOverrideMutation,
	useDeleteSegmentationOverrideMutation
} = segmentationOverrideApi;
