import styled from "styled-components";

export const Container = styled.section`
	position: relative;
	height: 800px;
	margin-bottom: 6rem;
	max-width: 1800px;
	margin: 0 auto;
	@media (max-width: 1250px) {
		height: 608px;
	}
	@media (max-width: 720px) {
		height: 500px;
	}
	@media (max-width: 460px) {
		height: 350px;
	}
	@media (max-width: 360px) {
		height: 270px;
	}
`;

export const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
	z-index: -1;
	@media (max-width: 650px) {
		height: 500px;
	}
	@media (max-width: 460px) {
		height: 350px;
	}
	@media (max-width: 360px) {
		height: 270px;
	}
`;

export const GridMerch = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1%;
	transform: translateY(-6.5rem);
	@media (max-width: 1250px) {
		transform: translateY(-4.5rem);
	}
	@media (max-width: 819px) {
		flex-direction: column;
	}
	@media (max-width: 720px) {
		transform: translateY(-2.5rem);
	}
	@media (max-width: 600px) {
		transform: translateY(-3.5rem);
	}
	@media (max-width: 460px) {
		transform: translateY(-2rem);
	}
`;
