import "animate.css";
import { Container } from "./styles";
import { useImagePath } from "hooks";
import { PBLogoText } from "components/PoisBountyWidget/lib/common";

export const Intro = () => {
	const { getImage } = useImagePath("local");
	const logoImageUrl = getImage("/spec_pois_bounty/pois_bounty_logo_text.png");

	return (
		<Container className="animate__animated animate__fadeIn">
			<PBLogoText src={logoImageUrl} />
		</Container>
	);
};
