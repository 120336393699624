import { getCount, getDisplay, links } from "helpers";
import { useAppSelector } from "hooks";
import { CrownIcon } from "utils/svgIcons";
import { useEffect, useState } from "react";
import useBreakpoints from "hooks/useBreakpoints";
import { selectPoisBountyV2State } from "redux/slices";
import { HighlightOffOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import background from "assets/pois-bounty/v2/sticky-tag/sticky-background-pois.png";
import backgroundLandscape from "assets/pois-bounty/v2/sticky-tag/sticky-background-pois-landscape.png";
import { getMiniProgressBarWidth } from "components/PoisBountyWidgetV2/lib/ProgressSteps/lib/Step/helper";
import { Bar, Button, EmptyProgressBar, FillPercentage, FillText, RewardsCountDot, Sticky, Text } from "./styles";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { Trackers } from "interfaces";

interface PoisBountyStickyTagProps { }

const PoisBountyStickyTag = ({ }: PoisBountyStickyTagProps) => {
	const navigate = useNavigate();
	const { isSmUp } = useBreakpoints();
	const { userTracker, nowTime } = useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { dataV2, trackers, isEventActiveV2, remaining24hour } = useAppSelector(selectPoisBountyV2State);
	const { loyaltyPoints, rewardClaimStatuses, eventId, cycleIndex, endTime } = trackers;
	const [unclaimedRewardsCount, setUnclaimedRewardsCount] = useState<number>(0);
	const [displayTime, setDisplayTime] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [previousData, setPreviousData] = useState<Trackers>();
	const location = useLocation();
	const { pathname } = location;
	const cycleComplete: boolean = loyaltyPoints === rewardClaimStatuses?.slice(-1)[0].reward.loyaltyPoints;

	useEffect(() => {
		if (!!userTracker && pathname !== links.home.path && pathname !== links.pois_bounty.path && isEventActiveV2 && !remaining24hour) {
			if (previousData?.loyaltyPoints === loyaltyPoints) {
				return;
			}
			const eventTimer = getCount(nowTime, endTime);
			const timerDisplay = getDisplay(eventTimer);
			setDisplayTime(timerDisplay);
			
			const { trackers } = dataV2;
			setPreviousData(trackers[0]);
			let count = 0;
			trackers?.map(t => {
				count += t.rewardClaimStatuses.filter(rcs => rcs.isClaimed === false && rcs.reward.loyaltyPoints <= t.loyaltyPoints).length;
			});
			setUnclaimedRewardsCount(count);
			setOpen(true);

			reportEvent("spawn", {
				source: "pbv2_progress_bar_pop_up",
				loyalty_point_balance: loyaltyPoints,
				loyalty_point_cycle_threshold: rewardClaimStatuses?.slice(-1)[0].reward.loyaltyPoints,
				pois_bounty_id: eventId,
				cycle_index: cycleIndex,
				number_of_rewards_in_badge: count,
				event_timer: timerDisplay,
			});
		} else {
			setOpen(false);
		}
	}, [dataV2, isEventActiveV2, remaining24hour, pathname, userTracker]);

	return open && pathname !== links.pois_bounty.path ? (
		<Sticky imageUrl={isSmUp ? backgroundLandscape : background}>
			<Button
				className="button"
				onClick={() => {
					setOpen(false);
					reportEvent("exit", {
						source: "pbv2_progress_bar_pop_up",
						pois_bounty_id: eventId
					});
				}}
			>
				<HighlightOffOutlined
					sx={{
						strokeWidth: 0.005,
						width: 30,
						height: 30,
						backgroundColor: "rgba(0,0,0, .3)",
						borderRadius: "100%",
						color: "#fff",
						"&:hover": {
							backgroundColor: "rgba(0,0,0, .6)"
						}
					}}
					fontSize="large"
				/>
			</Button>
			<div onClick={() => {
				navigate(links.pois_bounty.path)
				reportEvent("click", {
					source: "pbv2_progress_bar_pop_up",
					loyalty_point_balance: loyaltyPoints,
					loyalty_point_cycle_threshold: rewardClaimStatuses.slice(-1)[0].reward.loyaltyPoints,
					pois_bounty_id: eventId,
					cycle_index: cycleIndex,
					number_of_rewards_in_badge: unclaimedRewardsCount,
					event_timer: displayTime,
				});
			}}>
				<Text className="text">Poi’s Bounty</Text>
				{/* 📊 Reward Progress Bar */}
				<EmptyProgressBar>
					{unclaimedRewardsCount > 0 &&
						<RewardsCountDot />
					}
					<Bar className="reward-bar">
						<FillPercentage
							style={{
								width: getMiniProgressBarWidth(
									loyaltyPoints,
									rewardClaimStatuses.slice(-1)[0].reward.loyaltyPoints,
								)
							}}
						>
						</FillPercentage>
						<FillText>
							<CrownIcon />
							<p>
								{cycleComplete ? "READY TO RESTART" : `${loyaltyPoints} / ${rewardClaimStatuses.slice(-1)[0].reward.loyaltyPoints}`}
							</p>
						</FillText>
					</Bar>
				</EmptyProgressBar>
			</div>
		</Sticky>
	) : null;
};

export default PoisBountyStickyTag;
