import HttpRequest from "../HttpRequest";
import { AbstractHttpService } from "../types";

/* eslint-disable */
export default class HttpResponse {
	readonly httpRequest: HttpRequest;
	readonly httpService: AbstractHttpService;
	readonly headers: Record<string, string>;
	readonly statusCode: number;
	readonly data: any;

	constructor(
		httpRequest: HttpRequest,
		httpService: AbstractHttpService,
		headers: Record<string, string>,
		statusCode: number,
		data: any
	) {
		this.httpRequest = httpRequest;
		this.httpService = httpService;
		this.headers = headers;
		this.statusCode = statusCode;
		this.data = data;
	}
}

/* eslint-enable */
