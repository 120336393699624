import { Fragment, useEffect } from "react";
import PageTopNav from "../../components/PageTopNav";
import { NewsPageContentSkeleton } from "./skeleton";
import parse from "html-react-parser";
import Pagination from "./components/Pagination";
import Search from "./components/Search";
import SectionHeader from "components/Section/SectionHeader";
import { NewsIcon } from "utils/svgIcons";
import { useWebNewsContext } from "contexts/WebNewsProvider";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import PostList from "./components/PostList";
import { useEnvContext } from "../../contexts/EnvProvider";
import { FeaturesFlags } from "helpers";
import { PrivateRoute } from "hoc";
import { PageContent } from "../../components/PageContent";
import { Background, Characters, Container } from "./styles";
import background from "assets/winners/desktop-bg.png";
import characters from "assets/winners/title-characters.png";

export const parseContent = (content: string) => {
	return content ? (parse(content) as string) : "";
};
export const stripHtml = (content = "") => {
	return content.replace(/(<([^>]+)>)/gi, "");
};

const News = () => {
	const params = useParams();
	const { isFeatureFlagEnabled } = useEnvContext();
	const {
		posts,
		currentPageNum,
		setCurrentPageNum,
		totalPages,
		searchTerm,
		setSearchTerm,
		isLoading,
		resetNewsState
	} = useWebNewsContext();

	// console.log("posts", posts);

	const getHeaderText = () => {
		let text = "Latest News";
		if (searchTerm || currentPageNum || totalPages) {
			if (searchTerm || currentPageNum > 1) {
				text = "News";
			}
		}
		return text;
	};

	// Page num route parameter
	useEffect(() => {
		if (params?.num) {
			setCurrentPageNum(parseInt(params.num));
		} else {
			resetNewsState();
			window.scrollTo(0, 0);
		}
	}, [params]);

	// const isLoadingTrue = true;

	return (
		<PrivateRoute isEnabled={isFeatureFlagEnabled(FeaturesFlags.NEWS)}>
			<Fragment>
				<Container>
					<Background src={background} />
					<Characters src={characters} />
					<PageTopNav
						navItemColor="#FFE37E"
						sectionMenuData={[]}
						isLandingPage
					/>
					<Search
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						setCurrentPageNum={setCurrentPageNum}
					/>
				</Container>
				<PageContent source={"news_list_page"} enableBackToTop>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<SectionHeader text={getHeaderText()} icon={<NewsIcon />} />
					</Box>
					{/* {isLoadingTrue ? ( */}
					{isLoading ? <NewsPageContentSkeleton /> : <PostList posts={posts} />}
					<Pagination
						count={totalPages}
						page={currentPageNum}
						setPage={setCurrentPageNum}
					/>
				</PageContent>
			</Fragment>
		</PrivateRoute>
	);
};

export default News;
