import { Dispatch, SetStateAction } from "react";
import { ConnectionStatus } from "..";
import { IDebugLogger } from "../../../utils/debugLogger";

/* eslint-disable */
const getMe = ({
	connectionStatus,
	setUserName,
	setUserId,
	setProfileImage,
	debug
}: {
	connectionStatus: string;
	setUserName: Dispatch<SetStateAction<string>>;
	setUserId: Dispatch<SetStateAction<string>>;
	setProfileImage: Dispatch<SetStateAction<string | null>>;
	debug: IDebugLogger;
}) => {
	const me = async () => {
		if (connectionStatus === ConnectionStatus.CONNECTED) {
			!!window?.FB &&
				(await (window.FB as any).api(
					"/me?fields=id,name,picture.width(100).height(100)",
					(response: any) => {
						debug.log({
							iconText: "FB.api->me:",
							message: ["response", response]
						});
						setUserName(response.name);
						setUserId(response.id);
						setProfileImage(response?.picture?.data?.url + '?timestamp=' + new Date().getTime() || null);
					}
				));
		}
	};
	return me;
};
/* eslint-enable */

export default getMe;
