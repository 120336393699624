import { useEffect } from "react";
import { useAppDispatch } from "hooks";
import { getDailyLoginStreak } from "redux/slices";
import { useWebStoreContext } from "contexts";

export const useInitialActions = (): void => {
	const dispatch = useAppDispatch();
	const { authToken } = useWebStoreContext();

	useEffect(() => {
		if (authToken?.value) {
			dispatch(getDailyLoginStreak());
		}
	}, [authToken]);
};
