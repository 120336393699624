import { LOCAL_STORAGE_DATE_JUMPED } from "debug/contexts/DebugWebStoreProvider";
import { baseApi } from "../api";

export const bouncyChestApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getChestState: builder.query({
			query: () => {
				const dateJumped = localStorage.getItem(LOCAL_STORAGE_DATE_JUMPED);

				return `/rest/bouncy-chest/is-active${
					dateJumped ? `?date=${dateJumped}` : ""
				}`;
			},

			providesTags: ["bouncyChestTag"]
		})
	})
});

export const { useGetChestStateQuery } = bouncyChestApi;
