import React from "react";
import { Container, CountDownStyles, Image } from "./styles";
import CountdownV2 from "../../../../components/CountdownV2/index";
import { useWebStoreContext } from "contexts";
import { useMediaQuery } from "@mui/material";
import { IShopPurchaseOfferClub } from "contexts/WebStoreProvider/types";
import { onGiftGaloreEventEnded } from "redux/slices";
import { useAppDispatch } from "hooks";

interface Props {
	offer: IShopPurchaseOfferClub;
	refetch: () => void;
}

export const getBackgroundUrl = (
	url: string,
	variant: "small" | "large"
): string => {
	return url?.replace("{club_shop_banner_variant}", variant);
};
export const BannerCard: React.FC<Props> = ({ offer, refetch }) => {
	const dispatch = useAppDispatch();
	const { nowTime, store } = useWebStoreContext();
	const isSmallLandscape = useMediaQuery(
		"(min-width:690px) and (max-width:815px)"
	);
	const isPortrait = useMediaQuery("(max-width:690px)");

	const gift = store?.clubGifts?.find((gift) => gift.id === offer.id);
	if (!gift?.bannerGlobalImageId) {
		return null;
	}

	const url = store?.globalImages?.find(
		(image) => image.id === gift?.bannerGlobalImageId
	)?.imageUrl as string;
	return (
		<Container>
			<Image src={getBackgroundUrl(url, isPortrait ? "small" : "large")} />
			<CountdownV2
				startTime={nowTime}
				endTime={offer?.endTime}
				text="Sale ends in: "
				onEnd={() => {
					dispatch(onGiftGaloreEventEnded());
					refetch();
				}}
				status={offer.id}
				style={isSmallLandscape ? CountDownStyles : undefined}
			/>
		</Container>
	);
};
