import { styled } from "@mui/material";
import FirstPurchaseBadgeIcon from "assets/red-badge.svg"

export const StyledCard = styled("div")`
	position: relative;
	border-radius: 12px;
	background: linear-gradient(180deg, #D8FFFD 0%, #9EEBE6 100%);
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
	padding: 8px 6px;
`;

export const RewardContainer = styled("div")`
	position: relative;
	border-radius: 8px;
	border: 2px solid #FFF;
	background: linear-gradient(180deg, #FFF 0%, #E7E7E7 100%);
	box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
	width: 100%;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const RewardImage = styled("img") <{ isLp?: boolean }>`
	width: auto;
	height: 51px;
	margin-top: ${(props) => props.isLp ? "5px" : 0};

	@media (max-width: 360px) {
		width: inherit;
		height: auto;
	}
`;

export const WebBonusText = styled("p")`
	position: absolute;
	top: 0;
	color: #333;
	font-family: "Inter";
	font-size: 9px;
	font-weight: 700;
	margin: 0;
`;

export const RewardNumber = styled("span")`
	color: #FFF;
	font-family: "Baloo";
	font-size: 15px;
	position: absolute;
	bottom: 0px;
	right: 5px;
	z-index: 1;
	text-shadow: 0px 2px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000;

	&.center {
		right: 50%;
		transform: translateX(50%);
	}

	&.cross-out {
		color: #EB5757;
		bottom: 15px;
	}
`;

export const FirstPurchaseBadge = styled("div")`
	position: absolute;
	z-index: 1;
	top: -23px;
	right: -5px;
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(${FirstPurchaseBadgeIcon});
	background-size: cover;
	transform: rotate(-21deg);
	color: #fff;
	font-family: "Baloo";
	font-size: 11px;
	line-height: 12px;
	text-shadow: 0px 0px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000;

	> span {
		margin-bottom: 5px;
	}
`;

export const InfoButton = styled("button")`
	position: absolute;
	top: 3px;
	right: 0;
	background: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 1;
`;

export const CrossOutLine = styled("div")`
	::after {
		content: "";
		background-color: #eb5757;
		border: 1px solid #000;
		border-radius: 2px;
		width: 110%;
		height: 1px;
		display: block;
		position: absolute;
		top: 45%;
		margin-left: -8%;
	}
`;
