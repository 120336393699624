import { useAppDispatch, useAppSelector } from "hooks";
import { Button } from "./styles";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useCallback } from "react";
import { onOpenPopUp, selectPoisBountyState } from "redux/slices";
import { Info } from "assets/pois-bounty/svg";

interface InfoButtonProps {}

export const InfoButton: React.FC<InfoButtonProps> = () => {
	const dispatch = useAppDispatch();
	const { tracker } = useAppSelector(selectPoisBountyState).data;
	const { reportEvent } = useGoogleAnalyticsContext();

	const onLearnMore = useCallback(() => {
		dispatch(onOpenPopUp("pb-learn-more"));
		reportEvent("learn_more_clicked", {
			source: "pois_bounty_menu",
			pois_bounty_id: tracker.eventId
		});
	}, [tracker]);

	return (
		<Button onClick={onLearnMore}>
			<Info />
		</Button>
	);
};
