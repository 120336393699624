import styled from "styled-components";

export const IconContainer = styled.div`
	position: relative;
	cursor: pointer;
	border-radius: 4px;
	padding: 4px;
	display: flex;
	align-items: center;
	transition: all 0.3s ease;
	:hover {
		background: rgba(0, 0, 0, 0.1);
		transition: all 0.3s ease;
	}
`;

export const Dot = styled.div`
	position: absolute;
	top: -2px;
	right: 0;
	width: 13px;
	height: 13px;
	border-radius: 100%;
	background-color: #e53939;
`;
