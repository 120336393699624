import { getVariantImage } from "utils/getVariantImage";

export const getClassByNodesCount = (nodes: number) => {
	if (nodes > 5) {
		return "several-nodes";
	}
	return "";
};

export const getBackgroundUrl = (url: string, isPortrait: boolean) => {
	return getVariantImage(
		url,
		"{endless_offer_variant}",
		isPortrait ? "small" : "large"
	);
};
