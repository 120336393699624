import { Flag } from "./svg";
import { Container, Text } from "./styles";

interface FreeProps {}

export const Free: React.FC<FreeProps> = () => {
	return (
		<Container>
			<Text>Free</Text>
			<Flag />
		</Container>
	);
};
