import { showDebugLabelsPath } from "./helper";
import { DebugSlice } from "./types";

const storedShowDebugLabels = localStorage.getItem(showDebugLabelsPath);
const initialShowDebugLabels = storedShowDebugLabels
	? JSON.parse(storedShowDebugLabels)
	: false;

export const initialState: DebugSlice = {
	showDebugLabels: initialShowDebugLabels,
	debugEnabled: {
		success: false,
		errorMessage: "",
		enabled: false
	}
};
