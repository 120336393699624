import { ButtonProps } from "@mui/material/Button";
import React, { ForwardedRef, ReactNode } from "react";
import {
	StyledButton,
	StyledButtonContainedSecondary,
	StyledButtonContainedTertiary,
	StyledButtonOutlined,
	StyledButtonText
} from "./styled";

interface IThemeButtonProps extends ButtonProps {
	children: ReactNode;
	className?: string;
	themeColor?: "primary" | "secondary" | "tertiary";
}

const ThemeButton = React.forwardRef(
	(
		{
			children,
			onClick,
			className,
			themeColor = "primary",
			variant = "contained",
			...props
		}: IThemeButtonProps,
		ref: ForwardedRef<HTMLButtonElement | null>
	): JSX.Element => {
		// TODO: fix "any"
		/* eslint-disable-next-line */
		const buttonProps: any = {
			variant,
			size: "large",
			onClick,
			className,
			ref,
			...props
		};
		if (variant === "contained" && themeColor === "secondary") {
			return (
				<StyledButtonContainedSecondary {...buttonProps}>
					{children}
				</StyledButtonContainedSecondary>
			);
		}
		if (variant === "contained" && themeColor === "tertiary") {
			return (
				<StyledButtonContainedTertiary {...buttonProps}>
					{children}
				</StyledButtonContainedTertiary>
			);
		}
		if (variant === "outlined") {
			return (
				<StyledButtonOutlined {...buttonProps}>{children}</StyledButtonOutlined>
			);
		}
		if (variant === "text") {
			return (
				<StyledButtonText {...buttonProps}>{children}</StyledButtonText>
			);
		}
		return <StyledButton {...buttonProps}>{children}</StyledButton>;
	}
);

ThemeButton.displayName = "ThemeButton";

export default ThemeButton;
