import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { ProfileIcon } from "utils/svgIcons";
import { UserProfileImage } from "../styled";

export const ProfileImage = () => {
	const { profileImage } = useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<UserProfileImage>
			{profileImage ? (
				<img
					src={profileImage}
					onClick={() => {
						reportEvent("profile_click", { source: "navbar" });
					}}
				/>
			) : (
				<ProfileIcon />
			)}
		</UserProfileImage>
	);
};
