import { RewardType } from "components/DIP/types";
import { selectWebStoreState } from "redux/slices";
import { useAppSelector } from "./useAppSelector";

interface IUseLoadReward {
	loadReward: (rewardId: string) => RewardItem;
}

interface RewardItem {
	imageUrl: string;
	numberOfItems: number;
}

export const useLoadReward = (): IUseLoadReward => {
	const { rewards, rewardTypes } = useAppSelector(selectWebStoreState).store;

	const loadReward = (rewardId: string): RewardItem => {
		if (!rewardId) {
			return {} as RewardItem;
		}

		const diplayReward = {} as RewardItem;
		if (!rewards) {
			return diplayReward;
		}
		const reward = rewards[rewardId as string];

		if (!rewardId.includes("chest")) {
			if (!reward.items) {
				return diplayReward;
			}
	
			Object.entries(reward.items).forEach((item) => {
				(rewardTypes as RewardType[]).forEach((rewardType) => {
					if (rewardType.id === item[0]) {
						diplayReward.imageUrl = rewardType.imageUrl;
						diplayReward.numberOfItems = item[1] as number;
					}
				});
			});
		} else {
			diplayReward.imageUrl = reward?.overrideImageUrl || "";
		}
		return diplayReward;
	};

	return { loadReward };
};
