import { baseApi } from "../api";

export const dripOfferApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		claimDripOfferReward: builder.mutation({
			query: (body) => {
				const queryParams = new URLSearchParams();
				for (const key in body) {
					queryParams.append(key, body[key]);
				}
				return {
					url: `/rest/drip-offer/claim-reward?${queryParams.toString()}`,
					method: "POST"
					//body
				};
			},
			//invalidatesTags: [{ type: "featureTrackerTag", id: "drip-offer" }]
		}),
		suppressResponse: builder.mutation({
			query: (body) => {
				const queryParams = new URLSearchParams();
				for (const key in body) {
					queryParams.append(key, body[key]);
				}
				return {
					url: `/rest/drip-offer/suppress-response?${queryParams.toString()}`,
					method: "POST"
					//body
				};
			},
			//invalidatesTags: [{ type: "featureTrackerTag", id: "drip-offer" }]
		})
	})
});

export const { useClaimDripOfferRewardMutation, useSuppressResponseMutation } = dripOfferApi;
