import { Alert } from "./icon";
import { useMediaQuery } from "@mui/material";
import { tabletGiftsGaloreView } from "../../styles";
import { useGiftGaloreTracker } from "pages/ClubShop/ClubEventSection";
import { ProgressInfoStyled, Label, BuyButton, Container } from "./styles";

interface ProgressInfoProps {}

export const ProgressInfo: React.FC<ProgressInfoProps> = () => {
	const { moreClubMembersNeeded, tracker } = useGiftGaloreTracker();
	const { isUserQualifiedToContribute } = tracker;
	const isMobile = useMediaQuery(`(max-width:${tabletGiftsGaloreView})`);

	return (
		<Container
			style={{
				transform: `translateY(${moreClubMembersNeeded ? "1rem" : 0})`
			}}
		>
			<BuyButton
				disabled={!isUserQualifiedToContribute}
				style={{
					width: isUserQualifiedToContribute && isMobile ? "150px" : undefined
				}}
				onClick={() => {
					location.href = "#gift-boxes";
				}}
			>
				{!isUserQualifiedToContribute ? "Already Contributed" : "GO NOW!"}
			</BuyButton>
			{moreClubMembersNeeded && (
				<ProgressInfoStyled>
					<Alert />
					<Label>More Club Members needed to Unlock a reward!</Label>
					<Alert className="icon-alert" />
				</ProgressInfoStyled>
			)}
		</Container>
	);
};
