// import * as React from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { Grid } from "@mui/material";

const ClubShopDialog = ({
	onSetMemberTaps,
	onResetFreeClubGiftButton
}: {
	onSetMemberTaps: () => void;
	onResetFreeClubGiftButton: () => void;
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSetMemberTaps = async () => {
		setOpen(false);
		onSetMemberTaps();
	};

	const handleResetClubGift = async () => {
		setOpen(false);
		onResetFreeClubGiftButton();
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Club Shop Debug
			</Button>
			<Dialog fullWidth open={open} onClose={handleClose}>
				<DialogTitle>Club Shop Debug</DialogTitle>
				<DialogContent>
					<Grid
						container
						sx={{
							display: "flex",
							flexDirection: "row",
							marginTop: "20px",
							marginBottom: "20px"
						}}
					>
						<Button variant="outlined" onClick={handleSetMemberTaps}>
							Simulate Member Clicks
						</Button>
					</Grid>
					<Grid
						container
						sx={{
							display: "flex",
							flexDirection: "row",
							marginTop: "20px",
							marginBottom: "20px"
						}}
					>
						<Button variant="outlined" onClick={handleResetClubGift}>
							Reset Free Club Gift
						</Button>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ClubShopDialog;
