import styled from "styled-components";

export const defaultTextColor = "#014189";

export const SectionTitle = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #073670;
	display: flex;
	align-items: center;
	margin: 10px 0px 0px 0px;
	font-family: Baloo;
	font-size: 34px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 5px;
`;