import styled from "styled-components";

export const Container = styled.div`
	position: absolute;
	top: 0px;
	width: 100%;
	overflow: hidden;
`;

export const BannerImage = styled.img`
	position: relative;
	top: 0px;
	width: 100%;

	@media (orientation: landscape) {
		width: 125%;
		left: 50%;
		transform: translateX(-50%);

		@media (min-width: 1300px) {
			width: 115%;
		}
		@media (min-width: 1920px) {
			width: 105%;
		}
		@media (min-width: 2140px) {
			width: 100%;
		}
	}
`;

export const InfoButton = styled.img`
	position: absolute;
	top: 20%;
	right: 5%;
	z-index: 2;
	cursor: pointer;

	@media (orientation: landscape) {
		width: 30px;
		top: 32%;
		right: 20%;

		@media (min-width: 1300px) {
			right: 25%;
		}
	}
`;

export const Brush = styled.div<{ image?: string }>`
	position: absolute;
	bottom: 0px;
	width: 100%;
	max-height: 40px;
	content: url(${(props) => props.image});
`;
