import LoyaltyPointsHeadsUp from "../LoyaltyPointsHeadsUp";
import AppBar from "./AppBar";

const AppNavigation = ({
	children: pageContent
}: {
	children: React.ReactElement;
}) => {
	return (
		<>
			<AppBar />
			{pageContent}
			<LoyaltyPointsHeadsUp />
		</>
	);
};

export default AppNavigation;
