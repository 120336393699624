import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Buttons/ThemeButton";
import CloseIconButton from "../../lib/CloseIconButton";
import { Typography } from "@mui/material";
import {
	bundleItem_dialogContentText_typographySxProps,
	bundleItem_dialogTitleSxProps,
	dialogActions_buttonSxProps,
	DialogTitleText,
	StyledCrownIcon
} from "../styled";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useState } from "react";
import { Legalese } from "../lib/Legalese_BundleItem";
import { BundleItemsGrid } from "../lib/BundleItemsGrid";
import { ILoyaltyRewardBundleItem } from "..";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

export const BundleItem = ({
	onCloseClick,
	onClickBuy,
	rewardItems
}: {
	onCloseClick: () => void;
	onClickBuy: (offerId: string) => void;
	rewardItems: ILoyaltyRewardBundleItem[];
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const [isLoading, setIsLoading] = useState(false);
	const { dialogState } = useDialogModalRedux();
	const { offerId, itemDescription, itemPrice } = dialogState;
	return (
		<>
			<DialogTitle sx={bundleItem_dialogTitleSxProps}>
				<CloseIconButton
					onClose={() => {
						onCloseClick();
						reportEvent("dialog_x_close", {
							source: "loyalty_purchase_confirm_dialog"
						});
					}}
				/>
				<DialogTitleText>{itemDescription}</DialogTitleText>
				<BundleItemsGrid rewardItems={rewardItems} />
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 0, padding: "0px 12px" }}>
				<DialogContentText sx={{ padding: ".75rem 1rem" }}>
					<Typography
						component="span"
						sx={bundleItem_dialogContentText_typographySxProps}
					>
						Confirm your purchase
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
				<Legalese />
				{isLoading ? (
					<Button disabled sx={dialogActions_buttonSxProps}>
						processing...
					</Button>
				) : (
					<Button
						onClick={() => {
							setIsLoading(true);
							onClickBuy(offerId || "");
							reportEvent("loyalty_buy_click", {
								source: "loyalty_purchase_confirm_dialog"
							});
							// disable loading after timeout ie. if user loses internet mid-purchase
							setTimeout(() => {
								setIsLoading(false);
							}, 5000);
						}}
						sx={dialogActions_buttonSxProps}
					>
						<Typography fontSize="24px">
							<StyledCrownIcon />
							{itemPrice?.toLocaleString("en-US")}
						</Typography>
					</Button>
				)}
			</DialogActions>
		</>
	);
};
