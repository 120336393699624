import { useAppDispatch, useAppSelector } from "hooks";
import { Button } from "./styles";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useCallback } from "react";
import { onOpenPopUp, selectPoisBountyV2State } from "redux/slices";
import { Info } from "assets/pois-bounty/svg";

interface InfoButtonProps {}

export const InfoButton: React.FC<InfoButtonProps> = () => {
	const dispatch = useAppDispatch();
	const { trackers } = useAppSelector(selectPoisBountyV2State);
	const { reportEvent } = useGoogleAnalyticsContext();

	const onLearnMore = useCallback(() => {
		dispatch(onOpenPopUp("pb-pbftue-v2"));
		reportEvent("learn_more_clicked", {
			source: "pois_bounty_v2",
			pois_bounty_id: trackers.eventId
		});
	}, [trackers]);

	return (
		<Button onClick={onLearnMore}>
			<Info />
		</Button>
	);
};
