import { DipResponse } from "interfaces";
import { DipSlice } from "./types";

export const initialState: DipSlice = {
	opened: false,
	activeTrigger: undefined,
	triggerCached: undefined,
	currentDip: undefined,
	pendingData: false,
	data: {} as DipResponse,
	queueTriggers: [],
	dipType: undefined,
	claimRewardProcess: {
		loading: false,
		error: false,
		claiming: false,
	},
	availableTriggers: [],
	mode: "default",
	debug: {
		segments: [],
		data: undefined,
		userSegment: undefined
	}
};
