import { Box, SxProps } from "@mui/material";
import { ScrollToContentButton } from "../ScrollToContentButton";
import {
	AppStoreButtonApple,
	AppStoreButtonGoogle
} from "./lib/AppStoreButtons";
import { PlayNowText } from "./lib/PlayNowText";
import {
	AppStoreButtonContainer,
	appStoreButtonContainerSxProps
} from "./styled";

const HeroGetGame = ({
	sx = {},
	sectionContentRef
}: {
	sx?: SxProps;
	sectionContentRef: React.RefObject<HTMLElement>;
}) => {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
			<ScrollToContentButton sectionContentRef={sectionContentRef} />
			<PlayNowText />
			<AppStoreButtonContainer sx={appStoreButtonContainerSxProps}>
				<AppStoreButtonGoogle />
				<AppStoreButtonApple />
			</AppStoreButtonContainer>
		</Box>
	);
};

export default HeroGetGame;
