import styled from "styled-components";

export const Container = styled.div`
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 55%;
	height: 30%;
	z-index: 3;

	@media (min-width: 733px) {
		width: 18%;
		height: 42%;
	}
	/* align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: center; */
`;

export const CrabImage = styled.img`
	width: 75%;
	position: absolute;
	bottom: 0px;
	object-fit: contain;
`;

export const CrabBubbleContainer = styled.div<{ image?: string }>`
	width: 60%;
	height: 38%;
	position: absolute;
	background-image: url(${(props) => props.image});
	background-repeat: no-repeat;
	background-size: 100% auto;
	top: 0px;
	right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 733px) {
		width: 70%;
		height: 44%;
	}
`;

export const DescriptionContainer = styled.div`
	position: absolute;
	padding: 6%;
	color: #333;
	text-align: center;
	font-weight: 400;
	font-size: 20px;
	font-family: Baloo;
	font-style: normal;
	@media (min-width: 733px) {
		font-size: 12px;
	}
`;

export const Title = styled.div`
	color: #000;
	line-height: 100%;
	-webkit-text-stroke-width: 2;
	-webkit-text-stroke-color: #fff;
	font-size: clamp(1em, 1.6dvw, 2.16em);

	@media (min-width: 733px) {
		font-size: clamp(1.25em, 1.6dvw, 2.16em);
	}
`;

export const SuperOfferTitle = styled.div`
	color: #000;
	line-height: 100%;
	text-shadow: 0 0 5px #fbbd05;
	font-size: clamp(0.94em, 1.6dvw, 1.92em);

	@media (min-width: 733px) {
		font-size: clamp(1.17em, 1.6dvw, 1.92em);
	}
`;

export const Message = styled.div`
	//color: #000;
	line-height: 100%;
	font-size: clamp(0.85em, 1.2dvw, 1.25em);

	@media (min-width: 733px) {
		font-size: clamp(0.9em, 1.5dvw, 1.6em);
	}
`;
