import { PREFIX } from "./helper";
import service from "services/api";
import { RootState } from "redux/store";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const clearWaterfallCurrentTracker = createAsyncThunk(
	`${PREFIX}/clear-current-tracker`,
	async (_, thunkAPI): Promise<void> => {
		const { sessionTracker } = (thunkAPI.getState() as RootState).waterfall;
		if (!sessionTracker) {
			return;
		}
		const { saleId } = sessionTracker;
		try {
			await service.Post(
				`/rest/debug/waterfall-sale/tracker/delete?saleId=${saleId}`
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const clearWaterfallAllTracker = createAsyncThunk(
	`${PREFIX}/clear-all-tracker`,
	async (): Promise<void> => {
		try {
			await service.Post("/rest/debug/waterfall-sale/tracker/delete-all");
		} catch (error) {
			console.log(error);
		}
	}
);
