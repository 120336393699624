import { IShowErrorDialogOptions } from "redux/slices";
import { getDebugLogger, IDebugLogger } from "../../../utils/debugLogger";
import { WsDialogs } from "../types";
import { DialogVariant, IDialogProps } from "interfaces";

const debug = getDebugLogger({
	color: "OrangeRed",
	iconText: "OOPS:"
});

export const simulateErrorResponse = (
	errorCode: string,
	errorMessage: string
) => {
	return { response: { data: { errorCode, errorMessage } } };
};

export interface IGetHandleErrorResponseProps {
	// eslint-disable-next-line
	showErrorDialog: (response: any, options?: IShowErrorDialogOptions) => void;
	logout: () => void;
	backendUri: string;
	openDialog: (variant: DialogVariant, props?: IDialogProps) => void;
	// eslint-disable-next-line
	reportEvent: (eventName: string, eventValues: any) => void;
	debug: IDebugLogger;
	setNetworkErrorDetected: React.Dispatch<React.SetStateAction<boolean>>;
	showTryReLoginDialog: () => void;
}

export type IGetHandleErrorResponseReturnType = (
	responseData: IErrorDataResponse | undefined
) => void;
export interface IErrorDataResponse {
	success?: boolean;
	errorCode?: string;
	errorMessage?: string;
}

const getHandleErrorResponse = ({
	logout,
	showErrorDialog,
	openDialog,
	setNetworkErrorDetected,
	showTryReLoginDialog
}: IGetHandleErrorResponseProps): IGetHandleErrorResponseReturnType => {
	// eslint-disable-next-line
	const handleErrorResponse = (error: any): void => {
		const { response } = error;
		const responseStatus: number | undefined = response?.status;
		const responseData: IErrorDataResponse | undefined = response?.data;

		let state = {
			errorCode: "unhandled_error",
			errorMessage: "unhandled error"
		};

		if (!responseData) {
			state = {
				errorCode: "request_failed",
				errorMessage: error?.message ? error.message : "Request failed"
			};
			if (error?.message === "Network Error") {
				setNetworkErrorDetected(true);
			}
		} else {
			const { errorCode, errorMessage } = responseData;
			if (errorCode) {
				state.errorCode = errorCode;
			}
			if (errorMessage !== undefined) {
				state.errorMessage = errorMessage;
			}
		}

		debug.logError({
			iconText: "handleErrorResponse:",
			message: [JSON.stringify(state)]
		});
		if (!responseData) {
			if (responseStatus === 500) {
				logout();
				return showTryReLoginDialog();
			}
			return showErrorDialog({ data: state });
		}

		const errorDialogOptions = {
			isContinueButtonEnabled: true,
			isReLoginEnabled: false,
			isReloadButtonEnabled: false
		};
		switch (state.errorCode) {
			/**
			 * Early exit ie. no popup conditions
			 */
			case "maintenance_mode":
				// if (!isLocalNetwork()) {
				debug.log({
					iconText: "MAINTENANCE MODE:",
					message: ["handleErrorResponse, reloading"]
				});
				return location.reload();
			// }

			/**
			 * break conditions for extra logic with default error dialog
			 */
			case "facebook_access_token_error":
			case "facebook_access_token_expired":
			case "auth_token_expired":
			case "invalid_auth_token":
				// console.warn("TRY LOGGING IN AGAIN");
				errorDialogOptions.isReLoginEnabled = true;
				errorDialogOptions.isContinueButtonEnabled = false;
				logout();
				break;
			case "banned_user":
			case "archived_user":
			case "no_user_id_for_facebook_user":
			case "no_user_id_for_scopely_user":
				logout();
				break;

			// ----------------------------------------------------------------
			// errors that can predetermined via user eligibility check:
			case "daily_gift_unavailable":
				console.warn("daily gift unavailable");
				break;
			case "exceeds_purchase_limit":
				break;
			case "endless_offer_node_reward_already_claimed":
				state.errorCode = state.errorMessage;
				state.errorMessage = "";
				break;
			case "endless_offer_node_offer_already_purchased":
				state.errorCode = state.errorMessage;
				state.errorMessage = "";
				break;
			case "insufficient_loyalty_point_balance":
				break;
			case "only_non_payers":
				// errorCode used for text display:
				state.errorCode = "offer_unavailable";
				break;
			// ----------------------------------------------------------------

			case "offer_not_found":
				break;
			case "inactive_offer":
				break;
			case "invoice_not_found":
				break;
			case "xsolla_token_creation_failure":
				console.log("XSOLLA TOKEN ERROR", state);
				debug.log({
					iconText: "handleErrorResponse:",
					message: [state.errorMessage]
				});
				break;
			case "exception":
				errorDialogOptions.isContinueButtonEnabled = false;
				errorDialogOptions.isReloadButtonEnabled = true;
				break;

			default:
				debug.log({
					iconText: "handleErrorResponse:",
					message: [state.errorMessage]
				});
		}
		// setWsDialog(WsDialogs.DEFAULT);
		openDialog(WsDialogs.DEFAULT, {
			title: "error",
			message: state.errorMessage
		});
		return showErrorDialog(
			{
				data: state
			},
			errorDialogOptions
		);
	};

	return handleErrorResponse;
};

export default getHandleErrorResponse;
