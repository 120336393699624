import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Typography,
	DialogActions,
	Grid
} from "@mui/material";
import ThemeButton from "components/Buttons/ThemeButton";
import { useEnvContext, useGoogleAnalyticsContext } from "contexts";
import { useMegaMenuRedux } from "redux/slices/mega-menu/hooks/useMegaMenuRedux";
import CloseIconButton from "../lib/CloseIconButton";
import {
	dialogSxProps,
	dialogContentSxProps,
	dialogContentTextSxProps,
	typographyMainHeadingSxProps,
	gridContainerSxProps,
	dialogActionsSxProps,
	buttonSxProps
} from "./styled";

const DialogLoyaltyFaq = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onCloseClick: () => void;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { cdnPrefix } = useEnvContext();
	const { toggleDrawer } = useMegaMenuRedux();
	return (
		<Dialog open={show} onClose={onCloseClick} sx={dialogSxProps}>
			<DialogTitle>
				<CloseIconButton
					onClose={() => {
						onCloseClick();
						reportEvent("dialog_x_closed", { source: "loyalty_faq_dialog" });
					}}
				/>
			</DialogTitle>
			<DialogContent sx={dialogContentSxProps}>
				<DialogContentText sx={dialogContentTextSxProps}>
					<Typography
						variant="h5"
						component="strong"
						sx={typographyMainHeadingSxProps}
					>
						Earn Loyalty Points with every purchase!
					</Typography>
					<Grid container spacing={0} sx={gridContainerSxProps}>
						<Grid item>
							<img src={`${cdnPrefix}/spec_loyalty_faq/col1-shop.png`} />
							<Typography className="title">Shop</Typography>
							<Typography className="desc" variant="caption" component="p">
								Purchase items in the Shop and collect Loyalty Points from your
								purchases.
							</Typography>
						</Grid>
						<Grid item>
							<img src={`${cdnPrefix}/spec_loyalty_faq/col2-redeem.png`} />
							<Typography className="title">Redeem</Typography>
							<Typography className="desc" variant="caption" component="p">
								Use your Loyalty Points to redeem for exclusive rewards in the
								Loyalty Points.
							</Typography>
						</Grid>
						<Grid item>
							<img src={`${cdnPrefix}/spec_loyalty_faq/col3-use.png`} />
							<Typography className="title">Use</Typography>
							<Typography className="desc" variant="caption" component="p">
								Return to your inbox in Tiki Solitaire TriPeaks and use your
								rewards in-game.
							</Typography>
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={dialogActionsSxProps}>
				<ThemeButton
					onClick={() => {
						onCloseClick();
						toggleDrawer(false);
						reportEvent("continue_shopping_click", {
							source: "loyalty_faq_dialog"
						});
					}}
					sx={buttonSxProps}
				>
					Continue Shopping
				</ThemeButton>
			</DialogActions>
		</Dialog>
	);
};

export { DialogLoyaltyFaq };
