import styled from "styled-components";
import { SxProps, Theme } from "@mui/material";

const InformativeStyled = styled.div`
	padding: 1rem 0.5rem;
	button {
		padding: 0.8rem 1rem;
		width: 300px;
		/* @media (max-width: 720px) {
			width: 70%;
		} */
	}
`;

const buttonStyled: SxProps<Theme> = {
	gap: ".5rem",
	position: "absolute",
	bottom: "2.5%",
	left: "50%",
	transform: "translateX(-50%)"
};

export { InformativeStyled, buttonStyled };
