import classnames from "classnames";
import { Grid, Typography } from "@mui/material";
import { CrownIcon } from "utils/svgIcons";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { MouseEventHandler, Ref } from "react";
import { CardFinalMessage } from "styles/general_styles";
import { useAppSelector } from "hooks";
import { selectPoisBountyV2State } from "redux/slices";
import { CtaButton, LoyalBox, TitleImage } from "../styled";
import { useWebStoreContext } from "contexts";

export interface TitleContentProps {
	currentOffer: HTMLButtonElement | null;
	isCurrentOffer: boolean;
	titleImageUrl: string;
	offerId: string;
	buyButtonRef: Ref<HTMLButtonElement | null> | undefined;
	onClickBuy: MouseEventHandler<HTMLButtonElement>;
	ctaText: string;
	loyaltyPointReward: number;
}

export const TitleContent = ({
	currentOffer,
	isCurrentOffer,
	titleImageUrl,
	offerId,
	buyButtonRef,
	onClickBuy,
	ctaText,
	loyaltyPointReward
}: TitleContentProps) => {
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const { userTracker } = useWebStoreContext();
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;

	return (
		<>
			<Grid
				container
				spacing={2}
				direction="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
			>
				<Grid item mt={4}>
					<TitleImage className="title-image" data-imageurl={titleImageUrl} />
				</Grid>
				<Grid item width="100%" display="flex" justifyContent="center" mt={-2}>
					<CtaButton
						id={`buy-button-${offerId}`}
						ref={buyButtonRef}
						variant="contained"
						color="success"
						size="large"
						onClick={onClickBuy}
						className={classnames({
							isCurrentOfferSet: !isCurrentOffer && !!currentOffer,
							isCurrentOffer: !!isCurrentOffer
						})}
						sx={{ fontSize: { xs: "88%", sm: "100%" } }}
					>
						{ctaText}
					</CtaButton>
				</Grid>
				{showOfferId && (
					<Grid item>
						<Typography
							sx={{ fontWeight: 700, color: "red", fontSize: "10px" }}
						>
							{offerId}
						</Typography>
					</Grid>
				)}
				<Grid item mb={{ sm: 1 }}>
					<LoyalBox sx={{ fontSize: { xs: "12px", sm: "88%", md: "90%" } }}>
						<div>
							{"Earn"}
							<CrownIcon width={16} />
							{`${loyaltyPointReward.toLocaleString("en-US")}`}
						</div>
						{isPoisActive && (
							<CardFinalMessage color="#FBBD05">
								Contribute to Poi’s Bounty
							</CardFinalMessage>
						)}
					</LoyalBox>
				</Grid>
			</Grid>
		</>
	);
};
