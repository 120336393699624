import { Typography } from "@mui/material";
import PostListItem from "./PostListItem";
import SectionContent from "./SectionContentGrid";

export interface IPostListItem {
	date: Date;
	id: string;
	index: number;
	jetpack_featured_media_url: string;
	slug: string;
	title: {
		rendered: string;
	};
	excerpt: {
		rendered: string;
	};
	content: {
		rendered: string;
	};
}

const PostList = ({ posts }: { posts: IPostListItem[] }) => {
	return posts?.length && posts.length > 0 ? (
		<SectionContent
			items={posts.map((post: IPostListItem, index: number) => (
				<PostListItem key={index} post={post} />
			))}
		/>
	) : (
		<Typography align="center" sx={{ lineHeight: 5 }}>
			No Results Found..
		</Typography>
	);
};
/* eslint-enable */

export default PostList;
