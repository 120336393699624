import { AxiosError } from "axios";
import {
	IWebStoreAxiosErrorResponse,
	IWebStoreGeneralErrorResponse
} from "contexts/WebStoreProvider/types";
import { useAppSelector } from "hooks";
import { IDialogProps, DialogVariant, WsDialogs } from "interfaces";
import { useDispatch } from "react-redux";
import {
	IShowErrorDialogOptions,
	onCloseDialog,
	onOpenDialog,
	selectDialogState
} from "redux/slices";
import titleCase from "utils/titleCase";

const defaultProps: IDialogProps = {
	offerId: "",
	itemDescription: "",
	itemPrice: undefined,
	itemImageUrl: "",
	items: [],
	section: undefined
};

export const useDialogModalRedux = () => {
	const { variant, props } = useAppSelector(selectDialogState);

	const dispatch = useDispatch();

	const openDialog = (variant: DialogVariant, props: IDialogProps = {}) => {
		dispatch(onOpenDialog({ ...props, variant: variant }));
	};
	const closeDialog = () => {
		dispatch(onCloseDialog());
	};

	const showErrorDialogHelper = (error: AxiosError) => {
		showErrorDialog(error.response as IWebStoreAxiosErrorResponse);
	};

	const showErrorDialog = (
		response:
			| IWebStoreAxiosErrorResponse
			| IWebStoreGeneralErrorResponse
			| undefined,
		options: IShowErrorDialogOptions = {}
	) => {
		if (!response) {
			return;
		}
		const { errorCode, errorMessage } = response.data;
		openDialog(WsDialogs.ERROR, {
			title: titleCase(errorCode),
			message: errorMessage,
			isContinueButtonEnabled: options?.isContinueButtonEnabled,
			isReLoginEnabled: options?.isReLoginEnabled,
			isReloadButtonEnabled: options?.isReloadButtonEnabled
		});
	};

	const showTryReLoginDialog = () => {
		openDialog(WsDialogs.ERROR, {
			title: "Please login again.",
			isContinueButtonEnabled: false,
			isReLoginEnabled: true
		});
	};

	const showTryReloadDialog = () => {
		openDialog(WsDialogs.ERROR, {
			title: "Exception"
		});
	};

	return {
		variant,
		dialogState: props ?? defaultProps,
		openDialog,
		closeDialog,
		showErrorDialogHelper,
		showErrorDialog,
		showTryReLoginDialog,
		showTryReloadDialog
	};
};
