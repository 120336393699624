import { Breadcrumbs, Link as MuiLink, Typography } from "@mui/material";
import { links } from "helpers";
import { Link } from "react-router-dom";

const BreadCrumbsBlog = ({
	title
}: {
	title: string | JSX.Element | JSX.Element[];
}) => (
	<Breadcrumbs
		aria-label="breadcrumb"
		sx={{
			padding: "1rem 0",
			fontSize: "10px",
			lineHeight: "14.55px",
			a: { textDecoration: "none" }
		}}
	>
		<MuiLink underline="hover" color="inherit">
			<Link color="inherit" to={links.home.path}>
				Home
			</Link>
		</MuiLink>
		<MuiLink underline="hover" color="inherit">
			<Link color="inherit" to="/news">
				News
			</Link>
		</MuiLink>
		<Typography
			color="text.primary"
			sx={{
				fontSize: "10px",
				lineHeight: "14.55px",
				fontWeight: "bold",
				textDecoration: "underline"
			}}
		>
			{title}
		</Typography>
	</Breadcrumbs>
);

export default BreadCrumbsBlog;
