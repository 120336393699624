import useQuery from "./useQuery";

/**
 * Param: null | "?clearStorage=false"
 * isClearStorageEnabled: false
 * uri: https://webstore.tripeaksapp.com/
 * uri: https://webstore.tripeaksapp.com/?clearStorage=false
 *
 * Param: ?clearStorage | ?clearStorage=true
 * isClearStorageEnabled: true
 * uri: https://webstore.tripeaksapp.com/?clearStorage=true
 */
export default function useClearStorage() {
	const query = useQuery();
	const isClearStorageEnabled =
		query.get("clearStorage") !== null && query.get("clearStorage") === "true";

	// prettier-ignore
	const lastStorageCleanDateKey = "store.solitairetripeaks.com:lastStorageCleanDate";
	const lastStorageCleanDate = localStorage.getItem(lastStorageCleanDateKey);
	if (!lastStorageCleanDate) {
		localStorage.clear();
		localStorage.setItem(lastStorageCleanDateKey, JSON.stringify(Date.now()));
		location.reload();
	}

	return { isClearStorageEnabled };
}
