import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	width: 250px;
	margin: 0 auto;
	align-items: center;

	@media (min-width: 650px) {
		margin: unset;
		align-items: flex-end;
		justify-content: end;
		height: 300px;
		width: 300px;
		transform: translateY(2rem);
	}
	@media (min-width: 650px) and (max-height: 430px) {
		height: 200px;
		width: 240px;
	}
	@media (min-width: 1150px) {
		transform: unset;
		margin: unset;
		align-items: flex-end;
		height: 350px;
		width: 380px;
	}
`;

export const Image = styled.img`
	object-fit: cover;
	width: 75%;
	height: 100%;

	@media (min-width: 650px) {
		object-fit: contain;
		//position: absolute;
		bottom: 20px;
		right: -50px;
	}
	@media (min-width: 650px) and (max-height: 430px) {
		right: -20px;
		bottom: 5px;
	}
`;
