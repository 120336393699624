import { ClubName } from "..";
import { useMediaQuery } from "@mui/material";
import { tabletGiftsGaloreView } from "../../styles";
import { getVariantImage } from "utils/getVariantImage";
import { Info as InfoStyled, Title, PurchaseLabel } from "./styles";
import { useGiftGaloreTracker } from "pages/ClubShop/ClubEventSection";

interface InfoProps {}

export const Info: React.FC<InfoProps> = () => {
	const { tracker, eventIsCompleted } = useGiftGaloreTracker();
	const { titleImageUrl } = tracker;
	const isMobile = useMediaQuery(`(max-width:${tabletGiftsGaloreView})`);
	const eventCompletedMobileUI = isMobile && eventIsCompleted;

	return (
		<InfoStyled style={{ width: eventCompletedMobileUI ? "70%" : undefined }}>
			<ClubName className="clubName" name={tracker.clubName} />
			<Title
				src={getVariantImage(
					titleImageUrl,
					"{club_gifts_galore_variant}",
					isMobile ? "small" : "large"
				)}
			/>
			<PurchaseLabel
				style={{ fontSize: eventCompletedMobileUI ? "30px" : undefined }}
			>
				{eventIsCompleted
					? "Congratulations! Event Completed!"
					: "Each member's Large Gift Box purchase gives you progress towards a bonus Reward!"}
			</PurchaseLabel>
		</InfoStyled>
	);
};
