import { ReactNode, useEffect, useState } from "react";
import { useWebStoreContext } from "../../contexts/WebStoreProvider";
import {
	Box,
	Breadcrumbs,
	Grid,
	Link,
	Stack,
	styled,
	Switch,
	SwitchProps,
	Typography
} from "@mui/material";
import { useGoogleAnalyticsContext } from "../../contexts/GoogleAnalyticsProvider";
import { PageContent } from "components/PageContent";
import { links } from "helpers";

const PrivacyAdsToggle = () => {
	const { adsPersonalization } = useGoogleAnalyticsContext();
	const { handleAdsPersonalizationChange } = useWebStoreContext();
	const [isChecked, setIsChecked] = useState(!adsPersonalization);

	const handleToggleChange = () => {
		handleAdsPersonalizationChange();
		setIsChecked(!isChecked);
	};

	useEffect(() => {
		window && window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div
				style={{
					backgroundColor: "white"
				}}
			>
				<PageContent
					source="privacy_ads_toggle_page"
					sxContainer={{ backgroundImage: "none", backgroundColor: "white" }}
				>
					<BreadCrumbsPrivacyAdsToggle />
					<Typography
						variant="h5"
						sx={{ marginBottom: { xs: "0", sm: "2rem" }, fontWeight: "bold" }}
					>
						Do Not Sell or Share My Personal Information
					</Typography>
					<Grid
						container
						spacing={0}
						sx={{ flexDirection: { xs: "column-reverse", sm: "row" } }}
					>
						<Grid
							item
							xs={12}
							sm={5}
							sx={{
								backgroundColor: "#FFF7D1",
								padding: "1.33rem",
								borderRadius: "10px",
								position: { xs: "fixed", sm: "static" },
								bottom: 0,
								zIndex: { xs: 111, sm: "auto" }
							}}
						>
							<Box sx={{ display: { xs: "none", sm: "block" } }}>
								<PrivacyColumn1TextHiddenOnMobile />
							</Box>

							<PrivacyTypography>
								If you would like to opt out of either the
								&quot;sale&quot;/&quot;sharing&quot; of your information or
								&quot;targeted advertising&quot; related to personalized ads
								that appear in connection with this site, move the toggle to the
								&quot;on&quot; position.
							</PrivacyTypography>

							<AdsToggleSwitch
								isChecked={isChecked}
								handleToggleChange={handleToggleChange}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={7}
							sx={{
								padding: "1.33rem",
								borderRadius: "10px"
							}}
						>
							<Box
								sx={{
									height: { xs: "50vh", sm: "auto" },
									backgroundColor: { xs: "#F5F5F5", sm: "transparent" },
									padding: { xs: "1em", sm: 0 },
									borderRadius: "10px",
									overflow: "auto",
									// TODO: scrollbar colors don't seem to work properly?
									"::-webkit-scrollbar-thumb": {
										background: "blue"
									},
									scrollbarColor: "blue white"
								}}
							>
								<Box sx={{ display: { xs: "block", sm: "none" } }}>
									<PrivacyColumn1TextHiddenOnMobile />
								</Box>
								<PrivacyTypography>
									Please note that if you have created a Scopely Account to log
									in across multiple Scopely apps and/or websites, then we will
									make reasonable efforts to apply your opt-out request to each
									of those apps and sites. Otherwise, if you want to opt out of
									personalized advertising arising from your use of another
									Scopely app or site, please visit that particular app’s or
									site’s Do Not Sell or Share My Personal Information page.
								</PrivacyTypography>
								<PrivacyTypography>
									Additionally, note that if you opt out, you may continue to
									see ads on this site, but they will be less relevant to your
									interests. You may also still see personalized ads on your
									devices if you do not opt out from all of your devices and
									browsers and if you do not opt out of personalized advertising
									on the other apps and websites that you visit. To learn more
									about your additional choices for opting out of personalized
									advertising on other apps and websites, you can visit{" "}
									<Link target="_blank" href="http://optout.aboutads.info/#/">
										http://optout.aboutads.info/#/
									</Link>{" "}
									or{" "}
									<Link
										target="_blank"
										href="http://optout.networkadvertising.org/#"
									>
										http://optout.networkadvertising.org/#
									</Link>
									.
								</PrivacyTypography>
								<PrivacyTypography>
									We also recognize the Global Privacy Control as a valid,
									additional opt-out method. To learn more about the Global
									Privacy Control, please visit{" "}
									<Link
										target="_blank"
										href="https://globalprivacycontrol.org/"
									>
										https://globalprivacycontrol.org/
									</Link>
									. Note that the Global Privacy Control is currently only
									applicable to opt-outs from websites. For mobile apps, please
									follow the instructions above.
								</PrivacyTypography>
								<PrivacyTypography>
									You can learn more about our privacy practices by reading our
									&nbsp;
									<Link target="_blank" href="http://scopely.com/privacy">
										Privacy Policy
									</Link>
									.
								</PrivacyTypography>
							</Box>
						</Grid>
					</Grid>
				</PageContent>
			</div>
		</>
	);
};

export default PrivacyAdsToggle;

const BreadCrumbsPrivacyAdsToggle = () => (
	<Breadcrumbs
		aria-label="breadcrumb"
		sx={{ padding: "1rem 0", fontSize: "10px", lineHeight: "14.55px" }}
	>
		<Link underline="hover" color="inherit" href={links.home.path}>
			Home
		</Link>
		<Link underline="hover" color="inherit" href="http://scopely.com/privacy">
			Privacy Policy
		</Link>
		<Typography
			color="text.primary"
			sx={{ fontSize: "10px", lineHeight: "14.55px" }}
		>
			Do Not Sell or Share My Personal Information
		</Typography>
	</Breadcrumbs>
);

const PrivacyTypography = ({ children }: { children: ReactNode }) => (
	<Typography
		variant="body2"
		sx={{
			marginBottom: "1rem",
			fontSize: "12px",
			lineHeight: "22px"
		}}
	>
		{children}
	</Typography>
);

const AdsToggleSwitch = ({
	isChecked,
	handleToggleChange
}: {
	isChecked: boolean;
	handleToggleChange: () => void;
}) => (
	<Stack
		direction="row"
		spacing={1}
		alignItems="center"
		sx={{
			justifyContent: "space-between",
			backgroundColor: "white",
			borderRadius: "10px",
			padding: "0 .5rem",
			marginRight: { xs: "21%", sm: "auto" }
		}}
	>
		<Typography
			sx={{
				fontWeight: "medium",
				fontSize: "14px",
				lineHeight: "22.63px"
			}}
		>
			Opted {isChecked ? "Out" : "In"}
		</Typography>
		<IOSSwitch
			inputProps={{ "aria-label": "Opted in/out" }}
			checked={isChecked}
			onChange={handleToggleChange}
			name="Opted in/out"
		/>
	</Stack>
);

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5
			}
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff"
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600]
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3
		}
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500
		})
	}
}));

const PrivacyColumn1TextHiddenOnMobile = () => (
	<PrivacyTypography>
		We and our advertising partners use cookies and similar technologies to show
		you advertising personalized to your interests (known as &quot;personalized
		advertising&quot;) and measure its effectiveness. These technologies collect
		information such as advertising identifiers, IP addresses, and other unique
		identifiers from your devices. Scopely does not sell this data in exchange
		for money, but we want you to know that this sharing of information with
		partners for personalized advertising purposes nonetheless may be considered
		either a &quot;sale&quot; or &quot;share&quot; of personal information under
		the California Consumer Privacy Act (CCPA). The use of personalized
		advertising may also qualify as &quot;targeted advertising&quot; under other
		U.S. state laws. If you are located in California or one of these other
		states, you can opt out of personalized advertising as described below.
	</PrivacyTypography>
);
