import classNames from "classnames";
import { useEnvContext } from "contexts/EnvProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { FeaturesFlags } from "helpers";
import { useAppSelector } from "hooks";
import { CSSProperties, useMemo } from "react";
import { selectPoisBountyV2State } from "redux/slices";
import { PoisBountyContext } from "./context";
import { getClassNameVariants } from "./helper";
import { useInitialActions, useVariantByTime } from "./hooks";
import { PoisBountyContainer } from "./styles";
import { InProgress } from "./variants";

interface PoisBountyWidgetProps {
	isOnMegaMenu?: boolean;
	isPortrait: boolean;
	isPageVariant?: boolean;
	style?: CSSProperties;
}

const PoisBountyWidgetV2 = ({
	isOnMegaMenu = false,
	isPortrait,
	isPageVariant = false,
	style
}: PoisBountyWidgetProps) => {
	const { isFeatureFlagEnabled } = useEnvContext();
	const { userTracker } = useWebStoreContext();
	const { variant, isEventActiveV2, blockAllButtons, trackers } = useAppSelector(selectPoisBountyV2State);
	const showWidget =
		!!userTracker &&
		isEventActiveV2 &&
		isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2);
	useInitialActions();
	useVariantByTime(isOnMegaMenu);

	const values = useMemo(
		() => ({ isPortrait, isPageVariant }),
		[isPortrait, isPageVariant]
	);

	return showWidget ? (
		<PoisBountyContext.Provider value={values}>
			<PoisBountyContainer
				style={style}
				className={classNames(
					getClassNameVariants(isPageVariant, isPortrait, blockAllButtons, variant)
				)}
				stepCount={trackers.rewardClaimStatuses.length}
			>
				<InProgress />
			</PoisBountyContainer>
		</PoisBountyContext.Provider>
	) : null;
};

export default PoisBountyWidgetV2;
