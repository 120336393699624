import { useLoadReward } from "hooks";
import boxImage from "assets/club-gifts-galore/box.png";
import starBurstImage from "assets/club-gifts-galore/star-burst.png";
import { useGiftGaloreTracker } from "pages/ClubShop/ClubEventSection";
import rewardBackgroundImage from "assets/club-gifts-galore/item-bg.png";
import {
	Bar,
	Box,
	Reward,
	Container,
	Progress,
	StarBurst,
	RewardImage,
	RewardLabel,
	CurrentMembers,
	CurrentMembersMobile,
	RewardBackground
} from "./styles";

interface ProgressBarProps {
	progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
	const { loadReward } = useLoadReward();
	const { tracker } = useGiftGaloreTracker();
	const { imageUrl, numberOfItems } = loadReward(tracker?.rewardId);
	const { purchaseCount, purchaseCountRequired } = tracker;

	return (
		<Container>
			<CurrentMembersMobile>
				{purchaseCount}/{purchaseCountRequired}
			</CurrentMembersMobile>
			<Box src={boxImage} />
			<Progress>
				<Bar style={{ width: `${progress}%` }} />
				<CurrentMembers>
					{purchaseCount}/{purchaseCountRequired}
				</CurrentMembers>
			</Progress>
			<Reward>
				<StarBurst src={starBurstImage} />
				<RewardImage src={imageUrl} />
				<RewardBackground src={rewardBackgroundImage} />
				{numberOfItems && (
					<RewardLabel>
						x{numberOfItems?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
					</RewardLabel>
				)}
			</Reward>
		</Container>
	);
};
