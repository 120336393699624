import { Typography } from "@mui/material";
import PostListItem from "./PostListItem";
import SectionContent from "./SectionContentGrid";
/* eslint-disable */
//TODO: FIX any
const PostList = ({ posts }: { posts: any[] }) => {
	return posts?.length && posts.length > 0 ? (
		<SectionContent
			items={posts.map((post: any) => (
				<PostListItem key={post.id} post={post} />
			))}
		/>
	) : (
		<Typography align="center" sx={{ lineHeight: 5 }}>
			No Results Found..
		</Typography>
	);
};
/* eslint-disable */

export default PostList;
