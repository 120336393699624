import styled from "styled-components";
import { defaultTextColor } from "../../styles";
import { CardFinalMessage, CardItemContainer } from "../styles";
interface ICardContainer {
	color?: string;
	image?: string;
}

export const CardContainer = styled.div<ICardContainer>`
	background: ${(props) => props.color};
	box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
	border-radius: 30px;
	margin: 10px;
	animation: fadeIn 1s;
	position: relative;
	overflow: hidden;
	@media (min-width: 750px) {
		height: 380px;
		background-image: url(${(props) => props.image});
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: -150px;
	}
	@media (min-width: 850px) {
		background-position-x: -50px;
	}
	@media (min-width: 1000px) {
		height: 410px;
		background-position-x: 0;
	}
`;

export const DataContainer = styled.div`
	padding: 16px;
	@media (min-width: 750px) {
		padding: 20px;
		background: #ebf4ff;
		width: 350px;
		height: 225px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 20px;
		border-radius: 20px;
		position: absolute;
		bottom: 0px;
	}
	@media (min-width: 850px) {
		padding: 20px;
		background: #ebf4ff;
		width: 400px;
		height: 255px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 20px;
		border-radius: 20px;
		position: absolute;
		bottom: 0px;
	}
`;

export const CardImage = styled.img`
	width: 100%;
	@media (min-width: 750px) {
		display: none;
	}
`;

export const ComeBackMessage = styled.div`
	text-align: center;
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	margin: 0px 0px 40px 0px;
	@media (min-width: 750px) {
		font-size: 18px;
		line-height: 28px;
	}
`;

export const FinalMessage = styled(CardFinalMessage)`
	margin: 5px 0px 0px 0px;
	@media (min-width: 750px) {
		font-size: 16px;
	}
`;

export const ItemContainer = styled(CardItemContainer)`
	height: 60px;
	@media (min-width: 750px) {
		padding: 2px;
	}
`;
