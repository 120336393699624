export const getMobileGradient = (
	color: string,
	variant: "body" | "head"
): string => {
	return variant === "head"
		? `linear-gradient(96deg, ${color} 10%, rgba(0, 0, 0, 0) 95%)`
		: `linear-gradient(96deg, ${color} -500%, rgba(0, 0, 0, 0) 95%)`;
};

export const getDesktopGradient = (color: string): string =>
	`linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, ${color} 580%)`;
