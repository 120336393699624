import { Card, styled, Typography } from "@mui/material";
import flagSvgCenter from "../../../../assets/Flag.svg";

export const StyledCard = styled(Card)`
	color: #014189;
	text-align: left;
	position: relative;
	display: flex;
	justify-content: left;
	flex-direction: column;
	border-radius: 20px;
	background-size: 170%;
	background-repeat: no-repeat;
	background-position: center -98px;
	background-color: #fff;

	.content {
		padding: 10px;
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 46%;
		@media (max-width: 500px) {
			padding: 5px;
			padding-top: 30px;
		}
	}
`;

export const RewardImage = styled("img")`
	width: auto;
	/* height: 72px;
	margin-bottom: 0.7rem; */
	height: 86px;
	margin-bottom: 0.3rem;
	position: absolute;
	bottom: 20px;
	z-index: 1;
`;

export const Bonus = styled("div")`
	background-image: url(${flagSvgCenter});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 35px;
	position: absolute;
	width: 100%;
	justify-content: center;
	align-items: flex-end;
	display: flex;
	padding-bottom: 1rem;
	color: #fff;
	text-align: center;
	font-family: Baloo;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
`;

export const ImageWrap = styled("div")`
	position: relative;
	height: 135px;
	width: 100%;
	background-position: center bottom;
	display: flex;
	justify-content: center;
	align-items: flex-end;
`;

export const Overlap = styled("div")`
	position: absolute;
	top: -1.5rem;
	&.loyalty-variant {
		top: -1rem;
	}
`;

export const Title = styled(Typography)`
	color: #113b62;
	text-align: center;
	font-family: Baloo;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
`;

export const CrossOutValue = styled(Typography)`
	color: #eb5757;
	font-weight: 700;
	position: relative;
	font-size: 13px;
	display: flex;
	align-items: center;
	&.reward-variant {
		width: fit-content;
		text-align: center;
		font-family: Baloo;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;

		&.small {
			@media (max-width: 950px) {
				font-size: 15px;
			}
		}
	}
`;

export const CrossOutLine = styled("span")`
	border-bottom: 2px solid #eb5757;
	width: 100%;
	height: 1px;
	display: block;
	position: absolute;
	top: 41%;
`;
