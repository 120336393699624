import { useEffect } from "react";
import { useAppDispatch } from "hooks";
import { onClosePopUp } from "redux/slices";
import CTA from "components/Buttons/ThemeButton";
import { Head, Title, Container } from "./styles";
import { Grid, useMediaQuery } from "@mui/material";
import { useGoogleAnalyticsContext } from "contexts";
import titleDesktop from "assets/club-gifts-galore/title.png";
import titleMobile from "assets/club-gifts-galore/title-mobile.png";
import backgroundMobile from "assets/club-gifts-galore/earned-reward-mobile.png";
import backgroundDesktop from "assets/club-gifts-galore/earned-reward-desktop.png";
import { tabletGiftsGaloreView } from "pages/ClubShop/ClubEventSection/lib/GiftsGaloreCard/styles";

interface CGGEarnedRewardProps { }

export const CGGEarnedReward: React.FC<CGGEarnedRewardProps> = () => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const isMobileSize = useMediaQuery(`(max-width:${tabletGiftsGaloreView})`);

	useEffect(() => {
		reportEvent("reward_pop_up", {
			source: "club_gifts_galore"
		});
	}, []);

	return (
		<Container /* isMobile={isMobile} */ background={isMobileSize ? backgroundMobile : backgroundDesktop}>
			<Head>
				<Title src={isMobileSize ? titleMobile : titleDesktop} />
			</Head>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={12} md="auto" display="flex" justifyContent="center">
					<CTA
						style={{ width: "220px", fontSize: 20 }}
						onClick={() => {
							dispatch(onClosePopUp());
						}}
					>
						Yay!
					</CTA>
				</Grid>
				{/* {isMobile && (
					<Grid item>
						<BackToGameButton
							source="club_gifts_galore_dialog"
							themeColor="secondary"
							styles={{ width: "220px", fontSize: 20 }}
							onClick={() => {
								dispatch(onClosePopUp());
							}}
						>
							Back to Game
						</BackToGameButton>
					</Grid>
				)} */}
			</Grid>
		</Container>
	);
};
