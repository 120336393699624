import { Divider, ListItem, ListItemText, Stack } from "@mui/material";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { links } from "helpers";
import { SiteLink } from "interfaces";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CookieButton } from "../styled";

const privacyLinksData = [
	{
		...links.tos,
		analytics_source: "footer"
	},
	{
		...links.privacy,
		analytics_source: "footer"
	}
];

const PrivacyLink = ({
	link,
	children
}: {
	link: SiteLink;
	children: ReactNode;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<ListItem
			button
			component="a"
			key={link.analytics_key}
			href={link.path ? link.path : "#"}
			title={link.title}
			target={link?.target ? link.target : "_blank"}
			rel="noreferrer"
			onClick={() => {
				reportEvent(link.analytics_key, {
					source: link.analytics_source
				});
			}}
			sx={{ justifyContent: "center", textAlign: "center" }}
		>
			{children}
		</ListItem>
	);
};
const PrivacyRouterLink = ({
	link,
	children
}: {
	link: SiteLink;
	children: ReactNode;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<ListItem
			button
			component={Link}
			to={link.path}
			title={link.title}
			target={link?.target ? link.target : "_blank"}
			rel="noreferrer"
			onClick={() => {
				reportEvent(link.analytics_key, {
					source: link.analytics_source
				});
			}}
			sx={{ justifyContent: "center", textAlign: "center" }}
		>
			{children}
		</ListItem>
	);
};
const CookiesButton = () => {
	const siteData = window["OneTrust" as keyof typeof window]?.GetDomainData();
	const text = siteData ? siteData.CookieSettingButtonText : "Cookies";
	return (
		//<!-- OneTrust Cookies Settings button start -->
		<ListItem
			button
			component={CookieButton}
			onClick={() => {
				window["OneTrust" as keyof typeof window]?.ToggleInfoDisplay();
			}}
			id="ot-sdk-btn"
			className="ot-sdk-show-settings"
			sx={{
				justifyContent: "center",
				textAlign: "center",
				marginLeft: "0 !important",
				padding: ".8em 1em !important"
			}}
		>
			{text}
		</ListItem>
		//<!-- OneTrust Cookies Settings button end -->
	);
};
export const FooterPrivacyMenu = () => {
	return (
		<>
			<Stack
				direction="row"
				divider={
					<Divider orientation="vertical" flexItem sx={{ height: "20px" }} />
				}
				spacing={2}
				sx={{
					width: "auto",
					alignItems: "center",
					display: "flex",
					a: {
						color: "white",
						textDecoration: "none",
						display: "flex",
						alignItems: "center",
						whiteSpace: "nowrap",
						marginLeft: "0 !important"
					},
					".MuiDivider-root": {
						alignSelf: "auto",
						borderColor: "white",
						margin: 0
					}
				}}
			>
				{privacyLinksData.map((link: SiteLink) => {
					const listContent = (
						<ListItemText
							primary={link.title}
							sx={{ span: { fontSize: ".8rem" } }}
						/>
					);
					return link.path.indexOf("/") === 0 ? (
						<PrivacyRouterLink link={link} key={link.title}>
							{listContent}
						</PrivacyRouterLink>
					) : (
						<PrivacyLink link={link} key={link.title}>
							{listContent}
						</PrivacyLink>
					);
				})}
				<CookiesButton />
			</Stack>
		</>
	);
};
