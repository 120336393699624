import { styled } from "@mui/material";

const LogoWrap = styled("div")`
	display: inline-block;
`;

const LogoImage = styled("img")`
	margin-top: 6px;
	width: 100px;
	content: url("https://cdn.solitairetripeaks.com/WebStore/static_assets/frontend/public/logo/tiki_solitaire_logo.png");
`;

const Logo = ({ id, className }: { id?: string; className?: string }) => {
	return (
		<LogoWrap id={id} className={className}>
			<LogoImage />
		</LogoWrap>
	);
};

export default Logo;
