import { DisplayItem } from "../types";
//import { getRewardsById } from "./helper";
import { useGetOffersQuery } from "redux/apis";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { Config, DipButton, PurchaseOfferSingleItem } from "interfaces";
import useRewardsById from "hooks/useRewardsById";
//import { useEffect } from "react";

interface DIPRewards {
	getDIPReward: (item: DipButton) => DisplayItem;
}

export const useDIPRewards = (): DIPRewards => {
	const { authToken } = useWebStoreContext();
	const { data: loyaltyOffers } = useGetOffersQuery({
		offerType: "loyalty",
		isAuthenticated: !!authToken?.value
	});
	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: !!authToken?.value
	});

	const getDIPReward = (item: DipButton): DisplayItem => {
		const { actionType, loyaltyOfferId, rewardId, offerId } = item;

		let displayItem: DisplayItem = {
			buyType: "usd",
			price: 0,
			rewards: []
		};

		switch (actionType) {
			case "grantReward":
			case "grantLoyaltyReward":
				displayItem = {
					...displayItem,
					rewards: useRewardsById(rewardId as string),
					buttonLabel: "CLAIM"
				};
				break;
			case "loyaltyPurchase":
				const loyalty = loyaltyOffers?.offers.find(
					(c: Config) => c.id === loyaltyOfferId
				);

				if (loyalty) {
					const { rewardId: loyaltyRewardId } = loyalty;
					displayItem = {
						...displayItem,
						price: loyalty.loyaltyPointPrice,
						buyType: "loyalty_points",
						rewards: useRewardsById(loyaltyRewardId)
					};
				}
				break;
			case "offerPurchase":
				const offer = purchaseOffers?.offers.find(
					(offer: Config) => offer.id === offerId
				) as PurchaseOfferSingleItem;

				if (offer) {
					const { rewardId } = offer;
					displayItem = {
						...displayItem,
						price: offer.price,
						buyType: "usd",
						loyaltyPointReward: offer.loyaltyPointReward,
						rewards: useRewardsById(rewardId),
						rewardMultiplier: offer.rewardMultiplier,
						badgeText: offer.badgeText,
						badgeImageUrl: offer.badgeImageUrl
					};
				}
				break;

			default:
				console.log(`Reward - ActionType not handled: ${actionType}`);
		}

		return displayItem;
	};

	return {
		getDIPReward
	};
};
