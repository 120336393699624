import Header from "./SectionHeader";
import ContentGrid from "./SectionContentGrid";
import ContentFull from "./SectionContentFull";
import ContentFullCarousel from "./SectionContentFullCarousel";
import { RefObject } from "react";

const displayTypes = {
	full: "full",
	grid: "grid"
};
type displayType = (typeof displayTypes)[keyof typeof displayTypes];

const Section = ({
	offers,
	sectionRef,
	headerText,
	sectionPage,
	display = "full",
	Content = ContentFull,
	isHideShowLess = false,
	icon
}: {
	offers: JSX.Element[];
	sectionRef: RefObject<HTMLElement>;
	headerText: string;
	sectionPage: string;
	display?: displayType;
	Content?: ({
		items,
		isHideShowLess,
		sectionName,
		sectionPage
	}: {
		items: JSX.Element[];
		isHideShowLess: boolean;
		sectionName: string;
		sectionPage: string;
	}) => JSX.Element;
	isHideShowLess?: boolean;
	iconUrl: string;
	icon: JSX.Element;
}) => {
	if (display === "grid") {
		Content = ContentGrid;
	} else if (display === "carousel") {
		Content = ContentFullCarousel;
	}

	return (
		<>
			{offers.length > 0 && (
				<section ref={sectionRef}>
					<Header text={headerText} icon={icon} />
					<Content
						items={offers}
						isHideShowLess={isHideShowLess}
						sectionName={headerText}
						sectionPage={sectionPage}
					/>
				</section>
			)}
		</>
	);
};

export default Section;
