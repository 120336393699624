import { Dispatch, SetStateAction } from "react";
import { IDebugLogger } from "../../../utils/debugLogger";
import { useGoogleAnalyticsContext } from "../../GoogleAnalyticsProvider";
import { ConnectionStatus } from "..";
import { LoginType } from "../../../domain/Login/LoginGoogleAnalyticsHelper";

// TODO: oauth redirect params (only for mobile all else uses FB SDK)
/* eslint-disable */
const getIsMobile = () => {
	let isMobile = false;

	try {
		isMobile = !!(
			(window.navigator && (window.navigator as any).standalone) ||
			navigator.userAgent.match("CriOS") ||
			navigator.userAgent.match(/mobile/i)
		);
	} catch (ex) {
		// continue regardless of error
	}

	return isMobile;
};
/* eslint-enable */

// eslint-disable-next-line
const getParamsFromObject = (params: any) =>
	"?" +
	Object.keys(params)
		.map((param) => `${param}=${encodeURIComponent(params[param])}`)
		.join("&");

const getLogin = ({
	setFbAccessToken,
	setConnectionStatus,
	debug
}: {
	setFbAccessToken: Dispatch<SetStateAction<string | null>>;
	setConnectionStatus: Dispatch<SetStateAction<ConnectionStatus>>;
	debug: IDebugLogger;
}) => {
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();
	const login = async () => {
		if (process.env.REACT_APP_FAKE_LOGIN_FB === "true") {
			alert("LOGIN HARDCODED");
			setFbAccessToken(`${process.env.REACT_APP_FAKE_LOGIN_FB_TOKEN}`);

			return;
		}

		loginGoogleAnalyticsHelper.reportLoginOptionLoginStart(LoginType.Facebook);
		setConnectionStatus(ConnectionStatus.CONNECTING);

		const redirectUrl = `${window.location.protocol}//${window.location.host}/`; //note host includes port numbers for local dev
		const params = {
			client_id: "573256842742072", //appId,
			redirect_uri: redirectUrl,
			state: window.location.pathname,
			return_scopes: false,
			scope: "public_profile",
			response_type: "token",
			auth_type: ""
		};

		if (getIsMobile()) {
			// TODO: add tracking for mobile redirect
			window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(
				params
			)}`;
			return;
		}
		try {
			window.FB.login((response: fb.StatusResponse) => {
				if (response.status === "connected") {
					loginGoogleAnalyticsHelper.reportLoginOptionLoginSuccess(
						LoginType.Facebook
					);
					setFbAccessToken(response.authResponse.accessToken);
				} else {
					loginGoogleAnalyticsHelper.reportLoginOptionLoginFailure(
						LoginType.Facebook,
						response.status
					);
					setFbAccessToken(null);
					setConnectionStatus(ConnectionStatus.UNKNOWN);
				}
				debug.logFunction({
					iconText: "login -> FB.login:\n",
					message: ["accessToken:", response?.authResponse?.accessToken || null]
				});
			});
		} catch (error) {
			setConnectionStatus(ConnectionStatus.UNKNOWN);
		}
	};
	return login;
};

export default getLogin;
