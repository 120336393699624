import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PREFIX } from "./helper";
import { initialState } from "./initial-state";
import { collectDailyGift } from "redux/slices";

export const dailyGiftSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(collectDailyGift.fulfilled, (state, action) => {
			if (!action.payload) {
				return;
			}
			state.data = action.payload;
		});
	}
});

export const {} = dailyGiftSlice.actions;

export const selectDailyGiftState = (state: RootState) => state.dailyGift;
export default dailyGiftSlice.reducer;
export * from "./types";
export * from "./thunks";
