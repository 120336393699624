/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import useBreakpoints from "../hooks/useBreakpoints";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import theme from "theme";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { WsDialogs as IDialogVariants } from "interfaces/dialog/variant";
import { useGoogleAnalyticsContext } from "contexts";

const CarouselComponent = (props: CarouselProps) => {
	const { isMdUp, isMdDown } = useBreakpoints();
	const { authToken } = useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const [index, setIndex] = useState<number>(-1);
	const [timerId, setTimerId] = useState<NodeJS.Timeout>();
	const defaultHeight = 310;
	const [height, setHeight] = useState(defaultHeight);
	const dialogState = useSelector((state: RootState) => state.dialog);

	const [autoplay, setAutoplay] = useState(false);

	const defaultNavButtonWrapperProps = {
		style: {
			bottom: "8px",
			top: "unset",
			display:
				!!props?.children &&
					(!Array.isArray(props.children) || props.children.length <= 1)
					? "none"
					: "block"
		}
	};
	const [navButtonsWrapperProps, setNavButtonsWrapperProps] = useState(
		defaultNavButtonWrapperProps
	);

	const reactiveAutoplay = (time: number) => {
		if (timerId) {
			clearTimeout(timerId);
		}

		const newTimerId = setTimeout(() => {
			setAutoplay(true);
		}, time);

		setTimerId(newTimerId);

		return () => clearTimeout(newTimerId);
	};

	const reportRenderSlide = (n: number) => {
		const slides = Array.isArray(props.children) ? props.children : [];
		reportEvent("offer_rendered", {
			source: "feature_deal",
			offer_id: slides[n].props.data.id,
			reward_id: slides[n].props.data.rewardId,
			price: slides[n].props.data.price,
			loyalty_points: slides[n].props.data.loyaltyPointReward,
			offer_ends_time: slides[n].props.data.endTime,
			bannertext: slides[n].props.data.bannerText,
			image_url: slides[n].props.data.backgroundImageUrl
		});
	};

	const slideChange = (n: number | undefined) => {
		setAutoplay(false);
		reactiveAutoplay(3500);
		n && reportRenderSlide(n);
	};

	useEffect(() => {
		reportRenderSlide(0);
	}, []);

	// Reset autoplay on login/logout
	useEffect(() => {
		setAutoplay(false);
		setIndex(0);
		reactiveAutoplay(8000);
	}, [authToken]);

	useEffect(() => {
		if (
			dialogState?.variant ===
			(IDialogVariants.XSOLLA_PURCHASE || IDialogVariants.LOGIN_TO_CONNECT)
		) {
			clearTimeout(timerId);
			setAutoplay(false);
		} else {
			reactiveAutoplay(3500);
		}
	}, [dialogState]);

	// Set initial state at index after position 0
	useEffect(() => {
		if (index === 0) {
			setIndex(-1);
		}
	}, [index]);

	//change height based on viewport dimensions
	useEffect(() => {
		let newHeight = defaultHeight;

		if (isMdDown) {
			newHeight = 350;
		}
		if (theme.breakpoints.down("featCardImg")) {
			newHeight = 360;
		}
		if (isMdUp) {
			newHeight = 375;
		}
		setHeight(newHeight);
	}, [isMdUp, isMdDown]);

	//When items change: Hide nav buttons if 1 or less items available
	useEffect(() => {
		const navButtonsWrapperProps = {
			...defaultNavButtonWrapperProps
		};
		setNavButtonsWrapperProps(navButtonsWrapperProps);
	}, [props.children]);

	return (
		<Carousel
			animation="slide"
			swipe={
				!!props?.children &&
				(props.children as number > 1 ||
					(Array.isArray(props.children) && props.children.length > 1))
			}
			autoPlay={autoplay}
			onChange={(n) => slideChange(n)}
			height={height}
			fullHeightHover={false}
			interval={3500}
			index={index}
			indicatorIconButtonProps={{
				style: {
					color: "#01A1BD", // 3
					transform: "scale(0.7)"
				}
			}}
			indicatorContainerProps={{
				style: {
					display:
						!!props?.children &&
							(props.children as number <= 1 ||
								(Array.isArray(props.children) && props.children.length <= 1))
							? "none"
							: "block"
				}
			}}
			activeIndicatorIconButtonProps={{
				style: {
					color: "#EB5757",
					transform: "scale(1)"
				}
			}}
			navButtonsAlwaysVisible={
				!!props?.children &&
				(props.children as number > 1 ||
					(Array.isArray(props.children) && props.children.length > 1))
			}
			navButtonsAlwaysInvisible={
				!!props?.children &&
				(props.children as number <= 1 ||
					(Array.isArray(props.children) && props.children.length <= 1))
			}
			navButtonsProps={{
				style: {
					//opacity: "0.5",
					transform: "scale(0.8)",
					backgroundColor: "rgba(0, 0, 0, 0.5)",
					color: "white",
					transition: "200ms"
				}
			}}
			navButtonsWrapperProps={navButtonsWrapperProps}
		>
			{props?.children}
		</Carousel>
	);
};

export default CarouselComponent;
