import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getAvailableTriggers } from "../helper";
import { getDIPs } from "../thunks";
import { DipSlice } from "../types";
import { DipResponse } from "interfaces/dip/responses";

export const DIP_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<DipSlice>
): void => {
	builder.addCase(getDIPs.pending, (state) => {
		state.pendingData = true;
	});

	builder.addCase(getDIPs.fulfilled, (state, action) => {
		state.pendingData = false;
		const dipResponse = action.payload;
		if (!dipResponse) {
			return;
		}
		const { dips } = dipResponse;
		if (dips && dips.length >= 1) {
			state.data = dipResponse;
			state.availableTriggers = getAvailableTriggers(dips);
		} else {
			state.data = {} as DipResponse;
		}
	});
};
