import styled from "styled-components";

export const Title = styled.div`
	font-size: "16px";
	margin: 10px 15px 10px 15px;
	display: inline-block;
	transition: all 0.3s ease;
	color: #113b62;
	:hover {
		color: #4088b7;
	}
	:active {
		color: #286084;
	}
`;

export const CookieButton = styled("button")`
	background: transparent !important;
	font-size: 16px !important;
	color: #113b62 !important;
	border: none !important;
	display: inline-block;
	transition: all 0.3s ease;
	font-family: inherit !important;

	:hover {
		background: transparent !important;
		color: #4088b7 !important;
		border: none !important;
	}
	:active {
		background: transparent !important;
		color: #286084 !important;
		border: none !important;
	}
`;
