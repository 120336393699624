import styled from "@emotion/styled";
import {
	giftGaloreTextShadow,
	tabletGiftsGaloreView,
	desktopGiftsGaloreView,
	giftGaloreTextShadowMobile
} from "../../styles";

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: start;
	gap: 0.4rem;
	padding: 4rem 1rem 0rem;
	width: 65%;

	.clubName {
		margin-bottom: 0.5rem;
	}

	@media (min-width: ${tabletGiftsGaloreView}) {
		width: 100%;
		padding: 3.5rem 0 0.5rem;
		justify-content: center;
		align-items: center;
		text-align: center;

		.clubName {
			display: none;
		}
	}
	@media (min-width: ${desktopGiftsGaloreView}) {
		max-width: calc(900px - 2rem);
		padding: 2rem;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
`;

export const PurchaseLabel = styled.span`
	font-family: Berlin Sans FB Demi;
	font-size: 20px;
	font-weight: 900;
	color: #fff;
	display: block;
	text-shadow: ${giftGaloreTextShadowMobile};

	@media (min-width: ${tabletGiftsGaloreView}) {
		text-shadow: ${giftGaloreTextShadow};
		font-size: 28px;
	}
	@media (min-width: ${desktopGiftsGaloreView}) {
		font-size: 34px;
	}
`;

export const Title = styled.img`
	max-width: 220px;

	@media (min-width: ${tabletGiftsGaloreView}) {
		max-width: 88%;
	}
`;
