import moment from "moment";
import { useEffect } from "react";
import {
	LS_CLOSED_STICKY_TAGS,
	getStickiesFromLocalStorage
} from "redux/slices/sticky-sale-tag/helper";

const COOLDOWN_SEC = 10;

export const useRefreshStickyStorage = (): void => {
	const stickies = getStickiesFromLocalStorage();

	useEffect(() => {
		if (!stickies.length) {
			return;
		}
		stickies.forEach((sticky, _, arr) => {
			const { date, id } = sticky;
			const cooldownEnds =
				moment.duration(moment().diff(moment(date))).asSeconds() > COOLDOWN_SEC;
			if (cooldownEnds) {
				localStorage.setItem(
					LS_CLOSED_STICKY_TAGS,
					JSON.stringify(arr.filter((stick) => stick.id !== id))
				);
			}
		});
	}, [stickies]);
};
