import { styled, SxProps } from "@mui/material";
import axios from "axios";
import { WEB_BLOG_ROOT_URL } from "contexts/WebNewsProvider/contstants";
import { Link as RouterLink } from "react-router-dom";
import useSWR from "swr";
import { parseContent } from "..";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const PrevNextLinks = styled("div")`
	display: flex;
	justify-content: space-between;
	padding: 1rem;
`;

const StyledRouterLink = styled(RouterLink)`
	flex-basis: 30%;
	width: 33%;
`;
const IconLabel = styled("div")`
	line-height: 1;
	display: flex;
	align-items: center;
	color: gray;
`;

const PrevNextArticleLinks = ({
	postDate,
	postId,
	sx = {}
}: {
	postDate: Date;
	postId: string;
	sx?: SxProps;
}) => {
	if (!postDate || !postId) {
		return null;
	}
	const query = {
		exclude: postId,
		per_page: 1,
		orderBy: "date",
		categories: "1360",
	};
	/* eslint-disable */
	const adjacentRightQuery = new URLSearchParams({
		...query,
		order: "asc",
		after: postDate
	} as any).toString();
	const adjacentLeftQuery = new URLSearchParams({
		...query,
		order: "desc",
		before: postDate
	} as any).toString();
	/* eslint-enable */

	const { data: dataLeft } = useSWR(
		`${WEB_BLOG_ROOT_URL}wp/v2/posts?${adjacentLeftQuery}`,
		axios
	);
	const { data: dataRight } = useSWR(
		`${WEB_BLOG_ROOT_URL}wp/v2/posts?${adjacentRightQuery}`,
		axios
	);

	// console.log("adjacentRightQuery, dataRight", adjacentRightQuery, dataRight);
	// console.log("adjacentLeftQuery, dataLeft", adjacentLeftQuery, dataLeft);

	const prevPost = dataRight?.data?.[0] ?? null;
	const nextPost = dataLeft?.data?.[0] ?? null;

	const handleClick = () => {
		window && window.scrollTo(0, 0);
	};

	return (
		<PrevNextLinks
			sx={{
				justifyContent: !prevPost ? "flex-end" : undefined,
				a: {
					fontSize: { xs: "11px", sm: "inherit" },
					flexBasis: { xs: "40%", sm: "33%" }
				},
				...sx
			}}
		>
			{prevPost && (
				<StyledRouterLink to={`/news/${prevPost.slug}`} onClick={handleClick}>
					<IconLabel>
						<KeyboardDoubleArrowLeftIcon /> Previous Post
					</IconLabel>
					{parseContent(prevPost.title.rendered)}
				</StyledRouterLink>
			)}
			{nextPost && (
				<StyledRouterLink
					to={`/news/${nextPost.slug}`}
					onClick={handleClick}
					sx={{ textAlign: "right" }}
				>
					<IconLabel sx={{ justifyContent: "right" }}>
						Next Post
						<KeyboardDoubleArrowRightIcon />
					</IconLabel>
					{parseContent(nextPost.title.rendered)}
				</StyledRouterLink>
			)}
		</PrevNextLinks>
	);
};

export default PrevNextArticleLinks;
