import { SxProps, Box } from "@mui/material";
import styled from "styled-components";

export const playNowBoxSxProps: SxProps = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontWeight: "bold",
	fontSize: { xs: "3.1vw", sm: "2vw", md: "1.5vw", lg: "1.1vw" },
	span: { padding: "0 .5rem" },
	img: {
		marginBottom: "-.13em",
		height: "100%",
		width: { xs: "11vw", sm: "4vw" }
	},
	lineHeight: 3
};

export const appStoreButtonContainerSxProps: SxProps = {
	display: "flex",
	justifyContent: "center",
	flexWrap: "wrap",
	"svg, img": {
		margin: "0 1vw",
		width: { xs: "auto" },
		height: {
			xs: "3rem",
			lg: "4rem",
			xl: "3.3vw"
		}
	}
};

export const HoverFloatShadowBox = styled(Box)`
	.hvr-float-shadow {
		display: inline-block;
		vertical-align: middle;
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		position: relative;
		transition-duration: 0.3s;
		transition-property: transform;
	}
	.hvr-float-shadow:before {
		pointer-events: none;
		position: absolute;
		z-index: -1;
		content: "";
		top: 100%;
		left: 5%;
		height: 10px;
		width: 90%;
		opacity: 0;
		background: -webkit-radial-gradient(
			center,
			ellipse,
			rgba(0, 0, 0, 0.35) 0%,
			rgba(0, 0, 0, 0) 80%
		);
		background: radial-gradient(
			ellipse at center,
			rgba(0, 0, 0, 0.35) 0%,
			rgba(0, 0, 0, 0) 80%
		);
		/* W3C */
		transition-duration: 0.3s;
		transition-property: transform, opacity;
	}
	.hvr-float-shadow:hover,
	.hvr-float-shadow:focus,
	.hvr-float-shadow:active {
		transform: translateY(-5px);
		/* move the element up by 5px */
	}
	.hvr-float-shadow:hover:before,
	.hvr-float-shadow:focus:before,
	.hvr-float-shadow:active:before {
		opacity: 1;
		transform: translateY(5px);
		/* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
	}
`;

export const AppStoreButtonContainer = HoverFloatShadowBox;
