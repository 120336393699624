import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { onClosePopUp } from "redux/slices";
import { Slide1, Slide2, Slide3, Slide4, Slide5 } from "./lib";

export const Tutorial = () => {
	const dispatch = useAppDispatch();
	const [slideNumber, setSlideNumber] = useState(1);

	useEffect(() => {
		if (slideNumber === 6) {
			dispatch(onClosePopUp());
		}
	}, [slideNumber]);

	return (
		<>
			{slideNumber === 1 && (<Slide1 onNext={() => setSlideNumber(slideNumber + 1)} />)}
			{slideNumber === 2 && (<Slide2 onNext={() => setSlideNumber(slideNumber + 1)} />)}
			{slideNumber === 3 && (<Slide3 onNext={() => setSlideNumber(slideNumber + 1)} />)}
			{slideNumber === 4 && (<Slide4 onNext={() => setSlideNumber(slideNumber + 1)} />)}
			{slideNumber === 5 && (<Slide5 onNext={() => setSlideNumber(slideNumber + 1)} />)}
		</>
	)
};
