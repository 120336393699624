import styled from "styled-components";
import ThemeButton from "components/Buttons/ThemeButton";
import {
	giftGaloreTextShadow,
	tabletGiftsGaloreView,
	giftGaloreTextShadowMobile
} from "../../styles";

export const Container = styled.div`
	position: absolute;
	bottom: 1.5rem;
	left: 1rem;
	width: 70%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 1rem;
	text-align: start;

	.icon-alert {
		display: none;
	}

	@media (min-width: ${tabletGiftsGaloreView}) {
		position: unset;
		bottom: unset;
		left: unset;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: center;

		.icon-alert {
			display: flex;
		}
	}
`;
export const ProgressInfoStyled = styled.div`
	text-align: start;
	display: flex;
	gap: 6px;
	svg {
		width: 35px;
	}

	.icon-alert {
		display: none;
	}

	@media (min-width: ${tabletGiftsGaloreView}) {
		justify-content: center;
		align-items: center;
		width: 100%;
		text-align: center;

		svg {
			width: 50px;
		}

		.icon-alert {
			display: flex;
		}
	}
`;

export const Label = styled.span`
	font-family: Berlin Sans FB Demi;
	font-size: 20px;
	color: #ffe37e;
	display: block;
	text-shadow: ${giftGaloreTextShadowMobile};

	@media (min-width: ${tabletGiftsGaloreView}) {
		font-size: 22px;
		text-shadow: ${giftGaloreTextShadow};
	}
	@media (max-width: ${tabletGiftsGaloreView}) {
		font-size: 16px;
	}
`;

export const BuyButton = styled(ThemeButton)`
	padding: 0 1rem;
	@media (min-width: ${tabletGiftsGaloreView}) {
		width: 277px;
		font-size: 24px;
		height: 58px;
	}
`;
