import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebStore } from "interfaces";
import { RootState } from "redux/store";
import { PREFIX } from "./helper";
import { initialState } from "./initial-state";

export const webStoreSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		loadData: (state, action: PayloadAction<WebStore>) => {
			state.store = action.payload;
		}
	}
});

export const { loadData } = webStoreSlice.actions;

export const selectWebStoreState = (state: RootState) => state.webStore;
export default webStoreSlice.reducer;
export * from "./types";
export * from "./initializer";
