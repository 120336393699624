import { PrivateRoute } from "hoc";
import { Box } from "@mui/material";
import { FeaturesFlags } from "helpers";
import Search from "./components/Search";
import { Fragment, useEffect } from "react";
import { WinnersIcon } from "utils/svgIcons";
import PostList from "./components/PostList";
import { NewsPageContentSkeleton } from "./skeleton";
import PageTopNav from "../../components/PageTopNav";
import { useEnvContext } from "../../contexts/EnvProvider";
import { PageContent } from "../../components/PageContent";
import SectionHeader from "components/Section/SectionHeader";
import { useWebWinnersContext } from "contexts/WebWinnersProvider";
import { Background, Container, Characters } from "./styles";
import background from "assets/winners/desktop-bg.png";
import characters from "assets/winners/title-characters.png";

const Winners = () => {
	const { isFeatureFlagEnabled } = useEnvContext();
	const {
		posts,
		currentPageNum,
		setCurrentPageNum,
		totalPages,
		searchTerm,
		setSearchTerm,
		isLoading
	} = useWebWinnersContext();

	const getHeaderText = () => {
		let text = "LATEST WINNERS";
		if (searchTerm || currentPageNum || totalPages) {
			if (searchTerm || currentPageNum > 1) {
				text = "WINNERS";
			}
		}
		return text;
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<PrivateRoute isEnabled={isFeatureFlagEnabled(FeaturesFlags.WINNERS)}>
			<Fragment>
				<Container>
					<Background src={background} />
					<Characters src={characters} />
					<PageTopNav
						navItemColor="#FFE37E"
						sectionMenuData={[]}
						isLandingPage
					/>
					<Search
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						setCurrentPageNum={setCurrentPageNum}
					/>
				</Container>

				<PageContent source={"winners_list_page"} enableBackToTop>
					<Box>
						<SectionHeader text={getHeaderText()} icon={<WinnersIcon />} />
					</Box>
					{isLoading ? <NewsPageContentSkeleton /> : <PostList posts={posts} />}
				</PageContent>
			</Fragment>
		</PrivateRoute>
	);
};

export default Winners;
