/* eslint @typescript-eslint/no-empty-interface: "off" */
import axios from "axios";
import moment, { Moment } from "moment";
import createPersistedState from "use-persisted-state";
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useRef,
	useState
} from "react";
// Hooks & other Contexts:
import { useFacebookContext } from "../FacebookProvider";
import { useEnvContext } from "../EnvProvider";
import useBreakpoints from "../../hooks/useBreakpoints";
import useXSollaTokenOverrideId from "../../hooks/useXsollaTokenOverrideId";
import useWindowFocus from "use-window-focus";
// API Methods:
import {
	getBuy,
	getCheckUserEligibility,
	getGetStore,
	getHandleErrorResponse,
	getLoyaltyBuy,
	getSyncUserTracker,
	getPostUserMetadata
} from "./api";
// Types:
import {
	IAuthToken,
	IBuyProps,
	IHeaders,
	ILoginResponse,
	IPopupsProp,
	IShopFilterableItem,
	IShopSortableItem,
	IShopStore,
	IUserTracker,
	IWebStoreContext,
	IWebStoreContextApi,
	IWebStoreContextApiProps,
	IWsLoyaltyBuyProps
} from "./types";
import { WsDialogs as IDialogVariants } from "interfaces/dialog/variant";

// Debug Logger:
import {
	defaultDebugLoggerProps,
	getDebugLogger,
	IDebugLogger
} from "../../utils/debugLogger";
import { useGoogleAnalyticsContext } from "../GoogleAnalyticsProvider";
import { useWebstoreHttpService } from "../WebstoreHttpServiceProvider";
import { GSNHeader } from "../../infrastructure/HttpService/headerConstants";
import LoginService from "../../domain/Login/LoginService";
import { useScopelyIdentityContext } from "../ScopelyIdentityProvider";
import {
	AbstractLoginRequest,
	FacebookLoginRequest,
	ScopelyLoginRequest
} from "../../domain/Login/LoginRequest";
import LoggerWrapper, {
	ILogger
} from "../../infrastructure/Logging/LoggerWrapper";
import { defaultSignedInProfileIconPath } from "../../utils/svgIcons";
import { useEffectOnce } from "react-use";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
	LOGOUT_ACTION,
	LOGOUT_NOT_CONNECTED_ACTION
} from "../ScopelyIdentityProvider/api/logout";
import { analyticsTracker } from "../ScopelyIdentityProvider/config";
import { useAppDispatch } from "hooks";
import { loadWebStoreData, onDIPtrigger } from "redux/slices";
import {
	getScopelyStateFromSession,
	removeScopelyStateSession
} from "contexts/ScopelyIdentityProvider/helpers";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

import { onFocusClaim } from "redux/slices";
import { offersApi } from "redux/apis";
import { jwtDecode } from "jwt-decode";
import { ScopelyIdentityContext } from "contexts/ScopelyIdentityProvider/types";
import AutoLoginRequest from "domain/Login/LoginRequest/AutoLoginRequest";
import { LoginType } from "domain/Login/types";

const debugColor = "rgb(0, 191, 255)"; // highlight color
const debug = getDebugLogger({
	isEnabled: !false,
	color: debugColor
});

// This is a more type-safe version of the logger.
// This getDebugLogger has some really odd implementations
// like how "logEffect" and others are "Loggers" in that they are methods
// that wrap some things.
// TODO: I'd like to get away from this eventually, but for now this is a wrapper to
// Get things off of the older implementations
const debugLogger: ILogger = new LoggerWrapper(debug, "WebStoreProvider");

//----------------------------------------------------------------
// Hooks for Persistent State i.e. localStorage / sessionStorage
const useStoreRawState = createPersistedState<IShopStore | null>(
	"store.solitairetripeaks.com:storeRaw",
	sessionStorage
);
const useUserTrackerState = createPersistedState<IUserTracker | null>(
	"store.solitairetripeaks.com:userTracker"
);
const useAuthTokenState = createPersistedState<IAuthToken | null>(
	"store.solitairetripeaks.com:authToken"
);
const useServerNamespaceState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:serverNamespace"
);
const useNamespaceOverrideState = createPersistedState<
	string | null | undefined
>("store.solitairetripeaks.com:namespaceOverride");
const useDryRunOverrideState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:dryRunOverride"
);
const useProfileImageState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:profileImage"
);
const useUserNameState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:userName"
);
const useHelpshiftState = createPersistedState<object | null>(
	"store.solitairetripeaks.com:helpshift"
);
/* eslint-disable-next-line */
const useActiveItemToBeClicked = createPersistedState<any>(
	"store.solitairetripeaks.com:actionItemToBeClicked"
);
const useAutoLoginExpiredTokenState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:autoLoginExpiredToken"
);

export const LOCAL_STORAGE_LOGIN_FROM = "store.solitairetripeaks.com:loginFrom";
//----------------------------------------------------------------

export const WebStoreContext = createContext<IWebStoreContext>({
	store: null,
	authToken: null,
	userTracker: null,
	buy: async () => undefined,
	loyaltyBuy: async () => undefined,
	// eslint-disable-next-line
	wsStoreBuy: async (IWsStoreBuyProps) => undefined,
	// eslint-disable-next-line
	wsLoyaltyBuy: async (IWsLoyaltyBuyProps) => undefined,
	syncUserTracker: async () => {
		// no op
	},
	// eslint-disable-next-line
	handleErrorResponse: (response: any) => {
		// no op
	},
	serverNamespace: null,
	namespaceOverride: null,
	// eslint-disable-next-line
	setNamespaceOverride: (_namespace: string | null) => {
		// no op
	},
	dryRunOverride: null,
	// eslint-disable-next-line
	setDryRunOverride: (_dryRunOverride: string | null) => {
		// no op
	},
	openLoginToContinueDialog: () => {
		//no op
	},
	// eslint-disable-next-line
	reProcessStoreRaw: (debug?: IDebugLogger) => {
		// no op
	},
	// eslint-disable-next-line
	forceReloadStoreRaw: (debug?: IDebugLogger) => {
		// no op
	},
	// eslint-disable-next-line
	createHeaders: (headers: IHeaders): IHeaders => {
		return {};
	},
	// eslint-disable-next-line
	setAuthToken: (authToken: IAuthToken | null) => {
		// no op
	},
	fbAccessToken: null,
	logout: () => {
		// no op
	},
	profileImage: null,
	userName: null,
	setCurrentOffer: () => null,
	currentOffer: null,
	activeItemToBeClicked: null,
	setActiveItemToBeClicked: () => null,
	handleRefresh: () => null,
	setNowTime: () => {
		// no op
	},
	nowTime: null,
	networkErrorDetected: false,
	handleAdsPersonalizationChange: () => null,
	suppressOrderConfirmation: "false",
	// eslint-disable-next-line
	postUserMetadata: async (options: any) => undefined,
	immediateLogin: () => {
		// no op
	},
	isAutoLoginIn: false,
	isAutoLogedIn: false,
	autoLoginExpiredToken: null
});

const WebStoreProvider = ({ children }: { children: ReactNode }) => {
	const [searchParams] = useSearchParams();

	//----------------------------------------------------------------
	// persistent states:
	const [authToken, setAuthToken] = useAuthTokenState(null);
	const [userTracker, setUserTracker] = useUserTrackerState(null);
	const [storeRaw, setStoreRaw] = useStoreRawState(null);
	const [serverNamespace, setServerNamespace] = useServerNamespaceState(null);
	const [profileImage, setProfileImage] = useProfileImageState(null);
	const [userName, setUserName] = useUserNameState(null);
	const [helpshiftInternal, setHelpshiftInternal] = useHelpshiftState(null);
	const [isAutoLoginIn, setIsAutoLoginIn] = useState<boolean>(
		searchParams.get("loginType") === "auto" ?? false
	);
	const [isAutoLogedIn, setIsAutoLogedIn] = useState<boolean>(false);
	const [autoLoginExpiredToken, setAutoLoginExpiredToken] =
		useAutoLoginExpiredTokenState(null);
	const { setScopelyAccessToken, setScopelyIdentityToken } = useContext(
		ScopelyIdentityContext
	);

	// Note: This state is persisted, because some login methods take us away from the store site, like Scopely
	const [activeItemToBeClicked, setActiveItemToBeClicked] =
		useActiveItemToBeClicked(null);

	// useEffect(() => {
	// 	console.log("test_05", { authToken: authToken, userTracker: userTracker });
	// }, [authToken, userTracker]);

	useEffect(() => {
		if (!authToken?.value) {
			setUserTracker(null);
			setProfileImage(null);
		}
	}, [authToken]);

	//----------------------------------------------------------------
	const [nowTime, setNowTimeInternal] = useState<Moment>(moment());
	const [store, setStore] = useState<IShopStore | null>(null);
	const [namespaceOverride, setNamespaceOverrideInternal] =
		useNamespaceOverrideState(undefined);
	const [dryRunOverride, setDryRunOverrideInternal] =
		useDryRunOverrideState(null);
	const [currentOffer, setCurrentOffer] = useState<HTMLButtonElement | null>(
		null
	);
	const currentOfferClicks = useRef<number>(0);
	const [networkErrorDetected, setNetworkErrorDetected] =
		useState<boolean>(false);
	const [receivedFirstLoginReward, setReceivedFirstLoginReward] = useState<
		ILoginResponse["firstLoginReward"] | null
	>(null);

	const [showClaimRewardsUI, setShowClaimRewardsUI] = useState<boolean>(false);
	const [suppressOrderConfirmation, setSuppressOrderConfirmation] =
		useState("false");

	const isProcessStoreRawEnabled = useRef<boolean>(true);
	const {
		reportEvent,
		setTuidForAnalytics,
		syntheticId,
		loginGoogleAnalyticsHelper,
		syncRemoteAdsPersonalizationSettings,
		switchAdsPersonalizationToLogoutState,
		adsPersonalization,
		enableAdsPersonalization
	} = useGoogleAnalyticsContext();

	const isWindowFocused = useWindowFocus();
	const { backendUri } = useEnvContext();
	const { xsollaTokenOverrideId } = useXSollaTokenOverrideId();
	const { isMdDown } = useBreakpoints();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		variant: currentOpenDialogVariant,
		openDialog,
		showErrorDialog,
		showErrorDialogHelper,
		showTryReLoginDialog
	} = useDialogModalRedux();
	const {
		logout: fbLogout,
		fbAccessToken,
		userName: fbUserName
	} = useFacebookContext();

	const {
		sidAccessToken,
		sidIdentityToken,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		startLogout: startScopelyLogout,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		tryConcludeLogout: concludeScopelyLogout
	} = useScopelyIdentityContext();

	const hasSomeFormOfCredentials =
		fbAccessToken || (sidAccessToken && sidIdentityToken);

	//----------------------------------------------------------------
	// WebstoreHttpService
	const { webstoreHttpService } = useWebstoreHttpService();

	const location = useLocation();

	const setNowTime = (now: string) => {
		const newNow = moment(now);
		setNowTimeInternal(newNow);
	};

	const setNamespaceOverride = (_namespace: string | null) => {
		setNamespaceOverrideInternal(_namespace);
	};

	const setDryRunOverride = (_dryRunOverride: string | null) => {
		setDryRunOverrideInternal(_dryRunOverride);
	};

	const showClaimMyReward = async () => {
		// don't show loginReward dialog if another dialog already open

		const oneTrustIsHiden = document
			.getElementById("onetrust-pc-sdk")
			?.classList.contains("ot-hide");
		const oneTrustVisibility =
			document.getElementById("onetrust-pc-sdk")?.style.visibility;

		if (!currentOpenDialogVariant) {
			if (oneTrustIsHiden || oneTrustVisibility === "hidden") {
				reportEvent("dialog_shown", { source: "free_login_gift_dialog" });
				openDialog(IDialogVariants.CLAIM_MY_REWARD);
			}
		}
	};

	const showReceivedFirstLoginRewardConfirmation = async () => {
		reportEvent("dialog_shown", { source: "free_login_gift_received_dialog" });
		openDialog(IDialogVariants.LOGIN_REWARD_CONFIRMATION);
	};

	const openLoginToContinueDialog = async (loginFail?: boolean) => {
		if (!isAutoLoginIn || loginFail) {
			openDialog(IDialogVariants.LOGIN_TO_CONNECT);
			reportEvent("dialog_shown", {
				source: "login_to_continue_dialog"
			});
		}
	};

	const createHeaders = (headers: IHeaders = {}): IHeaders => {
		const data: IHeaders = {
			...headers
		};
		if (namespaceOverride) {
			data["X-GSN-WEBSTORE-NAMESPACE-OVERRIDE"] = namespaceOverride;
		}
		return data;
	};

	const handleAdsPersonalizationChange = () => {
		enableAdsPersonalization(!adsPersonalization, userTracker);
	};
	/**
	 * wsGetStore: wrapper for api.getStore method
	 * @param isReprocessEnabled
	 * @param debug
	 * @param forceReload
	 * @returns void
	 */
	const wsGetStore = async (
		isReprocessEnabled: boolean | undefined,
		debug?: IDebugLogger,
		forceReload?: boolean
	) => {
		try {
			const data = await api.getStore(
				storeRaw,
				typeof isReprocessEnabled !== "undefined"
					? isReprocessEnabled
					: isProcessStoreRawEnabled?.current,
				debug,
				forceReload,
				setNowTime,
				authToken
			);
			if (data === null || data === undefined) {
				return;
			}
			setStoreRaw(data);
			if (data.serverNamespace) {
				setServerNamespace(data.serverNamespace);
			}
			const deepCloneData = JSON.parse(JSON.stringify(data));

			if (authToken?.value) {
				const userTrackerResponse = await getUserTracker();
				if (userTrackerResponse && userTrackerResponse.userTracker) {
					processStoreRaw(deepCloneData, userTrackerResponse.userTracker);
				} else {
					processStoreRaw(deepCloneData, null);
				}
			} else {
				processStoreRaw(deepCloneData, null);
			}
		} catch (err) {
			console.error(err);
		}
	};

	/* eslint-disable-next-line */
	const wsCheckUserEligibility = (type: string, params: any) => {
		const isUserEligible = api.checkUserEligibility(type, params);
		return isUserEligible;
	};

	/**
	 * wsStoreBuy: wrapper for api.buy method
	 * - TODO: can be used for extra inputs needed in purchase flow modals
	 * @param offerId: string
	 * @param authToken: IAuthToken | null | undefined
	 * @returns IAxiosResponse
	 */

	const wsStoreBuy = async (props: IBuyProps) => {
		const res = await api.buy(props);
		debug.logFunction({ iconText: "wsStoreBuy:", message: [res] });
		return res;
	};
	const autoWsStoreBuy = async ({
		offerId,
		purchaseLimit,
		onlyNonPayers
	}: {
		offerId: string;
		purchaseLimit?: number;
		onlyNonPayers?: boolean;
	}) => {
		if (!isWindowFocused) {
			return;
		}
		if (authToken?.value) {
			if (
				!wsCheckUserEligibility("store_buy", {
					offerId,
					purchaseLimit,
					onlyNonPayers
				})
			) {
				return;
			}
			const invoice = await wsStoreBuy({ offerId });
			if (invoice) {
				openDialog(IDialogVariants.XSOLLA_PURCHASE, {
					xsollaToken: invoice.xsollaToken,
					xsollaHost: invoice.xsollaHost,
					cbClose: () => {
						dispatch(offersApi.util.invalidateTags(["offersTag"]));
						setCurrentOffer(null);
						reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
						handleRefresh();
					}
				});
			} else {
				setCurrentOffer(null);
			}

			//NOTE:bfloyd error cases already handled for undefined/null invoice
		} else {
			openLoginToContinueDialog();
		}
	};
	/**
	 * wsLoyaltyBuy: wrapper for api.loyaltyBuy method
	 * @param offerId
	 * - TODO: can be used for extra inputs needed in purchase flow modals
	 * @param offerId: string
	 * @returns IAxiosResponse
	 */
	/* eslint-disable-next-line */
	const wsLoyaltyBuy = async (offerId: string): Promise<any | null> => {
		const res = await api.loyaltyBuy(offerId);
		debug.logFunction({ iconText: "wsLoyaltyBuy:", message: [res] });
		return res;
	};

	/* eslint-disable */
	const autoWsLoyaltyBuy = async ({
		resetCurrentOffer,
		offerId,
		description,
		price,
		rewardImageUrl
	}: IWsLoyaltyBuyProps): Promise<any | null> => {
		if (!isWindowFocused) {
			return;
		}
		if (
			// intercept insufficient points before making request
			typeof userTracker?.loyaltyPoints !== "undefined" &&
			userTracker.loyaltyPoints < price
		) {
			openDialog(IDialogVariants.INSUFFICIENT_LOYALTY_POINT_BALANCE, {
				cbClose: resetCurrentOffer
			});
			reportEvent("dialog_shown", { source: "insufficient_funds_dialog" });
		} else {
			openDialog(IDialogVariants.LOYALTY_PURCHASE_CONFIRM, {
				offerId,
				itemDescription: description,
				itemPrice: price,
				itemImageUrl: rewardImageUrl,
				cbClose: resetCurrentOffer
			});
			reportEvent("dialog_shown", {
				source: "loyalty_purchase_confirm_dialog"
			});
		}
	};
	/* eslint-enable */

	const processStoreRaw = (
		data: IShopStore | null,
		freshUserTracker: IUserTracker | null
	) => {
		if (!data) {
			return;
		}
		if (!freshUserTracker) {
			freshUserTracker = userTracker;
		}
		const nowTimeFromResponse = moment(data.nowTime);
		const sortPriority = (a: IShopSortableItem, b: IShopSortableItem) =>
			a.priority - b.priority;

		const filterDate = (offer: IShopFilterableItem) => {
			const isInRange =
				(!offer.startTime ||
					moment(offer.startTime).isBefore(nowTimeFromResponse)) &&
				(!offer.endTime || moment(offer.endTime).isAfter(nowTimeFromResponse));
			return isInRange;
		};
		// filter by date & sort by priority
		data.mainShopSections = data.mainShopSections
			.filter(filterDate)
			.sort(sortPriority);
		data.loyaltyShopSections = data.loyaltyShopSections
			.filter(filterDate)
			.sort(sortPriority);
		// filter only by date
		data.firstLoginRewards = data.firstLoginRewards.filter(filterDate);

		setStore(data);
		dispatch(loadWebStoreData(data));

		isProcessStoreRawEnabled.current = false;
	};

	const logout = async (isAccountNotConnectedLogout = false) => {
		const scopelyLogoutAction = isAccountNotConnectedLogout
			? LOGOUT_NOT_CONNECTED_ACTION
			: LOGOUT_ACTION;

		const wasScopelyLogin =
			sidIdentityToken !== null && sidAccessToken !== null;

		debug.logFunction({
			iconText: "WebStoreProvider -> logout:",
			message: [
				`
		await fbLogout()
		setFbAccessToken(null);
		setAuthToken(null);
		setProfileImage(null);
		setUserName(null);
		setUserTracker(null);
		setUserName(null);
		setTuidForAnalytics(null);
		setIsAutoLogedIn(false);
		sessionStorage.clear();
		localStorage.removeItem("store.solitairetripeaks.com:authToken");
		localStorage.removeItem("store.solitairetripeaks.com:profileImage");
		localStorage.removeItem("store.solitairetripeaks.com:userName");
		localStorage.removeItem("store.solitairetripeaks.com:userTracker");
		await startScopelyLogout(${scopelyLogoutAction});
		wasScopelyLogin?: ${wasScopelyLogin};
				`
			]
		});

		loginGoogleAnalyticsHelper.reportLogout();

		setAuthToken(null);
		setProfileImage(null);
		setUserName(null);
		setUserTracker(null);
		setTuidForAnalytics(null);
		sessionStorage.clear();
		setActiveItemToBeClicked(null);
		setHelpshiftData(null);
		setIsAutoLogedIn(false);
		// TODO: How to reset tenant correctly
		analyticsTracker.getContext().setTenant("");
		analyticsTracker.updateAppProperty("sys.user_id", "");
		switchAdsPersonalizationToLogoutState();
		// If logged in on scopely, will redirect to scopely endpoint for logout, otherwise will stay on current page

		/* START - SCOPELYID TEMPORAL FIX */
		//console.log("test_05_IdentityToken", sidIdentityToken);
		//console.log("test_05_wasScopelyLogin", wasScopelyLogin);
		if (wasScopelyLogin) {
			try {
				const currentDate = new Date();
				// const mockDate = new Date();
				// mockDate.setDate(mockDate.getDate() - 1);

				const decoded = jwtDecode(sidIdentityToken);
				const expirationDate = decoded?.exp
					? new Date(decoded.exp * 1000)
					: null;

				//console.log("test_05_decodedToken", decoded);
				//console.log("test_05_expirationDate", expirationDate);

				//if (mockDate < currentDate) {
				if (!expirationDate || expirationDate < currentDate) {
					localStorage.removeItem(
						"store.solitairetripeaks.com:sidIdentityToken"
					);
					clearScopelyState();
				} else {
					await startScopelyLogout(scopelyLogoutAction, location.pathname);
					if (!isAccountNotConnectedLogout) {
						await concludeScopelyLogout();
					}
				}
			} catch (error) {
				clearScopelyState();
			}
		} else {
			await fbLogout();
		}
		/* END - SCOPELYID TEMPORAL FIX */

		localStorage.removeItem("store.solitairetripeaks.com:authToken");
		localStorage.removeItem("store.solitairetripeaks.com:profileImage");
		localStorage.removeItem("store.solitairetripeaks.com:userName");
		localStorage.removeItem("store.solitairetripeaks.com:userTracker");

		if (!isAccountNotConnectedLogout) {
			removeScopelyStateSession();
			forceReloadStoreRaw();
		}
	};

	const immediateLogin = async (token: string) => {
		setIsAutoLoginIn(true);
		setAutoLoginExpiredToken(token);
		let loginSuccess = true;
		let loginRequest: AbstractLoginRequest | null = null;
		loginRequest = new AutoLoginRequest("auto", token);

		if (!loginRequest) {
			return;
		}

		await setProfileImage(null);
		fbAccessToken && (await fbLogout());
		sidAccessToken && sidIdentityToken && (await clearScopelyState());

		await loginService
			.login(loginRequest)
			.then((loginResponse: ILoginResponse) => {
				if (!loginResponse?.success) {
					loginSuccess = false;
					debugLogger.logWithTitle("autoLogin", [
						"failed:",
						loginResponse?.success
					]);
					reportEvent("immediate_login_error", {
						error_type: loginResponse.errorMessage
					});
					return;
				}

				//SESSION START FOR NEW LOGIN
				reportEvent("session_start", {
					page_location: location.pathname
				});
				reportEvent("immediate_login", {
					source: "received",
					tuid: loginResponse.userTracker.id
				});
				loginGoogleAnalyticsHelper.reportLoginOptionLoginSuccess(
					LoginType.Auto
				);
				setIsAutoLogedIn(true);
			})
			.catch((error) => {
				loginSuccess = false;
				debugLogger.logWithTitle("autoLogin", ["failed:", error]);
				reportEvent("immediate_login_error", {
					error_type: error
				});
			})
			.finally(async () => {
				navigate(location.pathname);
				setIsAutoLoginIn(false);
				!loginSuccess && (await logout());
				!loginSuccess && openLoginToContinueDialog(!loginSuccess);
			});
	};

	const clearScopelyState = () => {
		localStorage.removeItem("store.solitairetripeaks.com:sidAccessToken");
		localStorage.removeItem("com.scopely:scopelyid:idToken");
		removeScopelyStateSession();
		setScopelyAccessToken(null);
		setScopelyIdentityToken(null);
	};

	// TODO: usually works but sometimes doesn't remove expired offer,
	const reProcessStoreRaw = (
		debug: IDebugLogger = defaultDebugLoggerProps
	): void => {
		debug.log({
			iconText: "reProcessStoreRaw:",
			message: ["autoStore( isProcessEnabled = true ))"]
		});
		isProcessStoreRawEnabled.current = true;
		wsGetStore(true, debug);
	};

	const forceReloadStoreRaw = (
		debug: IDebugLogger = defaultDebugLoggerProps
	): void => {
		debug.log({
			iconText: "forceReloadStoreRaw:",
			message: ["autoStore( isProcessEnabled = true ))"]
		});
		isProcessStoreRawEnabled.current = true;
		wsGetStore(true, debug, true);
	};

	const setHelpshiftData = (helpshiftData: object | null): void => {
		setHelpshiftInternal(helpshiftData);
	};

	const handleErrorResponse = getHandleErrorResponse({
		logout,
		showErrorDialog,
		backendUri: backendUri || "",
		openDialog,
		reportEvent,
		setNetworkErrorDetected,
		debug,
		showTryReLoginDialog
	});

	const popups: IPopupsProp = {
		showErrorDialog,
		showErrorDialogHelper,
		openDialog,
		handleErrorResponse
	};

	// New Login Service, same behavior, abstracted out to be FB independent
	// This should eventually replace IWebstoreContextApi::login
	const loginService: LoginService = new LoginService(
		webstoreHttpService,
		loginGoogleAnalyticsHelper,
		{
			onLoginSuccess: (res: ILoginResponse) => {
				res.authToken && setAuthToken(res.authToken);
				res.userTracker && setUserTracker(res.userTracker);
				res.userTracker.name && setUserName(res.userTracker.name);
				dispatch(onDIPtrigger("loginSuccess"));

				res.firstLoginReward &&
					setReceivedFirstLoginReward(res.firstLoginReward);

				if (res.userTracker && res.helpshiftToken) {
					setHelpshiftData({
						userId: res.userTracker.id,
						userAuthToken: res.helpshiftToken
					});
				}

				const profilePic = res.profilePictureUrl
					? res.profilePictureUrl + "?timestamp=" + new Date().getTime()
					: defaultSignedInProfileIconPath;
				setProfileImage(profilePic);

				analyticsTracker.getContext().setTenant(res.userTracker.id);
				analyticsTracker.updateAppProperty("sys.user_id", res.userTracker.id);
				syncRemoteAdsPersonalizationSettings(res.userTracker);

				debugLogger.logWithTitle("login", [
					"success:",
					res?.success,
					"\n Setting Data From Login Response:\n ",
					[res]
				]);
			},
			onLoginFail: () => {
				logout();
			},
			onLoginError: (err: { response: { status: number; data: object } }) => {
				const {
					response: { status, data }
				} = err;
				console.error(err, status, data);
				const wasScopelyLogin =
					sidIdentityToken !== null && sidAccessToken !== null;
				logout(status === 401);
				if (status === 401) {
					// Skip dialog for scopely, the page is about to redirect to their logout page.
					if (!wasScopelyLogin) {
						openDialog(IDialogVariants.ACCOUNT_NOT_CONNECTED);
						removeScopelyStateSession();
					}
					reportEvent("dialog_shown", {
						source: "account_not_connected_dialog"
					});
					return;
				}
				popups.handleErrorResponse(err);
			}
		}
	);

	const apiProps: IWebStoreContextApiProps = {
		axios,
		authToken,
		backendUri: backendUri || "",
		dryRunOverride,
		popups,
		createHeaders,
		reportEvent,
		debug,
		syntheticId,
		loginService
	};

	const api: IWebStoreContextApi = {
		getStore: getGetStore({
			...apiProps,
			backendUri: backendUri || "",
			logout,
			setNowTime,
			authToken
		}),
		syncUserTracker: getSyncUserTracker({
			...apiProps,
			setUserTracker,
			logout,
			setNowTime,
			setNetworkErrorDetected,
			setUserName,
			profileImage,
			setProfileImage,
			isAutoLoginIn
		}),
		postUserMetadata: getPostUserMetadata({
			...apiProps,
			setUserTracker,
			logout,
			userTracker,
			setNowTime,
			setNetworkErrorDetected
		}),
		buy: getBuy({
			...apiProps,
			isMdDown,
			xsollaTokenOverrideId,
			logout
		}),
		loyaltyBuy: getLoyaltyBuy({ ...apiProps, logout, setUserTracker }),
		logout,
		checkUserEligibility: getCheckUserEligibility({ ...apiProps, userTracker })
	};

	const handleRefresh = async () => {
		await forceReloadStoreRaw();
		if (authToken?.value) {
			await getUserTracker();
		}
	};

	const getUserTracker = async () => {
		return await api.syncUserTracker();
	};

	/**
	 * autoOpenAccountNotConnectedOnScopelyReturn
	 * - Scopely Login redirects us off the page and back with a state param, in this case we logged out after
	 * failing to find an account. So we need to catch the redirect and display the account not connected dialog
	 */
	useEffectOnce(() => {
		const searchParamState = searchParams.get("state");
		let stateObj = getScopelyStateFromSession();
		stateObj = stateObj && JSON.parse(stateObj);

		if (stateObj?.action === LOGOUT_NOT_CONNECTED_ACTION) {
			if (authToken === null) {
				openDialog(IDialogVariants.ACCOUNT_NOT_CONNECTED);
				removeScopelyStateSession();
			}
			// In a second, push a new url into the window history. This will make it so that
			// a refresh wont have the same param that causes this to happen
			setTimeout(() => {
				const newUrl = document.location.href.replace(
					`state=${searchParamState}`,
					""
				);

				window.history.pushState(
					{ additionalInformation: "Removed state param" },
					"",
					newUrl
				);
			}, 1000);
		}

		//SESSION START FOR PAGE LOAD
		reportEvent("session_start", {
			page_location: location.pathname
		});

		dispatch(onFocusClaim());
		//
	});

	/**
	 * autoUpdateWebStoreHttpService:
	 * - Update settings and default headers for the http service when relevant data changes
	 */
	useEffect(() => {
		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: [
				"\nuseEffect( webStoreHttpServiceUpdate, [webstoreHttpService, authToken, namespaceOverride]",
				[webstoreHttpService, authToken, namespaceOverride]
			]
		});

		webstoreHttpService
			?.setDefaultHeader(GSNHeader.NamespaceOverride, namespaceOverride || null)
			.setAuthHeader(authToken?.value ? `Bearer ${authToken?.value}` : null);
	}, [webstoreHttpService, authToken, namespaceOverride]);

	/**
	 * autoUpdateHelpshiftConfig
	 */
	useEffect(() => {
		/* eslint-disable-next-line */
		const myWindow = window as any;
		if (myWindow.helpshiftConfig) {
			const helpshiftData: { userId?: string; userAuthToken?: string } | null =
				helpshiftInternal;
			debug.logUseEffect({
				iconText: "WebStoreProvider",
				message: [
					"\nuseEffect( helpShiftInit, [helpshiftData]",
					[helpshiftData]
				]
			});
			if (helpshiftData == null) {
				myWindow.helpshiftConfig.userId = undefined;
				myWindow.helpshiftConfig.userAuthToken = undefined;
				myWindow.Helpshift("updateHelpshiftConfig");
			} else {
				myWindow.helpshiftConfig.userId = helpshiftData.userId;
				myWindow.helpshiftConfig.userAuthToken = helpshiftData.userAuthToken;
				myWindow.Helpshift("updateHelpshiftConfig");
			}
		}
	}, [helpshiftInternal]);

	/**
	 * autoLoginReward:
	 * - initial dialog (on page load)
	 */
	useEffect(() => {
		if (!store || authToken?.value) {
			return;
		}
		// TP-24905 fix for the case when we are redirected back from Scopely login with a state param
		const searchParamState = searchParams.get("state");
		if (searchParamState) {
			return;
		}

		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: ["\nuseEffect( autoLoginReward, [store]", [store]]
		});

		setTimeout(() => {
			setShowClaimRewardsUI(true);
		}, 2000);
	}, [store]);

	useEffect(() => {
		if (
			showClaimRewardsUI &&
			store &&
			!authToken?.value &&
			!hasSomeFormOfCredentials
		) {
			debug.log({
				iconText: "autoLoginReward:",
				message: ["📌 show claimMyReward dialog"]
			});
			showClaimMyReward();
		}
	}, [showClaimRewardsUI]);

	/**
	 * autoStore:
	 * - after namespaceOverride change
	 */
	useEffect(() => {
		// const debug = getDebugLogger({
		// 	isEnabled: true,
		// 	color: debugColor
		// });
		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: [
				"\nuseEffect( autoStore, [namespaceOverride])",
				[namespaceOverride]
			]
		});
		debug.log({
			iconText: "autoStore:", //▶
			message: [`${new Date().toLocaleString()}`]
		});
		wsGetStore(true, debug);
	}, [namespaceOverride]);

	/**
	 * autoStoreOnRefocus:
	 * - when isWindowFocused true
	 * NOTE: we don't want to run on `storeRaw` change to avoid infinite loop
	 */
	useEffect(() => {
		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: [
				"\nuseEffect( autoStoreOnRefocus, [isWindowFocused]",
				[isWindowFocused]
			]
		});
		const autoStoreOnRefocus = async () => {
			if (isWindowFocused) {
				debug.log({
					iconText: "autoStoreOnRefocus:", //▶
					message: [`${new Date().toLocaleString()}`]
				});

				// TODO: reset current offer if user closes FB login popup/window without logging in
				setTimeout(() => {
					if (currentOffer && !authToken) {
						setCurrentOffer(null);
					}
				}, 3000);

				// sync user tracker so processStoreRaw can filter out purchases with limits

				if (authToken?.value) {
					await getUserTracker();
				}
				wsGetStore(undefined, debug);
				if (isProcessStoreRawEnabled.current) {
					isProcessStoreRawEnabled.current = false;
				}
			} else {
				isProcessStoreRawEnabled.current = true;
			}
			debug.log({
				iconText: "autoStoreOnRefocus:",
				message: [
					"isProcessStoreRawEnabled.current",
					isProcessStoreRawEnabled.current
				]
			});
		};
		autoStoreOnRefocus();
	}, [isWindowFocused]);

	/**
	 * autoRefresh:
	 * - when window focused or storeRaw change.
	 */
	useEffect(() => {
		// const debug = getDebugLogger({
		// 	isEnabled: true,
		// 	color: debugColor
		// });
		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: [
				"\nuseEffect( autoRefresh, [isWindowFocused, storeRaw]",
				[isWindowFocused, storeRaw]
			]
		});

		const autoRefresh = () => {
			const refreshIntervalSeconds = 600000;
			return setInterval(() => {
				debug.log({
					icon: "⮑ autoRefresh:", //▶
					message: [`${new Date().toLocaleString()}`]
				});
				wsGetStore(undefined, debug);
			}, refreshIntervalSeconds);
		};
		const refreshIntervalId = isWindowFocused && store && autoRefresh();
		debug.log({
			icon: "⏳ autoRefresh:",
			message: [refreshIntervalId ? "POLLING..." : "OFF"]
		});

		return () => {
			refreshIntervalId && clearInterval(refreshIntervalId);
		};
	}, [isWindowFocused, storeRaw]);

	/**
	 * autoProcessStoreOnUserUpdate:
	 * - when syncUserTracker updates useTracker
	 */
	useEffect(() => {
		const autoProcessStoreOnUserUpdate = () => {
			if (userTracker) {
				const deepCloneData = JSON.parse(JSON.stringify(storeRaw));
				processStoreRaw(deepCloneData, null);
			}
		};
		autoProcessStoreOnUserUpdate();
	}, [userTracker]);

	/**
	 * autoLogin:
	 * - once authToken has been set + userTracker or fbAccessToken
	 */
	useEffect(() => {
		debugLogger.logEffect(
			"autoLogin",
			"[fbAccessToken, sidAccessToken, sidIdentityToken, authToken]",
			[fbAccessToken, sidAccessToken, sidIdentityToken, authToken]
		);
		const hasCredentials =
			fbAccessToken !== null ||
			(sidAccessToken !== null && sidIdentityToken !== null);

		// If we do not have any credentials, and there is an auth token and it is expired, logout.
		if (!hasCredentials && authToken && authToken?.value) {
			const timeTillExpiration = moment.utc(authToken.expiresAt).diff(moment());
			if (timeTillExpiration < 0) {
				debugLogger.logWithTitle(
					"autoLogin",
					"authToken expired.. logging out!!!"
				);
				logout();
				return;
			}
		}

		// If we have credentials for an auth token but not token...
		//If we are loggin out then skip the auto login

		const searchParamState = searchParams.get("state");
		let stateObj = getScopelyStateFromSession();
		stateObj = stateObj && JSON.parse(stateObj);
		stateObj = stateObj || { action: searchParamState };

		debugLogger.logWithTitle(
			"autoLogin",
			"stateObj?.action " + stateObj?.action
		);

		if (authToken === null && hasCredentials && stateObj?.action !== "logout") {
			let loginRequest: AbstractLoginRequest | null = null;
			if (fbAccessToken !== null) {
				loginRequest = new FacebookLoginRequest(fbAccessToken);
			} else if (sidAccessToken !== null && sidIdentityToken !== null) {
				loginRequest = new ScopelyLoginRequest(
					sidIdentityToken,
					sidAccessToken
				);
			}

			if (!loginRequest) {
				return;
			}

			loginService.login(loginRequest).then((loginResponse: ILoginResponse) => {
				//SESSION START FOR NEW LOGIN
				reportEvent("session_start", {
					page_location: location.pathname
				});

				dispatch(onFocusClaim());
				//

				if (!loginResponse?.success) {
					debugLogger.logWithTitle("autoLogin", [
						"failed:",
						loginResponse?.success,
						"skip..."
					]);
					return;
				}
			});
		}
	}, [fbAccessToken, sidAccessToken, sidIdentityToken, authToken]);

	/**
	 * autoUser:
	 * - when authToken changes
	 * - required for updating userTracker on page load (when login response persisted)
	 */
	useEffect(() => {
		if (!authToken?.value) {
			return;
		}
		// const debug = getDebugLogger({
		// 	isEnabled: true,
		// 	color: debugColor
		// });
		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: ["\nuseEffect( autoUser, [authToken])", [authToken]]
		});

		const autoUser = async () => {
			if (authToken?.value) {
				debug.log({
					iconText: "autoUser:",
					message: [
						"!!authToken.value:",
						!!authToken?.value,
						"api.syncUserTracker()"
					]
				});
				await getUserTracker();
			}
		};
		autoUser();
	}, [authToken]);

	/**
	 * autoUserName:
	 * - persist userName once retrieved
	 */
	useEffect(() => {
		if (!fbUserName || !authToken) {
			return;
		}

		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: ["\nuseEffect( autoUserName, [fbUserName])", [fbUserName]]
		});

		if (fbUserName) {
			debug.log({
				iconText: "autoUserName:",
				message: ["setUserName:", fbUserName]
			});
			setUserName(fbUserName);
		}
	}, [fbUserName, authToken]);

	/**
	 * autoOpenCurrentOffer:
	 * - when non-auth'd user clicks an offer and is prompted to login
	 * - open previous clicked offer once authenticated
	 */
	useEffect(() => {
		debug.logUseEffect({
			iconText: "WebStoreProvider",
			message: [
				"\nuseEffect( autoOpenCurrentOffer, [userTracker, currentOffer, activeItemToBeClicked]",
				[userTracker, currentOffer, activeItemToBeClicked]
			]
		});

		if (!userTracker || !activeItemToBeClicked) {
			return;
		}

		const autoOpenCurrentOffer = () => {
			if (activeItemToBeClicked) {
				debug.log({
					iconText: "activeItemToBeClicked:",
					message: [activeItemToBeClicked]
				});
				switch (activeItemToBeClicked.type) {
					case "store_offer":
						autoWsStoreBuy(activeItemToBeClicked);
						break;
					case "loyalty_offer":
						autoWsLoyaltyBuy(activeItemToBeClicked);
						break;
				}
				setActiveItemToBeClicked(null);
				return;
			}
			//----------------------------------------------------------------
		};
		autoOpenCurrentOffer();
	}, [userTracker, currentOffer, activeItemToBeClicked]);

	/**
	 * autoResetCurrentOfferClicks:
	 * - reset the click counter when currentOffer removed
	 */
	useEffect(() => {
		const autoResetCurrentOfferClicks = () => {
			if (!currentOffer) {
				currentOfferClicks.current = 0;
			}
		};
		autoResetCurrentOfferClicks();
	}, [currentOffer]);

	/**
	 * autoNow:
	 * - when nowTime changes start counting from nowTime
	 */
	useEffect(() => {
		const autoNow = () => {
			return setInterval(() => {
				setNowTimeInternal(nowTime.add(1, "second"));
			}, 1000);
		};
		const intervalId = autoNow();
		return () => {
			clearInterval(intervalId);
		};
	}, [nowTime]);

	/**
	 * autoReceivedFirstLoginReward:
	 * - when user receives first login reward do something
	 */
	useEffect(() => {
		const autoReceivedFirstLoginReward = () => {
			if (receivedFirstLoginReward) {
				debugLogger.logEffect(
					"autoReceivedFirstLoginReward",
					"[receivedFirstLoginReward]",
					[receivedFirstLoginReward]
				);
				debugLogger.logWithTitle(
					"autoReceivedFirstLoginReward",
					"Received first loginReward!"
				);

				showReceivedFirstLoginRewardConfirmation();
			}
		};
		autoReceivedFirstLoginReward();
	}, [receivedFirstLoginReward]);

	/**
	 * autoUserTrackerMeta
	 */
	useEffect(() => {
		const autoUserTrackerMeta = () => {
			if (userTracker?.metadata?.suppressOrderConfirmation) {
				setSuppressOrderConfirmation(
					userTracker.metadata.suppressOrderConfirmation
				);
			}
		};
		autoUserTrackerMeta();
	}, [userTracker]);

	useEffect(() => {
		if (authToken?.value && userTracker?.id) {
			//console.log("test_05", "Get Tracker - Login");
			getUserTracker();
		}

		//console.log("test_05", "Init interval - Polling");
		const intervalId = setInterval(async () => {
			debug.log({
				icon: "User Tracker Polling:", //▶
				message: [`${new Date().toLocaleString()}`, userTracker]
			});
			if (authToken?.value) {
				//console.log("test_05", "Get Tracker - Polling");
				await getUserTracker();
			}
		}, 30000);
		return () => {
			//console.log("test_05", "Clear Interval - Polling");
			clearInterval(intervalId);
		};
	}, [authToken?.value, userTracker?.id]);

	return (
		<WebStoreContext.Provider
			value={{
				store,
				authToken,
				userTracker,
				buy: api.buy,
				loyaltyBuy: api.loyaltyBuy,
				wsStoreBuy,
				wsLoyaltyBuy,
				syncUserTracker: api.syncUserTracker,
				handleErrorResponse,
				serverNamespace,
				namespaceOverride,
				setNamespaceOverride,
				dryRunOverride,
				setDryRunOverride,
				openLoginToContinueDialog: openLoginToContinueDialog,
				reProcessStoreRaw,
				forceReloadStoreRaw,
				createHeaders,
				setAuthToken,
				fbAccessToken,
				logout,
				profileImage,
				userName,
				setCurrentOffer,
				currentOffer,
				activeItemToBeClicked,
				setActiveItemToBeClicked,
				handleRefresh,
				setNowTime,
				nowTime,
				networkErrorDetected,
				handleAdsPersonalizationChange,
				suppressOrderConfirmation,
				postUserMetadata: api.postUserMetadata,
				immediateLogin,
				isAutoLoginIn,
				isAutoLogedIn,
				autoLoginExpiredToken
			}}
		>
			{children}
		</WebStoreContext.Provider>
	);
};

const useWebStoreContext = () => {
	const context = useContext(WebStoreContext);
	if (context === undefined) {
		throw new Error("useWebStoreContext was used outside of its Provider");
	}
	return context;
};

export default WebStoreProvider;
export { useWebStoreContext };
