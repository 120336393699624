import styled from "styled-components";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import bg from "assets/faq/bg_v2.png";
import bgMobile from "assets/faq/bg-mobile.png";
//import brush from "assets/faq/brush.png";
import brushMobile from "assets/faq/brush-mobile.png";

export const CustomAccordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
	border: "0.5px solid #EBEBEB",
	// "&:not(:last-child)": {
	// 	borderBottom: 0
	// },
	"&:before": {
		height: "0px"
	}
}));

export const Container = styled.div`
	position: relative;
	background-image: url(${bgMobile});
	background-repeat: no-repeat;
	background-size: 100%;
	width: 100%;
	min-height: 425px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
	border-radius: 12px;
	overflow: hidden;

	@media (orientation: landscape) {
		background-size: auto;
		background-position: top;
		background-image: url(${bg});
		min-height: 400px;
		overflow: visible;
	}
`;

export const ItemsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 95%;
	max-width: 580px;
	@media (orientation: landscape) {
		width: 60%;
		max-width: 580px;
	}
`;

export const BgBrush = styled.img`
	content: url(${brushMobile});
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	//height: 100%;
	@media (orientation: landscape) {
		content: none;
	}
`;

export const Character = styled.img`
	display: none;
	position: absolute;
	@media (orientation: landscape) {
		width: 185px;
		display: unset;
	}
`;

export const Title = styled.div`
	color: #113b62;
	z-index: 1;
	margin-top: 55px;
	text-align: center;
	font-family: Baloo;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	//line-height: 29px;
`;

export const DotIcon = styled.span`
	font-size: 1rem;
	font-weight: 1000;
	color: #333333;
	line-height: 1.7;

	&::before {
		content: "•";
	}
`;
