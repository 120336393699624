import { Moment } from "moment";
import { styled } from "@mui/material";
import { useState, useEffect } from "react";
import { getCount, getDisplay } from "helpers";
import { CSSProperties } from "styled-components";
import { getDebugLogger } from "../../utils/debugLogger";

const debug = getDebugLogger({
	isEnabled: true
});

const WrapperSpan = styled("span")`
	margin-left: 0.2rem;
`;

const TimerDisplayV2 = ({
	startTime,
	endTime,
	onEnd,
	status,
	style
}: {
	style?: CSSProperties;
	startTime: Moment | null;
	endTime: string;
	onEnd: () => void;
	status?: string;
}) => {
	const count = getCount(startTime, endTime);
	const [display, setDisplay] = useState<string>(getDisplay(count));

	useEffect(() => {
		setDisplay(getDisplay(count));
	}, [status]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			const newCount = getCount(startTime, endTime);
			if (newCount.totalSeconds <= 0) {
				debug.logUseEffect({
					iconText: "TimerDisplay",
					message: ["\nuseEffect( countdown_ended, []", []]
				});
				onEnd();
				clearInterval(intervalId);
			}
			setDisplay(getDisplay(newCount));
		}, 1000);
		return () => clearInterval(intervalId);
	}, [status, startTime, endTime]);

	return (
		<WrapperSpan style={style} data-testid="timerDisplay">
			{display}
		</WrapperSpan>
	);
};

export default TimerDisplayV2;
