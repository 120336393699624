import { Grid } from "@mui/material";
import { useAppDispatch, useImagePath } from "hooks";
import headerImg from "assets/daily-gift/bg-popup.png";
import { onClosePopUp, onDIPtrigger } from "redux/slices";
import { Body, Button, Container, Header, Title } from "./styles";
import { useWebStoreContext } from "contexts";

interface DailyRewardEarnedProps {}

export const DailyRewardEarned: React.FC<DailyRewardEarnedProps> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const headImageUrl = getImage(headerImg);
	const { syncUserTracker } = useWebStoreContext();

	return (
		<Container>
			<Header data-image={headImageUrl}></Header>
			<Body>
				<Title>Your Free Daily Gift was sent to your inbox in-game!</Title>
				<Grid container spacing={2}>
					<Grid
						item
						xs={12}
						//sm={isMobile ? 6 : 12}
						display="flex"
						justifyContent="center"
					>
						<Button
							variant="contained"
							themeColor="primary"
							sx={{ minWidth: { xs: "80%", /* sm: isMobile ? "100%" : "80%" */ } }}
							onClick={() => {
								dispatch(onDIPtrigger("collectDailyGift"));
								syncUserTracker();
								dispatch(onClosePopUp());
							}}
						>
							Continue Shopping
						</Button>
					</Grid>
					{/* {isMobile && (
						<Grid item xs={12} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="daily_reward_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "80%", sm: "100%" } }}
							/>
						</Grid>
					)} */}
				</Grid>
			</Body>
		</Container>
	);
};
