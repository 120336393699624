import { useEffect, useState } from "react";
import { getActiveSaleTags } from "./helper";
import { useAppDispatch, useAppSelector } from "hooks";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import {
	setActiveTags,
	hideHamburgerDot,
	showHamburgerDot,
	selectStickySaleTagState
} from "redux/slices";
import { useEnvContext } from "contexts/EnvProvider";
import { FeaturesFlags } from "helpers";

export const useSetActiveSaleTag = (): void => {
	const dispatch = useAppDispatch();
	const { nowTime, userTracker } = useWebStoreContext();
	const [showed, setShowed] = useState<boolean>();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { saleTags } = useAppSelector(selectStickySaleTagState);
	const activeTags = getActiveSaleTags(saleTags, userTracker, nowTime);
	if (!isFeatureFlagEnabled(FeaturesFlags.STICKY_TAG)) {
		return;
	}

	useEffect(() => {
		// @ts-expect-error
		if (typeof nowTime._tzm === "undefined") {
			return;
		}
		if (!activeTags.length) {
			dispatch(hideHamburgerDot());
			return;
		}

		dispatch(setActiveTags(activeTags));
	}, [saleTags, nowTime]);

	useEffect(() => {
		if (showed) {
			return;
		}
		for (const tag of activeTags) {
			if (tag.showMegaMenuTag) {
				switch (tag.megaMenuTagSection) {
					case "Club Free Gift":
						if (!isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP_FREE_GIFT)) {
							return;
						}
						break;
					case "Poi's Bounty":
						if (!isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY)) {
							return;
						}
						break;
					case "Club Shop":
						if (!isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP)) {
							return;
						}
						if (!userTracker?.clubId) {
							return;
						}
						break;
				}
				setShowed(true);
				dispatch(showHamburgerDot());
			}
		}
	}, [showed, activeTags]);
};
