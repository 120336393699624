import { Box, Card, Grid, styled } from "@mui/material";
import Button from "components/Buttons/ThemeButton";

export function getBackgroundUrl(urlTemplate: string, variant: string): string {
	return urlTemplate.replace("{image_variant_1}", variant);
}

export function getFeaturedCard(backgroundImageUrl: string) {
	return styled(FeaturedCard)(({ theme }) => ({
		[theme.breakpoints.down("featCardImg")]: {
			backgroundImage: `url(${getBackgroundUrl(backgroundImageUrl, "small")})`
		},
		[theme.breakpoints.up("featCardImg")]: {
			backgroundImage: `url(${getBackgroundUrl(backgroundImageUrl, "large")})`
		}
	}));
}

export const FeaturedCard = styled(Card)(({ theme }) => ({
	"& *": {
		transition: "all 0.05s ease-in"
	},
	[theme.breakpoints.down("xs")]: {
		backgroundSize: "114% 113%",
		backgroundPosition: "-10px 0px",
		height: "auto"
	},
	[theme.breakpoints.down("md")]: {
		minHeight: 330
	},
	[theme.breakpoints.down("featCardImg")]: {
		backgroundSize: "cover",
		backgroundPosition: "center",
		minHeight: 340
	},
	[theme.breakpoints.up("featCardImg")]: {
		backgroundPosition: "center left",
		backgroundSize: "auto 100%",
		minHeight: 340
	},
	[theme.breakpoints.up("md")]: {
		minHeight: 355
	},
	position: "relative",
	zIndex: 0,
	backgroundSize: "cover",
	borderRadius: 20,
	width: "100%",
	margin: `0 auto ${theme.spacing(2)}`,
	height: "auto",
	paddingTop: "1rem"
}));
/* eslint-disable */
export const TitleImage = styled("img")`
	content: url(${(props: any) => props["data-imageurl"]});
	max-width: 300px;
	width: 100%;
	bottom: 62px;

	@media (min-width: 1200px) {
		width: 150%;
	}
	@media (max-width: 1200px) {
		width: 100%;
		left: 1%;
		bottom: 4vw;
	}
	@media (max-width: 900px) {
		width: 32vw;
	}
	@media (max-width: 500px) {
		width: 45vw;
	}
`;
/* eslint-enable */
export const CtaButton = styled(Button)`
	width: 274px;
	@media (max-width: 430px) {
		width: 133px;
	}
`;

export const ButtonWrapper = styled(Box)`
	width: 50%;

	& button {
		cursor: pointer;
		font-size: 3rem;
	}
`;

export const RewardItem = styled(Grid)`
	.imgWrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100px;
			max-height: 100px;
			height: 72px; // fallback for older browsers
			height: clamp(50px, 5vw, 69px);
			z-index: 1;
			width: auto;
			max-width: none;
			@media (max-width: 500px) {
				max-width: 35px;
				max-height: 35px;
			}
		}
	}
`;

export const RewardItemBox = styled(Box)`
	color: white;
	padding-top: 4.2rem;
	//padding: 4.2rem 16% 0;

	${(props) => props.theme.breakpoints.down("sm")} {
		padding-right: 7%;
	}
	-webkit-text-fill-color: white;
	text-shadow: 0 4px 4px rgb(0 0 0 / 35%);
	margin-bottom: 1rem;

	div[class*="textWrap"] {
		font-size: 1.4rem;
		font-size: clamp(12px, 1.65vw, 26px);
		font-weight: bold;
		margin-bottom: 1rem;
		text-align: center;
		z-index: 1;
		position: relative;
		@media (max-width: 500px) {
			font-size: clamp(11px, 1.65vw, 26px);
		}
	}

	& > div {
		backdrop-filter: blur(7px);
	}
`;

export const RewardItemBoxGrid = styled(Grid)({
	background: "rgba(0, 0, 0, 0.15)",
	borderRadius: "10px",
	paddingTop: ".6rem",
	paddingBottom: ".3rem",
	justifyContent: "center"
});

export const InfoBox = styled(Box)`
	align-items: center;
	background-color: rgb(0 0 0 / 50%);
	border-radius: 84px;
	color: white;
	display: flex;
	flex-direction: row;
	font-weight: bold;
	opacity: 0.9;
	padding: 0.4rem 0.7rem;
	background-repeat: "no-repeat";
`;

export const ItemValue = styled("div")`
	@media (max-width: 500px) {
		&.small-text {
			transform: translateY(2px);
			font-size: 9px !important;
		}
	}
`;

export const LoyalBox = styled(InfoBox)`
	background-position: 90% center;
	color: white;
	padding-right: "2rem";
	background-color: "white";
	white-space: nowrap;
	font-size: 12px;
	width: fit-content;
	flex-direction: column;
	border-radius: 22px;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.2rem;
	}
`;

export const LoyalBoxWrap = styled(Box)`
	bottom: -0.7rem;
	display: flex;
	font-size: 0.5em;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;

	${(props) => props.theme.breakpoints.up("sm")} {
		font-size: 1em;
	}
`;

export const ButtonContainer = styled(Box)`
	&.isCurrentOffer button,
	&.isCurrentOffer.isCurrentOfferSet button {
		background: rgba(62, 122, 32, 1);
	}
`;
