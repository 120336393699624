import classNames from "classnames";
import { usePoisBountyContext } from "components/PoisBountyWidget/context";
import { useImagePath } from "hooks";
import { Container, Information, Title, Chest } from "./styles";
import { InfoButton, Timer } from "components/PoisBountyWidget/lib/common";

export const SentUncollectedRewards = () => {
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const { getImage } = useImagePath("local");
	const backgroundEmblem = getImage(
		"/spec_pois_bounty/pois_bounty_tiki_and_poi_emblem.png"
	);
	const treasureChest = getImage(
		"/spec_pois_bounty/pois_bounty_treasure_chest.png"
	);
	const megaMenuVariant = !isPageVariant;
	const variants = [
		isPortrait && "portrait",
		megaMenuVariant && "mega-menu-variant"
	];

	return (
		<Container className={classNames([...variants])}>
			<Information
				background-image={backgroundEmblem}
				className={classNames([...variants])}
			>
				<Title className={classNames([...variants])}>
					Your uncollected rewards during this event were sent to your in-game
					inbox
				</Title>
			</Information>
			<Chest src={treasureChest} className={classNames([...variants])} />

			{/* 🔦 Overlap components */}
			<Timer />
			<InfoButton />
		</Container>
	);
};
