import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Typography,
	DialogActions,
	styled
} from "@mui/material";
import ThemeButton from "components/Buttons/ThemeButton";
import { useEnvContext, useGoogleAnalyticsContext } from "contexts";
import { useAppDispatch } from "hooks";
import { onDIPtrigger } from "redux/slices";
import CloseIconButton from "../lib/CloseIconButton";

const buttonStyles = {
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	marginBottom: 1,
	width: "calc(100% - 3rem)",
	marginLeft: 2,
	marginRight: 2
};

const ButtonContainer = styled("div")({
	width: "100%",
	paddingLeft: "16px"
});

const DialogDailyGiftConfirmation = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onCloseClick: () => void;
}) => {
	const dispatch = useAppDispatch();
	const { cdnPrefix } = useEnvContext();
	const backgroundImage = `url(${cdnPrefix}/spec_daily_gift/web_daily_gift_confirm_v4.png)`;
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<Dialog
			open={show}
			onClose={() => {
				dispatch(onDIPtrigger("collectDailyGift"));
				onCloseClick();
			}}
			sx={{
				".MuiPaper-root": {
					borderRadius: 6,
					paddingBottom: ".15rem",
					width: "462px"
				}
			}}
		>
			<DialogTitle
				sx={{
					height: "199px",
					padding: 0,
					backgroundSize: "cover",
					backgroundColor: "#3B2FC5",
					backgroundImage: backgroundImage,
					backgroundPosition: "center top",
					overflow: "hidden"
				}}
			>
				<CloseIconButton
					onClose={() => {
						onCloseClick();
						reportEvent("dialog_x_close", {
							source: "daily_gift_confirmation_dialog"
						});
					}}
				/>
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 0 }}>
				<DialogContentText sx={{ padding: ".75rem 1rem" }}>
					<Typography
						variant="h6"
						component="strong"
						sx={{
							fontWeight: "bold",
							margin: "1rem 0 .75rem",
							display: "block",
							textAlign: "center",
							fontSize: "130%",
							lineHeight: 1,
							color: "#333333"
						}}
					>
						Your Daily Gift was sent to your inbox in-game!
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
				<ButtonContainer>
					<ThemeButton
						onClick={() => {
							onCloseClick();
							dispatch(onDIPtrigger("collectDailyGift"));
							reportEvent("continue_shopping_click", {
								source: "daily_gift_confirmation_dialog"
							});
						}}
						sx={{
							...buttonStyles
						}}
					>
						Continue Shopping
					</ThemeButton>
				</ButtonContainer>
			</DialogActions>
		</Dialog>
	);
};

export { DialogDailyGiftConfirmation };
