/* eslint-disable */
export enum GSNHeader {
	NamespaceOverride = "X-GSN-WEBSTORE-NAMESPACE-OVERRIDE",
	DeviceType = "X-GSN-WEBSTORE-DEVICE-TYPE",
	SyntheticId = "X-GSN-WEBSTORE-SYNTHETIC-ID"
}

export enum ContentType {
	PlainText = "text/plain; charset=utf-8"
}
/* eslint-enable */
