import {
	IWebStoreContextApiProps,
	IUserTracker,
	IAuthToken,
	IUserTrackerResponse,
	IPostUserMetadataOptions
} from "../types";
import { isMobile } from "react-device-detect";
import { useGoogleAnalyticsContext } from "../../GoogleAnalyticsProvider";
import { analyticsTracker } from "../../ScopelyIdentityProvider/config";

interface IGetPostUserMetadataProps extends IWebStoreContextApiProps {
	setUserTracker: React.Dispatch<React.SetStateAction<IUserTracker | null>>;
	authToken: IAuthToken | null;
	logout: () => void;
	userTracker?: IUserTracker | null;
	setNowTime: (now: string) => void;
	setNetworkErrorDetected: React.Dispatch<React.SetStateAction<boolean>>;
}

export type IPostUserMetadataReturnType = ({
	key,
	value
}: {
	key: string;
	value: string;
}) => Promise<IUserTrackerResponse | void>;

const getPostUserMetadata = ({
	axios,
	debug,
	backendUri,
	popups,
	setUserTracker,
	createHeaders,
	authToken,
	userTracker,
	setNowTime,
	syntheticId,
	setNetworkErrorDetected
}: IGetPostUserMetadataProps): IPostUserMetadataReturnType => {
	const { setTuidForAnalytics, checkAdsPersonalizationRemoteSettings } =
		useGoogleAnalyticsContext();
	const postUserMetadata = async ({
		key,
		value
	}: IPostUserMetadataOptions): Promise<void | IUserTrackerResponse> => {
		if (!authToken?.value || !userTracker) {
			// popups.showErrorDialog("no_auth_token_found", "No auth token found");
			// window?.console &&
			// 	console.warn("no_auth_token_found", "No auth token found");
			return;
		}

		let version = null;
		if (userTracker && userTracker?.version) {
			version = userTracker.version;
		}
		const queryParams: {
			version?: string;
		} = {};
		if (version) {
			queryParams.version = version;
		}
		const deviceToken = analyticsTracker.getDeviceToken();
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				// "Content-Type": "text/plain; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId,
				"X-TITAN-DEVICE-TOKEN": deviceToken ? deviceToken : ""
			}),
			params: queryParams
		};
		const uri = `/rest/user/tracker/metadataValue?key=${key}&value=${value}`;
		return axios
			.post<IUserTrackerResponse>(uri, {}, requestConfig)
			.then(async (response) => {
				if (response.data) {
					setNetworkErrorDetected(false);
					if (setNowTime && response.headers["x-gsn-webstore-now"]) {
						setNowTime(response.headers["x-gsn-webstore-now"]);
					}
					debug.log({
						iconText: "postUserMetadata:",
						message: ["userTracker:", response.data.userTracker]
					});
					if (userTracker) {
						setUserTracker(response.data.userTracker);
						if (response.data.userTracker.id) {
							setTuidForAnalytics(response.data.userTracker.id);
							analyticsTracker
								.getContext()
								.setTenant(response.data.userTracker.id);
							checkAdsPersonalizationRemoteSettings(response.data.userTracker);
						}
						return { userTracker: response.data.userTracker };
					}
				} else {
					popups.handleErrorResponse({ response });
				}
			})
			.catch((err) => {
				const status = err?.response?.status;
				if (status === 304) {
					setNetworkErrorDetected(false);
					if (userTracker && userTracker.id) {
						setTuidForAnalytics(userTracker.id);
						analyticsTracker.getContext().setTenant(userTracker.id);
						checkAdsPersonalizationRemoteSettings(userTracker);
					}
					return;
				}
				if (status) {
					popups.handleErrorResponse(err);
				}
			});
	};

	return postUserMetadata;
};

export default getPostUserMetadata;
