import { parseContent } from "..";
import { Box, Divider, styled, Typography } from "@mui/material";
import moment from "moment";
import ShareThisWidget from "./ShareThis";
import PrevNextArticleLinks from "./PrevNextArticleLinks";
import { useImagePreloader } from "hooks";
import { NewsArticleContentHeaderSkeleton } from "../skeleton";
import { IPostListItem } from "./PostList";

export const NewsItem = styled(Box)`
	border-radius: 18px;
	overflow: hidden;
	background: #fffaf1;
	box-shadow: 0px 11.0701px 11.0701px rgba(0, 0, 0, 0.1);
	border-radius: 22px;
	color: #014189;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	a,
	.date {
		color: #01a1bd;
		text-decoration: none;
	}
`;

const FeaturedImage = styled("img")`
	width: 100%;
	max-width: none;
	max-height: 261px;
	animation: fadeIn 1s;
`;
const Content = styled("div")`
	max-width: 790px;
	padding: 1em 2em;

	p {
		width: 100%;
	}
	div[dir="auto"] {
		margin-bottom: 1rem;
	}

	.alignright,
	.alignleft {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.alignright {
		float: right;
		margin-left: 2rem;
	}
	.alignleft {
		float: left;
		margin-right: 2rem;
	}
	img {
		max-width: 100%;
		max-height: 100vh;
		width: auto;
		height: auto;
		border-radius: 18px;
		max-height: 600px;
		margin: auto auto;
	}
	strong img {
		display: block;
	}
	ol,
	ul {
		margin-left: 0;
		padding-left: 2%;
		li {
			ol,
			ul {
				margin-left: 0;
				padding-left: 2%;
				li {
					ol,
					ul {
						margin-left: 0;
						padding-left: 1%;
						li {
							ol,
							ul {
								margin-left: 0;
								padding-left: 1%;
							}
						}
					}
				}
			}
		}
	}

	ul,
	ol,
	p {
		img {
			display: block;
			max-height: 768px;
			border-radius: 18px;
		}
	}
`;

const ArticleItem = ({ post }: { post: IPostListItem }) => {
	const postDate = post?.date && moment(post.date).format("MMMM DD, YYYY");
	const { isImageLoaded: isFeaturedImageLoaded } = useImagePreloader(
		post?.jetpack_featured_media_url
	);
	return (
		<NewsItem>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					animation: "fadeIn 1s"
				}}
			>
				{(isFeaturedImageLoaded && post?.jetpack_featured_media_url && (
					<FeaturedImage src={post?.jetpack_featured_media_url} />
				)) || <NewsArticleContentHeaderSkeleton />}
				<Box
					sx={{
						width: "100%",
						padding: "1rem",
						display: "flex",
						boxSizing: "border-box",
						justifyContent: "flex-start"
					}}
				>
					<Typography
						component="span"
						sx={{ marginBottom: "1rem", display: "block" }}
					>
						{postDate}
					</Typography>
				</Box>
				<Content>
					<Typography component="h3" variant="h5">
						{parseContent(post?.title?.rendered)}
					</Typography>
					<Typography
						component="div"
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "flex-start"
						}}
					>
						{parseContent(post?.content?.rendered || "")}
					</Typography>
					<Divider />
					<ShareThisWidget
						shareUrl={location.href}
						title={post?.title?.rendered}
					/>
					<Divider />
				</Content>
				<PrevNextArticleLinks
					postDate={post.date}
					postId={post.id}
					sx={{
						width: "100%",
						boxSizing: "border-box"
					}}
				/>
			</Box>
		</NewsItem>
	);
};

export default ArticleItem;
