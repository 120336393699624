import { FeaturesFlags } from "helpers";
import { PrivateRoute } from "../../hoc";
import { Fragment, useState } from "react";
import ClubEventSection from "./ClubEventSection";
import { useEnvContext } from "contexts/EnvProvider";
import { PageContent } from "components/PageContent";
import { GiftBoxesSection } from "./GiftBoxesSection/index";
import { useWebStoreContext } from "../../contexts/WebStoreProvider";
import PageTopNav, { ISectionMenuItem } from "../../components/PageTopNav";

const ClubShop = () => {
	const { userTracker } = useWebStoreContext();
	const [sectionMenuData] = useState<ISectionMenuItem[]>([]);
	const { isFeatureFlagEnabled } = useEnvContext();

	return (
		<PrivateRoute
			isEnabled={
				(userTracker?.clubId ? true : false) &&
				isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP)
			}
		>
			<Fragment>
				<PageTopNav sectionMenuData={sectionMenuData} />
				<PageContent source="club_shop_page">
					<ClubEventSection />
					<GiftBoxesSection />
				</PageContent>
			</Fragment>
		</PrivateRoute>
	);
};

export default ClubShop;
