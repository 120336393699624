import { Skeleton, styled } from "@mui/material";
import { ReactElement } from "react";
import { Content } from "../../components/Section/SectionContentGrid";

const MenuContainer = styled("div")`
	display: flex;
`;
const ContentContainer = styled("div")`
	margin-top: 1rem;
`;

/* eslint-disable */

const loop = (times: number, callback: (i: number) => ReactElement) => {
	return [...Array(times)].map((item: any, i: number) => callback(i));
};

const MainMenuItems: any = (): ReactElement[] => {
	return loop(2, (i: number) => (
		<Skeleton
			variant="rectangular"
			animation="wave"
			width={190}
			height={40}
			key={"main-menu-item-" + i}
			sx={{ marginRight: ".5rem", borderRadius: "5px" }}
		/>
	));
};

const SectionMenuItems: any = (): ReactElement[] => {
	return loop(5, (i: number) => (
		<Skeleton
			animation="wave"
			variant="text"
			width={120}
			height={65}
			key={"section-menu-item-" + i}
			sx={{ marginRight: ".5rem" }}
		/>
	));
};

const ContentOfferCards: any = (): ReactElement => {
	return (
		<>
			<Skeleton
				animation="wave"
				variant="text"
				width={220}
				height={65}
				sx={{ marginBottom: ".5rem", flexBasis: "100%" }}
			/>
			<ContentContainer>
				<Content>
					{loop(4, (i: number) => (
						<Skeleton
							animation="wave"
							variant="rectangular"
							height={270}
							key={"offer-item-" + i}
							sx={{ marginBottom: 0, paddingBottom: 0, borderRadius: "12px" }}
						/>
					))}
				</Content>
			</ContentContainer>
		</>
	);
};

export const LoyaltyShopMenuSkeleton = () => {
	return (
		<>
			<MenuContainer sx={{ marginTop: { xs: 1.5, md: "-2.5rem" } }}>
				<MainMenuItems />
			</MenuContainer>
			<MenuContainer sx={{ marginTop: "-.2rem" }}>
				<SectionMenuItems />
			</MenuContainer>
		</>
	);
};

/* eslint-enable */

export const SingleItemsSkeleton = () => {
	return (
		<>
			<ContentOfferCards />
			<ContentOfferCards />
			<ContentOfferCards />
		</>
	);
};
