import { AbstractHttpService } from "./types";
import AxiosHttpService from "./axiosHttpService";
import { IDebugLogger } from "../../utils/debugLogger";

// All callers depend on abstraction, not concretion
export function createHttpService(logger: IDebugLogger): AbstractHttpService {
	return new AxiosHttpService(logger);
}

export { AbstractHttpService };
