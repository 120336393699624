import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	position: relative;
	grid-gap: 10px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 34%;
	top: -11%;
	flex-direction: row;
`;
