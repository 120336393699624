// import * as React from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import SegmentationOverride from "./SegmentationOverride";
import { SegmentationContent } from "./styles";

const SegmentationDialog = () => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClick}>
				Set Segmentation
			</Button>
			<Dialog fullScreen open={open} onClose={handleClick}>
				{/* <DialogTitle>Segmentation Override</DialogTitle> */}
				<DialogContent>
					<SegmentationContent>
						<SegmentationOverride onSave={handleClick} />
					</SegmentationContent>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClick}>Close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SegmentationDialog;
