export const Timer = () => (
	<svg width="21" height="22" viewBox="0 0 21 22" fill="none">
		<g clipPath="url(#clip0_2735_35858)">
			<path
				d="M14.7055 11.0016H12.7245V7.72625C12.7102 7.38365 12.564 7.05985 12.3165 6.8225C12.069 6.58516 11.7394 6.45264 11.3964 6.45264C11.0535 6.45264 10.7239 6.58516 10.4764 6.8225C10.2289 7.05985 10.0827 7.38365 10.0684 7.72625V12.3611C10.0696 12.713 10.2101 13.0502 10.4592 13.2988C10.7083 13.5475 11.0457 13.6874 11.3976 13.688C11.493 13.6874 11.5882 13.6773 11.6816 13.6578H14.7079C14.887 13.6652 15.0657 13.6365 15.2334 13.5731C15.401 13.5097 15.5541 13.4131 15.6835 13.289C15.8129 13.165 15.9158 13.016 15.9862 12.8512C16.0565 12.6863 16.0927 12.5089 16.0927 12.3297C16.0927 12.1504 16.0565 11.9731 15.9862 11.8082C15.9158 11.6433 15.8129 11.4944 15.6835 11.3703C15.5541 11.2463 15.401 11.1496 15.2334 11.0863C15.0657 11.0229 14.887 10.9941 14.7079 11.0016H14.7055Z"
				fill="#EB5757"
			/>
			<path
				d="M11.7499 2.58612C9.8 2.5835 7.89976 3.20061 6.3235 4.34835L5.35042 3.37296L6.40963 2.31376C6.62784 2.09548 6.75043 1.79948 6.75043 1.49084C6.75043 1.1822 6.62784 0.886208 6.40963 0.667935L6.08139 0.33737C5.86311 0.119163 5.56713 -0.00341797 5.25849 -0.00341797C4.94986 -0.00341797 4.65385 0.119163 4.43557 0.33737L0.340802 4.43215C0.122595 4.65042 0 4.94642 0 5.25506C0 5.5637 0.122595 5.8597 0.340802 6.07798L0.669016 6.40853C0.887289 6.62674 1.1833 6.74933 1.49194 6.74933C1.80058 6.74933 2.09659 6.62674 2.31486 6.40853L3.37404 5.34934L4.33546 6.30844C3.09176 7.97848 2.44803 10.0193 2.5084 12.1007C2.56878 14.1821 3.32975 16.1822 4.66816 17.7774C6.00658 19.3725 7.84412 20.4693 9.88338 20.8903C11.9226 21.3113 14.0443 21.0318 15.905 20.0971C17.7657 19.1624 19.2565 17.6271 20.1362 15.7398C21.0158 13.8525 21.2329 11.7235 20.7522 9.69748C20.2715 7.67146 19.1212 5.86693 17.4875 4.57593C15.8537 3.28493 13.8321 2.58302 11.7499 2.58379V2.58612ZM11.7499 18.5253C10.4265 18.5253 9.1329 18.1328 8.03262 17.3976C6.93235 16.6623 6.0748 15.6173 5.56849 14.3946C5.06218 13.172 4.92982 11.8267 5.18816 10.5288C5.4465 9.23092 6.08395 8.03881 7.01985 7.10323C7.95575 6.16766 9.14807 5.53065 10.446 5.27276C11.744 5.01487 13.0893 5.14769 14.3118 5.65443C15.5342 6.16117 16.579 7.01905 17.3138 8.11959C18.0487 9.22012 18.4407 10.5139 18.4402 11.8372C18.4384 13.6108 17.7328 15.3112 16.4785 16.5652C15.2241 17.8191 13.5235 18.524 11.7499 18.5253Z"
				fill="#EB5757"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2735_35858">
				<rect width="21" height="21.0861" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
