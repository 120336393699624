import { PREFIX, showDebugLabelsPath } from "./helper";
import { RootState } from "redux/store";
import { isDebugEnabled } from "./thunks";
import { initialState } from "./initial-state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const debugSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		setShowDebugLabels: (state, action: PayloadAction<boolean>) => {
			state.showDebugLabels = action.payload;
			localStorage.setItem(showDebugLabelsPath, JSON.stringify(action.payload));
		}
	},
	extraReducers: (builder) => {
		builder.addCase(isDebugEnabled.fulfilled, (state, action) => {
			const debugConfig = action.payload;
			if (typeof debugConfig !== "undefined") {
				state.debugEnabled = debugConfig;
			}
		});
	}
});

export const { setShowDebugLabels } = debugSlice.actions;

export const debugState = (state: RootState) => state.debug;
export default debugSlice.reducer;
export * from "./thunks";
