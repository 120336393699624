import styled, { CSSProperties } from "styled-components";
//import bgBrushTop from "assets/sticky-dip/bg-brush-top.png";
//import bgBrushBottom from "assets/sticky-dip/bg-brush-bottom.png";
import bgBrush from "assets/limited-deals/bg-brush.png";

export const Container = styled.div`
	width: 100%;
	padding: 0;
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;

	&.buy-it-all {
		object-fit: cover;
	}
`;

export const Title = styled.div<{ titleImage?: string }>`
	content: url(${(props) => props.titleImage});
	max-width: 300px;
	margin-bottom: 20px;

	@media (min-width: 731px) {
		max-width: 380px;
	}

	@media (min-width: 931px) {
		max-width: 420px;
	}
`;

export const timerStyle: CSSProperties = {
	top: "8%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#fff",
	position: "absolute"
};

export const timerStyleMobile: CSSProperties = {
	...timerStyle,
	top: "5%",
	whiteSpace: "nowrap"
};

// export const BgTopBrush = styled.div`
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background-size: auto auto;
// 	background-position: top;
// 	background-repeat: no-repeat;
// 	background-image: url(${bgBrushTop});
// 	padding: 2px 0;
// `;

// export const BgBottomBrush = styled.div`
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background-size: auto auto;
// 	background-position: bottom;
// 	background-repeat: no-repeat;
// 	background-image: url(${bgBrushBottom});
// `;

export const BgBrush = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	//src: url(${bgBrush});
	width: 100%;
	height: 100%;
	//background-size: 100% 100%;
	//background-position: center;
	//background-repeat: no-repeat;
	//background-image: url(${bgBrush});
`;
