export const Flag = () => (
	<svg
		width="126"
		height="45"
		viewBox="0 0 126 45"
		fill="none"
		style={{ borderTopRightRadius: "24px" }}
	>
		<path d="M126.141 0L0 0L9.0758 22.5L0 45L126.141 45V0Z" fill="#EB5757" />
	</svg>
);
