import { AnimatePresence, motion } from "framer-motion";
import { CSSProperties } from "react";

const commonStyles: CSSProperties = {
	width: "100%",
	justifyContent: "center",
	alignItems: "center",
	display: "flex"
};

interface Props {
	children: JSX.Element | JSX.Element[];
}

export const DownUpAnimation = ({ children }: Props): JSX.Element => {
	return (
		<AnimatePresence>
			<motion.div
				initial={{ translateY: 100 }}
				animate={{ translateY: 0 }}
				exit={{ opacity: 0 }}
				style={commonStyles}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};

export const LeftRightAnimation = ({ children }: Props): JSX.Element => {
	return (
		<AnimatePresence>
			<motion.div
				initial={{ translateX: -200 }}
				animate={{ translateX: 0 }}
				exit={{ opacity: 0 }}
				style={commonStyles}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};
