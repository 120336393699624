import { Grid } from "@mui/material";
import coinIcon from "assets/coin.png";
import { AppBarUserBox } from "./styled";
//import { CrownIcon } from "utils/svgIcons";
import { useWebStoreContext } from "contexts";
import useBreakpoints from "hooks/useBreakpoints";
import { GenericPointBalance, ProfileImage } from "./lib";
import LoyaltyPoints from "./lib/LoyaltyPoints";

export const AppBarUser = () => {
	const { authToken, userTracker } = useWebStoreContext();
	const { isSmDown } = useBreakpoints();
	const inventory = userTracker?.inventory;

	return (
		<AppBarUserBox>
			{userTracker && authToken && (
				<Grid
					container
					position="absolute"
					direction={isSmDown ? "column" : "row"}
					right={67}
					spacing={0.5}
					width="max-content"
				>
					<Grid item>
						<GenericPointBalance
							icon={<img src={coinIcon} alt="" />}
							points={inventory?.coins ?? 0}
						/>
					</Grid>
					<Grid item>
						{/* <GenericPointBalance icon={<CrownIcon />} points={userTracker.loyaltyPoints} /> */}
						<LoyaltyPoints
							points={userTracker?.isFirstLogin ? 0 : userTracker.loyaltyPoints}
						/>
					</Grid>
				</Grid>
			)}
			<ProfileImage />
		</AppBarUserBox>
	);
};
