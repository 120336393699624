import { links } from "helpers";
import { StickyButton } from "./styles";
import { useGoogleAnalyticsContext } from "contexts";
import stickyBackToGameIcon from "assets/icon-back-to-game.png";

interface StickyBackToGameButtonProps {
	styles?: React.CSSProperties;
	source: string;
	onClick?: () => void;
	className?: string;
}

const StickyBackToGameButton = ({ styles, source, onClick, className }: StickyBackToGameButtonProps) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { analytics_key, path } = links.back_to_game;

	const handleClick = () => {
		reportEvent(analytics_key, { source: source });
		window?.open(path, "_blank");
		onClick?.();
	};

	return (
		<StickyButton
			className={className}
			onClick={handleClick}
			style={{ ...styles }}
		>
			<img src={stickyBackToGameIcon} alt="Back to game button" />
		</StickyButton>
	);
};

export default StickyBackToGameButton;
