import { DefaultSvgProps } from "assets/pois-bounty/svg";

export const Alert = (props: DefaultSvgProps) => (
	<svg
		{...props}
		width="30"
		height="31"
		viewBox="0 0 30 31"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_d_3151_12082)">
			<ellipse cx="15" cy="14.4375" rx="12" ry="11.5625" fill="#B5251C" />
			<path
				d="M15 27C22.1444 27 28 21.4103 28 14.4375C28 7.46474 22.1444 1.875 15 1.875C7.85563 1.875 2 7.46474 2 14.4375C2 21.4103 7.85563 27 15 27Z"
				stroke="white"
				strokeWidth="2"
			/>
		</g>
		<path
			d="M17.1654 9.25621C17.1654 10.588 17.0447 11.8394 16.8032 13.0102C16.5105 14.2103 16.2214 15.4067 15.9361 16.5995C15.8702 16.8703 15.5775 17.0057 15.0579 17.0057C14.4652 17.0057 14.1359 16.8886 14.07 16.6544C13.3822 14.3054 13.0382 12.9699 13.0382 12.648C13.0382 12.3479 13.0529 11.9016 13.0821 11.3088C13.1114 10.7161 13.1261 10.2697 13.1261 9.96969C13.1261 9.64039 13.1151 9.14645 13.0931 8.48785C13.0712 7.82926 13.0602 7.33531 13.0602 7.00602C13.0602 6.73526 13.1261 6.59988 13.2578 6.59988C13.4627 6.59988 13.77 6.61452 14.1798 6.64379C14.5896 6.67306 14.8969 6.6877 15.1018 6.6877C15.3067 6.6877 15.6141 6.67306 16.0239 6.64379C16.4337 6.61452 16.741 6.59988 16.9459 6.59988C17.0923 6.59988 17.1654 7.48533 17.1654 9.25621ZM17.1654 20.134C17.1654 20.7487 16.9752 21.2609 16.5946 21.6707C16.2141 22.0732 15.7165 22.2744 15.1018 22.2744C14.4798 22.2744 13.9786 22.0732 13.598 21.6707C13.2248 21.2682 13.0382 20.756 13.0382 20.134C13.0382 19.5193 13.2285 19.0144 13.609 18.6192C13.9895 18.2241 14.4871 18.0265 15.1018 18.0265C15.7092 18.0265 16.2032 18.2241 16.5837 18.6192C16.9715 19.0144 17.1654 19.5193 17.1654 20.134Z"
			fill="white"
		/>
		<defs>
			<filter
				id="filter0_d_3151_12082"
				x="0"
				y="0.875"
				width="30"
				height="30.125"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius="1"
					operator="dilate"
					in="SourceAlpha"
					result="effect1_dropShadow_3151_12082"
				/>
				<feOffset dy="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_3151_12082"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_3151_12082"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
