/**
 * useBreakpoints custom hook
 * usage: const {isSmUp, isMdUp, isLgUp, isXlUp} = useBreakpoints();
 */
import { useMediaQuery } from "@mui/material";
import theme from "../theme";

export default function useBreakpoints() {
	// Size and up <
	const isSmUp = useMediaQuery(
		theme.breakpoints.up("sm").replace("@media", "")
	);
	const isMdUp = useMediaQuery(
		theme.breakpoints.up("md").replace("@media", "")
	);
	const isLgUp = useMediaQuery(
		theme.breakpoints.up("lg").replace("@media", "")
	);
	const isXlUp = useMediaQuery(
		theme.breakpoints.up("xl").replace("@media", "")
	);

	// Size and down >
	const isSmDown = useMediaQuery(
		theme.breakpoints.down("sm").replace("@media", "")
	);
	const isMdDown = useMediaQuery(
		theme.breakpoints.down("md").replace("@media", "")
	);
	const isLgDown = useMediaQuery(
		theme.breakpoints.down("lg").replace("@media", "")
	);
	const isXlDown = useMediaQuery(
		theme.breakpoints.down("xl").replace("@media", "")
	);

	return {
		isSmUp,
		isMdUp,
		isLgUp,
		isXlUp,
		isSmDown,
		isMdDown,
		isLgDown,
		isXlDown
	};
}
