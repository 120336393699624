import { Container, Image } from "./styles";
import { useMediaQuery } from "@mui/material";
import { useDailyLoginStreak } from "../../context";
import { getVariantImage } from "utils/getVariantImage";
import { StreakDay } from "interfaces";

interface IllustrationsProps {}

export const Illustrations: React.FC<IllustrationsProps> = ({}) => {
	const { tracker } = useDailyLoginStreak();
	const isMobile = useMediaQuery("(max-width:649px)");

	const nextCollectionDay = tracker?.streakDays.find((day: StreakDay) => {
		return tracker?.nextCollectionDate === day.startOfDay;
	});

	const getImageSource = () => {
		const imageUrl = tracker?.isStreakBroken
			? nextCollectionDay?.brokenStreakImageUrl
			: tracker.lastDayCollected
			? tracker.streakDays.slice(-1)[0].collectedImageUrl
			: nextCollectionDay?.uncollectedImageUrl;

		return getVariantImage(
			imageUrl || "",
			"{daily_bonus_streak_variant}",
			isMobile ? "small" : "large"
		);
	};
	return (
		<Container>
			<Image src={getImageSource()} />
		</Container>
	);
};
