import { Grid, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { ILandingPageSection } from "../..";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import SectionButton from "../../../../components/Buttons/SectionButton";
import {
	sectionContainerSxProps,
	sectionContentTextSxProps,
	sectionContentTitleSxProps,
	sectionRowContentCellSxProps,
	sectionRowImageCellSxProps
} from "./styled";
//
const LandingPageSection = ({
	section,
	isReversed = false
}: {
	section: ILandingPageSection;
	isReversed?: boolean;
}) => {
	const { isSmUp } = useBreakpoints();
	const [direction, setDirection] = useState("row");

	useEffect(() => {
		if (isSmUp) {
			setDirection(!isReversed ? "row-reverse" : "row");
		} else {
			setDirection("column");
		}
	}, [isSmUp]);

	return (
		<Grid
			container
			spacing={{ xs: 2, md: 4 }}
			columns={{ xs: 1, sm: 2 }}
			key={section.title}
			component="section"
			sx={{
				...sectionContainerSxProps,
				flexDirection: direction
			}}
		>
			<Grid item xs={1} sx={sectionRowImageCellSxProps}>
				<img src={section.image} />
			</Grid>
			<Grid item xs={1} sx={sectionRowContentCellSxProps}>
				<Box>
					<Typography variant="h2" sx={sectionContentTitleSxProps}>
						{section.title}
					</Typography>
					<Typography
						component="p"
						style={{ lineHeight: 1.7 }}
						sx={sectionContentTextSxProps}
					>
						{section.content}
					</Typography>
					<SectionButton
						href={section.linkHref}
						text={section.linkText}
						analytics={section.analytics}
						isExternal={!!section?.linkIsExternal}
						openExternalSameTab={section.openExternalSameTab}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

export default LandingPageSection;
