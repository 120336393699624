import { Button, styled } from "@mui/material";

export const StickyButton = styled(Button)({
	position: "fixed",
	bottom: 75,
	right: 10,
	zIndex: 999,
	padding: 0,

	"> img": {
		width: 84,
		height: 84,
	}
});
