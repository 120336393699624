// import * as React from 'react';
import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Snackbar,
	TextField
} from "@mui/material";
import moment from "moment";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WebStoreContext } from "../../contexts/WebStoreProvider";
import {
	DebugWebStoreContext,
	LOCAL_STORAGE_DATE_JUMPED
} from "../contexts/DebugWebStoreProvider";

const SetServerTimeDialog = () => {
	const { search } = useLocation();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [fieldValue, setFieldValue] = useState("");
	const [copied, setCopied] = useState<boolean>(false);
	const { nowTime } = useContext(WebStoreContext);
	const { setServerTime } = useContext(DebugWebStoreContext);

	useEffect(() => {
		if (nowTime) {
			const pacificTime = DateTime.fromISO(nowTime.toISOString())
				.setZone("America/Los_Angeles")
				.toISO();
			setFieldValue(pacificTime);
		} else {
			setFieldValue("");
		}

		const urlHasTimeJump = search.startsWith("?timeJump");
		if (urlHasTimeJump) {
			const timeJump = search.split("=")[1];
			if (moment(timeJump).isValid()) {
				navigate(window.location.pathname);
				localStorage.setItem(LOCAL_STORAGE_DATE_JUMPED, timeJump);
				setServerTime(timeJump);
			}
		}
	}, []);

	const handleClickOpen = () => {
		if (nowTime) {
			const pacificTime = DateTime.fromISO(nowTime.toISOString())
				.setZone("America/Los_Angeles")
				.toISO();
			setFieldValue(pacificTime);
		}
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	/* eslint-disable-next-line */
	const onChange = (e: any) => {
		setFieldValue(e.target.value);
	};

	const copyToClipboard = () => {
		const baseUrl = window.location.href;
		const timeJump =
			localStorage.getItem(LOCAL_STORAGE_DATE_JUMPED) ?? fieldValue;
		navigator.clipboard.writeText(`${baseUrl}?timeJump=${timeJump}`);
		setCopied(true);
	};

	const handleSetServerTime = () => {
		localStorage.setItem(LOCAL_STORAGE_DATE_JUMPED, fieldValue);
		setServerTime(fieldValue);
		setOpen(false);
	};

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				open={copied}
				autoHideDuration={2000}
				onClose={() => setCopied(false)}
				message="Copied to clipboard"
			/>
			<ButtonGroup>
				<Button variant="outlined" onClick={handleClickOpen}>
					Set
				</Button>
				<Button variant="outlined" onClick={copyToClipboard}>
					Copy
				</Button>
			</ButtonGroup>
			<Dialog fullWidth open={open} onClose={handleClose}>
				<DialogTitle>Set Server Now</DialogTitle>
				<DialogContent>
					<TextField
						onChange={onChange}
						autoFocus
						margin="dense"
						id="name"
						defaultValue={fieldValue}
						label="Server Now"
						type="string"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSetServerTime}>Set</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SetServerTimeDialog;
