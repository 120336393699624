import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { PBTextShadow } from "components/PoisBountyWidget/lib/common";

export const Container = styled(Box)`
	background-color: #0184a4;
	position: relative;
	height: 100%;
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	&.portrait {
		grid-template-columns: 1fr;
	}
	&.mega-menu-variant {
		grid-template-columns: 1fr;
	}
`;
/* eslint-disable */
export const Information = styled("div")`
	background-image: url(${(props: any) => props["background-image"]});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	background-color: #0184a4;
	position: relative;
	height: 100%;
	padding: 0 4rem;
	display: flex;
	align-items: center;
	@media (min-width: 900px) and (max-width: 1105px) {
		background-size: cover;
		padding: 0 2rem;
	}

	&.portrait {
		background-size: cover;
		position: absolute;
		background-position: -100px;
		bottom: 0;
		height: 50%;
		text-align: center;
		padding: 0 0.88rem;
	}

	&.mega-menu-variant {
		background-size: cover;
		background-position: -50px;
		align-items: flex-end;
		text-align: center;
		padding: 0 3rem;
	}
`;
/* eslint-enable */
export const Title = styled(PBTextShadow)`
	align-items: center;
	@media (min-width: 900px) and (max-width: 1105px) {
		font-size: 25px;
	}
	&.portrait {
		font-size: 30px;
	}

	&.mega-menu-variant {
		font-size: 25px;
		transform: translateY(-2.5rem);
	}
`;

export const Chest = styled("img")`
	position: absolute;
	top: 50%;
	right: 0;
	width: 380px;
	transform: translateY(-50%);
	@media (min-width: 900px) and (max-width: 1105px) {
		width: 300px;
	}

	&.portrait {
		all: unset;
		position: absolute;
		top: 3.5rem;
		left: 50%;
		transform: translateX(-51%);
	}

	&.mega-menu-variant {
		all: unset;
		position: absolute;
		top: 1rem;
		width: 340px;
		left: 50%;
		transform: translateX(-51%);
	}
`;
