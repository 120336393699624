import { useLoadReward } from "hooks";
import { useAppSelector } from "hooks";
import { PoisBountyProgressStep, RewardClaimStatuses } from "interfaces";
import { selectPoisBountyV2State } from "redux/slices";

interface IUseRewards {
	steps: PoisBountyProgressStep[];
}

export const useRewards = (data: RewardClaimStatuses[]): IUseRewards => {
	const { loadReward } = useLoadReward();
	const { trackers } = useAppSelector(selectPoisBountyV2State);

	const steps: PoisBountyProgressStep[] = data.map((item) => {
		const { reward, claimed } = item;
		const { rewardId, loyaltyPoints } = reward;
		return {
			imageUrl: loadReward(rewardId)?.imageUrl,
			rewardAmount: loadReward(rewardId)?.numberOfItems,
			target: loyaltyPoints,
			status: claimed
				? "unlocked"
				: trackers?.loyaltyPoints >= loyaltyPoints
				? "in-progress"
				: "locked"
		};
	});

	return {
		steps
	};
};
