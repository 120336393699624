import { Box } from "@mui/material";
import { images } from "helpers";
import { useImagePath } from "hooks";
import { playNowBoxSxProps } from "../styled";

export const PlayNowText = () => {
	const { getImage } = useImagePath("cdn");
	return (
		<Box sx={playNowBoxSxProps}>
			<Box>
				<img src={getImage(images.spec_landing_page__line_2_left.src)} />
			</Box>
			<span>Play now</span>
			<Box>
				<img src={getImage(images.spec_landing_page__line_2_right.src)} />
			</Box>
		</Box>
	);
};
