import styled from "@emotion/styled";
import { tabletGiftsGaloreView } from "../../styles";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0.8rem;
	right: 12px;
	z-index: 1;

	.clubName {
		display: none;
	}

	@media (min-width: ${tabletGiftsGaloreView}) {
		top: 15px;
		right: 10px;

		.clubName {
			font-size: 18px;
			display: flex;
		}
	}
`;

export const ClubName = styled.span`
	background: #ffffffb2;
	padding: 0.5rem 0.8rem;
	border-radius: 1rem;
	font-weight: 700;
	margin-right: 0.5rem;
`;

export const Button = styled.button`
	background-color: unset;
	border: unset;
	cursor: pointer;
	&:hover {
		circle {
			fill-opacity: 0.8;
		}
	}
`;
