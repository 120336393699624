/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	DialogContent,
	DialogContentText,
	Typography,
	DialogActions,
	Grid,
	useMediaQuery
} from "@mui/material";
import { useWebStoreContext, useEnvContext } from "contexts";
import {
	IShopRewardItem,
	IShopLoginReward,
	IShopReward
} from "contexts/WebStoreProvider/types";
import { useState, useEffect } from "react";
import BonusFlag from "components/BonusFlag";

import CloseIconButton from "../lib/CloseIconButton";
import ThemeButton from "components/Buttons/ThemeButton";
import { useImagePath } from "hooks";
import {
	CustomDialog,
	CustomDialogContent,
	CustomDialogContentText,
	CustomDialogTitle,
	StyledBox
} from "./styles";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";

const DialogClaimYourFreeLoginGift = ({
	show,
	onCloseClick,
	onClaimMyReward
}: {
	show: boolean;
	onCloseClick: () => void;
	onClaimMyReward: () => void;
}) => {
	const { store } = useWebStoreContext();
	const { cdnPrefix } = useEnvContext();
	const [bannerText, setBannerText] = useState("");
	const [description, setDescription] = useState("");
	const [rewardId, setRewardId] = useState("");
	const [rewardItems, setRewardItems] = useState<IShopRewardItem[]>([]);
	//const backgroundImage = `url(${cdnPrefix}/spec_modal_rewards/welcome_bg.png)`;
	const { getImage } = useImagePath("local");
	const backgroundImage = getImage("/spec_modal_rewards/welcome_bg_v3.png");
	const isLandscape = useMediaQuery("(max-height:590px)");
	const scale = useCalculateScale(
		isLandscape ? 730 : 410,
		isLandscape ? 330 : 550
	);

	useEffect(() => {
		const firstLoginRewards = () => {
			if (store?.firstLoginRewards) {
				const firstLoginReward = store.firstLoginRewards[0];
				if (!firstLoginReward) {
					return;
				}
				const { bannerText, description, rewardId } =
					firstLoginReward as IShopLoginReward;

				setBannerText(bannerText);
				setDescription(description);
				setRewardId(rewardId);
			}
		};
		firstLoginRewards();
	}, [store]);

	useEffect(() => {
		if (!store) {
			return;
		}
		// get reward
		const reward = store.rewards[rewardId];
		if (!reward) {
			console.error("reward", reward);
			return;
		}
		// flatten reward items
		const items: IShopRewardItem[] = Object.keys(reward.items).map((key) => {
			const { imageUrl = "" } = {
				...store.rewardTypes.filter((t: IShopReward) => t.id === key)[0]
			};
			const rewardItems = reward.items;
			return {
				id: key,
				text: key,
				value: rewardItems[key as keyof typeof rewardItems],
				imageUrl
			};
		});
		setRewardItems(items);
	}, [rewardId]);

	return rewardItems.length > 0 ? (
		<CustomDialog open={show} onClose={onCloseClick} scale={scale}>
			<CustomDialogTitle
				sx={{
					backgroundImage: `url(${backgroundImage})`
				}}
			>
				<BonusFlag position="left" text="FREE" />
				<CloseIconButton onClose={onCloseClick} />
			</CustomDialogTitle>
			<div
				style={{
					width: isLandscape ? "50%" : "auto"
				}}
			>
				<CustomDialogContent>
					<CustomDialogContentText>
						<Typography
							variant="h6"
							component="strong"
							sx={{
								fontFamily: "Baloo",
								fontWeight: "400",
								margin: ".15rem 0 .5rem",
								display: "block",
								textAlign: "center",
								//fontSize: isLandscape ? "120%" : "130%",
								fontSize: "23px",
								lineHeight: 1,
								color: "#333;"
							}}
						>
							{description}
						</Typography>
						<Grid
							container
							columns={2}
							spacing={2}
							sx={{ justifyContent: "center", marginTop: -1.53 }}
						>
							{rewardItems.map((reward) => {
								return (
									<Grid item key={`${reward.id}-${reward.value}`}>
										<StyledBox>
											<img src={reward.imageUrl} />
											<strong>{`x${reward.value}`}</strong>
										</StyledBox>
									</Grid>
								);
							})}
						</Grid>
					</CustomDialogContentText>
				</CustomDialogContent>
				<DialogActions
					sx={{ justifyContent: "center", flexDirection: "column" }}
				>
					<ThemeButton
						onClick={onClaimMyReward}
						sx={{
							paddingTop: ".75rem",
							paddingBottom: ".75rem",
							marginBottom: 1,
							width: "calc(100% - 6rem)",
							marginLeft: 2,
							marginRight: 2
						}}
					>
						Claim My Reward
					</ThemeButton>
					<Typography
						variant="caption"
						sx={{
							//paddingLeft: "3rem",
							//paddingRight: "3rem",
							fontSize: "11px",
							textAlign: "center"
						}}
					>
						*This reward can only be collected once on the initial login.
					</Typography>
				</DialogActions>
			</div>
		</CustomDialog>
	) : null;
};

export { DialogClaimYourFreeLoginGift };
