/**
 * This file is used to configure and preload static images
 */
export interface IImages {
	[filePathHandle: string]: IImage;
}
export interface IImage {
	src: string;
	enablePreload?: boolean;
}
export const images: IImages = {
	// spec_landing_page/
	spec_landing_page__page_header_background__template: {
		src: "/spec_landing_page/page-header-background-{variant}.png"
	},
	spec_landing_page__page_header_background__small: {
		src: "/spec_landing_page/page-header-background-small.png"
	},
	spec_landing_page__page_header_background__large: {
		src: "/spec_landing_page/page-header-background-large.png"
	},
	spec_landing_page__logo_tiki_solitaire_tripeaks_template: {
		src: "/spec_landing_page/logo-tiki-solitaire-tripeaks-{variant}.png"
	},
	spec_landing_page__page_content_background: {
		src: "/spec_landing_page/page-content-background.png"
	},
	spec_landing_page__page_header_chars_left: {
		src: "/spec_landing_page/page-header-chars-left-v2.png"
	},
	spec_landing_page__page_header_chars_right: {
		src: "/spec_landing_page/page-header-chars-right.png"
	},
	spec_landing_page__section_1_image_v1: {
		src: "/spec_landing_page/section-1-image-v1.png"
	},
	spec_landing_page__section_1_image_v2: {
		src: "/spec_landing_page/section-1-image-v2.png"
	},
	spec_landing_page__section_2_image: {
		src: "/spec_landing_page/section-2-image-v2.png"
	},
	spec_landing_page__section_3_image: {
		src: "/spec_landing_page/section-3-image-v2.png"
	},
	spec_landing_page__section_4_image: {
		src: "/spec_landing_page/section-4-image-v2.png"
	},
	spec_landing_page__section_5_image: {
		src: "/spec_landing_page/section-5-image-v2.png"
	},
	spec_landing_page__section_6_image: {
		src: "/spec_landing_page/section-6-image-v1.png"
	},
	spec_landing_page__line_2_left: {
		src: "/spec_landing_page/line-2-left.png"
	},
	spec_landing_page__line_2_right: {
		src: "/spec_landing_page/line-2-right.png"
	},

	// spec_daily_gift/
	spec_daily_gift__web_daily_gift_confirm: {
		src: "/spec_daily_gift/web_daily_gift_confirm_v4.png",
		enablePreload: true
	},
	// spec_modal_rewards/
	spec_modal_rewards__welcome_bg: {
		src: "/spec_modal_rewards/welcome_bg.png",
		enablePreload: true
	},
	// spec_loyalty_faq/
	spec_loyalty_faq__loyalty_faq_large: {
		src: "/spec_loyalty_faq/loyalty_faq_v2_large.png",
		enablePreload: true
	},
	spec_loyalty_faq__loyalty_faq_small: {
		src: "/spec_loyalty_faq/loyalty_faq_v2_small.png",
		enablePreload: true
	},
	spec_loyalty_faq__col1_shop: {
		src: "/spec_loyalty_faq/col1-shop.png",
		enablePreload: true
	},
	spec_loyalty_faq__col2_redeem: {
		src: "/spec_loyalty_faq/col2-redeem.png",
		enablePreload: true
	},
	spec_loyalty_faq__col3_use: {
		src: "/spec_loyalty_faq/col3-use.png",
		enablePreload: true
	},
	// spec_dialogs/
	spec_dialogs__oops: {
		src: "/spec_dialogs/oops2.png",
		enablePreload: true
	},
	spec_dialogs__success: {
		src: "/spec_dialogs/success.png",
		enablePreload: true
	},
	spec_dialogs__facebook_connect_foreground: {
		src: "/spec_dialogs/facebook-connect-foreground.png",
		enablePreload: true
	},
	spec_dialogs__facebook_connect_background: {
		src: "/spec_dialogs/facebook-connect-background.png",
		enablePreload: true
	}
};
