import Button from "@mui/material/Button";
import { styled } from "@mui/material";
import { ReactComponent as ScopelyIcon } from "../../../assets/ScopelyLogo.svg";
import {
	LOCAL_STORAGE_LOGIN_FROM,
	useWebStoreContext
} from "../../../contexts/WebStoreProvider";
import { useGoogleAnalyticsContext } from "../../../contexts/GoogleAnalyticsProvider";
import { useScopelyIdentityContext } from "../../../contexts/ScopelyIdentityProvider";
import { LoginType } from "../../../domain/Login/LoginGoogleAnalyticsHelper";
import { useFacebookContext } from "../../../contexts/FacebookProvider";
import { ConnectionStatus } from "../../../contexts/FacebookProvider/index";
import { useLocation } from "react-router-dom";

const ScopelyInternalLoginButton = styled(Button)`
	font-family: "Poppins", sans-serif;
	display: block;
	width: 100%;
	background-color: #4f5794;
	color: "white";
	font-size: 0.7rem;
	text-transform: none;
	position: relative;
	line-height: 2.5;
	&:hover {
		background-color: #444b80;
	}
`;
//background-color: #4f5794;

const ScopelyLoginButton = ({
	onClick,
	hideLogoutState = false,
	source,
	cbClick,
	prevCb,
	// default style assumed to be after Facebook login button element
	style = { marginTop: "0.75rem" }
}: {
	onClick?: () => void;
	cbClick?: () => void;
	prevCb?: () => void;
	hideLogoutState?: boolean;
	source: string;
	style?: React.CSSProperties;
}) => {
	const { isConnected, startLogin, setRedirectTo } =
		useScopelyIdentityContext();
	const { logout } = useWebStoreContext();
	const { connectionStatus } = useFacebookContext();
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();
	const location = useLocation();

	return (
		<>
			{!isConnected ? (
				<ScopelyInternalLoginButton
					variant="contained"
					onClick={() => {
						prevCb && prevCb();
						loginGoogleAnalyticsHelper.reportLoginOptionClick(
							LoginType.Scopely,
							source
						);
						if (onClick) {
							onClick();
						} else {
							const feature = source.split("#").pop();
							if (feature) {
								localStorage.setItem(LOCAL_STORAGE_LOGIN_FROM, source);
							}
							startLogin(location.pathname);
							setRedirectTo(location.pathname);
						}
						cbClick && cbClick();
					}}
					style={style}
					disabled={connectionStatus !== ConnectionStatus.UNKNOWN}
				>
					<ScopelyIcon
						style={{
							width: 12,
							height: "auto",
							position: "relative",
							bottom: -5,
							left: -4,
							maxHeight: "18px"
						}}
					/>
					Login with Scopely
				</ScopelyInternalLoginButton>
			) : (
				!hideLogoutState && (
					<ScopelyInternalLoginButton
						variant="outlined"
						onClick={logout}
						style={style}
					>
						Logout
					</ScopelyInternalLoginButton>
				)
			)}
		</>
	);
};

export default ScopelyLoginButton;
