import loyaltyFtue1 from "assets/pois-bounty/v2/loyalty_FTUE.png";
import loyaltyFtue2 from "assets/pois-bounty/v2/loyalty_FTUE_2.png";
import loyaltyFtue3 from "assets/pois-bounty/v2/loyalty_FTUE_3.png";

export interface LearnMoreItem {
	image: string;
	title: string;
	description?: string;
}

export const itemsV1: LearnMoreItem[] = [
	{
		image: "/spec_pois_bounty/Pois_bounty_tutorial_panel_1_desktop.png",
		title: "Make purchases in the Web Shop",
		description: "Purchase items in the shop during the event to collect Loyalty Points."
	},
	{
		image: "/spec_pois_bounty/Pois_bounty_tutorial_panel2_desktop.png",
		title: "Collect Loyalty Points",
		description: "Loyalty Points from your purchases will be reflected in the Poi's Bounty Meter."
	},
	{
		image: "/spec_pois_bounty/Pois_bounty_tutorial_panel3_desktop.png",
		title: "Collect Rewards from Poi!",
		description: "After you claim your rewards on the website, visit your TriPeaks Inbox to collect them!"
	}
];

export const itemsV2: LearnMoreItem[] = [
	{
		image: loyaltyFtue1,
		title: "Make purchases in the Web Shop",
		description: "Purchase items in the shop during the event to collect Loyalty Points."
	},
	{
		image: loyaltyFtue2,
		title: "Collect Loyalty Points",
		description: "Loyalty Points from your purchases will be reflected in the Poi's Bounty Meter."
	},
	{
		image: loyaltyFtue3,
		title: "Claim Rewards & Keep Going",
		description: "Once your meter is filled, claim your rewards & keep advancing until the event concludes."
	}
];
