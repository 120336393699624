import styled from "styled-components";
import { defaultTextColor } from "../styles";

export const CardTitle = styled.div`
	text-align: center;
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	margin: 0px 0px 15px 0px;
	@media (min-width: 576px) {
		font-size: 26px;
		line-height: 30px;
	}
	@media (min-width: 750px) {
		font-size: 29px;
		line-height: 35px;
	}
`;

export const CardSubTitle = styled.div`
	text-align: left;
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 700;
	font-size: 13px;
	line-height: 22px;
	@media (min-width: 576px) {
		font-size: 15px;
	}
	@media (min-width: 750px) {
		font-size: 16px;
	}
`;

export const CardFinalMessage = styled.div`
	text-align: right;
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 700;
	font-size: 16px;
	line-height: 35px;
	margin: 20px 0px 0px 0px;
	@media (min-width: 576px) {
		font-size: 18px;
	}
	@media (min-width: 750px) {
		font-size: 21px;
	}
`;

export const CardItemContainer = styled.div`
	background: #fafcff;
	padding: 5px;
	margin: 10px 0px 10px 0px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	height: 65px;
	@media (min-width: 576px) {
		padding: 10px;
	}
`;

export const CardIconContainer = styled.div`
	min-width: 95px;
	text-align: center;
`;
