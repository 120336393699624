import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import tiki from "assets/tutorial/tiki-2.svg";
import Button from "components/Buttons/ThemeButton";
import { useEffect, useState } from "react";
import { Arrow, Background2, Text, Tiki2 } from "../../styles";

interface Slide2Props {
	onNext: () => void;
}

export const Slide5 = ({ onNext }: Slide2Props) => {
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isLowHeight = useMediaQuery("(max-height: 380px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");

	const [shine, setShine] = useState<HTMLElement | null>();
	const [infoButton, setInfoButton] = useState<HTMLElement | null>();
	const [tutorialPosition, setTutorialPosition] = useState<{ top: number | string, left: number | string }>({
		top: 170,
		left: !isExtraSmall ? "-50%" : 0,
	});

	const scrollAndHighlight = async () => {
		if (infoButton && shine) {
			infoButton.scrollIntoView({ behavior: "smooth", block: "end" });

			await new Promise((resolve) => {
				const onScrollEnd = () => {
					window.removeEventListener("scrollend", onScrollEnd);
					resolve({});
				};
				window.addEventListener("scrollend", onScrollEnd);
				setTimeout(() => {
					window.removeEventListener("scrollend", onScrollEnd);
					resolve({});
				}, 1000);
			});

			const tutorialPos = infoButton.getBoundingClientRect();
			const centerX = tutorialPos.left + (tutorialPos.width / 2);
			const centerY = tutorialPos.top + (tutorialPos.height / 2);
			shine.style.position = "fixed";
			shine.style.zIndex = "9999";
			shine.style.left = `${centerX}px`;
			shine.style.top = `${centerY}px`;
			const centerTutorialX =
				isLowHeight
					? tutorialPos.left - 356
					: isExtraSmall
						? "-50%"
						: tutorialPos.left + tutorialPos.width * 1.5 - 356;
			const centerTutorialY = isLowHeight ? tutorialPos.top - tutorialPos.height / 2 : tutorialPos.top + tutorialPos.height + 10;
			setTutorialPosition({ top: centerTutorialY, left: centerTutorialX });
		}
	};

	useEffect(() => {
		const infoButton = document.getElementById("tutorial-info-button");
		setInfoButton(infoButton);
		const shine = document.getElementById("tutorial-info-button-shine");
		setShine(shine);

		scrollAndHighlight();
		infoButton?.setAttribute("style", "z-index: 99999");
		shine?.classList.add("animate");

		return () => {
			if (infoButton) {
				infoButton.setAttribute("style", "z-index: 2");
				shine?.classList.remove("animate");
			}
		}
	}, []);

	useEffect(() => {
		scrollAndHighlight();
	}, [window.innerWidth, window.innerHeight, infoButton, shine, isPortrait, isLowHeight]);

	return (
		<>
			<Grid
				container
				position="fixed"
				top={tutorialPosition.top}
				px={isExtraSmall ? 3 : 1.5}
				left={tutorialPosition.left}
				maxWidth={isExtraSmall ? "100%" : 356}
				sx={{ transform: isExtraSmall ? "translateX(50vw)" : "none" }}
			>
				<Grid item xs={12} display="flex" justifyContent="flex-end" mb={1}>
					<Arrow src={arrowUp} alt="Arrow up" className="site-to-side-right" />
				</Grid>

				<Grid item xs={12} display="flex" justifyContent="right">
					<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
						7/7
					</Text>
				</Grid>

				<Grid item xs={12}>
					<Background2>
						<Grid container mb={2}>
							<Grid item xs={2.5}>
								<Tiki2 src={tiki} alt="Tiki" bottom="4px" />
							</Grid>
							<Grid item xs={9.5} display="flex" flexDirection="column" gap={1} my={.7}>
								<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
									Enjoy shopping at Solitaire<br /> TriPeaks!
								</Text>
								<Text color="#462C12" fontFamily="Inter" fontSize="12px" lineHeight="15px">
									Revisit the tutorial under info.
								</Text>
							</Grid>
						</Grid>
						<Button className="next" onClick={onNext}>Finish</Button>
					</Background2>
				</Grid>
			</Grid>
		</>
	)
};
