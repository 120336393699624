import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		featCardImg: true;
	}
}
declare module "@mui/material/styles" {
	interface Palette {
		page: {
			backgroundColor: string;
		};
	}
	interface PaletteOptions {
		page: {
			backgroundColor: string;
		};
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			featCardImg: 844
		}
	},
	typography: {
		fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500
	},
	palette: {
		success: {
			// light: will be calculated from palette.primary.main,
			main: "#64C334",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
			contrastText: "#fff" //button text white instead of black
		},
		info: {
			main: "#4088B7"
		},
		page: {
			backgroundColor: "#fffaf1"
		}
	}
});

export default theme;
