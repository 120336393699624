import { links, images, FeaturesFlags } from "helpers";
import { ILandingPageSection } from ".";
import { useEnvContext } from "contexts";

export const getSections = (): ILandingPageSection[] => {
	const { isFeatureFlagEnabled } = useEnvContext();
	const sections: ILandingPageSection[] = [
		{
			image: images.spec_landing_page__section_1_image_v2.src,
			title: links.free_daily_gifts.title,
			content:
				"Claim your free daily gifts with Tiki Solitaire TriPeaks! Log in every day to receive exciting rewards and bonuses. Don't miss out on your daily treasures!",
			linkHref: links.free_daily_gifts.path,
			linkText: links.free_daily_gifts.title,
			analytics: links.free_daily_gifts.analytics_key,
			linkIsExternal: !!links.free_daily_gifts?.isExternal,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.DAILY_BONUS)
		},
		{
			image: images.spec_landing_page__section_4_image.src,
			title: links.winners.title,
			content:
				"Celebrate the champions of Tiki Solitaire TriPeaks! See the latest winners and their incredible scores. Join the competition and become a winner too!",
			linkHref: links.winners.path,
			linkText: links.winners.title,
			linkIsExternal: !!links.winners?.isExternal,
			analytics: links.winners.analytics_key,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.WINNERS)
		},
		{
			image: images.spec_landing_page__section_6_image.src,
			title: links.shop.title,
			content:
				"Explore our exclusive offers on premium products and elevate your shopping experience!",
			linkHref: links.shop.path,
			linkText: links.shop.title,
			linkIsExternal: !!links.shop?.isExternal,
			analytics: links.shop.analytics_key,
			isEnabled: true
		},
		{
			image: images.spec_landing_page__section_2_image.src,
			title: links.news.title,
			content:
				"Stay up to date with the latest news and updates from Tiki Solitaire TriPeaks. Read about new features, events, and special offers. Stay informed!",
			linkHref: links.news.path,
			linkText: links.news.title,
			linkIsExternal: !!links.news?.isExternal,
			analytics: links.news?.analytics_key,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.NEWS)
		},
		{
			image: images.spec_landing_page__section_5_image.src,
			title: links.merch_shop.title,
			content:
				"Explore our official merchandise shop for Tiki Solitaire TriPeaks. Find exclusive items, apparel, and collectibles. Shop for your favorite Tiki gear today!",
			linkHref: "https://merch.solitairetripeaks.com/",
			linkText: links.merch_shop.title,
			analytics: "spring_merch_shop_link_click",
			linkIsExternal: true,
			openExternalSameTab: true,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.MERCH_SHOP)
		},
		{
			image: images.spec_landing_page__section_3_image.src,
			title: links.forum.title,
			content:
				"Join the Tiki Solitaire TriPeaks Forum to meet and converse with other players! Ask questions, find new Clubs, take part in our monthly fun questions (where you can win big prizes), and so much more!",
			linkHref: links.forum.path,
			linkText: links.forum.title,
			analytics: links.forum.analytics_key,
			linkIsExternal: !!links.forum?.isExternal,
			isEnabled: false
		}
	];

	return sections;
};
