import { Dispatch, SetStateAction } from "react";
import { IDebugLogger } from "../../../utils/debugLogger";

const getLogout = ({
	setFbAccessToken,
	setProfileImage,
	setUserId,
	debug
}: {
	setFbAccessToken: Dispatch<SetStateAction<string | null>>;
	setProfileImage: Dispatch<SetStateAction<string | null>>;
	setUserId: Dispatch<SetStateAction<string>>;
	debug: IDebugLogger;
}) => {
	const logout = async () => {
		debug.logFunction({
			iconText: "/FacebookProvider/api/logout:",
			message: [
				"\nsetLogoutState:",
				"[fbAccessToken, fbProfileImage, fbUserId]",
				null,
				null,
				""
			]
		});

		(window.FB as fb.FacebookStatic)?.logout((response: fb.StatusResponse) => {
			debug.log({
				iconText: "logout -> FB.logout:",
				message: ["\nresponse:", response]
			});
		});
		setFbAccessToken(null);
		setProfileImage(null);
		setUserId("");
		localStorage.removeItem("store.solitairetripeaks.com:fbAccessToken");
	};
	return logout;
};

export default getLogout;
