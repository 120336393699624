import { SxProps, Theme } from "@mui/material";

export const IconButtonStyled: SxProps<Theme> = {
	position: "absolute",
	right: 4,
	top: 4,
	zIndex: 1,
	color: (theme) => theme.palette.grey[500],
	"&, & *, *": {
		transition: "all 0.3s linear"
	}
};

export const CancelIconStyled: SxProps<Theme> = {
	color: "black",
	opacity: "0.5",
	backgroundColor: "white",
	borderRadius: "13px",
	width: "30px",
	height: "30px",
	"&:hover": {
		opacity: 0.9
	}
};
