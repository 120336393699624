import { PoisBountyStatus } from "interfaces";
import { createContext, useContext } from "react";

interface IPoisContext {
	status: PoisBountyStatus;
	last: boolean | undefined;
	target: number;
}

export const StepContext = createContext<IPoisContext>({
	last: false,
	status: "locked",
	target: 0
});

export const useStepContext = (): IPoisContext => {
	const context = useContext(StepContext);
	if (!context) {
		throw new Error("You need a StepContext Provider");
	}
	return context;
};
