import { PoisBountyStatus } from "interfaces";
import { LockIcon, SuccessIcon } from "./styles";
import { useImagePath } from "hooks";

interface StatusIconProps {
	status: PoisBountyStatus;
}

export const StatusIcon = ({ status }: StatusIconProps) => {
	const { getImage } = useImagePath("local");

	switch (status) {
		case "locked":
			return (
				<LockIcon>
					<img />
				</LockIcon>
			);
		case "unlocked":
			return (
				<SuccessIcon>
					<img src={getImage("/spec_pois_bounty/pois_bounty_icon_check.png")} />
				</SuccessIcon>
			);
		default:
			return null;
	}
};
