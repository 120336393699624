import Dialog from "@mui/material/Dialog";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch } from "hooks";
import { onCloseDIP, onDIPtrigger } from "redux/slices";

import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

import { BundleItem } from "./variants/BundleItem";
import { SingleItem } from "./variants/SingleItem";
import { dialogSxProps } from "./styled";
import { WsDialogs } from "interfaces";
import { useRewardItems } from "./hooks/useRewardItems";

export type LoyaltyRewardItem =
	// [id: string]: number;
	Map<string, number> | never[];

export interface ILoyaltyRewardBundleItem {
	id: string;
	amount: number;
	imageUrl: string;
	pluralLabel: string;
	singularLabel: string;
}

const DialogLoyaltyPurchaseConfirm = ({
	show,
	onCloseClick
}: {
	show: boolean;
	// eslint-disable-next-line
	onCloseClick: (event?: any, reason?: string) => void;
}) => {
	const dispatch = useAppDispatch();

	const { wsLoyaltyBuy, authToken, handleRefresh, setCurrentOffer } =
		useWebStoreContext();

	const { reportEvent } = useGoogleAnalyticsContext();
	const { openDialog, dialogState } = useDialogModalRedux();
	const { items } = dialogState;
	const { hasRewardItems, rewardItems } = useRewardItems(items);
	const cbClose = () => {
		// setIsCurrentOffer(false);
		setCurrentOffer(null);
	};
	const onClickBuy = async (offerId: string) => {
		// reportLoyaltyPurchaseEvent(
		// 	"loyalty_purchase_confirmed",
		// 	userTracker?.id as string,
		// 	endTime,
		// 	offerId,
		// 	rewardId,
		// 	priority,
		// 	price,
		// 	campaignGroup,
		// 	sectionName
		// );
		if (authToken?.value) {
			const success = await wsLoyaltyBuy(offerId ?? "");
			console.log("SUCCESS:", success);
			if (success) {
				openDialog(WsDialogs.LOYALTY_PURCHASE_SUCCESS, { cbClose });

				reportEvent("dialog_shown", {
					source: "purchase_success_dialog"
				});
				handleRefresh();
				dispatch(onCloseDIP());
				dispatch(onDIPtrigger("loyaltyPurchaseSuccess"));
				return;
			}
			cbClose();
			dispatch(onDIPtrigger("purchaseFailed"));

			//NOTE:bfloyd error cases already handled for undefined/null invoice
		}
	};
	/**/

	const itemProps = {
		onCloseClick,
		onClickBuy,
		rewardItems
	};
	return (
		<Dialog
			open={show}
			onClose={onCloseClick}
			style={{ zIndex: 9999999 }}
			sx={dialogSxProps}
		>
			{hasRewardItems ? (
				<BundleItem {...itemProps} />
			) : (
				<SingleItem {...itemProps} />
			)}
		</Dialog>
	);
};

export { DialogLoyaltyPurchaseConfirm };
