export const PREFIX = "sticky-sale-tag";

export const LS_CLOSED_STICKY_TAGS =
	"store.solitairetripeaks.com:closedStickyTags";
export const LS_DOT_MEGAMENU_HIDDEN_AT =
	"store.solitairetripeaks.com:hamburgerDotHiddenAt";

type TagStored = { id: string; date: Date }[];

export const setStickyLocalStorage = (id: string): void => {
	const tags: TagStored = [];
	const newTag = { id, date: new Date() };
	const tagStored = localStorage.getItem(LS_CLOSED_STICKY_TAGS);

	if (tagStored) {
		const tagStoredParsed = JSON.parse(tagStored) as TagStored;
		tags.push(...tagStoredParsed);
		tags.push(newTag);
	} else {
		tags.push(newTag);
	}

	localStorage.setItem(LS_CLOSED_STICKY_TAGS, JSON.stringify(tags));
};

export const getStickiesFromLocalStorage = (): TagStored => {
	const stickies = localStorage.getItem(LS_CLOSED_STICKY_TAGS);
	if (stickies) {
		return JSON.parse(stickies);
	}
	return [];
};
