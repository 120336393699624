interface Category {
	id: number;
	description: string;
}

export const categories: Category[] = [
	{
		id: 1,
		description: "Sections"
	},
	{
		id: 2,
		description: "Learn More"
	}
];
