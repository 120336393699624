import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectDipState, sendDipAction } from "redux/slices";
import { CancelIconStyled, IconButtonStyled } from "./styles";

interface CloseProps {
	onClose: () => void;
}

export const Close: React.FC<CloseProps> = ({ onClose }) => {
	const dispatch = useAppDispatch();
	const { currentDip } = useAppSelector(selectDipState);

	return (
		<IconButton
			className="dip-close-button"
			aria-label="close"
			sx={IconButtonStyled}
			onClick={() => {
				onClose();
				dispatch(
					sendDipAction({
						dipId: currentDip?.dip.id,
						actionType: "close",
						buttonId: ""
					})
				);
			}}
		>
			<CancelIcon sx={CancelIconStyled} />
		</IconButton>
	);
};
