import { DefaultSvgProps } from ".";

export const CrownMarker = ({ className }: DefaultSvgProps) => (
	<svg
		className={className}
		width="30"
		height="30"
		viewBox="0 0 40 40"
		fill="none"
	>
		<circle cx="20" cy="20" r="17" fill="white" />
		<circle cx="20" cy="20" r="17" fill="white" />
		<circle cx="20" cy="20" r="17" fill="white" />
		<circle
			cx="20"
			cy="20"
			r="18.5"
			stroke="black"
			strokeOpacity="0.4"
			strokeWidth="3"
		/>
		<g filter="url(#filter0_i_673_22299)">
			<path
				d="M31.1747 14.2715C30.1708 13.3675 28.6477 13.4916 27.7649 14.5196C26.8822 15.5476 27.0034 17.1073 28.0073 18.0112C27.6957 19.0215 26.0168 20.9179 24.0091 20.6698C21.759 20.3685 21.1705 17.2136 21.1532 14.6614C21.9148 14.236 22.4167 13.4207 22.4167 12.4813C22.4167 11.1166 21.3436 10 19.9936 10C18.6608 10 17.5704 11.0989 17.5704 12.4813C17.5704 13.4207 18.0897 14.236 18.8339 14.6614C18.8166 17.1959 18.2281 20.3507 15.9781 20.6521C13.953 20.9179 12.2914 19.0037 11.9799 17.9935C12.9837 17.0896 13.0876 15.5299 12.2222 14.5019C11.3395 13.4739 9.81634 13.3675 8.81246 14.2537C7.82589 15.1576 7.72205 16.7174 8.58746 17.7453C9.00285 18.2416 9.60864 18.5429 10.2664 18.5961L12.551 29H19.9936H27.4361L29.7208 18.5961C30.3612 18.5606 30.967 18.2593 31.3997 17.7453C32.2824 16.7174 32.1785 15.1576 31.1747 14.2715Z"
				fill="#414141"
			/>
		</g>
		<defs>
			<filter
				id="filter0_i_673_22299"
				x="8"
				y="6"
				width="24"
				height="29"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="2" />
				<feGaussianBlur stdDeviation="1" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0"
				/>
				<feBlend
					mode="normal"
					in2="shape"
					result="effect1_innerShadow_673_22299"
				/>
			</filter>
		</defs>
	</svg>
);

export const CrownMarkerHighlighted = ({ className }: DefaultSvgProps) => (
	<svg
		className={className}
		width="30"
		height="30"
		viewBox="0 0 40 40"
		fill="none"
	>
		<circle cx="20" cy="20" r="17" fill="white" />
		<circle cx="20" cy="20" r="17" fill="white" />
		<circle cx="20" cy="20" r="17" fill="white" />
		<circle
			cx="20"
			cy="20"
			r="18.5"
			stroke="black"
			strokeOpacity="0.4"
			strokeWidth="3"
		/>
		<path
			d="M31.1747 14.2715C30.1708 13.3675 28.6477 13.4916 27.7649 14.5196C26.8822 15.5476 27.0034 17.1073 28.0073 18.0112C27.6957 19.0215 26.0168 20.9179 24.0091 20.6698C21.759 20.3685 21.1705 17.2136 21.1532 14.6614C21.9148 14.236 22.4167 13.4207 22.4167 12.4813C22.4167 11.1166 21.3436 10 19.9936 10C18.6608 10 17.5704 11.0989 17.5704 12.4813C17.5704 13.4207 18.0897 14.236 18.8339 14.6614C18.8166 17.1959 18.2281 20.3507 15.9781 20.6521C13.953 20.9179 12.2914 19.0037 11.9799 17.9935C12.9837 17.0896 13.0876 15.5299 12.2222 14.5019C11.3395 13.4739 9.81634 13.3675 8.81246 14.2537C7.82589 15.1576 7.72205 16.7174 8.58746 17.7453C9.00285 18.2416 9.60864 18.5429 10.2664 18.5961L12.551 29H19.9936H27.4361L29.7208 18.5961C30.3612 18.5606 30.967 18.2593 31.3997 17.7453C32.2824 16.7174 32.1785 15.1576 31.1747 14.2715Z"
			fill="url(#paint0_linear_673_22662)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_673_22662"
				x1="20"
				y1="10"
				x2="20"
				y2="29"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.151042" stopColor="#FFD338" />
				<stop offset="0.625" stopColor="#F2BF08" />
			</linearGradient>
		</defs>
	</svg>
);

export const Crown = () => (
	<svg width="15" height="14" viewBox="0 0 12 11" fill="none">
		<path
			d="M11.5873 2.74813C11.0854 2.27239 10.3238 2.33769 9.88247 2.87873C9.44111 3.41978 9.50169 4.24067 10.0036 4.71642C9.84786 5.24813 9.00841 6.24627 8.00453 6.11567C6.8795 5.95709 6.58526 4.29664 6.57661 2.95336C6.95739 2.72948 7.20835 2.30037 7.20835 1.80597C7.20835 1.08769 6.6718 0.5 5.99678 0.5C5.33041 0.5 4.78521 1.07836 4.78521 1.80597C4.78521 2.30037 5.04483 2.72948 5.41696 2.95336C5.4083 4.28731 5.11406 5.94776 3.98903 6.10634C2.9765 6.24627 2.14571 5.23881 1.98993 4.70709C2.49187 4.23134 2.54379 3.41045 2.11109 2.8694C1.66973 2.32836 0.908169 2.27239 0.406231 2.73881C-0.0870528 3.21455 -0.138977 4.03545 0.293728 4.57649C0.501426 4.83769 0.80432 4.99627 1.13318 5.02425L2.27552 10.5H5.99678H9.71804L10.8604 5.02425C11.1806 5.0056 11.4835 4.84701 11.6998 4.57649C12.1412 4.03545 12.0893 3.21455 11.5873 2.74813Z"
			fill="white"
		/>
	</svg>
);
