import background from "assets/pois-bounty/bg-pop-up.jpg";
import classNames from "classnames";
import useBreakpoints from "hooks/useBreakpoints";
import { Carousel, Grid } from "../PB-common/learn-more-ftue";
import {
	Background,
	Alignment,
	Body,
	Container,
	Head,
	Footer,
	Copy,
	Note,
	Bold
} from "./styles";

interface PBLearnMoreProps {}

export const PBLearnMore: React.FC<PBLearnMoreProps> = () => {
	const { isSmDown } = useBreakpoints();
	return (
		<Container
			className={classNames([isSmDown && "mobile", !isSmDown && "landscape"])}
		>
			<Head>
				<Background src={background} />
			</Head>
			<Alignment className="alignment">
				<Body>{isSmDown ? <Carousel /> : <Grid />}</Body>
				<Footer>
					<Copy className="copy">
						<Note className="note">Please Note:</Note>
						<Bold>
							Loyalty Points are not being deducted for Poi’s Bounty
							progression.
						</Bold>{" "}
						Only Loyalty Points accumulated during the sale are counted towards
						your progression.
					</Copy>
				</Footer>
			</Alignment>
		</Container>
	);
};
