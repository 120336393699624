import React from "react";
import { Grid } from "@mui/material";
import { BundleStyledBox } from "../styled";
import { ILoyaltyRewardBundleItem } from "..";

interface IBundleItemsGrid {
	rewardItems: ILoyaltyRewardBundleItem[];
}

export const BundleItemsGrid = ({ rewardItems }: IBundleItemsGrid) => {
	const bundleStyledBoxSxProps = {
		marginRight: 2,
		width: rewardItems.length === 4 ? "100%" : "25%"
	};
	const getGridPropsIfNeeded = () => {
		switch (rewardItems.length) {
			case 4:
				return {
					display: "grid",
					gridTemplateColumns: "33% 33%",
					gridTemplateRows: "55% 55%"
				};
			default:
				return {};
		}
	};
	return (
		<Grid
			container
			sx={{
				flexWrap: "wrap",
				lineHeight: 1,
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "stretch",
				alignContent: "stretch",
				fontFamily: "Baloo",
				...getGridPropsIfNeeded()
			}}
		>
			{rewardItems.map((rewardItem) => {
				return (
					<BundleStyledBox key={rewardItem.id} sx={bundleStyledBoxSxProps}>
						<img src={rewardItem.imageUrl} alt={rewardItem.id} />
						<span>{rewardItem.amount.toLocaleString("en-US")}</span>
					</BundleStyledBox>
				);
			})}
		</Grid>
	);
};
