import { PREFIX } from "./helper";
import service from "services/api";
import { GiftsGaloreResponse } from "interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITrackerData } from "./types";

export const postUserTrackerValue = createAsyncThunk(
	`${PREFIX}/postTracker`,
	async (
		trackerData: ITrackerData
	): Promise<GiftsGaloreResponse | undefined> => {
		try {
			const response = await service.Post<GiftsGaloreResponse>(
				`/rest/user/tracker/metadataValue?key=${trackerData.key}&value=${trackerData.value}`
			);
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);
