import { useState, useEffect, useCallback } from "react";

/* eslint-disable */
interface State {
	isLoading: boolean;
	error: any;
	data: any;
}
/* eslint-enable */

const initialState: State = {
	isLoading: true,
	error: null,
	data: undefined
};

/* eslint-disable */
export const useControllerQuery = (
	dependencies: Array<any>,
	controllerCallback: () => Promise<any>
) => {
	const [values, setValues] = useState(initialState);

	const loadData = useCallback(() => {
		setValues({
			...initialState
		});

		controllerCallback()
			.then((response) => {
				setValues({
					...initialState,
					isLoading: false,
					data: response.data
				});
			})
			.catch((err) => {
				setValues({
					...initialState,
					isLoading: false,
					error: err.message
				});
			});
	}, [...dependencies]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return {
		...values,
		setValues
	};
};

/* eslint-enable */
