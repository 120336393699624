import { DisplayItem, RewardType } from "components/DIP/types";
import { Rewards } from "interfaces";

export const getDisplayRewards = (
	rewardId: string,
	{ rewards, rewardTypes }: { rewards: Rewards; rewardTypes: RewardType[] }
) => {
	const items = rewards[rewardId]?.items;
	const displayRewards: DisplayItem["rewards"] = [];
	if (!items) {
		return [];
	}
	for (const [rewardItemId, units] of Object.entries(items)) {
		rewardTypes.forEach(({ id, imageUrl }) => {
			if (id === rewardItemId) {
				displayRewards.push({
					id,
					imageUrl: imageUrl,
					description: units.toLocaleString("en-US")
				});
			}
		});
	}

	return displayRewards;
};
