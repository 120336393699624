import { baseApi } from "../api";

export const endlessOfferApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		claimReward: builder.mutation({
			query: (body) => {
				const queryParams = new URLSearchParams();
				for (const key in body) {
					queryParams.append(key, body[key]);
				}
				return {
					url: `/rest/endless-offer/claim-reward?${queryParams.toString()}`,
					method: "POST"
					//body
				};
			}
			//invalidatesTags: [{ type: "featureTrackerTag", id: "endless-offer" }]
		}),
		completeNodes: builder.mutation({
			query: (body) => {
				const queryParams = new URLSearchParams();
				for (const key in body) {
					queryParams.append(key, body[key]);
				}
				return {
					url: `/rest/debug/endless-offer/complete-nodes?${queryParams.toString()}`,
					method: "POST"
					//body
				};
			},
			invalidatesTags: [{ type: "featureTrackerTag", id: "endless-offer" }]
		})
	})
});

export const { useClaimRewardMutation, useCompleteNodesMutation } =
	endlessOfferApi;
