import { LoginType } from "../../types";
import AbstractLoginRequest from "../types";

export default class AutoLoginRequest extends AbstractLoginRequest {
	constructor(type: string, token: string) {
		super(LoginType.Auto, {
			type: type,
			autoLoginToken: token
		});
	}
}
