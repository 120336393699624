import {
	CardContainer,
	DataContainer,
	FinalMessage,
	ComeBackMessage,
	CardImage,
	ItemContainer
} from "./styles";
import lock from "../../../../assets/Lock.svg";
import lockSmall from "../../../../assets/Lock_small.svg";
import Button from "../../../../components/Buttons/ThemeButton";
import BonusFlag from "../../../../components/BonusFlag";
import { useWebStoreContext } from "../../../../contexts/WebStoreProvider";
import { CardIconContainer, CardSubTitle, CardTitle } from "../styles";
import moment from "moment";
import { getFreeGift, unlockFreeGift } from "../../Controllers/giftController";
import { useState, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import CountdownV2 from "components/CountdownV2";
import { FreeGiftCardSkeleton } from "./skeleton";
import { ClubFreeGift } from "interfaces/web-store/club-free-gift";
import { selectWebStoreState } from "redux/slices";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { links } from "helpers";

/* eslint-disable */
enum Status {
	UnlockClickRequired = "UnlockClickRequired",
	UnlockClicked = "UnlockClicked",
	Unlocked = "Unlocked",
	Expired = "Expired",
	Error = "Error"
}
/* eslint-enable */

export const FreeGiftCard = () => {
	const { store } = useAppSelector(selectWebStoreState);
	const { syncUserTracker, nowTime, handleErrorResponse } =
		useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	// eslint-disable-next-line
	const [view, setView] = useState<any>();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const {
		isLoading,
		data: clubFreeGift,
		refetch,
		isError
	} = useQuery<ClubFreeGift, Error>({
		queryKey: ["free_gift"],
		queryFn: getFreeGift,
		refetchInterval: 5000,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true
	});

	const additionalInfo = [
		{
			status: Status.UnlockClickRequired,
			image: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_unlock_click_required"
			)?.imageUrl,
			imageWide: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_unlock_click_required_wide"
			)?.imageUrl,
			message: "Time left:"
		},
		{
			status: Status.UnlockClicked,
			image: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_unlock_clicked"
			)?.imageUrl,
			imageWide: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_unlock_clicked_wide"
			)?.imageUrl,
			message: "Time left:"
		},
		{
			status: Status.Unlocked,
			image: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_unlocked"
			)?.imageUrl,
			imageWide: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_unlocked_wide"
			)?.imageUrl,
			message: "Next Gift in:"
		},
		{
			status: Status.Expired,
			image: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_expired"
			)?.imageUrl,
			imageWide: store?.globalImages?.find(
				(image) => image.id === "club_free_gift_expired_wide"
			)?.imageUrl,
			message: "Next Gift in:"
		}
	];

	const mutation = useMutation<ClubFreeGift, Error>({
		mutationFn: () => {
			return unlockFreeGift();
		},
		onSuccess: (data: ClubFreeGift) => {
			if (data?.status === Status.Error) {
				refetch();
			} else {
				queryClient.setQueryData(["free_gift"], data);
			}
			if (data.unlockClicksNeeded) {
				if (data.unlockClicksNeeded === 0) {
					reportEvent("unlock_free_gift_click", { source: "club_shop_page" });
				} else if (data.unlockClicksNeeded > 0) {
					reportEvent("free_gift_click", { source: "club_shop_page" });
				}
			}
		},
		onError: (error) => {
			handleErrorResponse(error.message);
		}
	});

	const getUnlockTime = () => {
		return moment
			.duration(clubFreeGift?.unlockDurationMinutes, "minutes")
			.humanize();
	};

	const handleEnd = () => {
		setTimeout(() => {
			queryClient.refetchQueries(["free_gift"]);
		}, 1000);
	};

	const handleUnlock = async () => {
		mutation.mutate();
	};

	const handleNotInClub = async () => {
		const userTrackerResponse = await syncUserTracker();
		if (userTrackerResponse) {
			const latestUserTracker = userTrackerResponse.userTracker;
			if (!latestUserTracker.clubId) {
				navigate(links.home.path);
			}
		}
	};

	useEffect(() => {
		setView({
			...clubFreeGift,
			...additionalInfo.find((info) => info.status === clubFreeGift?.status)
		});
	}, [clubFreeGift]);

	if (clubFreeGift?.status === Status.Error || isError) {
		if (clubFreeGift?.code === "NotClubMember") {
			handleNotInClub();
			return <FreeGiftCardSkeleton />;
		}
		return <></>;
	}

	if (isLoading) {
		return <FreeGiftCardSkeleton />;
	}

	return (
		<CardContainer color="#EBF4FF" image={view?.imageWide}>
			{view?.unlockClicksNeeded !== view?.numClicksToUnlock && (
				<CountdownV2
					text={view?.message}
					startTime={nowTime}
					endTime={
						view?.status === Status.UnlockClickRequired ||
						view?.status === Status.UnlockClicked
							? view?.endDate
							: view?.nextAvailableStartDate
					}
					onEnd={handleEnd}
					status={view?.status}
				/>
			)}
			<CardImage src={view?.image}></CardImage>
			<BonusFlag text="FREE" position="right" />
			<DataContainer>
				{view?.status === Status.UnlockClickRequired ||
				view?.status === Status.UnlockClicked ? (
					<>
						<CardTitle>Club Free Gift</CardTitle>
						{view?.unlockClicksNeeded === view?.numClicksToUnlock && (
							<CardSubTitle>Unlock time: {getUnlockTime()}</CardSubTitle>
						)}
						<ItemContainer>
							<CardIconContainer>
								<img
									src={lock}
									style={{
										maxHeight: "60px"
									}}
								/>
							</CardIconContainer>
							<CardSubTitle>
								Earn when {view?.unlockClicksNeeded} members tap UNLOCK
							</CardSubTitle>
						</ItemContainer>
						<FinalMessage>
							x{view?.numClicksToUnlock} needed to unlock
							<img
								src={lockSmall}
								style={{
									marginLeft: "5px"
								}}
							/>
						</FinalMessage>
					</>
				) : view?.status === Status.Unlocked ? (
					<>
						<CardTitle>
							Your Club successfully unlocked the Free Club Gift!
						</CardTitle>
						<ComeBackMessage>
							Be sure to collect your gift in your TriPeaks Inbox!
						</ComeBackMessage>
					</>
				) : view?.status === Status.Expired ? (
					<>
						<CardTitle>Not enough members tapped UNLOCK in time</CardTitle>
						<ComeBackMessage>Come back and try again soon!</ComeBackMessage>
					</>
				) : (
					<>
						<label>Status: {view?.status}</label>
						<label>Code: {view?.code}</label>
					</>
				)}
				{view?.status !== Status.Unlocked && (
					<Button
						variant="contained"
						color="success"
						size="large"
						sx={{
							width: "100%",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem",
							fontSize: "18.5px",
							Weight: "700",
							height: "57px"
						}}
						disabled={
							view?.status !== Status.UnlockClickRequired || mutation.isLoading
						}
						onClick={handleUnlock}
					>
						Help Unlock Free Gift
					</Button>
				)}
			</DataContainer>
		</CardContainer>
	);
};
