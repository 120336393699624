import EnvProvider from "./contexts/EnvProvider";
import AppContent from "./AppContent";
import useClearStorage from "./hooks/useClearStorage";
import { Helmet } from "react-helmet";

const envProviderProps: { backendUri?: string; cdnPrefix?: string } = {};
if (process.env.REACT_APP_BACKEND_URI) {
	envProviderProps.backendUri = `${process.env.REACT_APP_BACKEND_URI}`;
}
if (process.env.REACT_APP_CDN_PREFIX) {
	envProviderProps.cdnPrefix = `${process.env.REACT_APP_CDN_PREFIX}`;
}

function App(): JSX.Element {
	const { isClearStorageEnabled } = useClearStorage();
	if (isClearStorageEnabled) {
		localStorage.clear();
		sessionStorage.clear();
	}
	return (
		<EnvProvider {...envProviderProps}>
			{/* SSR needed for share meta tags to work when shared (see backend/src/index) */}
			<Helmet>
				<meta
					name="description"
					content="The Official Website for Tiki Solitaire TriPeaks! Shop for the best deals on Coins and Boosters, read up on the latest news, check out recent winners, and take part in SAVINGS events!"
				/>
				<title>Tiki Solitaire TriPeaks</title>
				<meta property="og:url" content="https://play.solitairetripeaks.com" />
				<meta property="og:type" content="article" />
				<meta
					property="og:title"
					content="Tiki Solitaire TriPeaks Website - Best Deals on Coins, Boosters, and More"
				/>
				<meta
					property="og:description"
					content="Come for the deals, stay for the fun! The Tiki Solitaire TriPeaks website offers the best deals on coins, boosters, club gifts, and more. Play now and get your free daily rewards!"
				/>
				<meta
					property="og:image"
					content={`${envProviderProps.cdnPrefix}/fb_share/fb_link_02.jpeg`}
				/>
			</Helmet>
			<AppContent />
		</EnvProvider>
	);
}

export default App;
