export const sectionContainerSxProps = {
	paddingTop: "3rem"
};

export const sectionRowImageCellSxProps = {
	img: {
		maxWidth: "100%",
		marginBottom: { xs: 0, sm: "-18px" },
		paddingTop: { xs: 0, sm: "18px" }
	}
};

export const sectionRowContentCellSxProps = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "left",
	textAlign: "left"
};

export const sectionContentTitleSxProps = {
	fontWeight: "800",
	fontSize: { xs: "35px", lg: "40px", xl: "2.8vw" },
	lineHeight: "1.1",
	display: "flex",
	alignItems: "center",
	color: "#333333"
};

export const sectionContentTextSxProps = {
	fontWeight: "400",
	fontSize: {
		xs: "16px",
		md: "18px",
		lg: "1.2vw"
	},
	lineHeight: "1.33",
	letterSpacing: "-0.015em",
	color: "rgba(51, 51, 51, 0.8)",
	margin: "1rem 0"
};
