import { Card, Grid, Title } from "./styles";
import { useMediaQuery } from "@mui/material";
import { DailyLoginStreakContext } from "./context";
import { DailyLoginStreakTracker, StreakDay } from "interfaces";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { DailyProgress, Free, Illustrations, Timer } from "./lib";
import { onDIPtrigger, selectDailyLoginStreakState } from "redux/slices";
import { getVariantImage } from "utils/getVariantImage";
import { useWebStoreContext } from "contexts";
import backgroundLogin from "assets/daily-gift/background-login.png";
import backgroundLoginMobile from "assets/daily-gift/background-login-mobile.png";
import titleLogin from "assets/daily-gift/daily_login_streak_title_v4_large.png";
import titleLoginMobile from "assets/daily-gift/daily_login_streak_title_v4_small.png";
import Button from "components/Buttons/ThemeButton";
import moment from "moment";
import { DailyGiftCardSkeleton } from "./lib/skeleton";

interface DailyLoginStreakProps {}

export const DailyLoginStreak: React.FC<DailyLoginStreakProps> = () => {
	const { nowTime } = useWebStoreContext();
	const { tracker } = useAppSelector(selectDailyLoginStreakState);
	const { authToken, setCurrentOffer, setActiveItemToBeClicked } =
		useWebStoreContext();
	//const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width: 649px)");
	const { openLoginToContinueDialog } = useWebStoreContext();
	const values = useMemo(
		() => ({ tracker: tracker as DailyLoginStreakTracker }),
		[tracker]
	);
	const dispatch = useAppDispatch();

	const nextCollectionDay = tracker?.streakDays.find((day: StreakDay) => {
		return tracker?.nextCollectionDate === day.startOfDay;
	});

	const currentCollectionDay = tracker?.streakDays.find((day: StreakDay) => {
		return tracker?.now <= day.endOfDay;
	});

	const getTitleUrl = () => {
		if (!authToken?.value) {
			if (isMobile) {
				return titleLoginMobile;
			}
			return titleLogin;
		}

		if (!currentCollectionDay || !nowTime) {
			return;
		}

		const url = tracker?.isStreakBroken
			? nextCollectionDay?.brokenStreakTitleImageUrl
			: moment(tracker?.streakDays.slice(-1)[0].startOfDay) <= nowTime
			? tracker?.streakDays.slice(-1)[0].titleImageUrl
			: tracker?.streakDays[currentCollectionDay.dayIndex].titleImageUrl;

		return getVariantImage(
			url || "",
			"{daily_bonus_streak_variant}",
			isMobile ? "small" : "large"
		);
	};

	const getBackgroundUrl = () => {
		if (!authToken?.value) {
			if (isMobile) {
				return backgroundLoginMobile;
			}
			return backgroundLogin;
		}
		const url =
			nextCollectionDay?.backgroundImageUrl ||
			tracker?.streakDays.slice(-1)[0].backgroundImageUrl;

		return getVariantImage(
			url || "",
			"{daily_bonus_streak_variant}",
			isMobile ? "small" : "large"
		);
	};

	const handleLogin = () => {
		setCurrentOffer(null);
		setActiveItemToBeClicked(null);
		openLoginToContinueDialog();
		//dispatch(onOpenPopUp("daily-login-earned-reward")); //example
	};

	useEffect(() => {
		dispatch(onDIPtrigger("dailyGift"));
	}, []);

	if (!tracker && authToken?.value) {
		return <DailyGiftCardSkeleton />;
	}

	return tracker && authToken?.value ? (
		<DailyLoginStreakContext.Provider value={values}>
			<Card background={getBackgroundUrl()} isConnected={!!authToken?.value}>
				<Title
					src={getTitleUrl()}
					isStreakBroken={tracker?.isStreakBroken}
					isConnected={!!authToken?.value}
				/>
				<Free />
				<Timer />
				<Grid>
					<Illustrations />
					<DailyProgress />
				</Grid>
			</Card>
		</DailyLoginStreakContext.Provider>
	) : !authToken?.value ? (
		<Card background={getBackgroundUrl()} isConnected={!!authToken?.value}>
			<Title
				src={getTitleUrl()}
				style={{ top: "15%" }}
				isConnected={!!authToken?.value}
			/>
			<Free />
			<Button
				variant="contained"
				themeColor="secondary"
				onClick={() => handleLogin()}
				style={{
					width: isMobile ? "90%" : "320px",
					bottom: !isMobile ? "5%" : "10%",
					position: "absolute",
					fontSize: "20px"
				}}
			>
				{"Let's go!"}
			</Button>
		</Card>
	) : (
		<></>
	);
};

export * from "./context";
