/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */
import { useState, useEffect, SetStateAction } from "react";

export function useScroll() {
	const [lastScrollTop, setLastScrollTop] = useState(0);
	const [bodyOffset, setBodyOffset] = useState(
		document.body.getBoundingClientRect()
	);
	const [scrollY, setScrollY] = useState<SetStateAction<number>>(
		bodyOffset.top
	);
	const [scrollX, setScrollX] = useState<SetStateAction<number>>(
		bodyOffset.left
	);
	const [scrollDirection, setScrollDirection] =
		useState<SetStateAction<string>>();

	// eslint-disable-next-line
	const listener = (e: any) => {
		setBodyOffset(document.body.getBoundingClientRect());
		setScrollY(-bodyOffset.top);
		setScrollX(bodyOffset.left);
		setScrollDirection(lastScrollTop > -bodyOffset.top ? "up" : "down");
		setLastScrollTop(-bodyOffset.top);
	};

	useEffect(() => {
		window.addEventListener("scroll", listener);
		return () => {
			window.removeEventListener("scroll", listener);
		};
	});

	return {
		scrollY,
		scrollX,
		scrollDirection
	};
}
