import { IDebugLogger } from "../../utils/debugLogger";
import { DependencyList } from "react";

/* eslint-disable-next-line */
type MessageEntry = any;
type MessageParam = MessageEntry[] | MessageEntry;

export interface ILogger {
	logWithTitle(title: string, message: MessageParam): void;
	log(message: MessageEntry[]): void;
	logEffect(
		effectName: string,
		dependenciesString: string,
		dependencies: DependencyList
	): void;
}

export default class LoggerWrapper implements ILogger {
	private readonly internalLogger: IDebugLogger;
	private readonly iconTextPrefix: string;

	constructor(debugLogger: IDebugLogger, iconTextPrefix = "") {
		this.internalLogger = debugLogger;
		this.iconTextPrefix = iconTextPrefix;
	}

	private internalLog(iconText: string, message: MessageParam): void {
		if (!Array.isArray(message)) {
			message = [message];
		}
		this.internalLogger.log({
			iconText: iconText,
			message: message
		});
	}

	public logWithTitle(title: string, message: MessageParam): void {
		const iconTextSuffix = title ? ` - ${title}` : "";
		this.internalLog(this.iconTextPrefix + iconTextSuffix, message);
	}

	public log(message: MessageParam): void {
		this.logWithTitle("", message);
	}

	public logEffect(
		effectName: string,
		dependenciesString: string,
		dependencies: DependencyList
	) {
		this.internalLogger.logUseEffect({
			iconText: this.iconTextPrefix,
			message: [
				`\nuseEffect( ${effectName}, ${dependenciesString}`,
				dependencies
			]
		});
	}
}
