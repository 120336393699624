/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, styled, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import {
	IShopRewardItem,
	IShopReward,
	IShopPurchaseOfferSingleItem,
	IShopStore
} from "contexts/WebStoreProvider/types";
import { CrownIcon } from "utils/svgIcons";
import {
	Bonus,
	CrossOutLine,
	CrossOutValue,
	ImageWrap,
	Overlap,
	RewardImage,
	StyledCard,
	Title
} from "./styled";
import Button from "components/Buttons/ThemeButton";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "hooks";
import { onDIPtrigger, selectPoisBountyV2State } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { CardFinalMessage } from "styles/general_styles";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import { FirstPurchaseBanner } from "./lib/FirstPurchaseBanner";
import useRewardsById from "hooks/useRewardsById";

const OfferCard = ({
	className,
	data,
	backgroundImageUrl,
	itemRef
}: {
	itemRef?: React.MutableRefObject<HTMLDivElement | null>;
	className?: string;
	data: IShopPurchaseOfferSingleItem;
	backgroundImageUrl: string;
}): JSX.Element => {
	const {
		id: offerId,
		rewardId,
		description,
		price,
		priority,
		loyaltyPointReward,
		endTime,
		bannerText,
		sectionName,
		rewardImageUrl,
		campaignGroup,
		onlyNonPayers,
		loyaltyPointCrossout,
		rewardCrossout,
		purchaseLimit,
		firstPurchaseRewardBonus
	} = data;
	const { store, authToken, userTracker, setActiveItemToBeClicked } =
		useWebStoreContext();
	const {
		wsStoreBuy,
		openLoginToContinueDialog,
		setCurrentOffer,
		currentOffer,
		handleRefresh,
		suppressOrderConfirmation,
		postUserMetadata
	} = useWebStoreContext();
	const dispatch = useAppDispatch();
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const [isCurrentOffer, setIsCurrentOffer] = useState<boolean>(false);
	const { reportPurchaseEvent, reportEvent } = useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const buyButtonRef = useRef(null);
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const { isFeatureFlagEnabled } = useEnvContext();
	const displayRewards = useRewardsById(rewardId);

	const resetCurrentOffer = () => {
		setIsCurrentOffer(false);
		setCurrentOffer(null);
	};

	const cbClose = () => {
		resetCurrentOffer();
		reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
		handleRefresh();
		dispatch(onDIPtrigger("cache-mode"));
	};

	const firstPurchaseText = useMemo(() => {
		return (
			firstPurchaseRewardBonus &&
			store?.globalConfig
				?.find((c) => c.id === "first_purchase_badge_text")
				?.value?.replace(
					"{amount}",
					Number(firstPurchaseRewardBonus).toLocaleString("en-US")
				)
		);
	}, [firstPurchaseRewardBonus, store?.globalConfig]);

	const onClickBuy = async () => {
		// setCurrentOffer used for button states
		setCurrentOffer(buyButtonRef.current);
		if (authToken?.value) {
			const invoice = await wsStoreBuy({ offerId });
			if (invoice) {
				console.log("suppressOrderConfirmation", suppressOrderConfirmation);
				openDialog(WsDialogs.XSOLLA_PURCHASE, {
					xsollaToken: invoice.xsollaToken,
					xsollaHost: invoice.xsollaHost,
					source: "offer_card",
					cbClose,
					suppressOrderConfirmation,
					postUserMetadata,
					showClose: true,
					onPurchaseStarted: (data) => {
						reportEvent("purchase_started", {
							tuid: userTracker?.id as string,
							offer_id: offerId,
							reward_id: rewardId,
							priority: priority,
							price_in_cents: Math.round(price * 100),
							campaign_group: campaignGroup,
							section_name: sectionName,
							only_non_payers: onlyNonPayers,
							//event_time_client: eventTimeClient.toISO(),
							//minutes_left: minutesLeft,
							loyalty_point_crossout:
								typeof loyaltyPointCrossout !== "undefined",
							items_crossout: typeof rewardCrossout !== "undefined",
							firstPurchaseText,
							first_purchase_bonus: firstPurchaseText,
							invoice_id: invoice?.id,
							paystation_id: data?.payStation
						});
					},
					onPurchaseSuccess: () => {
						reportEvent("purchase_success", {
							invoice_id: invoice?.id
						});
					}
				});
			} else {
				resetCurrentOffer();
			}

			//NOTE:bfloyd error cases already handled for undefined/null invoice
		} else {
			// setActiveItemToBeClicked used for re-opening after authentication
			setActiveItemToBeClicked({
				type: "store_offer",
				offerId,
				onlyNonPayers,
				purchaseLimit
			});
			openLoginToContinueDialog();
		}
	};

	useEffect(() => {
		if (currentOffer === buyButtonRef.current) {
			setIsCurrentOffer(true);
		} else {
			setIsCurrentOffer(false);
		}
	}, [currentOffer]);

	const rewardCrossoutColor = loyaltyPointCrossout ? "#A6A6A6" : "#EB5757";

	return (
		<StyledCard className={className} ref={itemRef}>
			<ImageWrap
				style={{
					backgroundImage: `url(${backgroundImageUrl})`,
					height: "180px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}
			>
				<RewardImage src={rewardImageUrl} />
				{isFeatureFlagEnabled(FeaturesFlags.FIRST_PURCHASE_BONUS) &&
					firstPurchaseText && (
						<FirstPurchaseBanner
							firstPurchaseText={`${firstPurchaseText} ${
								Number(firstPurchaseRewardBonus) > 1
									? displayRewards[0]?.pluralLabel
									: displayRewards[0]?.singularLabel
							}`}
						/>
					)}
			</ImageWrap>
			{bannerText && <Bonus>{bannerText}</Bonus>}
			<div className="content">
				<Box
					style={{
						position: "relative",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						marginBottom: ".4rem"
					}}
				>
					<Overlap>
						<CrossOutValue
							style={{ color: rewardCrossoutColor }}
							className={classnames([
								"reward-variant",
								rewardCrossout && rewardCrossout.length >= 16 && "small"
							])}
						>
							{rewardCrossout && (
								<>
									<CrossOutLine
										style={{ borderBottom: `2px solid ${rewardCrossoutColor}` }}
									/>
									{rewardCrossout}
								</>
							)}
						</CrossOutValue>
					</Overlap>
					<Title style={{ marginTop: rewardCrossout ? "-0.2rem" : 0 }}>
						{description}
					</Title>
					{showOfferId && (
						<Typography
							sx={{
								fontWeight: 700,
								color: "red",
								fontSize: "10px",
								height: "auto",
								textAlign: "center"
							}}
						>
							{offerId}
						</Typography>
					)}
				</Box>
				<Box textAlign="center">
					<Box
						sx={{
							position: "relative",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							whiteSpace: "nowrap",
							marginBottom: ".4rem"
						}}
					>
						<Overlap className="loyalty-variant">
							<CrossOutValue>
								{loyaltyPointCrossout && (
									<>
										<CrossOutLine />
										Earn
										<CrownIcon width={15} style={{ margin: "0 .2rem" }} />
										{Number(loyaltyPointCrossout).toLocaleString("en-US")}
									</>
								)}
							</CrossOutValue>
						</Overlap>
						<Typography sx={{ fontWeight: 700, color: "#6D6D6D" }}>
							Earn
							<CrownIcon
								style={{ marginBottom: -2, marginLeft: 3, marginRight: 0 }}
							/>
							{` ${loyaltyPointReward.toLocaleString("en-US")}`}
						</Typography>
					</Box>
					{isPoisActive && (
						<CardFinalMessage color="#FBBD05" className="mb">
							Contribute to Poi’s Bounty
						</CardFinalMessage>
					)}
					<Button
						ref={buyButtonRef}
						variant="contained"
						color="success"
						size="large"
						onClick={onClickBuy}
						sx={{
							width: "100%",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem"
						}}
						className={classnames({
							isCurrentOffer,
							isCurrentOfferSet: !!currentOffer
						})}
					>
						{price} USD
					</Button>
				</Box>
			</div>
		</StyledCard>
	);
};

OfferCard.displayName = "OfferCard";

export { OfferCard };
