import { StreakDay } from "interfaces";
import { useWebStoreContext } from "contexts";

export const useWeekStreak = (
	streakDays: StreakDay[]
): { today: StreakDay; tomorrow: StreakDay } => {
	const { nowTime } = useWebStoreContext();
	const todayIndex = streakDays.findIndex(
		(streakDay) =>
			nowTime?.isAfter(streakDay.startOfDay) &&
			nowTime?.isBefore(streakDay.endOfDay)
	);

	return {
		today: streakDays[todayIndex],
		tomorrow: streakDays[todayIndex + 1]
	};
};
