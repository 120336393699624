export const Flag = () => (
	<svg
		width="98"
		height="29"
		viewBox="0 0 98 29"
		fill="none"
		style={{ borderTopLeftRadius: "12px" }}
	>
		<path d="M-75 0L98 0L91.8064 14.5L98 29L-75 29V0Z" fill="#EB5757"/>
	</svg>
);
