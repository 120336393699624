import { createContext, useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { getDebugLogger } from "../utils/debugLogger";
import { v4 as uuidv4 } from "uuid";
import createPersistedState from "use-persisted-state";
import { LoginGoogleAnalyticsHelper } from "../domain/Login/LoginGoogleAnalyticsHelper";
import { dataSharingService } from "./ScopelyIdentityProvider/config";
import { IUserTracker } from "./WebStoreProvider/types";

const ADS_PERSONALIZATION_KEY =
	"store.solitairetripeaks.com:adsPersonalization";
const debugColor = "PaleGreen";
const debug = getDebugLogger({
	// isEnabled: !false,
	color: debugColor
});

const useSyntheticIdState = createPersistedState<string>(
	"store.solitairetripeaks.com:syntheticId"
);

const useAdsPersonalizationState = createPersistedState<boolean>(
	ADS_PERSONALIZATION_KEY
);

const useAdsPersonalizationMetadataState =
	createPersistedState<IAdsPersonalizationMetadata>(
		"store.solitairetripeaks.com:adsPersonalizationMetadata"
	);

interface IAdsPersonalizationMetadata {
	logoutState: boolean;
	loginState: boolean;
	loginLastCheck: string;
}
interface IReportEventValues {
	event_time_client: string;
	[key: string]: string | number;
}
interface IPurchaseEventValues {
	offer_id: string;
	reward_id: string;
	priority: number;
	price_in_cents: number;
	campaign_group: string;
	section_name: string;
	only_non_payers: boolean;
	event_time_client: string;
	loyalty_point_crossout: boolean;
	items_crossout: boolean;
	first_purchase_bonus?: string;
	tuid: string;
	minutes_left: number | undefined;
	synthetic_id?: string;
}
interface ILoyaltyPurchaseEventValues {
	offer_id: string;
	reward_id: string;
	priority: number;
	loyalty_point_price: number;
	campaign_group: string;
	section_name: string;
	event_time_client: string;
	tuid: string;
	minutes_left: number | undefined;
	synthetic_id?: string;
}

export const GoogleAnalyticsContext = createContext<IGoogleAnalyticsContext>({
	reportEvent: () => {
		// empty
	},
	reportPurchaseEvent: () => {
		// empty
	},
	reportLoyaltyPurchaseEvent: () => {
		//empty
	},
	setTuidForAnalytics: () => {
		//empty
	},
	syntheticId: "",
	loginGoogleAnalyticsHelper: new LoginGoogleAnalyticsHelper(() => {
		//empty
	}),
	enableAdsPersonalization: () => {
		//empty
	},
	switchAdsPersonalizationToLogoutState: () => {
		//empty
	},
	syncRemoteAdsPersonalizationSettings: () => {
		//empty
	},
	checkAdsPersonalizationRemoteSettings: () => {
		//empty
	},
	adsPersonalization: false
});

/* eslint-disable */
export interface IGoogleAnalyticsContext {
	reportEvent(eventName: string, eventValues: any): void;
	reportPurchaseEvent(
		eventName: string,
		tuid: string,
		endTime: string,
		offerId: string,
		rewardId: string,
		priority: number,
		price: number,
		campaignGroup: string,
		sectionName: string,
		onlyNonPayers: boolean,
		loyalty_point_crossout?: boolean,
		items_crossout?: boolean,
		first_purchase_bonus?: string
	): void;
	reportLoyaltyPurchaseEvent(
		eventName: string,
		tuid: string,
		endTime: string,
		offerId: string,
		rewardId: string,
		priority: number,
		loyaltyPointPrice: number,
		campaignGroup: string,
		sectionName: string
	): void;
	setTuidForAnalytics(tuid: string | null): void;
	syntheticId: string;
	loginGoogleAnalyticsHelper: LoginGoogleAnalyticsHelper;
	enableAdsPersonalization(
		enabled: boolean,
		userTracker: IUserTracker | null
	): void;
	switchAdsPersonalizationToLogoutState(): void;
	syncRemoteAdsPersonalizationSettings(userTracker: IUserTracker): void;
	checkAdsPersonalizationRemoteSettings(userTracker: IUserTracker): void;
	adsPersonalization: boolean;
}
/* eslint-enable */

const setGtag = (
	eventName: string,
	eventValues:
		| IPurchaseEventValues
		| ILoyaltyPurchaseEventValues
		| IReportEventValues
) => {
	const gtagArgs = ["event", eventName, eventValues];
	window.gtag(...gtagArgs);
	debug.log({
		iconText: "window.gtag:",
		message: [...gtagArgs]
	});
};
function GoogleAnalyticsProvider({ children }: { children: React.ReactNode }) {
	const [tuid, setTuid] = useState<string | null>(null);
	const [syntheticId, setSyntheticId] = useSyntheticIdState("");
	const [adsPersonalization, setAdsPersonalization] =
		useAdsPersonalizationState(() => {
			if (localStorage.getItem(ADS_PERSONALIZATION_KEY)) {
				return localStorage.getItem(ADS_PERSONALIZATION_KEY) === "true";
				// @ts-ignore
			} else if (navigator.globalPrivacyControl) {
				return false;
			} else {
				return true;
			}
		});
	const [adsPersonalizationMetadata, setAdsPersonalizationMetadata] =
		useAdsPersonalizationMetadataState({
			logoutState: true,
			loginState: true,
			loginLastCheck: ""
		});

	useEffect(() => {
		if (!syntheticId) {
			const uuid = uuidv4();
			setSyntheticId(uuid);
		}
	}, []);

	const enableAdsPersonalization = (
		enabled: boolean,
		userTracker: IUserTracker | null
	): void => {
		setAdsPreferenceBasedOnCurrentState(enabled, userTracker);
		if (userTracker != null && userTracker.id != null) {
			dataSharingService.setConsentGranted(userTracker.id, enabled);
		}
	};

	const setAdsPreferenceBasedOnCurrentState = (
		enabled: boolean,
		userTracker: IUserTracker | null
	): void => {
		let consentState = "denied";
		if (enabled) {
			consentState = "granted";
		}
		window.gtag("consent", "update", {
			ad_storage: consentState,
			functionality_storage: consentState,
			personalization_storage: consentState
		});
		/* eslint-disable-next-line */
		window.gtag("set", {
			allow_google_signals: enabled,
			allow_ad_personalization_signals: enabled,
			restricted_data_processing: !enabled
		});
		setAdsPersonalization(enabled);
		if (userTracker != null && userTracker.id != null) {
			setAdsPersonalizationMetadata((prev) => ({
				logoutState: prev.logoutState,
				loginState: enabled,
				loginLastCheck: DateTime.now().setZone("America/Los_Angeles").toISO()
			}));
		} else {
			setAdsPersonalizationMetadata((prev) => ({
				logoutState: enabled,
				loginState: prev.loginState,
				loginLastCheck: prev.loginLastCheck
			}));
		}
	};

	const switchAdsPersonalizationToLogoutState = (): void => {
		setAdsPersonalization(adsPersonalizationMetadata.logoutState);
	};

	const syncRemoteAdsPersonalizationSettings = (
		userTracker: IUserTracker
	): void => {
		dataSharingService.tryForceRefresh(userTracker.id).then(() => {
			dataSharingService
				.isConsentGranted(userTracker.id)
				.then((response) =>
					setAdsPreferenceBasedOnCurrentState(response, userTracker)
				);
		});
	};

	const checkAdsPersonalizationRemoteSettings = (
		userTracker: IUserTracker
	): void => {
		if (shouldCheckAdsPersonalizationRemoteSettings()) {
			dataSharingService.tryForceRefresh(userTracker.id).then(() => {
				dataSharingService.isConsentGranted(userTracker.id).then((response) => {
					setAdsPreferenceBasedOnCurrentState(response, userTracker);
				});
			});
		}
	};

	const shouldCheckAdsPersonalizationRemoteSettings = (): boolean => {
		if (adsPersonalizationMetadata.loginLastCheck == "") {
			return true;
		}
		const nowDateTime = DateTime.now().setZone("America/Los_Angeles");
		const lastCheckDateTime = DateTime.fromISO(
			adsPersonalizationMetadata.loginLastCheck
		);
		if (lastCheckDateTime.isValid) {
			// Check every 2 hours
			return (
				Math.ceil(nowDateTime.diff(lastCheckDateTime, "hours").get("hours")) > 1
			);
		} else {
			return true;
		}
	};

	const setTuidForAnalytics = (tuid: string) => {
		setTuid(tuid);
	};

	//TODO FIX ANY
	/* eslint-disable-next-line */
	const reportEvent = (eventName: string, eventValues: any) => {
		debug.logUseEffect({
			iconText: "GoogleAnalyticsProvider",
			message: ["-> reportEvent"]
		});
		try {
			if (!eventValues) {
				eventValues = {};
			}
			if (tuid != null) {
				eventValues.tuid = tuid;
			}
			if (syntheticId) {
				eventValues.synthetic_id = syntheticId;
			}
			eventValues.event_time_client = DateTime.now()
				.setZone("America/Los_Angeles")
				.toISO();
			setGtag(eventName, eventValues);
		} catch (err) {
			console.error(err);
		}
	};

	const reportPurchaseEvent = (
		eventName: string,
		tuid: string,
		endTime: string,
		offerId: string,
		rewardId: string,
		priority: number,
		price: number,
		campaignGroup: string,
		sectionName: string,
		onlyNonPayers: boolean,
		loyalty_point_crossout = false,
		items_crossout = false,
		first_purchase_bonus?: string
	) => {
		debug.logUseEffect({
			iconText: "GoogleAnalyticsProvider",
			message: ["-> reportPurchaseEvent"]
		});
		try {
			const eventTimeClient = DateTime.now().setZone("America/Los_Angeles");
			let minutesLeft;
			if (endTime) {
				const endTimeDateTime = DateTime.fromISO(endTime);
				if (endTimeDateTime.isValid) {
					minutesLeft = Math.ceil(
						endTimeDateTime.diff(eventTimeClient, "minutes").get("minutes")
					);
				}
			}

			const eventValues: IPurchaseEventValues = {
				offer_id: offerId,
				reward_id: rewardId,
				priority: priority,
				price_in_cents: Math.round(price * 100),
				campaign_group: campaignGroup,
				section_name: sectionName,
				only_non_payers: onlyNonPayers,
				event_time_client: eventTimeClient.toISO(),
				tuid: tuid,
				minutes_left: minutesLeft,
				loyalty_point_crossout,
				items_crossout,
				first_purchase_bonus
			};
			if (syntheticId) {
				eventValues.synthetic_id = syntheticId;
			}
			setGtag(eventName, eventValues);
		} catch (err) {
			console.error(err);
		}
	};

	const reportLoyaltyPurchaseEvent = (
		eventName: string,
		tuid: string,
		endTime: string,
		offerId: string,
		rewardId: string,
		priority: number,
		loyaltyPointPrice: number,
		campaignGroup: string,
		sectionName: string
	) => {
		debug.logUseEffect({
			iconText: "GoogleAnalyticsProvider",
			message: ["-> reportLoyaltyPurchaseEvent"]
		});
		try {
			const eventTimeClient = DateTime.now().setZone("America/Los_Angeles");
			let minutesLeft;
			if (endTime) {
				const endTimeDateTime = DateTime.fromISO(endTime);
				if (endTimeDateTime.isValid) {
					minutesLeft = Math.ceil(
						endTimeDateTime.diff(eventTimeClient, "minutes").get("minutes")
					);
				}
			}

			const eventValues: ILoyaltyPurchaseEventValues = {
				offer_id: offerId,
				reward_id: rewardId,
				priority: priority,
				loyalty_point_price: loyaltyPointPrice,
				campaign_group: campaignGroup,
				section_name: sectionName,
				event_time_client: eventTimeClient.toISO(),
				tuid: tuid,
				minutes_left: minutesLeft
			};
			if (syntheticId) {
				eventValues.synthetic_id = syntheticId;
			}
			setGtag(eventName, eventValues);
		} catch (err) {
			console.error(err);
		}
	};

	const loginGoogleAnalyticsHelper = new LoginGoogleAnalyticsHelper(
		reportEvent
	);

	return (
		<>
			{syntheticId && (
				<GoogleAnalyticsContext.Provider
					value={{
						reportPurchaseEvent,
						reportLoyaltyPurchaseEvent,
						reportEvent,
						setTuidForAnalytics,
						syntheticId,
						loginGoogleAnalyticsHelper,
						enableAdsPersonalization,
						switchAdsPersonalizationToLogoutState,
						syncRemoteAdsPersonalizationSettings,
						checkAdsPersonalizationRemoteSettings,
						adsPersonalization
					}}
				>
					{children}
				</GoogleAnalyticsContext.Provider>
			)}
		</>
	);
}

export const useGoogleAnalyticsContext = () => {
	const context = useContext(GoogleAnalyticsContext);
	if (context === undefined) {
		throw new Error(
			"useGoogleAnalyticsContext was used outside of its Provider"
		);
	}
	return context;
};

export default GoogleAnalyticsProvider;
