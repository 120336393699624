import { styled } from "@mui/material";

export const Content = styled("div")`
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	justify-content: left;
`;

const SectionContent = ({ items }: { items: JSX.Element[] }) => (
	<Content>{items}</Content>
);

export default SectionContent;
