import { Slide, useMediaQuery } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import theme from "../../../../theme";

const HideOnScroll = ({
	children,
	isDisabled = false
}: {
	children: React.ReactElement;
	isDisabled?: boolean;
}) => {
	const trigger = useScrollTrigger({ threshold: 25 });
	const isDesktop = useMediaQuery(
		theme.breakpoints.up("lg").replace("@media", "")
	);

	return (
		<Slide
			appear={false}
			direction="down"
			in={isDesktop ? true : isDisabled || !trigger}
			style={{ visibility: "visible" }}
		>
			{children}
		</Slide>
	);
};

export default HideOnScroll;
