import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	List,
	ListItem,
	ListItemIcon,
	styled,
	Typography
} from "@mui/material";
import Button from "components/Buttons/ThemeButton";

export const StyledActionButton = styled(Button)({
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	width: "calc(100% - 3rem)",
	marginBottom: 1,
	marginLeft: 2,
	marginRight: 2
});
export const StyledActionsBox = styled(Box)({
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	width: "calc(100% - 6rem)",
	marginBottom: 1,
	marginLeft: 2,
	marginRight: 2,
	display: "flex",
	justifyContent: "center"
});
export const StyledDialogActions = styled(DialogActions)({
	justifyContent: "center",
	flexDirection: "column"
});

export const StyledDialog = styled(Dialog)({
	".MuiPaper-root": {
		// borderRadius: 6, // shorthand values only work in SxProps
		borderRadius: "18px",
		paddingBottom: ".15rem",
		width: "462px"
	}
});
export const StyledDialogTitle = styled(DialogTitle)({
	height: "68px",
	backgroundColor: "SteelBlue",
	backgroundPosition: "center",
	backgroundSize: "auto 100%",
	overflow: "hidden",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	"& img": {
		height: "200px",
		maxWidth: "100%",
		maxHeight: "100%"
	}
});
export const StyledDialogContent = styled(DialogContent)({
	paddingBottom: 0,
	maxHeight: "calc(100% - 194px)"
});
export const StyledDialogContentText = styled(DialogContentText)({
	padding: ".75rem 1rem"
});

export const StyledList = styled(List)({
	width: "100%",
	maxWidth: 360,
	bgcolor: "background.paper",
	"& .MuiListItemText-primary": { fontWeight: "bold", fontSize: "115%" },
	"& .MuiListItem-root": { paddingLeft: 0, paddingRight: 0 },
	"& .MuiListItemText-secondary": { fontSize: "80%" },
	height: "100%",
	overflow: "auto"
});

export const StyledListHeading = styled(Typography)({
	fontWeight: "bold",
	margin: ".15rem 0 .5rem",
	display: "block",
	textAlign: "center",
	lineHeight: 1.334,
	color: "#333333",
	fontSize: "1.5rem"
});

export const StyledListItemIcon = styled(ListItemIcon)({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	minWidth: "46px",
	marginTop: ".5rem",
	svg: {
		width: "auto",
		height: "30px"
	}
});

export const StyledListItem = styled(ListItem)({
	alignItems: "flex-start"
});
