import { links } from "helpers";
import { DipMode } from "./types";
import { ActionType, Dip, DipType, PopUpVariant, Trigger } from "interfaces";

export const PREFIX = "dip";

const DIPS_WITH_OFFERS: ActionType[] = [
	"grantReward",
	"grantLoyaltyReward",
	"loyaltyPurchase",
	"offerPurchase"
];

export const getDIPtype = (
	currentDip: Dip | undefined
): DipType | undefined => {
	if (typeof currentDip === "undefined") {
		return undefined;
	}
	if (typeof currentDip.buttons === "undefined") {
		return "informative";
	}

	const { actionType } = currentDip.buttons[0];
	if (DIPS_WITH_OFFERS.includes(actionType)) {
		return "offers";
	}

	return "informative";
};

export const getDIPByTrigger = (
	list: Dip[],
	trigger: Trigger
): Dip | undefined => {
	return list?.find((dip) => dip.dip.triggerType === trigger);
};

/***************************************************
 * Abort DIP execution
 ***************************************************/
interface Action {
	ABORT: boolean;
	CONTINUE: boolean;
}

const action: Action = {
	ABORT: true,
	CONTINUE: false
};

export const abortDIPExecution = (
	found: Dip,
	newTrigger: Trigger,
	mode: DipMode
): boolean => {
	const supressOnLanding =
		found.dip.suppressLandingPageDisplay &&
		location.pathname === links.home.path;

	if (supressOnLanding) {
		if (mode === "debug") {
			return action.CONTINUE;
		}
		// prettier-ignore
		if(newTrigger === "facebookLogout" || newTrigger === "scopelyLogout" || newTrigger === "bouncyChestClick" || newTrigger === "purchaseSuccess") {return action.CONTINUE;}
		return action.ABORT;
	}
	return action.CONTINUE;
};

export const getAvailableTriggers = (list: Dip[]): Trigger[] => {
	const triggers: Trigger[] = [];
	list.forEach((dip) => {
		triggers.push(dip.dip.triggerType);
	});
	return triggers;
};

export type GrantRewardType = Extract<
	ActionType,
	"grantLoyaltyReward" | "grantReward"
>;

export const grantRewardPopUp: {
	[G in GrantRewardType]: PopUpVariant;
} = {
	grantLoyaltyReward: "loyalty-points-added",
	grantReward: "grant-reward-collected"
};
