import React, { useMemo } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Container, Star } from "./styles";

interface BannerProps {
	firstPurchaseText: string;
}

export const FirstPurchaseBanner: React.FC<BannerProps> = ({
	firstPurchaseText
}) => {
	const starsCount = 16;
	const sparkle = 5;

	const starStyles = useMemo(() => {
		return Array.from({ length: starsCount }, () => ({
			top: `${Math.random() * 100}%`,
			left: `${Math.random() * 100}%`,
			animationDelay: `${Math.random() * sparkle}s`
		}));
	}, [starsCount, sparkle]);

	const scrolling = useSpring({
		from: { transform: "translate3d(125%,0,0)" },
		to: { transform: "translate3d(-125%,0,0)" },
		config: { duration: 6500 },
		loop: true
	});

	return (
		<Container>
			{starStyles.map((style, i) => (
				<Star key={i} style={style} />
			))}
			<animated.div style={scrolling}>{firstPurchaseText}</animated.div>
		</Container>
	);
};
