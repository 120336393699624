import styled from "styled-components";
import { Box } from "@mui/material";
import { isIPad13 } from "react-device-detect";

export const StyledStepTarget = styled(Box)<{ portrait: number }>(({ portrait }) => `
	background: #ffffff;
	border: 1.61px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0px 3.22353px 3.22353px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	padding: 2px 8px 2px 4px;
	font-weight: bold;
	transform: translateY(${isIPad13 ? "5px" : 0});
	color: #333333;
	width: ${portrait ? "48px" : "auto"};
	display: flex;
	justify-content: flex-end;

	&.claimed {
		background: rgba(255, 255, 255, 0.3);
	}

	svg {
		height: 10px;
	}

	&.mega-menu-variant {
		@media (max-width: 990px) {
			font-size: 0.54rem;
			padding: 1px 4px 1px 2px;
		}
	}
`
);
