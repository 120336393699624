import { PoisBountyStatus } from "interfaces";
import { StepConfig } from "./types";

export const DEFAULT_CONFIG: StepConfig = {
	hideBar: false,
	first: false
};

/***************************************************
 * ClassNames
 ***************************************************/
export const getTextClassNames = (
	status: PoisBountyStatus,
	isPageVariant: boolean
): (string | boolean)[] => [
	status === "in-progress" ? "animate__animated animate__jackInTheBox" : "",
	isPageVariant && "page-variant"
];

export const getPrizeClassNames = (status: PoisBountyStatus): string =>
	status === "in-progress" ? "animate__animated animate__jackInTheBox" : "";

export const getStepClassNames = (
	isPortrait: boolean,
	isPageVariant: boolean,
	firstStep: boolean | undefined
): (string | boolean | undefined)[] => [
	isPortrait && "portrait",
	!isPageVariant && "mega-menu-variant",
	firstStep && "first-step"
];

export const getRewardItemClassNames = (
	status: PoisBountyStatus,
	isPageVariant: boolean
): (string | boolean | undefined)[] => [
	"reward-item",
	status,
	isPageVariant && "page-variant",
	!isPageVariant && "mega-menu-variant"
];

export const getProgressClassNames = (
	firstStep: boolean | undefined,
	hideBar: boolean | undefined
): (string | boolean | undefined)[] => [
	"progress-reward-step",
	firstStep && "first",
	hideBar && "hide"
];

/***************************************************
 * Progress bar
 ***************************************************/
const PAGE_PROGRESS_PLACEHOLDER = 20;
const MEGA_MENU_PROGRESS_PLACEHOLDER = 30;
const DIFF_BASE = 1.3;
const DIFF_PROGRESS = 0.5;

const getProgressPlaceholder = (isPageVariant: boolean): number =>
	isPageVariant ? PAGE_PROGRESS_PLACEHOLDER : MEGA_MENU_PROGRESS_PLACEHOLDER;

export const getProgressBarWidth = (
	status: PoisBountyStatus,
	userLoyaltyPoints: number,
	target: number,
	isPageVariant: boolean
) => {
	let width: number;
	const userPercentage: number = (userLoyaltyPoints * 100) / target;
	const diff = (target - userLoyaltyPoints) / 100;

	if (status === "locked" || userLoyaltyPoints <= target) {
		if (!userLoyaltyPoints || userPercentage < getProgressPlaceholder(isPageVariant)) {
			return `${getProgressPlaceholder(isPageVariant)}%`;
		}
		width = diff <= DIFF_BASE ? userPercentage : userPercentage * DIFF_PROGRESS;
		return `${width}%`;
	}
	return "100%";
};

export const getMiniProgressBarWidth = (
	loyaltyPoints: number,
	target: number,
) => {
	let width: number;
	const userPercentage: number = (loyaltyPoints * 100) / target;

	if (loyaltyPoints <= target) {
		if (!loyaltyPoints) {
			return `0%`;
		}
		width = userPercentage;
		return `${width}%`;
	}
	return "100%";
};
