import { RootState } from "redux/store";
import { Dip, Trigger } from "interfaces";
import { initialState } from "./initial-state";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import {
	abortDIPExecution,
	getDIPByTrigger,
	getDIPtype,
	PREFIX
} from "./helper";
import {
	CLAIM_REWARD_EXTRA_REDUCER,
	DEBUG_EXTRA_REDUCER,
	DIP_EXTRA_REDUCER
} from "./extra-reducers";

export const dipSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onDIPtrigger: (state, action: PayloadAction<Trigger>) => {
			// prettier-ignore
			const { data, mode, debug, opened, queueTriggers, triggerCached } = current(state);

			// Setting the current trigger by checking first if we have a trigger
			// on chache. If that's the case we take that trigger as the new one that
			// will be executed. If It's not, then we take the trigger received
			let trigger: Trigger;
			if (action.payload === "cache-mode" && triggerCached) {
				trigger = triggerCached;
				state.triggerCached = undefined;
			} else {
				trigger = action.payload;
			}

			const isDebugMode = mode === "debug";
			const list: Dip[] | undefined = isDebugMode
				? debug["data"]?.dips
				: data.dips;
			const dipFound = getDIPByTrigger(list ?? [], trigger);

			if (!dipFound) {
				return;
			}

			// There are some conditions where we need to abort the execution
			// take a look to the abortDIPExecution helper
			// ONE OF THE CONDITIONS COULD BE SUPRESS ON LANDING PAGE
			if (abortDIPExecution(dipFound, trigger, mode)) {
				if (trigger !== "purchaseCancelled") {
					state.triggerCached = trigger;
				}
				return;
			}

			// If there is a DIP opened and a new one is triggered, we save that new one in a queue
			// to display it after the current one is closed. See on components/Dip/hooks/useInitialActions
			if (opened) {
				if (!queueTriggers.includes(trigger)) {
					if (trigger === "purchaseCancelled") {
						return;
					}

					state.queueTriggers = [...queueTriggers, trigger];
				}
				return;
			}

			state.currentDip = dipFound;
			state.activeTrigger = trigger;
			state.dipType = getDIPtype(dipFound);
			state.queueTriggers = queueTriggers.filter((trig) => trig !== trigger);
			state.opened = true;
		},
		setTriggerOnCache: (state, action: PayloadAction<Trigger>) => {
			if (state.triggerCached === "purchaseSuccess") {
				return;
			}
			state.triggerCached = action.payload;
		},
		onCloseDIP: (state): void => {
			state.currentDip = undefined;
			state.activeTrigger = undefined;
			state.opened = false;
			state.dipType = undefined;
		},
		onDisableButtonsDIP: (state): void => {
			state.claimRewardProcess.claiming = true;
		},
		onEnableButtonsDIP: (state): void => {
			state.claimRewardProcess.claiming = false;
		},
		onAddRequisitionCount: (state): void => {
			if (state.currentDip) {
				state.currentDip.requisitionCount++;
			}
		}
	},
	extraReducers: (builder) => {
		DIP_EXTRA_REDUCER(builder);
		CLAIM_REWARD_EXTRA_REDUCER(builder);
		DEBUG_EXTRA_REDUCER(builder);
	}
});

export const {
	onCloseDIP,
	onDIPtrigger,
	setTriggerOnCache,
	onDisableButtonsDIP,
	onEnableButtonsDIP,
	onAddRequisitionCount
} = dipSlice.actions;

export const selectDipState = (state: RootState) => state.dip;
export default dipSlice.reducer;
export * from "./types";
export * from "./thunks";
