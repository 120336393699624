import HttpRequest, { HttpMethod } from "./index";

export default class HttpRequestBuilder {
	/* eslint-disable */

	params: Record<string, any> = {};
	headers: Record<string, string> = {};
	url: string;
	method: HttpMethod;

	constructor(backendUri: string, method: HttpMethod) {
		// Required Params
		this.url = backendUri;
		this.method = method;
	}

	build(): HttpRequest {
		return new HttpRequest(this.url, this.method, this.params, this.headers);
	}

	withHeader(headerName: string, headerValue: string): HttpRequestBuilder {
		this.headers[headerName] = headerValue;
		return this;
	}

	withParam(paramName: string, paramValue: any): HttpRequestBuilder {
		this.params[paramName] = paramValue;
		return this;
	}

	withHeaders(headers: Record<string, string>): HttpRequestBuilder {
		Object.assign(this.headers, headers);
		return this;
	}

	withParams(params: Record<string, any>): HttpRequestBuilder {
		Object.assign(this.params, params);
		return this;
	}
	
	/* eslint-enable */
}
