import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getPoisBountyEventTracker } from "../thunks";
import { updatePoisBountySlice } from "./update-slice";
import { PoisBountySlice } from "../types";

export const TRACKER_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<PoisBountySlice>
): void => {
	builder.addCase(getPoisBountyEventTracker.fulfilled, (state, action) => {
		updatePoisBountySlice(state, action);
	});
};
