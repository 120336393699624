import { Helmet } from "react-helmet";

interface INewsMetaTags {
	url?: string;
	description?: string;
	title?: string;
	image?: string;
}

const NewsMetaTags = ({ url, description, title, image }: INewsMetaTags) => {
	return (
		<Helmet>
			{title && <title>{title}</title>}
			{title && <meta property="og:title" content={title} />}

			{description && <meta name="description" content={description} />}
			{description && <meta property="og:description" content={description} />}

			{url && <meta property="og:url" content={url} />}
			{url && <link rel="canonical" href={url} />}

			{image && <meta property="og:image" content={image} />}
			<meta property="og:type" content="article" />
		</Helmet>
	);
};

export default NewsMetaTags;
