import moment from "moment";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { LS_DOT_MEGAMENU_HIDDEN_AT } from "redux/slices/sticky-sale-tag/helper";

const SHOW_DOT = {
	YES: true,
	NO: false
};

export const useShowHamburgerDotCooldown = (): boolean => {
	const { nowTime } = useWebStoreContext();
	const lastHiddenDate = localStorage.getItem(LS_DOT_MEGAMENU_HIDDEN_AT);

	// @ts-expect-error
	if (typeof nowTime._tzm === "undefined") {
		return SHOW_DOT.NO;
	}

	// @ts-expect-error
	if (lastHiddenDate && typeof nowTime._tzm !== "undefined") {
		const lastDate = JSON.parse(lastHiddenDate) as Date;
		// prettier-ignore
		if (moment(nowTime).isAfter(moment(lastDate).add(1, "day"))) {
			localStorage.removeItem(LS_DOT_MEGAMENU_HIDDEN_AT);
			return SHOW_DOT.YES;
		} else {
			return SHOW_DOT.NO;
		}
	}
	// If there's not last hidden date that means that user have not clicked on a sale active tab
	return SHOW_DOT.YES;
};
