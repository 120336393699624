import { Box } from "@mui/material";
import classNames from "classnames";
import { usePoisBountyContext } from "components/PoisBountyWidget/context";
import { useAppSelector } from "hooks";
import { selectPoisBountyState } from "redux/slices";
import { getLabel } from "./helper";
import { ButtonStyled } from "./styles";
import { useStepContext } from "../Step/context";

interface ClaimButtonProps {
	onClick: () => void;
}

export const ClaimButton = ({ onClick }: ClaimButtonProps) => {
	const { status } = useStepContext();
	const { claimingReward } = useAppSelector(selectPoisBountyState);
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const statusLabel = getLabel(status);

	return (
		<Box className="claim-button">
			<ButtonStyled
				disabled={status === "locked" || status === "unlocked"}
				onClick={() => {
					if (claimingReward) {
						return;
					}
					onClick();
				}}
				className={classNames([
					status,
					isPortrait && "portrait",
					isPageVariant && "page-variant",
					!isPageVariant && "mega-menu-variant"
				])}
			>
				{statusLabel}
			</ButtonStyled>
		</Box>
	);
};
