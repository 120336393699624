import { Fragment, useEffect } from "react";
import { timerMobileStyle, timerStyle } from "./styles";
import { getNextCollectionDay, useWeekStreak } from "../../hooks";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import Countdown from "components/CountdownV2";
import { useDailyLoginStreak } from "../../context";
import { useAppDispatch } from "hooks";
import { getDailyLoginStreak } from "redux/slices";
import useBreakpoints from "hooks/useBreakpoints";
import { getCount, getDisplay } from "helpers";
import { useMediaQuery } from "@mui/material";

export const Timer: React.FC = ({}) => {
	const { isMdDown } = useBreakpoints();
	const { nowTime } = useWebStoreContext();
	const { tracker } = useDailyLoginStreak();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { streakDays, lastDayCollected, isStreakBroken } = tracker;
	const { today, tomorrow } = useWeekStreak(streakDays);
	const dispatch = useAppDispatch();
	const isSmallLandscape = useMediaQuery(
		"(max-width:932px) and (max-height:430px)"
	);
	const alreadyCollected = today && "collectedDate" in today;
	let endTime: string;

	if (lastDayCollected || !alreadyCollected) {
		endTime = today?.endOfDay;
	} else {
		endTime = tomorrow?.startOfDay;
	}

	const handleEnd = () => {
		dispatch(getDailyLoginStreak());
	};

	useEffect(() => {
		const timeRemaining = getCount(nowTime, endTime);
		const timeToDisplay = getDisplay(timeRemaining);
		reportEvent("daily_gift_streak_view", {
			gifts_claimed:
				getNextCollectionDay(tracker)?.streakDayIndex ?? streakDays.length,
			time_remaining: timeToDisplay,
			collected_state: alreadyCollected ?? false,
			broke_streak: isStreakBroken ?? false,
			source: "daily_gift_streak"
		});
	}, []);

	return today && endTime ? (
		<Countdown
			onEnd={() => handleEnd()}
			endTime={endTime}
			style={isSmallLandscape || isMdDown ? timerMobileStyle : timerStyle}
			startTime={nowTime}
			text={alreadyCollected ? "Next Free Gift:" : "Time to collect:"}
			status={alreadyCollected ? "Next Free Gift:" : "Time to collect:"}
		/>
	) : (
		<Fragment />
	);
};
