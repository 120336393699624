import { useEffect } from "react";
import { images, IImage } from "helpers/imagesConfig";
import { getDebugLogger } from "utils/debugLogger";
import { useImagePath } from "hooks/useImagePath";

const debug = getDebugLogger({
	isEnabled: true
});
const ImagePreloader = () => {
	const { getImageSrc } = useImagePath("cdn");
	const found: string[] = [];
	const skipped: string[] = [];
	// eslint-disable-next-line
	const imageFound = (e: any) => {
		e?.target?.src && found.push(e.target.src);
	};
	// eslint-disable-next-line
	const imageNotFound = (e: any) => {
		window?.console && console.warn("Preload image failed!", e.target.src);
	};
	useEffect(() => {
		let image: IImage;
		for (const key in images) {
			image = images[key];
			if (!image?.enablePreload) {
				skipped.push(image.src);
				continue;
			}
			const img = new Image();
			img.addEventListener("load", imageFound);
			img.addEventListener("error", imageNotFound);
			img.src = getImageSrc(image.src);
		}

		debug.logUseEffect({
			iconText: "ImagePreloader",
			message: ["loading..."]
		});

		debug.log({
			iconText: "success:",
			message: ["images loaded:", found, "images skipped:", skipped]
		});
	}, []);
	return null;
};

export default ImagePreloader;
