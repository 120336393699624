import { styled, Typography } from "@mui/material";

export const ProfileImage = styled("div")<{ color?: string }>(({ color }) => `
	display: flex;
	justify-content: center;
	margin-bottom: 0;

	&,
	img {
		border-radius: 60px;
	}
	img {
		margin: 4px;
		width: 48px;
		height: 48px;
	}
	.imgWrap {
		position: relative;
		border: 2px solid ${color || "#01799f"};
		padding: 0;
		display: inline-block;
		line-height: 0;
		border-radius: 60px;
	}
`);

export const LogoutButton = styled("button")`
	border: none;
	border-radius: 30px;
	background: none;
	color: #01799f;
	cursor: pointer;
	transition: color 0.2s linear;
	line-height: 0;
	padding: 0;
	margin: 0;
	position: absolute;
	bottom: 1px;
	right: 0px;

	height: 25px;
	width: 25px;

	&:hover {
		color: #00b2e8;
	}
`;

export const UserName = styled(Typography)`
	white-space: nowrap;
	font-weight: 700;
	font-size: 14px;
	@media (min-width: 1000px) {
		max-width: 110px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
export const ClubName = styled(Typography)`
	white-space: nowrap;
	font-weight: 400;
	font-size: 12px;
	color: #01799f;
	@media (min-width: 1000px) {
		max-width: 110px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
export const Level = styled("div")`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-bottom: 36px;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	p {
		padding: 0;
		text-align: center;

		&.label {
			/* background: pink; */
		}
	}

	& .value {
		/* background: grey; */
	}
`;

export const Points = styled("div")`
	background: #efefef;
	&:hover {
		background: rgb(237, 237, 237);
	}
	border-radius: 30px;
	padding: 7px 13px;
	display: flex;
	justify-content: space-between;
	color: #333333;
	font-weight: 700;
	font-size: 12px;

	& .label,
	& .value {
		display: flex;
		align-items: center;
	}
	& .label {
		margin-right: 4px;
		svg, img {
			margin-right: 6px;
			width: 18px;
		}
	}
`;

export const UserProfileContainer = styled("div")`
	@media (min-width: 1000px) {
		margin: 15px;
		display: flex;
		align-items: center;
	}
`;

export const User = styled("div")`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	@media (min-width: 1000px) {
		margin-bottom: 0px;
	}
	//margin-bottom: 15px;
`;
