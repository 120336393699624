import ThemeButton from "components/Buttons/ThemeButton";
import styled from "styled-components";

export const Item = styled.div`
	position: relative;
	height: 570px;
	width: 360px;
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
	border: 6px solid #fff;

	&:hover {
		img {
			transform: scale(1.1);
		}
	}

	@media (max-width: 890px) {
		width: 80%;
		height: 360px;
	}
	@media (max-width: 690px) {
		width: 95%;
		margin-bottom: 1.5rem;
	}
	@media (max-width: 460px) {
		width: 95%;
		height: 280px;
	}
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s all ease;
`;

export const Button = styled(ThemeButton)`
	position: absolute;
	bottom: 2rem;
	left: 50%;
	transform: translateX(-50%);
	width: 85%;
`;
