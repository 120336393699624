import { Box } from "@mui/material";
import { useImagePath } from "hooks/useImagePath";

const GameLogo = ({
	logoImagePathTemplate
}: {
	logoImagePathTemplate: string;
}) => {
	const { getImage } = useImagePath("cdn");
	return (
		<Box
			className="game-logo"
			component="img"
			sx={{
				content: {
					xs: `url(${getImage(logoImagePathTemplate, "small")})`,
					md: `url(${getImage(logoImagePathTemplate, "large")})`
				},
				width: { xs: "100%", sm: "63%", md: "52%", lg: "56%", xl: "33vw" },
				height: "100%"
			}}
		/>
	);
};

export default GameLogo;
