import styled from "styled-components";

export const TitleContainer = styled.div`
	font-family: "Baloo";
	font-weight: 400;
	font-size: 18px;
	line-height: 36px;
	display: flex;
	align-items: center;
`;

export const ItemDescription = styled.div`
	font-family: "Inter";
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6d6d6d;
`;

export const ItemTitle = styled.div`
	font-family: "Baloo";
	margin-left: 10px;
	font-weight: 400;
	font-size: 18px;
`;

export const OptionContainer = styled.div`
	margin: 10px 10px 20px 0px;
	transition: all 0.3s ease;
	color: #113b62;
	position: relative;
	padding: 0.2rem 0.5rem;
	:hover {
		color: #4088b7;
	}
	:active {
		color: #286084;
	}

	&.sale-tag {
		margin-top: 30px;
	}

	@media (min-width: 820px) {
		width: 160px;
		margin-right: 0px;
		&.sale-tag {
			margin-top: 10px;
		}
	}

	@media (min-width: 1020px) {
		margin-right: 10px;
		margin-left: 10px;
	}
`;

export const Tag = styled.div`
	position: absolute;
	top: -15px;
	left: 0;
	width: 100%;
	height: 15px;
	border-radius: 6px 6px 0 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	&.mobile {
		height: 20px;
		top: -20px;
		border-radius: 0;
		justify-content: flex-start;
	}
`;

export const TagText = styled.span`
	color: #fff;
	text-transform: uppercase;
	font-size: 10px;
	margin-right: 0.5rem;
	&.mobile {
		margin-left: 0.5rem;
		font-weight: bold;
		font-size: 14px;
	}
`;
