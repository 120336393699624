import { Dispatch, SetStateAction } from "react";
import { IFbPcitureResp } from "..";
import { IDebugLogger } from "../../../utils/debugLogger";

const getGetProfileImage = ({
	userId,
	setProfileImage,
	debug
}: {
	userId: string;
	setProfileImage: Dispatch<SetStateAction<string | null>>;
	debug: IDebugLogger;
}) => {
	const getProfileImage = async () => {
		if (!userId) {
			return;
		}
		!!window?.FB &&
			window.FB.api(
				`/${userId}/picture?redirect=false`,
				(response: IFbPcitureResp) => {
					if (response && !response.error) {
						const {
							data: { height, is_silhouette, url, width }
						} = response;
						debug.log({
							iconText: "getProfileImage:",
							message: [height, is_silhouette, url, width]
						});
						setProfileImage(url + '?timestamp=' + new Date().getTime());
					} else if (response.error) {
						console.error(response.error);
					}
				}
			);
	};
	return getProfileImage;
};

export default getGetProfileImage;
