import { keyframes } from "styled-components";

export const expandOverlay = keyframes`
  0% {
    width: 0;
    height: 0;
		top: 50%;
		left: 50%;
  }
  100% {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
		top: 8px;
		left: 8px;
  }
`;

export const moveDiagonally = keyframes`
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(15px, 15px);
  }
`;
