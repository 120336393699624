import styled from "styled-components";
import star from "assets/star.png";

export const Container = styled.div`
	background-color: rgba(0, 0, 0, 0.5);
	width: auto;
	//min-height: 20px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 405;
	font-size: 0.85rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	color: white;
	flex-wrap: nowrap;

	//overflow: hidden;
	position: relative;
	padding: 10px;

	gap: 5px;

	> svg,
	> img {
		width: 18px;
	}

	@media (max-width: 600px) {
		padding: 8px;
		font-size: 12px;
		> svg,
		> img {
			width: 12px;
		}
	}

	/* border: 0.2rem solid #c0c0c0;
	box-shadow: 0 0 0.2rem #c0c0c0, 0 0 0.2rem #c0c0c0, 0 0 2rem #bc13fe,
		0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe; */
`;

export const StarContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: -16px;
	left: -16px;
	opacity: 0;
	transition: opacity 1s ease-in;
	transition: opacity 2s ease-out;

	&.in {
		opacity: 1;
	}

	&.out {
		opacity: 0;
	}
`;

export const Star = styled.div`
	position: absolute;
	background-image: url(${star});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	overflow: hidden;
	z-index: 1;
	color: transparent;
	opacity: 0;
	animation: glitter 6s linear 0s infinite normal;
	-webkit-animation: glitter 6s linear 0s infinite normal;
	-moz-animation: glitter 8s linear 0s infinite normal;
	-ms-animation: glitter 8s linear 0s infinite normal;
	-o-animation: glitter 8s linear 0s infinite normal;
	width: 26px;
	height: 26px;

	@keyframes glitter {
		0% {
			-webkit-transform: scale(0.3) rotate(0deg);
			opacity: 0;
		}
		5% {
			-webkit-transform: scale(1) rotate(360deg);
			opacity: 1;
		}
		50% {
			-webkit-transform: scale(0.3) rotate(720deg);
			opacity: 0;
		}
		100% {
			-webkit-transform: scale(0.3) rotate(0deg);
			opacity: 0;
		}
	}
`;
