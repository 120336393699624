import PageTopNav from "../../components/PageTopNav";
import { useEffect, useState } from "react";
import { useWebStoreContext } from "../../contexts/WebStoreProvider";
import { PrivateRoute } from "../../hoc";
import { useEnvContext } from "contexts/EnvProvider";
import { FeaturesFlags } from "helpers";
import { FreeGiftSection } from "./FreeGiftSection";
import { PageContent } from "components/PageContent";
import { ISectionMenuItem } from "components/PageTopNav/types";

const ClubFreeGift = () => {
	const [sectionMenuData] = useState<ISectionMenuItem[]>([]);
	const { isFeatureFlagEnabled } = useEnvContext();
	const { userTracker } = useWebStoreContext();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<PrivateRoute
			isEnabled={
				(userTracker?.clubId ? true : false) &&
				isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP_FREE_GIFT)
			}
		>
			<>
				<PageTopNav sectionMenuData={sectionMenuData} />
				<PageContent source="club_free_gift_page">
					<FreeGiftSection />
				</PageContent>
			</>
		</PrivateRoute>
	);
};

export default ClubFreeGift;
