import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import classNames from "classnames";
import useBreakpoints from "hooks/useBreakpoints";
import { Card } from "../Card";
import { CarouselStyled } from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import { itemsV1 } from "../../items";
interface CarouselProps {
	items?: {
		image: string;
		title: string;
		description?: string;
	}[];
}

export const Carousel: React.FC<CarouselProps> = ({ items }) => {

	const { isSmDown } = useBreakpoints();
	const renderItems = items || itemsV1;

	return (
		<CarouselStyled className={classNames([isSmDown && "mobile"])}>
			<Swiper
				slidesPerView={1.2}
				spaceBetween={0}
				freeMode={false}
				modules={[FreeMode, Pagination]}
				className="mySwiper"
			>
				{renderItems.map((card, index) => (
					<SwiperSlide key={index} style={{ marginRight: "2rem" }}>
						<Card item={card} />
					</SwiperSlide>
				))}
			</Swiper>
		</CarouselStyled>
	);
};
