import styled from "styled-components";

export const Container = styled("div")`
	padding: 10vh 0%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	gap: 1rem;
	@media (min-width: 740px) {
		flex-wrap: nowrap;
	}
`;

export const Text = styled("div")`
	min-width: 300px;
	flex-basis: 43%;
	margin-bottom: 2rem;
	color: #333;
	@media (max-width: 840px) {
		flex-basis: 63%;
	}
`;
export const Image = styled("img")`
	max-width: 90%;
	@media (min-width: 740px) {
		max-width: 40%;
	}
	flex-basis: 35%;
`;
