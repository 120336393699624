export const dialogSxProps = {
	".MuiPaper-root": {
		borderRadius: 6,
		paddingBottom: ".15rem",
		width: "703px",
		maxWidth: "none"
	}
};

export const dialogContentSxProps = { paddingBottom: 0 };

export const dialogContentTextSxProps = { padding: ".75rem 1rem" };

export const typographyMainHeadingSxProps = {
	fontWeight: "bold",
	margin: ".15rem 0 .5rem",
	display: "block",
	textAlign: "center",
	lineHeight: 1,
	color: "#333333",
	marginBottom: "2.5rem",
	fontSize: "clamp(1rem, 2.4vw, 32px)",
	marginTop: "2rem"
};

export const gridContainerSxProps = {
	justifyContent: "space-between",
	textAlign: "center",
	marginBottom: "1rem",
	".MuiGrid-root": {
		xs: {
			flexBasis: "100%",
			maxWidth: "100%"
		},
		sm: {
			flexBasis: "32%",
			maxWidth: "32%"
		}
	},
	img: {
		borderRadius: "13px",
		width: "100%",
		height: "auto"
	},
	".title": {
		fontWeight: "bold",
		fontSize: "24px"
	},
	".desc": {
		lineHeight: 1.5,
		marginBottom: 4
	}
};

export const dialogActionsSxProps = {
	justifyContent: "center",
	flexDirection: "column"
};

export const buttonSxProps = {
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	marginBottom: 1,
	width: {
		xs: "calc(100% - 3rem)",
		sm: "50%"
	},
	marginLeft: 2,
	marginRight: 2
};
