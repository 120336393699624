import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import styled from "styled-components";

export const CustomDialog = styled(Dialog)<{ scale: number }>`
	.MuiPaper-root {
		transform: scale(${(props) => props?.scale});
		border-radius: 25px;
		display: flex;
		flex-direction: column;
		width: 380px;
		min-width: 380px;
		height: 500px;
		min-height: 500px;
		margin: 0px;
		//top: 32px;

		@media (max-height: 590px) {
			flex-direction: row;
			width: 700px;
			min-width: 700px;
			height: 300px;
			min-height: 300px;
		}
	}
`;

export const CustomDialogTitle = styled(DialogTitle)`
	background-color: SteelBlue;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	width: auto;
	height: 232px;
	padding: 0px;

	@media (max-height: 590px) {
		height: auto;
		width: 50%;
	}
`;

export const CustomDialogContent = styled(DialogContent)`
	display: flex;
	align-items: center;
	padding: 10px 24px 0px;
	max-height: 130px;

	@media (max-height: 590px) {
		padding: 25px 24px 0px;
	}
`;

export const CustomDialogContentText = styled(DialogContentText)`
	//padding: 1rem 0rem 0.5rem;
	padding: 0rem;

	@media (max-height: 590px) {
		//padding: 1rem 0rem 0.5rem;
		padding: 0rem;
	}
`;
