import { useWebStoreContext } from "contexts/WebStoreProvider";
import { ClubName, UserName } from "../styled";

export const UserInfo = () => {
	const { userName, userTracker } = useWebStoreContext();

	return (
		<div style={{ display: "flex", flexDirection: "column", margin: "18px" }}>
			<UserName>{`${userName}`}</UserName>
			{userTracker?.clubName && (
				<ClubName>{`${userTracker.clubName}`}</ClubName>
			)}
		</div>
	);
};
