import styled from "styled-components";

export const ProgressStyled = styled.div`
	display: flex;
	align-items: center;

	&.portrait {
		transform: rotate(-90deg) translate(-220px, -50%);

		@media (max-width: 770px) {
			margin-top: -25%;
		}
	}

	&.page-variant {
		margin-top: 65px;
	}
`;
