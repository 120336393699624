import { useEffect, useState } from "react";
import title from "assets/pois-bounty/v2/pois-logo.png";
import { Button, Container, Description, Title } from "./styles";
import { useAppDispatch, useAppSelector, useImagePath } from "hooks";
import { onClosePopUp, selectPoisBountyV2State } from "redux/slices";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { Grid } from "@mui/material";

interface PBClaimAllRewardsProps { }

export const PBClaimAllRewards: React.FC<PBClaimAllRewardsProps> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const { reportEvent } = useGoogleAnalyticsContext();
	const { trackers, remaining24hour, isEventActiveV2, eventEnded } = useAppSelector(selectPoisBountyV2State);
	const backgroundEmblem = getImage("/spec_pois_bounty/pois_bounty_tiki_and_poi_emblem.png");
	const [text, setText] = useState<string>("Cycle restarted! Now you can continue getting incredible rewards!");

	useEffect(() => {
		reportEvent("event_restarted_pop_up", {
			source: "pois_bounty_v2",
			pois_bounty_id: trackers.eventId
		});
		setText(remaining24hour || !isEventActiveV2 || eventEnded
			? "Your uncollected rewards during this event were sent to your in-game inbox"
			: "Cycle restarted! Now you can continue getting incredible rewards!"
		);
	}, []);


	return (
		<Container background-image={backgroundEmblem}>
			<Title src={title} />
			<Description>
				{text}
			</Description>
			<Grid container spacing={2}>
				<Grid item xs={12} /* sm={isMobile ? 6 : 12} */ display="flex" justifyContent="center">
					<Button
						onClick={() => {
							dispatch(onClosePopUp());
							reportEvent("collect_clicked", {
								source: "pois_bounty_login_collect",
								pois_bounty_id: trackers.eventId
							});
						}}
						sx={{ minWidth: { xs: "80%", /* sm: isMobile ? "100%" : "80%" */ } }}
					>
						Continue
					</Button>
				</Grid>
				{/* {isMobile && (
					<Grid item xs={12} sm={6} display="flex" justifyContent="center">
						<BackToGameButton
							source="pois_bounty_login_collect_dialog"
							themeColor="secondary"
							sx={{ minWidth: { xs: "80%", sm: "100%" } }}
						/>
					</Grid>
				)} */}
			</Grid>
		</Container>
	);
};
