import { Typography, styled } from "@mui/material";
/* eslint-disable */
export const Sticky = styled("div")`
	position: fixed;
	bottom: 2rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% 100%;
	height: 68px;
	background-image: url(${(props: any) => props["data-image"]});
	display: flex;
	align-items: center;
	width: 65vw;
	@media (min-width: 1500px) {
		width: 900px;
	}

	&.mobile {
		width: 80vw;
		height: 65px;
		transform: translateX(0);
		left: 2%;
		bottom: 0.8rem;
		.text {
			font-size: 18px;
			@media (max-width: 600px) {
				font-size: 14px;
				display: block;
				width: 200px;
			}
			@media (max-width: 420px) {
				width: 180px;
			}
		}
	}
`;
/* eslint-enable */
export const Body = styled("div")`
	height: 100%;
	margin: 0 auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 80%;
	@media (max-width: 945px) {
		max-width: 100%;
		text-align: center;
		transform: translateX(6%);
	}
`;

export const Tiki = styled("img")`
	top: 50%;
	left: 2%;
	transform: translateY(-65%);
	position: absolute;
	width: 150px;
	height: 150px;
	object-fit: contain;
	@media (max-width: 990px) {
		left: 0%;
		width: 130px;
		height: 130px;
	}
	@media (max-width: 600px) {
		width: 90px;
		height: 90px;
	}
	@media (max-width: 400px) {
		width: 70px;
		height: 70px;
	}
`;

export const Text = styled(Typography)`
	line-height: 1.2;
	color: #fff;
	font-weight: 600;
	font-size: 18px;
`;

export const Button = styled("button")`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 4%;
	background-color: unset;
	border: unset;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	svg {
		stroke: #fff;
	}
`;
