/* eslint-disable */
export enum WsDialogs {
	ERROR = "error",
	NONE = "none",
	DEFAULT = "default",
	CLAIM_MY_REWARD = "claim_your_free_login_gift",
	LOGIN_TO_CONNECT = "login_to_continue",
	ACCOUNT_NOT_CONNECTED = "your_account_is_not_connected",
	DAILY_GIFT_CONFIRMATION = "daily_gift_confirmation",
	// RECEIVED_FIRST_LOGIN_REWARD = "received-first-login-reward"
	LOGIN_REWARD_CONFIRMATION = "login_reward_confirmation",
	INSUFFICIENT_LOYALTY_POINT_BALANCE = "error_insufficient_loyalty_point_balance",
	LOYALTY_FAQ = "loyalty_faq",
	LOYALTY_PURCHASE_CONFIRM = "loyalty_purchase_confirm",
	LOYALTY_PURCHASE_SUCCESS = "loyalty_purchase_success",
	PRIVACY_ADS_TOGGLE = "privacy_ads_toggle",
	PURCHASE_SUCCESS = "purchase_success",
	SHOP_PURCHASE_SUCCESS = "shop_purchase_success",
	XSOLLA_PURCHASE = "xsolla_purchase"
}
/* eslint-enable */

//TODO: this dialog is from PopUpManager
export type PoisBountyDialogVariant = "uncollected-rewards";

export type WebstoreDialogVariant =
	| WsDialogs.DEFAULT
	| WsDialogs.CLAIM_MY_REWARD
	| WsDialogs.LOGIN_TO_CONNECT
	| WsDialogs.ACCOUNT_NOT_CONNECTED
	| WsDialogs.LOGIN_REWARD_CONFIRMATION
	| WsDialogs.DAILY_GIFT_CONFIRMATION
	| WsDialogs.INSUFFICIENT_LOYALTY_POINT_BALANCE
	| WsDialogs.LOYALTY_FAQ
	| WsDialogs.LOYALTY_PURCHASE_CONFIRM
	| WsDialogs.PRIVACY_ADS_TOGGLE
	| WsDialogs.LOYALTY_PURCHASE_SUCCESS
	| WsDialogs.SHOP_PURCHASE_SUCCESS
	| WsDialogs.XSOLLA_PURCHASE;

export type DialogVariant =
	| "default"
	| "error"
	| WebstoreDialogVariant
	| PoisBountyDialogVariant;
