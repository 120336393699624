import { RewardClaimStatus, RewardClaimStatuses } from "interfaces";

export const PREFIX = "pois-bounty";

export const getUnclaimedReward = (rewards: RewardClaimStatus[]): number => {
	return rewards.findIndex((reward) => typeof reward.claimed === "undefined");
};

export const getUnclaimedRewardV2 = (rewards: RewardClaimStatuses[]): number => {
	return rewards.findIndex((reward) => !reward.isClaimed);
};
