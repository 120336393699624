import { useEnvContext } from "contexts/EnvProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { FeaturesFlags } from "helpers";
import { useAppSelector } from "hooks";
import { SaleTag } from "interfaces";
import { selectStickySaleTagState } from "redux/slices";

export const useSaleTag = (section: string): SaleTag | undefined => {
	const { nowTime } = useWebStoreContext();
	const { saleTags } = useAppSelector(selectStickySaleTagState);
	const { isFeatureFlagEnabled } = useEnvContext();
	if (!isFeatureFlagEnabled(FeaturesFlags.STICKY_TAG)) {
		return;
	}
	const tags = saleTags.filter(
		(tag) => tag.showMegaMenuTag && tag.megaMenuTagSection === section
	);

	const stickyTag = tags.find((tag) => {
		if (nowTime?.isAfter(tag.startTime) && nowTime.isBefore(tag.endTime)) {
			return tag;
		}
	});

	return stickyTag;
};
