import {} from "hooks";
import useDebugAction from "hooks/useDebugAction";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";

export const DebugActions: React.FC = ({ children }) => {
	const {
		isLoading: isSettingDebugAction,
		isSuccess: isSuccessDebugAction,
		isError: isErrorDebugAction,
		isNormalFlow
	} = useDebugAction();
	const location = useLocation();

	if (isSettingDebugAction) {
		return (
			<div style={{ color: "blue", marginLeft: "5px" }}>
				Executing Debug Action...
			</div>
		);
	}

	if (isSuccessDebugAction) {
		window.location.replace(location.pathname);
		return (
			<div style={{ color: "green", marginLeft: "5px" }}>
				Success Executing Debug Action
			</div>
		);
	}

	if (isErrorDebugAction) {
		window.location.replace(location.pathname);
		return (
			<div style={{ color: "red", marginLeft: "5px" }}>
				Error Executing Debug Action
			</div>
		);
	}

	if (!isNormalFlow) {
		return null;
	}

	return <Fragment>{children}</Fragment>;
};
