import { LOCAL_STORAGE_DATE_JUMPED } from "debug/contexts/DebugWebStoreProvider";
import { baseApi } from "../api";
//import { AuthToken } from "interfaces";

export const offersApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getOffers: builder.query({
			query: (arg) => {
				const { offerType } = arg;
				const dateJumped = localStorage.getItem(LOCAL_STORAGE_DATE_JUMPED);

				return `/rest/offers?offerTypes=${offerType}${
					dateJumped ? `&date=${dateJumped}` : ""
				}`;
			},
			providesTags: (result, error, arg) => [
				{ type: "offersTag", id: arg.offerType }
			]
		})
	})
});

export const { useGetOffersQuery } = offersApi;
