import { createRef, useEffect, useState, createContext } from "react";
import PageTopNav, { ISectionMenuItem } from "components/PageTopNav";
import { useWebNewsContext } from "contexts/WebNewsProvider";
import { PageContent } from "components/PageContent/index";
import SingleItems from "./lib/SingleItems";
import { IShopSection } from "contexts/WebStoreProvider/types";
import { isEqualWith } from "lodash";
import LoyaltyLearnMoreCard from "./lib/LoyaltyLearnMoreCard";
import { useAppSelector } from "hooks";
import { selectShopSectionsState } from "redux/slices/shop-sections";
import { useEnvContext } from "contexts";
import { FeaturesFlags } from "helpers";
import PageTopNavV2 from "components/PageTopNavV2";

// eslint-disable-next-line
export const LoyaltyContext = createContext({} as any);

const LoyaltyShop = () => {
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const { resetNewsState } = useWebNewsContext();
	const [sectionMenuData, setSectionMenuData] = useState<ISectionMenuItem[]>(
		[]
	);
	const [loyaltyShopSections, setLoyaltyShopSections] = useState<
		IShopSection[]
	>([]);
	const { isFeatureFlagEnabled } = useEnvContext();

	useEffect(() => {
		window?.scrollTo(0, 0);
		resetNewsState();
	}, []);

	//Prevent rerendering when processing storeRaw
	useEffect(() => {
		if (!shopSections?.loyaltyShopSections) {
			return;
		}
		if (isEqualWith(shopSections?.loyaltyShopSections, loyaltyShopSections)) {
			return;
		}
		setLoyaltyShopSections(shopSections?.loyaltyShopSections);
	}, [shopSections?.loyaltyShopSections]);

	useEffect(() => {
		setSectionMenuData(
			shopSections?.loyaltyShopSections.map((data: IShopSection) => {
				const { name, title: text, iconImageUrl: icon } = data;
				const newRef = createRef();

				return {
					name,
					text,
					icon,
					sectionRef: newRef,
					analytics_source: "loyalty"
				};
			})
		);
	}, [loyaltyShopSections]);

	return (
		<LoyaltyContext.Provider value={{ sectionMenuData }}>
			{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
				<PageTopNavV2 sectionMenuData={sectionMenuData} />
			) : (
				<PageTopNav sectionMenuData={sectionMenuData} />
			)}
			<PageContent source={"loyalty_page"} enableBackToTop>
				<LoyaltyLearnMoreCard />
				<SingleItems />
			</PageContent>
		</LoyaltyContext.Provider>
	);
};

export default LoyaltyShop;
