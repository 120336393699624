import { Link, Typography } from "@mui/material";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { links } from "helpers/linksConfig";

export const Legalese = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<Typography
			variant="caption"
			textAlign="center"
			sx={{ padding: "0 0 1.4rem", fontSize: { xs: "11px", sm: "14px" } }}
		>
			By confirming your purchase, you agree to the{" "}
			<Link
				href={links.tos.path}
				target="_blank"
				rel="noreferrer"
				onClick={() => {
					reportEvent("terms_of_service_click", {
						source: "loyalty_purchase_confirm_dialog"
					});
				}}
			>
				Terms of Sale
			</Link>
			,<br /> You’ll be charged immediately.
		</Typography>
	);
};
