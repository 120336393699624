/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	Container,
	CustomButton,
	Dot,
	ExpandedText,
	StyledButtonGroup
} from "./styles";
import { configData, parseData } from "./helpers";
import { ISectionMenuItem } from "components/PageTopNav";
import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectStickyDIPState } from "redux/slices";
import { useGetFeatureTrackerQuery } from "redux/apis";
import { useEnvContext, useWebStoreContext } from "contexts";
import { FeaturesFlags } from "helpers";
import { EndlessOfferResponse } from "interfaces/endless-offer";
import { WaterfallResponse } from "interfaces/waterfall-sale";
import useNewDay from "hooks/useNewDay";
import { postUserTrackerValue } from "redux/slices/common";

export const SectionMenu = ({ items }: { items: ISectionMenuItem[] }) => {
	const [selected, setSelected] = useState(0);
	const itemsFiltered = items.filter((item) => item.sectionRef?.current);
	const { isFeatureFlagEnabled } = useEnvContext();
	const dispatch = useAppDispatch();
	const { authToken, userTracker } = useWebStoreContext();
	const { data: stickyData } = useAppSelector(selectStickyDIPState);
	const [displayLimitedDealDot, setDisplayLimitedDealDot] =
		useState<boolean>(false);
	const { newDay } = useNewDay();

	const isWaterfallSaleActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.WATERFALL_SALES)
		);
	};

	const isEndlessOfferActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.ENDLESS_OFFER)
		);
	};

	const isStickyDipActive = () => {
		return isFeatureFlagEnabled(FeaturesFlags.STICKY_DIPS);
	};

	const {
		data: waterfallData,
		refetch: refetchWaterfall
	}: { data?: WaterfallResponse; refetch: () => void } =
		useGetFeatureTrackerQuery(
			{
				FeatureType: "waterfall-sale",
				isAuthenticated: !!authToken?.value
			},
			{ skip: !isWaterfallSaleActive() }
		);

	const {
		data: endlessOfferData,
		refetch: refetchEndlessOffer
	}: { data?: EndlessOfferResponse; refetch: () => void } =
		useGetFeatureTrackerQuery(
			{
				FeatureType: "endless-offer",
				isAuthenticated: !!authToken?.value
			},
			{ skip: !isEndlessOfferActive() }
		);

	const getConfig = (name: string) => {
		return configData.find((item) => item.name === name);
	};

	const handleExpand = (index: number) => {
		const sectionElement = itemsFiltered[index].sectionRef?.current;
		if (sectionElement) {
			const rect = sectionElement.getBoundingClientRect();
			const offset = rect.top + window.scrollY - 145;
			window.scrollTo({ top: offset, behavior: "smooth" });
		}
	};

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const sectionElements = itemsFiltered
			.map((item, index) => {
				const sectionElement = item.sectionRef?.current;
				if (!sectionElement) {
					return null;
				}
				const rect = sectionElement.getBoundingClientRect();
				const top = rect.top + scrollPosition;
				const bottom = rect.bottom + scrollPosition;
				const middle = (top + bottom) / 2;
				return { index, top, bottom, middle };
			})
			.filter(Boolean);

		const activeSection = sectionElements.reduce((prev, current) => {
			if (current) {
				const prevMiddle = prev?.middle;
				const currentMiddle = current.middle;
				const scrollMiddle = scrollPosition + window.innerHeight / 2;
				if (
					prevMiddle !== undefined &&
					Math.abs(scrollMiddle - currentMiddle) <
						Math.abs(scrollMiddle - prevMiddle)
				) {
					return current;
				}
			}
			return prev;
		}, sectionElements[0]);

		if (activeSection) {
			setSelected(activeSection.index);
		}
	};

	const debouncedHandleScroll = debounce(handleScroll, 100);

	const sawCurrentLimitedDealsSection = () => {
		if (userTracker) {
			const values = userTracker.metadata?.lastSeenLimitedDealSectionV2
				? parseData(userTracker.metadata.lastSeenLimitedDealSectionV2)
				: "";
			const updatedValues = { ...values };
			if (isWaterfallSaleActive() && waterfallData?.sessionTracker?.id) {
				updatedValues.waterfall = waterfallData.sessionTracker.id;
			}
			if (isEndlessOfferActive() && endlessOfferData?.tracker?.eventId) {
				updatedValues.endlessSale = endlessOfferData.tracker.eventId;
			}
			if (isStickyDipActive() && stickyData) {
				updatedValues.stickyDip = Object.keys(stickyData);
			}

			dispatch(
				postUserTrackerValue({
					key: "lastSeenLimitedDealSectionV2",
					value: JSON.stringify(updatedValues)
				})
			);
		}
		setDisplayLimitedDealDot(false);
	};

	useEffect(() => {
		window.addEventListener("scroll", debouncedHandleScroll);
		return () => {
			window.removeEventListener("scroll", debouncedHandleScroll);
		};
	}, [itemsFiltered]);

	useEffect(() => {
		const parsedMetadata = userTracker?.metadata?.lastSeenLimitedDealSectionV2
			? parseData(userTracker.metadata.lastSeenLimitedDealSectionV2)
			: "";
		if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
			setDisplayLimitedDealDot(
				waterfallData?.sessionTracker?.id !== parsedMetadata?.waterfall
			);
		}
		if (isEndlessOfferActive() && endlessOfferData?.tracker) {
			setDisplayLimitedDealDot(
				endlessOfferData?.tracker.eventId !== parsedMetadata?.endlessSale
			);
		}
		if (isStickyDipActive() && stickyData) {
			const arraysHaveSameElements = (
				arr1: string[],
				arr2: string[]
			): boolean => {
				if (arr1.length > arr2.length) return false;
				const filteredArr2 =
					arr1.length < arr2.length
						? arr2.filter((val) => arr1.includes(val))
						: null;
				const sortedArr1 = [...arr1].sort();
				const sortedArr2 = [...(filteredArr2 ?? arr2)].sort();

				return sortedArr1.every((val, index) => val === sortedArr2[index]);
			};
			setDisplayLimitedDealDot(
				!arraysHaveSameElements(
					Object.keys(stickyData),
					parsedMetadata?.stickyDip ?? []
				)
			);
		}
	}, [userTracker, waterfallData, endlessOfferData, stickyData]);

	useEffect(() => {
		if (newDay) {
			refetchWaterfall();
			refetchEndlessOffer();
		}
	}, [newDay]);

	if (!itemsFiltered.length) {
		return null;
	}

	return (
		<Container>
			<StyledButtonGroup variant="contained" color="primary">
				{itemsFiltered.map((item, index) => {
					const config = getConfig(item.name);
					if (
						selected === index &&
						item.text === "Limited Deals" &&
						displayLimitedDealDot
					) {
						sawCurrentLimitedDealsSection();
					}

					return (
						<CustomButton
							key={index}
							className={selected === index ? "expanded" : ""}
							onClick={() => handleExpand(index)}
						>
							{config?.icon && (
								<config.icon fill={selected === index ? "#479FBA" : "white"} />
							)}
							<ExpandedText className={selected === index ? "expanded" : ""}>
								{item.text}
							</ExpandedText>
							{selected !== index &&
								item.text === "Limited Deals" &&
								displayLimitedDealDot && <Dot />}
						</CustomButton>
					);
				})}
			</StyledButtonGroup>
		</Container>
	);
};
