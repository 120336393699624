import { styled } from "@mui/material";

export const Container = styled("div")<{ scale: number }>`
	width: 368px;
	background-color: #fff;
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
	transform: scale(${(props) => props?.scale});
`;

export const Body = styled("div")`
	max-width: 372px;
	margin: 24px auto 0 auto;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const Title = styled("span")`
	max-width: 290px;
	font-family: "Baloo";
	font-size: 26px;
	line-height: 28px;
	color: #5D2904;

	@media (max-height: 400px) {
		font-size: 22px;
		max-width: 100%;
	}
`;

export const Text = styled("span")`
	max-width: 290px;
	font-family: "Inter";
	font-size: 16px;
	line-height: 26px;
	color: #333;

	@media (max-height: 400px) {
		font-size: 14px;
		max-width: 100%;
	}
`;
