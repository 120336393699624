import { Fragment } from "react";
import classNames from "classnames";
import { CrownIcon } from "utils/svgIcons";
import { DipButton, WsDialogs } from "interfaces";
import Button from "components/Buttons/ThemeButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { sortRewardsWithSpecificIdAtEnd } from "helpers";
import { getFlexBasis } from "components/DIP/lib/helper";
import { CardFinalMessage } from "styles/general_styles";
import { getDisplayRewards } from "utils/getDisplayRewards";
import { getClassNameVariant } from "components/DIP/helper";
import { useAppDispatch, useAppSelector, useImagePath } from "hooks";
import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import {
	getLimitedDeals,
	selectPoisBountyV2State,
	selectWebStoreState
} from "redux/slices";
import {
	Plus,
	Items,
	Footer,
	FreeTag,
	BogoBox,
	BogoItem,
	ItemImage,
	OfferItem,
	RewardItem,
	RewardAmount,
	OffersStyled,
	LoyaltyPoints
} from "./styles";

interface BogoProps {
	data: Omit<IShopPurchaseOffer, "onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText">[];
}

export const Bogo: React.FC<BogoProps> = ({ data }: BogoProps) => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const { openDialog } = useDialogModalRedux();
	const plus = getImage("/spec_dips/bogo_plus.png");
	const freeTag = getImage("/spec_dips/free_tag.png");
	const freeTagTwo = getImage("/spec_dips/free_tag_two.png");
	const { reportEvent } = useGoogleAnalyticsContext();
	const isLowWidth = useMediaQuery("(max-width:733px)");
	const isMobile = isLowWidth;
	const { rewards, rewardTypes } = useAppSelector(selectWebStoreState).store;
	const { wsStoreBuy, suppressOrderConfirmation, postUserMetadata } =
		useWebStoreContext();
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const { userTracker } = useWebStoreContext();
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;

	if (typeof data === "undefined" || !data) {
		return <Fragment />;
	}
	const bogos = [data[0], data[0]];

	return (
		<BogoBox>
			<OffersStyled>
				{bogos?.map(
					({ rewardId, id, rewardMultiplier, loyaltyPointReward }, index) => {
						// prettier-ignore
						const bogoOffer = index + 1 === bogos.length;

						const items = getDisplayRewards(rewardId, {
							rewards,
							rewardTypes
						});
						return (
							<BogoItem key={id + index}>
								<OfferItem
									className={classNames([
										getClassNameVariant(bogos.length as number),
										"item-reward"
									])}
								>
									<Items
										className={classNames([
											getClassNameVariant(bogos.length as number),
											"bundle-rewards-item"
										])}
									>
										{sortRewardsWithSpecificIdAtEnd(items, "random").map(
											({ imageUrl, description }, key) => {
												const flexBasis = getFlexBasis(
													bogos as unknown as DipButton[],
													items,
													isMobile
												);
												const rewardAmount = bogoOffer
													? (
														Number(description?.replace(",", "")) *
														(rewardMultiplier || 0)
													).toLocaleString("en-US")
													: description;

												return (
													<RewardItem key={key} width={flexBasis + "%"}>
														<ItemImage
															className="item-icon"
															src={imageUrl}
															alt=""
														/>
														<RewardAmount className="description">
															{rewardAmount}
														</RewardAmount>
													</RewardItem>
												);
											}
										)}
									</Items>

									<Footer
										className={classNames([
											loyaltyPointReward && "loyalty-reward",
											getClassNameVariant(bogos.length as number)
										])}
									>
										<LoyaltyPoints>
											<div>
												Earn <CrownIcon width={16} />
												{bogoOffer
													? (
														(loyaltyPointReward || 0) *
														(rewardMultiplier || 0)
													).toLocaleString("en-US")
													: loyaltyPointReward?.toLocaleString("en-US")}
											</div>
											{isPoisActive && (
												<CardFinalMessage color="#FBBD05">
													Contribute to Poi’s Bounty
												</CardFinalMessage>
											)}
										</LoyaltyPoints>
									</Footer>
									{bogoOffer && (
										<FreeTag
											src={
												data[0]?.dip?.bogoType === "double"
													? freeTagTwo
													: freeTag
											}
										/>
									)}
								</OfferItem>
								{!bogoOffer && <Plus src={plus} />}
							</BogoItem>
						);
					}
				)}
			</OffersStyled>
			<Button
				className="bogo-button"
				onClick={() => {
					/**🔦 We set the dipId on local-storage to get it on the invoice API call */
					localStorage.setItem(
						"com.scopely:dipId",
						data[0].dip?.id as string
					);
					wsStoreBuy({ offerId: data[0].id }).then((invoice) => {
						if (invoice) {
							openDialog(WsDialogs.XSOLLA_PURCHASE, {
								xsollaToken: invoice.xsollaToken,
								xsollaHost: invoice.xsollaHost,
								suppressOrderConfirmation,
								source: "dips",
								postUserMetadata,
								onPurchaseStarted: (purchaseSata) => {
									reportEvent("purchase_started", {
										section_name: "limited_deals",
										offer_id: data[0].id,
										price_in_cents: data[0].price * 100,
										reward_id: data[0].rewardId,
										invoice_id: invoice?.id,
										paystation_id: purchaseSata?.payStation
									});
								},
								onPurchaseSuccess: () => {
									reportEvent("purchase_success", {
										invoice_id: invoice?.id
									});
									dispatch(getLimitedDeals());
								},
								cbClose: () => {
									reportEvent("dialog_x_closed", {
										source: "xsolla_dialog",
										page_location: location.pathname
									});
								}
							});
						}
					});
				}}
			>
				{data[0].price} USD
			</Button>
		</BogoBox>
	);
};
