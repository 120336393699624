import { LOCAL_STORAGE_TIME_ZONE } from "debug/components/SetLocalTime";
import { getLocalDateEtcGMTFormat } from "helpers";
import { isMobile } from "react-device-detect";
import { analyticsTracker } from "../../ScopelyIdentityProvider/config";
import {
	IBuyProps,
	IInvoice,
	IInvoiceReponse,
	IWebStoreContext,
	IWebStoreContextApiProps
} from "../types";

interface IGetBuyProps extends IWebStoreContextApiProps {
	isMdDown: boolean;
	xsollaTokenOverrideId: string;
	logout: () => void;
}

const getBuy = ({
	axios,
	authToken,
	backendUri,
	popups,
	xsollaTokenOverrideId,
	dryRunOverride,
	createHeaders,
	debug,
	syntheticId
}: IGetBuyProps): IWebStoreContext["buy"] => {
	const buy = async (props: IBuyProps): Promise<IInvoice | undefined> => {
		const dipId = localStorage.getItem("com.scopely:dipId");
		const localTimeZoneOverride = localStorage.getItem(LOCAL_STORAGE_TIME_ZONE);
		debug.log({ iconText: "buy:", message: [`Buying ${props?.offerId}`] });

		/* CURRENT SETTINGS (hardcoded on server, overrides here for development purposes)
		const data = {
			xsollaTokenOverride: {
				settings: {
					ui: {
						// version: isMdDown ? "mobile" : "desktop",
						mobile: {
							header: {
								close_button: false
							},
							footer: {
								is_visible: false
							}
						},
						desktop: {
							header: {
								is_visible: true
							}
						}
					},
					redirect_policy: {
						manual_redirection_action: "postmessage"
					}
				}
			}
		};
		*/

		const data = {
			xsollaTokenOverride: {}
		};

		const queryParams: {
			offerId: string;
			dryRun?: string;
			xsollaTokenOverrideId?: string;
			dipId?: string;
			waterfallSaleId?: string;
			waterfallSaleTierIndex?: number;
			endlessOfferEventId?: string;
			endlessOfferNodeIndex?: number;
		} = {
			offerId: props?.offerId
		};

		if (dryRunOverride) {
			queryParams.dryRun = dryRunOverride;
		}

		if (xsollaTokenOverrideId) {
			queryParams.xsollaTokenOverrideId = xsollaTokenOverrideId;
		}

		if (dipId) {
			queryParams.dipId = dipId;
		}

		if (props?.waterfallSaleId) {
			queryParams.waterfallSaleId = props?.waterfallSaleId;
		}

		if (typeof props?.waterfallSaleTierIndex === "number") {
			queryParams.waterfallSaleTierIndex = props?.waterfallSaleTierIndex;
		}

		if (props?.endlessOfferEventId) {
			queryParams.endlessOfferEventId = props?.endlessOfferEventId;
		}

		if (typeof props?.endlessOfferNodeIndex === "number") {
			queryParams.endlessOfferNodeIndex = props?.endlessOfferNodeIndex;
		}

		const deviceToken = analyticsTracker.getDeviceToken();
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"Content-Type": "text/plain; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId,
				"X-GSN-WEBSTORE-REFERER-TIMEZONE": localTimeZoneOverride ? localTimeZoneOverride : getLocalDateEtcGMTFormat(),
				"X-TITAN-DEVICE-TOKEN": deviceToken ? deviceToken : ""
			}),
			params: queryParams
		};

		const uri = "/rest/store/invoice";
		const invoiceResponse = await axios
			.post<IInvoiceReponse>(uri, data, requestConfig)
			.then(async (response) => {
				//NOTE:bfloyd test errors
				// response.data.success = false;
				// response.data.erroorCode = "test_code";
				// response.data.errorMessage = "test_message";

				if (response.data && response.data.success) {
					debug.log({
						iconText: "buy:",
						message: ["invoiceResponse:", response.data]
					});
					return response.data;
				} else {
					console.error(response);
					popups.handleErrorResponse({ response });
				}
			})
			.catch((err) => {
				console.error(err.message);
				popups.handleErrorResponse(err);
			})
			.finally(() => {
				localStorage.removeItem("com.scopely:dipId");
			});

		return invoiceResponse?.invoice;
	};
	return buy;
};

export default getBuy;
