import FeaturedCard from "./FeaturedCard";
import { Content } from "components/Section/SectionContentFull";
import { useGoogleAnalyticsContext /* useEnvContext */ } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";

function LoyaltyLearnMoreCard() {
	const { openDialog } = useDialogModalRedux();
	const { reportEvent } = useGoogleAnalyticsContext();
	//const { cdnPrefix } = useEnvContext();

	const handleClick = () => {
		openDialog(WsDialogs.LOYALTY_FAQ);

		reportEvent("loyalty_learn_more_click", {
			source: "loyalty_page"
		});
		reportEvent("dialog_shown", { source: "loyalty_faq_dialog" });
	};

	return (
		<section className="loyalty-faq-section" data-testid="loyaltyFaqSection">
			<Content sx={{ marginTop: 3 }}>
				<FeaturedCard
					ctaClick={handleClick}
					ctaText="Learn More"
					backgroundColor="#34241e"
					textColor="white"
					backgroundImageUrl={`/spec_loyalty_faq/loyalty_faq_v4_{image_variant_1}.png`}
					titleText="Tiki TriPeaks Loyalty Points!"
					text="Everything you need to know about"
				/>
			</Content>
		</section>
	);
}

export default LoyaltyLearnMoreCard;
