import styled from "styled-components";
import Button from "components/Buttons/ThemeButton";
import { isIPad13 } from "react-device-detect";

export const ButtonStyled = styled(Button)`
	box-shadow: 0px 3.64835px 3.64835px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	width: 90%;
	padding: 1rem 0;
	font-size: 12px;
	line-height: 1;
	transition: all ease 0.3s;
	transform: translateY(${isIPad13 ? "15px" : 0});
	text-transform: none;

	&.page-variant {
		width: 90px;
		height: 44px;
	}

	&.mega-menu-variant {
		width: 64px;
		font-size: 0.8rem;

		@media (max-width: 990px) {
			width: 50px;
			height: 40px;
			font-size: 0.65rem;
		}
	}
	&.portrait {
		padding: 1rem 2rem;
	}
	&.unlocked {
		color: #7b7b7b;
		background-color: #b9b9b9;
		text-shadow: unset;
		border-radius: 8px;
	}
	&.locked {
		background: transparent !important;
		opacity: 0.7;
		color: #bcbcbc;
		border: 2px solid #b9b9b9;
		text-shadow: unset;
		border-radius: 8px;
	}

	@media (min-width: 755px) and (max-width: 950px) {
		padding: 0.8rem 0.5rem;
		min-height: 2rem;
		min-width: 2rem;
	}
`;
