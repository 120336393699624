import { Flag } from "./svg";
import { Container, Text } from "./styles";

interface BonusFlagProps {
	text: string;
}

export const BonusFlag: React.FC<BonusFlagProps> = ({text}: BonusFlagProps) => {
	return (
		<Container>
			<Text>{text}</Text>
			<Flag />
		</Container>
	);
};
