import ThemeButton from "components/Buttons/ThemeButton";
import { styled } from "@mui/material";

export const Container = styled("div")`
	width: 956px;
	max-width: 95vw;
	background-color: #fff;
	border-radius: 25px;
	position: relative;
	overflow: hidden;
	@media (max-width: 600px) {
		max-width: 342px;
		max-height: 95vh;
	}
`;

export const Header = styled("div")<{ "data-image": string }>`
	height: 410px;
	max-height: 95vh;
	width: 100%;
	background-repeat: no-repeat;
	background-position: cover;
	background-size: cover;
	background-image: url(${(props) => props["data-image"]});
	position: relative;
	@media (max-width: 600px) {
		height: 612px;
		max-height: 95vh;
	}
	@media (max-height: 668px) {
		height: 85vh;
	}
`;

export const Avatar = styled("img")`
	object-fit: contain;
	position: absolute;
	left: 51%;
	transform: translateX(-50%);
	@media (max-width: 600px) {
		bottom: 13%;
	}
	@media (max-height: 375px) {
		max-height: 80%;
	}
`;

export const Title = styled("img")`
	max-width: 95%;
	object-fit: contain;
	position: absolute;
	top: 43px;
	left: 50%;
	transform: translateX(-50%);
	@media (max-width: 600px) {
		top: 96px;
		width: inherit;
	}
`;

export const Button = styled(ThemeButton)`
	width: max-content;
	padding: 7px 28px;
	position: absolute;
	bottom: 26px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 24px;
	@media (max-width: 600px) {
		bottom: 24px;
	}
`;
