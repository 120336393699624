import styled from "styled-components";
import Collapse from "@mui/material/Collapse";
import Button from "components/Buttons/ThemeButton";
//import { styled : styledMui } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled.div<{
	image?: string;
	containerHeight?: number;
	containerWidth?: number;
}>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	position: relative;
	width: 100%;
	background-image: url(${(props) => props.image});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	height: calc(
		${(props) =>
			props?.containerHeight ? `${props?.containerHeight / 4}px` : "100%"}
	);
	max-height: 220px;

	&.several-nodes {
		//width: 50%;
		//height: calc(100% / 3);
		max-height: unset;
		height: 96%;
		z-index: 2;
	}

	@media (min-width: 733px) {
		background-position-x: center;
		background-position-y: unset;
		background-size: auto 94%;
		height: 100%;
		max-height: unset;
		width: calc(
			${(props) =>
				props?.containerWidth ? `${props?.containerWidth / 4}px` : "100%"}
		);

		&.several-nodes {
			background-size: 100% auto;
			z-index: unset;
			height: 100%;
			width: calc(
				${(props) =>
					props?.containerWidth ? `${props?.containerWidth / 6}px` : "100%"}
			);
		}
	}
`;

export const CardHeader = styled.div`
	height: 26%;
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	flex-direction: column;

	&.several-nodes {
		height: 25%;
	}

	@media (min-width: 733px) {
		height: 36%;
		max-height: unset;

		&.several-nodes {
			height: 35%;
		}
	}
`;

export const Ribbon = styled.div<{ image?: string }>`
	position: relative;
	top: -14%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${(props) => props.image});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 100%;
	color: #fff;
	text-shadow: -1px -1px 0 #593113, 1px -1px 0 #593113, -1px 1px 0 #593113,
		2px 2px 0 #593113;
	font-weight: 400;
	font-family: Baloo;
	font-style: normal;
	font-size: 18px;

	&.several-nodes {
		top: -3%;
		font-size: 15px;
	}

	@media (min-width: 733px) {
		position: relative;
		top: -5%;
		line-height: 100%;
		height: 58%;
		width: 120%;
		font-size: clamp(15px, 1.5dvw, 20px);

		&.several-nodes {
			position: unset;
			top: unset;
			height: 65%;
			font-size: clamp(15px, 1.5dvw, 20px);
		}
	}
`;

export const RibbonText = styled.div`
	position: relative;
	top: -6%;
`;

export const Items = styled.div`
	justify-content: space-evenly;
	position: relative;
	top: -5%;
	align-items: center;
	display: flex;
	width: 100%;
	height: 40%;

	&.several-nodes {
		top: -5%;
		height: 45%;
	}

	@media (min-width: 733px) {
		top: unset;
		width: unset;
		height: 32%;

		&.several-nodes {
			top: unset;
			height: 32%;
		}
	}
`;
interface IRewardItem {
	width?: string;
	filter?: number;
}
export const RewardItem = styled.div<IRewardItem>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 1px;
	filter: grayscale(${(props) => props.filter});

	@media (min-width: 733px) {
		flex-basis: ${(props) => props.width};
	}
`;

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000,
		1.5px 1.5px 0 #000000;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #333;
	&.several-nodes {
		font-size: 12px;
	}

	@media (min-width: 733px) {
		font-size: 12px;
		&.several-nodes {
			font-size: 10px;
		}
	}

	@media (min-width: 930px) {
		font-size: 14px;
		&.several-nodes {
			font-size: 14px;
		}
	}
`;

export const ItemImage = styled.img`
	width: 43px;
	height: 43px;
	object-fit: contain;
	justify-self: center;
	align-self: center;

	&.several-nodes {
		width: 38px;
		height: 38px;
	}

	@media (min-width: 733px) {
		width: 35px;
		height: 35px;
		&.several-nodes {
			width: 30px;
			height: 30px;
		}
	}

	@media (min-width: 900px) {
		width: 45px;
		height: 45px;
		&.several-nodes {
			width: 38px;
			height: 38px;
		}
	}

	@media (min-width: 1050px) {
		width: 55px;
		height: 55px;
		&.several-nodes {
			width: 40px;
			height: 40px;
		}
	}

	@media (min-width: 1200px) {
		width: 65px;
		height: 65px;
		&.several-nodes {
			width: 55px;
			height: 55px;
		}
	}
`;

export const InfoBox = styled(Box)`
	align-items: center;
	background-color: rgb(0 0 0 / 30%);
	border-radius: 84px;
	color: white;
	display: flex;
	flex-direction: row;
	font-weight: 220;
	opacity: 0.9;
	padding: 0.15rem 0.4rem;
	background-repeat: no-repeat;

	@media (min-width: 733px) {
		padding: 0rem 0.4rem;
		&.several-nodes {
			padding: 0rem 0.4rem;
		}
	}
	@media (min-width: 930px) {
		padding: 0.15rem 0.4rem;
		&.several-nodes {
			padding: 0.15rem 0.4rem;
		}
	}
`;

export const LoyalBox = styled(InfoBox)`
	background-position: 90% center;
	color: white;
	background: rgba(0, 0, 0, 0.51);
	white-space: nowrap;
	font-size: 15px;
	svg,
	img {
		padding-left: 0.5rem;
		width: 17px;
	}

	&.several-nodes {
		font-size: 12px;
	}

	@media (min-width: 733px) {
		font-size: 12px;
		&.several-nodes {
			font-size: 10px;
		}
	}

	@media (min-width: 900px) {
		&.several-nodes {
			font-size: 12px;
		}
	}
`;

export const LoyalBoxWrap = styled(Box)`
	position: relative;
	display: flex;
	align-items: center;
	font-size: 0.5em;
	justify-content: center;
	height: 15%;

	&.several-nodes {
		//position: absolute;
		top: -10%;
	}

	@media (min-width: 733px) {
		//position: absolute;
		//top: 3%;
		height: 14%;
		&.several-nodes {
			height: 15%;
			top: unset;
		}
	}
`;

export const StyledButton = styled(Button)`
	position: relative;
	border-radius: 8px !important;
	width: 120px;
	height: 55%;
	font-size: 15px;

	&.several-nodes {
		top: -10%;
		height: 15%;
	}

	@media (min-width: 733px) {
		width: 100px;
		font-size: 12px;
		height: 18%;
		//top: 4%;

		&.several-nodes {
			top: unset;
			height: 18%;
		}
	}

	@media (min-width: 900px) {
		border-radius: 11px !important;
		width: 120px;
		font-size: 15px;
	}

	@media (min-width: 1050px) {
		width: 135px;

		&.several-nodes {
			width: 125px;
		}
	}
`;

export const LockImage = styled.img`
	height: 100%;
	position: absolute;
	right: -5%;
	object-fit: contain;
`;

export const StyledCollapseHorizontal = styled(Collapse)({
	"&.MuiCollapse-entered": {
		width: "100% !important"
	},
	"& .MuiCollapse-wrapper": {
		width: "100%"
	},
	"& .MuiCollapse-wrapperInner": {
		width: "100%"
	}
});

export const StyledCollapseVertical = styled(Collapse)({
	"&.MuiCollapse-entered": {
		height: "100% !important"
	},
	"& .MuiCollapse-wrapper": {
		height: "100%"
	},
	"& .MuiCollapse-wrapperInner": {
		height: "100%"
	}
});

export const DebugInfo = styled.div`
	font-weight: 800;
	color: #f82c00;
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	z-index: 3;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
	top: -15px;
	font-size: 10px;

	&.several-nodes {
		top: -12px;
	}

	@media (min-width: 733px) {
		top: -12px;
		font-size: clamp(10px, 1.2dvw, 14px);
		&.several-nodes {
			font-size: clamp(8px, 1dvw, 12px);
		}
	}
`;
