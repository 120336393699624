import { Box, Grid, styled } from "@mui/material";
import { FeaturedCard as _FeaturedCard_, Text, TitleText } from "./styled";
import Button from "components/Buttons/ThemeButton";

function getBackgroundUrl(urlTemplate: string, variant: string): string {
	return urlTemplate.replace("{image_variant_1}", variant);
}

const CardLayout = ({
	textContent,
	titleContent,
	itemsContent
}: {
	textContent?: JSX.Element;
	titleContent: JSX.Element;
	itemsContent: JSX.Element | null;
}) => {
	return (
		<Box
			sx={{
				position: "relative",
				bottom: 0,
				top: 0,
				left: 0,
				right: 0
			}}
		>
			<Grid container style={{ height: "100%" }}>
				<Grid
					item
					xs={8}
					sm={12}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: { xs: "1rem 1rem 0 1rem", lg: "8rem 1rem 0 10rem" },
						flexDirection: "column"
					}}
				>
					{textContent}
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					lg={6}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: { xs: "0 1rem 1rem 1rem", lg: "0 1rem 0 10rem" },
						marginBottom: { lg: "7rem" },
						flexDirection: "column"
					}}
				>
					{titleContent}
				</Grid>
				<Grid
					item
					xs={0}
					sm={6}
					sx={{
						position: "relative"
					}}
				>
					{itemsContent}
				</Grid>
			</Grid>
		</Box>
	);
};

const FeaturedCard = ({
	ctaClick,
	ctaText = "CLICK ME",
	backgroundImageUrl,
	titleText,
	text
}: {
	ctaClick?: () => void;
	imageUrl?: string;
	cardHeader?: string;
	cardText?: string;
	ctaText?: string;
	ctaHref?: string;
	className?: string;
	backgroundColor: string;
	backgroundImageUrl: string;
	titleText: string;
	text: string;
	textColor: string;
}) => {
	const FeaturedCard = styled(_FeaturedCard_)(({ theme }) => ({
		backgroundRepeat: "no-repeat",
		// TODO: update background color when image background is updated
		// NOTE: visible on xl viewport sizes
		backgroundColor: "#8433e9",
		[theme.breakpoints.down("sm")]: {
			backgroundImage: `url(${getBackgroundUrl(backgroundImageUrl, "small")})`
		},
		[theme.breakpoints.up("sm")]: {
			backgroundImage: `url(${getBackgroundUrl(backgroundImageUrl, "large")})`
		}
	}));

	// const TitleImage = styled(_TitleImage_)({
	// 	content: `url("${titleImageUrl}")`
	// });

	return (
		<FeaturedCard>
			<CardLayout
				textContent={
					<Text>{text}</Text>
				}
				titleContent={
					<>
						{/* <TitleImage className="titleImg" /> */}
						<TitleText>{titleText}</TitleText>
						<Button
							variant="contained"
							themeColor="secondary"
							onClick={ctaClick}
						>
							{ctaText}
						</Button>
					</>
				}
				itemsContent={null}
			/>
		</FeaturedCard>
	);
};

export default FeaturedCard;
