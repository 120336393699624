import { useEnvContext } from "contexts/EnvProvider";

type ImageVariants = "large" | "medium" | "small";

// custom hook to address development of images prior to being uploaded to CDN
//
// cdn bucket:
// tripeaks / WebStore / static_assets / frontend / public
//
// cdn path:
// https://storage.googleapis.com/tripeaks/WebStore/static_assets/frontend/public/spec_landing_page/section-1-char.png
//
// local path:
// /frontend/public/spec_landing_page/section-1-char.png
//
//
export const useImagePath = (env: "local" | "cdn") => {
	const { cdnPrefix } = useEnvContext();

	const getImageSrc = (url: string) => {
		return cdnPrefix && env === "cdn" ? `${cdnPrefix}${url}` : url;
	};

	const getImageVariant = (urlTemplate: string, variant: ImageVariants) => {
		return urlTemplate.replace("{variant}", variant);
	};

	const getImage = (url: string, variant?: ImageVariants) => {
		if (variant) {
			return getImageSrc(getImageVariant(url, variant));
		} else {
			return getImageSrc(url);
		}
	};

	return { getImage, getImageSrc, getImageVariant };
};
