import moment from "moment";
import { Moment } from "moment";
import { RewardClaimStatuses } from "interfaces";

interface EventTimes {
	eventInProgress: boolean;
	eventEnded: boolean;
	saleEnded: boolean;
}

export const getEventTimes = (now: Moment | null, end: Moment): EventTimes => {
	const diff = moment.duration(end.diff(now));

	return {
		eventInProgress: moment(now).isBefore(end),
		eventEnded: diff.days() < 0,
		saleEnded: diff.hours() <= 0
	};
};

export const getUncollectedRewards = (
	rewards: RewardClaimStatuses[],
	userLoyaltyPoints: number
): RewardClaimStatuses[] => {
	const uncollectedRewards: RewardClaimStatuses[] = [];
	rewards.forEach((rewardItem) => {
		const { reward, claimed } = rewardItem;
		if (reward.loyaltyPoints <= userLoyaltyPoints) {
			if (typeof claimed === "undefined") {
				uncollectedRewards.push(rewardItem);
			}
		}
	});
	return uncollectedRewards;
};
