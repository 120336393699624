import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
	background: #049fd9;
	padding: 20px;
	color: white;
	font-family: "Inter", sans-serif;
`;

const SectionTitle = styled.h3`
	font-size: 22px;
	line-height: 24px;
	font-family: "Baloo", sans-serif;
	margin-bottom: 16px;
`;

const LinkList = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
`;

const LinkItem = styled.li`
	margin-bottom: 12px;
	font-size: 16px;
	line-height: 26px;
	font-weight: 500;
`;

const ExternalLink = styled.a`
	color: white;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

const Divider = styled.div`
	height: 1px;
	background: #ffffff33;
	margin: 20px 0;
`;

const SocialMedia = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
`;

const AppBadges = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
`;

const Terms = styled.div`
	font-size: 12px;
	line-height: 1.5;
	text-align: center;
`;

const Footer = () => {
	return (
		<FooterWrapper>
			<div>
				<SectionTitle>Sections</SectionTitle>
				<LinkList>
					<LinkItem>
						<ExternalLink href="#">Web Shop</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Pois Bounty</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Club Shop</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Loyalty Shop</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Daily Gift</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">
							Merch Shop <Badge>NEW</Badge>
						</ExternalLink>
					</LinkItem>
				</LinkList>
			</div>

			<div>
				<SectionTitle>Learn More</SectionTitle>
				<LinkList>
					<LinkItem>
						<ExternalLink href="#">News</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Forum</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">FAQ</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Winners</ExternalLink>
					</LinkItem>
				</LinkList>
			</div>

			<div>
				<SectionTitle>About Us</SectionTitle>
				<LinkList>
					<LinkItem>
						<ExternalLink href="#">Who We Are</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Contact Us</ExternalLink>
					</LinkItem>
					<LinkItem>
						<ExternalLink href="#">Customer Support</ExternalLink>
					</LinkItem>
				</LinkList>
			</div>

			<SocialMedia>
				<ExternalLink href="#">
					<img src="./assets/facebook-square-7.svg" alt="Facebook" width="24" />
				</ExternalLink>
				<ExternalLink href="#">
					<img src="./assets/twitter-7.svg" alt="Twitter" width="24" />
				</ExternalLink>
			</SocialMedia>

			<AppBadges>
				<ExternalLink href="#">
					<img src="./assets/btn-apple.svg" alt="App Store" />
				</ExternalLink>
				<ExternalLink href="#">
					<img src="./assets/btn-google.svg" alt="Google Play" />
				</ExternalLink>
			</AppBadges>

			<Divider />

			<Terms>
				<p>Terms of Service | Privacy Policy</p>
				<p>Do Not Sell or Share My Personal Information</p>
				<p>© GSN Games, Inc. 2004-2021.</p>
				<p>All Rights Reserved.</p>
			</Terms>
		</FooterWrapper>
	);
};

const Badge = styled.span`
	background: red;
	color: white;
	font-size: 10px;
	border-radius: 5px;
	padding: 2px 5px;
	margin-left: 5px;
`;

export default Footer;
