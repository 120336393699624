import styled from "styled-components";
import FirstPurchaseBadgeIcon from "assets/red-badge.svg";

export const Container = styled("div")`
	position: absolute;
	z-index: 1;
	top: -23px;
	right: -5px;
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(${FirstPurchaseBadgeIcon});
	background-size: cover;
	//transform: rotate(-21deg);
	color: #fff;
	font-family: "Baloo";
	font-size: 11px;
	line-height: 12px;
	text-shadow: 0px 0px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000;

	> span {
		margin-bottom: 5px;
		opacity: 0;
		animation: fadeIn 2s infinite;
	}

	animation: scale-rotate 2s infinite;

	@keyframes scale-rotate {
		0% {
			transform: scale(1) rotate(-30deg);
		}
		50% {
			transform: scale(1.2) rotate(-20deg);
		}
		100% {
			transform: scale(1) rotate(-30deg);
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;
