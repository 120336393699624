import {
	IWebStoreContextApiProps,
	IInvoiceReponse,
	IWebStoreContextApi,
	IUserTracker
} from "../types";
import { isMobile } from "react-device-detect";
import {analyticsTracker} from "../../ScopelyIdentityProvider/config";

interface IGetLoyaltyBuyProps extends IWebStoreContextApiProps {
	logout: () => void;
	setUserTracker: React.Dispatch<React.SetStateAction<IUserTracker | null>>;
}

const getLoyaltyBuy = ({
	axios,
	authToken,
	backendUri,
	popups,
	createHeaders,
	debug,
	setUserTracker,
	syntheticId
}: IGetLoyaltyBuyProps): IWebStoreContextApi["loyaltyBuy"] => {
	// eslint-disable-next-line
	const loyaltyBuy = async (offerId: string): Promise<any | null> => {
		const dipId = localStorage.getItem("com.scopely:dipId");
		debug.log({
			iconText: "loyaltyBuy:",
			message: [`Buying loyalty offer ${offerId}`]
		});

		const queryParams: {
			offerId: string;
			dipId: string | null;
		} = {
			offerId: offerId,
			dipId
		};
		const deviceToken = analyticsTracker.getDeviceToken();
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"Content-Type": "text/plain; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId,
				"X-TITAN-DEVICE-TOKEN": deviceToken ? deviceToken : ""
			}),
			params: queryParams
		};
		const uri = "/rest/store/loyalty/purchase";
		const response = await axios
			.post<IInvoiceReponse>(uri, {}, requestConfig)
			.then((response) => {
				if (!!response.data && response.data.success) {
					setUserTracker(response.data.userTracker);
					return response.data;
				} else {
					popups.handleErrorResponse({ response });
				}
			})
			.catch((err) => {
				popups.handleErrorResponse(err);
			})
			.finally(() => {
				localStorage.removeItem("com.scopely:dipId");
			});

		return typeof response === "number" ? response : response?.success;
	};

	return loyaltyBuy;
};

export default getLoyaltyBuy;
