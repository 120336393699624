import { Button } from "@mui/material";
import classnames from "classnames";
import { ISectionMenuItemProps } from "components/PageTopNav/types";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import getSectionIcon, { CircleIcon } from "utils/svgIcons";
import scrollTo from "utils/scrollToWcallback";
import {
	Item,
	sectionMenuItemButtonSxProps,
	sectionMenuItemSxProps
} from "components/PageTopNav/styled";
import { useEffect, useRef } from "react";

export const SectionMenuItem = ({
	item,
	index,
	selectedIndex,
	setSelectedIndex,
	sectionMenuBottomPosition,
	updateSectionMenuBottomPosition,
	setSelectedRef,
	displayLimitedDealDot,
	sawCurrentLimitedDealsSection
}: ISectionMenuItemProps) => {
	const iconName = ["waterfall_sale", "endless_offer"].includes(item.name)
		? "limited_deals"
		: item.name;
	const icon = getSectionIcon(iconName);
	const { reportEvent } = useGoogleAnalyticsContext();
	const sectionMenuItemRef = useRef<HTMLDivElement | null>(null);

	const handleClick = () => {
		setSelectedIndex(index);
		// app bar + nav is 176px from top
		// for phone portrait using -100
		const offset = -72; //-100; //-176;
		let y =
			item?.sectionRef?.current.getBoundingClientRect().top +
			window.pageYOffset +
			offset;

		if (sectionMenuBottomPosition) {
			if (sectionMenuBottomPosition > y) {
				y = y + sectionMenuBottomPosition;
			} else {
				y = y - sectionMenuBottomPosition;
			}
		}

		scrollTo(y, () => {
			updateSectionMenuBottomPosition();
		});

		reportEvent("navbar_click", {
			source: item.analytics_source + "_page",
			navbar_type: item.text.toLowerCase()
		});
	};

	useEffect(() => {
		if (index === selectedIndex) {
			setSelectedRef(sectionMenuItemRef?.current);
			if (iconName === "limited_deals") {
				sawCurrentLimitedDealsSection();
			}
		}
	}, [selectedIndex]);

	return (
		<Item
			ref={sectionMenuItemRef}
			key={item.text + "-" + item.name}
			sx={sectionMenuItemSxProps}
			className={classnames({
				selected: index === selectedIndex,
				highlight:
					iconName === "limited_deals" &&
					index !== selectedIndex &&
					displayLimitedDealDot
			})}
		>
			<Button
				variant="contained"
				onClick={handleClick}
				sx={sectionMenuItemButtonSxProps}
			>
				{icon?.svg ? icon.svg : <CircleIcon />}
				{item.text}
			</Button>
		</Item>
	);
};
