import { Grid } from "@mui/material";
import loyaltyV2Icon from "assets/loyalty-icon-v2.png";
import PlusIcon from "assets/plus-icon.svg";
import classNames from "classnames";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import Button from "components/Buttons/ThemeButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { IShopPurchaseOffer, IShopPurchaseOfferSingleItem } from "contexts/WebStoreProvider/types";
import { useAppDispatch, useAppSelector } from "hooks";
import useRewardsById from "hooks/useRewardsById";
import { WsDialogs } from "interfaces";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { featureTrackerAPI, useGetOffersQuery } from "redux/apis";
import { onClosePopUp, onDIPtrigger, selectPopUpManagerState } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { BonusFlag } from "./lib/Flag";
import {
	Container,
	ContentBackground,
	Plus,
	RewardImage,
	Text,
} from "./styles";

interface SingleItemInfoProps { }

export const SingleItemInfo: React.FC<SingleItemInfoProps> = () => {
	const buyButtonRef = useRef(null);
	const dispatch = useAppDispatch();
	const scale = useCalculateScale(378, 380);
	const { openDialog } = useDialogModalRedux();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { variant } = useAppSelector(selectPopUpManagerState);
	const { data } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: true
	});
	const {
		authToken,
		currentOffer,
		handleRefresh,
		openLoginToContinueDialog,
		postUserMetadata,
		setActiveItemToBeClicked,
		setCurrentOffer,
		store,
		suppressOrderConfirmation,
		userTracker,
		wsStoreBuy,
	} = useWebStoreContext();
	const [isCurrentOffer, setIsCurrentOffer] = useState<boolean>(false);
	const [offerData, setOfferData] = useState<IShopPurchaseOfferSingleItem & IShopPurchaseOffer>();
	const displayRewards = useRewardsById(offerData?.rewardId as string);

	const firstPurchaseText = useMemo(() => {
		return (
			offerData?.firstPurchaseRewardBonus &&
			store?.globalConfig
				?.find((c) => c.id === "first_purchase_badge_text")
				?.value?.replace(
					"{amount}",
					Number(offerData.firstPurchaseRewardBonus).toLocaleString("en-US")
				)
		);
	}, [offerData?.firstPurchaseRewardBonus, store?.globalConfig]);

	const resetCurrentOffer = () => {
		setIsCurrentOffer(false);
		setCurrentOffer(null);
	};

	const cbClose = () => {
		resetCurrentOffer();
		reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
		handleRefresh();
		dispatch(onDIPtrigger("cache-mode"));
	};

	const onClose = () => {
		dispatch(
			featureTrackerAPI.util.invalidateTags([
				{
					type: "featureTrackerTag",
					id: "drip-offer"
				}
			])
		);
		dispatch(onClosePopUp());
	};

	const onClickBuy = async () => {
		if (offerData) {
			// setCurrentOffer used for button states
			setCurrentOffer(buyButtonRef.current);
			const {
				campaignGroup,
				id: offerId,
				loyaltyPointCrossout,
				onlyNonPayers,
				price,
				priority,
				purchaseLimit,
				rewardCrossout,
				rewardId,
				sectionName,
			} = offerData;
			if (authToken?.value) {
				const invoice = await wsStoreBuy({ offerId });
				if (invoice) {
					console.log("suppressOrderConfirmation", suppressOrderConfirmation);
					openDialog(WsDialogs.XSOLLA_PURCHASE, {
						xsollaToken: invoice.xsollaToken,
						xsollaHost: invoice.xsollaHost,
						source: "offer_card",
						cbClose,
						suppressOrderConfirmation,
						postUserMetadata,
						showClose: true,
						onPurchaseStarted: (data) => {
							reportEvent("purchase_started", {
								tuid: userTracker?.id as string,
								offer_id: offerId,
								reward_id: rewardId,
								priority: priority,
								price_in_cents: Math.round(price * 100),
								campaign_group: campaignGroup,
								section_name: sectionName,
								only_non_payers: onlyNonPayers,
								loyalty_point_crossout: typeof loyaltyPointCrossout !== "undefined",
								items_crossout: typeof rewardCrossout !== "undefined",
								firstPurchaseText,
								first_purchase_bonus: firstPurchaseText,
								invoice_id: invoice?.id,
								paystation_id: data?.payStation
							});
						},
						onPurchaseSuccess: () => {
							reportEvent("purchase_success", {
								invoice_id: invoice?.id
							});
							dispatch(onClosePopUp());
						}
					});
				} else {
					resetCurrentOffer();
				}

				//NOTE: bfloyd error cases already handled for undefined/null invoice
			} else {
				// setActiveItemToBeClicked used for re-opening after authentication
				setActiveItemToBeClicked({
					type: "store_offer",
					offerId,
					onlyNonPayers,
					purchaseLimit
				});
				openLoginToContinueDialog();
			}
		}
	};

	useEffect(() => {
		reportEvent("single_items", {
			source: "info_button_click",
		});
	}, []);

	useEffect(() => {
		if (data) {
			const id = variant?.split("-").slice(-1)?.[0];
			const offer = data?.offers.find(
				(offer: IShopPurchaseOffer) => offer.id === id
			) as IShopPurchaseOfferSingleItem & IShopPurchaseOffer;
			setOfferData(offer);
		}
	}, [data]);

	if (!offerData) {
		return <Fragment />
	}

	const {
		bannerText,
		description,
		firstPurchaseRewardBonus,
		loyaltyPointReward,
		price,
		rewardImageUrl,
		firstPurchaseBonusImageUrl,
	} = offerData;

	return (
		<Container scale={scale}>
			<CloseIconButton onClose={onClose} />
			{bannerText && <BonusFlag text={bannerText} />}
			<Grid container spacing={1} position="relative" mt={3} mb={2}>
				<Grid item xs={firstPurchaseRewardBonus ? 6 : 12}>
					<Text color="#333">You get:</Text>
					<ContentBackground>
						<RewardImage src={rewardImageUrl} />
						<Text color="#000">{description.replace("x", "")}</Text>
					</ContentBackground>
				</Grid>
				{firstPurchaseRewardBonus && (
					<>
						<Plus src={PlusIcon} alt="Plus" />
						<Grid item xs={6}>
							<Text color="#333333">First purchase bonus:</Text>
							<ContentBackground>
								<RewardImage src={firstPurchaseBonusImageUrl} />
								<Text color="#000">
									{`${Number(firstPurchaseRewardBonus).toLocaleString("en-US")} ${Number(firstPurchaseRewardBonus) > 1 ? displayRewards?.[0]?.pluralLabel : displayRewards?.[0]?.singularLabel}`}
								</Text>
							</ContentBackground>
						</Grid>
					</>
				)}
			</Grid>
			<Text color="#333" align="center">Earn Loyalty Points with every purchase</Text>
			<Text color="#333" align="center">and redeem for rewards!</Text>
			<Grid item my={2}>
				<ContentBackground>
					<RewardImage src={loyaltyV2Icon} alt="Loyalty points icon" />
					<Text color="#000">{Number(loyaltyPointReward).toLocaleString("en-US")} Loyalty Points</Text>
				</ContentBackground>
			</Grid>
			<Button
				ref={buyButtonRef}
				variant="contained"
				color="success"
				size="large"
				onClick={onClickBuy}
				sx={{
					width: "100%",
					paddingTop: "0.5rem",
					paddingBottom: "0.5rem"
				}}
				className={classNames({
					isCurrentOffer,
					isCurrentOfferSet: !!currentOffer
				})}
			>
				{price} USD
			</Button>
		</Container>
	);
};
