import styled from "styled-components";

export const Day = styled.div`
	overflow: hidden;
	padding-top: 15px;
	position: relative;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 14px;
	background-color: #00000026;
	box-shadow: 0px 9.386210441589355px 7.508967876434326px 0px #0000001a;
	backdrop-filter: blur(5px);
	height: 70px;

	&.last-item {
		height: 80px;
	}

	@media (min-width: 650px) {
		height: 90px;
		&.last-item {
			height: unset;
		}
	}
	@media (min-width: 650px) and (max-height: 430px) {
		height: 55px;
	}
	@media (min-width: 1150px) {
		height: 100px;
	}
`;

export const Banner = styled.div`
	position: absolute;
	top: -4.5px;
	left: 0;
	width: 100%;
	height: 25px;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 2;
	&.last-item {
		height: 30px;
		@media (min-width: 650px) and (max-height: 430px) {
			height: 26px;
		}
	}

	.flag {
		width: 100%;
		position: absolute;
		bottom: 0;
		z-index: -1;
	}

	@media (min-width: 650px) {
		//height: 28px;
	}
	@media (min-width: 1150px) {
		top: 0;

		&.last-item {
			height: 28px;
		}
	}
`;

export const BannerText = styled.span`
	font-family: "Baloo";
	color: #fff;
	font-size: 16px;
	transform: translateY(0px);

	&.collected {
		opacity: 0.5;
	}

	@media (min-width: 650px) {
		font-size: 17px;
		transform: translateY(1px);
	}
	@media (min-width: 1150px) {
		font-size: 18px;
		transform: translateY(-1px);
	}
`;

export const Image = styled.img<{ lastItem: boolean }>(
	({ lastItem }) => `
	object-fit: contain;
	z-index: 2;

	// desktop
	@media (min-width: 1150px) {
		width: ${lastItem ? "90px" : "70px"};
		height: ${lastItem ? "90px" : "70px"};
	}
	// tablet
	@media (min-width: 650px) {
		width: ${lastItem ? "75px" : "60px"};
		height: ${lastItem ? "75px" : "60px"};
	}
	// landscape mobile
	@media (min-width: 650px) and (max-height: 430px) {
		width: ${lastItem ? "50px" : "35px"};
		height: ${lastItem ? "50px" : "35px"};
	}
	// mobile
	@media (max-width: 649px) {
		width: ${lastItem ? "80px" : "55px"};
		height: ${lastItem ? "80px" : "55px"};
	}

	&.collected {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		opacity: 0.5;
	}
`
);

export const StarBurst = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
`;

export const Checked = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -40%);
	width: 50px;
	height: 50px;
	z-index: 4;

	@media (min-width: 650px) and (max-height: 430px) {
		width: 40px;
		height: 40px;
		transform: translate(-50%, -30%);
	}
`;

export const Locked = styled.img`
	position: absolute;
	top: 5px;
	right: -5px;
	width: 40px;
	height: 40px;
	z-index: 4;

	@media (min-width: 650px) and (max-height: 430px) {
		width: 30px;
		height: 30px;
		right: 0px;
	}
`;

export const Number = styled.span`
	font-family: "Baloo";
	z-index: 3;
	color: white;
	text-shadow: 0 0 3px #000000, 0 0 4px #000000, 0 0 4px #000000,
		0 0 3px #000000;

	display: flex;
	font-size: 23px;
	align-items: center;
	justify-content: left;

	&.collected {
		opacity: 0.5;
	}
`;
