import { useWebStoreContext } from "contexts";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";

const useNewDay = () => {
	const { nowTime } = useWebStoreContext();
	const getCurrentDate = () => {
		if (!nowTime) {
			return;
		}
		const pacificTime = DateTime.fromISO(nowTime.toISOString())
			.setZone("America/Los_Angeles")
			.toISO();
		return pacificTime;
	};

	const [initialDate, setInitialDate] = useState(getCurrentDate());
	const [newDay, setNewDay] = useState<string | undefined>();

	useEffect(() => {
		if (!nowTime) {
			return;
		}
		const currentDate = getCurrentDate();
		if (currentDate?.slice(0, 10) !== initialDate?.slice(0, 10)) {
			setInitialDate(currentDate);
			setNewDay(currentDate);
		}
	}, [nowTime]);

	return {
		newDay
	};
};

export default useNewDay;
