import { useWebStoreContext } from "contexts/WebStoreProvider";
import { profileIconPath } from "utils/svgIcons";
import { ProfileImage } from "../styled";
import { LogOutButtonComponent } from "./LogoutButton";
import { useScopelyIdentityContext } from "contexts";

export const ProfileImageComponent = () => {
	const { profileImage, fbAccessToken } = useWebStoreContext();
	const { sidAccessToken, sidIdentityToken } = useScopelyIdentityContext();

	return (
		<ProfileImage color={fbAccessToken ? "#3B5998" : sidAccessToken && sidIdentityToken ? "#016ED3" : "#01799f"}>
			<span className="imgWrap">
				<img src={profileImage || profileIconPath} />
				<LogOutButtonComponent />
			</span>
		</ProfileImage>
	);
};
