import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { onClaimReward } from "../thunks";
import { updatePoisBountySlice } from "./update-slice";
import { PoisBountySlice } from "../types";

export const CLAIM_REWARD_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<PoisBountySlice>
): void => {
	builder.addCase(onClaimReward.pending, (state) => {
		state.claimingReward = true;
	});

	builder.addCase(onClaimReward.fulfilled, (state, action) => {
		updatePoisBountySlice(state, action);
		state.claimingReward = false;
	});
};
