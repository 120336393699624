import { PREFIX } from "./helper";
import { RootState } from "redux/store";
import { initialState } from "./initial-state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WaterfallResponse } from "interfaces/waterfall-sale";

export const waterfallSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		setWaterfallResponse: (state, action: PayloadAction<WaterfallResponse>) => {
			state.response = action.payload;
			state.sessionTracker = action.payload?.sessionTracker;
		}
	}
});

export const { setWaterfallResponse } = waterfallSlice.actions;

export const selectWaterfallState = (state: RootState) => state.waterfall;
export default waterfallSlice.reducer;
export * from "./types";
export * from "./thunks";
