import { getStartLogin, getTryConcludeLogin } from "./login";
import { getStartLogout, getTryConcludeLogout } from "./logout";
import { IDebugLogger } from "../../../utils/debugLogger";
import { scopelyId } from "../config";
import { Dispatch, SetStateAction } from "react";
import { LoginResult, LogoutResult } from "@scopely/web-sdk-scopelyid";
/* eslint-disable */
export interface IScopelyIdentityApi {
	startLogin: (origPath: string) => Promise<void>;
	startLogout: (logoutActionType: string, origPath: string) => Promise<void>;
	tryConcludeLogin: (onSuccess?: () => void) => Promise<LoginResult>;
	tryConcludeLogout: (onSuccess?: () => void) => Promise<LogoutResult>;
}
/* eslint-enable */

export const initAPI = ({
	setSIDAccessToken,
	setSIDIdentityToken,
	debug
}: {
	setSIDAccessToken: Dispatch<SetStateAction<string | null>>;
	setSIDIdentityToken: Dispatch<SetStateAction<string | null>>;
	debug: IDebugLogger;
}): IScopelyIdentityApi => {
	return {
		startLogin: getStartLogin({ scopelyId, debug }),
		tryConcludeLogin: getTryConcludeLogin({
			scopelyId,
			setSIDAccessToken,
			setSIDIdentityToken,
			debug
		}),
		startLogout: getStartLogout({ scopelyId, debug }),
		tryConcludeLogout: getTryConcludeLogout({
			scopelyId,
			setSIDAccessToken,
			setSIDIdentityToken,
			debug
		})
	};
};
