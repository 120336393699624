/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSpring, animated } from "@react-spring/web";
import { useWebStoreContext } from "contexts";
import React, { useEffect, useState } from "react";
import { Container, Star, StarContainer } from "./styles";
import confetti from "canvas-confetti";
import { CrownIcon } from "utils/svgIcons";
import { useAppSelector } from "hooks";
import { selectDipState, selectPopUpManagerState } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

const starsCount = 30;
const sparkle = 5;

const starStyles = Array.from({ length: starsCount }, () => ({
	top: `${Math.random() * 100}%`,
	left: `${Math.random() * 100}%`,
	animationDelay: `${Math.random() * sparkle}s`
}));

interface LoyaltyPointsProps {
	points: number;
}

const LoyaltyPoints: React.FC<LoyaltyPointsProps> = ({ points = 0 }) => {
	const [isAnimating, setIsAnimating] = useState(false);

	const [currentPoints, setCurrentPoints] = useState(points);

	const pointsDifference = points - currentPoints;

	const { variant: popupManagerVariant } = useAppSelector(
		selectPopUpManagerState
	);
	const { variant: dialogVariant } = useDialogModalRedux();
	const { currentDip: dipVariant } = useAppSelector(selectDipState);

	const countAnimation = useSpring({
		//number: 100,
		from: { number: currentPoints },
		to: { number: points },
		config: {
			duration:
				pointsDifference < 0
					? 0
					: pointsDifference <= 200
					? 2000
					: Math.min(6500, pointsDifference * 10)
		},
		pause: !isAnimating,
		onRest: () => {
			setIsAnimating(false);
			setCurrentPoints(points);
		}
	});

	const startAnimation = () => {
		countAnimation.number.reset();
		setIsAnimating(true);
		//confetti();
	};

	const isPopupsOpen = () => {
		return popupManagerVariant || dialogVariant || dipVariant;
	};

	useEffect(() => {
		if (points !== currentPoints && !isPopupsOpen()) {
			startAnimation();
		}
	}, [points, popupManagerVariant, dialogVariant, dipVariant]);

	return (
		<Container>
			<StarContainer className={isAnimating ? "in" : "out"}>
				{starStyles.map((style, i) => (
					<Star key={i} style={style} />
				))}
			</StarContainer>
			<CrownIcon />
			<animated.div style={{ zIndex: 2 }}>
				{countAnimation.number.to(
					(val) => `${Math.floor(val).toLocaleString("en-US")}`
				)}
			</animated.div>
		</Container>
	);
};

export default LoyaltyPoints;
