import { Box } from "@mui/material";
import styled from "styled-components";

export const WinnersItem = styled(Box)`
	position: relative;
	border-radius: 16px;
	overflow: hidden;
	background: #fffaf1;
	box-shadow: 0px 11.0701px 11.0701px rgba(0, 0, 0, 0.1);
	color: #014189;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	a,
	.date {
		color: #01a1bd;
		text-decoration: none;
	}
`;

export const ShareContainer = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
	margin: 1rem;
	position: absolute;
	bottom: 0;
	right: 0;
	span {
		color: #7e7e7e;
		//margin-right: 0.5rem;
		text-align: right;
		font-size: 13px;
		font-family: Inter;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}
`;

export const Helper = styled.div`
	height: 50px;
	@media (max-width: 900px) {
		height: 60px;
	}
`;

export const ShareButton = styled.button`
	border: unset;
	background-color: unset;
	cursor: pointer;
	border-radius: 100%;
	background: #3b5998;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;
`;

export const Content = styled("div")`
	max-width: 790px;
	padding: 0.3rem;

	p {
		margin: 0;
		width: 100%;
	}
	div[dir="auto"] {
		margin-bottom: 1rem;
	}

	.alignright,
	.alignleft {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.alignright {
		float: right;
		margin-left: 2rem;
	}
	.alignleft {
		float: left;
		margin-right: 2rem;
	}
	img {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 600px;
		margin: auto auto;
	}
	strong img {
		display: block;
	}
	/* ol,
	ul {
		margin-left: 0;
		padding-left: 2%;
		li {
			ol,
			ul {
				margin-left: 0;
				padding-left: 2%;
				li {
					ol,
					ul {
						margin-left: 0;
						padding-left: 1%;
						li {
							ol,
							ul {
								margin-left: 0;
								padding-left: 1%;
							}
						}
					}
				}
			}
		}
	} */

	/* ul,
	ol,
	p {
		img {
			display: block;
			max-height: 768px;
			border-radius: 18px;
		}
	} */
`;
