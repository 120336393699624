import { items } from "./items";
import { useState } from "react";
import { MerchItem } from "./lib";
import { PrivateRoute } from "hoc";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts/EnvProvider";
import { Container, Background, GridMerch } from "./styles";
import { useInitialActions } from "./hooks/useInitialActions";
import background from "assets/merch-shop/banner-merch-shop-desktop.png";
import backgroundMobile from "assets/merch-shop/banner-merch-shop-mobile.png";
import PageTopNav, { ISectionMenuItem } from "../../components/PageTopNav";
import BackToTopButton from "components/PageContent/lib/PageBackToTopButton";
import { useMediaQuery } from "@mui/material";

const MerchShop = () => {
	const isLowWidth = useMediaQuery("(max-width:820px)");
	const [sectionMenuData] = useState<ISectionMenuItem[]>([]);
	const { isFeatureFlagEnabled } = useEnvContext();
	useInitialActions();

	return (
		<PrivateRoute isEnabled={isFeatureFlagEnabled(FeaturesFlags.MERCH_SHOP)}>
			<Container>
				<Background src={isLowWidth ? backgroundMobile : background} />
				<PageTopNav
					navItemColor="#FFE37E"
					sectionMenuData={sectionMenuData}
					isLandingPage
				/>
			</Container>
			<GridMerch>
				{items.map((item, key) => (
					<MerchItem item={item} key={key} />
				))}
			</GridMerch>
			<BackToTopButton
				source="merch_shop_page"
				style={{ position: "relative", marginTop: 0 }}
			/>
		</PrivateRoute>
	);
};

export default MerchShop;
