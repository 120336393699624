import { Link, styled } from "@mui/material";
import { ScrollDownIcon } from "utils/svgIcons";

export const ScrollToContentButton = ({
	sectionContentRef
}: {
	sectionContentRef: React.RefObject<HTMLElement | null>;
}) => {
	const handleClick = () => {
		if (!sectionContentRef.current) {
			return;
		}
		const offset = 0;
		const y =
			sectionContentRef.current.getBoundingClientRect().top +
			window.pageYOffset +
			offset;
		scrollTo({ top: y, behavior: "smooth" });
	};
	return (
		<StyledLink
			className="hvr-hang hvr-glow"
			onClick={handleClick}
			sx={{
				display: { xs: "none", sm: "block" },
				position: "relative",
				cursor: "pointer",
				opacity: 0.6,
				"&:hover": { opacity: 1 },
				svg: {
					width: { lg: "3vw" },
					height: { lg: "3vw" }
				}
			}}
		>
			<ScrollDownIcon />
		</StyledLink>
	);
};

const StyledLink = styled(Link)`
	border-radius: 2vw;
	line-height: 0.9;
	@-webkit-keyframes hvr-hang {
		0% {
			transform: translateY(8px);
		}
		50% {
			transform: translateY(4px);
		}
		100% {
			transform: translateY(8px);
		}
	}
	@keyframes hvr-hang {
		0% {
			transform: translateY(8px);
		}
		50% {
			transform: translateY(4px);
		}
		100% {
			transform: translateY(8px);
		}
	}
	@-webkit-keyframes hvr-hang-sink {
		100% {
			transform: translateY(8px);
		}
	}
	@keyframes hvr-hang-sink {
		100% {
			transform: translateY(8px);
		}
	}

	/*
		Hang - reversed non-hover hanging
	*/
	&.hvr-hang {
		animation-name: hvr-hang-sink, hvr-hang;
		animation-duration: 0.3s, 1.5s;
		animation-delay: 0s, 0.3s;
		animation-timing-function: ease-out, ease-in-out;
		animation-iteration-count: 1, infinite;
		animation-fill-mode: forwards;
		animation-direction: normal, alternate;
	}
	&.hvr-hang:hover,
	&.hvr-hang:focus,
	&.hvr-hang:active {
		display: inline-block;
		vertical-align: middle;
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	}

	&.hvr-hang:active {
		opacity: 0.5;
	}
`;
