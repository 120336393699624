import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getAvailableTriggers } from "../helper";
import { DipSlice } from "../types";
import {
	getAllDIPs,
	getCurrentSegments,
	getCurrentUserSegments
} from "../thunks";

export const DEBUG_EXTRA_REDUCER = (
	builder: ActionReducerMapBuilder<DipSlice>
): void => {
	builder.addCase(getAllDIPs.fulfilled, (state, action) => {
		if (action.payload) {
			state.debug.data = action.payload;
			state.mode = "debug";
			state.availableTriggers = getAvailableTriggers(action.payload.dips);
		}
	});

	builder.addCase(getCurrentSegments.fulfilled, (state, action) => {
		const segments = action.payload;
		if (segments) {
			state.debug.segments = segments;
		}
	});

	builder.addCase(getCurrentUserSegments.fulfilled, (state, action) => {
		const userSegment = action.payload;
		if (userSegment) {
			state.debug.userSegment = userSegment;
		}
	});
};
