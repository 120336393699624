import { Box, styled } from "@mui/material";
/* eslint-disable */
export const PBBackground = styled(Box)`
	background-repeat: no-repeat;
	background-color: #0184a4;
	background-image: linear-gradient(
			153.95deg,
			rgba(1, 125, 156, 0) 41.84%,
			#0183a2 86.81%
		),
		url(${(props: any) => props["data-background-image"]});

	@media (max-width: 755px) {
		background-image: linear-gradient(
				153.95deg,
				rgba(1, 125, 156, 0) 41.84%,
				#0183a2 86.81%
			),
			url(${(props: any) => props["data-background-image"]});
	}
`;
/* eslint-enable */
export const PBLogoText = styled("img")`
	width: 200px;
`;

export const PBTextShadow = styled(Box)`
	font-family: "Baloo";
	color: white;
	text-shadow: 1.0996px 4.0996px 0px #000000, 0px -2px 0px #000000;
	font-size: 28px;
`;
