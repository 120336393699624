import { baseApi } from "../api";

export const featureTrackerAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getFeatureTracker: builder.query({
			query: (arg) => `/rest/${arg.FeatureType}/tracker`,
			providesTags: (result, error, arg) => [
				{ type: "featureTrackerTag", id: arg.FeatureType }
			]
		}),
		deleteFeatureTracker: builder.mutation({
			query: (arg) => ({
				url: `/rest/debug/${arg.FeatureType}/tracker/delete`,
				method: "POST"
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: "featureTrackerTag", id: arg.FeatureType }
			]
		})
	})
});

export const { useGetFeatureTrackerQuery, useDeleteFeatureTrackerMutation } =
	featureTrackerAPI;
