import { SiteLinks } from "interfaces";

export const links: SiteLinks = {
	back_to_game: {
		path: "https://gsngames-tripeaks.onelink.me/o8Pf/6d0oh30l",
		title: "Back to Game",
		analytics_key: "click_back_to_game",
		isExternal: true,
		target: "_blank"
	},
	tos: {
		path: "http://scopely.com/tos",
		title: "Terms of Service",
		analytics_key: "terms_of_service_click",
		isExternal: true
	},
	privacy: {
		path: "http://scopely.com/privacy",
		title: "Privacy Policy",
		analytics_key: "privacy_policy_click",
		isExternal: true
	},
	privacy_ads_toggle: {
		path: "/privacy-ads-toggle",
		title: "Do Not Sell or Share My Personal Information",
		analytics_key: "ads_toggle_page_click"
	},
	forum: {
		path: "https://forum.solitairetripeaks.com/",
		title: "Forum",
		description: "Connect with other players.",
		analytics_key: "forum_section_click",
		isExternal: true
	},
	appstore_apple: {
		path: "https://apps.apple.com/US/app/id892521917",
		title: "iOS App Store",
		analytics_key: "appstore_apple_click",
		isExternal: true
	},
	appstore_google: {
		path: "https://play.google.com/store/apps/details?id=com.gsn.android.tripeaks",
		title: "Google Play Store",
		analytics_key: "appstore_google_click",
		isExternal: true
	},
	social_facebook: {
		path: "https://facebook.com/solitairetripeaks",
		title: "Facebook",
		analytics_key: "social_facebook_click",
		isExternal: true
	},
	social_twitter: {
		path: "https://twitter.com/TriPeaksApp",
		title: "Twitter",
		analytics_key: "social_twitter_click",
		isExternal: true
	},
	social_instagram: {
		path: "https://www.instagram.com/tikisolitairetripeaks/",
		title: "Instagram",
		analytics_key: "social_instagram_click",
		isExternal: true
	},
	faq: {
		title: "FAQ",
		path: "https://scopely.helpshift.com/hc/section/1963-web-shop/",
		analytics_key: "faq_section_click",
		description: "Have questions? Find answers.",
		isExternal: true
	},
	merch_shop: {
		path: "/merch-shop",
		title: "Merch Shop",
		description: "Our Official Merchandise Shop.",
		analytics_key: "web_merch_shop_section_click"
	},
	news: {
		path: "/news",
		title: "News",
		description: "Stay Up to Date with Our Latest News, Updates!",
		analytics_key: "news_section_click"
	},
	home: {
		path: "/",
		title: "Home",
		description: "Tiki Solitaire TriPeaks homepage.",
		analytics_key: "home_page_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_home"
	},
	winners: {
		path: "/winners",
		title: "Winners",
		description: "Celebrate the Champions of Tiki Solitaire TriPeaks.",
		analytics_key: "winners_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_winners"
	},
	shop: {
		path: "/shop",
		title: "Shop",
		description: "The one-stop-shop.",
		analytics_key: "shop_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_web_shop"
	},
	pois_bounty: {
		path: "/pois-bounty",
		title: "Poi's Bounty",
		description: "Collect rewards from Poi.",
		analytics_key: "pois_bounty_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_pois_bounty"
	},
	club_shop: {
		path: "/club-shop",
		title: "Club Shop",
		description: "Shop for your Club.",
		analytics_key: "club_shop_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_club_shop"
	},
	loyalty: {
		path: "/loyalty-points",
		title: "Loyalty Points",
		description: "Be Rewarded for Your Loyalty.",
		analytics_key: "loyalty_shop_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_loyalty_shop"
	},
	free_daily_gifts: {
		path: "/free-daily-gifts",
		title: "Free Daily Gifts",
		description: "Claim DAILY GIFTS with Your 7-Day Gift Streak!",
		analytics_key: "daily_gift_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_daily_free_gift"
	},
	club_free_gift: {
		path: "/club-free-gift",
		title: "Club Free Gift",
		description: "A free daily gift for your whole club.",
		analytics_key: "club_free_gift_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_club_free_gift"
	},
	drip_offer: {
		path: "/special-offers",
		title: "Special Offers",
		description: "Extra Prizes available for a Limited Time.",
		analytics_key: "special_offer_section_click",
		mega_menu_sale_tag_event_name: "mega_menu_tag_drip_offer"
	},
};

export const PATH_SECTIONS = {
	[links.home.path]: "Home",
	[links.shop.path]: "Shop",
	[links.club_shop.path]: "Club Shop",
	[links.loyalty.path]: "Loyalty Points",
	[links.free_daily_gifts.path]: "Free Daily Gifts",
	[links.club_free_gift.path]: "Club Free Gift",
	[links.news.path]: "All News",
	[links.forum.path]: "Forum",
	[links.faq.path]: "FAQ",
	[links.pois_bounty.path]: "Poi's Bounty",
	[links.merch_shop.path]: "Merch Shop",
	[links.drip_offer.path]: "Special Offers",
};
