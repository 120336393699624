import { Fragment, useEffect, useState } from "react";
import { useGiftGaloreTracker } from "../..";
import { useMediaQuery } from "@mui/material";
import { useGetOffersQuery } from "redux/apis";
import CountdownV2 from "components/CountdownV2";
import { useAppDispatch, useAppSelector } from "hooks";
import { getVariantImage } from "utils/getVariantImage";
import { Info, InfoButton, ProgressBar, ProgressInfo } from "./lib";
import { Container, tabletGiftsGaloreView, timeStyle } from "./styles";
import { BannerCard } from "pages/ClubShop/GiftBoxesSection/BannerCard";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { onGiftGaloreEventEnded, selectGiftsGaloreState } from "redux/slices";

export const GiftsGaloreCard = () => {
	const dispatch = useAppDispatch();
	const { nowTime, store, authToken } = useWebStoreContext();
	const { trackerLoading } = useAppSelector(selectGiftsGaloreState);
	const { reportEvent } = useGoogleAnalyticsContext();
	const { tracker, moreClubMembersNeeded, eventIsCompleted } = useGiftGaloreTracker();
	const [bannerGlobalImageId, setBannerGlobalImageId] = useState<string>();
	const {
		id,
		purchaseCount,
		backgroundImageUrl,
		purchaseCountRequired,
		completedBackgroundImageUrl
	} = tracker;

	const isMobile = useMediaQuery(`(max-width:${tabletGiftsGaloreView})`);
	const progressBar = 15 + ((100 - 15) / purchaseCountRequired) * purchaseCount;

	// @ts-expect-error
	const showCountDown = typeof nowTime._tzm !== "undefined";
	const { data, isLoading, refetch, isFetching } = useGetOffersQuery(
		{
			offerType: "clubGift",
			isAuthenticated: !!authToken?.value
		},
		{ pollingInterval: 600000 }
	);

	useEffect(() => {
		reportEvent("club_shop_event", {
			source: "club_gifts_galore",
			club_gifts_galore_id: id,
			progress: purchaseCount,
			maximum_threshold: purchaseCountRequired,
			more_club_members_needed: moreClubMembersNeeded
		});
	}, []);

	useEffect(() => {
		if (!!store && !!data && eventIsCompleted) {
			setBannerGlobalImageId(
				store.clubGifts.find((gift) => gift.id === data.offers[0].id)?.bannerGlobalImageId ?? undefined
			);
		}
	}, [store, data, eventIsCompleted]);

	return (
		<>
			{!isLoading && !isFetching && !trackerLoading && (
				<>
					{bannerGlobalImageId ? (
						<BannerCard offer={data?.offers[0]} refetch={refetch} />
					) : (
						<Container
							backgroundImage={getVariantImage(
								eventIsCompleted
									? completedBackgroundImageUrl
									: backgroundImageUrl,
								"{club_gifts_galore_variant}",
								isMobile ? "small" : "large"
							)}
						>
							<Info />
							<InfoButton />
							{!eventIsCompleted && (
								<Fragment>
									<ProgressBar progress={progressBar} />
									<ProgressInfo />

									{showCountDown && (
										<CountdownV2
											style={timeStyle}
											startTime={nowTime}
											text="Event ends in: "
											endTime={tracker.endTime}
											onEnd={() => {
												dispatch(onGiftGaloreEventEnded());
												refetch();
											}}
											labelStyle={{ fontSize: isMobile ? ".82rem" : "unset" }}
										/>
									)}
								</Fragment>
							)}
						</Container>
					)}
				</>
			)}
		</>
	);
};
