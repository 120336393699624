import { styled } from "@mui/material";

export const CarouselStyled = styled("div")`
	width: 100%;
	.mySwiper {
		width: 350px;
		height: 340px;
	}

	&.mobile {
		height: 260px;
	}

	.swiper-wrapper {
		height: 205px;
		margin-left: 5px;
	}
	.swiper-pagination {
		.swiper-pagination-bullet {
			border: 2px solid #fff;
			background-color: transparent;
			&.swiper-pagination-bullet-active {
				background-color: #fff;
			}
		}
	}

	&.portrait {
		padding-top: 2rem;
		.swiper-wrapper {
			height: 425px;
		}
	}
`;
