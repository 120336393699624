/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flag } from "./svg";
import classNames from "classnames";
import { useLoadReward } from "hooks";
import { useStreakVariant } from "../../hooks";
import { StreakDay as Streak } from "interfaces";
import checkedPNG from "assets/daily-gift/check.png";
import lockedPNG from "assets/daily-gift/locked.png";
import starBurstPNG from "assets/daily-gift/star-burst.png";
import treasureChestPNG from "assets/daily-gift/treasure-chest.png";
import { Day, Image, Number, Banner, StarBurst, BannerText } from "./styles";
import { loadVariantAsset, STREAK_DAY_COLORS } from "./helper";
import useRewardsById from "hooks/useRewardsById";

interface StreakDayProps {
	streakDay: Streak;
	lastItem?: boolean;
}

export const StreakDay: React.FC<StreakDayProps> = ({
	streakDay,
	lastItem = false
}) => {
	const { loadReward } = useLoadReward();
	const { rewardId, dayIndex } = streakDay;
	const { imageUrl, numberOfItems } = loadReward(lastItem ? "" : rewardId);
	const { variant, isAvailable, isLocked } = useStreakVariant(streakDay);
	const Asset = loadVariantAsset[variant];
	const lastItemClassName = classNames(lastItem && "last-item");
	const rewards = useRewardsById(rewardId);

	return (
		<Day
			className={lastItemClassName}
			style={{
				border: isAvailable ? `3px solid ${STREAK_DAY_COLORS[variant]}` : 0
			}}
		>
			<Banner className={lastItemClassName}>
				<BannerText className={classNames(variant)}>
					Day 0{dayIndex + 1}
				</BannerText>
				<Flag color={STREAK_DAY_COLORS[variant]} />
			</Banner>
			{!streakDay.collectedDate && (
				<div
					style={{
						position: "relative",
						width: "100%",
						display: "flex",
						justifyContent: "center"
					}}
				>
					<Image
						className={classNames(variant)}
						lastItem={lastItem}
						src={
							lastItem
								? rewards[0]?.overrideImageUrl ?? treasureChestPNG
								: imageUrl
						}
					/>
					{numberOfItems && (
						<Number
							className={classNames(variant)}
							style={{
								position: "absolute",
								bottom: -12,
								minWidth: "41%",
								right: 3
							}}
						>
							x{numberOfItems?.toLocaleString("en-US")}
						</Number>
					)}
				</div>
			)}

			{lastItem && <StarBurst src={starBurstPNG} />}
			{Asset && <Asset src={isLocked ? lockedPNG : checkedPNG} />}
		</Day>
	);
};
