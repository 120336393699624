import { Chip } from "@mui/material";
import { ReactComponent as CrownIcon } from "../assets/Crown.svg";
import { useWebStoreContext } from "../contexts/WebStoreProvider";

const LoyaltyPointsHeadsUp = () => {
	const isEnabled = false;
	if (!isEnabled) {
		return null;
	}
	const { userTracker } = useWebStoreContext();
	if (!userTracker) {
		return null;
	}

	const loyaltyPoints = userTracker.loyaltyPoints.toLocaleString("en-US");

	return (
		<Chip
			icon={
				<div
					style={{
						backgroundColor: "#64C334",
						color: "white",
						borderRadius: "80px",
						padding: "4px"
					}}
				>
					<CrownIcon />
				</div>
			}
			label={loyaltyPoints}
			sx={{
				position: "fixed",
				zIndex: 1,
				width: "auto",
				maxWidth: "none",
				left: { xs: "36px", lg: "336px" },
				bottom: { xs: "36px", md: "72px" },
				backgroundColor: "rgb(255 255 255 / 95%)",
				fontSize: "200%",
				padding: "1rem 3rem",
				height: "auto",
				maxHeight: "5.7rem",
				borderRadius: "60px",
				paddingLeft: 0,
				paddingRight: "0.5rem",
				filter: "drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.3))",

				".MuiChip-label": {
					color: "#333333"
				},
				".MuiChip-icon": {
					color: "white",
					marginLeft: 1,
					backgroundColor: "rgb(100, 195, 52)",
					borderRadius: "80px",
					padding: "4px"
				},
				".MuiChip-icon svg": {
					width: "auto",
					height: "40px",
					margin: "0.7rem"
				}
			}}
		/>
	);
};

export default LoyaltyPointsHeadsUp;
