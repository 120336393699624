import DebugWebStoreProvider from "../contexts/DebugWebStoreProvider";
import DebugConsole from "./DebugConsole";

const DebugMenu = () => {
	return (
		<DebugWebStoreProvider>
			<DebugConsole />
		</DebugWebStoreProvider>
	);
};

export default DebugMenu;
