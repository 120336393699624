import { styled } from "@mui/material";
import theme from "../../theme";
import flagSvgRight from "../../assets/FlagRightTop.svg";
import flagSvgLeft from "../../assets/FlagLeft.svg";
import flagSvgCenter from "../../assets/Flag.svg";
import flagSpecial from "../../assets/FlagSpecial.svg";

// default _Wrapper_ & _BonusFlag_ centered

export const _Wrapper_ = styled("div")({
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	display: "flex",
	justifyContent: "center",
	zIndex: 1
});
export const _BonusFlag_ = styled("div")`
	background-image: url(${flagSvgCenter});
	background-repeat: no-repeat;
	background-size: contain;
	height: 40px;
	max-width: 217px;
	min-width: 180px;

	p {
		color: rgb(255 255 255);
		font-size: 17px;
		font-size: clamp(17px, 1.7vw, 28px);
		font-style: normal;
		font-weight: 700;
		line-height: 1.5;
		text-align: center;
		text-shadow: 0 4px 4px rgb(0 0 0 / 15%);

		${theme.breakpoints.up("lg")} {
			font-size: 20px;
		}
	}
`;

// position === "center";

export const BonusFlagCenter = styled(_BonusFlag_)``;
export const BonusFlagSpecial = styled(_BonusFlag_)`
	background-image: url(${flagSpecial});
	width: 100%;
	border-radius: 24px;
	height: 60px;
	max-width: 1000px;
	p {
		font-size: 26px;
		color: #593113;
		padding-top: 10px;
		font-weight: 700;
		line-height: 28px;
	}
`;
export const WrapperCenter = styled(_Wrapper_)({
	justifyContent: "center"
});

export const BonusFlagCenterRight = styled(_BonusFlag_)`
	position: absolute;
	right: 20%;
	width: 17vw;
`;

// position === "right";

export const WrapperRight = styled(_Wrapper_)({
	justifyContent: "flex-end"
});
export const BonusFlagRight = styled(_BonusFlag_)`
	background-image: url(${flagSvgRight});
	background-position: "center left";
	background-size: auto 100%;
	min-width: 90px;

	p {
		margin-left: 12px;
		padding-right: 12px;
		padding-left: 12px;

		display: block;
		line-height: 40px;
		white-space: nowrap;
		background-color: #eb5757;
	}
`;

export const BonusFlagRightSmall = styled(_BonusFlag_)`
	background-image: url(${flagSvgRight});
	background-position: "center left";
	background-size: auto 100%;
	min-width: 90px;
	height: 25px;

	p {
		margin-left: 12px;
		padding-right: 20px;
		padding-left: 10px;

		display: block;
		text-transform: uppercase;
		font-size: 17px;
		font-family: Baloo;
		font-style: normal;
		font-weight: 400;
		line-height: 25px;
		white-space: nowrap;
		background-color: #eb5757;
	}
`;

// position === "left";

export const WrapperLeft = styled(_Wrapper_)({
	justifyContent: "flex-start"
});
export const BonusFlagLeft = styled(_BonusFlag_)`
	background-image: url(${flagSvgLeft});
	background-position: right top;
	min-width: 90px;

	p {
		margin-right: 12px;
		padding-left: 12px;
		display: block;
		line-height: 35px;
		white-space: nowrap;
		background-color: #eb5757;
	}
`;
