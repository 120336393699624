export const faqData = [
	{
		question: "What is the TriPeaks Web Shop?",
		answer: {
			type: "text",
			content:
				"The Web Shop is the best place to get exclusive deals on Volcanos, Wilds, Coins, Boosters, and more for Tiki Solitaire TriPeaks. You’ll find offers that aren’t available in the app, plus earn Loyalty Points with every purchase! Loyalty Points can be redeemed on the web for even more rewards!"
		}
	},
	{
		question: "How secure is the TriPeaks Web Shop?",
		answer: {
			type: "text",
			content:
				"The TriPeaks Web Shop uses Xsolla to process payments, ensuring your transactions are protected by advanced SSL encryption. Xsolla is also PCI DSS compliant, meeting the highest security standards for handling your credit card information. You can shop with complete confidence that your personal and payment data are secure."
		}
	},
	{
		question: "How do I make a purchase?",
		answer: {
			type: "steps",
			content: [
				"Visit the TriPeaks Web Shop.",
				"Select your desired item and click 'Buy'.",
				"Enter your payment details at checkout.",
				"Our trusted payment provider Xsolla processes the transaction, offering multiple payment methods.",
				"Confirm your purchase, and your items will appear in your in-game inbox immediately."
			]
		}
	},
	{
		question:
			"Where is my Web Shop purchase & how long does it take to receive it?",
		answer: {
			type: "text",
			content:
				"Your Web Shop purchase will appear in your in-game inbox immediately after the transaction is completed. Simply check your inbox within the game, and you should see your items ready to use right away! If you don’t see it after a few minutes, please refresh the game or contact support."
		}
	},
	{
		question: "What are Loyalty Points?",
		answer: {
			type: "text",
			content:
				"Loyalty Points are earned with every purchase you make in the Web Shop. You can redeem them for extra items in the Loyalty Points section. You also earn points through the Daily Login Reward. Don't forget to check out the Daily Deals in the Loyalty Shop for limited-time offers!"
		}
	}
];
