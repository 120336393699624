import styled, { CSSProperties } from "styled-components";

const Wrapper = styled.section`
	position: fixed;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.7);
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	//overflow-y: auto;
	display: flex;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: center;
`;

const Container = styled.div<{ scale: number }>`
	position: relative;
	border-radius: 24px;
	transition: all ease 0.5s;
	overflow: hidden;
	margin: 10px;
	box-sizing: border-box;
	transform: scale(${(props) => props?.scale});

	&.offers {
		display: flex;
		align-items: flex-end;
		justify-content: center;

		@media (orientation: portrait) {
			width: 460px;
			min-width: 460px;
			height: 824px;
			min-height: 824px;
		}

		@media (orientation: landscape) {
			width: 1050px;
			min-width: 1050px;
			height: 450px;
			min-height: 450px;
		}
	}

	&.bogo {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding-bottom: 1rem;

		@media (orientation: portrait) {
			width: 460px;
			min-width: 460px;
			height: 824px;
			min-height: 824px;
		}

		@media (orientation: landscape) {
			width: 1050px;
			min-width: 1050px;
			height: 450px;
			min-height: 450px;
		}
	}

	&.informative {
		@media (orientation: portrait) {
			margin: 0px 10px 0px 10px;
			width: 400px;
			min-width: 400px;
			height: 564px;
			min-height: 564px;
			img {
				width: 100%;
			}
		}

		@media (orientation: landscape) {
			width: 1050px;
			min-width: 1050px;
			height: 398px;
			min-height: 398px;
		}
	}

	&.buy-it-all {
		display: flex;
		align-items: flex-end;
		justify-content: center;

		@media (orientation: portrait) {
			width: 460px;
			min-width: 460px;
			height: 824px;
			min-height: 824px;
		}

		@media (orientation: landscape) {
			width: 1050px;
			min-width: 1050px;
			height: 450px;
			min-height: 450px;
		}
	}
`;

const Title = styled.div<{ BackgroundImage?: string }>`
	content: url(${(props) => props.BackgroundImage});

	@media (orientation: portrait) {
		max-width: 90%;
		margin: 0 auto;
		margin-top: 80px;
	}

	@media (orientation: landscape) {
		max-width: 600px;
		margin-top: 10px;
	}
`;

const timeStyle: CSSProperties = {
	top: 10,
	left: 10,
	fontSize: "1em"
};

export { Wrapper, Container, Title, timeStyle };
