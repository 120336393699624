import { PoisBountyResponse } from "interfaces";
import { PoisBountySlice } from "./types";

export const initialState: PoisBountySlice = {
	variant: "intro",
	data: {} as PoisBountyResponse,
	rewards: [],
	rewardCollected: undefined,
	claimingReward: false,
	isEventActive: false,
	userLoyaltyPoints: 0,
	eventEnded: false,
	remaining24hour: false,
	responseSucceedWithNoTracker: false
};
