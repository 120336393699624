import { useMediaQuery } from "@mui/material";
import { Button, Image, Item } from "./styles";
import { useGoogleAnalyticsContext } from "contexts";

export type MerchItem = {
	link: string;
	desktopImage: string;
	mobileImage: string;
	buttonLabel: string;
};

interface MerchItemProps {
	item: MerchItem;
}

export const MerchItem: React.FC<MerchItemProps> = ({ item }) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const isLowWidth = useMediaQuery("(max-width:819px)");
	const { desktopImage, buttonLabel, link, mobileImage } = item;

	return (
		<Item>
			<Image src={isLowWidth ? mobileImage : desktopImage} />
			<Button
				onClick={() => {
					reportEvent("spring_merch_shop_link_click", {
						source: "merch_page_merch_button",
						promoted_merch_item: buttonLabel.toLowerCase()
					});
					window.open(link, "_self");
				}}
			>
				{buttonLabel}
				<svg width="25" height="25" viewBox="0 0 25 25" fill="none">
					<path
						d="M11.5586 8.16406H7.54547C7.01504 8.16406 6.50633 8.37478 6.13126 8.74985C5.75618 9.12492 5.54547 9.63363 5.54547 10.1641V17.1641C5.54547 17.6945 5.75618 18.2032 6.13126 18.5783C6.50633 18.9533 7.01504 19.1641 7.54547 19.1641H14.5127C15.0431 19.1641 15.5518 18.9533 15.9269 18.5783C16.302 18.2032 16.5127 17.6945 16.5127 17.1641V13.1641"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M12.6996 11.9278L18.3936 6.27095M18.3936 6.27095L14.137 6.28478M18.3936 6.27095L18.3797 10.4998"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</Button>
		</Item>
	);
};
