import { Typography } from "@mui/material";

export const FooterCopyright = () => {
	return (
		<Typography
			variant="caption"
			sx={{ color: "white", lineHeight: 4, paddingLeft: "16px" }}
		>
			© GSN Games, Inc. 2004-2022. All Rights Reserved.
		</Typography>
	);
};
