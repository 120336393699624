import {
	defaultDebugLoggerProps,
	IDebugLogger
} from "../../../utils/debugLogger";
import {
	IWebStoreContextApiProps,
	IWebStoreContext,
	IShopStore,
	IAuthToken
} from "../types";
import { isMobile } from "react-device-detect";
import { analyticsTracker } from "../../ScopelyIdentityProvider/config";
import moment from "moment";

/* eslint-disable */
export type IGetGetStoreReturnType = (
	storeRaw: IShopStore | null,
	isProcessEnabled?: boolean | undefined,
	debug?: IDebugLogger | undefined,
	forceReload?: boolean | undefined,
	setNowTime?: (now: string) => void,
	authToken?: IAuthToken | null
) => Promise<IWebStoreContext["store"]>;
/* eslint-enable */

interface IGetGetStore extends IWebStoreContextApiProps {
	backendUri: string;
	logout(): void;
	setNowTime(now: string): void;
}

const getGetStore = ({
	axios,
	backendUri,
	popups,
	createHeaders,
	logout,
	setNowTime,
	authToken,
	syntheticId
}: IGetGetStore): IGetGetStoreReturnType => {
	const getStore = async (
		storeRaw: IShopStore | null,
		isProcessEnabled = true,
		debug: IDebugLogger = defaultDebugLoggerProps,
		forceReload = false
	) => {
		// if persisted store found pull it's version string to determine if content has been changed
		let version = null;
		if (
			storeRaw &&
			typeof storeRaw === "object" &&
			Object.keys(storeRaw || {}).length > 0 &&
			!forceReload
		) {
			version = storeRaw.version;
		}

		const deviceToken = analyticsTracker.getDeviceToken();
		const headersMap: {
			[key: string]: string;
		} = {
			"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
			"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId,
			"X-TITAN-DEVICE-TOKEN": deviceToken ? deviceToken : ""
		};
		if (authToken) {
			// check if persisted token exists
			if (authToken?.value) {
				// check if expired token, if not return it.
				const dateDiff = moment.utc(authToken.expiresAt).diff(moment());
				if (dateDiff > 0) {
					debug.logFunction({
						iconText: "getStore:",
						message: ["authToken persisted.."]
					});
					headersMap.Authorization = `Bearer ${authToken?.value}`;
				} else {
					debug.logFunction({
						iconText: "getStore:",
						message: ["authToken expired, logging out!!!"]
					});
					logout();
				}
			}
		}

		const queryParams: {
			version?: string;
		} = {};
		if (version) {
			queryParams.version = version;
		}
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders(headersMap),
			params: queryParams
		};
		const uri = "/rest/store";
		debug.log({ isEnabled: false, icon: "uri", message: [uri] });
		return axios
			.get(uri, requestConfig)
			.then((response) => {
				if (response?.data) {
					debug.log({
						iconText: "getStore:",
						message: [
							response.status,
							"normal response, version:",
							response.data.version
						]
					});
					if (setNowTime && response.headers["x-gsn-webstore-now"]) {
						setNowTime(response.headers["x-gsn-webstore-now"]);
						response.data["nowTime"] = response.headers["x-gsn-webstore-now"];
					}
					return response.data;
				} else {
					popups.showErrorDialog("get_store_no_data", "get store no data");
					return null;
				}
			})
			.catch((err) => {
				const {
					response: { status }
				} = err;
				if (status === 304) {
					debug.log({
						iconText: "getStore:",
						message: [
							status,
							"cached response, version:",
							storeRaw?.version + "\n",
							isProcessEnabled && storeRaw
								? " process storeRaw"
								: " skip processing"
						]
					});
					if (err.response.headers["x-gsn-webstore-now"]) {
						setNowTime(err.response.headers["x-gsn-webstore-now"]);
						if (storeRaw) {
							storeRaw.nowTime = err.response.headers["x-gsn-webstore-now"];
						}
					}
					return isProcessEnabled && storeRaw ? storeRaw : null;
				}

				popups.handleErrorResponse(err);
			});
	};

	return getStore;
};

export default getGetStore;
