import {
	IWebStoreContextApiProps,
	IUserTracker,
	IUserTrackerResponse,
	IAuthToken
} from "../types";
import { isMobile } from "react-device-detect";
import { analyticsTracker } from "../../ScopelyIdentityProvider/config";
import { useGoogleAnalyticsContext } from "../../GoogleAnalyticsProvider";

interface IGetSyncUserTrackerProps extends IWebStoreContextApiProps {
	setUserTracker: React.Dispatch<React.SetStateAction<IUserTracker | null>>;
	setUserName: React.Dispatch<React.SetStateAction<string | null>>;
	logout: () => void;
	setNowTime: (now: string) => void;
	setNetworkErrorDetected: React.Dispatch<React.SetStateAction<boolean>>;
	profileImage: string | null;
	setProfileImage: React.Dispatch<React.SetStateAction<string | null>>;
	isAutoLoginIn?: boolean;
}

export type ISyncUserTrackerReturnType =
	() => Promise<IUserTrackerResponse | void>;

const getSyncUserTracker = ({
	axios,
	debug,
	backendUri,
	popups,
	setUserTracker,
	createHeaders,
	setNowTime,
	syntheticId,
	setNetworkErrorDetected,
	setUserName,
	profileImage,
	setProfileImage,
	isAutoLoginIn
}: IGetSyncUserTrackerProps): ISyncUserTrackerReturnType => {
	const { setTuidForAnalytics, checkAdsPersonalizationRemoteSettings } = useGoogleAnalyticsContext();
	const syncUserTracker = async (): Promise<void | IUserTrackerResponse> => {
		const token = localStorage.getItem("store.solitairetripeaks.com:authToken");
		const authToken: IAuthToken = token ? JSON.parse(token) : null;
		const user = localStorage.getItem("store.solitairetripeaks.com:userTracker");
		const userTracker: IUserTracker = user ? JSON.parse(user) : null;
		if (!authToken?.value || !userTracker) {
			// popups.showErrorDialog("no_auth_token_found", "No auth token found");
			// window?.console &&
			// 	console.warn("no_auth_token_found", "No auth token found");
			return;
		}

		let version = null;
		if (userTracker && userTracker?.version) {
			version = userTracker.version;
		}
		const queryParams: {
			version?: string;
		} = {};
		if (version) {
			queryParams.version = version;
		}
		const deviceToken = analyticsTracker.getDeviceToken();
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				// "Content-Type": "text/plain; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId,
				"X-TITAN-DEVICE-TOKEN": deviceToken ? deviceToken : ""
			}),
			params: queryParams
		};
		const uri = "/rest/user/tracker";
		return axios
			.get<IUserTrackerResponse>(uri, requestConfig)
			.then(async (response) => {
				if (response.data) {
					setNetworkErrorDetected(false);
					if (setNowTime && response.headers["x-gsn-webstore-now"]) {
						setNowTime(response.headers["x-gsn-webstore-now"]);
					}
					debug.log({
						iconText: "syncUserTracker:",
						message: ["userTracker:", response.data.userTracker]
					});
					if (userTracker) {
						setUserName(response.data.userTracker.name);
						setUserTracker(response.data.userTracker);
						if (profileImage && !isAutoLoginIn) {
							const url = new URL(profileImage);
							const baseURL = url.origin + url.pathname.split('/').slice(0, -1).join('/');
							setProfileImage(baseURL + "/" + userTracker.id + "?timestamp=" + new Date().getTime());
						}
						if (response.data.userTracker.id) {
							setTuidForAnalytics(response.data.userTracker.id);
							analyticsTracker
								.getContext()
								.setTenant(response.data.userTracker.id);
							checkAdsPersonalizationRemoteSettings(response.data.userTracker);
						}
						return { userTracker: response.data.userTracker };
					}
				} else {
					popups.handleErrorResponse({ response });
				}
			})
			.catch((err) => {
				const status = err?.response?.status;
				if (status === 304) {
					setNetworkErrorDetected(false);
					if (userTracker && userTracker.id) {
						setTuidForAnalytics(userTracker.id);
						analyticsTracker.getContext().setTenant(userTracker.id);
						checkAdsPersonalizationRemoteSettings(userTracker);
					}
					return;
				}
				if (status) {
					popups.handleErrorResponse(err);
				}
			});
	};

	return syncUserTracker;
};

export default getSyncUserTracker;
