import { Checked, Locked } from "./styles";
import { StreakVariant } from "../../hooks/useStreakVariant";
import { StyledComponent } from "styled-components";

export const STREAK_DAY_COLORS: { [V in StreakVariant]: string } = {
	collected: "rgba(109, 109, 109, .5)",
	available: "#5BB52D",
	locked: "rgba(109, 109, 109, 1)"
};

export const loadVariantAsset: {
	[V in StreakVariant]: StyledComponent<
		"img",
		never,
		Record<string, unknown>,
		never
	> | null;
} = {
	locked: Locked,
	collected: Checked,
	available: null
};
