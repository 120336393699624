import { Typography } from "@mui/material";
import styled from "styled-components";

export const OffersStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	//margin-bottom: 5rem;
	position: absolute;
	top: 38%;
	right: 0px;
	left: 0px;
	height: 60%;

	@media (max-width: 733px) {
		top: 58%;
		transform: translateY(-50%);
		width: 100%;
		height: unset;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}
`;

export const OfferItem = styled.div`
	//display: grid;
	grid-template-rows: auto;
	background-color: rgba(51, 51, 51, 0.3);
	border: 1px solid #9aeffc;
	border-radius: 12px;
	padding: 0.3rem;
	gap: 0.5rem;
	margin: 3px;
	width: 200px;
	height: fit-content;
	z-index: 999;
	align-content: stretch;

	&.loyalty-reward {
		align-content: stretch;
	}
	&.one-item {
		min-width: 400px;
		width: auto;
		img {
			width: 55px;
			height: 55px;
		}
	}
	&.two-items {
		min-width: 250px;
	}
	&.several-items {
		min-width: 200px;
	}

	@media (max-width: 733px) {
		img {
			width: 46px;
			height: 46px;
		}

		padding: 0.7rem;
		width: 85%;
		&.one-item {
			min-width: unset;
			width: 85%;
		}
	}

	@media (min-width: 900px) {
		img {
			width: 48px;
			height: 48px;
		}
		padding: 0.7rem;
	}

	@media (min-width: 1100px) {
		img {
			width: 65px;
			height: 65px;
		}
		gap: 1.2rem;
		&.one-item {
			img {
				width: 65px;
				height: 65px;
			}
		}
		&.two-items {
			min-width: 350px;
		}
		&.several-items {
			min-width: 300px;
		}
	}
	/* @media (max-width: 700px) {
		max-width: 400px;
	} */
`;

export const Items = styled.div`
	justify-content: center;
	align-items: end;
	display: flex;
	flex-wrap: wrap;
`;

export const RewardItem = styled.div<{ width: string }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${(props) => props.width};
	box-sizing: border-box;
	padding: 1px;
`;

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 11px;
	@media (min-width: 1100px) {
		font-size: 15px;
	}
`;

export const ItemImage = styled.img`
	width: 42px;
	height: 42px;
	object-fit: contain;
	justify-self: center;
	align-self: center;
`;

export const Footer = styled.div`
	display: grid;
	align-items: flex-end;
	button {
		padding: 0.5rem 0.2rem;
		gap: 0.4rem;
		font-size: 0.9rem;
		height: 40px;
		@media (min-width: 1100px) {
			height: unset;
		}
	}

	&.loyalty-reward {
		align-items: center;
		grid-template-columns: minmax(85px, auto) auto;
		grid-gap: 10px;
		button {
			font-size: 0.8rem;
		}

		@media (max-width: 733px) {
			&.one-item {
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
				width: 100%;
				button {
					width: 100%;
				}
			}
		}
	}
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	padding: 0.5rem 0.8rem;
	margin: 0.3rem 0rem;
	border-radius: 100px;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.2rem;
	}
`;
