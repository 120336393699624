import ThemeButton from "components/Buttons/ThemeButton";
import { styled } from "@mui/material";

export const Container = styled("div")<{ scale: number }>`
	width: 368px;
	background-color: #fff;
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
	transform: scale(${(props) => props?.scale});
	/* @media (max-width: 415px) {
		width: 95vw;
	} */
`;

export const Header = styled("div")<{ "data-image": string }>`
	height: 188px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: cover;
	background-size: cover;
	background-image: url(${(props) => props["data-image"]});
	position: relative;
`;

export const HeaderTitle = styled("img")`
	object-fit: contain;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const PalmTrees = styled("div")<{ "data-image": string }>`
	height: 188px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: cover;
	background-size: cover;
	background-image: url(${(props) => props["data-image"]});
	position: relative;
`;

export const Body = styled("div")`
	max-width: 372px;
	margin: 0 auto;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

export const Title = styled("span")`
	max-width: 290px;
	font-family: "Baloo";
	font-size: 26px;
	text-align: center;
	line-height: 28px;
	color: #333;

	/* @media (max-height: 400px) {
		font-size: 22px;
		max-width: 100%;
	} */
`;

export const SubTitle = styled("span")`
	//max-width: 290px;
	line-height: 14px;
	color: #333;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	/* @media (max-height: 400px) {
		font-size: 22px;
		max-width: 100%;
	} */
`;

export const Button = styled(ThemeButton)`
	padding: 0.2rem;
	width: 90%;
	font-size: 16px;
`;
