import { PREFIX } from "./helper";
import service from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IShopSections } from "./types";

export const getShopSections = createAsyncThunk(
	`${PREFIX}/get-shop-sections`,
	async (): Promise<IShopSections> => {
		try {
			const response: IShopSections = await service.Get(
				"/rest/store/shopSections"
			);
			return response;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
);
