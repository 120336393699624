import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import tiki from "assets/tutorial/tiki-2.svg";
import Button from "components/Buttons/ThemeButton";
import { useWebStoreContext } from "contexts";
import { useAppDispatch, useAppSelector } from "hooks";
import useBreakpoints from "hooks/useBreakpoints";
import { IOffer } from "pages/StoreFront/lib/SingleItems";
import { useEffect, useState } from "react";
import { useGetOffersQuery } from "redux/apis";
import { onClosePopUp } from "redux/slices";
import { selectShopSectionsState } from "redux/slices/shop-sections";
import { Arrow, Background2, Text, Tiki2 } from "../../styles";

interface Slide3Props {
	onNext: () => void;
}

export const Slide3 = ({ onNext }: Slide3Props) => {

	const dispatch = useAppDispatch();
	const { isSmUp } = useBreakpoints();
	const { authToken } = useWebStoreContext();
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isLowHeight = useMediaQuery("(max-height: 600px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const { data: shop } = useGetOffersQuery(
		{
			offerType: "singleItem",
			isAuthenticated: !!authToken?.value
		}
	);

	const [step, setStep] = useState<number>(1);
	const [offerId, setOfferId] = useState<string>("");
	const [shine, setShine] = useState<HTMLElement | null>();
	const [button, setButton] = useState<HTMLElement | null>();
	const [singleItem, setSingleItem] = useState<HTMLElement | null>();
	const [shineButton, setShineButton] = useState<HTMLElement | null>();
	const [tutorialPosition, setTutorialPosition] = useState<{ top: number, left: number | string }>({
		top: 285,
		left: !isExtraSmall ? "-50%" : "-190px",
	});

	const scrollAndHighlight = async (height: number) => {
		if (singleItem && shine) {
			const headerOffset = 82;
			const elementPosition = singleItem.getBoundingClientRect();
			const offsetPosition = Number(elementPosition.top) + window.scrollY - headerOffset;
			window.scrollTo({ top: offsetPosition, behavior: "smooth" });

			await new Promise((resolve) => {
				const onScrollEnd = () => {
					window.removeEventListener("scrollend", onScrollEnd);
					resolve({});
				};
				window.addEventListener("scrollend", onScrollEnd);
			});

			const newPosition = singleItem.getBoundingClientRect();
			const centerX = newPosition.left + (newPosition.width / 2);
			const centerY = newPosition.top + (newPosition.height / 2);
			shine.style.position = "fixed";
			shine.style.left = `${centerX}px`;
			shine.style.top = `${centerY}px`;

			const centerTutorialX =
				isExtraSmall ? "-50%"
					: isSmUp && !isLowHeight
						? newPosition.left + newPosition.width / 2 - 380 * 0.75
						: newPosition.left + (height < 600 ? newPosition.width + 30 : - newPosition.width);
			const centerTutorialY = newPosition.top + (height < 600 ? 0 : newPosition.height + 10);
			setTutorialPosition(tp => ({ ...tp, top: centerTutorialY, left: centerTutorialX }));
		}
	};

	useEffect(() => {
		const filteredSections = shopSections?.mainShopSections.filter((data) =>
			shop.offers?.find(
				(of: IOffer) =>
					of.sectionName === data.name && of.sectionName !== "limited_deals"
			)
		);
		const sectionOffers = filteredSections.map((data) => {
			return shop?.offers.filter(
				(of: IOffer) => of.sectionName === data.name
			);
		});

		const offerId = sectionOffers?.[0]?.[isLowHeight ? 0 : 1]?.id;
		setOfferId(offerId);
		const singleItem = document.getElementById(offerId);
		setSingleItem(singleItem);
		const button = document.getElementById(`${offerId} button`);
		setButton(button);
		const shine = document.getElementById(`${offerId}-offer-card-shine`);
		setShine(shine);
		const shineButton = document.getElementById(`${offerId}-offer-card-button-shine`);
		setShineButton(shineButton);

		scrollAndHighlight(window.innerHeight);
		singleItem?.setAttribute("style", "z-index: 99999");
		shine?.classList.add("animate");

		return () => {
			shine?.classList.remove("animate");
			shineButton?.classList.remove("animate");
			button?.setAttribute("style", "z-index: auto");
			singleItem?.setAttribute("style", "z-index: auto");
		}
	}, [isLowHeight]);

	useEffect(() => {
		setStep(1);
		shine?.classList.remove("animate");
		shineButton?.classList.remove("animate");
		button?.setAttribute("style", "z-index: auto");
		singleItem?.setAttribute("style", "z-index: auto");
	}, [isLowHeight]);

	useEffect(() => {
		if (step === 2 && offerId) {
			shine?.classList.remove("animate");
			shineButton?.classList.add("animate");
			button?.setAttribute("style", "z-index: 9999");
			singleItem?.setAttribute("style", "z-index: auto");

			const newPosition = button?.getBoundingClientRect();
			if (newPosition && shineButton) {
				const centerX = newPosition.left + (newPosition.width / 2);
				const centerY = newPosition.top + (newPosition.height / 2);
				shineButton.style.position = "fixed";
				shineButton.style.left = `${centerX}px`;
				shineButton.style.top = `${centerY}px`;
			}
		}
	}, [step]);

	useEffect(() => {
		scrollAndHighlight(window.innerHeight);
	}, [window.innerWidth, window.innerHeight, singleItem, shine, isPortrait, step]);

	return (
		<>
			<Grid
				px={3}
				container
				maxWidth={380}
				position="fixed"
				top={tutorialPosition.top}
				left={tutorialPosition.left}
				sx={{ transform: isExtraSmall ? "translateX(50vw)" : "none" }}
			>
				<Grid item xs={12} display="flex" justifyContent={!isPortrait && isLowHeight ? "flex-start" : "flex-end"} mr={6}>
					<Arrow src={arrowUp} alt="Arrow up" className="site-to-side-left" />
				</Grid>

				<Grid item xs={12} display="flex" justifyContent="right">
					<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
						{step === 1 ? "3/7" : "4/7"}
					</Text>
				</Grid>

				<Grid item xs={12}>
					<Background2>
						<Grid container mb={2}>
							<Grid item xs={2.5}>
								<Tiki2 src={tiki} alt="Tiki" bottom={step === 1 ? "7px" : "10px"} />
							</Grid>
							<Grid item xs={9.5} display="flex" flexDirection="column" gap={1}>
								{step === 1
									? (
										<>
											<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
												Get more value and Loyalty Points with web-only deals!
											</Text>
											<Text color="#462C12" fontFamily="Inter" fontSize="12px" lineHeight="15px">
												Redeem points for rewards—watch for bonuses.
											</Text>
										</>
									)
									: (
										<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
											Click an item to purchase securely through the Official Web Store of Tiki Solitaire TriPeaks™.
										</Text>
									)
								}
							</Grid>
						</Grid>
						<Button className="next" onClick={() => step === 1 ? setStep(2) : onNext()}>Next</Button>
						<Button className="skip" variant="text" onClick={() => dispatch(onClosePopUp())}>Skip</Button>
					</Background2>
				</Grid>
			</Grid>
		</>
	)
};
