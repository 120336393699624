import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import { Config, DipButton, WsDialogs } from "interfaces";
import { useLocation } from "react-router-dom";
import {
	claimDipReward,
	onAddRequisitionCount,
	onCloseDIP,
	onDisableButtonsDIP,
	onEnableButtonsDIP,
	onOpenPopUp,
	selectDipState,
	selectWebStoreState,
	sendDipAction,
	setOpen
} from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { links } from "helpers";
import { getCloseClickTrackingParams } from "../helper";
import { useGetOffersQuery } from "redux/apis";

interface DIPRewards {
	/**
	 *  @function onAction
	 * 	@interface (button:DipButton) => void
	 * 	@description Receive a button and do two actions:
	 * 		1. Send to server the action that user did
	 * 		2. Evaluate `actionType` property to know what webstore has to do: navigate, init a purchase, claim a reward, etc
	 */
	onAction: (button: DipButton) => void;
}

export const useDIPAction = (): DIPRewards => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { currentDip } = useAppSelector(selectDipState);
	const { openDialog } = useDialogModalRedux();
	const { rewards } = useAppSelector(selectWebStoreState).store;
	const { authToken } = useWebStoreContext();
	const { data: loyaltyOffers } = useGetOffersQuery({
		offerType: "loyalty",
		isAuthenticated: authToken?.value ? true : false
	});
	const {
		userTracker,
		wsStoreBuy,
		suppressOrderConfirmation,
		postUserMetadata
	} = useWebStoreContext();

	const location = useLocation();

	const handleCloseClick = () => {
		dispatch(onCloseDIP());
		reportEvent("dialog_x_close", {
			source: "dips_dialog",
			...getCloseClickTrackingParams(currentDip?.dip.id, currentDip?.buttons)
		});
	};

	useEffect(() => {
		if (
			currentDip?.requisitionCount !== undefined &&
			currentDip?.dip.maxRequisitions !== undefined &&
			currentDip?.requisitionCount === currentDip?.dip.maxRequisitions
		) {
			handleCloseClick();
		}
	}, [currentDip?.requisitionCount]);

	const onAction = (button: DipButton | undefined): void => {
		if (typeof button === "undefined") {
			return;
		}
		// prettier-ignore
		const {actionType, id, loyaltyOfferId, deeplinkUrl, offerId, shopSection, rewardId, externalUrl} = button;

		/**🔦 Sending the action executed by user to server */
		dispatch(
			sendDipAction({
				actionType: "clickedButton",
				buttonId: id ?? ""
			})
		);

		/**🔦 Evaluating what webstore has to do depending actionType */
		switch (actionType) {
			case "deeplink":
				window.open(deeplinkUrl, "_blank");
				dispatch(onCloseDIP());
				break;
			case "externalUrl":
				window.open(externalUrl, "_blank");
				dispatch(onCloseDIP());
				break;
			case "closeDip":
				dispatch(onCloseDIP());
				break;
			case "grantReward":
			case "grantLoyaltyReward":
				reportEvent("dips_button_click", {
					source: "dips_dialog",
					dip_id: currentDip?.dip.id,
					reward_id: rewardId,
					page_location: location.pathname
				});
				dispatch(
					claimDipReward({
						dipId: currentDip?.dip.id as string,
						buttonId: id,
						grantRewardType: actionType
					})
				);
				break;
			case "loyaltyPurchase":
				//const loyalty = loyaltyOffers.find((c) => c.id === loyaltyOfferId);
				const loyalty = loyaltyOffers.offers.find(
					(c: Config) => c.id === loyaltyOfferId
				);
				if (loyalty) {
					const {
						description,
						loyaltyPointPrice,
						rewardId,
						rewardImageUrl,
						id
					} = loyalty;
					const reward = rewards[rewardId];
					const rewardItems = reward ? reward.items : [];
					/**🔦 We check if user has enough loyalty points to buy the offer */
					// @ts-ignore
					if (userTracker?.loyaltyPoints < loyaltyPointPrice) {
						openDialog(WsDialogs.INSUFFICIENT_LOYALTY_POINT_BALANCE);
						return;
					}
					/**🔦 We set the dipId on local-storage to get it on the loyaltyBuy API call */
					localStorage.setItem(
						"com.scopely:dipId",
						currentDip?.dip.id as string
					);
					reportEvent("dips_button_click", {
						source: "dips_dialog",
						loyalty_offer_id: loyaltyOfferId,
						dip_id: currentDip?.dip.id,
						reward_id: rewardId,
						page_location: location.pathname
					});
					openDialog(WsDialogs.LOYALTY_PURCHASE_CONFIRM, {
						offerId: id,
						itemDescription: description,
						itemPrice: loyaltyPointPrice,
						itemImageUrl: rewardImageUrl,
						items: rewardItems
					});
				}
				break;
			case "offerPurchase":
				dispatch(onDisableButtonsDIP());
				/**🔦 We set the dipId on local-storage to get it on the invoice API call */
				localStorage.setItem("com.scopely:dipId", currentDip?.dip.id as string);

				wsStoreBuy({ offerId: offerId as string }).then((invoice) => {
					if (invoice) {
						reportEvent("dips_button_click", {
							source: "dips_dialog",
							offer_id: offerId,
							dip_id: currentDip?.dip.id,
							reward_id: rewardId,
							page_location: location.pathname
						});
						// dispatch(onCloseDIP());
						openDialog(WsDialogs.XSOLLA_PURCHASE, {
							xsollaToken: invoice.xsollaToken,
							xsollaHost: invoice.xsollaHost,
							suppressOrderConfirmation,
							source: "dips",
							postUserMetadata,
							onPurchaseStarted: (data) => {
								reportEvent("purchase_started", {
									section_name: "dips_dialog",
									offer_id: offerId,
									//price_in_cents:
									reward_id: rewardId,
									invoice_id: invoice?.id,
									paystation_id: data?.payStation
								});
							},
							onPurchaseSuccess: () => {
								reportEvent("purchase_success", {
									invoice_id: invoice?.id
								});

								if (currentDip?.dip.triggerType === "bouncyChestClick") {
									dispatch(onOpenPopUp("bouncy-chest-reward-collected"));
								}

								if (currentDip) {
									dispatch(onAddRequisitionCount());
								}
							},
							cbClose: () => {
								dispatch(onEnableButtonsDIP());
								reportEvent("dialog_x_closed", {
									source: "xsolla_dialog",
									page_location: location.pathname
								});
							}
						});
					}
				});
				break;
			case "shopSection":
				dispatch(setOpen(false));
				switch (shopSection) {
					case "clubShop":
						navigate(links.club_shop.path);
						break;
					case "loyalty":
						navigate(links.loyalty.path);
						break;
					case "clubFreeGift":
						navigate(links.club_free_gift.path);
						break;
					case "freeDailyGifts":
						navigate(links.free_daily_gifts.path);
						break;
					case "poisBounty":
						navigate(links.pois_bounty.path);
						break;
					case "news":
						navigate(links.news.path);
						break;
					case "merchShop":
						navigate(links.merch_shop.path);
						break;
					case "dripOffer":
						navigate(links.drip_offer.path);
						break;
					default:
						navigate(links.shop.path);
				}
				dispatch(onCloseDIP());
				break;

			default:
				dispatch(onCloseDIP());
		}
	};

	return {
		onAction
	};
};
