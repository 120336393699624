import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper, TextField } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Wrapper } from "./styles";

const SearchWordpress = ({
	searchTerm,
	setSearchTerm,
	setCurrentPageNum
}: {
	searchTerm: string | null;
	setSearchTerm: Dispatch<SetStateAction<string | null>>;
	setCurrentPageNum: Dispatch<SetStateAction<number>>;
}) => {
	/* eslint-disable */
	const handleChange = (event: any) => {
		setSearchTerm(event.target.value);
		setCurrentPageNum(1);
	};
	/* eslint-enable */

	const handleReset = () => {
		setCurrentPageNum(1);
		setSearchTerm("");
	};

	return (
		<Wrapper>
			<Paper
				component="form"
				noValidate
				autoComplete="off"
				/* eslint-disable-next-line */
				onSubmit={(event: any) => {
					event.preventDefault();
				}}
				sx={{
					padding: "0 1rem",
					position: "relative",
					borderRadius: "24px",
					border: "1px solid #B9B9B9",
					background: "#EBEBEB"
				}}
			>
				<TextField
					id="outlined-basic"
					placeholder="Search all winners posts"
					onChange={handleChange}
					value={searchTerm}
					fullWidth
					sx={{ fieldset: { border: "none" } }}
				/>

				{searchTerm === "" || searchTerm === null ? (
					<SearchIcon sx={{ p: "16px", position: "absolute", right: "3%" }} />
				) : (
					<IconButton
						sx={{ p: "16px", position: "absolute", right: "3%" }}
						aria-label="clear search"
						onClick={handleReset}
					>
						<CloseIcon />
					</IconButton>
				)}
			</Paper>
		</Wrapper>
	);
};

export default SearchWordpress;
