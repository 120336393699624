import { LoginType } from "../../types";
import AbstractLoginRequest from "../types";

export default class ScopelyLoginRequest extends AbstractLoginRequest {
	constructor(scopelyIdentityToken: string, scopelyAccessToken: string) {
		super(LoginType.Scopely, {
			sidIdentityToken: scopelyIdentityToken,
			sidAccessToken: scopelyAccessToken
		});
	}
}
