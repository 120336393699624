import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { featureTrackerAPI, useCompleteNodesMutation } from "redux/apis";
import { EndlessOfferResponse } from "interfaces/endless-offer";
import { useWebStoreContext } from "contexts";

const SetEndlessOfferPurchaseDialog = () => {
	//const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [fieldValue, setFieldValue] = useState(1);
	const [completeNodes] = useCompleteNodesMutation();
	const { authToken } = useWebStoreContext();
	const { data }: { data?: EndlessOfferResponse } =
		featureTrackerAPI.endpoints.getFeatureTracker.useQueryState({
			FeatureType: "endless-offer",
			isAuthenticated: !!authToken?.value
		});
	const handleOpen = (value: boolean) => {
		setOpen(value);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(e.target.valueAsNumber);
	};

	const handleSetPurchases = async () => {
		if (!data?.tracker && fieldValue < 0) {
			return;
		}

		await completeNodes({
			eventId: data?.tracker?.eventId,
			numNodes: fieldValue
		}).unwrap();

		setOpen(false);
	};

	return (
		<div>
			<Button variant="outlined" onClick={() => handleOpen(true)}>
				Submit
			</Button>
			<Dialog open={open} onClose={() => handleOpen(false)}>
				<DialogTitle>Set amount of purchased nodes</DialogTitle>
				<DialogContent>
					<TextField
						onChange={onChange}
						autoFocus
						margin="dense"
						id="name"
						defaultValue={fieldValue}
						label="Simulate Nodes Purchases"
						type="number"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleOpen(false)}>Cancel</Button>
					<Button onClick={() => handleSetPurchases()}>Submit</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SetEndlessOfferPurchaseDialog;
