import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { PREFIX } from "../helper";
import { initialState } from "./initial-state";
import { PBV2ChangePayload, PoisBountyV2Slice } from "./types";
import {
	TRACKERS_EXTRA_REDUCER,
	CLAIM_REWARD_EXTRA_REDUCER_V2,
	CLAIM_ALL_REWARDS_EXTRA_REDUCER,
	CREATE_CYCLE_TRACKER_EXTRA_REDUCER,
} from "../extra-reducers";

export const poisBountyV2Slice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onPBV2change: (
			state,
			action: PayloadAction<PBV2ChangePayload<PoisBountyV2Slice>>
		) => {
			const { payload } = action;
			for (const [key, value] of Object.entries(payload)) {
				// @ts-expect-error
				state[key] = value;
			}
		}
	},
	extraReducers: (builder) => {
		TRACKERS_EXTRA_REDUCER(builder);
		CLAIM_REWARD_EXTRA_REDUCER_V2(builder);
		CLAIM_ALL_REWARDS_EXTRA_REDUCER(builder);
		CREATE_CYCLE_TRACKER_EXTRA_REDUCER(builder);
	}
});

export const { onPBV2change } = poisBountyV2Slice.actions;

export const selectPoisBountyV2State = (state: RootState) => state.poisBountyV2;
export default poisBountyV2Slice.reducer;
export * from "./types";
export * from "./thunks";
