import { IDebugLogger } from "../../../utils/debugLogger";
import { ScopelyId } from "@scopely/web-sdk-scopelyid";
import { useGoogleAnalyticsContext } from "../../GoogleAnalyticsProvider";
import { Dispatch, SetStateAction } from "react";
import { LoginType } from "../../../domain/Login/LoginGoogleAnalyticsHelper";
import { setScopelyStateToSession } from "../helpers";

export const LOGIN_ACTION = "login";

export const getStartLogin = ({
	scopelyId,
	debug
}: {
	scopelyId: ScopelyId;
	debug: IDebugLogger;
}) => {
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();
	return async (origPath: string) => {
		loginGoogleAnalyticsHelper.reportLoginOptionLoginStart(LoginType.Scopely);
		debug.logFunction({
			iconText: "/ScopelyIdentityProvider/api/login:",
			message: ["startLogin"]
		});
		setScopelyStateToSession(
			JSON.stringify({ action: LOGIN_ACTION, path: origPath })
		);
		await scopelyId.login(LOGIN_ACTION);
	};
};

export const getTryConcludeLogin = ({
	scopelyId,
	setSIDAccessToken,
	setSIDIdentityToken,
	debug
}: {
	scopelyId: ScopelyId;
	setSIDAccessToken: Dispatch<SetStateAction<string | null>>;
	setSIDIdentityToken: Dispatch<SetStateAction<string | null>>;
	debug: IDebugLogger;
}) => {
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();
	return async (onSuccess?: () => void) => {
		const loginResult = await scopelyId.authorizationResult(
			window.location.href
		);

		if (loginResult.isSuccessful()) {
			loginGoogleAnalyticsHelper.reportLoginOptionLoginSuccess(
				LoginType.Scopely
			);
			const accessToken = loginResult.getResponse()?.getAccessToken();
			const identityToken = loginResult.getResponse()?.getIdToken();
			debug.log({
				iconText: "ScopelyIdentityProvider.api.login",
				message: [
					"\naccessToken: " + accessToken,
					"\nidentityToken: " + identityToken
				]
			});
			if (accessToken) {
				setSIDAccessToken(accessToken);
			}
			if (identityToken) {
				setSIDIdentityToken(identityToken);
			}
			onSuccess && onSuccess();
		} else {
			debug.log({
				iconText: "Scopely Login Fail",
				message: [loginResult.getError()]
			});
			loginGoogleAnalyticsHelper.reportLoginOptionLoginFailure(
				LoginType.Scopely,
				loginResult.getError() ?? ""
			);
		}
		return loginResult;
	};
};
