import { Card, styled } from "@mui/material";

export const FeaturedCard = styled(Card)(({ theme }) => ({
	[theme.breakpoints.down("xs")]: {
		backgroundSize: "114% 113%",
		backgroundPosition: "center left",
		minHeight: 470
	},
	[theme.breakpoints.up("sm")]: {
		minHeight: 270
	},
	[theme.breakpoints.down("featCardImg")]: {
		backgroundSize: "cover",
		backgroundPosition: "bottom right",
		minHeight: 229
	},
	[theme.breakpoints.down("sm")]: {
		backgroundPosition: "center right",
		minHeight: 229
	},
	[theme.breakpoints.up("featCardImg")]: {
		backgroundPosition: "bottom right",
		backgroundSize: "auto 100%",
		minHeight: 319
	},
	position: "relative",
	zIndex: 0,
	backgroundSize: "cover",
	borderRadius: 20,
	width: "100%",
	margin: `0 auto ${theme.spacing(2)}`,
	"& *": {
		transition: "all 0.05s ease-in"
	},
	button: {
		maxWidth: "240px",
		height: "auto",
		paddingTop: "0.8rem",
		paddingBottom: "0.8rem"
	}
}));

export const TitleText = styled("div")`
	font-family: "Baloo";
	color: #fff;
	font-size: 52px;
	line-height: 56px;
	margin-bottom: 1rem;
	${(props) => props.theme.breakpoints.down("lg")} {
		font-size: 43px;
		line-height: 38px;
		margin-bottom: 10rem;
	}

	text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.24);
`;

export const Text = styled("p")`
	font-family: "Baloo";
	color: #fff;
	font-size: 26px;
	line-height: 28px;
	${(props) => props.theme.breakpoints.down("lg")} {
		font-size: 22px;
		line-height: 24px;
	}
	margin-top: 0;
	margin-bottom: .5rem;
	text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.24);
`;
