import { PREFIX } from "./helper";
import service from "services/api";
import { onDIPtrigger } from "../dip";
import { RootState } from "redux/store";
import { onOpenPopUp } from "../pop-up-manager";
import { PoisBountyResponse } from "interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPoisBountyV2EventTrackers } from "./v2";

export const getPoisBountyEventTracker = createAsyncThunk(
	`${PREFIX}/get`,
	async (): Promise<PoisBountyResponse | undefined> => {
		try {
			return await service.Get<PoisBountyResponse>("/rest/poisBounty/tracker");
		} catch (error) {
			console.log(error);
		}
	}
);

export const onClaimReward = createAsyncThunk(
	`${PREFIX}/claim-reward`,
	async (
		rewardIndex: number,
		thunkAPI
	): Promise<PoisBountyResponse | undefined> => {
		const { data } = (thunkAPI.getState() as RootState).poisBounty;
		const { eventId } = data.tracker;
		try {
			const response = await service.Post<PoisBountyResponse>(
				`/rest/poisBounty/claim?eventId=${eventId}&rewardIndex=${rewardIndex}`
			);
			thunkAPI.dispatch(onDIPtrigger("poisBountyAutoClaim"));
			thunkAPI.dispatch(onOpenPopUp("pb-reward-collected"));
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);

export const onFocusClaim = createAsyncThunk(
	`${PREFIX}/claim-reward`,
	async (): Promise<void> => {
		try {
			await service.Post("/rest/uievent", { type: "onFocus" });
		} catch (error) {
			console.log(error);
		}
	}
);

export const onAutoClaim = createAsyncThunk(
	`${PREFIX}/claim-reward`,
	async (): Promise<void> => {
		try {
			await service.Post("/rest/uievent", { type: "onPoisBountyEventEnd" });
		} catch (error) {
			console.log(error);
		}
	}
);
/* eslint-disable */
export const onPopUpDisplayed = createAsyncThunk(
	`${PREFIX}/pop-up-displayed`,
	async (eventId: string): Promise<any | undefined> => {
		try {
			await service.Post(
				`/rest/poisBounty/completeDisplayed?eventId=${eventId}`
			);
		} catch (error) {
			console.log(error);
		}
	}
);
/* eslint-enable */

export const onFTUE = createAsyncThunk(
	`${PREFIX}/on-ftue`,
	async (): Promise<void> => {
		try {
			await service.Post(
				"/rest/user/tracker/metadataValue?key=poisBountyFtue&value=true"
			);
		} catch (error) {
			console.log(error);
		}
	}
);

/***************************************************
 * DEBUG thunks
 ***************************************************/
export const clearPoisBountyUserTracker = createAsyncThunk(
	`${PREFIX}/DEBUG-clear-pois-bounty-user-tracker`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post<{ success: boolean }>(
				"/rest/debug/poisBounty/tracker/delete"
			);
			thunkAPI.dispatch(getPoisBountyEventTracker());
			thunkAPI.dispatch(getPoisBountyV2EventTrackers());
		} catch (error) {
			console.log(error);
		}
	}
);

export const clearPoisBountyUserTrackers = createAsyncThunk(
	`${PREFIX}/DEBUG-clear-pois-bounty-user-trackers`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post<{ success: boolean }>(
				"/rest/debug/poisBounty/tracker/deleteAll"
			);
			thunkAPI.dispatch(getPoisBountyEventTracker());
			thunkAPI.dispatch(getPoisBountyV2EventTrackers());
		} catch (error) {
			console.log(error);
		}
	}
);
