import styled from "styled-components";
import { Typography } from "@mui/material";
import ThemeButton from "components/Buttons/ThemeButton";
import {
	expandOverlay,
	moveDiagonally,
} from "./animations";

export const BuyItAllStyled = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 7px;

	@media (orientation: portrait) {
		width: 100%;
		height: 640px;
		justify-content: flex-end;
		flex-wrap: nowrap;
		gap: 1rem;

		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	@media (orientation: landscape) {
		height: 295px;
		gap: 0.5rem;
	}
`;

export const LockedOffer = styled.div`
	position: absolute;
	width: calc(100% - 8px);
	height: calc(100% - 8px);
	background: rgba(0, 0, 0, 0.4);
	border-radius: 12px;
	z-index: 1;
	animation: ${expandOverlay} 0.2s forwards;
`;

export const FingerPointer = styled.img`
	width: 65px;
	height: 79px;
	position: absolute;
	bottom: -55%;
	right: -15%;
	z-index: 2;
	animation: ${moveDiagonally} 2s infinite;

	@media (orientation: portrait) {
		bottom: -50px;
	}
`;

// prettier-ignore
export const BuyItAllItem = styled.div<{ tier: number }>(({ tier }) => `
	position: relative;
	background-color: rgba(51, 51, 51, 0.3);
	border: 4px solid ${tier === 0 && "#C9832E" || tier === 1 && "#D9D9D9" || tier === 2 && "#FBBD05"};
	border-radius: 12px;
	padding: 0.5rem;
	height: calc((${tier} * 22px) + "auto");
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@media (orientation: landscape) {
		img {
			width: 60px;
			height: 60px;
		}
		&.several-items {
			min-width: 300px;
		}
	}
`);

// prettier-ignore
export const Items = styled.div<{ tier: number }>(({ tier }) => `
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media (orientation: portrait) {
		flex-direction: column;
		gap: calc((${tier} * 11px));
	}
	@media (orientation: landscape) {
		margin: calc((${tier} * 11px));
	}
`);

// prettier-ignore
export const RewardItem = styled.div<{ width: string }>(({ width }) => `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${width};
	box-sizing: border-box;
	padding: 1px;
`);

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 15px;
`;

export const ItemImage = styled.img`
	width: 46px;
	height: 46px;
	object-fit: contain;
	justify-self: center;
	align-self: center;
`;

export const Footer = styled.div`
	button {
		width: 100%;
		// check padding later
		padding: 0.5rem 0.2rem;
	}
	&.loyalty-reward {
		grid-template-columns: minmax(85px, auto) auto;
		grid-gap: 10px;
	}
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 0.5rem 0.8rem;
	margin: 0.3rem 0rem;
	border-radius: 100px;
	line-height: 22px;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.2rem;
	}
`;

export const BuyItAllFooterText = styled.p`
	color: white;
	text-align: center;
	text-shadow: 0px 4px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000;
	-webkit-text-stroke-width: 1.5;
	-webkit-text-stroke-color: #000;
	font-family: "Baloo";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin: 0;
`;

export const BuyItAllButton = styled(ThemeButton)`
	font-size: 24px;
	width: 289px;
	min-height: 52px;

	@media (orientation: portrait) {
		min-height: 58px;
	}
`;

// prettier-ignore
export const BestValueBadge = styled.div<{ backgroundImage: string }>(({ backgroundImage }) => `
	position: absolute;
	z-index: 1;
	top: -30px;
	right: 0px;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(${backgroundImage});
	background-size: cover;
	transform: rotate(-21deg);
	color: #fff;
	font-family: "Baloo";
	font-size: 13px;
	line-height: 12px;
	text-shadow: 0px 0px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747, 0px 1px 0 #474747, 0px -1px 0 #474747, -1px 0px 0 #474747,
		1px 0px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747, 0px 1px 0 #474747, 0px -1px 0 #474747, -1px 0px 0 #474747,
		1px 0px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747;
	transition: ease .3s;

	> span {
		width: 50px;
	}

	&.buy-all-button {
		top: 50%;
		transform: translate(50%, -50%) rotate(-21deg);
	}

	&.blocked-offer {
		filter: grayscale(1.0) brightness(45%);
	}
`);
