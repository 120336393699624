import { useWebStoreContext } from "contexts/WebStoreProvider";

export const DryRunOverrideLabel = () => {
	const { dryRunOverride } = useWebStoreContext();
	return (
		<>
			{dryRunOverride && (
				<span>
					(Payment Mode={dryRunOverride === "true" ? "Test" : "Real"})
				</span>
			)}
		</>
	);
};
