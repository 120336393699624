import styled from "@emotion/styled";

export const Button = styled.button`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	z-index: 1;
	background-color: unset;
	border: unset;
	cursor: pointer;
	&:hover {
		circle {
			fill-opacity: 0.8;
		}
	}
`;
