import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import tiki from "assets/tutorial/tiki-2.svg";
import Button from "components/Buttons/ThemeButton";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { onClosePopUp } from "redux/slices";
import { Arrow, Background2, Text, Tiki2 } from "../../styles";

interface Slide2Props {
	onNext: () => void;
}

export const Slide2 = ({ onNext }: Slide2Props) => {
	const dispatch = useAppDispatch();
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");

	const [subMenu, setSubMenu] = useState<HTMLElement | null>();
	const [tutorialPosition, setTutorialPosition] = useState<{ top: number, left: number | string }>({
		top: 370,
		left: isExtraSmall ? "-50%" : "-190px",
	});

	const scrollAndHighlight = async (height: number) => {
		if (subMenu) {
			subMenu.scrollIntoView({ behavior: "smooth", block: height < 500 ? "start" : "center" });
			//const rect = subMenu.getBoundingClientRect();
			//const isInView = rect.top >= 0 && rect.bottom <= height / 2;

			await new Promise((resolve) => {
				const onScrollEnd = () => {
					window.removeEventListener("scrollend", onScrollEnd);
					resolve({});
				};
				window.addEventListener("scrollend", onScrollEnd);
				setTimeout(() => {
					window.removeEventListener("scrollend", onScrollEnd);
					resolve({});
				}, 500);
			});

			const tutorialPos = subMenu.getBoundingClientRect();
			const centerY = tutorialPos.top + tutorialPos.height + 10;
			setTutorialPosition({ top: centerY, left: isExtraSmall ? "-50%" : "-190px" });
		}
	};

	useEffect(() => {
		const subMenu = document.getElementById("sub-menu");
		setSubMenu(subMenu);
		const shine = document.getElementById("sub-menu-shine-effect");
		scrollAndHighlight(window.innerHeight);
		subMenu?.setAttribute("style", "z-index: 99999");
		shine?.classList.add("animate");

		return () => {
			subMenu?.setAttribute("style", "z-index: 2");
			shine?.classList.remove("animate");
		}
	}, []);

	useEffect(() => {
		scrollAndHighlight(window.innerHeight);
	}, [window.innerWidth, window.innerHeight, subMenu, isExtraSmall, isPortrait]);

	return (
		<>
			<Grid
				px={3}
				container
				maxWidth={380}
				position="fixed"
				top={tutorialPosition.top}
				left={tutorialPosition.left}
				sx={{ transform: "translateX(50vw)" }}
			>
				<Grid item xs={12} display="flex" justifyContent="center">
					<Arrow src={arrowUp} alt="Arrow up" />
				</Grid>

				<Grid item xs={12} display="flex" justifyContent="right">
					<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
						2/7
					</Text>
				</Grid>

				<Grid item xs={12}>
					<Background2>
						<Grid container mb={2}>
							<Grid item xs={2.5}>
								<Tiki2 src={tiki} alt="Tiki" />
							</Grid>
							<Grid item xs={9.5}>
								<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
									Use the top menu to explore<br /> Wilds, Volcanos, Coins and<br /> Boosters.
								</Text>
								<Text color="#462C12" fontFamily="Inter" fontSize="12px" lineHeight="15px">
									Quickly browse the best offers.
								</Text>
							</Grid>
						</Grid>
						<Button className="next" onClick={onNext}>Next</Button>
						<Button className="skip" variant="text" onClick={() => dispatch(onClosePopUp())}>Skip</Button>
					</Background2>
				</Grid>
			</Grid>
		</>
	)
};
