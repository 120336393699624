import styled from "styled-components";
import star from "assets/star.png";

export const Container = styled.div`
	width: 100%;
	height: 20%;
	background: #5bb52d;
	position: absolute;
	bottom: 0px;
	color: #fff;
	font-family: Baloo;
	font-size: 20px;
	font-style: normal;
	white-space: nowrap;

	/* @media (min-width: 1536px) {
		width: 100%;
	} */
`;

export const Star = styled.div`
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	background-image: url(${star});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	overflow: hidden;
	color: transparent;
	opacity: 0;
	animation: glitter 6s linear 0s infinite normal;
	-webkit-animation: glitter 6s linear 0s infinite normal;
	-moz-animation: glitter 8s linear 0s infinite normal;
	-ms-animation: glitter 8s linear 0s infinite normal;
	-o-animation: glitter 8s linear 0s infinite normal;
	width: 26px;
	height: 26px;

	@keyframes glitter {
		0% {
			-webkit-transform: scale(0.3) rotate(0deg);
			opacity: 0;
		}
		5% {
			-webkit-transform: scale(1) rotate(360deg);
			opacity: 1;
		}
		50% {
			-webkit-transform: scale(0.3) rotate(720deg);
			opacity: 0;
		}
		100% {
			-webkit-transform: scale(0.3) rotate(0deg);
			opacity: 0;
		}
	}
`;
