import { Box, styled } from "@mui/material";
import { isIPad13 } from "react-device-detect";

export const StepStyled = styled(Box)<{ steps: number }>(({ steps }) => `
	position: relative;
	width: ${steps === 7 && "14%"
	|| steps === 6 && "16.5%"
	|| steps <= 5 && "20.1%"
	|| "20.1%"
	};
	height: 250px;
	&.first-step {
		width: 8.6%;
	}
	&.portrait {
		width: 120px;

		.claim-button {
			transform: rotate(90deg) translateX(2px);
		}
		.reward-item {
			transform: rotate(90deg) translateX(80px);
		}
		.target {
			transform: rotate(90deg) translate(-115px, 0px);
			z-index: -1;
		}
		.step-marker {
			transform: rotate(90deg) translateX(-100px);
			background-color: unset;
			border: unset;
			box-shadow: unset;
		}
		.progress-reward-step {
			transform: translateY(-115px);
		}
		.loyalty-points-indicator {
			transform: rotate(90deg) translate(-20px, 15px);
		}
		.progress-indicator.marker {
			transform: rotate(-90deg) translate(-12px, 0px);
			z-index: -1;
		}
		.container-indicator {
			&::before {
				height: 62px;
			}
		}
	}
`);

export const Reward = styled(Box)`
	position: absolute;
	z-index: 10;
	bottom: 0;
	right: 0;
	gap: ${isIPad13 ? 0 : "1rem"};
	transform: ${isIPad13 ? "translate(24px, 3px)" : "translateX(24px)"};
	width: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	
	&.claim-all {
		bottom: 30px;
		gap: ${isIPad13 ? 0 : "1rem"};
		transform: ${isIPad13 ? "translate(24px, 10px)" : "translateX(24px)"};
	}
`;

export const ProgressRewardStep = styled(Box)`
	background-color: rgba(0, 0, 0, 0.51);
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;
	height: 18px;
	padding: 0.1rem;

	&.first {
		border-left: 2px solid #fff;
		border-radius: 1rem 0 0 1rem;
	}
	&.last-step {
		width: 50%;
	}
`;

export const Bar = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	background-color: transparent;
`;

export const Percentage = styled(Box)`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	background: linear-gradient(
		180deg,
		#ffe37e 0%,
		#ffe178 19.09%,
		#ffda58 20.5%,
		#ffc700 100%
	);

	&.first {
		border-radius: 1rem 0 0 1rem;
	}
`;

export const RewardItem = styled(Box)`
	width: 52px;
	height: 52px;
	&.page-variant {
		width: 70px;
		height: 70px;
		transform: translateY(${isIPad13 ? "-35px" : "-0.7rem"});
	}
	&.mega-menu-variant {
		transform: translateY(${isIPad13 ? "-28px" : 0});

		@media (max-width: 990px) {
			width: 42px;
			height: 42px;
			padding: 0.21rem;
		}
	}
	padding: 0.25rem;
	border-radius: 11px;
	background-color: #fff;
	&.in-progress {
		background-color: #ffca0d;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;
`;

export const StarBurst = styled("img")`
	position: absolute;
	width: 150px;
	height: 150px;
`;

export const RewardText = styled(Box)`
	font-family: "Baloo";
	position: absolute;
	bottom: -3px;
	right: 4px;
	color: white;
	text-shadow: 2px 2px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
	display: flex;
	font-size: 18px;
	align-items: center;

	&.page-variant {
		font-size: 20px;
	}
`;

export const RewardImage = styled(Box)<{ background: string, color?: string }>(({ background, color }) => `
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 11px;
	background-image: url(${background});
	background-color: ${color || "#F8CB53"};
	background-size: cover;
	background-position: center bottom;
	display: flex;
	justify-content: center;
	align-items: center;
`);

export const RewardPrizeImage = styled("img")<{ status: string }>(({ status }) => `
	width: auto;
	height: auto;
	width: 80%;
	height: 75%;
	object-fit: contain;
	filter: ${status === 'unlocked' ? 'grayscale(1.0)' : 'none'};
`);
