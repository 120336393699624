import { onClosePopUp } from "redux/slices";
import useBreakpoints from "hooks/useBreakpoints";
import { useAppDispatch, useImagePath } from "hooks";
import { Avatar, Button, Container, Header, Title } from "./styles";
import poi from "assets/daily-gift/poi-daily-login-streak-break.png";
import mobileHeaderImg from "assets/daily-gift/mobile-background.png";
import title from "assets/daily-gift/text-daily-login-streak-break.png";
import headerImg from "assets/daily-gift/background-daily-login-streak-break.png";
import mobilePoi from "assets/daily-gift/mobile-poi-daily-login-streak-break.png";
import mobileTitle from "assets/daily-gift/mobile-text-daily-login-streak-break.png";

interface DailyRewardStreakBreakProps {}

export const DailyRewardStreakBreak: React.FC<
	DailyRewardStreakBreakProps
> = () => {
	const dispatch = useAppDispatch();
	const { isSmDown } = useBreakpoints();
	const { getImage } = useImagePath("local");
	const headImageUrl = getImage(headerImg);
	const avatarUrl = getImage(poi);
	const titleUrl = getImage(title);
	const mobileHeadImageUrl = getImage(mobileHeaderImg);
	const mobileAvatarUrl = getImage(mobilePoi);
	const mobileTitleUrl = getImage(mobileTitle);

	return (
		<Container>
			<Header data-image={isSmDown ? mobileHeadImageUrl : headImageUrl}>
				<Avatar src={isSmDown ? mobileAvatarUrl : avatarUrl} />
				<Title src={isSmDown ? mobileTitleUrl : titleUrl} />
				<Button
					variant="contained"
					themeColor="secondary"
					onClick={() => dispatch(onClosePopUp())}
				>
					Continue Shopping
				</Button>
			</Header>
		</Container>
	);
};
