/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from "@mui/material";
import classnames from "classnames";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { links } from "helpers";
import { useAppSelector } from "hooks";
import { Navigation } from "interfaces";
import { Link, useLocation } from "react-router-dom";
import { selectPoisBountyState, selectPoisBountyV2State } from "redux/slices";
import { getNavigationMenu } from "utils/getNavigationMenu";
import { CategoriesContainer, CategoryTitle } from "../styled";
import { categories } from "../../AppNavigation/MegaMenu/categories";
import { ExternalIcon } from "utils/svgIcons";
import { FooterLogo } from "./FooterLogo";

export const FooterMainMenu = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { userTracker } = useWebStoreContext();
	const location = useLocation();
	const { isEventActive } = useAppSelector(selectPoisBountyState);
	const { isEventActiveV2 } = useAppSelector(selectPoisBountyV2State);
	const isPoisActive: boolean = isEventActive || isEventActiveV2;
	const navigation = getNavigationMenu(
		userTracker?.clubId,
		isPoisActive,
		userTracker
	).filter((page) => page?.isDisplayInFooter);

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		page: Navigation
	) => {
		if (!page.isExternal) {
			window.scrollTo(0, 0);
		}
		if (location.pathname === page.path) {
			return;
		}
		switch (page.path) {
			case links.home.path:
				reportEvent("home_section_click", { source: "footer" });
				break;
			case links.shop.path:
				reportEvent("store_section_click", { source: "footer" });
				break;
			case links.club_shop.path:
				reportEvent("clup_shop_section_click", { source: "footer" });
				break;
			case links.loyalty.path:
				reportEvent("loyalty_section_click", { source: "footer" });
				break;
			case links.drip_offer.path:
				reportEvent("drip_offer_section_click", { source: "footer" });
				break;
		}
	};

	return (
		<CategoriesContainer>
			{categories.map((category) => (
				<Box
					key={category.id}
					sx={{
						width: { xs: "50%", md: "calc(100%/3)" },
						justifyContent: { xs: "center" },
						paddingTop: "10px"
					}}
				>
					<CategoryTitle>{category.description}</CategoryTitle>
					<List
						sx={{
							"&, a": { color: "white" },
							a: { textDecoration: "none" },
							".MuiListItemText-root": {
								margin: 0
							},
							".MuiListItemButton-root": {
								padding: 0
							},
							".MuiTypography-root": {
								fontSize: "16px",
								fontFamily: "Inter"
								//lineHeight: "1"
							},
							".icon svg": {
								width: "auto",
								height: "18px",
								marginBottom: "-2px"
							},
							paddingTop: "0px",
							paddingBottom: "0px"
						}}
					>
						{navigation
							.filter((page) => page.categoryId === category.id)
							.map((page) => (
								<ListItem
									key={page.title}
									className={classnames({
										selected: location.pathname === page.path
									})}
								>
									<Link to={page.path} target={page.isExternal ? "_blank" : "_self"}>
										<ListItemButton
											onClick={(event) => handleListItemClick(event, page)}
										>
											<ListItemText
												primary={page.title}
												secondary={page.isExternal ? <ExternalIcon color="white" /> : null}
												sx={{ display: "flex", alignItems: "center" }}
												disableTypography
											/>
										</ListItemButton>
									</Link>
								</ListItem>
							))}
					</List>
				</Box>
			))}
			{/* <Box
				sx={{
					display: "flex",
					width: { xs: "50%", md: "calc(100%/4)" },
					justifyContent: { xs: "flex-start", md: "flex-end" },
					paddingTop: "10px"
				}}
			>
				<FooterLogo />
			</Box> */}
		</CategoriesContainer>
	);
};
