import HttpRequest from "./HttpRequest";
import { IDebugLogger } from "../../utils/debugLogger";
import HttpResponse from "./HttpResponse";

/* eslint-disable */
export enum RequestAuthOption {
	NoAuth,
	UseAuth
}
/* eslint-enable */

export abstract class AbstractHttpService {
	private defaultHeaders: Record<string, string> = {};
	protected defaultBackendUrl?: string;
	protected authHeader: string | null = null;
	private debugLogger: IDebugLogger;
	private logContext = "Http Service";

	constructor(debugLogger: IDebugLogger) {
		this.debugLogger = debugLogger;
	}

	/* eslint-disable-next-line */
	protected log(message: any[]) {
		this.debugLogger.log({ iconText: this.logContext, message: message });
	}

	makeRequest(
		request: HttpRequest,
		authOption: RequestAuthOption = RequestAuthOption.NoAuth
	): Promise<HttpResponse> {
		request.headers = { ...this.defaultHeaders, ...request.headers };
		if (authOption === RequestAuthOption.UseAuth) {
			if (this.authHeader === null) {
				// TODO: Log Error
			} else {
				request.headers["Authorization"] = this.authHeader;
			}
		}

		return this.makeRequestInternal(request);
	}

	protected abstract makeRequestInternal(
		request: HttpRequest
	): Promise<HttpResponse>;

	setLogContext(logContext: string): void {
		this.logContext = logContext;
	}

	setDefaultHeader(headerName: string, headerValue: string | null): this {
		this.log(["Setting Default Header [", headerName, ":", headerValue, "]"]);
		if (headerValue !== null) {
			this.defaultHeaders[headerName] = headerValue;
		} else {
			delete this.defaultHeaders[headerName];
		}
		return this;
	}

	/**
	 * @param backendUrl The url prepended to Request URLs that are not absolute
	 */
	setDefaultBackendUrl(backendUrl?: string): this {
		this.log(["Setting Default Backend Url: ", backendUrl]);
		this.defaultBackendUrl = backendUrl;
		return this;
	}

	setAuthHeader(authHeader: string | null): this {
		this.log(["Setting Auth Header: ", authHeader]);
		this.authHeader = authHeader;
		return this;
	}
}
