import service from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PREFIX } from "./helper";
import { ICollectDailyGiftResponse } from "interfaces";

export const collectDailyGift = createAsyncThunk(
	`${PREFIX}/collect-daily-gift`,
	async (): Promise<ICollectDailyGiftResponse | undefined> => {
		try {
			const response = await service.Post<ICollectDailyGiftResponse>(
				"/rest/store/collectDailyGift"
			);
			return response;
		} catch (error) {
			console.log(error);
		}
	}
);
