import {
	Container,
	CrabBubbleContainer,
	CrabImage,
	DescriptionContainer,
	Message
	//SuperOfferTitle,
	//Title
} from "./styles";
import crab from "assets/endless-offer/crab.gif";
import crabBubble from "assets/endless-offer/crab-bubble.png";
import { useEffect, useState } from "react";

interface Props {
	nextSuperOfferCount: number;
}

export const EndlessCrab: React.FC<Props> = ({ nextSuperOfferCount }) => {
	const [isBubble, setIsBubble] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setIsBubble(true), 2000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Container>
			{isBubble && (
				<CrabBubbleContainer
					className="animate__animated animate__fadeIn"
					image={crabBubble}
				>
					<DescriptionContainer>
						{nextSuperOfferCount === 0 ? (
							<>
								{/* <SuperOfferTitle>Super Offer</SuperOfferTitle> */}
								<Message>Super Offer</Message>
								<Message>is here!</Message>
							</>
						) : (
							<>
								{/* <Title>{nextSuperOfferCount} more till</Title> */}
								<Message>{nextSuperOfferCount} more &lsquo;til</Message>
								<Message>Super Offer</Message>
							</>
						)}
					</DescriptionContainer>
				</CrabBubbleContainer>
			)}
			<CrabImage
				className={
					nextSuperOfferCount === 0 ? "animate__animated animate__tada" : ""
				}
				src={crab}
			/>
		</Container>
	);
};
