import { PREFIX } from "./helper";
import service from "services/api";
import { onButtonDisable } from ".";
import { onOpenPopUp } from "../pop-up-manager";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DailyLoginStreakResponse } from "interfaces";

export const getDailyLoginStreak = createAsyncThunk(
	`${PREFIX}/get`,
	async (): Promise<DailyLoginStreakResponse | undefined> => {
		try {
			return await service.Get<DailyLoginStreakResponse>(
				"/rest/daily-bonus/streak-tracker"
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const collectDailyBonus = createAsyncThunk<
	DailyLoginStreakResponse | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{ showErrorDialog: any; rewardId: string }
>(
	`${PREFIX}/collect-bonus`,
	async (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		{ showErrorDialog, rewardId },
		thunkAPI
	): Promise<DailyLoginStreakResponse | undefined> => {
		thunkAPI.dispatch(onButtonDisable(true));
		try {
			const data = await service.Post<DailyLoginStreakResponse>(
				"/rest/daily-bonus/collect"
			);
			if (rewardId === "loyalty_points") {
				thunkAPI.dispatch(onOpenPopUp("daily-login-streak-loyalty-points"));
			} else {
				thunkAPI.dispatch(onOpenPopUp("daily-login-earned-reward"));
			}
			return data;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			console.log(error);
			thunkAPI.dispatch(getDailyLoginStreak());
			// Fix for now, api service does not support data on error
			if (error?.code === 403) {
				const response = {
					data: {
						errorCode: "daily_gift_unavailable",
						errorMessage:
							"You have already collected your Daily Gift. Come back tomorrow for your next Daily Gift!"
					}
				};
				showErrorDialog(response, {
					isContinueButtonEnabled: true
				});
			}
		} finally {
			thunkAPI.dispatch(onButtonDisable(false));
		}
	}
);

/***************************************************
 * DEBUG thunks
 ***************************************************/

export const clearDailyLoginTracker = createAsyncThunk(
	`${PREFIX}/debug/clear-tracker`,
	async (_, thunkAPI): Promise<void> => {
		try {
			await service.Post<DailyLoginStreakResponse>(
				"/rest/debug/daily-bonus/streak-trackers/clear"
			);
		} catch (error) {
			console.log(error);
		} finally {
			thunkAPI.dispatch(getDailyLoginStreak());
		}
	}
);

export const collectDailySeries = createAsyncThunk(
	`${PREFIX}/debug/collect-series`,
	async (numCollects: number, thunkAPI): Promise<void> => {
		try {
			await service.Post<DailyLoginStreakResponse>(
				`/rest/debug/daily-bonus/collect-series?numCollects=${numCollects}`
			);
			thunkAPI.dispatch(getDailyLoginStreak());
		} catch (error) {
			console.log(error);
		}
	}
);
