import { useState, useEffect } from "react";

export const useImagePreloader = (url: string) => {
	const [isImageLoaded, setIsImageLoaded] = useState(url ? false : true);

	useEffect(() => {
		if (!url) {
			return;
		}
		const imageToLoad = new Image();
		imageToLoad.src = url;
		imageToLoad.onload = () => {
			setIsImageLoaded(true);
			// console.log("image loaded: " + image);
		};

		const timer = setTimeout(() => setIsImageLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, [url]);

	return { isImageLoaded };
};
