import { useLoadReward } from "hooks";
import { useAppSelector } from "hooks";
import { PoisBountyProgressStep, RewardClaimStatus } from "interfaces";
import { selectPoisBountyState } from "redux/slices";

interface IUseRewards {
	steps: PoisBountyProgressStep[];
}

export const useRewards = (data: RewardClaimStatus[]): IUseRewards => {
	const { loadReward } = useLoadReward();
	const { tracker } = useAppSelector(selectPoisBountyState).data;

	const steps: PoisBountyProgressStep[] = data.map((item) => {
		const { reward, claimed } = item;
		const { rewardId, loyaltyPoints } = reward;
		return {
			imageUrl: loadReward(rewardId)?.imageUrl,
			rewardAmount: loadReward(rewardId)?.numberOfItems,
			target: loyaltyPoints,
			status: claimed
				? "unlocked"
				: tracker?.loyaltyPoints >= loyaltyPoints
				? "in-progress"
				: "locked"
		};
	});

	return {
		steps
	};
};
