import { styled } from "@mui/material";

export const Container = styled("div")`
	width: 910px;
	height: 490px;
	background-color: #fff;
	border-radius: 1.5rem 1.5rem 1rem 1rem;
	position: relative;
	overflow: hidden;
	@media (max-width: 910px) {
		width: 95vw;
		margin: 0 auto;
	}
	&.landscape {
		@media (max-width: 695px) {
			height: 520px;
		}
		@media (max-width: 680px) {
			height: 550px;
		}
		@media (max-width: 900px) and (max-height: 570px) {
			height: 320px;
			.alignment {
				transform: translateY(-140px);
			}
			.copy {
				font-size: 11px;
				line-height: 12px;
			}
		}
	}

	&.mobile {
		height: 450px;
		max-width: 410px;
		padding-bottom: 0.5rem;
		.alignment {
			gap: 0;
		}
		.note {
			margin-top: "8px";
			font-size: 18px;
		}

		.copy {
			line-height: 19px;
			font-size: 12px;
		}
	}
`;

export const Alignment = styled("div")`
	transform: translateY(-120px);
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const Head = styled("div")`
	height: 180px;
`;

export const Body = styled("div")``;

export const Background = styled("img")`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Footer = styled("div")`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	gap: 1rem;
	width: 80%;
	margin: 0 auto;
	margin-top: 0;
`;

export const Copy = styled("p")`
	margin: 0;
	font-size: 14px;
	line-height: 26px;
`;

export const Note = styled("p")`
	margin: 0;
	font-weight: bold;
	font-size: 24px;
	color: #f2994a;
	margin-bottom: 0.5rem;
	@media (max-width: 700px) and (max-height: 400px) {
		font-size: 20px;
	}
`;

export const Bold = styled("strong")``;
