import { useState } from "react";
import { useAppDispatch } from "hooks";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { collectDailySeries } from "redux/slices";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const SetDailyLoginSeriesDialog = () => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [fieldValue, setFieldValue] = useState(0);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(e.target.valueAsNumber);
	};

	const onSubmit = () => {
		if (fieldValue < 0) {
			return;
		}
		dispatch(collectDailySeries(fieldValue));
		setOpen(false);
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Set
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Set</DialogTitle>
				<DialogContent>
					<TextField
						onChange={onChange}
						autoFocus
						margin="dense"
						id="name"
						defaultValue={fieldValue}
						label="Number collect"
						type="number"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={onSubmit}>Submit</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SetDailyLoginSeriesDialog;
