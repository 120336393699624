import styled from "styled-components";

export const ProgressStyled = styled.div<{ stepCount: number }>(({ stepCount }) => `
	display: flex;
	align-items: center;
	justify-content: ${stepCount < 5 ? "center" : "normal"};
	transform: ${(stepCount < 5 && "translateX(-20px)") || "translateX(0)"};

	&.portrait {
		transform: ${
			(stepCount === 7 && "rotate(-90deg) translate(-340px, -94%)") ||
			(stepCount === 6 && "rotate(-90deg) translate(-270px, -70%)") ||
			(stepCount === 4 && "rotate(-90deg) translate(-170px, -22%)") ||
			(stepCount === 3 && "rotate(-90deg) translate(-110px, 0%)") ||
			"rotate(-90deg) translate(-220px, -50%)"
		};

		@media (max-width: 770px) {
			margin-top: -25%;
		}
		@media (max-width: 360px) {
			transform: ${
				(stepCount === 7 && "rotate(-90deg) translate(-340px, -97%)") ||
				(stepCount === 6 && "rotate(-90deg) translate(-270px, -73%)") ||
				(stepCount === 4 && "rotate(-90deg) translate(-170px, -26%)") ||
				(stepCount === 3 && "rotate(-90deg) translate(-110px, -1%)") ||
				"rotate(-90deg) translate(-220px, -50%)"
			};
		}
	}

	&.page-variant {
		margin-top: 65px;

		&.event-completed {
			margin-top: 20px;
		}
	}
`
);
