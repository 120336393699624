import React from "react";

import { Navigation } from "interfaces/navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import {
	ClubShopIcon,
	CrownIcon,
	NewsIcon,
	ShopIcon,
	DailyGiftIcon,
	ClubGiftIcon,
	PoisBountyIcon,
	DripOfferIcon
} from "utils/svgIcons";
import { VersionInfo } from "pages/StoreFront/styles";
import { useWebStoreContext } from "contexts";
import { TopBarButton } from "components/PageTopNavV2/styled";

interface Props {
	page: Navigation;
}

export const MenuOption: React.FC<Props> = ({ page }) => {
	const location = useLocation();
	const { reportEvent } = useGoogleAnalyticsContext();
	const navigate = useNavigate();
	const { authToken } = useWebStoreContext();

	const onClick = (page: Navigation) => {
		reportEvent(page.analytics_key, { source: "navbar" });
		navigate(page.path);
	};

	return (
		<TopBarButton
			color={location.pathname === page.path ? "#113b62" : "#fff"}
			background={
				location.pathname === page.path ? "#FFF" : "rgba(17, 59, 98, 0.75)"
			}
			onClick={() => onClick(page)}
		>
			{page.icon === "store" && <ShopIcon />}
			{page.icon === "club" && <ClubShopIcon />}
			{page.icon === "crown" && <CrownIcon />}
			{page.icon === "news" && <NewsIcon />}
			{page.icon === "daily-gift" && <DailyGiftIcon />}
			{page.icon === "club-gift" && <ClubGiftIcon />}
			{page.icon === "pois-bounty" && <PoisBountyIcon />}
			{page.icon === "drip-offer" && <DripOfferIcon />}
			<span>{page.title}</span>
			{
				//! Once finished we must comment the following line
				authToken?.value && page.path === "/shop" && (
					<VersionInfo>Version 2.0</VersionInfo>
				)
			}
		</TopBarButton>
	);
};
