import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { links } from "helpers";

const PaginationComponent = ({
	count,
	page
}: {
	count: number;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const navigate = useNavigate();
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		navigate(`${links.news.path}/page/${value}`);
		window && window.scrollTo(0, 0);
	};

	return count > 1 ? (
		<Stack
			spacing={2}
			sx={{ marginTop: "4rem", "&, button": { fontWeight: "bold" } }}
		>
			{/* <Typography>Page: {page}</Typography> */}
			<Pagination
				count={count}
				page={page}
				onChange={handleChange}
				sx={{ display: "flex", justifyContent: "center" }}
			/>
		</Stack>
	) : null;
};

export default PaginationComponent;
