import Main from "./Main";
import theme from "./theme";
import DIP from "./components/DIP";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { isMobile } from "react-device-detect";
import PopUpManager from "components/PopUpManager";
import StickySaleTag from "components/StickySaleTag";
import DebugMenu from "./debug/components/DebugMenuDev";
import WebNewsProvider from "./contexts/WebNewsProvider";
import FacebookProvider from "./contexts/FacebookProvider";
import WebStoreProvider from "./contexts/WebStoreProvider";
import WebWinnersProvider from "contexts/WebWinnersProvider";
import { UnLoggedRedirection } from "hoc/UnLoggedRedirection";
import PoisBountyStickyTag from "components/PoisBountyStickyTag";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GoogleAnalyticsProvider from "./contexts/GoogleAnalyticsProvider";
import ScopelyIdentityProvider from "./contexts/ScopelyIdentityProvider";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import StickyBackToGameButton from "components/Buttons/StickyBackToGameButton";
import WebstoreHttpServiceProvider from "./contexts/WebstoreHttpServiceProvider";
import { DebugActions } from "debug/components/DebugActions";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnReconnect: false,
			refetchOnWindowFocus: false
		}
	}
});

function AppContent(): JSX.Element {
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<DebugActions>
					<WebstoreHttpServiceProvider>
						<GoogleAnalyticsProvider>
							<FacebookProvider>
								<ScopelyIdentityProvider>
									<WebStoreProvider>
										<WebNewsProvider>
											<WebWinnersProvider>
												<StyledEngineProvider injectFirst>
													<UnLoggedRedirection>
														<ThemeProvider theme={theme}>
															<Main />
															<DIP />
															<PopUpManager />
															<StickySaleTag />
															<PoisBountyStickyTag />
															{isMobile && (
																<StickyBackToGameButton source="sticky_button" />
															)}
															{process.env.REACT_APP_WITH_DEBUG === "true" && (
																<DebugMenu />
															)}
														</ThemeProvider>
													</UnLoggedRedirection>
												</StyledEngineProvider>
											</WebWinnersProvider>
										</WebNewsProvider>
									</WebStoreProvider>
								</ScopelyIdentityProvider>
							</FacebookProvider>
						</GoogleAnalyticsProvider>
					</WebstoreHttpServiceProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</DebugActions>
			</QueryClientProvider>
		</Provider>
	);
}

export default AppContent;
