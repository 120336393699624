import { Link, Typography, useMediaQuery } from "@mui/material";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";

export const Legalese = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const isLandscapeMobile = useMediaQuery("(max-width: 932px) and (max-height: 440px)");

	return (
		<Typography
			variant="caption"
			sx={{ p: !isLandscapeMobile ? ".75rem 1.5rem" : ".5rem 1.5rem" }}
			fontSize={!isLandscapeMobile ? "0.75rem" : "0.7rem"}
		>
			By confirming your purchase, you agree to the{" "}
			<Link
				href="http://scopely.com/tos"
				target="_blank"
				rel="noreferrer"
				onClick={() => {
					reportEvent("terms_of_service_click", {
						source: "loyalty_purchase_confirm_dialog"
					});
				}}
			>
				Store Terms of Sale
			</Link>
			, including{" "}
			<Link
				href="http://scopely.com/privacy"
				target="_blank"
				rel="noreferrer"
				onClick={() => {
					reportEvent("privacy_policy_click", {
						source: "loyalty_purchase_confirm_dialog"
					});
				}}
			>
				Usage rules for Digital Goods
			</Link>
			. You’ll be charged immediately.
		</Typography>
	);
};
