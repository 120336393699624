// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { WebStoreContext } from "../../contexts/WebStoreProvider";
import { useContext, useEffect, useState } from "react";
import { DebugWebStoreContext } from "../contexts/DebugWebStoreProvider";

const SetLoyaltyPointsDialog = () => {
	const [open, setOpen] = useState(false);
	const [fieldValue, setFieldValue] = useState(0);
	const { userTracker } = useContext(WebStoreContext);
	const { setLoyaltyPoints } = useContext(DebugWebStoreContext);

	useEffect(() => {
		if (userTracker) {
			setFieldValue(userTracker.loyaltyPoints);
		} else {
			setFieldValue(0);
		}
	}, [userTracker]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(e.target.valueAsNumber);
	};

	const handleSetLoyaltyPoints = () => {
		if (fieldValue < 0) {
			return;
		}
		setLoyaltyPoints(fieldValue);
		setOpen(false);
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Set
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Set</DialogTitle>
				<DialogContent>
					<TextField
						onChange={onChange}
						autoFocus
						margin="dense"
						id="name"
						defaultValue={fieldValue}
						label="Loyalty Points"
						type="number"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSetLoyaltyPoints}>Set</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SetLoyaltyPointsDialog;
