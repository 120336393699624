import { styled, SvgIcon } from "@mui/material";
import { ArrowRightIcon } from "utils/svgIcons";
import { Link as RouterLink } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ThemeButton from "../ThemeButton";
import { useGoogleAnalyticsContext } from "contexts";

const _SectionButton = styled(ThemeButton)`
	background: linear-gradient(180deg, #4088b7 0%, #1c6595 100%);
	border: 1px solid #52a5dc;
	width: 194px;
	&:hover {
		background: linear-gradient(180deg, #36a1e8 0%, #2070a5 100%),
			linear-gradient(0deg, #4ab8ff, #4ab8ff);
	}
`;

const SectionButton = ({
	isExternal,
	href,
	text,
	analytics,
	openExternalSameTab = false
}: {
	isExternal: boolean;
	href: string;
	text: string;
	openExternalSameTab?: boolean;
	analytics: string;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	/* eslint-disable-next-line */
	const Button: any = _SectionButton;

	return (
		<>
			{!isExternal ? (
				<Button
					to={href}
					component={RouterLink}
					onClick={() => {
						window.scrollTo(0, 0);
						reportEvent(analytics, { source: "home_page" });
					}}
				>
					{text}
					<SvgIcon component={ArrowRightIcon} />
				</Button>
			) : (
				<Button
					onClick={() => {
						window.open(href, openExternalSameTab ? "_self" : "_blank");
						reportEvent(analytics, { source: "home_page" });
					}}
				>
					{text}
					<OpenInNewIcon />
				</Button>
			)}
		</>
	);
};

export default SectionButton;
