import React from "react";
import { Skeleton } from "@mui/material";

export const FreeGiftCardSkeleton = () => {
	return (
		<Skeleton
			animation="wave"
			variant="rectangular"
			sx={{
				maxWidth: "100%",
				margin: "10px 10px 40px 10px",
				height: "400px",
				borderRadius: "25px"
			}}
		/>
	);
};
