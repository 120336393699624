import classNames from "classnames";
import { StepProps } from "./types";
import { StepContext } from "./context";
import { useCallback, useMemo } from "react";
import { nFormatter } from "utils/number-formatter";
import { useGoogleAnalyticsContext } from "contexts";
import { useAppDispatch, useAppSelector } from "hooks";
import starBurst from "assets/pois-bounty/star-burst.png";
import { ClaimButton, Marker, StatusIcon, Target } from "..";
import { Indicator } from "components/PoisBountyWidgetV2/lib/common";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";
import { onClaimV2Reward, onPBV2change, selectPoisBountyV2State } from "redux/slices";
import {
	DEFAULT_CONFIG,
	getPrizeClassNames,
	getProgressBarWidth,
	getProgressClassNames,
	getRewardItemClassNames,
	getStepClassNames,
	getTextClassNames
} from "./helper";
import {
	Bar,
	Percentage,
	ProgressRewardStep,
	Reward,
	RewardImage,
	RewardItem,
	RewardPrizeImage,
	RewardText,
	StarBurst,
	StepStyled
} from "./styles";

export const Step = ({
	reward,
	stepIndex,
	config = DEFAULT_CONFIG
}: StepProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const { rewards, trackers, userLoyaltyPoints, blockAllButtons } = useAppSelector(selectPoisBountyV2State);
	const { hideBar, first, last } = config;
	const { target, status, rewardAmount, imageUrl } = reward;
	const isInProgress = status === "in-progress";
	const isLocked = status === "locked";

	const onClaim = useCallback(() => {
		dispatch(onPBV2change({ rewardCollectedV2: reward }));
		dispatch(onClaimV2Reward(stepIndex));
		reportEvent("claim_reward", {
			source: "pois_bounty_v2",
			pois_bounty_id: trackers.eventId,
			reward_id: rewards[stepIndex].reward.rewardId,
			tier: stepIndex + 1,
			cycle_index: trackers.cycleIndex,
		});
	}, []);

	const values = useMemo(() => ({ target, last, status }), [status]);

	return (
		<StepContext.Provider value={values}>
			<StepStyled
				className={classNames(getStepClassNames(isPortrait, isPageVariant, first))}
				steps={trackers.rewardClaimStatuses.length}
			>
				{/* 🎁 Reward:  item, marker, target and claim button */}
				<Reward className={`reward ${blockAllButtons && "claim-all"}`}>
					<RewardItem
						className={classNames(
							getRewardItemClassNames(status, isPageVariant)
						)}
					>
						{isInProgress && <StarBurst src={starBurst} />}
						<StatusIcon status={status} />
						<RewardImage
							background={"/spec_pois_bounty/item-glow.png"}
							color={trackers.rewardSlotColorHex}
						>
							<RewardPrizeImage
								src={imageUrl}
								status={status}
								className={getPrizeClassNames(status)}
							/>
							{rewardAmount && (
								<RewardText
									className={classNames(getTextClassNames(status, isPageVariant))}
								>
									x{nFormatter(rewardAmount)}
								</RewardText>
							)}
						</RewardImage>
					</RewardItem>
					<Marker />
					<Target />
					{!blockAllButtons && (
						<ClaimButton onClick={onClaim} />
					)}
				</Reward>

				{/* 📊 Reward Progress Bar */}
				<ProgressRewardStep
					className={classNames(getProgressClassNames(first, hideBar))}
				>
					<Bar className="reward-bar">
						{!hideBar && (
							<Percentage
								className={classNames(first && "first")}
								style={{
									width: getProgressBarWidth(
										status,
										userLoyaltyPoints,
										target,
										isPageVariant
									)
								}}
							>
								{isLocked && (
									<Indicator
										lastStep={last}
										userLoyaltyPoints={userLoyaltyPoints}
									/>
								)}
							</Percentage>
						)}
					</Bar>
				</ProgressRewardStep>
			</StepStyled>
		</StepContext.Provider>
	);
};
