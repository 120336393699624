import { AppBar, Toolbar } from "@mui/material";
import { MenuButton } from "components/AppNavigation/MegaMenu/lib";
import { MegaMenu } from "components/AppNavigation/MegaMenu";
import { AppBarLogo, AppBarUser } from "./lib";
import { appBarStyles } from "./styles";
import HideOnScroll from "./lib/HideOnScroll";

const AppBarComponent = () => {
	return (
		<HideOnScroll isDisabled>
			<AppBar position="fixed" sx={appBarStyles}>
				<Toolbar>
					<AppBarLogo />
					<AppBarUser />
					<MenuButton />
					<MegaMenu />
				</Toolbar>
			</AppBar>
		</HideOnScroll>
	);
};

export default AppBarComponent;
