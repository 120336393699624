import { createContext, ReactNode, useContext, useEffect } from "react";
import { getDebugLogger } from "../utils/debugLogger";
import { useEnvContext } from "./EnvProvider";
import { isMobile } from "react-device-detect";
import { useGoogleAnalyticsContext } from "./GoogleAnalyticsProvider";
import {
	AbstractHttpService,
	createHttpService
} from "../infrastructure/HttpService";
import { GSNHeader } from "../infrastructure/HttpService/headerConstants";

const debugColor = "Cyan";
const debug = getDebugLogger({
	isEnabled: !false,
	color: debugColor
});

export interface IWebstoreHttpServiceContext {
	webstoreHttpService: AbstractHttpService;
}

export const WebstoreHttpServiceContext =
	createContext<IWebstoreHttpServiceContext>({
		webstoreHttpService: createHttpService(debug)
	});

const WebstoreHttpServiceProvider = ({ children }: { children: ReactNode }) => {
	const { backendUri } = useEnvContext();
	const deviceType = isMobile ? "mobile" : "desktop";
	const { syntheticId } = useGoogleAnalyticsContext();

	const httpService = createHttpService(debug);
	httpService.setLogContext("Webstore Http Service");
	httpService
		.setDefaultHeader(GSNHeader.DeviceType, deviceType)
		.setDefaultHeader(GSNHeader.SyntheticId, syntheticId)
		.setDefaultBackendUrl(backendUri);
	// If any of these values change, update httpService
	useEffect(() => {
		httpService
			.setDefaultHeader(GSNHeader.DeviceType, deviceType)
			.setDefaultHeader(GSNHeader.SyntheticId, syntheticId)
			.setDefaultBackendUrl(backendUri);
	}, [backendUri, deviceType, syntheticId]);

	const value: IWebstoreHttpServiceContext = {
		webstoreHttpService: httpService
	};

	return (
		<WebstoreHttpServiceContext.Provider value={value}>
			{children}
		</WebstoreHttpServiceContext.Provider>
	);
};

export const useWebstoreHttpService = () => {
	const context = useContext(WebstoreHttpServiceContext);
	if (context === undefined) {
		throw new Error(
			"WebstoreHttpServiceContext was used outside of its Provider"
		);
	}
	return context;
};

export default WebstoreHttpServiceProvider;
