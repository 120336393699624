import { useEnvContext } from "contexts/EnvProvider";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { FeaturesFlags } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectDipState, onDIPtrigger } from "redux/slices";
import { FacebookIcon, LogoutIcon, scopelyIconPath } from "utils/svgIcons";
import { LogoutButton } from "../styled";
import { useScopelyIdentityContext } from "contexts";

export const LogOutButtonComponent = () => {
	const dispatch = useAppDispatch();
	const { logout, userTracker, fbAccessToken, isAutoLogedIn } = useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { availableTriggers } = useAppSelector(selectDipState);
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();
	const { sidAccessToken, sidIdentityToken } = useScopelyIdentityContext();

	return (
		<LogoutButton
			onClick={() => {
				const scopelyLogout =
					userTracker?.metadata?.userData?.loginType === "scopely";
				loginGoogleAnalyticsHelper.reportLogoutClick("right_menu");
				if (!isFeatureFlagEnabled(FeaturesFlags.DIPS)) {
					logout();
					return;
				}

				if (scopelyLogout && availableTriggers.includes("scopelyLogout")) {
					dispatch(onDIPtrigger("scopelyLogout"));
				} else if (availableTriggers.includes("facebookLogout")) {
					dispatch(onDIPtrigger("facebookLogout"));
				} else {
					logout();
				}
			}}
		>
			{fbAccessToken && !isAutoLogedIn
				? <FacebookIcon title="Log out" style={{ width: " 100%", height: "auto" }} />
				: sidAccessToken && sidIdentityToken
					// using img instead of icon because svg <circle> prop doesn't work on larger devices
					? <img src={scopelyIconPath} alt="Scopely logout" style={{ width: "100%", height: "auto" }} />
					: <LogoutIcon title="Log out" style={{ width: " 100%", height: "auto" }} />
			}
		</LogoutButton>
	);
};
