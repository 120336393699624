import dip from "./slices/dip";
import debug from "./slices/debug";
import dialog from "./slices/dialog";
import megaMenu from "./slices/mega-menu";
import webStore from "./slices/web-store";
import waterfall from "./slices/waterfall";
import dailyGift from "./slices/daily-gift";
import stickyDip from "./slices/sticky-dip";
import poisBounty from "./slices/pois-bounty";
import poisBountyV2 from "./slices/pois-bounty/v2";
import popUpManager from "./slices/pop-up-manager";
import stickySaleTag from "./slices/sticky-sale-tag";
import clubGiftsGalore from "./slices/club-gifts-galore";
import shopSections from "./slices/shop-sections";
import dailyLoginStreak from "./slices/daily-login-streak";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { baseApi } from "./apis/api";

export const store = configureStore({
	reducer: {
		webStore,
		dip,
		shopSections,
		poisBounty,
		poisBountyV2,
		megaMenu,
		dailyGift,
		stickyDip,
		waterfall,
		clubGiftsGalore,
		popUpManager,
		dialog,
		dailyLoginStreak,
		stickySaleTag,
		debug,
		[baseApi.reducerPath]: baseApi.reducer
		//[segmentationOverrideApi.reducerPath]: segmentationOverrideApi.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([baseApi.middleware])
});

setupListeners(store.dispatch);
/***************************************************
 * Redux interfaces ~ types
 ***************************************************/
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
