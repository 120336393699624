import { useState, useEffect } from "react";

const useCalculateScale = (referenceWidth: number, referenceHeight: number) => {
	const [scale, setScale] = useState(1);

	useEffect(() => {
		const calculateScale = () => {
			const windowWidth = document.documentElement.clientWidth;
			const windowHeight = document.documentElement.clientHeight;
			const scaleX = windowWidth / referenceWidth;
			const scaleY = windowHeight / referenceHeight;
			const scaleFactor = Math.min(scaleX, scaleY);
			setScale(scaleFactor);
		};

		calculateScale();
		window.addEventListener("resize", calculateScale);

		return () => {
			window.removeEventListener("resize", calculateScale);
		};
	}, [referenceWidth, referenceHeight]);

	return scale > 1 ? 1 : scale;
};

export default useCalculateScale;
