import styled from "styled-components";
import { defaultTextColor } from "styles/general_styles";
import { Typography } from "@mui/material";
import { keyframes } from "styled-components";

interface ICardContainer {
	priority?: number;
	eventIsCompleted: boolean;
	isUserQualifiedToContribute?: boolean;
	image?: string;
}

export const CardContainer = styled.div<ICardContainer>`
	background: ${(props) => props.color};
	box-shadow: ${(props) =>
		props?.priority === 1 &&
		!props?.eventIsCompleted &&
		props?.isUserQualifiedToContribute
			? "0px 4px 16px 5px #FFDA58"
			: "0px 6px 15px 0px rgba(0, 0, 0, 0.15)"};
	border-radius: 26px;
	margin: 10px 0px 10px 0px;
	animation: fadeIn 1s;
	position: relative;
	max-width: 400px;
	height: auto;
	min-height: 325px;
	font-size: 12px;
	overflow: hidden;
`;

export const ImageContainer = styled.div<{ image: string }>`
	position: relative;
	background-image: url(${(props) => props.image});
	background-repeat: no-repeat;
	background-size: 100% auto;
	width: 100%;
	height: 107.25px /*33%*/;
	display: flex;
	align-items: center;
	//justify-content: center;
`;

export const DataContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr; /* Dos columnas de igual ancho */
	grid-gap: 3px;
	padding: 8px;
`;

export const Title = styled.div`
	width: 60%;
	color: #fff;
	text-shadow: 0 0 5px #113b62, 0 0 5px #113b62, 0 0 5px #113b62,
		0 0 5px #113b62, -1px -1px 0 #113b62, 1px -1px 0 #113b62, -1px 1px 0 #113b62,
		1px 1px 0 #113b62, -2px -2px 0 #113b62, 2px -2px 0 #113b62,
		-2px 2px 0 #113b62, 2px 2px 0 #113b62;

	font-family: Baloo;
	font-size: clamp(2em, 2.2dvw, 2.4em);

	font-style: normal;
	font-weight: 400;
	line-height: 30px;
	margin-left: 10px;
`;

export const SubTitle = styled.div`
	text-align: left;
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 700;
	font-size: 1em;
	line-height: 15px;
`;

export const RewardsContainer = styled.div`
	flex-basis: 50%;
	background: #ebebeb;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	//height: 94px;
	height: 100px;
`;

export const Reward = styled.div`
	position: relative;
	text-align: center;
`;

export const RewardIcon = styled.img`
	max-height: 56px;
	position: relative;
	z-index: 1;

	&.several-items {
		max-height: 50px;

		@media (min-width: 1150px) {
			max-height: 56px;
		}
	}
`;

export const Number = styled.span`
	position: absolute;
	bottom: -9px;
	right: 0px;
	font-family: "Baloo";
	color: #fff;
	z-index: 3;
	color: white;
	text-shadow: 0 0 3px #000000, 0 0 4px #000000, 0 0 4px #000000,
		0 0 3px #000000;
	display: flex;
	font-size: 1.6em;
	align-items: center;
`;

export const StarBurstFrames = keyframes`
  from {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
`;

export const StarBurst = styled.img`
	animation: ${StarBurstFrames} 20s linear infinite;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0.8;
`;

export const CrossOutValue = styled(Typography)`
	color: #eb5757;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	font-size: 1em;
	position: relative;
	z-index: 1;

	&.thankyoucoins {
		position: absolute;
		top: -45%;
		text-decoration: line-through;
		text-decoration-thickness: 1.5px;

		@media (min-width: 733px) {
			text-decoration-thickness: 1.4px;
		}
		@media (min-width: 1150px) {
			text-decoration-thickness: 1.5px;
		}
	}

	&.reward {
		position: absolute;
		top: 25px;
		right: -8px;
		font-size: 1.33em;
		text-shadow: 0 0 3px #000, 0 0 3px #000, 0 0 3px #000, 0 0 3px #000,
			0 0 3px #000, 0 0 3px #000, 0 0 3px #000, 0 0 3px #000, 0 0 3px #000,
			0 0 3px #000;
		font-weight: 800;
		//text-decoration: line-through;
		//text-decoration-thickness: 1.2px;
	}
`;

export const CrossOutLine = styled("span")`
	border-bottom: 2px solid #eb5757;
	width: 100%;
	height: 1px;
	display: block;
	position: absolute;
	top: 40%;

	&.reward {
		top: 42%;
		border-radius: 2px;
		height: 2px;
		border: 1px solid #000;
		background: #eb5757;
	}
`;

export const ThanksMessage = styled.div`
	position: relative;
	text-align: left;
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 700;
	font-size: 1em;
	padding: 4%;
	padding-top: 0px;
	line-height: 15px;

	@media (min-width: 733px) {
		font-size: 0.9em;
	}
	@media (min-width: 1150px) {
		font-size: 1em;
	}

	@media (min-width: 1250px) {
		font-size: 1.1em;
	}
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
	//margin-top: 6px;
`;

export const LoyaltyPoints = styled.div`
	background-color: #ebebeb;
	color: #333;
	font-size: 1.2em;
	font-weight: 700;
	padding: 0.5em 0.9em;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;

	&.crossout {
		padding: 0.2em 0.8em;
	}
`;

export const FinalMessage = styled.div`
	//color: ${(props) => props.color || defaultTextColor};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 1.2em;
	height: 38px;
	text-align: center;
`;
