import { timeStyle } from "./styles";
import classNames from "classnames";
import moment, { Moment } from "moment";
import { FeaturesFlags } from "helpers";
import { useGetOffersQuery } from "redux/apis";
import Countdown from "components/CountdownV2";
import { useEnvContext } from "contexts/EnvProvider";
import { Fragment, useEffect, useState } from "react";
import { useInitialActions, useListeners } from "./hooks";
import { onCloseDIP, onDIPtrigger, selectDipState } from "redux/slices/dip";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch, useAppSelector, useImagePreloader } from "hooks";
import { getClassNameVariant, getCloseClickTrackingParams } from "./helper";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import {
	Animated,
	Assets,
	Bogo,
	BuyItAll,
	Close,
	Container,
	Informative,
	Offers,
	Wrapper
} from ".";
import useDIPCalculateScale from "./hooks/useDIPCalculateScale";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Typography } from "@mui/material";

const DIP = () => {
	const dispatch = useAppDispatch();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { nowTime, authToken } = useWebStoreContext();
	const { dipType, opened, currentDip } = useAppSelector(selectDipState);
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const { reportEvent } = useGoogleAnalyticsContext();
	const [dipEndTime, setDipEndTime] = useState<Moment | undefined | string>();
	const [stickyTimerLabel, setStickyTimerLabel] = useState<string | undefined>(
		undefined
	);
	// prettier-ignore
	const { isImageLoaded: isBackgroundImageLoaded } = useImagePreloader(currentDip?.dip.imageUrl as string);
	// prettier-ignore
	const { isImageLoaded: isHeaderImageLoaded } = useImagePreloader(currentDip?.dip.headerImageUrl as string);
	// prettier-ignore
	const isStickyDip = typeof currentDip?.dip.timerDurationMinutes !== "undefined";
	const isOffersType = dipType === "offers";
	const showCountdown = currentDip?.dip.timer || isStickyDip;
	const isBogo = currentDip?.dip.isBogo;
	const isBuyItAll = currentDip?.dip.isBuyItAll;
	const scale = useDIPCalculateScale(dipType);

	const { isLoading: isLoyaltyOffersloading } = useGetOffersQuery({
		offerType: "loyalty",
		isAuthenticated: authToken?.value ? true : false
	});
	const { isLoading: isPurchaseOffersLoading } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: authToken?.value ? true : false
	});

	useInitialActions();
	useListeners();

	const handleCloseClick = () => {
		dispatch(onCloseDIP());
		setDipEndTime(undefined);
		setStickyTimerLabel(undefined);
		setTimeout(() => {
			dispatch(onDIPtrigger("cache-mode"));
		}, 1000);
		reportEvent("dialog_x_close", {
			source: "dips_dialog",
			...getCloseClickTrackingParams(currentDip?.dip.id, currentDip?.buttons)
		});
	};

	useEffect(() => {
		if (typeof dipEndTime !== "undefined") {
			return;
		}
		setDipEndTime(
			isStickyDip
				? moment(nowTime).add(currentDip.dip.timerDurationMinutes, "minutes")
				: currentDip?.dip.endTime
		);
	}, [dipEndTime, nowTime, isStickyDip, currentDip]);

	return opened &&
		isFeatureFlagEnabled(FeaturesFlags.DIPS) &&
		isBackgroundImageLoaded &&
		!isLoyaltyOffersloading &&
		!isPurchaseOffersLoading &&
		isHeaderImageLoaded ? (
		<Wrapper>
			<Animated>
				<Container
					scale={scale}
					className={classNames([
						isOffersType ? "offers" : "informative",
						isBogo && "bogo",
						isBuyItAll && "buy-it-all",
						getClassNameVariant(currentDip?.buttons?.length as number)
					])}
				>
					{showOfferId && (
						<Typography
							sx={{
								fontWeight: 700,
								color: "red",
								fontSize: "12px",
								height: "auto",
								position: "absolute",
								top: 50,
								left: "1.1%"
							}}
						>
							{currentDip?.dip?.id}
						</Typography>
					)}
					{isBuyItAll ? (
						<BuyItAll />
					) : isBogo ? (
						<Bogo />
					) : isOffersType ? (
						<Offers />
					) : (
						<Informative />
					)}
					<Assets dipType={dipType} />
					<Close onClose={handleCloseClick} />
					{showCountdown && dipEndTime && (
						<Countdown
							// @ts-ignore
							endTime={dipEndTime}
							startTime={nowTime}
							text={stickyTimerLabel}
							className="dip-countdown"
							onEnd={() => {
								if (isStickyDip) {
									setStickyTimerLabel("Expired");
									return;
								}
								dispatch(onCloseDIP());
								setDipEndTime(undefined);
							}}
							hideTimerDisplayOnEnd={true}
							style={timeStyle}
						/>
					)}
				</Container>
			</Animated>
		</Wrapper>
	) : (
		<Fragment />
	);
};

export default DIP;
export * from "./types/provider";
export * from "./animation";
export * from "./styles";
export * from "./lib";
