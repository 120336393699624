import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import ExperimentsOverride from "./ExperimentsOverride";
import { SegmentationContent } from "./styles";

const ExperimentsDialog = () => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClick}>
				Set Experiments
			</Button>
			<Dialog fullScreen open={open} onClose={handleClick}>
				<DialogContent>
					<SegmentationContent>
						<ExperimentsOverride onSave={handleClick} />
					</SegmentationContent>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClick}>Close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ExperimentsDialog;
