import { RefObject, useRef } from "react";
import {
	IShopLoyaltyOffer,
	IShopSection
} from "contexts/WebStoreProvider/types";
import getSectionIcon from "utils/svgIcons";
import Section from "components/Section";
import LoyaltyOfferCard from "../LoyaltyOfferCard";

const LoyaltyFeaturedOfferSection = ({
	data,
	sectionRef,
	sectionOffers
}: {
	className?: string;
	data: IShopSection;
	sectionRef: RefObject<HTMLElement>;
	sectionOffers: IShopLoyaltyOffer[];
}): JSX.Element => {
	const backgroundImageUrl = data?.cardBackgroundImageUrl || "";

	const offers: JSX.Element[] = sectionOffers.map((o, i) => {
		const itemRef = useRef<HTMLDivElement>(null);
		return (
			<LoyaltyOfferCard
				itemRef={itemRef}
				data={o}
				backgroundImageUrl={backgroundImageUrl}
				key={o.rewardId + i}
			/>
		);
	});

	const sectionProps = {
		offers,
		sectionRef,
		headerText: data.title,
		iconUrl: getSectionIcon(data.name)?.url,
		icon: getSectionIcon(data.name)?.svg,
		display: "grid",
		isHideShowLess: true,
		sectionPage: "loyalty"
	};

	return <Section {...sectionProps} />;
};

export default LoyaltyFeaturedOfferSection;
