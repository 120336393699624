import { XsollaCont } from "../styled";

const XsollaFrame = ({
	token,
	host
}: {
	token: string;
	host: string;
}): JSX.Element => {
	const src = `https://${host}/paystation3/?access_token=${token}`;

	return (
		<XsollaCont className="xsolla-frame">
			<iframe id="xsolla-iframe" src={src} />
		</XsollaCont>
	);
};

export default XsollaFrame;
