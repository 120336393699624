import ThemeButton from "components/Buttons/ThemeButton";
import { styled } from "@mui/material";

export const Container = styled("div")`
	width: 910px;
	height: auto;
	padding-bottom: 1.5rem;
	background-color: #fff;
	border-radius: 1.5rem 1.5rem 1rem 1rem;
	position: relative;
	overflow: hidden;

	@media (max-width: 910px) {
		width: 95vw;
		margin: 0 auto;
	}

	&.mobile {
		max-width: 410px;

		.head {
			height: 95px;
		}

		.avatar-title {
			width: 90px;
		}

		.note {
			font-size: 16px;
		}

		.copy {
			line-height: 20px;
			font-size: 11px;
		}

		.button {
			padding: 0.6rem 3rem;
			margin-top: -1rem;
		}
	}

	@media (max-width: 932px) and (max-height: 440px) {
		height: 260px;
		width: 95vw;

		.head {
			height: 80px;
		}

		.avatar {
			display: none;
		}

		.alignment {
			transform: translateY(-68px);
		}

		.note {
			font-size: 16px;
			margin-bottom: 0;
		}

		.button {
			padding: 0.4rem 2rem;
			margin-top: -4rem;
		}
	}

	@media (max-height: 360px) {
		height: 220px;
	}
`;

export const Alignment = styled("div")`
	transform: translateY(-30px);
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const Head = styled("div")`
	height: 180px;
`;

export const Avatar = styled("img")`
	position: absolute;
	top: 4%;
	left: 50%;
	transform: translateX(-50%);
	width: 280px;
`;

export const Title = styled("img")`
	position: absolute;
	top: 1%;
	left: 50%;
	transform: translateX(-50%);
	width: 120px;
`;

export const Body = styled("div")``;

export const Background = styled("img")`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Footer = styled("div")`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	gap: 1rem;
	width: 90%;
	margin: 0 auto;
	@media (max-width: 910px) {
		width: 95%;
	}
`;

export const Note = styled("p")`
	margin: 0;
	font-weight: bold;
	font-size: 24px;
	color: #f2994a;
	margin-bottom: 0.5rem;
`;

export const Copy = styled("p")`
	margin: 0;
	font-size: 14px;
	line-height: 26px;
	@media (max-width: 900px) and (max-height: 440px) {
		font-size: 12px;
		line-height: 20px;
	}
`;

export const Button = styled(ThemeButton)`
	padding: 1rem 3rem;
	margin: 0 auto;
`;
