import Button from "components/Buttons/ThemeButton";
import { useDIPAction } from "components/DIP/hooks";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useAppSelector } from "hooks";
import { Fragment } from "react";
import { selectDipState } from "redux/slices";
import { getButtonLabel } from "./helper";
import { buttonStyled, InformativeStyled } from "./styles";
import { useLocation } from "react-router-dom";

interface InformativeProps {}

export const Informative: React.FC<InformativeProps> = () => {
	const { currentDip } = useAppSelector(selectDipState);
	const { onAction } = useDIPAction();
	const { reportEvent } = useGoogleAnalyticsContext();
	const location = useLocation();

	if (!currentDip) {
		return <Fragment />;
	}
	const { buttons } = currentDip;

	return (
		<InformativeStyled>
			{buttons && (
				<Button
					style={{ zIndex: 90 }}
					sx={buttonStyled}
					onClick={() => {
						onAction(buttons[0]);
						reportEvent("continue_shopping_click", {
							source: "dips_dialog",
							page_location: location.pathname
						});
					}}
				>
					{getButtonLabel(buttons[0])}
				</Button>
			)}
		</InformativeStyled>
	);
};
