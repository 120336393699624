import ThemeButton from "components/Buttons/ThemeButton";
import { styled } from "@mui/material";

export const Container = styled("div")`
	width: 430px;
	background-color: #fff;
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
	@media (max-width: 430px) {
		width: 95vw;
	}
`;

export const Header = styled("div")<{ "data-image": string }>`
	height: 188px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: cover;
	background-size: cover;
	background-position: center;
	background-image: url(${(props) => props["data-image"]});
	position: relative;

	@media (max-height: 375px) {
		height: 140px;
	}
`;

export const Avatar = styled("img")`
	object-fit: contain;
	position: absolute;
	bottom: 12px;
	left: 20%;
`;

export const Tresure = styled("img")`
	object-fit: contain;
	position: absolute;
	bottom: -12px;
	left: 28%;
`;

export const Body = styled("div")`
	max-width: 372px;
	margin: 0 auto;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

export const Title = styled("span")`
	font-family: "Baloo";
	font-size: 26px;
	text-align: center;
	color: #333;

	@media (max-height: 375px) {
		font-size: 24px;
		line-height: 24px;
	}
`;

export const Button = styled(ThemeButton)`
	padding: 0.5rem;
`;
