import { Box } from "@mui/material";
import { images } from "helpers";
import useBreakpoints from "hooks/useBreakpoints";
import { useImagePath } from "hooks/useImagePath";
import { useImagePreloader } from "hooks/useImagePreloader";
import { useEffect, useState } from "react";
import HeroGetGame from "./lib/GetGame";
import GameLogo from "./lib/GameLogo";
import {
	pageHeroWrapperBoxSxProps,
	pageHeroBoxSxProps,
	heroGetGameSxProps
} from "./styled";

const PageHero = ({
	sectionContentRef
}: {
	sectionContentRef: React.RefObject<HTMLElement>;
}) => {
	const [loading, setLoading] = useState(true);
	const { isMdUp } = useBreakpoints();
	const { getImage } = useImagePath("cdn");

	const leftImage = getImage(
		images.spec_landing_page__page_header_chars_left.src
	);
	const rightImage = getImage(
		images.spec_landing_page__page_header_chars_right.src
	);

	// we define logo image path here for preloading purposes, this gets passed down to GameLogo component
	const logoImagePathTemplate =
		images.spec_landing_page__logo_tiki_solitaire_tripeaks_template.src;
	const logoImageToPreload = getImage(
		logoImagePathTemplate,
		isMdUp ? "large" : "small"
	);

	const { isImageLoaded: isLeftImageLoaded } = useImagePreloader(leftImage);
	const { isImageLoaded: isRightImageLoaded } = useImagePreloader(rightImage);
	const { isImageLoaded: isLogoImageLoaded } =
		useImagePreloader(logoImageToPreload);

	useEffect(() => {
		if (isLogoImageLoaded && isRightImageLoaded && isLeftImageLoaded) {
			setLoading(false);
		}
	}, [isLogoImageLoaded, isRightImageLoaded, isLeftImageLoaded]);

	return (
		<Box
			className="page-hero animate__animated animate__bounceInDown"
			sx={pageHeroWrapperBoxSxProps}
		>
			{!loading && (
				<Box sx={pageHeroBoxSxProps}>
					<GameLogo logoImagePathTemplate={logoImagePathTemplate} />
					<HeroGetGame
						sx={heroGetGameSxProps}
						sectionContentRef={sectionContentRef}
					/>
					<img className="left" src={leftImage} />
					<img className="right" src={rightImage} />
				</Box>
			)}
		</Box>
	);
};

export default PageHero;
