import { MouseEventHandler, ReactElement } from "react";
import { Points } from "../styled";

interface GenericBalanceProps {
	text: string;
	icon: ReactElement<string>;
	points: number | string;
	onClick?: MouseEventHandler<HTMLDivElement>;
}

export const GenericBalance = ({ text, icon, points, onClick }: GenericBalanceProps) => {
	return (
		<Points
			onClick={onClick}
			sx={{
				cursor: "pointer"
			}}
		>
			<span className="label">
				{icon}
				{text}
			</span>
			<span className="value">
				{points.toLocaleString("en-US")}
			</span>
		</Points>
	);
};
