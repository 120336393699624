import { useEnvContext } from "contexts/EnvProvider";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { Link } from "react-router-dom";
import { LogoImage } from "../styled";
import { links } from "helpers";

export const FooterLogo = () => {
	const { cdnPrefix } = useEnvContext();
	const { reportEvent } = useGoogleAnalyticsContext();

	const handleLogoClick = () => {
		window.scrollTo(0, 0);
		reportEvent("logo_clicked", { source: "footer" });
	};
	return (
		<Link to={links.home.path} onClick={handleLogoClick}>
			<LogoImage src={`${cdnPrefix}/logo/tiki_solitaire_logo.png`} />
		</Link>
	);
};
