import { ReactComponent as FeaturedIcon } from "assets/submenu/ico-featured.svg";
import { ReactComponent as LimitedDealsIcon } from "assets/submenu//ico-crono.svg";
import { ReactComponent as VolcanoIcon } from "assets/submenu//ico-volcanos.svg";
import { ReactComponent as WildsIcon } from "assets/submenu//ico-wilds.svg";
import { ReactComponent as CoinsIcon } from "assets/submenu//ico-coins.svg";
import { ReactComponent as BoostersIcon } from "assets/submenu//ico-boosters.svg";
import { ReactComponent as DealsIcon } from "assets/submenu/ico-daily.svg";

export const configData = [
	{
		icon: FeaturedIcon,
		name: "featured"
	},
	{
		icon: LimitedDealsIcon,
		name: "limited_deals"
	},
	{
		icon: LimitedDealsIcon,
		name: "waterfall_sale"
	},
	{
		icon: LimitedDealsIcon,
		name: "endless_offer"
	},
	{
		icon: VolcanoIcon,
		name: "volcanos"
	},
	{
		icon: WildsIcon,
		name: "wilds"
	},
	{
		icon: CoinsIcon,
		name: "coins"
	},
	{
		icon: BoostersIcon,
		name: "boosters"
	},
	{
		icon: DealsIcon,
		name: "daily_deals"
	}
];

export const parseData = (data: string) => {
	try {
		return JSON.parse(data);
	} catch {
		return data;
	}
};
