/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography, DialogActions, useMediaQuery } from "@mui/material";
import ThemeButton from "components/Buttons/ThemeButton";
import { useGoogleAnalyticsContext } from "contexts";
import { useAppDispatch, useImagePath } from "hooks";
import { onDIPtrigger } from "redux/slices";
import CloseIconButton from "../lib/CloseIconButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import {
	CustomDialog,
	CustomDialogContent,
	CustomDialogContentText,
	CustomDialogTitle
} from "./styles";

const DialogLoginRewardConfirmationV2 = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onCloseClick: () => void;
}) => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { getImage } = useImagePath("local");
	const backgroundImage = getImage("/spec_modal_rewards/welcome_bg_v3.png");
	const isLandscape = useMediaQuery("(max-height:590px)");
	const scale = useCalculateScale(
		isLandscape ? 730 : 410,
		isLandscape ? 330 : 470
	);

	return (
		<CustomDialog
			scale={scale}
			open={show}
			onClose={() => {
				onCloseClick();
				dispatch(onDIPtrigger("firstTimeLogin"));
			}}
		>
			<CustomDialogTitle
				sx={{
					backgroundImage: `url(${backgroundImage})`
				}}
			>
				<CloseIconButton
					onClose={() => {
						onCloseClick();
						reportEvent("dialog_x_close", {
							source: "received_first_login_reward_confirmation_dialog"
						});
					}}
				/>
			</CustomDialogTitle>
			<div
				style={{
					width: isLandscape ? "50%" : "auto"
				}}
			>
				<CustomDialogContent>
					<CustomDialogContentText
					// sx={{ padding: isLandscape ? "3rem 0rem" : ".75rem 0rem" }}
					>
						<Typography
							variant="h6"
							component="strong"
							sx={{
								fontFamily: "Baloo",
								fontWeight: "400",
								margin: ".15rem 0 .5rem",
								display: "block",
								textAlign: "center",
								fontSize: "23px",
								lineHeight: 1,
								color: "#333"
							}}
						>
							{"Welcome to Tiki Solitaire TriPeaks Website!"}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								//paddingLeft: "3rem",
								//paddingRight: "3rem",
								color: "#333",
								display: "block",
								fontSize: "14px",
								textAlign: "center",
								lineHeight: "16px"
							}}
						>
							Enjoy unique deals and free bonuses that assure you’ve chosen the
							best!
						</Typography>
					</CustomDialogContentText>
				</CustomDialogContent>
				<DialogActions
					sx={{ justifyContent: "center", flexDirection: "column" }}
				>
					<ThemeButton
						onClick={() => {
							onCloseClick();
							dispatch(onDIPtrigger("firstTimeLogin"));
							reportEvent("continue_shopping_click", {
								source: "received_first_login_reward_confirmation_dialog"
							});
						}}
						sx={{
							paddingTop: ".75rem",
							paddingBottom: ".75rem",
							marginBottom: 1,
							width: "calc(100% - 6rem)",
							marginLeft: 2,
							marginRight: 2
						}}
					>
						Keep Browsing!
					</ThemeButton>
				</DialogActions>
			</div>
		</CustomDialog>
	);
};

export { DialogLoginRewardConfirmationV2 };
