import BackToTopButton from "components/PageContent/lib/PageBackToTopButton";
import {
	PageContentContainer,
	PageContentInner
} from "components/PageContent/styled";
import { images } from "helpers";
import { useImagePath } from "hooks/useImagePath";
import { createRef, useEffect, useState } from "react";
import PageHero from "./lib/Hero";
import Section from "./lib/Section";
import { getSections } from "./sections";
import { SectionContent, landingPageContainerSxProps } from "./styled";

export interface ILandingPageSection {
	image: string;
	title: string;
	content: string;
	linkHref: string;
	analytics: string;
	linkText: string;
	linkIsExternal: boolean;
	featureFlag?: string;
	openExternalSameTab?: boolean;
	isEnabled: boolean;
}

const LandingPage = () => {
	const [contentSections] = useState<ILandingPageSection[]>(
		getSections().filter((section) => section.isEnabled)
	);
	const sectionContentRef = createRef<HTMLDivElement>();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { getImage } = useImagePath("local");

	const pageHeaderBackground =
		images.spec_landing_page__page_header_background__template.src;
	const pageContentBackground =
		images.spec_landing_page__page_content_background.src;

	return (
		<PageContentContainer
			className="page-container animate__animated animate__fadeIn"
			sx={{
				...landingPageContainerSxProps,
				backgroundImage: {
					xs: `url(${getImage(pageHeaderBackground, "small")}), url(${getImage(
						pageContentBackground
					)})`,
					sm: `url(${getImage(pageHeaderBackground, "large")}), url(${getImage(
						pageContentBackground
					)})`
				}
			}}
		>
			<PageContentInner
				className="page-content"
				sx={{ maxWidth: { lg: "80%" } }}
			>
				<PageHero sectionContentRef={sectionContentRef} />
				<SectionContent className="section-content" ref={sectionContentRef}>
					{contentSections.map((section, index): JSX.Element => {
						const isReversed = index % 2 === 0;
						return (
							<Section
								key={section.title}
								section={{ ...section, image: getImage(section.image) }}
								isReversed={isReversed}
							/>
						);
					})}
				</SectionContent>
				<BackToTopButton source="landing_page" />
			</PageContentInner>
		</PageContentContainer>
	);
};

export default LandingPage;
