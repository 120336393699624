import { AxiosRequestHeaders } from "axios";
import { LOCAL_STORAGE_TIME_ZONE } from "debug/components/SetLocalTime";
import { getLocalDateEtcGMTFormat } from "helpers";
import { AuthToken } from "interfaces";
import {analyticsTracker} from "../../contexts/ScopelyIdentityProvider/config";

export const convertToAxiosHeaders = (
	pHeaders: Headers
): AxiosRequestHeaders => {
	const axiosHeaders: AxiosRequestHeaders = {};

	pHeaders.forEach((value, key) => {
		axiosHeaders[key] = value;
	});

	return axiosHeaders;
};
export const headersConfig = (pHeaders?: Headers): Headers => {
	let headers = new Headers();
	if (pHeaders) {
		headers = pHeaders;
	}

	headers.set("Content-Type", "application/json");

	const authToken = localStorage.getItem(
		"store.solitairetripeaks.com:authToken"
	);
	const token = authToken ? JSON.parse(authToken) : null;
	if (token) {
		headers.set("authorization", `Bearer ${(token as AuthToken).value}`);
	}
	const namespaceOverride = localStorage.getItem(
		"store.solitairetripeaks.com:namespaceOverride"
	);
	const parsedNameSpaceOverride = namespaceOverride
		? JSON.parse(namespaceOverride)
		: null;

	if (parsedNameSpaceOverride) {
		headers.set("X-GSN-WEBSTORE-NAMESPACE-OVERRIDE", parsedNameSpaceOverride);
	}

	const syntheticId = localStorage.getItem(
		"store.solitairetripeaks.com:syntheticId"
	);
	const parsedSyntheticId = syntheticId ? JSON.parse(syntheticId) : null;
	if (parsedSyntheticId) {
		headers.set("X-GSN-WEBSTORE-SYNTHETIC-ID", parsedSyntheticId);
	}
	const deviceToken = analyticsTracker.getDeviceToken();
	if (deviceToken) {
		headers.set("X-TITAN-DEVICE-TOKEN", deviceToken);
	}

	const localTimeZoneOverride = localStorage.getItem(LOCAL_STORAGE_TIME_ZONE);
	headers.set("X-GSN-WEBSTORE-REFERER-TIMEZONE", localTimeZoneOverride ? localTimeZoneOverride : getLocalDateEtcGMTFormat());

	return headers;
};
