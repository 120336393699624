import { Button, styled } from "@mui/material";
import buttonGlow from "assets/buttons/button-glow.png";

export const StyledButton = styled(Button)`
	background-color: unset;
	border: unset;
	box-shadow: unset;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 7px 16px;
	gap: 5px;
	height: 50px;
	background: linear-gradient(180deg, #74eb38 0%, #5bb52d 100%);
	border: 1px solid #66cc32;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.45);
	border-radius: 16px;
	text-transform: capitalize;
	font-weight: 700;
	font-size: 16px;
	line-height: 28px;
	color: #ffffff;
	transition: all ease 0.3s;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

	&.isCurrentOfferSet {
		pointer-events: none;
	}
	${(props) => props.theme.breakpoints.down("sm")} {
		.sm-and-down-hide {
			display: none;
		}
	}

	&:hover {
		background: linear-gradient(180deg, #73ff2c 0%, #5bb52d 100%),
			linear-gradient(0deg, #82dc54, #82dc54);
	}

	&:disabled {
		text-shadow: none;
		color: #7b7b7b;
		border-radius: 16px;
		border: 1px solid #bcbcbc;
		background: linear-gradient(180deg, #c5c5c5 0%, #9d9d9d 100%);
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.45);
	}
`;

export const StyledButtonContainedSecondary = styled(StyledButton)`
	background: linear-gradient(180deg, #4088b7 0%, #1c6595 100%);
	border: 1px solid #52a5dc;
	&:hover {
		background: linear-gradient(180deg, #36a1e8 0%, #2070a5 100%),
			linear-gradient(0deg, #4ab8ff, #4ab8ff);
	}
`;

export const StyledButtonContainedTertiary = styled(StyledButton)`
	border: 1px solid #ffe37e;
	background: url(${buttonGlow}),
		linear-gradient(180deg, #ffc700 0%, #ff8a00 100%);
	background-repeat: no-repeat;
	background-blend-mode: luminosity, normal;
	box-shadow: 0px 0px 8px 0px #ffe500 inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.45);
	background-size: 130%;
	background-position: center;

	&:hover {
		background: url(${buttonGlow}),
			linear-gradient(180deg, #ffc700 0%, #ffc700 100%);
		background-size: 130%;
		background-position: center;
	}
`;

export const StyledButtonOutlined = styled(StyledButton)`
	color: inherit;
	border: 2px solid inherit;
	background: none;
	border-color: inherit;
	border-width: 2px;

	&:hover {
		background: none;
		border: 2px solid inherit;
		border-color: inherit;
		border-width: 3px;
	}
`;

export const StyledButtonText = styled(StyledButton)`
	background: none;
	border: none;
	box-shadow: none;

	&:hover {
		background: none;
		opacity: 0.8;
	}
`;
