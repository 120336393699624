import classNames from "classnames";
import { closeStickyTag } from "redux/slices";
import { HighlightOffOutlined } from "@mui/icons-material";
import { Sticky, Tiki, Body, Button, Text } from "./styles";
import { useAppDispatch, useImagePath, useWindowSize } from "hooks";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts/EnvProvider";
import {
	useGetStickyTag,
	useRefreshStickyStorage,
	useSetActiveSaleTag
} from "./hooks";

interface StickySaleTagProps {}

const StickySaleTag: React.FC<StickySaleTagProps> = () => {
	useSetActiveSaleTag();
	useRefreshStickyStorage();
	const { width } = useWindowSize();
	const dispatch = useAppDispatch();
	const stickyTag = useGetStickyTag();
	const { getImage } = useImagePath("local");
	const { isFeatureFlagEnabled } = useEnvContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const tikiUrl = getImage("/spec_sticky_tag/tiki-sticky.png");
	const backgroundMobileUrl = getImage(
		"/spec_sticky_tag/line-brush-mobile.png"
	);
	const backgroundDesktopUrl = getImage(
		"/spec_sticky_tag/line-brush-desktop.png"
	);
	const isMobile = width <= 720;

	return stickyTag && isFeatureFlagEnabled(FeaturesFlags.STICKY_TAG) ? (
		<Sticky
			data-image={isMobile ? backgroundMobileUrl : backgroundDesktopUrl}
			className={classNames(isMobile && "mobile")}
		>
			<Tiki className="tiki" src={tikiUrl} />
			<Body className="body">
				<Text className="text">{stickyTag.stickyTagText}</Text>
			</Body>
			<Button
				className="button"
				onClick={() => {
					dispatch(closeStickyTag(stickyTag.id));

					const { startTime, endTime, stickyTagText } = stickyTag;
					reportEvent("hide_sticky_tag", {
						source: "sticky_tag",
						sale_start_time: startTime,
						sale_end_time: endTime,
						stickyTagText: stickyTagText
					});
				}}
			>
				<HighlightOffOutlined
					sx={{
						strokeWidth: 0.005,
						width: 30,
						height: 30,
						backgroundColor: "rgba(0,0,0, .3)",
						borderRadius: "100%",
						color: "#fff",
						"&:hover": {
							backgroundColor: "rgba(0,0,0, .6)"
						}
					}}
					fontSize="large"
				/>
			</Button>
		</Sticky>
	) : null;
};

export default StickySaleTag;
