/* eslint-disable @typescript-eslint/no-unused-vars */
import { GiftsGaloreCard } from "./lib";
import { setProviderValues } from "./helpers";
import { useMediaQuery } from "@mui/material";
import { GiftsGaloreTracker } from "interfaces";
import { useAppDispatch, useAppSelector } from "hooks";
import { useInitialActions } from "./useInitialActions";
//import { IconContainer, SectionTitle } from "../styles";
import { postUserTrackerValue } from "redux/slices/common";
import { Fragment, createContext, useContext, useEffect } from "react";
import { onOpenPopUp, selectGiftsGaloreState, selectPopUpManagerState } from "redux/slices";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { ReactComponent as ConffetiHornIcon } from "assets/club-gifts-galore/svg/ConffetiHorn.svg";
import { SectionIconContainer, SectionTitle } from "styles/general_styles";

const SS_CGG_FTUE = "store.solitairetripeaks.com:cggFtue";

export type IContext = {
	eventIsCompleted: boolean;
	tracker: GiftsGaloreTracker;
	moreClubMembersNeeded: boolean;
};

const GiftsGaloreContext = createContext<IContext>({
	eventIsCompleted: false,
	moreClubMembersNeeded: false,
	tracker: {} as GiftsGaloreTracker
});

export const useGiftGaloreTracker = (): IContext =>
	useContext(GiftsGaloreContext);

const ClubEventSection = () => {
	useInitialActions();
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { userTracker, syncUserTracker } = useWebStoreContext();
	const { tracker } = useAppSelector(selectGiftsGaloreState);
	const { variant } = useAppSelector(selectPopUpManagerState);
	const isMobile = useMediaQuery("(max-width: 550px)");
	const hasDisplayedClubGiftGaloreFtue =
		userTracker?.metadata?.hasDisplayedClubGiftGaloreFtue;

	useEffect(() => {
		const st_cggFtue = sessionStorage.getItem(SS_CGG_FTUE);
		if (!userTracker || st_cggFtue || !tracker || !!variant) {
			return;
		}
		if (hasDisplayedClubGiftGaloreFtue) {
			return;
		}
		dispatch(onOpenPopUp("cgg-more-info"));
		dispatch(
			postUserTrackerValue({
				key: "hasDisplayedClubGiftGaloreFtue",
				value: "true"
			})
		);

		syncUserTracker();
		sessionStorage.setItem(SS_CGG_FTUE, JSON.stringify("true"));

		reportEvent("three_panel_ftue_initial_pop_up", {
			source: "club_gifts_galore"
		});
	}, [userTracker, tracker]);

	return tracker ? (
		<Fragment>
			<SectionTitle>
				<SectionIconContainer>
					<ConffetiHornIcon width="21px" height="40px" />
				</SectionIconContainer>
				<div>CLUB EVENT</div>
			</SectionTitle>

			<GiftsGaloreContext.Provider value={setProviderValues(tracker)}>
				<GiftsGaloreCard />
			</GiftsGaloreContext.Provider>
		</Fragment>
	) : (
		<Fragment />
	);
};

export default ClubEventSection;
