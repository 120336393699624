import { styled, SxProps } from "@mui/material";
import backToTopIconPath from "../../../assets/BackToTop.svg";
import { useGoogleAnalyticsContext } from "../../../contexts/GoogleAnalyticsProvider";
import { CSSProperties } from "react";

const Wrapper = styled("div")`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
	//position: absolute;
	left: 0;
	right: 0;
	bottom: 2rem;

	@media (min-width: 800px) {
		margin-top: 2rem;
	}

	button {
		background: none;
		border: none;
		width: 5rem;
		height: auto;
		cursor: pointer;

		&:active {
			margin-bottom: -2px;
		}
	}
`;

const BackToTopButton = ({
	source,
	style,
	sx = {}
}: {
	style?: CSSProperties;
	source: string;
	sx?: SxProps;
}): JSX.Element => {
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<Wrapper style={style} sx={sx}>
			<button
				className="back-to-top"
				onClick={() => {
					window.scrollTo({ top: 0, behavior: "smooth" });
					reportEvent("back_to_top_click", { source: source });
				}}
			>
				<img width={72} height={72} src={backToTopIconPath} alt="back-to-top" />
			</button>
		</Wrapper>
	);
};

export default BackToTopButton;
