import { styled } from "@mui/material";
import TimerDisplay from "./TimerDisplayV2";
import { TimerIcon } from "../../utils/svgIcons";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./styles.module.scss";
import { Moment } from "moment";
import classNames from "classnames";
import { CSSProperties } from "styled-components";
import { useState } from "react";

const TimerBox = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("featCardImg")]: {
		fontSize: "0.9em"
	}
}));

const CountdownV2 = ({
	text = "Ends in:",
	startTime,
	endTime,
	onEnd,
	status,
	icon,
	labelStyle,
	style,
	iconStyle,
	className,
	hideTimerDisplayOnEnd
}: {
	text?: string;
	startTime: Moment | null;
	endTime: string;
	icon?: JSX.Element;
	onEnd: () => void;
	status?: string;
	labelStyle?: CSSProperties;
	style?: CSSProperties;
	iconStyle?: CSSProperties;
	className?: string;
	hideTimerDisplayOnEnd?: boolean;
}) => {
	const [showTimerDisplay, setShowTimerDisplay] = useState<boolean>(true);
	return (
		<TimerBox
			className={classNames([
				styles.offerTimer,
				className && styles[className]
			])}
			style={style}
		>
			{icon || (
				<TimerIcon
					style={{
						width: "22px",
						height: "22px",
						fill: "white",
						paddingRight: "4px",
						marginLeft: "-3px",
						...iconStyle
					}}
				/>
			)}
			<span style={labelStyle} className="sm-and-down-hide">
				{text}
			</span>
			{showTimerDisplay && (
				<TimerDisplay
					style={labelStyle}
					startTime={startTime}
					endTime={endTime}
					status={status}
					onEnd={() => {
						onEnd();
						hideTimerDisplayOnEnd && setShowTimerDisplay(false);
					}}
				/>
			)}
		</TimerBox>
	);
};

export default CountdownV2;
