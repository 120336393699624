import { PoisBountyV2Response, Trackers } from "interfaces";
import { PoisBountyV2Slice } from "./types";

export const initialState: PoisBountyV2Slice = {
	variant: "intro",
	dataV2: {} as PoisBountyV2Response,
	trackers: {} as Trackers,
	rewards: [],
	rewardCollectedV2: undefined,
	claimingReward: false,
	claimingAllRewards: false,
	creatingNewCycle: false,
	blockAllButtons: false,
	isEventActiveV2: false,
	userLoyaltyPoints: 0,
	eventEnded: false,
	remaining24hour: false,
	responseSucceedWithNoTrackerV2: false
};