import { DefaultSvgProps } from ".";

export const Info = ({ size = 34 }: DefaultSvgProps) => (
	<svg width={size} height={size} viewBox="0 0 32 32" fill="none">
		<circle
			cx="16.0004"
			cy="15.9994"
			r="13.7143"
			fill="black"
			fillOpacity="0.3"
		/>
		<path
			d="M14.9002 22.867H17.3002V14.667H14.9002V22.867ZM16.0002 12.467C16.4002 12.467 16.728 12.3392 16.9835 12.0837C17.2391 11.8281 17.3669 11.5114 17.3669 11.1337C17.3669 10.7114 17.2391 10.3725 16.9835 10.117C16.728 9.86144 16.4002 9.73366 16.0002 9.73366C15.6002 9.73366 15.2724 9.86144 15.0169 10.117C14.7613 10.3725 14.6335 10.7114 14.6335 11.1337C14.6335 11.5114 14.7613 11.8281 15.0169 12.0837C15.2724 12.3392 15.6002 12.467 16.0002 12.467ZM16.0002 29.8003C14.0891 29.8003 12.3002 29.4392 10.6335 28.717C8.96686 27.9948 7.50575 27.0059 6.2502 25.7503C4.99464 24.4948 4.00575 23.0337 3.28353 21.367C2.56131 19.7003 2.2002 17.9114 2.2002 16.0003C2.2002 14.067 2.56131 12.267 3.28353 10.6003C4.00575 8.93366 4.98908 7.4781 6.23353 6.23366C7.47797 4.98921 8.93908 4.00033 10.6169 3.26699C12.2946 2.53366 14.0891 2.16699 16.0002 2.16699C17.9335 2.16699 19.7391 2.53366 21.4169 3.26699C23.0946 4.00033 24.5502 4.98366 25.7835 6.21699C27.0169 7.45033 28.0002 8.90588 28.7335 10.5837C29.4669 12.2614 29.8335 14.067 29.8335 16.0003C29.8335 17.9114 29.4669 19.7059 28.7335 21.3837C28.0002 23.0614 27.0113 24.5225 25.7669 25.767C24.5224 27.0114 23.0669 27.9948 21.4002 28.717C19.7335 29.4392 17.9335 29.8003 16.0002 29.8003ZM16.0335 27.167C19.1224 27.167 21.7502 26.0781 23.9169 23.9003C26.0835 21.7225 27.1669 19.0781 27.1669 15.967C27.1669 12.8781 26.0835 10.2503 23.9169 8.08366C21.7502 5.91699 19.1113 4.83366 16.0002 4.83366C12.9113 4.83366 10.278 5.91699 8.10019 8.08366C5.92242 10.2503 4.83353 12.8892 4.83353 16.0003C4.83353 19.0892 5.92242 21.7225 8.10019 23.9003C10.278 26.0781 12.9224 27.167 16.0335 27.167Z"
			fill="white"
		/>
	</svg>
);
