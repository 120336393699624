import styled, { css } from "styled-components";
import { Box, Typography } from "@mui/material";

interface ICardContainer {
	image?: string;
	filter?: number;
}

interface IRewardItem {
	width?: string;
	filter?: number;
}

interface IItems {
	isOffer?: boolean;
}

interface IDescription {
	variant?: string;
}

export const Container = styled.div<ICardContainer>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-image: url(${(props) => props.image});
	filter: grayscale(0);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	max-width: 270px;
	max-height: 270px;

	@media(max-width: 733px) {
		width: 40vw;
		height: 40vw;
	}
	@media(min-width: 734px) {
		width: 20vw;
		height: 20vw;
	}
	/* @media(orientation: landscape) {
		width: 20vw;
		height: 20vw;
		max-width: 270px;
		max-height: 270px;

		@media(max-width: 733px) {
			width: 40vw;
			height: 40vw;
		}
	}

	@media (orientation: portrait) {
		width: 20vw;
		height: 20vw;

		@media(max-width: 733px) {
			width: 40vw;
			height: 40vw;
		}
	} */
`;

export const DescriptionContainer = styled.div`
	height: 28.42%;
	//height: 81px;
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	flex-direction: column;

	@media (orientation: portrait) {
	}
`;

export const Percentage = styled.div`
	color: #fff;
	text-align: center;
	text-shadow: -1px -1px 0 #593113, 1px -1px 0 #593113, -1px 1px 0 #593113,
		2px 2px 0 #593113;
	font-weight: 400;
	font-family: Baloo;
	font-size: 22px;
	font-style: normal;
	z-index: 500;
	line-height: 100%;
	height: 30%;
	width: 80px;

	@media(max-width: 733px) {
		font-size: 4vw;
	}
	@media(min-width: 734px) {
		font-size: 2vw;
	}
	@media (min-width: 1536px) {
		font-size: 25px;
	}

	/* @media (orientation: landscape) {
		font-size: 2vw;
		@media (min-width: 1536px) {
			font-size: 25px;
		}
		@media (max-width: 667px) {
			font-size: 4vw;
		}
	}
	@media (orientation: portrait) {
		font-size: 2vw;

		@media(max-width: 733px) {
			font-size: 4vw;
		}
	} */
`;

export const Description = styled.div<IDescription>`
	color: #fff;
	text-shadow: -1px -1px 0 #593113, 1px -1px 0 #593113, -1px 1px 0 #593113,
		2px 2px 0 #593113;
	font-weight: 400;
	font-family: Baloo;
	font-style: normal;
	z-index: 500;
	font-size: 16px;
	line-height: 100%;
	height: 32%;
	display: flex;
	align-items: center;
	justify-content: center;

	${(props) => {
		switch (props.variant) {
			case "item":
				return css`
					font-size: 12px;
					height: 25%;
				`;
			case "freeItem":
				return css`
					font-size: 30px;
					height: 40%;
				`;
			default:
				break;
		}
	}}

	@media (min-width: 930px) {
		height: 36%;
		//font-size: 20px;
		${(props) => {
		switch (props.variant) {
			case "item":
				return css`
						font-size: 16px;
						height: 25%;
					`;
			case "freeItem":
				return css`
						font-size: 34px;
					`;
			default:
				break;
		}
	}}
	}
	@media(max-width: 733px) {
		font-size: 3vw;
	}
	@media(min-width: 734px) {
		font-size: 1.5vw;
	}
	@media (min-width: 1536px) {
		font-size: 20px;
	}
	/* @media (orientation: landscape) {
		font-size: 1.5vw;
		@media (min-width: 1536px) {
			font-size: 20px;
		}
		@media (max-width: 667px) {
			font-size: 3vw;
		}
	}
	@media (orientation: portrait) {
		font-size: 1.5vw;
		//height: auto;
		@media(max-width: 733px) {
			font-size: 3vw;
		}
	} */
`;

export const Items = styled.div<IItems>`
	justify-content: center;
	align-items: center;
	display: flex;
	//flex-wrap: wrap;
	z-index: 500;
	//flex-basis: 60%;
	//height: 50.79%;
	height: ${(props) => (props.isOffer ? "52.79%" : "61%")};
`;

export const RewardItem = styled.div<IRewardItem>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${(props) => props.width};
	box-sizing: border-box;
	padding: 1px;
	filter: grayscale(${(props) => props.filter});
	//gap: 0.4rem;
`;

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 12px;

	@media (min-width: 930px) {
		font-size: 16px;
	}
	@media (min-width: 1536px) {
		font-size: 20px;
	}
`;

export const ItemImage = styled.img<{ loyaltyBoxExtraMessage?: boolean }>(({ loyaltyBoxExtraMessage }) => `
	width: ${loyaltyBoxExtraMessage ? "45px" : "55px"};
	height: ${loyaltyBoxExtraMessage ? "45px" : "55px"};
	object-fit: contain;
	justify-self: center;
	align-self: center;
	max-width: 55px;
	max-height: 55px;

	@media(max-width: 733px) {
		width: ${loyaltyBoxExtraMessage ? "6.5vw" : "9vw"};
		height: ${loyaltyBoxExtraMessage ? "6.5vw" : "9vw"};
	}
	@media(min-width: 734px) {
		width: ${loyaltyBoxExtraMessage ? "3vw" : "4vw"};
		height: ${loyaltyBoxExtraMessage ? "3vw" : "4vw"};
	}

	/* @media(orientation: landscape) {
		width: ${loyaltyBoxExtraMessage ? "3vw" : "4vw"};
		height: ${loyaltyBoxExtraMessage ? "3vw" : "4vw"};
		max-width: 55px;
		max-height: 55px;

		@media(max-width: 733px) {
			width: ${loyaltyBoxExtraMessage ? "6.5vw" : "9vw"};
			height: ${loyaltyBoxExtraMessage ? "6.5vw" : "9vw"};
		}
	}

	@media (orientation: portrait) {
		width: ${loyaltyBoxExtraMessage ? "3vw" : "4vw"};
		height: ${loyaltyBoxExtraMessage ? "3vw" : "4vw"};

		@media(max-width: 733px) {
			width: ${loyaltyBoxExtraMessage ? "6.5vw" : "9vw"};
			height: ${loyaltyBoxExtraMessage ? "6.5vw" : "9vw"};
		}
	} */
`);

export const LockIcon = styled.img`
	position: absolute;
	top: 30%;
	right: -12px;
	height: 75%;
	z-index: 1000;

	@media (min-width: 360px) {
		right: -7%;
	}
	@media (min-width: 930px) {
		right: -9%;
	}
`;

export const InfoBox = styled(Box)<{ loyaltyBoxExtraMessage?: boolean }>(({ loyaltyBoxExtraMessage }) => `
	background-color: rgb(0 0 0 / 30%);
	border-radius: 84px;
	color: white;
	font-weight: 220;
	opacity: 0.9;
	padding: 0.3rem 0.7rem;
	position: absolute;
	background-repeat: "no-repeat";

	@media(orientation: portrait) {
		@media (max-width: 600px) {
			padding: ${loyaltyBoxExtraMessage ? "0.1rem 0.7rem" : "0.3rem 0.7rem"};
		}
	}
`);

export const LoyalBox = styled(InfoBox)<{ loyaltyBoxExtraMessage?: boolean }>(({ loyaltyBoxExtraMessage }) => `
	background-position: 90% center;
	color: white;
	padding-right: "2rem";
	background-color: "white";
	white-space: nowrap;
	//font-size: ${loyaltyBoxExtraMessage ? "0.85vw" : "1vw"};

	> div {
		align-items: center;
		justify-content: center;
		display: flex;
		gap: 0.2rem;
	}
`);

export const LoyalBoxWrap = styled(Box)<{ loyaltyBoxExtraMessage?: boolean }>(({ loyaltyBoxExtraMessage }) => `
	bottom: ${loyaltyBoxExtraMessage ? "12%" : "9%"};
	display: flex;
	font-size: 0.5em;
	justify-content: center;
	position: relative;
`);
