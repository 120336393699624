import { ClubName as ClubNameStyled } from "./styles";

interface ClubNameProps {
	name: string;
	className: string;
}

export const ClubName: React.FC<ClubNameProps> = ({ className, name }) => {
	const truncate = name.length > 15;
	name = truncate ? `${name.slice(0, 12)}...` : name;
	return (
		<ClubNameStyled className={className}>
			<span>Club:</span> {name}
		</ClubNameStyled>
	);
};
