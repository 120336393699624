/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEqualWith } from "lodash";
import { useAppSelector } from "hooks";
import SingleItems from "./lib/SingleItems";
import { useWebNewsContext, useWebStoreContext } from "contexts";
import FeaturedDeals from "./lib/FeaturedDeals";
import { PageContent } from "components/PageContent";
import { IShopSection } from "contexts/WebStoreProvider/types";
import { LimitedDealsSection } from "./lib/LimitedDealsSection";
import { selectShopSectionsState } from "redux/slices/shop-sections";
import PageTopNav, { ISectionMenuItem } from "components/PageTopNav";
import { useState, createRef, useEffect } from "react";
import { ShopContext } from ".";
//import { VersionInfo } from "./styles";
import { FAQ } from "./lib/FAQ";
import HeaderBanner from "./lib/HeaderBanner";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { WsDialogs as IDialogVariants } from "interfaces/dialog/variant";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import PageTopNavV2 from "components/PageTopNavV2";
import BackToTopButton from "components/PageContent/lib/PageBackToTopButton";

// eslint-disable-next-line
//export const ShopContext = createContext({} as any);

const StoreFrontV2 = () => {
	const { resetNewsState } = useWebNewsContext();
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const [sectionMenuData, setSectionMenuData] = useState<ISectionMenuItem[]>(
		[]
	);
	const { authToken } = useWebStoreContext();
	const [mainShopSections, setShopSections] = useState<IShopSection[]>([]);
	const { variant, openDialog, dialogState, closeDialog } =
		useDialogModalRedux();
	const isMegaMenuOpen = useSelector(
		(state: RootState) => state.megaMenu.isOpen
	);
	useEffect(() => {
		window?.scrollTo(0, 0);
		resetNewsState();
	}, []);

	//Prevent rerendering when processing storeRaw
	useEffect(() => {
		if (!shopSections?.mainShopSections) {
			return;
		}
		if (isEqualWith(shopSections?.mainShopSections, mainShopSections)) {
			return;
		}
		setShopSections(shopSections?.mainShopSections);
	}, [shopSections?.mainShopSections]);

	useEffect(() => {
		setSectionMenuData(
			mainShopSections.map((data) => {
				const {
					name,
					title: text,
					iconImageUrl: icon,
					cardBackgroundImageUrl,
					startTime,
					endTime
				} = data;
				const newRef = createRef();

				return {
					name,
					text,
					icon,
					sectionRef: newRef,
					analytics_source: "store",
					cardBackgroundImageUrl,
					startTime,
					endTime
				};
			})
		);
	}, [mainShopSections]);

	useEffect(() => {
		if (!authToken?.value) {
			openDialog(IDialogVariants.LOGIN_TO_CONNECT, { source: "shop" });
		} else if (variant === IDialogVariants.LOGIN_TO_CONNECT) {
			closeDialog();
		}
		return () => {
			if (
				!authToken?.value &&
				(variant === IDialogVariants.LOGIN_TO_CONNECT || variant === undefined)
			) {
				closeDialog();
			}
		};
	}, [authToken?.value, variant]);

	return (
		<div
			style={{
				position: "relative",
				filter: authToken?.value ? "none" : "blur(10px)"
				//pointerEvents: authToken?.value ? "auto" : "none"
			}}
		>
			<ShopContext.Provider value={{ sectionMenuData }}>
				<HeaderBanner />
				<PageTopNavV2
					sectionMenuData={sectionMenuData}
					hasSectionBanner={true}
				/>

				<PageContent
					source={"store_page"}
					sxContainer={{
						paddingBottom: "0 !important"
					}}
				>
					<FeaturedDeals />
					<LimitedDealsSection />
					<SingleItems />
				</PageContent>
				<PageContent
					source={"store_page"}
					sxContent={{
						minWidth: "100%"
					}}
					enableBackToTop={true}
				>
					<FAQ />
				</PageContent>
			</ShopContext.Provider>
		</div>
	);
};

export default StoreFrontV2;
