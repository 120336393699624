import styled from "styled-components";

export const Card = styled.div<{ background: string; isConnected?: boolean }>`
	display: flex;
	justify-content: flex-end;
	margin: 10px;
	position: relative;
	align-items: center;
	flex-direction: column;
	background-image: url(${(props) => props["background"]});
	background-repeat: no-repeat;
	background-position: center bottom;
	border-radius: 24px;
	background-size: cover;
	height: ${(props) => (props.isConnected ? "620px" : "550px")};
	padding: 1rem;

	@media (min-width: 650px) {
		padding: 0;
		background-size: ${(props) => (props.isConnected ? "unset" : "cover")};
		justify-content: ${(props) => (props.isConnected ? "center" : "unset")};
		height: 500px;
		max-width: 1280px;
	}

	@media (min-width: 650px) and (max-height: 430px) {
		justify-content: ${(props) => (props.isConnected ? "end" : "unset")};
		height: 299px;
	}
`;

export const Title = styled.img<{
	isStreakBroken?: boolean;
	isConnected?: boolean;
}>`
	position: absolute;
	top: 4rem;
	left: 50%;
	transform: translateX(-50%);
	@media (min-width: 650px) {
		top: unset;
		left: unset;
		transform: unset;
		position: relative;
		width: ${(props) =>
			props.isConnected
				? props.isStreakBroken
					? "clamp(500px, 60vw, 750px)"
					: "clamp(400px, 40vw, 550px)"
				: "clamp(450px, 50vw, 550px)"};
	}
	@media (max-width: 932px) and (max-height: 430px) {
		width: ${(props) =>
			props.isConnected
				? props.isStreakBroken
					? "clamp(430px, 53vw, 750px)"
					: "clamp(280px, 38vw, 550px)"
				: "clamp(450px, 50vw, 550px)"};
		max-width: 450px;
	}
	@media (max-width: 375px) {
		width: 90%;
	}
`;

export const Grid = styled.div`
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	//gap: 1rem;

	@media (min-width: 650px) {
		flex-direction: row;
		gap: 0rem;
		justify-content: center;
		align-items: center;
	}

	@media (min-width: 650px) and (max-height: 430px) {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
`;
