import styled from "styled-components";

export const FooterContainer = styled("footer")`
	position: relative;
	min-height: 20rem;
	width: 100vw;

	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;

	background-color: #00789e;
	background: linear-gradient(45deg, #00789e 22.73%, #00d7ca 98.32%);
`;

export const Footer = styled("div")`
	display: flex;
	justify-content: center;
	box-sizing: border-box;

	.MuiDivider-root {
		border-color: rgba(255 255 255 / 30%);
	}
	.MuiListItem-root,
	.MuiListItemButton-root {
		//padding-left: 0;
	}
	.MuiListItemButton-root {
		display: flex;
		align-items: center;
	}
	.MuiListItemButton-root .icon {
		margin-right: 0.5rem;
	}
`;
export const FooterContent = styled("div")`
	box-sizing: border-box;
	padding: 40px 12px 0;
	width: 100%;
	max-width: 1250px;

	@media (min-width: 600px) {
		padding: 52px 18px 0;
	}
`;

export const CookieButton = styled("div")`
	background: transparent;
	color: white !important;
	border: none !important;
	width: 100%;

	:hover {
		background: rgba(0, 0, 0, 0.04) !important;
	}
`;

export const CategoriesContainer = styled("div")`
	display: flex;
	flex-wrap: wrap;
	//justify-content: center;
	align-items: flex-start;
	//justify-content: space-around;
	width: 100%;
`;

// export const Category = styled("div")`
// 	//flex-basis: 50%;
// 	min-width: calc(100% / 3);
// 	box-sizing: border-box;
// 	padding-left: 15px;
// `;

export const CategoryTitle = styled("div")`
	color: #fff;
	font-family: Baloo;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	padding: 8px 16px;

	@media (min-width: 600px) {
		font-size: 26px;
	}
`;

// export const LogoContainer = styled("div")`
// 	width: 100%;
// 	display: flex;
// 	justify-content: center;
// `;

export const LogoImage = styled("img")`
	max-height: 71px;
	padding: 16px;

	@media (min-width: 900px) {
		max-height: 90px;
	}
`;
