import { MouseEventHandler, ReactElement } from "react";
import { StyledChip } from "../styled";

interface GenericPointBalanceProps {
	icon: ReactElement<string>;
	points: number | string;
	onClick?: MouseEventHandler<HTMLDivElement>;
}

export const GenericPointBalance = ({ icon, points, onClick }: GenericPointBalanceProps) => {

	return (
		<StyledChip
			icon={icon}
			label={points.toLocaleString("en-US")}
			onClick={onClick}
		/>
	);
};
