import service from "services/api";
import { IClubGiftResponse } from "interfaces";

export const getFreeGift = async () => {
	const url = "/rest/club/freeGift";

	const response = await service.Get<IClubGiftResponse>(url);

	return response.clubFreeGift;
};

export const unlockFreeGift = async () => {
	const url = "/rest/club/freeGift";

	const response = await service.Post<IClubGiftResponse>(url, null);

	return response.clubFreeGift;
};
