import { createContext } from "react";
import { LoginResult, LogoutResult } from "@scopely/web-sdk-scopelyid";

/* eslint-disable */
export enum ConnectionStatus {
	UNKNOWN = "unknown",
	CONNECTED = "connected"
}

export interface IScopelyIdentityContext {
	connectionStatus: ConnectionStatus;
	isConnected: boolean;
	startLogin(origPath: string): Promise<void>;
	startLogout(logoutActionType: string, origPath: string): Promise<void>;
	tryConcludeLogin(onSuccess?: () => void): Promise<LoginResult | null>;
	tryConcludeLogout(onSuccess?: () => void): Promise<LogoutResult | null>;
	setScopelyAccessToken(sidAccessToken: string | null): void;
	setScopelyIdentityToken(sidIdentityToken: string | null): void;
	sidAccessToken: string | null;
	sidIdentityToken: string | null;
	setRedirectTo: React.Dispatch<React.SetStateAction<string | undefined>>;
}
/* eslint-enable */

export const ScopelyIdentityContext = createContext<IScopelyIdentityContext>({
	connectionStatus: ConnectionStatus.UNKNOWN,
	isConnected: false,
	// eslint-disable-next-line
	startLogin: async (origPath: string) => undefined,
	// eslint-disable-next-line
	startLogout: async (logoutActionType: string, origPath: string) => {
		// noop
	},
	tryConcludeLogin: async () => null,
	tryConcludeLogout: async () => null,
	// eslint-disable-next-line
	setScopelyAccessToken: (sidAccessToken: string) => {
		// noop
	},
	// eslint-disable-next-line
	setScopelyIdentityToken: (sidIdentityToken: string) => {
		// noop
	},
	sidAccessToken: null,
	sidIdentityToken: null,
	setRedirectTo: () => {}
});
