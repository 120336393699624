// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

const SetAuthTokenTtlDialog = ({
	onClick
}: {
	onClick: (auth: number) => void;
}) => {
	const [open, setOpen] = useState(false);
	const [fieldValue, setFieldValue] = useState(0);

	const handleClickOpen = () => {
		setFieldValue(0);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	/* eslint-disable-next-line */
	const onChange = (e: any) => {
		setFieldValue(e.target.value);
	};

	const handleSetAuthTokenTtl = async () => {
		setOpen(false);
		onClick(fieldValue);
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Set Auth Token
			</Button>
			<Dialog fullWidth open={open} onClose={handleClose}>
				<DialogTitle>Seconds to expire Auth Token</DialogTitle>
				<DialogContent>
					<TextField
						onChange={onChange}
						autoFocus
						margin="dense"
						id="name"
						defaultValue={fieldValue}
						label="Auth Token TTL"
						type="number"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSetAuthTokenTtl}>Set</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SetAuthTokenTtlDialog;
