import { StreakDay } from "interfaces";
import { useWebStoreContext } from "contexts";

export type StreakVariant = "collected" | "available" | "locked";

type StreakVariantHelpers = {
	variant: StreakVariant;
	isLocked: boolean;
	isAvailable: boolean;
	isCollected: boolean;
};

export const useStreakVariant = (
	streakDay: StreakDay
): StreakVariantHelpers => {
	const { nowTime } = useWebStoreContext();
	const { startOfDay, endOfDay } = streakDay;

	const variant =
		"collectedDate" in streakDay
			? "collected"
			: nowTime?.isAfter(startOfDay) && nowTime.isBefore(endOfDay)
			? "available"
			: "locked";

	// additional flags
	const isLocked = variant === "locked";
	const isAvailable = variant === "available";
	const isCollected = variant === "collected";

	return {
		variant,
		isLocked,
		isAvailable,
		isCollected
	};
};
