import { Box, Grid } from "@mui/material";
import { GridItems } from "./styles";

export const CardLayout = ({
	titleContent,
	itemsContent
}: {
	titleContent: JSX.Element;
	itemsContent: JSX.Element;
	itemsLength?: number;
}) => {
	return (
		<Box
			sx={{
				position: "relative"
			}}
		>
			<Grid container justifyContent={{ sm: "flex-end", md: "center" }} pr={{ sm: 2 }} spacing={{ sm: 2 }}>
				<Grid item xs={6} sm={5} md={4.25} lg={4}>
					{titleContent}
				</Grid>
				<GridItems item xs={6} sm={5} md={4.25} lg={4}>
					{itemsContent}
				</GridItems>
			</Grid>
		</Box>
	);
};
