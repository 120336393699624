import { SaleTag } from "interfaces";
import { useAppSelector } from "hooks";
import { PATH_SECTIONS } from "helpers";
import { useLocation } from "react-router-dom";
import { selectStickySaleTagState } from "redux/slices";
import { getStickiesFromLocalStorage } from "redux/slices/sticky-sale-tag/helper";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";

export const useGetStickyTag = (): SaleTag | undefined => {
	const { pathname } = useLocation();
	const { nowTime } = useWebStoreContext();
	const LS_STICKIES = getStickiesFromLocalStorage();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { saleTags, closedStickyTags } = useAppSelector(
		selectStickySaleTagState
	);

	// @ts-expect-error
	const notTimeReady = typeof nowTime._tzm === "undefined";
	if (notTimeReady) {
		return;
	}

	const stickyTags = saleTags.filter(
		(tag) => tag.megaMenuTagSection === PATH_SECTIONS[pathname]
	);
	const stickyTag = stickyTags.find((tag) => {
		if (nowTime?.isAfter(tag.startTime) && nowTime.isBefore(tag.endTime)) {
			return tag;
		}
	});
	const foundOnStorage = LS_STICKIES.find(
		(stickyStoraged) => stickyStoraged.id === stickyTag?.id
	);

	// prettier-ignore
	if (
		typeof stickyTag === "undefined" ||
		!stickyTag.showStickyTag ||
		closedStickyTags.includes(stickyTag.id) ||
		typeof foundOnStorage !== "undefined"
	) { return; }

	const { startTime, endTime, stickyTagText } = stickyTag;

	reportEvent("spawn_sticky_tag", {
		source: "sticky_tag",
		sale_start_time: startTime,
		sale_end_time: endTime,
		stickyTagText: stickyTagText
	});

	return stickyTag;
};
