import { CrownIcon } from "utils/svgIcons";
import { StyledStepTarget } from "./styles";
import { useStepContext } from "../Step/context";

export const Target = () => {
	const { target } = useStepContext();

	return (
		<StyledStepTarget className="target">
			<CrownIcon />
			<span>{target?.toLocaleString("en-US")}</span>
		</StyledStepTarget>
	);
};
