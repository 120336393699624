import { StreakDay } from "..";
import { getNextCollectionDay, useWeekStreak } from "../../hooks";
import { useDailyLoginStreak } from "../..";
import { getCount, getDisplay } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { ClaimButton, Container, Progress } from "./styles";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { collectDailyBonus, selectDailyLoginStreakState } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import useRewardsById from "hooks/useRewardsById";

interface DailyProgressProps {}

export const DailyProgress: React.FC<DailyProgressProps> = () => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { nowTime } = useWebStoreContext();
	const { tracker } = useDailyLoginStreak();
	const { streakDays } = tracker;
	const { isClaiming } = useAppSelector(selectDailyLoginStreakState);
	const { today } = useWeekStreak(streakDays);
	const { showErrorDialog } = useDialogModalRedux();
	const alreadyCollected = today && "collectedDate" in today;

	//gets rewardId of the current day to differentiate loyalty points when claiming
	const rewards = useRewardsById(today?.rewardId);

	const analyticsReport = () => {
		const timeRemaining = getCount(nowTime, today.endOfDay);
		const timeToDisplay: string = getDisplay(timeRemaining);
		const nextCollectionDay: number | undefined =
			getNextCollectionDay(tracker)?.dayIndex;
		const numberDayGiftClaimed: number = nextCollectionDay
			? nextCollectionDay + 1
			: 1;
		reportEvent("daily_gift_streak_claim", {
			number_day_gift_claimed: numberDayGiftClaimed,
			time_remaining: timeToDisplay,
			source: "daily_gift_streak"
		});
	};

	return (
		<Container>
			<Progress>
				{streakDays.map((streak, key) => (
					<StreakDay
						key={key}
						streakDay={streak}
						lastItem={key + 1 === streakDays.length}
					/>
				))}
			</Progress>
			<ClaimButton
				disabled={isClaiming || !!alreadyCollected}
				onClick={() => {
					dispatch(
						collectDailyBonus({ showErrorDialog, rewardId: rewards[0]?.id })
					);
					analyticsReport();
				}}
			>
				{isClaiming ? "Claiming..." : alreadyCollected ? "Collected" : "Claim"}
			</ClaimButton>
		</Container>
	);
};
