import homeIconPath, { ReactComponent as HomeIcon } from "../assets/Home.svg";

import crownIconPath, {
	ReactComponent as CrownIcon
} from "../assets/Crown.svg";

import crownWhiteIconPath, {
	ReactComponent as CrownWhiteIcon
} from "../assets/CrownWhite.svg";

import crownOutlinedIconPath, {
	ReactComponent as CrownOutlinedIcon
} from "../assets/pois_bounty_crown_icon.svg";

import shopIconPath, { ReactComponent as ShopIcon } from "../assets/Store.svg";

import clubShopIconPath, {
	ReactComponent as ClubShopIcon
} from "../assets/ClubShopIcon.svg";

import merchShopIconPath, {
	ReactComponent as MerchShopIcon
} from "../assets/MerchShopIcon.svg";

import poisBountyIconPath, {
	ReactComponent as PoisBountyIcon
} from "../assets/Pois_bounty_icon.svg";

import clubGiftIconPath, {
	ReactComponent as ClubGiftIcon
} from "../assets/ico-clubgift.svg";
import clubGiftIconWhitePath, {
	ReactComponent as ClubGiftIconWhite
} from "../assets/ico-clubgift-white.svg";

import dailyGiftIconPath, {
	ReactComponent as DailyGiftIcon
} from "../assets/ico-dailygift.svg";

import winnersIconPath, {
	ReactComponent as WinnersIcon
} from "../assets/Winners.svg";

import profileIconPath, {
	ReactComponent as ProfileIcon
} from "../assets/Profile.svg";

import defaultSignedInProfileIconPath, {
	ReactComponent as DefaultSignInProfileIcon
} from "../assets/DefaultProfile.svg";

import circleIconPath, {
	ReactComponent as CircleIcon
} from "../assets/Circle.svg";

import logoutIconPath, {
	ReactComponent as LogoutIcon
} from "../assets/ProfileLogOutIcon.svg";

import timerIconPath, {
	ReactComponent as TimerIcon
} from "../assets/Timer.svg";

import faqIconPath, { ReactComponent as FaqIcon } from "../assets/FaqIcon.svg";

import newsIconPath, {
	ReactComponent as NewsIcon
} from "../assets/NewsIcon.svg";

import forumIconPath, {
	ReactComponent as ForumIcon
} from "../assets/ForumIcon.svg";

import scopelyIconPath, {
	ReactComponent as ScopelyIcon
} from "../assets/ico-scopely.svg";

import facebookIconPath, {
	ReactComponent as FacebookIcon
} from "../assets/ico-face.svg";

import shareThisFacebookIconPath, {
	ReactComponent as ShareThisFacebookIcon
} from "../assets/ShareButton-Facebook.svg";

import shareThisTwitterIconPath, {
	ReactComponent as ShareThisTwitterIcon
} from "../assets/ShareButton-Twitter.svg";

import infoIconPath, {
	ReactComponent as InfoIcon
} from "../assets/InfoIcon.svg";

import arrowRightIconPath, {
	ReactComponent as ArrowRightIcon
} from "../assets/ArrowRight.svg";

import storeButtonAppleOutlinePath, {
	ReactComponent as StoreButtonAppleOutline
} from "../assets/StoreButtonAppleOutline.svg";

import storeButtonGoogleOutlinePath, {
	ReactComponent as StoreButtonGoogleOutline
} from "../assets/StoreButtonGoogleOutline.svg";

import appleStoreIconPath, {
	ReactComponent as AppleStoreIcon
} from "../assets/StoreBtnAppleOfficial.svg";

import googleStoreIconPath, {
	ReactComponent as GoogleStoreIcon
} from "../assets/StoreBtnGoogleOfficial.svg";

import scrollDownIconPath, {
	ReactComponent as ScrollDownIcon
} from "../assets/ico-scroll.svg";

import externalIconPath, {
	ReactComponent as ExternalIcon
} from "../assets/external.svg";

import dripOfferIconPath, {
	ReactComponent as DripOfferIcon
} from "../assets/drip-offer/drip-offer-icon.svg";

import redXIconPath, {
	ReactComponent as RedXIcon
} from "../assets/drip-offer/red-x.svg";

//////////////////////////
// section icons:
//////////////////////////

import boostersIconPath, {
	ReactComponent as BoostersIcon
} from "../assets/section_icons/boosters.svg";

import coinsIconPath, {
	ReactComponent as CoinsIcon
} from "../assets/section_icons/coins.svg";

import featuredIconPath, {
	ReactComponent as FeaturedIcon
} from "../assets/section_icons/featured.svg";

import volcanoIconPath, {
	ReactComponent as VolcanoIcon
} from "../assets/section_icons/volcano.svg";

import wildsIconPath, {
	ReactComponent as WildsIcon
} from "../assets/section_icons/wilds.svg";

import dailyIconPath, {
	ReactComponent as DailyIcon
} from "../assets/section_icons/daily.svg";

import limitedDealsIconPath, {
	ReactComponent as LimitedDealsIcon
} from "../assets/section_icons/limitedDeals.svg";

// section icons
const boosters = {
	url: boostersIconPath,
	svg: <BoostersIcon />
};
const coins = {
	url: coinsIconPath,
	svg: <CoinsIcon />
};
const featured = {
	url: featuredIconPath,
	svg: <FeaturedIcon />
};
const volcanos = {
	url: volcanoIconPath,
	svg: <VolcanoIcon />
};
const wilds = {
	url: wildsIconPath,
	svg: <WildsIcon />
};
const daily_deals = {
	url: dailyIconPath,
	svg: <DailyIcon />
};

const daily_gift = {
	url: dailyGiftIconPath,
	svg: <DailyGiftIcon />
};
const club_gift = {
	url: clubGiftIconPath,
	svg: <ClubGiftIcon />
};
const winners = {
	url: wildsIconPath,
	svg: <WinnersIcon />
};

const limited_deals = {
	url: wildsIconPath,
	svg: <LimitedDealsIcon />
};

const sectionIconConfig = {
	boosters,
	coins,
	featured,
	volcanos,
	wilds,
	daily_deals,
	daily_gift,
	club_gift,
	winners,
	limited_deals
};

const getSectionIcon = (handle: string) => {
	return sectionIconConfig[handle as keyof typeof sectionIconConfig];
};

export default getSectionIcon;
export {
	LimitedDealsIcon,
	limitedDealsIconPath,
	WinnersIcon,
	winnersIconPath,
	CircleIcon,
	circleIconPath,
	CoinsIcon,
	coinsIconPath,
	ClubGiftIcon,
	clubGiftIconPath,
	ExternalIcon,
	externalIconPath,
	ClubGiftIconWhite,
	clubGiftIconWhitePath,
	MerchShopIcon,
	merchShopIconPath,
	ClubShopIcon,
	clubShopIconPath,
	CrownIcon,
	crownIconPath,
	CrownOutlinedIcon,
	crownOutlinedIconPath,
	CrownWhiteIcon,
	PoisBountyIcon,
	poisBountyIconPath,
	crownWhiteIconPath,
	DailyGiftIcon,
	dailyGiftIconPath,
	defaultSignedInProfileIconPath,
	DefaultSignInProfileIcon,
	FaqIcon,
	faqIconPath,
	ForumIcon,
	forumIconPath,
	HomeIcon,
	homeIconPath,
	InfoIcon,
	infoIconPath,
	LogoutIcon,
	logoutIconPath,
	NewsIcon,
	newsIconPath,
	ProfileIcon,
	profileIconPath,
	ScopelyIcon,
	scopelyIconPath,
	FacebookIcon,
	facebookIconPath,
	ShareThisFacebookIcon,
	shareThisFacebookIconPath,
	ShareThisTwitterIcon,
	shareThisTwitterIconPath,
	ShopIcon,
	shopIconPath,
	TimerIcon,
	timerIconPath,
	arrowRightIconPath,
	ArrowRightIcon,
	storeButtonAppleOutlinePath,
	StoreButtonAppleOutline,
	storeButtonGoogleOutlinePath,
	StoreButtonGoogleOutline,
	appleStoreIconPath,
	AppleStoreIcon,
	googleStoreIconPath,
	GoogleStoreIcon,
	scrollDownIconPath,
	ScrollDownIcon,
	DripOfferIcon,
	dripOfferIconPath,
	RedXIcon,
	redXIconPath,
};
