import { Grid, IconButton } from "@mui/material";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { links } from "helpers";
import { appleStoreIconPath, googleStoreIconPath } from "utils/svgIcons";

export const FooterAppStoreButtons = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	return (
		<Grid
			item
			py={2}
			sx={{
				padding: "16px"
			}}
		>
			<Grid
				container
				spacing={0.8}
				direction="row"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item xs={6} textAlign="center">
					<IconButton
						onClick={() => {
							reportEvent("apple_store_click", { source: "footer" });
						}}
						component="a"
						href={links.appstore_apple.path}
						title="iOS App Store"
						target="_blank"
						rel="noreferrer"
						sx={{
							padding: "0px"
						}}
					>
						<img src={appleStoreIconPath} alt="Apple App Store page" />
					</IconButton>
				</Grid>
				<Grid item xs={6} textAlign="center">
					<IconButton
						onClick={() => {
							reportEvent("google_play_store_click", { source: "footer" });
						}}
						component="a"
						href={links.appstore_google.path}
						title="Google Play Store"
						target="_blank"
						rel="noreferrer"
						sx={{
							padding: "0px"
						}}
					>
						<img src={googleStoreIconPath} alt="Google App Store page" />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};
