import styled from "@emotion/styled";
import { tabletGiftsGaloreView } from "../../styles";

export const ClubName = styled.span`
	background: #ffffffb2;
	padding: 0.5rem 0.8rem;
	border-radius: 1rem;
	font-weight: 700;
	margin-right: 0.5rem;
	font-size: 13px;
	span {
		color: #6d6d6d;
		@media (min-width: ${tabletGiftsGaloreView}) {
			margin-right: 4px;
		}
	}
`;
