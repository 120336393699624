import { styled } from "@mui/material";

export const Container = styled("div")`
	background-color: #fff;
	max-width: 430px;
	border-radius: 1rem;
	overflow: hidden;

	@media (max-height: 500px) {
		width: 340px;
	}

	&.mobile {
		width: 95%;
		margin: 0 auto;
	}
`;

export const Head = styled("div")`
	height: 180px;
	@media (max-height: 500px) {
		height: 115px;
	}
`;

export const Body = styled("div")`
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

export const Background = styled("img")`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Note = styled("p")`
	font-family: Baloo;
	margin: 0;
	font-size: 24px;
	font-weight: 400;
	text-align: center;

	@media (max-height: 500px) {
		font-size: 18px;
	}
`;
