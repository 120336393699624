import { PoisBountyProgressStep } from "interfaces";

export const hideBar = (
	arr: PoisBountyProgressStep[],
	index: number,
	userLoyaltyPoints: number
): boolean => {
	const hideIndexes: number[] = [];
	arr.forEach((item, index) => {
		if (userLoyaltyPoints - item.target === 0) {
			return hideIndexes.push(index);
		}
		if (userLoyaltyPoints - item.target < 0) {
			hideIndexes.push(index);
		}
	});

	return index > hideIndexes[0];
};
