import { Grid } from "@mui/material";
import collectedNode from "assets/drip-offer/collected-node.png";
import shine from "assets/drip-offer/shine.png";
import tiki from "assets/drip-offer/tiki.gif";
import uncollectedNode from "assets/drip-offer/unclaimed-node.png";
import { useWebStoreContext } from "contexts";
import { IShopRewardItem } from "contexts/WebStoreProvider/types";
import { Node } from "interfaces/drip-offer";
import { getNodeItemData, handleTicketVariant } from "pages/DripOffer/hooks";
import { ClaimedSmallTicket, Shine, SmallTicket, SmallTicketTiki } from "pages/DripOffer/styles";
import { useEffect } from "react";

interface NodeProps {
	node: Node;
	onSetAvailableNode: (node: Node) => void;
	changeSize: boolean;
	availableNode?: Node;
	isPopup?: boolean;
}

export const DisplayNode = ({ node, onSetAvailableNode, changeSize, availableNode, isPopup }: NodeProps) => {
	const { store } = useWebStoreContext();

	if (!store) {
		return null;
	}

	useEffect(() => {
		!availableNode && node.state === "available" && onSetAvailableNode(node);
	});

	const items: IShopRewardItem[] | null = getNodeItemData(store, node);

	if (!isPopup) {
		return (
			<Grid item xs={4} sm={changeSize ? 1.714 : 2} md={1.4} lg={1.1} position="relative">
				{(node.state === "claimed" || node.state === "disqualified") &&
					<ClaimedSmallTicket src={node.state === "claimed" ? collectedNode : uncollectedNode} alt="Collected node state image" />
				}
				{node.state === "available" && (
					<>
						<Shine src={shine} alt="Shiny style node state image" />
						<SmallTicketTiki src={tiki} alt="Tiki saying hi" />
					</>
				)}
				<SmallTicket
					container
					direction="column"
					background={handleTicketVariant(node.rewardBackgroundImageUrl, node.state === "available")}
					claimed={node.state === "claimed" || node.state === "disqualified" ? "true" : "false"}
				>
					<Grid item md={11} lg={11} position="relative" display="flex" justifyContent="center" alignItems="center">
						<div className="item-content small">
							<img src={items?.[0].imageUrl} alt="" />
							<p className={`small ${Number(items?.[0].value) > 999 && "is-coin"}`}>
								{Number(items?.[0].value) < 999 && "x"}{items?.[0].value?.toLocaleString('en-US')}
							</p>
						</div>
					</Grid>
					<Grid item md={1} lg={1} px={.5}>
						<p className="prize-name">Prize {node.nodeIndex + 2}</p>
					</Grid>
				</SmallTicket>
			</Grid>
		);
	} else {
		return (
			<Grid item xs={4} sm={changeSize ? 1.714 : 2} position="relative">
				{(node.state === "claimed" || node.state === "disqualified") &&
					<ClaimedSmallTicket src={node.state === "claimed" ? collectedNode : uncollectedNode} alt="Collected node state image" />
				}
				{node.state === "available" && (
					<>
						<Shine src={shine} alt="Shiny style node state image" />
						<SmallTicketTiki src={tiki} alt="Tiki saying hi" />
					</>
				)}
				<SmallTicket
					container
					direction="column"
					background={handleTicketVariant(node.rewardBackgroundImageUrl, node.state === "available")}
					claimed={(node.state === "claimed" || node.state === "disqualified") ? "true" : "false"}
					ispopup="true"
				>
					<Grid item xs={11} position="relative" display="flex" justifyContent="center" alignItems="center">
						<div className="item-content small">
							<img src={items?.[0].imageUrl} alt="" />
							<p className={`small ${Number(items?.[0].value) > 999 && "is-coin"}`}>
								{Number(items?.[0].value) < 999 && "x"}{items?.[0].value?.toLocaleString('en-US')}
							</p>
						</div>
					</Grid>
					<Grid item xs={1} px={.5}>
						<p className="prize-name">Prize {node.nodeIndex + 2}</p>
					</Grid>
				</SmallTicket>
			</Grid>
		);
	}
};

interface DisplayFirstNodeProps {
	items: IShopRewardItem[];
	background: string;
	isPopup?: boolean;
}

export const DisplayFirstNode = ({ items, background, isPopup }: DisplayFirstNodeProps) => {

	if (!isPopup) {
		return (
			<Grid item xs={4} sm={1.714} md={1.4} lg={1.1} position="relative">
				<ClaimedSmallTicket src={collectedNode} alt="Collected node image" />
				<SmallTicket container direction="column" background={background} claimed={"true"}>
					<Grid item md={11} lg={11} position="relative" display="flex" justifyContent="center" alignItems="center">
						<div className="item-content small">
							<img src={items[0].imageUrl} alt="" />
							<p className={`small ${items[0].id === "coin" && "is-coin"}`}>
								{items[0].id !== "coin" && "x"}{items[0].value?.toLocaleString('en-US')}
							</p>
						</div>
					</Grid>
					<Grid item md={1} lg={1} px={.5}>
						<p className="prize-name">Prize 1</p>
					</Grid>
				</SmallTicket>
			</Grid>
		);
	} else {
		return (
			<Grid item xs={4} sm={1.714} position="relative">
				<ClaimedSmallTicket src={collectedNode} alt="Collected node image" />
				<SmallTicket container direction="column" background={background} claimed={"true"} ispopup="true">
					<Grid item md={11} lg={11} position="relative" display="flex" justifyContent="center" alignItems="center">
						<div className="item-content small">
							<img src={items[0].imageUrl} alt="" />
							<p className={`small ${items[0].id === "coin" && "is-coin"}`}>
								{items[0].id !== "coin" && "x"}{items[0].value?.toLocaleString('en-US')}
							</p>
						</div>
					</Grid>
					<Grid item md={1} lg={1} px={.5}>
						<p className="prize-name">Prize 1</p>
					</Grid>
				</SmallTicket>
			</Grid>
		);
	}
};
