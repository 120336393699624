import { styled } from "@mui/material";
import Carousel from "../Carousel";

export const Content = styled("div")`
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	justify-content: left;
`;

const SectionContent = ({ items }: { items: JSX.Element[] }) => (
	<Carousel>{items}</Carousel>
);

export default SectionContent;
