import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { getDebugLogger, IDebugLogger } from "../../utils/debugLogger";
import getCheckLoginState from "./api/checkLoginState";
import getGetProfileImage from "./api/getProfileImage";
import getTryLogin from "./api/getTryLogin";
import getLogin from "./api/login";
import getLogout from "./api/logout";
import getMe from "./api/me";
import createPersistedState from "use-persisted-state";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleAnalyticsContext } from "../GoogleAnalyticsProvider";
import { LoginType } from "../../domain/Login/LoginGoogleAnalyticsHelper";
import { links } from "helpers";

const debug = getDebugLogger({
	isEnabled: !false,
	color: "HotPink"
});

const useFbAccessTokenState = createPersistedState<string | null>(
	"store.solitairetripeaks.com:fbAccessToken"
);

/* eslint-disable */
export enum ConnectionStatus {
	UNKNOWN = "unknown",
	CONNECTING = "connecting",
	CONNECTED = "connected",
	DISCONNECTED = "disconnected"
}
/* eslint-enable */

export interface IFacebookContext {
	connectionStatus: ConnectionStatus;
	userName: string;
	fbAccessToken: string | null;
	setFbAccessToken(fbAccessToken: string | null): void;
	login(): void;
	tryLogin(): void;
	logout(): void;
	me(): void;
	checkLoginState(): void;
	isConnected: boolean;
	profileImage: string | null;
}

export interface IFbPicture {
	width: number;
	height: number;
	is_silhouette: boolean;
	url: string;
}
/* eslint-disable */
export interface IFbPcitureResp {
	data: IFbPicture;
	error?: any;
}
/* eslint-enable */

// A custom hook that builds on useLocation to parse
// the query string for you.
function useHash() {
	const { hash } = useLocation();
	return useMemo(() => new URLSearchParams(hash), [hash]);
}

export const FacebookContext = createContext<IFacebookContext>({
	connectionStatus: ConnectionStatus.UNKNOWN,
	userName: "",
	fbAccessToken: null,
	login: async () => {
		// no op
	},
	// eslint-disable-next-line
	setFbAccessToken: (fbAccessToken: string | null) => {
		// no op
	},
	tryLogin: async () => {
		// no op
	},
	logout: async () => {
		// no op
	},
	me: async () => {
		// no op
	},
	checkLoginState: async () => {
		// no op
	},
	isConnected: false,
	profileImage: null
});

/* eslint-disable */
export interface IFacebookContextApi {
	checkLoginState: (debug?: IDebugLogger) => Promise<void>;
	login: (debug?: IDebugLogger) => Promise<void>;
	tryLogin: (debug?: IDebugLogger) => Promise<void>;
	logout: (debug?: IDebugLogger) => Promise<void>;
	me: (debug?: IDebugLogger) => Promise<void>;
	getProfileImage: (debug?: IDebugLogger) => Promise<void>;
}
/* eslint-enable */

function FacebookProvider({ children }: { children: ReactNode }) {
	const query = useHash();
	const navigate = useNavigate();
	const { loginGoogleAnalyticsHelper } = useGoogleAnalyticsContext();

	const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
		ConnectionStatus.UNKNOWN
	);
	const [userName, setUserName] = useState<string>("");
	const [userId, setUserId] = useState<string>("");
	const [fbAccessToken, setFbAccessToken] = useFbAccessTokenState(null);
	const [profileImage, setProfileImage] = useState<string | null>(null);

	// 304 when is cached
	// const debug = getDebugLogger({
	// 	isEnabled: true,
	// 	color: "HotPink"
	// });

	const api: IFacebookContextApi = {
		checkLoginState: getCheckLoginState({ setFbAccessToken, debug }),
		login: getLogin({ setFbAccessToken, setConnectionStatus, debug }),
		tryLogin: getTryLogin({ setFbAccessToken, setConnectionStatus, debug }),
		logout: getLogout({ setFbAccessToken, setProfileImage, setUserId, debug }),
		me: getMe({
			connectionStatus,
			setUserName,
			setUserId,
			setProfileImage,
			debug
		}),
		getProfileImage: getGetProfileImage({ userId, setProfileImage, debug })
	};

	useEffect(() => {
		return; // turned off for debug
		debug.logUseEffect({
			iconText: "FacebookProvider",
			message: ["\nuseEffect( checkLoginStateAsync, [] )"]
		});
		const checkLoginStateAsync = async () => {
			await api.checkLoginState(debug);
		};
		checkLoginStateAsync();
	}, []);

	/**
	 * autoHandleOauthRedirectResponse
	 * For mobile devices, handle the oauth redirect response.
	 * This was needed for embedded browsers
	 */
	useEffect(() => {
		const autoHandleOauthRedirectResponse = () => {
			const accessToken = query.get("#access_token");
			const state = query.get("state");
			if (accessToken) {
				setFbAccessToken(accessToken);
				loginGoogleAnalyticsHelper.reportLoginOptionLoginSuccess(
					LoginType.Facebook
				);
				navigate(state ? state.replace(/'/g, "") : links.home.path, {
					replace: true
				});
			}
		};
		autoHandleOauthRedirectResponse();
	}, []);
	-(
		/**
		 * autoFbConnectionStatus:
		 */
		useEffect(() => {
			debug.logUseEffect({
				iconText: "FacebookProvider",
				message: [
					"\nuseEffect( setFbConnectionStatus, [fbAccessToken])",
					[fbAccessToken]
				]
			});

			const autoFbConnectionStatus = () => {
				if (fbAccessToken) {
					setConnectionStatus(ConnectionStatus.CONNECTED);
				} else {
					setConnectionStatus(ConnectionStatus.UNKNOWN);
				}
				debug.log({
					iconText: "autoFbConnectionStatus:",
					message: [
						fbAccessToken
							? ConnectionStatus.CONNECTED
							: ConnectionStatus.UNKNOWN
					]
				});
			};
			autoFbConnectionStatus();
		}, [fbAccessToken])
	);

	/**
	 * autoFbMe
	 */
	useEffect(() => {
		if (connectionStatus !== ConnectionStatus.CONNECTED) {
			return;
		}
		const autoFbMe = () => {
			debug.logUseEffect({
				iconText: "FacebookProvider",
				message: [
					"\nuseEffect( fetchMeAsync, [connectionStatus])",
					[connectionStatus]
				]
			});
			const fetchMeAsync = async () => {
				await api.me();
			};
			fetchMeAsync();
		};
		autoFbMe();
	}, [connectionStatus]);

	/*
	useEffect(() => {
		return; // TODO: using me() instead to retrieve user profile image
		debug.logUseEffect({
			iconText: "FacebookProvider",
			message: ["\nuseEffect( fetchUserInfo, [userId])", [userId]]
		});
		const fetchUserInfo = async () => {
			await api.getProfileImage(debug);
		};
		fetchUserInfo();
	}, [userId]);
	 */

	const isConnected = connectionStatus === ConnectionStatus.CONNECTED;

	return (
		<FacebookContext.Provider
			value={{
				connectionStatus,
				login: api.login,
				tryLogin: api.tryLogin,
				logout: api.logout,
				me: api.me,
				checkLoginState: api.checkLoginState,
				userName,
				fbAccessToken,
				setFbAccessToken,
				isConnected,
				profileImage
			}}
		>
			{children}
		</FacebookContext.Provider>
	);
}

export const useFacebookContext = () => {
	const context = useContext(FacebookContext);
	if (context === undefined) {
		throw new Error("useWebStoreContext was used outside of its Provider");
	}
	return context;
};

export default FacebookProvider;
