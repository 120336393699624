import { Grid } from "@mui/material";
import headerImg from "assets/drip-offer/background-popup.png";
import tikiImg from "assets/drip-offer/tiki.gif";
import title from "assets/drip-offer/title-drip-offer.png";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import { useGoogleAnalyticsContext } from "contexts";
import { useAppDispatch, useImagePath } from "hooks";
import { useEffect } from "react";
import { onClosePopUp } from "redux/slices";
import {
	Body,
	Button,
	Container,
	Header,
	HeaderTitle,
	Text,
	Tiki,
	Title
} from "./styles";

interface DripOfferMissedEventProps { }

export const DripOfferMissedEvent: React.FC<DripOfferMissedEventProps> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const { reportEvent } = useGoogleAnalyticsContext();
	const headImageUrl = getImage(headerImg);
	const titleUrl = getImage(title);
	const scale = useCalculateScale(378, 380);

	const onClose = () => {
		dispatch(onClosePopUp());
	};

	useEffect(() => {
		reportEvent("drip_offer", {
			source: "show_pop_up",
			pop_up_type: "event_finished",
		})
	}, []);

	return (
		<Container scale={scale}>
			<CloseIconButton onClose={onClose} />
			<Header data-image={headImageUrl}>
				<Tiki src={tikiImg} alt="Tiki says Hi!" />
				<HeaderTitle src={titleUrl} />
			</Header>
			<Body>
				<Title>The event has ended</Title>
				<Text>Come back again next time!</Text>
				<Grid container /* spacing={{ xs: 1, sm: 2 }} */>
					{/* {isMobile && (
						<Grid item xs={12} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="endless_offer_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							/>
						</Grid>
					)} */}
					<Grid
						item
						xs={12}
						/* sm={isMobile ? 6 : 12} */
						display="flex"
						justifyContent="center"
					>
						<Button
							variant="contained"
							themeColor="primary"
							sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							onClick={() => onClose()}
						>
							Continue Shopping
						</Button>
					</Grid>

				</Grid>
			</Body>
		</Container>
	);
};
