import axios, { AxiosRequestConfig } from "axios";
import { AbstractHttpService } from "./types";
import HttpRequest from "./HttpRequest";
import HttpResponse from "./HttpResponse";

export default class AxiosHttpService extends AbstractHttpService {
	makeRequestInternal(request: HttpRequest): Promise<HttpResponse> {
		const axiosRequest: AxiosRequestConfig = {
			baseURL: this.defaultBackendUrl,
			url: request.url,
			method: request.method,
			params: request.params,
			headers: request.headers
		};

		return axios.request(axiosRequest).then((res) => {
			if (!res.data) {
				throw new Error("request failed for some reason");
			}
			this.log([request.url, "\n", res]);

			return new HttpResponse(request, this, res.headers, res.status, res.data);
		});
	}
}
