import { Moment } from "moment";
import { SaleTag } from "interfaces";
import { IUserTracker } from "contexts/WebStoreProvider/types";

export const getActiveSaleTags = (
	saleTags: SaleTag[],
	userTracker: IUserTracker | null,
	nowTime: Moment | null
): SaleTag[] => {
	// @ts-expect-error
	if (typeof nowTime._tzm === "undefined") {
		return [];
	}

	let activeTags = saleTags.filter((tag) => {
		if (nowTime?.isAfter(tag.startTime) && nowTime.isBefore(tag.endTime)) {
			return tag;
		}
	});

	if (!userTracker?.clubId) {
		activeTags = activeTags.filter(
			(tag) => tag.megaMenuTagSection !== "Club Shop"
		);
	}

	return activeTags;
};
