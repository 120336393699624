import styled from "styled-components";
import { Typography } from "@mui/material";

export const BogoBox = styled.div`
	@media (orientation: portrait) {
		width: 100%;
	}
	@media (orientation: landscape) {
		width: unset;
	}

	.bogo-button {
		width: 92%;
		margin: 0px auto;
		padding: 0.5rem;
		@media (orientation: landscape) {
			padding: 0.6rem;
			max-width: 300px;
		}
	}
`;

export const OffersStyled = styled.div`
	display: flex;
	justify-content: center;
	padding: 7px;

	@media (orientation: portrait) {
		height: 550px;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	@media (orientation: landscape) {
		height: 178px;
		align-items: center;
		flex-wrap: nowrap;
	}
`;

export const OfferItem = styled.div`
	position: relative;
	//display: grid;
	grid-template-rows: auto;
	background-color: rgba(51, 51, 51, 0.3);
	border: 1px solid #9aeffc;
	border-radius: 12px;
	padding: 0.7rem;
	gap: 0.5rem;
	margin: 3px;
	width: 200px;
	height: auto;
	z-index: 999;
	align-content: stretch;

	@media (orientation: portrait) {
		width: 90%;
		&.one-item {
			min-width: unset;
			.item-icon {
				width: 55px;
				height: 55px;
			}
		}
		&.two-items {
			min-width: 250px;
		}
		&.several-items {
			min-width: 200px;
		}
	}
	@media (orientation: landscape) {
		gap: 1.2rem;
		.item-icon {
			width: 65px;
			height: 65px;
		}
		&.one-item {
			min-width: 400px;
			width: auto;
		}
		&.two-items {
			min-width: 350px;
		}
		&.several-items {
			min-width: 300px;
		}
	}
`;

export const BogoItem = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	flex-direction: column;
	@media (orientation: landscape) {
		flex-direction: row;
	}
`;

export const Items = styled.div`
	justify-content: center;
	align-items: end;
	display: flex;
	//flex-wrap: wrap;
`;
/* eslint-disable */
export const RewardItem = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${(props) => props.width};
	box-sizing: border-box;
	padding: 1px;
`;
/* eslint-enable */

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 15px;
`;

export const ItemImage = styled.img`
	width: 46px;
	height: 46px;
	object-fit: contain;
	justify-self: center;
	align-self: center;
`;

export const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	padding: 0.5rem 0.8rem;
	border-radius: 100px;

	> div {
		align-items: center;
		justify-content: center;
		display: flex;
		gap: 0.2rem;
	}
`;

export const Plus = styled.img`
	width: 40px;
	height: 42px;
	margin: 0.4rem 0;
	@media (orientation: landscape) {
		margin: 0 1rem;
	}
`;

export const FreeTag = styled.img`
	width: 105px;
	height: 93px;
	object-fit: contain;
	position: absolute;
	top: -3.8rem;
	right: 8%;
`;
