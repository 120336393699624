import { getUnclaimedReward } from "../helper";
import { LoadDataActionType, PoisBountySlice } from "../types";

export const updatePoisBountySlice = (
	state: PoisBountySlice,
	action: LoadDataActionType
): void => {
	const poisBountyData = action.payload;
	if (!poisBountyData) {
		return;
	}

	if (!("tracker" in poisBountyData)) {
		state.responseSucceedWithNoTracker = true;
		return;
	}

	const { tracker } = poisBountyData;
	const { loyaltyPoints, rewardClaimStatuses } = tracker;
	const unclaimedRewardIndex = getUnclaimedReward(rewardClaimStatuses);

	if (unclaimedRewardIndex === -1) {
		state.remaining24hour = true;
	}
	state.data = poisBountyData;
	state.rewards = rewardClaimStatuses;
	state.userLoyaltyPoints = loyaltyPoints;
};
