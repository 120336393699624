import { PREFIX } from "./helper";
import { RootState } from "redux/store";
import { getGiftsGalore, onGiftGaloreEventEnded } from "./thunks";
import { initialState } from "./initial-state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const giftsGaloreSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onSeenRewardedPopUp: (state, action: PayloadAction<boolean>) => {
			state.seenRewardedPopUp = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getGiftsGalore.fulfilled, (state, action) => {
			const response = action.payload;
			if (typeof response === "undefined") {
				return;
			}
			state.data = response;
			if ("tracker" in response) {
				state.tracker = response.tracker;
			} else {
				state.tracker = undefined;
			}
			state.trackerLoading = false;
		});
		builder.addCase(getGiftsGalore.rejected, (state) => {
			state.trackerLoading = false;
		});
		builder.addCase(onGiftGaloreEventEnded.fulfilled, (state) => {
			state.data = undefined;
			state.tracker = undefined;
			state.trackerLoading = false;
		});
		builder.addCase(onGiftGaloreEventEnded.pending, (state) => {
			state.trackerLoading = true;
		});
		builder.addCase(onGiftGaloreEventEnded.rejected, (state) => {
			state.trackerLoading = false;
		});
	}
});

export const { onSeenRewardedPopUp } = giftsGaloreSlice.actions;
export const selectGiftsGaloreState = (state: RootState) =>
	state.clubGiftsGalore;
export default giftsGaloreSlice.reducer;
export * from "./types";
export * from "./thunks";
