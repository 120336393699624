import { styled } from "@mui/material";

export const GridStyled = styled("div")`
	display: flex;
	justify-content: center;
	gap: 15px;
	@media (max-width: 932px) and (max-height: 440px) {
		display: grid;
		grid-template-columns: repeat(3, 180px);
		justify-content: center;
		grid-gap: 5%;
		transform: translateX(-0.7rem);
	}
	@media (max-height: 360px) {
		grid-gap: 3%;
		transform: translateX(-0.3rem);
	}
`;
