import { Fragment } from "react";
import { ClubGiftIcon } from "utils/svgIcons";
import { FreeGiftCard } from "./FreeGiftCard";
import { links } from "helpers";
import { SectionIconContainer, SectionTitle } from "styles/general_styles";

export const FreeGiftSection = () => {
	return (
		<Fragment>
			<SectionTitle>
				<SectionIconContainer>
					<ClubGiftIcon width="25px" height="40px" fill="#073670" />
				</SectionIconContainer>
				{links.club_free_gift.title.toUpperCase()}
			</SectionTitle>
			<FreeGiftCard />
		</Fragment>
	);
};
