import { useMediaQuery } from "@mui/material";
import { useAppSelector } from "hooks";
import { DipType } from "interfaces";
import { selectDipState } from "redux/slices";
import styled from "styled-components";

export const getBackgroundUrl = (
	url: string,
	variant: "small" | "large"
): string => {
	return url?.replace("{dips_image_variant}", variant);
};

interface AssetsProps {
	dipType: DipType | undefined;
}

export const Assets: React.FC<AssetsProps> = ({ dipType }) => {
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const isMobile = isPortrait;
	const { currentDip } = useAppSelector(selectDipState);
	const url = currentDip?.dip.imageUrl as string;

	const Image = styled.img`
		width: 100%;
		position: absolute;
		z-index: -1;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		bottom: 0;
		right: 0;
		height: ${dipType === "informative" ? "100%" : "unset"};
		@media (orientation: portrait) {
			min-height: 380px;
			height: 100%;
		}
	`;

	return <Image src={getBackgroundUrl(url, isMobile ? "small" : "large")} />;
};
