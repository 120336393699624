import { WebStore } from "interfaces";
import { AppThunk } from "redux/store";
import { loadData } from ".";
import { getShopSections } from "../shop-sections";

export const loadWebStoreData =
	(data: WebStore): AppThunk =>
	(dispatch) => {
		dispatch(loadData(data));
		dispatch(getShopSections());
	};
