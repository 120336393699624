import { useEffect } from "react";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	onOpenPopUp,
	getGiftsGalore,
	onSeenRewardedPopUp,
	selectGiftsGaloreState
} from "redux/slices";

type LocalSessionTracker = { eventId: string; views: number };

export const CGG_POP_UP_REWARDED =
	"store.solitairetripeaks.com:CGGRewardBanner";

export const useInitialActions = () => {
	const dispatch = useAppDispatch();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { tracker, seenRewardedPopUp } = useAppSelector(selectGiftsGaloreState);
	const localCounter: LocalSessionTracker = JSON.parse(
		localStorage.getItem(CGG_POP_UP_REWARDED) as string
	);

	useEffect(() => {
		if (isFeatureFlagEnabled(FeaturesFlags.CLUB_GIFT_BONUS)) {
			dispatch(getGiftsGalore());
		}
	}, []);

	useEffect(() => {
		if (!tracker) {
			const cleanup = setInterval(() => {
				dispatch(getGiftsGalore());
			}, 60000);
			return () => {
				clearInterval(cleanup);
			};
		}
		const cleanup = setInterval(() => {
			dispatch(getGiftsGalore());
		}, 10000);
		return () => {
			clearInterval(cleanup);
		};
	}, [tracker]);

	useEffect(() => {
		if (!tracker) {
			return;
		}
		const { hasUncollectedBonusRewards, eventId } = tracker;

		if (localCounter && localCounter?.eventId !== eventId) {
			dispatch(onSeenRewardedPopUp(false));
			localCounter.views = 0;
		}

		if (hasUncollectedBonusRewards && !seenRewardedPopUp) {
			dispatch(onSeenRewardedPopUp(true));

			if (localCounter) {
				if (localCounter.views < 3) {
					localCounter.views = localCounter.views + 1;
					dispatch(onOpenPopUp("cgg-earned-reward"));
				}
				localCounter.eventId = eventId;
				localStorage.setItem(CGG_POP_UP_REWARDED, JSON.stringify(localCounter));
			} else {
				localStorage.setItem(
					CGG_POP_UP_REWARDED,
					JSON.stringify({ eventId: eventId, views: 1 })
				);
				dispatch(onOpenPopUp("cgg-earned-reward"));
			}
		}
	}, [tracker, seenRewardedPopUp]);
};
