import { useAppDispatch, useAppSelector } from "hooks";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FeaturesFlags } from "helpers";
import {
	getLimitedDeals,
	onSetStickyDIPData,
	onSetStickyDIPsDisplayed,
	selectStickyDIPState
} from "redux/slices";
import {
	useEnvContext,
	useGoogleAnalyticsContext,
	useWebStoreContext
} from "contexts";
import { useGetOffersQuery, useGetFeatureTrackerQuery } from "redux/apis";
import { ShopContext } from "pages/StoreFront";
import { IItem } from "../SingleItems";
import EnlessOffer from "../EndlessOffer";
import { WaterFallSale } from "../WaterFallSale";
import StickyDIP from "components/StickyDIP";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, SwiperOptions } from "swiper";
import SectionHeader from "components/Section/SectionHeader";
import { LimitedDealsIcon } from "utils/svgIcons";
import { SwiperContainer } from "styles/general_styles";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { PurchaseOfferSingleItem, WsDialogs } from "interfaces";
import { SortStyckyDips } from "./helpers";
import { EndlessOfferResponse } from "interfaces/endless-offer";
import { WaterfallResponse } from "interfaces/waterfall-sale";

export const LimitedDealsSection = () => {
	const dispatch = useAppDispatch();
	const { variant } = useDialogModalRedux();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { sectionMenuData } = useContext(ShopContext);
	const { reportEvent } = useGoogleAnalyticsContext();
	const { authToken, userTracker } = useWebStoreContext();
	const swiperRef = useRef<SwiperRef | null>(null);

	const [swiperSlides, setSwiperSlides] = useState<
		Array<JSX.Element> | undefined
	>();

	const swiperParams: SwiperOptions = {
		autoplay: {
			delay: 7000,
			pauseOnMouseEnter: true,
			disableOnInteraction: variant === WsDialogs.XSOLLA_PURCHASE ? true : false
		},
		modules: [Autoplay, Pagination],
		navigation: true,
		pagination: {
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 4
		},
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 30,
		slidesOffsetAfter: 0
	};

	const { data: stickyData } = useAppSelector(selectStickyDIPState);
	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: !!authToken?.value
	});
	const { data: EndlessOfferData }: { data?: EndlessOfferResponse } =
		useGetFeatureTrackerQuery({
			FeatureType: "endless-offer",
			isAuthenticated: !!authToken?.value
		});
	const { data: waterfallData }: { data?: WaterfallResponse } =
		useGetFeatureTrackerQuery({
			FeatureType: "waterfall-sale",
			isAuthenticated: !!authToken?.value
		});

	const isWaterfallSaleActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.WATERFALL_SALES)
		);
	};
	const isEndlessOfferActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.ENDLESS_OFFER)
		);
	};
	const isStickyDipActive = () => {
		return isFeatureFlagEnabled(FeaturesFlags.STICKY_DIPS);
	};

	const section = sectionMenuData.find((item: IItem) => {
		if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
			return item.name === "waterfall_sale";
		}
		if (isEndlessOfferActive() && EndlessOfferData?.tracker) {
			return item.name === "endless_offer";
		}
		if (isStickyDipActive() && stickyData) {
			return item.name === "limited_deals";
		}
		return false;
	});

	const analytics = (index: number) => {
		if (swiperSlides?.[index]?.props?.data) {
			reportEvent("offer_rendered", {
				source: "limited_deal",
				offer_id: swiperSlides[index].props.data[0]?.id,
				reward_id: swiperSlides[index].props.data[0]?.rewardId,
				price: swiperSlides[index].props.data[0]?.price,
				loyalty_points: swiperSlides[index].props.data[0]?.loyaltyPointReward,
				offer_ends_time: swiperSlides[index].props.data[0]?.timerExpirationDate,
				image_url: swiperSlides[index].props.data[0]?.dip?.imageUrl
			});
		} else {
			if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
				const tierStatus = waterfallData?.sessionTracker?.tierStatuses.filter(
					(ts) => !ts.completed
				)?.[0];
				reportEvent("offer_rendered", {
					source: "limited_deal",
					offer_id: waterfallData.sessionTracker.id,
					reward_id: purchaseOffers?.offers.find(
						(offer: PurchaseOfferSingleItem) =>
							offer.id === tierStatus.tier.offerId
					)?.rewardId,
					sale_id: waterfallData.sessionTracker.saleId,
					price: purchaseOffers?.offers.find(
						(offer: PurchaseOfferSingleItem) =>
							offer.id === tierStatus.tier.offerId
					)?.price,
					loyalty_points: purchaseOffers?.offers.find(
						(offer: PurchaseOfferSingleItem) =>
							offer.id === tierStatus.tier.offerId
					)?.loyaltyPointReward,
					offer_ends_time: waterfallData.sessionTracker.endTime,
					bannertext: waterfallData.sessionTracker.title,
					image_url: waterfallData.sessionTracker.backgroundImageUrl
				});
			} else if (isEndlessOfferActive() && EndlessOfferData?.tracker) {
				const node = EndlessOfferData?.tracker?.nodes.filter(
					(node) => !node.completionTime
				)?.[0];
				reportEvent("offer_rendered", {
					source: "limited_deal",
					offer_id: node.offerId,
					event_id: EndlessOfferData.tracker.eventId,
					reward_id: node.rewardId,
					price:
						purchaseOffers?.offers.find(
							(offer: PurchaseOfferSingleItem) => offer.id === node.offerId
						)?.price ?? 0,
					loyalty_points:
						purchaseOffers?.offers.find(
							(offer: PurchaseOfferSingleItem) => offer.id === node.offerId
						)?.loyaltyPointReward ?? 0,
					offer_ends_time: EndlessOfferData.tracker.endTime,
					bannertext: EndlessOfferData.tracker.titleImageUrl,
					image_url: EndlessOfferData.tracker.themeBackgroundImageUrl
				});
			}
		}
	};

	useEffect(() => {
		analytics(0);

		const handleVisibilityChange = () => {
			if (!document.hidden) {
				dispatch(getLimitedDeals());
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useMemo(() => {
		if (variant === WsDialogs.XSOLLA_PURCHASE) {
			swiperRef.current?.swiper?.autoplay?.stop();
		} else {
			swiperRef.current?.swiper?.autoplay?.start();
		}
	}, [variant]);

	useEffect(() => {
		if (!userTracker) {
			dispatch(onSetStickyDIPData(undefined));
			setSwiperSlides(undefined);
		} else {
			dispatch(getLimitedDeals());
		}
	}, [userTracker]);

	useEffect(() => {
		const generateSwiperContent = () => {
			const swiperContent: Array<JSX.Element> = [];
			if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
				swiperContent.push(
					<WaterFallSale key={waterfallData?.sessionTracker.id} />
				);
			}
			if (isEndlessOfferActive() && EndlessOfferData?.tracker) {
				swiperContent.push(
					<EnlessOffer key={EndlessOfferData?.tracker.eventId} />
				);
			}
			if (isStickyDipActive() && stickyData) {
				const sortedStickyDataByDates = SortStyckyDips(
					stickyData,
					swiperContent.length
				);
				Object.values(Object.fromEntries(sortedStickyDataByDates)).forEach(
					(sd) => {
						swiperContent.push(
							<StickyDIP data={sd} key={sd[0].campaignGroup} />
						);
					}
				);
				dispatch(
					onSetStickyDIPsDisplayed(Object.fromEntries(sortedStickyDataByDates))
				);
			}
			setSwiperSlides([...swiperContent]);
		};
		generateSwiperContent();
	}, [stickyData, EndlessOfferData, waterfallData]);

	if (!section || !purchaseOffers) {
		return <></>;
	}

	return (
		<section ref={section?.sectionRef} style={{ marginBottom: "40px" }}>
			<SectionHeader
				text="LIMITED DEALS"
				icon={<LimitedDealsIcon />}
				sx={{ marginBottom: { xs: "0px", sm: "10px" } }}
			/>
			<SwiperContainer>
				{swiperSlides && (
					<Swiper
						ref={swiperRef}
						{...swiperParams}
						className="tiki-swiper"
						onSlideChange={(e) => analytics(e.activeIndex)}
					>
						{swiperSlides.map((ss, i) => (
							<SwiperSlide key={`${ss.key}-${i}`}>{ss}</SwiperSlide>
						))}
					</Swiper>
				)}
			</SwiperContainer>
		</section>
	);
};
