/* eslint-disable @typescript-eslint/no-explicit-any */
import { IShopPurchaseOfferMultiItem } from "contexts/WebStoreProvider/types";
/* eslint-disable */
export const sortRewardsWithSpecificIdAtEnd = (
	arr: any[],
	specificId: string
) => {
	arr.sort((a, b) => (a.id === specificId ? 1 : b.id === specificId ? -1 : 0));
	return arr;
};

export const sortRewardsWithSpecificId = (
	arr: any[],
	specificId: string,
	position: "first" | "last"
) => {
	const order = position === "first" ? -1 : 1;
	arr.sort((a, b) =>
		a.id === specificId ? order : b.id === specificId ? -order : 0
	);
	return arr;
};

export const sortOfferByPriority = (arr: IShopPurchaseOfferMultiItem[]) => {
	arr.sort((a, b) => a.priority - b.priority);
	return arr;
};
