/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Container } from "./styles";

interface BannerProps {
	firstPurchaseText: string | undefined;
	firstPurchaseRewardBonus: string | undefined;
}

export const FirstPurchaseBanner: React.FC<BannerProps> = ({
	firstPurchaseText,
	firstPurchaseRewardBonus
}) => {
	const [currentText, setCurrentText] = useState(firstPurchaseText);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentText(
				currentText === firstPurchaseText
					? firstPurchaseRewardBonus
					: firstPurchaseText
			);
		}, 2000);

		return () => clearInterval(intervalId);
	}, [currentText]);

	return (
		<Container key={currentText}>
			<span
				style={{
					fontSize: currentText === firstPurchaseRewardBonus ? "14px" : "unset"
				}}
			>
				{currentText}
			</span>
		</Container>
	);
};
