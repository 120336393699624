import { IUserTracker } from "contexts/WebStoreProvider/types";
import { FeaturesFlags, links } from "helpers";
import { DripOfferResponse } from "interfaces/drip-offer";
import { Moment } from "moment";
import { useGetFeatureTrackerQuery } from "redux/apis";
import { useEnvContext } from "../contexts/EnvProvider";
import { Navigation } from "../interfaces";

export const getNavigationMenu = (
	isUserOnClub: string | undefined,
	poisBountyEventActive?: boolean,
	userTracker?: IUserTracker | null,
	nowTime?: Moment | null
): Navigation[] => {
	const { isFeatureFlagEnabled } = useEnvContext();

	const { data: dripOfferData }: { data?: DripOfferResponse } =
		useGetFeatureTrackerQuery(
			{
				FeatureType: "drip-offer",
				isAuthenticated: !!userTracker
			},
			{
				skip: !isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER) || !userTracker,
			}
		);

	const NAVIGATION: Navigation[] = [
		{
			...links.home,
			icon: "home",
			isEnabled: true,
			categoryId: 1,
			isDisplayInFooter: true
		},
		{
			...links.shop,
			icon: "store",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled: true,
			categoryId: 1
		},
		{
			...links.drip_offer,
			icon: "drip-offer",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER),
			categoryId: 1
		},
		{
			...links.pois_bounty,
			icon: "pois-bounty",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled:
				isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY) ||
				isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2),
			categoryId: 1
		},
		{
			...links.club_shop,
			icon: "club",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP),
			categoryId: 1
		},
		{
			...links.loyalty,
			icon: "crown",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled: true,
			categoryId: 1
		},
		{
			...links.free_daily_gifts,
			icon: "daily-gift",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.DAILY_BONUS),
			categoryId: 1
		},
		{
			...links.club_free_gift,
			icon: "club-gift",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: true,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP_FREE_GIFT),
			categoryId: 1
		},
		{
			...links.merch_shop,
			icon: "merch-shop",
			isDisplayInFooter: true,
			isDisplayInPageTopNav: false,
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.MERCH_SHOP),
			categoryId: 1
		},
		{
			...links.news,
			icon: "news",
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.NEWS),
			categoryId: 2,
			isDisplayInFooter: true
		},
		{
			...links.forum,
			icon: "forum",
			isEnabled: false,
			categoryId: 2,
			isDisplayInFooter: true
		},
		{
			...links.winners,
			icon: "winners",
			isEnabled: isFeatureFlagEnabled(FeaturesFlags.WINNERS),
			categoryId: 2,
			isDisplayInFooter: true
		},
		{
			...links.faq,
			icon: "faq",
			isEnabled: true,
			categoryId: 2,
			isDisplayInFooter: true
		}
	];

	let navigation: Navigation[] = NAVIGATION.filter(
		(navItem) => navItem.isEnabled === true
	);

	if (!isUserOnClub) {
		navigation = navigation.filter(
			(navItem) =>
				navItem.path !== "/club-shop" && navItem.path !== "/club-free-gift"
		);
	}

	if (!isUserOnClub) {
		navigation = navigation.filter(
			(navItem) =>
				navItem.path !== "/club-shop" && navItem.path !== "/club-free-gift"
		);
	}

	// @ts-expect-error
	if (nowTime && typeof nowTime._tzm === "undefined") {
		navigation = navigation.filter(
			(navItem) => navItem.path !== "/pois-bounty"
		);
	}

	if (!poisBountyEventActive || !userTracker) {
		navigation = navigation.filter(
			(navItem) => navItem.path !== "/pois-bounty"
		);
	}

	if (
		!userTracker ||
		!dripOfferData?.tracker ||
		dripOfferData?.tracker?.isFinished !== false
	) {
		navigation = navigation.filter(
			(navItem) => navItem.path !== "/special-offers"
		);
	}

	return navigation;
};
