/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Dialog,
	DialogTitle,
	styled,
	Box,
	DialogContent,
	DialogContentText
} from "@mui/material";
import theme from "theme";

export const StyledBox = styled(Box)`
	background: ${theme.palette.grey[300]};
	border-radius: 13px;
	width: 100px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #000;
	font-size: 18px;
	img {
		width: auto;
		max-width: 80%;
		height: auto;
		max-height: 50%;
		margin: 10%;
		margin-bottom: 0;
	}
`;

export const CustomDialog = styled(Dialog)<{ scale: number }>`
	.MuiPaper-root {
		transform: scale(${(props) => props?.scale});
		border-radius: 25px;
		display: flex;
		flex-direction: column;
		width: 380px;
		min-width: 380px;
		height: 520px;
		min-height: 520px;
		margin: 0px;

		@media (max-height: 590px) {
			flex-direction: row;
			width: 700px;
			min-width: 700px;
			height: 300px;
			min-height: 300px;
		}
	}
`;

export const CustomDialogTitle = styled(DialogTitle)`
	background-color: SteelBlue;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	width: auto;
	height: 232px;
	padding: 0px;

	@media (max-height: 590px) {
		height: auto;
		width: 50%;
	}
`;

export const CustomDialogContent = styled(DialogContent)`
	padding: 0px 24px;

	@media (max-height: 590px) {
		padding: 20px 10px 0px;
	}
`;

export const CustomDialogContentText = styled(DialogContentText)`
	padding: 1rem 0rem 0.5rem;

	@media (max-height: 590px) {
		padding: 1rem 0rem 0.5rem;
	}
`;
