/* eslint-disable */
import { Container, Grid, Skeleton, SxProps } from "@mui/material";
import useBreakpoints from "hooks/useBreakpoints";

export const DripOfferSkeleton = () => {

	const { isLgDown } = useBreakpoints();

	const styles: SxProps = {
		maxWidth: "100%",
	};

	return (
		<Container>
			<Grid container my={8}>
				<Grid item xs={12} display="flex" justifyContent="center">
					<Skeleton variant="rounded" animation="wave" width={412} height={194} sx={styles} />
				</Grid>
				<Grid item xs={12} sm={11} my={3} display="flex" justifyContent="center" mx="auto">
					<Skeleton variant="rounded" animation="wave" width={716} height={191} sx={styles} />
				</Grid>
				<Grid item xs={12} sm={11} lg={8} mx="auto">
					<Grid container spacing={2}>
						{Array.from(Array(6)).map((_, i) => (
							<Grid item key={i} xs={4} sm={2} lg={2} display="flex" justifyContent="center">
								<Skeleton variant="rounded" animation="wave" width={isLgDown ? "100%" : 106} height={161} sx={styles} />
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}