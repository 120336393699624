import { styled } from "@mui/material";

export const PoisBountyContainer = styled("div")<{ stepCount: number }>(
	({ stepCount }) => `
	position: relative;
	border-radius: 18px;
	background-color: #0183a2;
	max-width: 530px;
	height: 460px;
	overflow: hidden;

	&.page-variant {
		height: 323px;
		
		&.claim-all {
			height: 375px;
		}
	}

	&.mega-menu-variant {
		transform: translateX(10px);
		@media(max-width: 1100px) {
			width: 0;
			display: none;
		}
	}
	background: linear-gradient(
		153.95deg,
		rgba(1, 125, 156, 0) 41.84%,
		#0183a2 86.81%
	);
	&.portrait {
		height: calc((${stepCount} * 120px) + 150px);
		max-width: 370px;
		background-color: #0183a2;

		&.claim-all {
			height: calc((${stepCount} * 120px) + 340px);
		}
	}
`
);
