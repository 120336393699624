import { Link, SxProps } from "@mui/material";
import { links } from "helpers/linksConfig";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { appleStoreIconPath, googleStoreIconPath } from "utils/svgIcons";

const AppStoreButton = ({
	type,
	source,
	className = "",
	sx = {}
}: {
	type: "apple" | "google";
	source: string;
	className?: string;
	sx?: SxProps;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();

	const props = {
		className,
		sx,
		component: "a",
		target: "_blank",
		rel: "noreferrer"
	};
	const appleProps = {
		...props,
		onClick: () => {
			reportEvent(links.appstore_apple.analytics_key, { source });
		},
		href: links.appstore_apple.path,
		title: links.appstore_apple.title
	};
	const googleProps = {
		...props,
		onClick: () => {
			reportEvent(links.appstore_google.analytics_key, { source });
		},
		href: links.appstore_google.path,
		title: links.appstore_google.title
	};
	return type === "apple" ? (
		<Link {...appleProps}>
			<img src={appleStoreIconPath} alt={appleProps.title} />
		</Link>
	) : (
		<Link {...googleProps}>
			<img src={googleStoreIconPath} alt={googleProps.title} />
		</Link>
	);
};

export default AppStoreButton;
