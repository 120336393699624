import classNames from "classnames";
import { IShopRewardItem } from "../../../../../contexts/WebStoreProvider/types";
import {
	ItemValue,
	RewardItem,
	RewardItemBox,
	RewardItemBoxGrid
} from "../styled";

export const RewardItems = ({
	items,
	limit = 6
}: {
	items: IShopRewardItem[];
	limit?: number;
}) => {
	items = items.slice(0, limit);
	return (
		<RewardItemBox>
			<RewardItemBoxGrid container rowSpacing={1} columnSpacing={0}>
				{items.map((item) => (
					<RewardItem
						item
						xs={items.length > 4 ? 4 : 6}
						key={`${item.id}-${item.value}`}
					>
						<div className="imgWrap">
							<img src={item.imageUrl} />
						</div>
						<ItemValue
							className={classNames([
								"textWrap",
								String(item.value).length > 5 && "small-text"
							])}
						>
							{item.value?.toLocaleString("en-US")}
						</ItemValue>
					</RewardItem>
				))}
			</RewardItemBoxGrid>
		</RewardItemBox>
	);
};
