import background from "assets/pois-bounty/bg-pop-up.jpg";
import classNames from "classnames";
import useBreakpoints from "hooks/useBreakpoints";
import avatar from "assets/pois-bounty/avatar-pois-bounty.png";
import titleMobile from "assets/pois-bounty/title-mobile-pois-bounty.png";
import { Carousel, Grid } from "../PB-common/learn-more-ftue";
import { useAppDispatch, useAppSelector } from "hooks";
import { onClosePopUp, selectPoisBountyState } from "redux/slices";
import { useEffect } from "react";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import {
	Background,
	Alignment,
	Body,
	Container,
	Head,
	Title,
	Avatar,
	Footer,
	Copy,
	Note,
	Bold,
	Button
} from "./styles";

interface PBFTUEProps {}

export const PBFTUE: React.FC<PBFTUEProps> = () => {
	const { isSmDown } = useBreakpoints();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { tracker } = useAppSelector(selectPoisBountyState).data;
	const dispatch = useAppDispatch();

	useEffect(() => {
		reportEvent("tutorial_clicked", {
			source: "pois_bounty_menu",
			pois_bounty_id: tracker.eventId
		});
	}, []);

	return (
		<Container className={classNames(isSmDown && "mobile")}>
			<Head className="head">
				<Background src={background} />
				{isSmDown ? (
					<Title className="avatar-title" src={titleMobile} />
				) : (
					<Avatar className="avatar" src={avatar} />
				)}
			</Head>
			<Alignment className="alignment">
				<Body>{isSmDown ? <Carousel /> : <Grid />}</Body>
				<Footer>
					<Copy className="copy">
						<Note className="note">Please Note:</Note>
						<Bold>
							Loyalty Points are not being deducted for Poi’s Bounty
							progression.
						</Bold>{" "}
						Only Loyalty Points accumulated during the sale are counted towards
						your progression.
					</Copy>
				</Footer>
			</Alignment>
			<Button className="button" onClick={() => dispatch(onClosePopUp())}>
				Continue Shopping
			</Button>
		</Container>
	);
};
