import { Trackers } from "interfaces";
import { getUnclaimedRewardV2 } from "../../helper";
import { LoadDataActionTypeV2, PoisBountyV2Slice } from "../../v2/types";

export const updatePoisBountyV2Slice = (
	state: PoisBountyV2Slice,
	action: LoadDataActionTypeV2
): void => {
	const poisBountyData = action.payload;
	if (!poisBountyData) {
		return;
	}
	if (!("trackers" in poisBountyData)) {
		state.responseSucceedWithNoTrackerV2 = true;
		return;
	}
	
	const { trackers } = poisBountyData;
	const currentCycle: Trackers = trackers.findLast((t: Trackers) => t.completed === false) ?? trackers[0];
	const { loyaltyPoints, rewardClaimStatuses } = currentCycle;
	const unclaimedRewardIndex = getUnclaimedRewardV2(rewardClaimStatuses);
	
	if (unclaimedRewardIndex === -1 && !state.creatingNewCycle) {
		state.remaining24hour = true;
	}
	state.dataV2 = poisBountyData;
	state.trackers = currentCycle;
	state.rewards = rewardClaimStatuses;
	state.userLoyaltyPoints = loyaltyPoints;
	state.blockAllButtons = loyaltyPoints >= rewardClaimStatuses.slice(-1)[0].reward.loyaltyPoints;
};
