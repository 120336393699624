import classNames from "classnames";
import { useEffect, useState } from "react";
import { ButtonStyled } from "../ClaimButton/styles";
import { useGoogleAnalyticsContext } from "contexts";
import { useAppDispatch, useAppSelector } from "hooks";
import { EventCompleted, EventCompletedMobile } from "./styles";
import { onClaimAll, selectPoisBountyV2State } from "redux/slices";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";
import poiEventCompleted from "assets/pois-bounty/v2/poi-event-completed.png";

interface EventCompleteProps { }

export const EventComplete = ({ }: EventCompleteProps) => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isPageVariant, isPortrait } = usePoisBountyContext();
	const { claimingAllRewards, dataV2, trackers, remaining24hour } = useAppSelector(selectPoisBountyV2State);

	const [unclaimedRewardsCount, setUnclaimedRewardsCount] = useState<number>(0);

	const claimAllRewardsAndReport = () => {
		dispatch(onClaimAll(true));
		reportEvent("claim_all_rewards", {
			source: "pois_bounty_v2",
			pois_bounty_id: trackers.eventId,
			cycle_index: trackers.cycleIndex,
			number_of_rewards: unclaimedRewardsCount,
		});
	};

	useEffect(() => {
		const { trackers } = dataV2;
		let count = 0;
		trackers.map(t => {
			count += t.rewardClaimStatuses.filter(rcs => rcs.isClaimed === false && rcs.reward.loyaltyPoints <= t.loyaltyPoints).length;
		});
		setUnclaimedRewardsCount(count);
	}, [dataV2]);

	if (isPortrait) {
		return (
			<EventCompletedMobile>
				<p>
					{!remaining24hour &&
						<>
							<span>Cycle Completed!</span><br />
						</>
					}
					You have rewards to claim.<br />
					{!remaining24hour &&
						"Reach here to restart the meter!"
					}
				</p>
				<div>
					<ButtonStyled
						disabled={claimingAllRewards}
						onClick={() => {
							claimAllRewardsAndReport();
						}}
					>
						{!remaining24hour ? "Claim and Restart" : "Claim All"}
					</ButtonStyled>
					<img src={poiEventCompleted} alt="Poi event completed image" />
				</div>
			</EventCompletedMobile>
		)
	} else {
		return (
			<EventCompleted className={classNames([!isPageVariant && "mega-menu-variant"])}>
				<img src={poiEventCompleted} alt="Poi event completed image" />
				<p>
					{!remaining24hour &&
						<span>Cycle Completed!</span>
					}
					You have rewards to claim.
				</p>
				<ButtonStyled
					disabled={claimingAllRewards}
					onClick={() => {
						claimAllRewardsAndReport();
					}}
				>
					{!remaining24hour ? "Claim and Restart" : "Claim All"}
				</ButtonStyled>
			</EventCompleted>
		);
	}
};
