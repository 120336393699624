import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography
} from "@mui/material";
import { useEnvContext } from "../../contexts/EnvProvider";
import { DialogTitleProps } from "components/BootstrapDialog";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { onClose } = props;
	const { cdnPrefix } = useEnvContext();
	const backgroundImage = `url(${cdnPrefix}/spec_dialogs/oops2.png)`;
	return (
		<DialogTitle
			sx={{
				height: "160px",
				padding: 0,
				backgroundSize: "cover",
				backgroundColor: "#3B2FC5",
				backgroundImage: backgroundImage,
				backgroundPosition: "center",
				overflow: "hidden"
			}}
		>
			<CloseIconButton onClose={onClose} />
		</DialogTitle>
	);
};

const BootstrapDialog = ({
	title,
	subtitle,
	children,
	handleClose,
	open,
	showClose = true
}: {
	title?: string | JSX.Element;
	subtitle: string | undefined;
	children: React.ReactNode;
	handleClose: () => void;
	open: boolean;
	ctaButton?: JSX.Element | null;
	showClose?: boolean | undefined;
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			style={{ zIndex: 9999 }}
			sx={{
				".MuiPaper-root": {
					borderRadius: 6,
					paddingBottom: ".15rem",
					width: "462px"
				}
			}}
		>
			<BootstrapDialogTitle
				id="customized-dialog-title"
				onClose={handleClose}
				isShowClose={showClose}
			>
				{title}
			</BootstrapDialogTitle>
			<DialogContent sx={{ paddingBottom: 0 }}>
				<DialogContentText sx={{ padding: ".75rem 1rem" }}>
					<Typography
						variant="h4"
						component="strong"
						sx={{
							fontWeight: "bold",
							margin: "1rem 0 .75rem",
							display: "block",
							textAlign: "center",
							lineHeight: 1,
							color: "#333333"
						}}
					>
						{subtitle}
					</Typography>
					{children}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default BootstrapDialog;
