import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;

export const Text = styled.span`
	font-family: Baloo;
	font-size: 16px;
	line-height: 17px;
	text-align: center;
	position: absolute;
	color: #fff;
	text-transform: uppercase;
	margin-left: -5px;
`;
