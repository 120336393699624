import styled, { CSSProperties } from "styled-components";
import bgBrushTop from "../../../../assets/waterfall-sale/bg-brush-top.png";
import bgBrushBottom from "../../../../assets/waterfall-sale/bg-brush-bottom.png";

interface IDiv {
	BackgroundImage: string;
}

export const Container = styled.div<IDiv>`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	background-image: url(${(props) => props.BackgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;

	@media (orientation: landscape) {
		padding: 8% 0;
		@media(max-width: 740px) {
			padding: 6% 0;
		}
		@media(max-width: 667px) {
			padding: 14% 0;
		}
	}
	@media (orientation: portrait) {
		padding: 8% 0;
	}
`;

export const OffersContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 1;

	@media (min-width: 734px) {
		flex-direction: row;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	@media (min-width: 734px) {
		flex-direction: row;
	}
`;

export const Image = styled.img`
	width: 28px;
	margin-top: 18px;
	transform: rotate(90deg);

	@media (min-width: 734px) {
		margin-top: 40px;
		transform: rotate(0deg);
	}
	@media (min-width: 930px) {
		width: 40px;
	}
	@media (orientation: portrait) {
		@media (max-width: 430px) {
			margin-top: 8px;
		}
	}
`;

export const BgTopBrush = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: auto auto;
	background-position: top;
	background-repeat: no-repeat;
	background-image: url(${bgBrushTop});
	padding: 2px 0;
	top: -25px;
`;

export const BgBottomBrush = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: auto auto;
	background-position: bottom;
	background-repeat: no-repeat;
	background-image: url(${bgBrushBottom});
	bottom: -25px;
`;

export const Title = styled.div<IDiv>`
	content: url(${(props) => props.BackgroundImage});
	min-width: 210px;
	max-width: 300px;
	margin-top: 20px;
	z-index: 1;

	width: 40vw;
`;

export const timerStyle: CSSProperties = {
	whiteSpace: "nowrap",
	top: "9%",
	backgroundColor: "#fff",
};

export const timerStyleTablet: CSSProperties = {
	...timerStyle,
	top: "5%",
};

export const timerStyleMobile: CSSProperties = {
	...timerStyleTablet,
	fontSize: "0.7rem",
	top: "2%",
};

export const timerStyleExtraSmall: CSSProperties = {
	...timerStyleMobile,
	padding: "4px 8px",
};
