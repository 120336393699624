import { Trigger } from "interfaces";
import { useAppSelector } from "hooks";
import { selectWebStoreState } from "redux/slices";
import { DisplayItem, RewardType } from "../types";
import { links } from "../../../helpers/linksConfig";

export const getRewardsById = (rewardId: string): DisplayItem["rewards"] => {
	const { rewards, rewardTypes } = useAppSelector(selectWebStoreState).store;
	if (!rewards || !rewardTypes) {
		return [];
	}

	const items = rewards[rewardId]?.items;
	const displayRewards: DisplayItem["rewards"] = [];
	if (!items) {
		return [];
	}
	for (const [rewardItemId, units] of Object.entries(items)) {
		(rewardTypes as RewardType[]).forEach(({ id, imageUrl }) => {
			if (id === rewardItemId) {
				displayRewards.push({
					id,
					imageUrl: imageUrl,
					description: units.toLocaleString("en-US")
				});
			}
		});
	}

	return displayRewards;
};

export const PATHS_DIPS_TRIGGERS: { [path: string]: Trigger } = {
	[links.shop.path]: "mainShop",
	[links.loyalty.path]: "loyaltyShop",
	[links.free_daily_gifts.path]: "freeDailyGifts",
	[links.club_free_gift.path]: "clubFreeGift",
	[links.club_shop.path]: "clubShop",
	[links.pois_bounty.path]: "poisBounty",
	[links.drip_offer.path]: "dripOffer",
};
