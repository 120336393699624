import { Link, SxProps } from "@mui/material";
import AppStoreButton from "components/Buttons/AppStoreButton";
import { links } from "helpers/linksConfig";
import {
	StoreButtonAppleOutline,
	StoreButtonGoogleOutline
} from "utils/svgIcons";

export const AppStoreButtonGoogleOutlined = ({ sx = {} }: { sx?: SxProps }) => (
	<Link
		className="hvr-float-shadow"
		sx={sx}
		href={links.appstore_google.path}
		title={links.appstore_google.title}
	>
		<StoreButtonGoogleOutline />
	</Link>
);

export const AppStoreButtonAppleOutlined = ({ sx = {} }: { sx?: SxProps }) => (
	<Link
		className="hvr-float-shadow"
		sx={sx}
		href={links.appstore_apple.path}
		title={links.appstore_apple.title}
	>
		<StoreButtonAppleOutline />
	</Link>
);
export const AppStoreButtonApple = ({ sx = {} }: { sx?: SxProps }) => (
	<AppStoreButton
		className="hvr-float-shadow"
		type="apple"
		source="hero"
		sx={sx}
	/>
);
export const AppStoreButtonGoogle = ({ sx = {} }: { sx?: SxProps }) => (
	<AppStoreButton
		className="hvr-float-shadow"
		type="google"
		source="hero"
		sx={sx}
	/>
);
