import { Dispatch, SetStateAction } from "react";
import { ConnectionStatus } from "..";
import { IDebugLogger } from "../../../utils/debugLogger";

const getTryLogin = ({
	setFbAccessToken,
	setConnectionStatus,
	debug
}: {
	setFbAccessToken: Dispatch<SetStateAction<string | null>>;
	setConnectionStatus: Dispatch<SetStateAction<ConnectionStatus>>;
	debug: IDebugLogger;
}) => {
	const tryLogin = async () => {
		try {
			window.FB.getLoginStatus((response: fb.StatusResponse) => {
				// console.log(`checkLoginState response.status:${JSON.stringify(respon)}`);
				if (response.status === "connected") {
					debug.log({
						iconText: "tryLogin -> FB.getLoginStatus",
						message: [
							"connected, accessToken:",
							response.authResponse.accessToken
						]
					});
					setFbAccessToken(response.authResponse.accessToken);
				} else {
					window.FB.login((response: fb.StatusResponse) => {
						if (response.status === "connected") {
							setFbAccessToken(response.authResponse.accessToken);
						} else {
							setFbAccessToken(null);
						}
						debug.log({
							iconText: "tryLogin -> FB.login:",
							message: [
								response.status,
								"accessToken:",
								response?.authResponse?.accessToken || null
							]
						});
					});
				}
			});
		} catch (err) {
			console.error(err);
			setFbAccessToken(null);
			setConnectionStatus(ConnectionStatus.UNKNOWN);
			debug.log({
				icon: "tryLogin -> error:",
				message: [
					"connectionStatus:",
					ConnectionStatus.UNKNOWN,
					"accessToken:",
					null
				]
			});
		}
	};

	return tryLogin;
};

export default getTryLogin;
