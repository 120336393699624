import { Box, styled } from "@mui/material";
import styledC from "styled-components";
import theme from "theme";

export const PageTopNav = styled("div")`
	position: sticky;
	a {
		text-decoration: none;
	}
`;

export const Nav = styled("div")`
	width: 100%;
	/* overflow-x: auto;
	padding-top: 0.3rem;
	@media (max-width: 465px) {
		width: 100vw;
	} */
`;

export const SectionMenu = styled("div")`
	padding: 0.3rem 0;
	z-index: 100;
	// horizontal scrolling
	overflow: hidden;

	&.appBarStatic {
		margin-top: 0;
		padding-top: 0;
	}

	& > div {
		/**
		 * horizontal wrap items:
		 */
		// wrap items:
		// flex-wrap: wrap;

		/**
		 * horizontal scroll items:
		 */
		flex-wrap: nowrap;
		overflow-x: auto;
		// Hide scrollbar for Chrome, Safari and Opera
		&::-webkit-scrollbar {
			display: none;
		}
		// Hide scrollbar for IE, Edge add Firefox
		& {
			-ms-overflow-style: none;
			scrollbar-width: none; /* Firefox */
		}
	}

	button {
		text-transform: capitalize;
	}
	div[class*="selected"] button {
		background-color: rgb(229 57 57);
	}

	div[class*="highlight"] button::after {
		content: "";
		position: absolute;
		top: -3px;
		right: -3px;
		border: 6px solid rgb(229 57 57);
		border-radius: 50%;
	}

	svg {
		margin-right: 0.2rem;
	}
`;
/* eslint-disable */
export const MainMenuContainer = styled(Box)`
	background-color: #fffaf1;
	flex-wrap: wrap;
	padding: 0;
	padding-top: 0.2rem;
	text-transform: uppercase;
	z-index: 100;

	> div {
		padding-left: 0;
	}

	&,
	a {
		font-size: 16px;
		font-weight: bold;
		text-decoration: none;
		white-space: nowrap;

		${theme.breakpoints.up("lg")} {
			font-size: 16px;
		}
	}

	a {
		color: rgb(4, 157, 174);
		align-items: center;
		display: flex;
		justify-content: flex-start;
	}

	div {
		background-color: transparent !important;
	}

	div[class*="selected"] a {
		color: ${(props: any) => props["nav-item-color"] ?? "rgb(229, 57, 57)"};

		span[class*="text"] {
			border-bottom: 4px solid
				${(props: any) => props["nav-item-color"] ?? "rgb(229, 57, 57)"};
		}
	}

	svg {
		height: 29px;
		margin-right: 0.5rem;
		width: 22px;
	}

	${theme.breakpoints.down("sm")} {
		top: 3.5rem;
	}

	${theme.breakpoints.up("lg")} {
		display: flex;
		justify-content: center;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1;
	}
`;
/* eslint-enable */

export const SectionMenuContainer = styled(Box)`
	//background-color: #fffaf1;
	position: relative;
`;

export const Item = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary
}));

export const mainMenuContainerInnerSxProps = {
	display: "flex",
	justifyContent: { xs: "left" },
	padding: { xs: "1rem 0", lg: "2px 8px" },
	maxWidth: { lg: "1245px" },
	width: { xs: "97%" }
};

export const sectionMenuContainerSxProps = {
	position: "fixed",
	zIndex: 2,
	width: "auto",
	marginRight: { xs: 0 },
	marginLeft: { xs: 0 },
	paddingLeft: { xs: 0 },
	paddingRight: { xs: 0 },
	display: "flex",
	justifyContent: "center"
};

export const sectionMenuInnerSxProps = {
	display: "flex",
	justifyContent: { xs: "center" },
	padding: { xs: 0 },
	width: { xs: "100%" }
};

export const sectionMenuItemSxProps = {
	background: "none",
	padding: {
		xs: "0.5vw",
		sm: "6px",
		whiteSpace: "nowrap",
		svg: {
			maxHeight: "18px",
			height: "auto",
			width: "19px"
		}
	}
};

export const sectionMenuItemButtonSxProps = {
	background: "rgba(1, 161, 189, 1)",
	":hover": {
		background: "Rgba(1, 161, 189, 0.8)"
	},
	borderRadius: "6px",
	fontSize: {
		xs: "72%",
		sm: "90%"
	},
	padding: {
		xs: "1.1vw 2.5vw",
		sm: "6px 16px"
	},
	svg: {
		fill: "#fff"
	}
};

export const TopBarButton = styledC.span<{ color: string; background: string }>`
	position: relative;
	display: flex;
	padding: 8px 18px;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-radius: 16px;
	box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
	background: ${(props) => props.background};
	color: ${(props) => props.color};
	font-family: Baloo;
	font-size: 20px;
	font-style: normal;
	text-transform: capitalize;
	font-weight: 400;
	line-height: 24px;
	transition: 0.4s all ease-in-out;
	cursor: pointer;
	

	@media (hover: hover) {
		&:hover {
			background: #fff;
			color: #113b62;
		}
	}

	@media (orientation: portrait) {
		padding: 2px 9px;
	}

`;
