import { useWebStoreContext } from "contexts/WebStoreProvider";
import { IShopLoyaltyOffer } from "contexts/WebStoreProvider/types";
import { SingleItemsSkeleton } from "../../skeleton";
import { useContext, useEffect, useState } from "react";
import { offersApi, useGetOffersQuery } from "redux/apis";
import { LoyaltyContext } from "../../index";
import LoyaltyFeaturedOfferSection from "../LoyaltyOfferSection";
import { useDispatch } from "react-redux";
import useNewDay from "hooks/useNewDay";
import { ISectionMenuItem } from "components/PageTopNav";

const SingleItems = () => {
	const { store, authToken } = useWebStoreContext();
	const [content, setContent] = useState<JSX.Element[]>();
	const dispatch = useDispatch();
	const { newDay } = useNewDay();
	const { data: shop, refetch } = useGetOffersQuery(
		{
			offerType: "loyalty",
			isAuthenticated: !!authToken?.value
		},
		{ pollingInterval: 600000 }
	);
	const { sectionMenuData } = useContext(LoyaltyContext);

	useEffect(() => {
		if (!store?.loyaltyShopSections || !shop || !sectionMenuData.length) {
			return;
		}
		setContent(
			store?.loyaltyShopSections
				.filter((data) =>
					shop.offers?.find(
						(of: IShopLoyaltyOffer) => of.sectionName === data.name
					)
				)
				.map((data) => {
					const sectionOffers = shop?.offers.filter(
						(of: IShopLoyaltyOffer) => of.sectionName === data.name
					);
					const sectionProps = {
						data,
						sectionRef: sectionMenuData.find(
							(item: ISectionMenuItem) => item.name === data.name
						)?.sectionRef,
						uniqueKey: data.name
					};

					return (
						<LoyaltyFeaturedOfferSection
							{...sectionProps}
							key={data.name}
							sectionOffers={sectionOffers as IShopLoyaltyOffer[]}
						/>
					);
				})
		);
	}, [shop, sectionMenuData]);

	useEffect(() => {
		dispatch(
			offersApi.util.invalidateTags([
				{
					type: "offersTag",
					id: "loyalty"
				}
			])
		);
	}, [sectionMenuData]);

	useEffect(() => {
		if (newDay) {
			refetch();
		}
	}, [newDay]);

	if (!content) {
		return <SingleItemsSkeleton />;
	}

	return <>{content}</>;
};

export default SingleItems;
