import { baseApi } from "../api";

export const timezoneAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getTimezones: builder.query({
			query: () => `/rest/debug/user/timezones`,
		}),
	})
});

export const { useGetTimezonesQuery } = timezoneAPI;
