import { useEffect } from "react";
import { hideBar } from "./helper";
import classNames from "classnames";
import { ProgressStyled } from "./styles";
import { StepConfig } from "./lib/Step/types";
import { useAppDispatch, useAppSelector } from "hooks";
import { useRewards } from "components/PoisBountyWidgetV2/hooks";
import { onPBV2change, selectPoisBountyV2State } from "redux/slices";
import { EventComplete, Step } from "./lib";
import { usePoisBountyContext } from "components/PoisBountyWidgetV2/context";

export interface ProgressStepsProps { }

export const ProgressSteps: React.FC<ProgressStepsProps> = () => {
	const dispatch = useAppDispatch();
	const { isPortrait, isPageVariant } = usePoisBountyContext();
	const { trackers, userLoyaltyPoints, remaining24hour } = useAppSelector(selectPoisBountyV2State);
	const { steps } = useRewards(trackers.rewardClaimStatuses);

	useEffect(() => {
		if (userLoyaltyPoints >= steps.slice(-1)[0].target) {
			dispatch(onPBV2change({ blockAllButtons: true }));
		}
	}, [userLoyaltyPoints, steps]);

	return (
		<>
			{(userLoyaltyPoints >= steps.slice(-1)[0].target || remaining24hour) && isPortrait && (
				<EventComplete />
			)}
			<ProgressStyled
				className={classNames([
					isPortrait && "portrait",
					isPageVariant && "page-variant",
					userLoyaltyPoints >= steps.slice(-1)[0].target && isPortrait && "event-completed"
				])}
				stepCount={trackers.rewardClaimStatuses.length}
			>
				{steps.map((reward, index, arr) => {
					const config: StepConfig = {
						hideBar: hideBar(arr, index, userLoyaltyPoints),
						first: index === 0,
						last: index + 1 === arr.length
					};

					return (
						<Step key={index} reward={reward} stepIndex={index} config={config} />
					);
				})}
			</ProgressStyled>
			{(userLoyaltyPoints >= steps.slice(-1)[0].target || remaining24hour) && !isPortrait && (
				<EventComplete />
			)}
		</>
	);
};
