import moment from "moment";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEventTimes, getUncollectedRewards } from "./helper";
import {
	onAutoClaim,
	onOpenPopUp,
	onPBV2change,
	onPopUpV2Displayed,
	selectPoisBountyV2State
} from "redux/slices";
import { links } from "helpers";

export const useVariantByTime = (triggerUIEvent: boolean): void => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { nowTime } = useWebStoreContext();
	const { dataV2, rewards, userLoyaltyPoints, eventEnded, trackers } =
		useAppSelector(selectPoisBountyV2State);
	const [uiEventSent, setUiEventSent] = useState(false);
	const { userTracker } = useWebStoreContext();

	const disablePBEvent = useCallback((): void => {
		dispatch(onPBV2change({ isEventActiveV2: false }));
		// if the user is on the /pois-bounty page we redirect to landing page. Because
		// if there's not event active it makes no sense keep the user on the PB page
		if (pathname === links.pois_bounty.path) {
			navigate(links.home.path);
		}
	}, []);

	const enablePBEvent = useCallback((): void => {
		dispatch(onPBV2change({ variant: "in-progress" }));
		dispatch(onPBV2change({ isEventActiveV2: true }));
	}, []);

	useEffect(() => {
		if (!Object.keys(dataV2).length || !userTracker) {
			return;
		}
		const { endTime, completeDisplayed, eventId } = trackers;
		// Get the current state of the event by dates
		const { eventInProgress, saleEnded, eventEnded } = getEventTimes(
			nowTime,
			moment(endTime)
		);

		if (eventInProgress) {
			enablePBEvent();
			return;
		}

		// checking if there are uncollected rewards
		const uncollectedRewards = getUncollectedRewards(
			rewards,
			userLoyaltyPoints
		);

		if (saleEnded && !eventEnded) {
			enablePBEvent();

			if (!userLoyaltyPoints || !uncollectedRewards.length) {
				disablePBEvent();
				return;
			}
			// If the event is not in progress but it's not ended yet, that means that remaing 24 to
			// change to ended state. But before that we set 24hrs remaining state where only can
			// see their progress and claim their rewards. Because the sale is ended
			dispatch(onPBV2change({ remaining24hour: true, blockAllButtons: true }));
			return;
		}

		// If there are not uncollected rewards then we just disable PB envent
		if (!uncollectedRewards.length) {
			disablePBEvent();
			return;
		}

		// If there are uncollected rewards then we auto-claim those rewards and show the pop-up
		if (!completeDisplayed) {
			if (triggerUIEvent && !uiEventSent) {
				setUiEventSent(true);
				dispatch(onAutoClaim());
			}
			dispatch(onOpenPopUp("pb-uncollected-rewards"));
			dispatch(onPopUpV2Displayed(eventId));
			dispatch(onPBV2change({ variant: "sent-uncollected-rewards" }));
		}
	}, [dataV2, eventEnded, nowTime]);
};
