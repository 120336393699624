import { styled } from "@mui/material";

export const CardStyled = styled("div")`
	background-color: #fff;
	border-radius: 1rem;
	padding: 1rem;
	box-shadow: 2px 5px 4px 1px rgba(0, 0, 0, 0.1);
	width: 240px;
	display: grid;
	grid-template-rows: 145px auto;
	gap: 0.5rem;
	text-align: center;

	&.landscape {
		@media (max-width: 932px) {
			width: 220px;

			.title {
				font-size: 1rem;
			}
		}

		@media (max-width: 780px) {
			width: 180px;
		}

		@media (max-width: 680px) {
			width: 150px;
		}

		@media (max-width: 932px) and (max-height: 570px) {
			width: 96%;
			height: 160px;
			padding: 0.6rem 1rem;
			grid-template-rows: 75px auto;

			.description {
				font-size: 11px;
				line-height: 15px;
			}
			.title {
				font-size: 11px;
				margin-bottom: 0.2rem;
				line-height: 12px;
			}
		}

		@media (max-height: 360px) {
			height: 146px;
			padding: 0.6rem;
		}
	}

	&.mobile {
		height: 230px;
		grid-template-rows: 120px auto;

		.title {
			font-size: 1rem;
		}

		.body {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
		}

		.image {
			height: 120px;
		}

		.description {
			line-height: 20px;
			font-size: 14px;
		}
	}
`;

export const Img = styled("img")`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 1rem;
`;

export const Body = styled("div")``;

export const Title = styled("h3")`
	font-family: "Baloo";
	font-size: 20px;
	font-weight: 400;
	line-height: 22px;
	color: #113B62;
	margin: 0;
`;

export const Description = styled("p")`
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	margin: 0;
	@media (max-width: 700px) and (max-height: 400px) {
		font-size: 10px !important;
	}
`;
