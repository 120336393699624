/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
	useMediaQuery
} from "@mui/material";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import FacebookLoginButton from "components/Buttons/FacebookLoginButton";
import ScopelyLoginButton from "components/Buttons/ScopelyLoginButton";
import CloseIconButton from "../lib/CloseIconButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import {
	CustomDialog,
	CustomDialogContent,
	CustomDialogContentText,
	CustomDialogTitle
} from "./styles";

const DialogLoginToContinue = ({
	show,
	onCloseClick,
	source
}: {
	show: boolean;
	// eslint-disable-next-line
	onCloseClick: (event?: any, reason?: any) => void;
	source?: string;
}) => {
	const { cdnPrefix, isFeatureFlagEnabled } = useEnvContext();
	const backgroundImage = `url(${cdnPrefix}/spec_modal_login/header_modal_login.png)`;
	const isLandscape = useMediaQuery("(max-height:590px)");
	const top = source === "shop" ? 32 : 0;
	const scale = useCalculateScale(
		isLandscape ? 730 : 410,
		isLandscape ? 395 + top : 550
	);

	return (
		<CustomDialog
			open={show}
			//onClose={onCloseClick}
			hideBackdrop={source === "shop" ? true : false}
			sx={{
				zIndex: source === "shop" ? 2 : undefined,
				".MuiPaper-root": {
					top: top
				}
			}}
			scale={scale}
		>
			<CustomDialogTitle
				sx={{
					backgroundImage: backgroundImage
				}}
			>
				{source === "shop" ? null : <CloseIconButton onClose={onCloseClick} />}
			</CustomDialogTitle>
			<div
				style={{
					width: isLandscape ? "50%" : "auto"
				}}
			>
				<CustomDialogContent>
					<CustomDialogContentText>
						<Typography
							variant="h5"
							component="div"
							sx={{
								fontWeight: "bold",
								marginTop: ".5rem 0 .5rem",
								display: "block",
								textAlign: "left",
								lineHeight: 1.8,
								color: "#333333",
								fontSize: { xs: "24px" }
							}}
						>
							<span>Login to continue</span>
						</Typography>
						<Typography
							variant="body1"
							component="strong"
							sx={{
								display: "block",
								textAlign: "left",
								lineHeight: 1.3,
								color: "#333333",
								fontSize: { xs: "1rem" }
							}}
						>
							To use the Web Shop, your <b>Tiki Solitaire TriPeaks</b> account
							must be connected to your Scopely or Facebook account.
						</Typography>
					</CustomDialogContentText>
				</CustomDialogContent>
				<DialogActions
					sx={{ justifyContent: "center", flexDirection: "column" }}
				>
					<Box
						sx={{
							width: "calc(100% - 2rem)"
						}}
					>
						{isFeatureFlagEnabled(FeaturesFlags.SCOPELY_LOGIN) && (
							<ScopelyLoginButton
								source={`login_to_continue_dialog${source ? `#${source}` : ""}`}
							/>
						)}
						<FacebookLoginButton
							cbClick={async () => {
								onCloseClick({ keepCurrentOffer: true });
							}}
							source={`login_to_continue_dialog${source ? `#${source}` : ""}`}
						/>
					</Box>
				</DialogActions>
			</div>
		</CustomDialog>
	);
};

export { DialogLoginToContinue };
