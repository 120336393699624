/**
 * Native scrollTo with callback
 * https://stackoverflow.com/a/55686711
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
function scrollTo(offset: number, callback: () => void) {
	const fixedOffset = offset.toFixed();
	const onScroll = function () {
		if (window.pageYOffset.toFixed() === fixedOffset) {
			window.removeEventListener("scroll", onScroll);
			callback();
		}
	};

	window.addEventListener("scroll", onScroll);
	onScroll();
	window.scrollTo({
		top: offset,
		behavior: "smooth"
	});
}

export default scrollTo;
