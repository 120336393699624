import React from "react";
import { Skeleton } from "@mui/material";

export const BoxCardSkeleton = () => {
	return (
		<Skeleton
			animation="wave"
			variant="rectangular"
			sx={{
				maxWidth: "100%",
				margin: "20px 5px 5px 5px",
				height: "322px",
				borderRadius: "25px"
			}}
		/>
	);
};
